import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';
import { parse } from 'query-string';
import { selectFleet, updateVeraScoreType } from 'components/auth/auth.actions';
import {
  isLoggedInUserTypeDriverSelector,
  rolesSelector,
} from 'components/auth/auth.reducer';
import { ROLES, V2_GLOBAL_INSTALLER_FLEET_ID } from 'constants/roles';
import {
  setActiveGroup,
  Group,
  fetchGroups,
  activeGroupIdSelector,
  clearGroups,
  findGroupById,
  findFirstAccessibleGroup,
} from 'components/groups/groups.redux';
import {
  setActiveDriver,
  clearDriversData,
} from 'components/organization/drivers/drivers.redux';
import {
  setActiveVehicle,
  clearVehiclesData,
} from 'components/organization/vehicles/vehicles.redux';
import { getNextFleet, isSuperfleet } from 'components/fleets/utils';
import PreloadingScreen from 'components/preloading-screen';
import { clearFleetData, clearFleetCacheData } from 'utils/localstorage';
import {
  selectActiveEntityType,
  selectActiveEntityId,
  setEntityType,
} from 'components/entity-type/active-entity-type.redux';
import {
  EntityType,
  SubFleetsEntityType,
} from 'components/entity-type/entity-type-utils';
import ErrorBoundary from 'components/error-boundary/error-boundary';
import { ROUTES } from './constants';
import { getFleetSettings } from 'components/settings/settings.redux';
import { UNTAGGED_COUNT_RECEIVED } from 'redux/faces';
import Error from './error';
import { fetchSuperfleetDetails } from 'components/superfleets/superfleets.redux';
import { FleetAccess } from 'models/db';
import { pollForNotifications } from 'components/notifications/notifications.redux';
import { useTranslation } from 'react-i18next';
import usePermissions, { Permissions } from 'hooks/use-permissions';
import useFeatureFlags from 'hooks/use-feature-flags';
import { VeraVersionType } from 'utils/vera-score-utils';
import { fleetLink } from 'components/fleet-link';
import useFleetData from 'hooks/use-fleet-data';
import { usePaginatedFaces } from 'hooks/use-faces-data';

interface Params {
  groupId: string;
  fleetId: string;
  entityId: string;
  superfleetId: string;
}

interface Props {
  match: {
    params: Params;
    path: string;
  };
  location: {
    pathname: string;
    search: string;
    state: { return_to_page?: string };
    hash: string;
  };
}

interface ConnectedProps {
  fleets?: any[];
  rootGroup: Group;
  activeGroupId: string;
  activeFleetId: string;
  activeEntityId: string;
  activeEntityType: EntityType | SubFleetsEntityType;
  dispatch: any;
  fleetPackage?: any;
  userRoles: string[];
  user?: any;
  isLoggedInUserDriver?: boolean;
}

export const FleetData: React.FC<Props & ConnectedProps> = props => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [received, setReceived] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [t] = useTranslation();
  const { hasPermission } = usePermissions();
  const featureFlags = useFeatureFlags();
  const fleetData = useFleetData({ current: true });

  const {
    dispatch,
    activeGroupId,
    match: { params, path },
    children,
    location,
    activeFleetId,
    userRoles,
    fleets = [],
    user,
    isLoggedInUserDriver,
  } = props;

  const hasDriverProfileViewRole =
    isLoggedInUserDriver &&
    userRoles.length === 1 &&
    userRoles.includes(ROLES.DRIVER_PROFILE_VIEWER);

  const hydrateGroups = () =>
    dispatch(fetchGroups()).then(hydrateActiveGroupId);

  const hydrateActiveGroupId = (rootGroup: Group) => {
    const nextGroupId =
      // prioritize the groupId in the url
      (params.groupId && findGroupById(rootGroup, params.groupId)?.id) ||
      // fallback to the first accessible group
      findFirstAccessibleGroup(rootGroup);

    if (nextGroupId) {
      dispatch(setActiveGroup(nextGroupId));
      return Promise.resolve();
    } else {
      return Promise.reject(
        t('User does not have access to any subfleets in this fleet.'),
      );
    }
  };

  const setDefaultEntity = () => {
    const parsed = parse(location.search);
    if (parsed?.entity) {
      const isEntityAllowed = fleetData?.isTypeAllowed(parsed.entity);
      if (isEntityAllowed) {
        dispatch(setEntityType(parsed.entity));
        return;
      }
    }
    const defaultEntity = fleetData?.defaultEntityType();
    defaultEntity && dispatch(setEntityType(defaultEntity));
  };

  const hydrateActiveEntity = () => {
    if (params.entityId && !hasDriverProfileViewRole) {
      const { entityId } = params;
      const isDriver = params.entityId.includes('d-');
      const isVehicle = params.entityId.includes('v-');

      if (isDriver && fleetData?.isTypeAllowed(EntityType.DRIVER)) {
        dispatch(setEntityType(EntityType.DRIVER));
        dispatch(setActiveDriver(entityId));
      }

      if (isVehicle && fleetData?.isTypeAllowed(EntityType.VEHICLE)) {
        dispatch(setEntityType(EntityType.VEHICLE));
        dispatch(setActiveVehicle(entityId));
      }
    }
  };

  /* f-admin is only used for Global Installer role */
  const validFleets = fleets?.filter(
    ({ fleet }) => fleet.id !== V2_GLOBAL_INSTALLER_FLEET_ID,
  );
  /* based on what is in the url and redux, what should the next fleet be? */
  const next: FleetAccess = getNextFleet({
    fleets: validFleets,
    urlFleetId: params.fleetId,
  });
  const nextFleetId = next && next.fleet.id;
  const nextFleetIsSuperfleet = next && isSuperfleet(next.fleet);
  const userHasSuperfleetEventsOnlyRole =
    userRoles.length === 1 && userRoles[0] === ROLES.SUPERFLEET_EVENTS;

  const isCoachingPage = location?.pathname.endsWith('/coaching');
  const {
    data: paginatedFacesData,
    status: paginatedFacesQueryStatus,
  } = usePaginatedFaces({
    enabled: received && !userHasSuperfleetEventsOnlyRole && !isCoachingPage,
  });

  const fetchData = () => {
    setLoaded(false);
    setReceived(false);
    if (nextFleetId && !error) {
      dispatch(selectFleet(nextFleetId));

      const rolesAreLoaded = userRoles.length >= 1;

      if (rolesAreLoaded && nextFleetIsSuperfleet) {
        dispatch(fetchSuperfleetDetails(nextFleetId))
          .then(() => {
            setLoaded(true);
            if (userHasSuperfleetEventsOnlyRole) {
              setReceived(true);
            }
          })
          .catch(e => {
            console.error(e);
            setError(e);
          });
        return;
      }

      if (rolesAreLoaded && !hasDriverProfileViewRole) {
        Promise.all([
          dispatch(getFleetSettings()),
          hydrateGroups(),
          dispatch(pollForNotifications()),
        ])
          .then(() => {
            setReceived(true);
          })
          .catch(e => {
            console.error(e);
            clearFleetCacheData();
            setError(e);
          });
      }
    }
  };

  const setDefaults = () => {
    const rolesLoaded = userRoles.length > 0;

    if (rolesLoaded && featureFlags.isLDReady) {
      if (received && !hasDriverProfileViewRole) {
        setDefaultEntity();
        hydrateActiveEntity();
        setLoaded(true);
      }

      if (hasDriverProfileViewRole) {
        setLoaded(true);
      }
    }
  };

  const dispatchUntaggedFaces = () => {
    dispatch({
      type: UNTAGGED_COUNT_RECEIVED,
      payload: {
        count: paginatedFacesData.untaggedFacesCount ?? 0,
        hasMore: paginatedFacesData.hasMoreUntaggedFaces ?? false,
      },
    });
  };

  useEffect(fetchData, [nextFleetId, userRoles, params.fleetId]);
  useEffect(hydrateActiveEntity, [params.entityId]);
  useEffect(setDefaults, [received, userRoles, featureFlags]);
  useEffect(() => {
    if (paginatedFacesQueryStatus === 'success') {
      dispatchUntaggedFaces();
    }
  }, [paginatedFacesQueryStatus]);

  useEffect(() => {
    return () => {
      clearFleetData();
      dispatch(clearGroups());
      dispatch(clearVehiclesData());
      dispatch(clearDriversData());
    };
  }, []);
  useEffect(() => {
    if (fleets && nextFleetId) {
      dispatch(updateVeraScoreType(VeraVersionType.VERA3));
    }
  }, [params.fleetId, nextFleetId, featureFlags, fleets]);

  const shouldRedirectToDriverProfile =
    hasDriverProfileViewRole &&
    nextFleetId === activeFleetId &&
    !!activeFleetId &&
    [ROUTES.F, ROUTES.FLEET].includes(path as ROUTES);

  const shouldRedirectToEvents =
    userRoles.length === 1 &&
    userRoles[0] === ROLES.SUPERFLEET_EVENTS &&
    activeFleetId &&
    nextFleetIsSuperfleet &&
    [ROUTES.F, ROUTES.FLEET, ROUTES.EVENT, ROUTES.EVENTS].includes(
      path as ROUTES,
    );

  const shouldRedirectToSuperfleet =
    [ROUTES.F, ROUTES.FLEET].includes(path as ROUTES) &&
    activeFleetId &&
    nextFleetIsSuperfleet;

  const shouldRedirect =
    loaded &&
    nextFleetId === activeFleetId &&
    [ROUTES.F, ROUTES.FLEET].includes(path as ROUTES) &&
    !!activeFleetId &&
    !!activeGroupId;

  if (error) {
    return <Error message={error} />;
  }
  if (!nextFleetId) {
    return <Redirect to={ROUTES.NO_FLEETS} />;
  }

  if (shouldRedirectToEvents) {
    return <Redirect to={`/s/${nextFleetId}/events`} />;
  }

  if (shouldRedirectToSuperfleet) {
    return <Redirect to={`/s/${nextFleetId}/safety-assessment`} />;
  }

  if (shouldRedirectToDriverProfile) {
    return (
      <Redirect
        to={fleetLink({
          pathname: ROUTES.DRIVER_LOGIN_INSIGHTS,
          fleetId: nextFleetId,
          driverId: user.id,
        })}
      />
    );
  }

  // figure out the next page to go to based on user permissions
  if (shouldRedirect && hasPermission(Permissions.CustomUserManagement, true)) {
    const { return_to_page = 'home' } = location.state || {};

    const nextPage = getNextAvailablePage(return_to_page, hasPermission);

    if (!nextPage) {
      return <Error message={'Missing proper permissions'} />;
    }

    const page = `/f/${activeFleetId}/g/${activeGroupId}/${nextPage}${location.search}`;
    return <Redirect to={page} />;
  } else if (hasPermission(Permissions.CustomUserManagement, true)) {
    const pageSlug = path.split('/').pop();
    if (PAGE_PERMISSIONS_MAP[pageSlug]) {
      const nextPage = getNextAvailablePage(pageSlug, hasPermission);

      if (!nextPage) {
        return <Error message={'Missing proper permissions'} />;
      }

      if (nextPage !== pageSlug) {
        const page = `/f/${activeFleetId}/g/${activeGroupId}/${nextPage}`;
        return <Redirect to={page} />;
      }
    }
  }

  if (shouldRedirect) {
    const { return_to_page = 'home' } = location.state || {};
    const page = `/f/${activeFleetId}/g/${activeGroupId}/${return_to_page}${location.search}`;
    return <Redirect to={page} />;
  }

  return loaded ? (
    <ErrorBoundary>{children}</ErrorBoundary>
  ) : (
    <PreloadingScreen />
  );
};

const PAGE_PERMISSIONS_MAP = {
  home: [Permissions.Home],
  insights: [Permissions.DriverInsightsHome, Permissions.VehicleInsightsHome],
  'safety-assessment': [Permissions.DriverInsightsHome],
  'safety-scorecard': [Permissions.DriverInsightsHome],
  'manager-performance': [Permissions.DriverInsightsHome],
  'event-insights': [Permissions.VehicleInsightsHome],
  'device-insights': [Permissions.VehicleInsightsHome],
  map: [Permissions.DriverMaps, Permissions.VehicleMaps],
  events: [Permissions.DriverEvents, Permissions.VehicleEvents],
  reports: [
    Permissions.ReportsCollision,
    Permissions.ReportsAtRisk,
    Permissions.ReportsDeviceHealth,
    Permissions.ReportsRealTimeEffectiveness,
    Permissions.ReportsPolicyViolations,
    Permissions.ReportsCoachingEffectiveness,
    Permissions.ReportsTopPerforming,
    Permissions.ReportsCustomReports,
  ],
  organization: [Permissions.DriverManagement, Permissions.VehicleManagement],
  coaching: [Permissions.CustomCoaching],
  roles: [Permissions.RoleManagement],
  users: [Permissions.UserManagement],
  settings: [Permissions.FleetSettings],
  developer: [Permissions.Developer],
};

const pages = [
  'home',
  'insights',
  'safety-assessment',
  'safety-scorecard',
  'manager-performance',
  'event-insights',
  'device-insights',
  'map',
  'events',
  'reports',
  'organization',
  'coaching',
  'roles',
  'users',
  'settings',
  'developer',
];

const checkPagePermissions = (page, hasPermission) => {
  return PAGE_PERMISSIONS_MAP[page].some(
    permission => !hasPermission(permission, 'no-access'),
  );
};

const getNextAvailablePage = (startPage, hasPermission) => {
  const startPageAllowed = checkPagePermissions(startPage, hasPermission);
  if (startPageAllowed) {
    return startPage;
  }

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    if (page === startPage) {
      continue;
    }
    const pageAllowed = checkPagePermissions(page, hasPermission);

    if (pageAllowed) {
      return page;
    }
  }

  return null;
};

const selector = createStructuredSelector({
  user: ({ user }) => user.user,
  fleets: ({ user }) => user.fleets,
  activeFleetId: ({ user }) => user.currentFleet,
  isLoggedInUserDriver: isLoggedInUserTypeDriverSelector,
  activeGroupId: activeGroupIdSelector,
  activeEntityType: selectActiveEntityType,
  activeEntityId: selectActiveEntityId,
  userRoles: rolesSelector,
  rootGroup: ({ groups }) => groups.rootGroup,
});

export default connect<Partial<ConnectedProps>, Props>(selector)(FleetData);
