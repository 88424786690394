import { getToken, getLocale, getApiExtras } from 'utils/localstorage';

window.fetch = fetch;

export interface RequestOptions {
  url: string;
  data?: any;
  method?: string;
}

export interface FetchOptions {
  body?: any;
  method?: string;
  headers?: any;
  responseType?: string;
  isStreamExpected?: boolean;
}

/**
 * fetchRequest is a helper for making requests to Nauto APIs
 *
 * @param url the request url
 * @param options payload for the requesting APIs
 * @param auth sets whether authentication needed for requesting NAUTO APIs. True by default
 */
export const fetchRequest = (
  url: string,
  options: FetchOptions,
  auth = true,
): Promise<any> => {
  const token = getToken();

  if (!token && auth) {
    return Promise.reject({ success: false, message: 'Unauthorized' });
  }

  const opts: any = {
    method: options.method,
    headers: {},
    body: {},
  };

  opts.headers = {
    ...options.headers,
    ...(auth && token && { Authorization: `Bearer ${token}` }),
    'Accept-Language': getLocale(true),
  };

  opts.body = encodeBody(options.body);

  return window
    .fetch(url, opts)
    .then(response => {
      if (options.responseType === 'arraybuffer') {
        return handleFetchArraybuffer(response);
      }
      if (options.responseType === 'blob') {
        return handleFetchBlob(response);
      }
      if (options.isStreamExpected) {
        return handleResponseText(response);
      }
      return handleFetchPrebody(response);
    })
    .then(response => {
      if (
        options.responseType === 'arraybuffer' ||
        options.isStreamExpected ||
        options.responseType === 'blob'
      ) {
        return response;
      }
      return handleFetchResponse(response);
    });
};

// This will work for objects, form data, null/undefined bodies, strings
// does not check for Typed arrays and will stringify them under this logic
// List of allowable fetch body types: https://github.github.io/fetch/
const encodeBody = (body: any): any => {
  // pass through anything that's NOT a POJO and an allowed body type
  // but would still be an object
  // any POJOs should be stringified and returned
  // return anything else without touching it (undefined, null, strings)
  if (
    body instanceof FormData ||
    body instanceof URLSearchParams ||
    body instanceof Blob ||
    body instanceof ArrayBuffer
  ) {
    return body;
  } else if (body instanceof Object) {
    return body && JSON.stringify(body);
  } else {
    return body;
  }
};

/**
 * Check status codes and status messages and throw if applicable
 *
 * @param {any} response
 * @returns
 */
export async function handleFetchPrebody(response: any) {
  if (response.status < 200 || response.status >= 300) {
    const errorMessage = await response.json();
    if (errorMessage.error) {
      throw new Error(errorMessage.error);
    } else {
      return errorMessage;
    }
  }

  return parseJSON(response);
}

function parseJSON(response) {
  return response.text().then(text => {
    return text ? JSON.parse(text) : { status: 'OK' };
  });
}

export async function handleResponseText(response: any) {
  return response.text();
}

/**
 * Check status codes and status messages and throw if applicable
 * Returns an arraybuffer
 *
 * @param {any} response
 * @returns ArrayBuffer
 */
export async function handleFetchArraybuffer(response: any) {
  if (response.status < 200 || response.status >= 300) {
    const errorMessage = await response.json();
    throw new Error(response.statusText || errorMessage.error);
  }
  return response.arrayBuffer();
}

/**
 * Check status codes and status messages and throw if applicable
 * Returns a blob
 *
 * @param {any} response
 * @returns Blob
 */
export async function handleFetchBlob(response: any) {
  if (response.status < 200 || response.status >= 300) {
    const errorMessage = await response.json();
    throw new Error(response.statusText || errorMessage.error);
  }
  return response.blob();
}

/**
 * Throw an error if the API is returning one
 *
 * @param {any} response
 * @returns
 */
export const handleFetchResponse = (response: any) => {
  if (response.valid || response.success || response.status === 'OK') {
    return response;
  } else if (response.error_code || response.error_code_detailed) {
    const errorMessage = response.error_code_detailed
      ? response.error_code_detailed
      : response.error_code;
    throw new Error(errorMessage);
  } else if (response.error && !response.error_code) {
    throw new Error(response.error);
  } else {
    return response;
  }
};

// Creates an additional API request parameter if the the user has set `api/extras` in local storage.
// See NAUTO-20699
export const apiExtras = () => {
  const extras = getApiExtras();
  return !!extras && { ['_extras']: JSON.stringify(extras) };
};

// Adding this mock to be able to mock fetch requests in unit tests
export const mockedFetchRequest = (url, options) =>
  fetchRequest(url, options, false);
