import { createSelector, createStructuredSelector } from 'reselect';
import { Dispatch, Action } from 'redux';
import { fetchRequest } from 'utils/requests';
import { cacheBustUrl, path } from 'utils/helpers';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { assignFaceToDriver } from 'redux/faces';
import { Face, Driver } from 'models/db';
import { getFullName, addFullNames } from 'utils/driver-utils';
import {
  fetchGroups,
  groupDriverIDsSelector,
  Group,
} from 'components/groups/groups.redux';
import {
  isReadOnlySelector,
  currentFleetSelector,
  isGlobalAdminSelector,
  isJapaneseFleetSelector,
} from 'components/auth/auth.reducer';
import { debugSelector } from 'components/modes/modes.redux';
import { fleetFeaturesSelector } from 'components/settings/settings.redux';

export const fleetDriversSelector = ({ drivers }) => drivers.drivers;

// build a map object with driver ids as keys and drivers as values
export const indexedFleetDriversSelector = createSelector(
  fleetDriversSelector,
  drivers => drivers.reduce((obj, d) => ({ ...obj, [d.id]: d }), {}),
);

export const groupDriversSelector = createSelector<any, any, any, any>(
  indexedFleetDriversSelector,
  groupDriverIDsSelector,
  (driversMap, ids) =>
    ids.reduce((list, id) => {
      const d = driversMap[id];
      return d ? [...list, d] : list;
    }, []),
);

const compareDrivers = (d1, d2, isJapaneseFleet) => {
  const locale = isJapaneseFleet ? 'ja' : 'en';
  return (
    (isJapaneseFleet
      ? d1.last_name.localeCompare(d2.last_name, locale)
      : d1.name.localeCompare(d2.name)) || d1.id.localeCompare(d2.id)
  );
};

export const alphabeticalDriverListSelector = createSelector(
  groupDriversSelector,
  isJapaneseFleetSelector,
  (drivers, isJapaneseFleet) =>
    drivers.sort((d1, d2) => compareDrivers(d1, d2, isJapaneseFleet)),
);

export const findDriverInGroup = (group: Group, driverId: string) => {
  if (group.direct_driver_ids && group.direct_driver_ids.includes(driverId)) {
    return group.name;
  }

  return group.groups.reduce(
    (nameFound, g) => nameFound || findDriverInGroup(g, driverId),
    false,
  );
};

/**
 * selector for the drivers picker list
 */
export const driverPickerSelector = createStructuredSelector({
  drivers: fleetDriversSelector,
  fetchingDrivers: ({ drivers }) => drivers.isFetching,
  rootGroup: ({ groups }) => groups.rootGroup,
});

export const driverListSelector = createStructuredSelector({
  allDrivers: fleetDriversSelector,
  drivers: groupDriversSelector,
  createdDriverId: ({ drivers }) => drivers.createdDriverId,
  isFetching: ({ drivers }) => drivers.isFetching,
  isSubmitting: ({ drivers }) => drivers.isSubmitting,
  submitted: ({ drivers }) => drivers.submitted,
  isReadOnly: isReadOnlySelector,
  debug: debugSelector,
  fleet: currentFleetSelector,
  fleetFeatures: fleetFeaturesSelector,
  rootGroup: ({ groups }) => groups.rootGroup,
  isGlobalAdmin: isGlobalAdminSelector,
  isAssigning: ({ groups }) => groups.assigningDrivers,
  isJapaneseFleet: isJapaneseFleetSelector,
});

export const activeDriverSelector = createSelector(
  indexedFleetDriversSelector,
  ({ drivers }) => drivers.active,
  (indexedDrivers, activeId) => indexedDrivers[activeId],
);

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export enum ActionTypes {
  CLEAR_DATA = 'drivers/clear-driver-data',
  FETCHING_DRIVERS = 'drivers/fetching-drivers',
  HYDRATE_DRIVERS = 'drivers/hydrate-drivers',
  DRIVERS_LIST_FETCHED = 'drivers/driver-list-fetched',
  GET_DRIVER_DETAILS_START = 'drivers/get-driver-details-start',
  GET_DRIVER_DETAILS_SUCCESS = 'drivers/get-driver-details-success',
  GET_DRIVER_DETAILS_ERROR = 'drivers/get-driver-details-success',
  ERROR = 'drivers/error',
  DELETE_START = 'drivers/delete-driver-start',
  DELETE_ERROR = 'drivers/delete-driver-error',
  DELETE_SUCCESS = 'drivers/delete-driver-success',
  MULTIPLE_DELETE_SUCCESS = 'drivers/delete-multiple-driver-success',
  SUBMIT_START = 'drivers/submitting',
  SUBMIT_SUCCESS = 'drivers/submit-success',
  CREATE_SUCCESS = 'drivers/create-success',
  UPDATE_SUCCESS = 'drivers/update-success',
  SUBMIT_ERROR = 'drivers/submit-error',
  SET_ACTIVE_DRIVER = 'drivers/set-active-driver',
  CLEAR_ACTIVE_DRIVER = 'drivers/clear-active-driver',
  CLEAR_SUBMITTED_VALUE = 'drivers/clear-submitted-value',
  UNSUBSCRIBE_DRIVERS_START = 'drivers/unsubscribe-drivers-start',
  UNSUBSCRIBE_DRIVERS_SUCCESS = 'drivers/unsubscribe-drivers-success',
}

export const DRIVERS_LIST_FETCHED = ActionTypes.DRIVERS_LIST_FETCHED;
export const SET_ACTIVE_DRIVER = ActionTypes.SET_ACTIVE_DRIVER;

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */
export const handleError = (error, dispatch) => {
  dispatch({
    type: ActionTypes.SUBMIT_ERROR,
    payload: { error: error.message },
  });
  throw error;
};

export const getDriverDeleteError = (error: any) => {
  return {
    type: ActionTypes.DELETE_ERROR,
    payload: {
      error,
    },
  };
};

export const setActiveDriver = (driverId: string) => dispatch => {
  dispatch({
    type: ActionTypes.SET_ACTIVE_DRIVER,
    meta: { sync: true },
    payload: { driverId },
  });
};

export const clearActiveDriver = () => dispatch =>
  dispatch({
    type: ActionTypes.CLEAR_ACTIVE_DRIVER,
    meta: { sync: true, driverId: '' },
  });

export const clearDriversData = () => dispatch =>
  dispatch({
    type: ActionTypes.CLEAR_DATA,
  });

export const clearSubmittedFormValue = () => dispatch =>
  dispatch({
    type: ActionTypes.CLEAR_SUBMITTED_VALUE,
  });

/* tslint:disable:no-duplicate-string */
export const fetchDriversList = ({
  isJapaneseFleet = false,
}: {
  isJapaneseFleet?: boolean;
}) => dispatch => {
  dispatch({ type: ActionTypes.FETCHING_DRIVERS });
  const url = cacheBustUrl(
    path(`${getServiceUrl()}/fleets/${getFleetId()}/groups/all-groups/drivers`),
  );
  return new Promise((resolve, reject) => {
    fetchRequest(url, { method: 'GET' })
      .then(resp => {
        const driversWithFullNames = addFullNames(resp.data, isJapaneseFleet);
        dispatch({
          type: ActionTypes.DRIVERS_LIST_FETCHED,
          meta: { sync: true },
          payload: { drivers: driversWithFullNames },
        });
        return driversWithFullNames;
      })
      .then(drivers => resolve(drivers))
      .catch(error => {
        dispatch({ type: ActionTypes.ERROR, payload: { error } });
        reject(error);
      });
  });
};

export const hydrateDrivers = drivers => dispatch =>
  dispatch({
    type: ActionTypes.HYDRATE_DRIVERS,
    payload: {
      drivers,
    },
  });

export const deleteDriver = (id: string) => (
  dispatch: Dispatch<Action>,
  getState,
) => {
  const currentFleet = getState().user.currentFleet;
  const url = `${getServiceUrl()}/fleets/${currentFleet}/drivers/${id}`;
  const body = {
    status: 'inactive',
  };
  dispatch({ type: ActionTypes.DELETE_START });

  return fetchRequest(url, { method: 'PUT', body })
    .then(res => {
      dispatch(fetchGroups());
      return dispatch({
        type: ActionTypes.DELETE_SUCCESS,
        payload: {
          id,
        },
      });
    })
    .catch(error => {
      dispatch(getDriverDeleteError(error));
      throw new Error(error);
    });
};

export const getDriverDetails = (id: string, isJapaneseFleet = false) => (
  dispatch: Dispatch<Action>,
  getState,
) => {
  const currentFleet = getState().user.currentFleet;
  const url = `${getServiceUrl()}/fleets/${currentFleet}/drivers/${id}`;
  dispatch({ type: ActionTypes.GET_DRIVER_DETAILS_START });
  return new Promise((resolve, reject) => {
    fetchRequest(url, { method: 'GET' })
      .then(resp => {
        const driverWithFullName = {
          ...resp.data,
          name: getFullName({ driver: resp.data, isJapaneseFleet }),
        };
        dispatch({
          type: ActionTypes.GET_DRIVER_DETAILS_SUCCESS,
          payload: {
            driver: driverWithFullName,
          },
        });
        return driverWithFullName;
      })
      .then(newDriver => resolve(newDriver))
      .catch(error => reject(error));
  }).catch(error => {
    dispatch({
      type: ActionTypes.GET_DRIVER_DETAILS_ERROR,
      payload: {
        error,
      },
    });
    throw new Error(error);
  });
};

export const deleteDrivers = (ids: string[]) => (
  dispatch: Dispatch<Action>,
  getState,
) => {
  const currentFleet = getState().user.currentFleet;
  const url = `${getServiceUrl()}/fleets/${currentFleet}/drivers`;
  const body = {
    ids,
  };
  dispatch({ type: ActionTypes.DELETE_START });

  return (
    fetchRequest(url, { method: 'DELETE', body })
      .then(res => {
        dispatch(fetchGroups());
        return dispatch({
          type: ActionTypes.MULTIPLE_DELETE_SUCCESS,
          payload: ids,
        });
      })
      /* tslint:disable:no-identical-functions */
      .catch(error => {
        dispatch(getDriverDeleteError(error));
        throw new Error(error);
      })
  );
};
/* tslint:enable:no-identical-functions */
export const updateDriver = (driverID: string, data: any) => (
  dispatch: (any) => void,
) => {
  dispatch({ type: ActionTypes.SUBMIT_START });

  const url = `${getServiceUrl()}/fleets/${getFleetId()}/drivers/${driverID}`;

  return fetchRequest(url, { method: 'PUT', body: data })
    .then((response: any) => {
      return dispatch({ type: ActionTypes.UPDATE_SUCCESS, payload: response });
    })
    .catch(error => {
      return handleError(error, dispatch);
    });
};

export const addDriver = (data: any) => (dispatch: (any) => void) => {
  dispatch({ type: ActionTypes.SUBMIT_START });

  const url = `${getServiceUrl()}/fleets/${getFleetId()}/drivers`;

  return fetchRequest(url, { method: 'POST', body: data })
    .then((response: any) => {
      const newDriverID = response.data.id;
      return dispatch({
        type: ActionTypes.CREATE_SUCCESS,
        payload: newDriverID,
      });
    })
    .catch(error => {
      return handleError(error, dispatch);
    });
};

export const unsubscribeDrivers = (ids: Driver[]) => (dispatch: any) => {
  dispatch({ type: ActionTypes.UNSUBSCRIBE_DRIVERS_START });

  const url = `${getServiceUrl()}/fleets/${getFleetId()}/drivers/unsubscribe`;
  const data = { ids };

  return fetchRequest(url, { method: 'DELETE', body: data })
    .then((response: any) => {
      return dispatch({ type: ActionTypes.UNSUBSCRIBE_DRIVERS_SUCCESS });
    })
    .catch(error => {
      return handleError(error, dispatch);
    });
};
// TODO: There should be a single addDriverWithFace API
// Two api call functions for a single form submitare no bueno
export const addDriverWithFace = (data: any, face: Face) => (
  dispatch: (any) => void,
) => {
  // step 1: add this driver
  dispatch({ type: ActionTypes.SUBMIT_START });
  const url = `${getServiceUrl()}/fleets/${getFleetId()}/drivers`;

  return fetchRequest(url, { method: 'POST', body: data })
    .then((response: any) => {
      // step 2: tag the face to the newly created driver
      const newDriverID = response.data.id;
      dispatch(
        assignFaceToDriver({
          newDriverID,
          faceID: face.id,
          oldDriverID: face['driver-id'],
        }),
      );
      return newDriverID;
    })
    .then((response: any) => {
      const newDriverID = response;
      return dispatch({
        type: ActionTypes.CREATE_SUCCESS,
        payload: newDriverID,
      });
    })
    .catch(error => {
      return handleError(error, dispatch);
    });
};
/* tslint:enable:no-duplicate-string */

/**
 * ----------------------------------------------------------------------------
 * Reducer
 * ----------------------------------------------------------------------------
 */
export interface Drivers {
  drivers: Driver[];
  active: string;
  isFetching: boolean;
  isSubmitting: boolean;
  submitted: boolean;
  error: string;
  submitError: string;
}

export const defaultState = {
  drivers: [],
  active: null,
  isFetching: false,
  isFetchingDriver: true,
  error: '',
  isDeleting: false,
  deleteError: '',
  isSubmitting: false,
  submitted: false,
  submitError: '',
};

export default (state: Drivers = defaultState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_ACTIVE_DRIVER:
      return {
        ...state,
        active: payload.driverId,
      };
    case ActionTypes.CLEAR_ACTIVE_DRIVER:
      return {
        ...state,
        active: defaultState.active,
      };
    case ActionTypes.CLEAR_SUBMITTED_VALUE:
      return {
        ...state,
        submitted: false,
      };
    case ActionTypes.ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload.error,
      };
    case ActionTypes.FETCHING_DRIVERS:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.HYDRATE_DRIVERS:
      return {
        ...state,
        drivers: payload.drivers,
      };
    case ActionTypes.DRIVERS_LIST_FETCHED:
      return {
        ...state,
        isFetching: false,
        drivers: payload.drivers || [],
      };
    case ActionTypes.SUBMIT_START:
      return {
        ...state,
        isSubmitting: true,
        submitted: false,
      };
    case ActionTypes.SUBMIT_ERROR:
      return {
        ...state,
        submitError: payload.error,
        isSubmitting: false,
        submitted: false,
      };
    case ActionTypes.DELETE_START:
      return {
        ...state,
        isDeleting: true,
        deleteError: '',
      };
    case ActionTypes.DELETE_ERROR:
      return {
        ...state,
        deleteError: payload.error,
        isDeleting: false,
      };
    case ActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        deleteError: '',
        isDeleting: false,
        drivers: state.drivers.filter(driver => driver.id !== payload.id),
      };
    case ActionTypes.MULTIPLE_DELETE_SUCCESS:
      return {
        ...state,
        deleteError: '',
        isDeleting: false,
        drivers: state.drivers.filter(
          driver => payload.indexOf(driver.id) === -1,
        ),
      };
    case ActionTypes.UPDATE_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        isSubmitting: false,
        submitted: true,
        submitting: false,
        createdDriverId: payload,
        drivers: state.drivers.map(driver =>
          driver.id === data.id ? { ...driver, ...data } : driver,
        ),
      };
    }
    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        submitted: true,
        submitting: false,
        createdDriverId: payload,
      };
    case ActionTypes.CLEAR_DATA:
      return defaultState;
    default:
      return state;
  }
};
