import { createStructuredSelector, createSelector } from 'reselect';
import { fetchRequest, apiExtras } from 'utils/requests';
import { path, trimTimestamp } from 'utils/helpers';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { Event } from 'models/db';
import { TYPE } from 'constants/events';
import { getAlertTimesMs, eventVideoStart } from 'components/events/utils';
import { fleetSelector } from 'hooks/use-fleet-data/provider';
import { fleetFeaturesSelector } from 'components/settings/settings.redux';

const FALLBACK = window.location.origin + '/lib/video/fallback.ts';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export const FETCHING_PLAYLIST = 'event/fetching-media-messages';
export const PLAYLIST_RECEIVED = 'event/media-messages-received';
export const PLAYLIST_FETCH_FAILED = 'event/media-messages-fetch-failed';
export const CLEAR_PLAYLIST = 'event/clear-media';
export const SET_DURATION = 'media-player/set-duration';

// the duration of a video segment
export const SEGMENT_DURATION_SECONDS = 5;
export const SEGMENT_DURATION_MS = SEGMENT_DURATION_SECONDS * 1000;

export const NO_VIDEO_MESSAGE = {
  MISSING: 'Video still uploading',
  UPLOAD_ERROR: 'Video unavailable',
  TRANSCODE_ERROR: 'Video unavailable',
};

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

const playlistSelector = ({ featureFlags }, { media }) => ({
  internal:
    media.in_camera_sync && featureFlags.stitchedVideoPlayback
      ? [media.in_camera_sync]
      : media.in_camera,
  external:
    media.out_camera_sync && featureFlags.stitchedVideoPlayback
      ? [media.out_camera_sync]
      : media.out_camera,
});

/**
 * given playlists arrays for a video event (generated by the playlistSelector)
 * generate and return links to an m3u8 playlist file for each side.
 */
export const M3U8Selector = createSelector(playlistSelector, playlist => {
  if (!playlist) {
    return null;
  }
  const M3U8Files = { internal: null, external: null };
  ['internal', 'external'].forEach(direction => {
    if (!playlist[direction]) {
      return;
    }

    const headers = [
      '#EXTM3U\n',
      '#EXT-X-PLAYLIST-TYPE:VOD\n',
      '#EXT-X-VERSION:3\n',
      `#EXT-X-TARGETDURATION:${playlist[direction].length *
        SEGMENT_DURATION_SECONDS}\n`,
      '#EXT-X-MEDIA-SEQUENCE:0\n',
    ];
    playlist[direction].sort((a, b) => a.start_time - b.start_time);
    const tracks = playlist[direction].reduce(
      (trackLines, { url, duration, error }) => [
        ...trackLines,
        '#EXTINF:5.0,\n',
        `${url || FALLBACK}\n`,
        '#EXT-X-DISCONTINUITY\n',
      ],
      [],
    );
    const footer = '#EXT-X-ENDLIST';
    const M3U8Text = [...headers, ...tracks, footer];
    const M3U8File = new Blob(M3U8Text, {
      type: 'application/x-mpegURL',
    });
    M3U8Files[direction] = URL.createObjectURL(M3U8File);
  });
  return M3U8Files;
});

interface Playlist {
  in: any[];
  out: any[];
  meta: Record<string, unknown>;
}

export interface MediaPlayerSelectorProps {
  playlist: Playlist;
  M3U8Files: Record<string, unknown>;
  fetchingMediaMessages: boolean;
}

export interface AlertMarker {
  percentageIn: number;
  secondsIn: number;
  isMarkEvent: boolean;
}

// selector for the media-player component
export const mediaPlayerSelector = createStructuredSelector({
  playlist: playlistSelector,
  M3U8Files: M3U8Selector,
  duration: ({ mediaPlayer }) => mediaPlayer.duration,
  fetchingMediaMessages: ({ mediaPlayer }) => mediaPlayer.fetchingMediaMessages,
  fleet: fleetSelector,
  fleetFeatures: fleetFeaturesSelector,
});

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */
/* tslint:disable:no-duplicate-string */
// get the media messages associated with this event
export const getPlaylist = (event: Event) => (dispatch: (any) => void) => {
  dispatch({
    type: FETCHING_PLAYLIST,
    payload: { event },
  });
  const { device, type, id } = event;

  const data = {
    ...apiExtras(),
  };

  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/events/playlist/${device}/${type}/${id}`,
    data,
  );

  return fetchRequest(url, { method: 'GET' })
    .then((response: any) => {
      return dispatch({
        type: PLAYLIST_RECEIVED,
        payload: {
          playlist: response.data,
        },
      });
    })
    .catch(error => {
      return dispatch({ type: PLAYLIST_FETCH_FAILED, payload: { error } });
    });
};
/* tslint:enable:no-duplicate-string */
// clears out the unassigned faces
export const clearMedia = () => dispatch => dispatch({ type: CLEAR_PLAYLIST });

/**
 * Set the duration of the currently watched video
 *
 * @param {number} duration
 */
export const setDuration = (duration: number) => ({
  type: SET_DURATION,
  payload: { duration },
});

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */
export interface MediaPlayerReducer {
  // duration of current video
  duration: number;
  // whether or not we are looking up media for an event
  fetchingPlaylist: boolean;
  // true if unable to fetch media for this event
  playlistFetchError: string;
  // if the event was not found by the API
  playlist: any;
  // the event for which are fetching/have fetched media
  event: Event;
  dispatch?: any;
}

export const initialState: MediaPlayerReducer = {
  fetchingPlaylist: false,
  playlistFetchError: '',
  playlist: null,
  event: null,
  duration: 0,
};

export default (
  state = initialState,
  { type, payload },
): MediaPlayerReducer => {
  switch (type) {
    case FETCHING_PLAYLIST:
      return {
        ...initialState,
        fetchingPlaylist: true,
        event: payload.event,
      };
    case PLAYLIST_RECEIVED:
      return {
        ...state,
        fetchingPlaylist: false,
        playlist: payload.playlist,
      };
    case PLAYLIST_FETCH_FAILED:
      return {
        ...state,
        fetchingPlaylist: false,
        playlistFetchError: payload.error,
      };
    case CLEAR_PLAYLIST:
      return initialState;
    case SET_DURATION:
      return {
        ...state,
        duration: payload.duration,
      };
    default:
      return state;
  }
};
