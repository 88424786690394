import { FleetProps, FleetAccess } from 'models/db';
import * as LS from 'utils/localstorage';
import { ROLES, fleetAppAccessRoles } from 'constants/roles';
import { createSelector } from 'reselect';
import moment from 'moment';
import { getPackageName, getFeatureName } from 'components/feature/utils';

export const FLEET_COLUMN_WIDTH = {
  COIN: 88,
  NAME: 200,
  ID: 160,
  PLAN: 120,
  COUNTRY: 88,
  DATA: 88,
  MENU: 36,
  CARET: 58,
};

export const getFleetName = (fleet: FleetProps): string =>
  fleet ? fleet.alias || fleet.name || fleet.id || '' : '';

export const getFleetPlan = (fleet: FleetProps, trans: any): string =>
  trans(getPackageName(fleet.fleet_offering.package));

export const getFleetAddons = (fleet: FleetProps, trans: any): any =>
  fleet.fleet_offering.add_on.map(feature => getFeatureName(feature, trans));

export const getFleetId = (fleet: FleetProps): string => fleet?.id;

export const getCreatedDate = (fleet: FleetProps): moment.Moment =>
  fleet.created
    ? moment(fleet.created)
    : fleet.fleet_created
    ? moment(fleet.fleet_created)
    : null;

export const getFormattedCreatedDate = (fleet: FleetProps): string =>
  fleet.created
    ? moment(fleet.created).format('L')
    : fleet.fleet_created
    ? moment(fleet.fleet_created).format('L')
    : null;

export const getFleetInitials = (fleet: FleetProps): string => {
  const name = getFleetName(fleet);
  if (name.length <= 1) {
    return name.toUpperCase();
  }

  const parts = name.split(' ');

  if (parts.length === 1) {
    return name.substring(0, 2).toUpperCase();
  }
  return `${parts[0].charAt(0)}${parts[1].charAt(0)}`.toUpperCase();
};

export const getSilentCount = (fleet: FleetProps): number =>
  fleet.device_health ? fleet.device_health.device_silent : -1;

export const getLooseCount = (fleet: FleetProps): number =>
  fleet.device_health ? fleet.device_health.device_loose : -1;

export const getObstructedCount = (fleet: FleetProps): number =>
  fleet.device_health ? fleet.device_health.device_obstructed : -1;

export const getConfig = (fleet: FleetProps): any =>
  fleet.fleet_device_config || {};

export const hasDistractionRTA = (fleet: FleetProps): boolean =>
  !!getConfig(fleet).rta_distraction;

export const hasTailgatingRTA = (fleet: FleetProps): boolean =>
  !!getConfig(fleet).rta_tailgating;

export const hasAudioRecording = (fleet: FleetProps): boolean =>
  !!getConfig(fleet).audio_recording;

export const getFleetUntaggedFaces = (fleet: FleetProps): number =>
  fleet.untaggedFaces?.untaggedFaces;

export const getFleetSkippedFaces = (fleet: FleetProps): number =>
  fleet.untaggedFaces?.skippedFaces;

export const hasRole = (
  fleetAccess: FleetAccess,
  roles: ROLES | ROLES[],
): boolean => {
  const rolesArray: ROLES[] = typeof roles === 'string' ? [roles] : roles;
  if (!fleetAccess?.roles) {
    return false;
  }
  return fleetAccess.roles.some(role => rolesArray.includes(role));
};

export const isSuperfleet = (fleet: FleetProps): boolean =>
  fleet?.is_super_fleet;

export const isSuperfleetChild = (fleetAccess: FleetAccess): boolean =>
  hasRole(fleetAccess, [ROLES.SUPERFLEET_CHILD]);

const findFleetById = (fleetsAccess: FleetAccess[], id: string): FleetAccess =>
  fleetsAccess.find(({ fleet }) => fleet.id === id);

export const hasAccess = (fleets: FleetAccess[], id: string): boolean => {
  return (
    !!fleets &&
    !!id &&
    fleets.some(({ fleet, roles, role_ids = [] }) => {
      if (fleet.id !== id) {
        return false;
      }
      if (role_ids?.length) {
        return role_ids.length > 0;
      } else {
        return roles.some(role => fleetAppAccessRoles.includes(role));
      }
    })
  );
};

export const getNextFleet = ({
  fleets,
  urlFleetId,
}: {
  fleets: FleetAccess[];
  urlFleetId: string;
}): FleetAccess => {
  if (!fleets) {
    return null;
  }
  // the url fleet id is the highest override
  if (hasAccess(fleets, urlFleetId)) {
    return findFleetById(fleets, urlFleetId);
  }

  // fallback to the localstorage fleet id
  const localFleetId = LS.getCachedFleetId();
  if (hasAccess(fleets, localFleetId)) {
    return findFleetById(fleets, localFleetId);
  }

  // fallback to the first superfleet
  const superfleets = fleets.filter(fleetAccess =>
    isSuperfleet(fleetAccess.fleet),
  );
  if (superfleets.length) {
    return superfleets[0];
  }

  // fallback to the first fleet
  return fleets.find(({ fleet: { id } }) => hasAccess(fleets, id));
};

export const canAccessFleetSelector = createSelector(
  ({ user: { fleets } }) => fleets,
  (_, { fleet }) => fleet,
  (fleets, fleet) => hasAccess(fleets, getFleetId(fleet)),
);
