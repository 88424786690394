import queryString from 'query-string';
const path = '//nauto-public.s3.amazonaws.com/apps/assets/img';

export const getSubdomain = () => {
  const host = window.location.host;
  const search = queryString.parse(window.location.search);
  return search?.subdomain || host.split('.')[0];
};

const assets = {
  default: {
    logo_horizontal_green: `${path}/logo-horizontal-green.svg`,
  },
  brightdrop: {
    logo_horizontal_green: `https://brightdrop-cobrand.s3.amazonaws.com/brightDrop-nauto-Lock-up.svg`,
  },
};

export const getAssets = () => {
  const subdomain = getSubdomain();
  return assets[subdomain] || assets.default;
};

export const img = {
  vera: `${path}/vera.svg`,
  crown_badge: `${path}/crown-badge.svg`,
  nauto_3d: `${path}/nauto-3d.png`,
  driver_fallback: `${path}/driver-fallback.svg`,
  animated_logo: `${path}/loading.gif`,
  mobile_nav_logo: `${path}/02-Logo-Registered-Trademark.svg`,
  ...getAssets(),
};
