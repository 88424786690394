import React, { FC, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import i18n from './utils/i18n';
import Routes from './routes';
import { storeConfig } from './store-provider';
import GlobalContextWrapper from './components/context/global-context-wrapper';
import { ToastProvider } from 'react-toast-notifications';
import Snackbar from 'components/snackbar';
import RQWithDataProviders from './data-providers';
import { ThemeProvider } from 'styled-components';
import { getTheme } from 'utils/localstorage';
import { Themes, GlobalStyles } from 'theme';
import { getSubdomain } from 'utils/get-assets';
import { CoachSessionProvider } from 'hooks/use-coach-session-reducer';
import { activeGroupIdSelector } from 'components/groups/groups.redux';
import { isLoggedInUserTypeDriverSelector } from 'components/auth/auth.reducer';
import getAppcues from 'utils/get-appcues';
import { omit } from 'lodash';

export const TOAST_COMPONENT = { Toast: Snackbar };

const getStoredTheme = () => {
  const localTheme = getTheme();
  const domain = getSubdomain();
  const themeKeys = Object.keys(Themes);
  if (themeKeys.includes(domain)) return Themes[domain];
  if (localTheme) return localTheme;
  return Themes.default;
};

const App = () => {
  const user = useSelector(state => state.user.user);
  const activeFleetId = useSelector(state => state.user.currentFleet);
  const activeGroupId = useSelector(activeGroupIdSelector);
  const isLoggedInUserDriver = useSelector(isLoggedInUserTypeDriverSelector);
  const Appcues = getAppcues();

  useEffect(() => {
    if (user && activeFleetId && activeGroupId) {
      Appcues.identify(user.id, {
        ...omit(user, ['id', 'password', 'phone']),
        fleetId: activeFleetId,
        groupId: activeGroupId,
        isLoggedInUserDriver,
      });
    }
  }, [user, activeFleetId, activeGroupId, isLoggedInUserDriver, Appcues]);

  return (
    <ToastProvider
      components={TOAST_COMPONENT}
      autoDismiss={true}
      placement="bottom-center"
    >
      <React.Fragment>
        <Route component={Routes} />
      </React.Fragment>
    </ToastProvider>
  );
};

/**
 * <Portals /> is used as a node for "react-portal" to render
 * children in. This allows for context sharing and not having
 * to wrap each portal with providers
 */
const Portals = () => <div id="portal-tooltips" />;

const FleetApp: FC = () => {
  const [theme, setTheme] = useState(Themes.default);
  const [themeLoaded, setThemeLoaded] = useState(false);

  useEffect(() => {
    const storedTheme = getStoredTheme();
    setTheme(storedTheme);
    setThemeLoaded(true);
  }, []);

  return (
    <>
      {themeLoaded && (
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Provider store={storeConfig()}>
            <I18nextProvider i18n={i18n}>
              <GlobalContextWrapper>
                <BrowserRouter>
                  <RQWithDataProviders>
                    <CoachSessionProvider>
                      <App />
                    </CoachSessionProvider>
                    <Portals />
                  </RQWithDataProviders>
                </BrowserRouter>
              </GlobalContextWrapper>
            </I18nextProvider>
          </Provider>
        </ThemeProvider>
      )}
    </>
  );
};

export default FleetApp;
