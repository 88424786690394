import { useQuery } from 'react-query';
import * as API from '@nauto/api';
import { THIRTY_MINUTES } from 'hooks/queries/constants';
import { ReportRowAPIData } from 'components/reports/types';
import { GridReportEntityType } from 'components/entity-type/entity-type-utils';
import { REPORT_QUERIES } from 'constants/query-keys';
import { Entities, QueryParams, QueryOpts } from './types';
import { QueryResult } from './use-reports';

const KEY_MAP = {
  [API.reports.EntityType.DRIVER]: REPORT_QUERIES.DRIVER,
  [API.reports.EntityType.VEHICLE]: REPORT_QUERIES.VEHICLE,
  [GridReportEntityType.Subfleets]: REPORT_QUERIES.GROUP,
};

const getQueryKey = (entity: Entities) => KEY_MAP[entity];

export const useReportQuery = (
  keys: QueryParams,
  opts?: QueryOpts,
): QueryResult => {
  const getReportData = async (): Promise<ReportRowAPIData[]> => {
    const { data } = await API.reports.getReport(keys);

    return data as ReportRowAPIData[];
  };

  const QUERY_KEY = getQueryKey(keys.entity);
  const query = useQuery([QUERY_KEY, keys], getReportData, {
    ...opts,
    cacheTime: THIRTY_MINUTES,
    staleTime: Infinity,
  });

  return query;
};
