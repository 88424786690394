export const MAP_TIMELINE_MAX_DAYS = 14;

// constants used to build react-calendar-timeline:
// https://www.npmjs.com/package/react-calendar-timeline

export const HEADER_LABEL_FORMATS = {
  yearShort: 'YY z',
  yearLong: 'YYYY z',
  monthShort: 'MM/YY z',
  monthMedium: 'MM/YYYY z',
  monthMediumLong: 'MMM YYYY z',
  monthLong: 'MMMM YYYY z',
  dayShort: 'L z',
  dayLong: 'LL z',
  hourShort: 'ha z',
  hourMedium: 'h:00a z',
  hourMediumLong: 'MMMM D, h:00a z',
  hourLong: 'ddd MMMM D, h:00a z',
  time: 'LLL z',
};

export const SUBHEADER_LABEL_FORMATS = {
  yearShort: 'YY',
  yearLong: 'YYYY',
  monthShort: 'MM',
  monthMedium: 'MMM',
  monthLong: 'MMMM',
  dayShort: 'D',
  dayMedium: 'dd D',
  dayMediumLong: 'ddd, D',
  dayLong: 'dddd, D',
  hourShort: 'ha',
  hourLong: 'h:00a',
  minuteShort: ':mm',
  minuteLong: 'h:mm',
};

const localizeObj = (o: Record<string, unknown>) =>
  Object.keys(o).reduce(
    (newObj, k) => ({
      ...newObj,
      [k]: o[k].replace('h', 'H').replace('a', ''),
    }),
    {},
  );

const HEADER_LABEL_FORMATS_24_HOUR = localizeObj(HEADER_LABEL_FORMATS);
const SUBHEADER_LABEL_FORMATS_24_HOUR = localizeObj(SUBHEADER_LABEL_FORMATS);

export const KEYS = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title', // key for item div content
  itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
  itemTimeStartKey: 'start_time',
  itemTimeEndKey: 'end_time',
};

export const MAX_ZOOM = {
  IN: 1 * 60 * 1000,
  OUT: 31 * 86400 * 1000,
};

export const defaultTimelineProps = (as24Hour: boolean) => ({
  fixedHeader: 'fixed',
  maxZoom: MAX_ZOOM.OUT,
  minZoom: MAX_ZOOM.IN,
  canMove: false, // trips and events are not editable
  canResize: false, // trips and events are not editable
  dragSnap: 1000, // smallest element is 1 second long
  headerLabelFormats: as24Hour
    ? HEADER_LABEL_FORMATS_24_HOUR
    : HEADER_LABEL_FORMATS,
  subHeaderLabelFormats: as24Hour
    ? SUBHEADER_LABEL_FORMATS_24_HOUR
    : SUBHEADER_LABEL_FORMATS,
});

export const SCRUBBER_ID = 'SCRUBBER_ID';

export const ASSIGNMENT_TYPE = {
  DRIVER: 'driver', // a named driver
  SYNTHETIC: 'synthetic', // an unnamed driver (has face snapshots that can be tagged)
  NONDRIVER: 'nondriver', // has been explicitely tagged as a non-driver person
  NONE: '', // no driver assignment has been made
};
