import { createStructuredSelector } from 'reselect';
import { fetchRequest, apiExtras } from 'utils/requests';
import { path } from 'utils/helpers';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { Event, Trip } from 'models/db';
import * as TripUtils from 'utils/trip-utils';
import get from 'lodash-es/get';
import { debugSelector } from 'components/modes/modes.redux';
import { activeGroupSelector } from 'components/groups/groups.redux';
import { selectFacesWithVehiclesAndGroups } from 'redux/faces';
import * as API from '@nauto/api';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export enum ACTIONS {
  FETCHING_TRIP_SNAPSHOTS = 'tag-trip/fetching-trip-snapshots',
  TRIP_SNAPSHOTS_RECEIVED = 'tag-trip/trip-snapshots-received',
  TRIP_SNAPSHOTS_ERROR = 'tag-trip/trip-snapshots-received',
  CLEAR_TRIP_SNAPSHOTS = 'tag-trip/clear-taggable-trip',
}
/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

export const tripSnapshotsSelector = createStructuredSelector({
  debug: debugSelector,
  snapshots: ({ tripSnapshots }) => tripSnapshots.snapshots,
  isFetching: ({ tripSnapshots }) => tripSnapshots.fetchingTripSnapshots,
  error: ({ tripSnapshots }) => tripSnapshots.error,
  groups: ({ groups }) => groups,
  activeGroup: activeGroupSelector,
  untaggedFaces: selectFacesWithVehiclesAndGroups,
  suggestions: ({ faces }) => faces.suggestions,
  fetchingSuggestions: ({ faces }) => faces.fetchingSuggestions,
});
/**
 * ---------------------------------------------- ------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */
/* tslint:disable:no-duplicate-string */
// get all untagged faces
export const getTripSnapshots = (trip: Trip) => (dispatch: (any) => void) => {
  // gets the vehicle state messages for the given time range in data
  dispatch({
    type: ACTIONS.FETCHING_TRIP_SNAPSHOTS,
    payload: { trip },
  });

  return API.trips
    .getTripSnapshots({
      fleetId: getFleetId(),
      min: TripUtils.getStartTime(trip).valueOf(),
      max: TripUtils.getEndTime(trip).valueOf(),
      apiExtras,
      trip,
    })
    .then((response: any) => {
      // map the response to just the signed urls from the snapshot-in messages
      const snapshots = get(response, 'data[0]._ts[snapshot-in]', []).map(
        message => message.data,
      );
      dispatch({
        type: ACTIONS.TRIP_SNAPSHOTS_RECEIVED,
        payload: { snapshots },
      });
    })
    .catch(error => {
      dispatch({ type: ACTIONS.TRIP_SNAPSHOTS_ERROR, payload: error });
    });
};
/* tslint:enable:no-duplicate-string */
// clears out the unassigned faces
export const clearTripSnapshots = () => dispatch =>
  dispatch({ type: ACTIONS.CLEAR_TRIP_SNAPSHOTS });

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */
export interface FacesReducer {
  fetchingTripSnapshots: boolean;
  trip: Trip;
  snapshots: Event[];
  error: string;
}

export const initialState: FacesReducer = {
  fetchingTripSnapshots: false,
  trip: null,
  snapshots: [],
  error: '',
};

export default (state = initialState, { type, payload }): FacesReducer => {
  switch (type) {
    case ACTIONS.FETCHING_TRIP_SNAPSHOTS:
      return {
        ...state,
        trip: payload.trip,
        fetchingTripSnapshots: true,
        snapshots: initialState.snapshots,
      };
    case ACTIONS.TRIP_SNAPSHOTS_RECEIVED:
      return {
        ...state,
        fetchingTripSnapshots: false,
        snapshots: payload.snapshots,
      };
    case ACTIONS.TRIP_SNAPSHOTS_ERROR:
      return {
        ...state,
        fetchingTripSnapshots: false,
        error: payload.error,
      };
    case ACTIONS.CLEAR_TRIP_SNAPSHOTS:
      return initialState;
    default:
      return state;
  }
};
