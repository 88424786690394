import moment from 'moment';
import { DispatchType, EventAPIResponse } from 'models/generics';
import {
  DEFAULT_COACHING_DAYS,
  LIMIT,
} from 'components/events/events-constants';
import { fetchRequest } from 'utils/requests';
import { path } from 'utils/helpers';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { TYPES_FOR_FILTER } from 'constants/events';
import { AnyAction } from 'redux';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */

const GET_COACHABLE_EVENTS_START = 'coaching/get-events-start';
const GET_COACHABLE_EVENTS_SUCCESS = 'coaching/get-events-success';
const GET_COACHABLE_EVENTS_ERROR = 'coaching/get-events-error';
const GET_MORE_COACHABLE_EVENTS_START = 'coaching/get-more-events-start';
const SET_RANGE_FILTER = 'coaching/set-range-filter';
const CHANGE_TYPE_GROUPS = 'coaching/change-type-groups';
const COACHING_STATUS_UPDATE_START = 'coaching/event-status-update-start';
const COACHING_STATUS_UPDATE_END = 'coaching/event-status-update-success';
const COACHING_STATUS_UPDATE_ERROR = 'coaching/event-status-update-error';
const COACHING_EVENT_FILTERS_ADD_BRAKING = 'coaching/event-filters-add-braking';

export const CoachingStatus = {
  COACHED: 'coached',
  COACHABLE: 'coachable',
  UNCOACHED: 'uncoached',
  UNCOACHABLE: 'uncoachable',
};

/**
 * ----------------------------------------------------------------------------
 * SELECTORS
 * ----------------------------------------------------------------------------
 */

/**
 * ----------------------------------------------------------------------------
 * ACTIONS
 * ----------------------------------------------------------------------------
 */
/* tslint:disable:bool-param-default */
export const getCoachableEvents = (
  groupId: string,
  status?: string,
  paginatedFetch = false,
) => (dispatch: (any) => void, getState) => {
  /* tslint:enable:bool-param-default */
  dispatch({
    type: paginatedFetch
      ? GET_MORE_COACHABLE_EVENTS_START
      : GET_COACHABLE_EVENTS_START,
  });
  const {
    drivers: { active },
    coaching: {
      eventFetchDate,
      pageNumber,
      eventFilters: { startDate, endDate, typeGroups },
    },
  } = getState();
  const eventTypes =
    typeGroups &&
    typeGroups
      .filter(x => x.checked)
      .map(f => f.id)
      .reduce(
        (eventKeys, typeGroup) => [
          ...eventKeys,
          ...TYPES_FOR_FILTER[typeGroup],
        ],
        [],
      );
  const eventDate = (paginatedFetch ? eventFetchDate : endDate)?.clone();
  const page = paginatedFetch ? pageNumber : 0;
  const params: any = {
    '_ts._min': `ms:${+eventDate.startOf('day')}`,
    '_ts._max': `ms:${+eventDate.endOf('day')}`,
    '_ts._order': 'desc',
    '_ts._offset': page * LIMIT,
    '_ts._limit': LIMIT,
    '_ts._format': 'object',
    _ts: eventTypes,
    group: groupId,
    driver: active,
    coaching_status: status,
    fetchHash: moment()
      .valueOf()
      .toString(),
  };

  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/events/coachable`,
    params,
  );
  return fetchRequest(url, { method: 'GET' })
    .then((response: any) => {
      const events = typeof response.data === 'string' ? [] : response.data;
      const endOfDayReached = response.end_of_results;
      dispatch({
        type: GET_COACHABLE_EVENTS_SUCCESS,
        payload: {
          events,
          pageNumber: endOfDayReached ? 0 : page + 1,
          lastDateFetched: endOfDayReached
            ? eventDate?.clone()?.subtract(1, 'd')
            : eventDate?.clone(),
          allEventsFetched:
            eventDate.startOf('day').isSame(startDate.startOf('day')) &&
            endOfDayReached,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: GET_COACHABLE_EVENTS_ERROR,
        payload: { error },
      });
    });
};

export const markCoachingEvent = ({
  eventKey,
  coaching_status,
  coachable_notes,
}) => (dispatch: (any) => Promise<AnyAction>) => {
  dispatch({ type: COACHING_STATUS_UPDATE_START });
  const url = `${getServiceUrl()}/fleets/${getFleetId()}/events/coachable/${eventKey}`;
  const options = {
    method: 'POST',
    body: {
      is_coachable: true,
      coaching_status,
      status: coaching_status,
      coachable_notes,
      notes: coachable_notes,
    },
  };

  return fetchRequest(url, options)
    .then((response: any) => {
      return dispatch({
        type: COACHING_STATUS_UPDATE_END,
        success: true,
        payload: { ...response, eventKey },
      });
    })
    .catch(error => {
      return dispatch({
        type: COACHING_STATUS_UPDATE_ERROR,
        success: false,
        payload: { error },
      });
    });
};

export const setRangeFilter = ({ startDate, endDate }) => (
  dispatch: (any) => AnyAction,
) => {
  return dispatch({
    type: SET_RANGE_FILTER,
    payload: {
      startDate: startDate.startOf('day'),
      endDate: endDate.endOf('day'),
    },
  });
};

export const toggleTypeGroupsFilter = groupId => (
  dispatch: (any) => AnyAction,
) => {
  return dispatch({
    type: CHANGE_TYPE_GROUPS,
    payload: { id: groupId },
  });
};

export const addBrakingEventFilter = () => dispatch => {
  return dispatch({
    type: COACHING_EVENT_FILTERS_ADD_BRAKING,
    payload: {
      filter: {
        id: 'Braking',
        name: 'Braking',
        checked: true,
      },
    },
  });
};

/**
 * ----------------------------------------------------------------------------
 * REDUCERS
 * ----------------------------------------------------------------------------
 */

export interface EventOptions {
  id: string;
  name?: string;
  checked?: boolean;
}

export interface Filters {
  typeGroups?: EventOptions[];
  startDate?: moment.Moment;
  endDate?: moment.Moment;
}

export interface EventReducer {
  events: EventAPIResponse[]; // the fetched events that match the current filter
  isFetching: boolean; // true if the end of all results has been fetched
  error: string; // whether an error occurred fetching events
  eventFilters: Filters; // the current filter set
  eventFetchDate?: moment.Moment;
  pageNumber: number;
  allEventsFetched: boolean;
}

export const defaultState: EventReducer = {
  events: [],
  error: '',
  isFetching: false,
  pageNumber: 0,
  allEventsFetched: false,
  eventFilters: {
    typeGroups: [
      {
        id: 'distraction',
        checked: true,
      },
      {
        id: 'collision',
        checked: true,
      },
    ],
    startDate: moment()
      .subtract(DEFAULT_COACHING_DAYS, 'd')
      .startOf('day'),
    endDate: moment(),
  },
};

export default (state = defaultState, action: DispatchType) => {
  const { payload, type } = action;
  switch (type) {
    case GET_COACHABLE_EVENTS_START:
      return {
        ...state,
        isFetching: true,
        allEventsFetched: false,
        events: [],
      };
    case GET_MORE_COACHABLE_EVENTS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_COACHABLE_EVENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        eventFetchDate: payload.lastDateFetched,
        pageNumber: payload.pageNumber,
        allEventsFetched: payload.allEventsFetched,
        events: [...state.events, ...payload.events],
      };
    case GET_COACHABLE_EVENTS_ERROR:
      return {
        ...state,
        isFetching: false,
        events: [],
        error: payload.error,
      };
    case COACHING_STATUS_UPDATE_END:
      return {
        ...state,
        events: state.events.filter(
          event => event.event_key !== payload.eventKey,
        ),
      };
    case CHANGE_TYPE_GROUPS: {
      const newTypeGroups = state.eventFilters.typeGroups.map(group => {
        if (group.id === payload.id) {
          group.checked = !group.checked;
        }
        return group;
      });
      return {
        ...state,
        eventFilters: {
          ...state.eventFilters,
          typeGroups: newTypeGroups,
        },
      };
    }
    case SET_RANGE_FILTER: {
      const newRangeFilters: Filters = {
        ...state.eventFilters,
        ...payload,
      };
      return {
        ...state,
        eventFilters: newRangeFilters,
      };
    }
    case COACHING_EVENT_FILTERS_ADD_BRAKING:
      return {
        ...state,
        eventFilters: {
          ...state.eventFilters,
          typeGroups: [...state.eventFilters.typeGroups, payload.filter],
        },
      };
  }
  return state;
};
