import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import * as API from '@nauto/api';
import { getLocale } from './localstorage';

const options: any = {
  initImmediate: true,
  debug: false,
  fallbackLng: {
    // Chrome and firefox fix
    en: ['en-US'],
    de: ['de-DE'],
    ja: ['ja-JP'],
    fr: ['fr-CA'],
    default: ['en-US'],
  },
  keySeparator: false,
  nsSeparator: '~',
  ns: ['translation', 'questionnaires', 'variables'],
  backend: {
    loadPath:
      'https://s3.us-east-1.amazonaws.com/nauto-prod-us-fleet-app-translations/{{lng}}/{{ns}}.json',
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
};

i18n.on('languageChanged', lng => {
  // If language changed to de-DE, will default back to en-US
  if (i18n.language === 'de-DE' || i18n.language === 'de') {
    i18n.changeLanguage('en-US');
  }
  moment.locale(lng);
  API.setOptions({ locale: getLocale(true) });
});

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init(options);

export default i18n;
