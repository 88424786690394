export enum ROLES {
  SYSADMIN = '0',
  GLOBAL_INSTALLER = '1',
  MANUFACTURER = '2',
  REVIEWER = '3',
  SERVICE = '6',
  NETSUITE = '10',
  SUPERFLEET_CHILD = '15',
  GLOBAL_ADMIN = '21',
  CONFIG_VIEWER = '100',
  CONFIG_EDITOR = '101',
  CONFIG_PUSHER = '102',
  PROFILE_VIEWER = '110',
  PROFILE_EDITOR = '111',
  FLEET_ADMIN = '4',
  FLEET_MANAGER = '5',
  HIDDEN_SUPPORT_FLEET_ADMIN = '7',
  FLEET_DATA_SERVICE_ADMIN = '200',
  FLEET_DATA_SERVICE = '50',
  FLEET_INSTALLER = '300',
  SUPERFLEET_FULL_ACCESS = '12',
  SUPERFLEET_SUMMARY = '13',
  SUPERFLEET_EVENTS = '14',
  DRIVER_ID_DEBUG_TOOL = '120',
  DRIVER_PROFILE_VIEWER = '8',
}

export const roleKeys = {
  GLOBAL_ADMIN: ROLES.GLOBAL_ADMIN,
  SUPPORT_ADMIN: ROLES.HIDDEN_SUPPORT_FLEET_ADMIN,
  FLEET_ADMIN: ROLES.FLEET_ADMIN,
  FLEET_MANAGER: ROLES.FLEET_MANAGER,
  FLEET_INSTALLER: ROLES.FLEET_INSTALLER,
  FLEET_DATA_SERVICE: ROLES.FLEET_DATA_SERVICE,
  GLOBAL_INSTALLER: ROLES.GLOBAL_INSTALLER,
  CONFIG_PUSHER: ROLES.CONFIG_PUSHER,
  CONFIG_EDITOR: ROLES.CONFIG_EDITOR,
  CONFIG_VIEWER: ROLES.CONFIG_VIEWER,
  SUPERFLEET_FULL_ACCESS: ROLES.SUPERFLEET_FULL_ACCESS,
  SUPERFLEET_SUMMARY: ROLES.SUPERFLEET_SUMMARY,
  SUPERFLEET_EVENTS: ROLES.SUPERFLEET_EVENTS,
  SUPERFLEET_CHILD: ROLES.SUPERFLEET_CHILD,
  DRIVER_ID_DEBUG_TOOL: ROLES.DRIVER_ID_DEBUG_TOOL,
  DRIVER_PROFILE_VIEWER: ROLES.DRIVER_PROFILE_VIEWER,
};

export const subfleetAccessRoles = [ROLES.FLEET_MANAGER];

export const internalRoles = {
  [ROLES.GLOBAL_ADMIN]: true,
  [ROLES.GLOBAL_INSTALLER]: true,
  [ROLES.CONFIG_VIEWER]: true,
  [ROLES.CONFIG_EDITOR]: true,
  [ROLES.CONFIG_PUSHER]: true,
  [ROLES.DRIVER_ID_DEBUG_TOOL]: true,
  [ROLES.FLEET_ADMIN]: true,
  [ROLES.SUPERFLEET_SUMMARY]: true,
  [ROLES.SUPERFLEET_FULL_ACCESS]: true,
  [ROLES.SUPERFLEET_EVENTS]: true,
};

export const globalRoles = [
  ROLES.GLOBAL_ADMIN,
  ROLES.GLOBAL_INSTALLER,
  ROLES.CONFIG_VIEWER,
  ROLES.CONFIG_EDITOR,
  ROLES.CONFIG_PUSHER,
  ROLES.DRIVER_ID_DEBUG_TOOL,
];

/* a user can only have one of these roles per fleet */
export const conflictingRoles = [
  ROLES.FLEET_ADMIN,
  ROLES.FLEET_MANAGER,
  ROLES.HIDDEN_SUPPORT_FLEET_ADMIN,
  ROLES.CONFIG_VIEWER,
  ROLES.SUPERFLEET_SUMMARY,
  ROLES.SUPERFLEET_SUMMARY,
];

export const managedRoleRules = {
  [roleKeys.GLOBAL_ADMIN]: {
    canInviteHiddenUsers: true,
    canInviteUsers: true,
    canInviteGlobalUsers: true,
    isGlobal: true,
  },
  [roleKeys.SUPPORT_ADMIN]: {
    isHiddenRole: true,
    canInviteUsers: true,
  },
  [roleKeys.FLEET_ADMIN]: { canInviteUsers: true },
  [roleKeys.FLEET_MANAGER]: { subfleetAccess: true },
  [roleKeys.FLEET_INSTALLER]: { isHiddenRole: true },
  [roleKeys.SUPERFLEET_CHILD]: { canInviteUsers: true },
  [roleKeys.GLOBAL_INSTALLER]: { isGlobal: true },
  [roleKeys.CONFIG_PUSHER]: { isGlobal: true },
  [roleKeys.CONFIG_EDITOR]: { isGlobal: true },
  [roleKeys.CONFIG_VIEWER]: { isGlobal: true },
  [roleKeys.SUPERFLEET_FULL_ACCESS]: { isSuperfleetRole: true },
  [roleKeys.SUPERFLEET_EVENTS]: { isSuperfleetRole: true },
  [roleKeys.SUPERFLEET_SUMMARY]: { isSuperfleetRole: true },
  [roleKeys.DRIVER_ID_DEBUG_TOOL]: { isGlobal: true },
  [roleKeys.FLEET_DATA_SERVICE]: {
    isGlobal: false,
    isHiddenRole: true,
    canInviteUsers: false,
  },
};

export const managedRoles = [
  ROLES.FLEET_ADMIN,
  ROLES.FLEET_MANAGER,
  ROLES.HIDDEN_SUPPORT_FLEET_ADMIN,
  ROLES.FLEET_INSTALLER,
  ROLES.FLEET_DATA_SERVICE,
  ROLES.GLOBAL_ADMIN,
  ROLES.GLOBAL_INSTALLER,
  ROLES.CONFIG_PUSHER,
  ROLES.CONFIG_EDITOR,
  ROLES.CONFIG_VIEWER,
  ROLES.SUPERFLEET_FULL_ACCESS,
  ROLES.SUPERFLEET_SUMMARY,
  ROLES.SUPERFLEET_EVENTS,
  ROLES.DRIVER_ID_DEBUG_TOOL,
];

export const fleetAppAccessRoles = [
  ROLES.FLEET_ADMIN,
  ROLES.FLEET_MANAGER,
  ROLES.HIDDEN_SUPPORT_FLEET_ADMIN,
  ROLES.GLOBAL_ADMIN,
  ROLES.SUPERFLEET_FULL_ACCESS,
  ROLES.SUPERFLEET_SUMMARY,
  ROLES.SUPERFLEET_EVENTS,
  ROLES.SUPERFLEET_CHILD,
  ROLES.DRIVER_PROFILE_VIEWER,
];

export enum ROLE_VALUES {
  TRUE = 'true',
  FALSE = 'false',
  FULL_ACCESS = 'full-access',
  READ_ONLY = 'read-only',
  NO_ACCESS = 'no-access',
}

export const isGlobal = (role: ROLES): boolean =>
  managedRoleRules[role]?.isGlobal;

export const isSuperfleetRole = (role: ROLES): boolean =>
  managedRoleRules[role]?.isSuperfleetRole;

export const canInviteHiddenUsers = (role: ROLES): boolean =>
  managedRoleRules[role]?.canInviteHiddenUsers;

export const canInviteUsers = (role: ROLES): boolean =>
  managedRoleRules[role]?.canInviteUsers;

export const hasSubfleetAccess = (role: ROLES): boolean =>
  managedRoleRules[role] && subfleetAccessRoles.includes(role);

export const isHiddenRole = (role: ROLES): boolean =>
  managedRoleRules[role]?.isHiddenRole;

export enum LoggedInUserType {
  USER = 'user',
  DRIVER = 'driver',
}

export const V2_GLOBAL_INSTALLER_FLEET_ID = 'f-admin';
