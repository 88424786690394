import i18next from 'i18next';
import { AlertType } from 'constants/alert-types';
import { get, isNil } from 'lodash-es';
import {
  AlertNotification,
  AlertResponseItem,
  AlertResponseRule,
  AlertResponse,
  RuleProperty,
  FilterType,
  Thresholds,
} from './types';

/**
 * Utility for converting specific alerts in the Alerts response to
 * a flattened list of notifications
 *
 * @param {AlertResponse} { alerts }
 * @param {AlertType[]} types
 */
export const mapAlertResponseToNotifications = (
  { alerts }: AlertResponse,
  types: AlertType[],
) =>
  Object.entries(alerts)
    .filter(([key, _]) => types.includes(key as AlertType))
    .map(([_, val]) => mapAlertToNotifications(val))
    .reduce((flattened, notifications) => [...flattened, ...notifications], []);

/**
 * Helper for `mapAlertResponseToNotifications to convert each alert
 * into a useable notification
 *
 * @param {AlertResponseItem} alert
 * @returns {AlertNotification[]}
 */
export const mapAlertToNotifications = (
  alert: AlertResponseItem,
): AlertNotification[] => {
  const rules = get(alert, 'properties.rules');
  if (!rules) {
    return [];
  }

  return rules.map(
    (r: AlertResponseRule): AlertNotification => ({
      type: alert.short_desc,
      id: r.id,
      name: r.name,
      enabled: r.enabled,
      rule: r,
    }),
  );
};

export const deriveInitialValues = (alert: AlertNotification) => {
  const distractionDuration =
    alert && alert.type === AlertType.Distraction
      ? alert.rule[RuleProperty.MinDuration]
      : Thresholds[AlertType.Distraction].Lower;
  const tailgatingDuration =
    alert && alert.type === AlertType.Tailgating
      ? alert.rule[RuleProperty.MinDuration]
      : Thresholds[AlertType.Tailgating].Lower;
  const brakingGForce =
    alert && alert.type === AlertType.Braking
      ? alert.rule[RuleProperty.MinGForce]
      : Thresholds[AlertType.Braking].Lower;

  return {
    alertName: alert ? alert.rule.name : '',
    alertType: alert ? alert.type : AlertType.Distraction,
    enabled: alert ? alert.rule.enabled : true,
    [AlertType.Distraction]: {
      [RuleProperty.MinDuration]: distractionDuration,
    },
    [AlertType.Tailgating]: {
      [RuleProperty.MinDuration]: tailgatingDuration,
    },
    [AlertType.Braking]: {
      [RuleProperty.MinGForce]: brakingGForce,
    },
  };
};

export const deriveSelectionText = (alert: AlertNotification): string => {
  const count = alert?.rule?.selected?.length;

  if (!count && count !== 0) {
    return i18next.t('Entire company');
  }
  const alertMap = {
    [FilterType.Drivers]: i18next.t('{{count}} drivers', { count }),
    [FilterType.Vehicles]: i18next.t('{{count}} vehicles', { count }),
    [FilterType.Subfleets]: i18next.t('{{count}} subfleets', { count }),
  };
  return alertMap[alert.rule.filter];
};

export const generateFieldName = (
  alert: AlertType,
  rule: RuleProperty,
): string => `${alert}.${rule}`;
