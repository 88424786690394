import { Action, Dispatch } from 'redux';
import moment from 'moment';
import { fetchRequest } from 'utils/requests';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import * as API from '@nauto/api';

export interface SensorData {
  timestamp: string;
  url: string;
}

export interface RequestTimestamp {
  start: moment.Moment;
  end: moment.Moment;
}

export interface ReducerState {
  updating: boolean;
  error: string;
  list: SensorData[];
  fetching: boolean;
}

export const initialState: ReducerState = {
  updating: false,
  fetching: false,
  list: [],
  error: '',
};

export enum Constants {
  GET_SENSOR_DATA_LIST_START = 'sensor-data/get-list-start',
  GET_SENSOR_DATA_LIST_END = 'sensor-data/get-list-end',
  GET_SENSOR_DATA_LIST_ERROR = 'sensor-data/get-list-error',
  SEND_SENSOR_DATA_REQUEST_START = 'sensor-data/start',
  SEND_SENSOR_DATA_REQUEST_ERROR = 'sensor-data/error',
  SEND_SENSOR_DATA_REQUEST_END = 'sensor-data/success',
}
/* tslint:disable:no-duplicate-string */
export const sendSensorDataRequest = (
  deviceId: string,
  startTime: moment.Moment,
  endTime: moment.Moment,
) => (dispatch: Dispatch<Action>) => {
  dispatch({ type: Constants.SEND_SENSOR_DATA_REQUEST_START });

  return API.admin
    .sendAdminCommand({
      fleetId: getFleetId(),
      deviceId,
      messageType: 'upload_sensor_data',
      messageBody: {
        'ts-min': startTime.valueOf(),
        'ts-max': endTime.valueOf(),
      },
    })
    .then(response => {
      return dispatch({
        type: Constants.SEND_SENSOR_DATA_REQUEST_END,
        payload: { ...response },
      });
    })
    .catch(error => {
      return dispatch({
        type: Constants.SEND_SENSOR_DATA_REQUEST_ERROR,
        payload: { error: error.message },
      });
    });
};

export const getSensorDataForDevice = (deviceId: string) => (
  dispatch: Dispatch<Action>,
) => {
  const url = `${getServiceUrl()}/admin/device/${deviceId}/data?type=sensor`;

  dispatch({ type: Constants.GET_SENSOR_DATA_LIST_START });

  return fetchRequest(url, { method: 'GET' })
    .then(response => {
      return dispatch({
        type: Constants.GET_SENSOR_DATA_LIST_END,
        payload: [...response.data],
      });
    })
    .catch(error => {
      return dispatch({
        type: Constants.GET_SENSOR_DATA_LIST_ERROR,
        payload: { error: error.message },
      });
    });
};
/* tslint:enable:no-duplicate-string */
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case Constants.GET_SENSOR_DATA_LIST_START:
      return { ...state, fetching: true, error: '' };

    case Constants.GET_SENSOR_DATA_LIST_END:
      return { ...state, fetching: false, error: '', list: payload };

    case Constants.GET_SENSOR_DATA_LIST_ERROR:
      return { ...state, fetching: false, error: payload.error };

    case Constants.SEND_SENSOR_DATA_REQUEST_START:
      return {
        ...state,
        updating: true,
        error: '',
      };

    case Constants.SEND_SENSOR_DATA_REQUEST_ERROR:
      return {
        ...state,
        updating: false,
        error: payload.error,
      };

    case Constants.SEND_SENSOR_DATA_REQUEST_END:
      return {
        ...state,
        updating: false,
        error: '',
      };

    default:
      return state;
  }
};
