import moment, { Moment } from 'moment';
import { createStructuredSelector, createSelector } from 'reselect';
import uniqBy from 'lodash-es/uniqBy';
import groupBy from 'lodash-es/groupBy';
import * as API from '@nauto/api';
import { Flags } from 'components/feature-flags/flags';
import { FleetSettings } from 'components/settings/settings.redux';
import { DEFAULT_DATA } from 'components/feature/constants';
import { fetchRequest, apiExtras } from '../utils/requests';
import { path } from '../utils/helpers';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { EntityType } from 'components/entity-type/entity-type-utils';
import {
  selectActiveEntityType,
  selectActiveEntityId,
  selectActiveEntity,
} from 'components/entity-type/active-entity-type.redux';
import { Trip, Driver, Features } from '../models/db';
import { getStartTime, getFullEndTime } from '../utils/trip-utils';
import {
  setActiveDriver,
  indexedFleetDriversSelector,
} from 'components/organization/drivers/drivers.redux';
import {
  setActiveVehicle,
  indexedFleetVehiclesSelector,
} from 'components/organization/vehicles/vehicles.redux';
import { mpTrack } from 'components/mixpanel';
import { isGlobalAdminSelector } from 'components/auth/auth.reducer';
import {
  getAllowedFilters,
  generateTripFilters,
} from 'components/events/utils';
import { ISOTimestamp } from '@nauto/api/lib/events';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */

// TODO: consider further breaking these up into separate redux state NAUTO-15390

// fetching trips data
export const FETCHING_TRIPS = 'trips/fetching-trips';
export const TRIPS_RECEIVED = 'trips/trips-received';
export const REFETCH_TRIPS = 'trips/refetch-trips';
export const TRIPS_REFETCHED = 'trips/trips-refetched';

// fetching data for an individual trip
export const FETCHING_TRIP_PATH = 'trips/fetching-trip-path';
export const TRIP_PATH_RECEIVED = 'trips/trip-path-received';
export const TRIP_PATH_FETCH_FAILED = 'trips/trip-path-failed';
export const FETCHING_TRIP_EVENTS = 'trips/fetching-trip-events';
export const TRIP_EVENTS_RECEIVED = 'trips/trip-events-received';
export const TRIP_EVENTS_APPEND = 'trips/trip-events-append';
export const TRIP_EVENTS_FETCH_FAILED = 'trips/trip-events-failed';

// View toggles
export const CLEAR_ACTIVE_TRIP = 'trips/clear-active-trip';
export const SET_ACTIVE_TRIP = 'trips/set-active-trip';
export const SET_ACTIVE_EVENT = 'trips/set-active-event';
export const TOGGLE_CALENDAR = 'trips/toggle-calendar';

// toggling active views
export const SCRUB = 'trips/scrub';
export const CLEAR_TRIPS = 'trips/clear-trips';

// Setting trip driver assignment
export const ASSIGNING_TRIP = 'trips/assign-trip';
export const ASSIGN_TRIP_SUCCESS = 'trips/assign-trip-success';
export const ASSIGN_TRIP_FAILED = 'trips/assign-trip-failed';
export const CLEAR_ASSIGN_TRIP_ERROR = 'trips/clear-assign-trip-error';

export const hourAsMs = 3600000;

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

export const tripsSelector = ({ trips_data }) => trips_data.trips;

export const sortedTripsSelector = createSelector<any, any, any>(
  tripsSelector,
  trips =>
    trips.sort((t1, t2) =>
      getStartTime(t1).isAfter(getStartTime(t2)) ? 1 : -1,
    ),
);

export const sortedJoinedTripsSelector = createSelector(
  sortedTripsSelector,
  indexedFleetDriversSelector,
  indexedFleetVehiclesSelector,
  (trips, driversByID, vehiclesByID) =>
    trips.map(trip => ({
      ...trip,
      driver_object: driversByID[trip.driver],
      vehicle_object: trip.vehicle
        ? vehiclesByID[trip.vehicle]
        : trip.vehicle_object,
      // TODO: when https://nautodev.atlassian.net/browse/NAUTO-19539 is merged to prod,
      // replace with just
      // vehicle_object: vehiclesByID[trip.vehicle],
    })),
);

export const hoursMatrixSelector = ({ trips_data }) => trips_data.hoursMatrix;
export const currentScrubTimeSelector = ({ trips_data }) =>
  trips_data.currentScrubTime;
export const activeTripSelector = ({ trips_data }) => trips_data.activeTrip;
export const isScrubbingSelector = ({ trips_data }) => trips_data.isScrubbing;
export const tripEventsSelector = ({ trips_data }) =>
  trips_data.tripEvents as API.events.Event[];
export const isFetchingTripSelector = ({ trips_data }) =>
  trips_data.fetchingTripPath;

// returns true if in trip mode, false if in live mode
export const isInTripModeSelector = createSelector(
  activeTripSelector,
  trip => !!trip,
);

/**
 * find all trips that apply to this entity
 */
export const selectActiveEntityTrips = createSelector(
  sortedJoinedTripsSelector,
  selectActiveEntityType,
  selectActiveEntityId,
  (trips, entityType, activeEntityId) => {
    if (!entityType) {
      return [];
    }
    // driver and vehicle are stored as driver_objecgt and vehicle_object
    const entityKey = `${entityType}_object`;
    return trips.filter(
      // find trips where entity matches
      trip => trip[entityKey] && trip[entityKey].id === activeEntityId,
    );
  },
);

export const selectLatestActiveEntityTrip = createSelector(
  selectActiveEntityTrips,
  trips => trips.length && trips[trips.length - 1],
);

export const isFetchingActiveEntityTripsSelector = createSelector(
  selectActiveEntity,
  hoursMatrixSelector,
  (activeEntity, hoursMatrix) => {
    const entityHours = (activeEntity && hoursMatrix[activeEntity.id]) || {};
    return !!Object.keys(entityHours).some(hour => !entityHours[hour]);
  },
);

export const eventTripsSelector = createStructuredSelector({
  username: ({ user }) => user.user.name,
  assigningTrip: ({ trips_data }) => !!trips_data.assigningTrip,
  assignTripError: ({ trips_data }) => !!trips_data.assignTripError,
  isGlobalAdmin: isGlobalAdminSelector,
});

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

interface TripPathOptions {
  isActiveTrip: boolean;
}
/* tslint:disable:no-duplicate-string */
export const getTripPath = (
  tripID: string,
  options: TripPathOptions = { isActiveTrip: false },
) => (dispatch: (any) => void) => {
  const pathFetchID = tripID;
  dispatch({ type: FETCHING_TRIP_PATH, payload: { pathFetchID } });
  const data = {
    ...apiExtras(),
  };
  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/trips/${tripID}/path`,
    data,
  );

  const opts = {
    method: 'GET',
  };

  return fetchRequest(url, opts)
    .then((response: any) => {
      const { path: tripPath, distance_m: distance } = response.data;
      mpTrack('Get Trip Path', { success: true });
      dispatch({
        type: TRIP_PATH_RECEIVED,
        payload: { tripPath, pathFetchID, distance, options },
      });
    })
    .catch(error => {
      mpTrack('Get Trip Path', { success: false, error });
      dispatch({ type: TRIP_PATH_FETCH_FAILED, payload: error });
    });
};

// clears out the currently stored path
export const clearActiveTrip = () => dispatch =>
  dispatch({ type: CLEAR_ACTIVE_TRIP });

// toggle the calendar
export const toggleCalendar = () => dispatch => {
  mpTrack('Toggle Trip Calendar');
  dispatch({ type: TOGGLE_CALENDAR });
};

// close the calendar and return to live fleet
export const backToLive = () => dispatch => {
  mpTrack('Back to Live Fleet from Calendar');
  dispatch({ type: CLEAR_ACTIVE_TRIP });
};

// toggle the calendar
export const scrub = (
  time: moment.Moment,
  released: boolean,
  newActiveTrip?: Trip,
) => dispatch => {
  const payload = { time, released };
  dispatch({ type: SCRUB, payload });
};

export const setActiveTripFromId = (tripId: string) => (dispatch, getState) => {
  const {
    trips_data: { trips },
  } = getState();
  const foundTrip = trips.find(t => t.id === tripId);
  if (foundTrip) {
    return dispatch(setActiveTrip(foundTrip));
  } else {
    throw Error('Trip not found');
  }
};

// set a new active trip
export const setActiveTrip = (trip: Trip) => (dispatch, getState) => {
  // set the active entity from the trip
  const { activeEntityType } = getState();
  const entityId = trip[activeEntityType];
  const setterMap = {
    [EntityType.DRIVER]: setActiveDriver,
    [EntityType.VEHICLE]: setActiveVehicle,
  };
  const setActiveEntity = setterMap[activeEntityType];

  return Promise.all([
    dispatch(setActiveEntity(entityId)),
    // set the current live device to the deviceID of this trip
    dispatch({ type: SET_ACTIVE_TRIP, payload: { trip } }),
    dispatch(getTripPath(trip.id)),
    dispatch(getTripEvents(trip)),
  ]);
};

// set a new active trip
export const setActiveEvent = (event: API.events.Event) => dispatch => {
  // set the current open event modal
  dispatch({
    type: SET_ACTIVE_EVENT,
    payload: { event },
  });
};

// return map to its base, just-fetched state
export const clearTrips = () => dispatch => {
  dispatch({ type: CLEAR_TRIPS });
};

// the individual hours being fetching
export interface Cell {
  id: string;
  hour: number;
}
// a "run" of adjacent hours for a given row
interface Period {
  id: string;
  startMs: number;
  endMs: number;
}
// a group of rows that need the same Period of data fetched
interface Query {
  ids: string[];
  startMs: number;
  endMs: number;
}
interface Range {
  start: Moment;
  end: Moment;
}

export const getHoursInRange = (range: Range): number[] => {
  const firstVisibleHour = range.start.startOf('h').valueOf();
  const lastVisibleHour = range.end.endOf('h').valueOf();
  const visibleHours = [];
  let nextHour = firstVisibleHour;
  while (nextHour <= lastVisibleHour) {
    visibleHours.push(nextHour);
    nextHour += hourAsMs;
  }
  return visibleHours;
};

/**
 * converts an array of adjacent hours to an object
 */
export const arrayToPeriod = (hours: number[], id: string): Period => ({
  id,
  startMs: hours[0],
  endMs: hours[hours.length - 1] + hourAsMs,
});

/**
 * returns all unfetchedPeriods (continuous runs of unfetched hours of data) per row
 * also returns fetchingCells which is an array of every cell included in all unfetchedPeriods
 */
export const rowsToCellsAndPeriods = (
  rowIds: string[],
  visibleHours: number[],
  hoursMatrix: HoursMatrix,
): {
  fetchingCells: Cell[];
  unfetchedPeriods: Period[];
} => {
  const fetchingCells: Cell[] = [];
  const unfetchedPeriods: Period[] = [];

  rowIds.forEach(id => {
    const rowHours = hoursMatrix[id] || {};
    let adjacentUnfetchedHours = [];
    for (const hour of visibleHours) {
      const hourIsFetched = hour in rowHours;
      if (!hourIsFetched) {
        adjacentUnfetchedHours.push(hour);
        fetchingCells.push({ id, hour });
      } else {
        if (adjacentUnfetchedHours.length) {
          unfetchedPeriods.push(arrayToPeriod(adjacentUnfetchedHours, id));
          adjacentUnfetchedHours = [];
        }
      }
    }
    if (adjacentUnfetchedHours.length) {
      unfetchedPeriods.push(arrayToPeriod(adjacentUnfetchedHours, id));
    }
  });
  return { fetchingCells, unfetchedPeriods };
};

/**
 * returns the queries needed in order to fetch data from all multi-hour periods
 */
export const periodsToQueries = (periods: Period[]): Query[] => {
  const periodGroups = groupBy(
    periods,
    ({ startMs, endMs }) => `${startMs}_${endMs}`,
  );

  return Object.keys(periodGroups).map(k => {
    const periodGroup = periodGroups[k];
    const ids = periodGroup.map(o => o.id);
    const { startMs, endMs } = periodGroup[0];
    return { startMs, endMs, ids };
  });
};

export const queryToPromiseFactory = (activeEntityType: nauto.EntityType) => (
  query: Query,
): Promise<API.Trip[]> => {
  const url = path(`${getServiceUrl()}/fleets/${getFleetId()}/driver_trips`, {
    start_time: 'ms:' + query.startMs,
    end_time: 'ms:' + query.endMs,
    // the two filter criteria are duration and ratio of moving to stopped messages
    view: activeEntityType,
    [activeEntityType]: query.ids,
  });

  return fetchRequest(url, { method: 'GET' }).then(res => res.data);
};

export const reduceTripFetchResponses = (responses: API.Trip[][]): API.Trip[] =>
  responses.reduce((tripsArray, resp) => [...tripsArray, ...(resp || [])], []);

// gets all trips in a given time range
export const fetchTrips = (
  range: { start: Moment; end: Moment },
  visibleRowIds: string[],
  hoursMatrix: HoursMatrix,
) => (dispatch: (any) => void, getState) => {
  const { activeEntityType } = getState();

  const visibleHours = getHoursInRange(range);

  const { fetchingCells, unfetchedPeriods } = rowsToCellsAndPeriods(
    visibleRowIds,
    visibleHours,
    hoursMatrix,
  );

  const queryPeriods = periodsToQueries(unfetchedPeriods);

  dispatch({ type: FETCHING_TRIPS, payload: { fetchingCells } });

  const requests = queryPeriods.map(queryToPromiseFactory(activeEntityType));

  return Promise.all(requests).then(responses => {
    const trips = reduceTripFetchResponses(responses);

    return dispatch({
      type: TRIPS_RECEIVED,
      payload: { trips, fetchedCells: fetchingCells },
    });
  });
};

export const getTripEvents = (trip: Trip) => (
  dispatch: (any) => void,
  getState,
) => {
  dispatch({ type: FETCHING_TRIP_EVENTS });

  const fleetId = getFleetId();

  const {
    featureFlags,
    fleetSettings: { fleet },
  }: { featureFlags: Flags; fleetSettings: FleetSettings } = getState();
  const fleetFeatures =
    (fleet && fleet.fleet_offering.features) || DEFAULT_DATA;

  const allowedFilters = getAllowedFilters(
    fleetFeatures as Features,
    featureFlags,
  );

  const tripFilters: API.events.Recipe[] = generateTripFilters(allowedFilters);

  const getEvents = (page?: API.events.Page) =>
    API.events
      .getEvents({
        fleetId,
        entityType: 'vehicle',
        device: trip.device,
        filters: tripFilters,
        start: getStartTime(trip).toISOString(),
        end: getFullEndTime(trip).toISOString(),
        limit: 50,
        ...(page && { page }),
        eventIDsOnly: false,
      })
      .then(({ data }) => {
        dispatch({
          type: page ? TRIP_EVENTS_APPEND : TRIP_EVENTS_RECEIVED,
          payload: data.events,
        });
        if (data.after) {
          getEvents(data.after);
        }
      })
      .catch(error => {
        dispatch({ type: TRIP_EVENTS_FETCH_FAILED, payload: error });
        console.log('error', error);
      });

  return getEvents();
};

export const handleAssignError = (error, dispatch) => {
  console.log(error);
  dispatch({
    type: ASSIGN_TRIP_FAILED,
    payload: { error: error.message },
  });
  throw error;
};

// sets a driver assignment
export const assignDriverToTrip = (driver: Driver, tripID: string) => (
  dispatch: (any) => void,
) => {
  dispatch({ type: ASSIGNING_TRIP });
  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/driver_trips/${tripID}`,
  );
  const options = {
    method: 'PUT',
    body: { driver: driver.id },
  };
  return new Promise((resolve, reject) => {
    fetchRequest(url, options)
      .then((response: any) => {
        dispatch({
          type: ASSIGN_TRIP_SUCCESS,
          payload: {
            driver,
            tripID,
          },
        });
      })
      .then(() => {
        resolve();
      })
      .catch(e => reject(e));
  }).catch(e => handleAssignError(e, dispatch));
};

export const refetchTrips = () => (dispatch: (any) => void) => {
  dispatch({ type: REFETCH_TRIPS });
};

export const tripsRefetched = () => (dispatch: (any) => void) => {
  dispatch({ type: TRIPS_REFETCHED });
};

export const clearAssignTripError = () => (dispatch: (any) => void) => {
  dispatch({ type: CLEAR_ASSIGN_TRIP_ERROR });
};

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */

interface Hours {
  [hour: number]: boolean;
}

// records which sections of the calendar have been fetched already
export interface HoursMatrix {
  [entityID: string]: Hours;
}

export interface TripPoint {
  heading_deg?: number;
  id: number;
  time?: number;
  momentTime: moment.Moment;
  state: 'm' | 's' | 'p';
  location?: number[];
  speed?: number;
}

export interface TripsReducer {
  // data loading indicators
  fetchingTripPath: string;
  fetchingTripEvents: boolean;
  assigningTrip: boolean;
  assignTripError: string;
  // all trips in this fleet
  trips: Trip[];
  // a map of each hour in the calendar as a cell
  // tracks whether the hour has been
  hoursMatrix: HoursMatrix;
  // displayed trip
  activeTrip: Trip;
  // displayed event modal
  activeEvent: API.events.Event;
  // path of the currently active trip
  tripPath: TripPoint[];
  // distance of the trip in meters
  tripDistance: number;
  // events occuring during the time span of the currently active trip - need to be further reduced to guarantee device match
  tripEvents: API.events.Event[];
  // snapshots ocurring during the currently active trip
  tripSnaps: API.events.Event[];
  // whether the calendar should be displayed
  // the user is currently dragging the scrubber
  isScrubbing: boolean;
  // the last position the scrubber was dropped
  lastScrubberReleasedTime: moment.Moment;
  // the current position of the scrubber
  currentScrubTime: moment.Moment;
  errors: Record<string, unknown>;
  // checking if need to refetch trips
  shouldRefetchTrips: boolean;
}

export const initialState: TripsReducer = {
  assigningTrip: false,
  assignTripError: '',
  fetchingTripPath: '',
  fetchingTripEvents: false,
  trips: [],
  hoursMatrix: {},
  activeTrip: null,
  activeEvent: null,
  tripPath: [],
  tripDistance: 0,
  tripEvents: [],
  tripSnaps: [],
  isScrubbing: false,
  lastScrubberReleasedTime: null,
  currentScrubTime: null,
  errors: {},
  shouldRefetchTrips: false,
};

export default (state = initialState, { type, payload }): TripsReducer => {
  switch (type) {
    case SET_ACTIVE_TRIP:
      return {
        ...state,
        activeTrip: payload.trip,
        ...(!state.isScrubbing
          ? {
              lastScrubberReleasedTime: getStartTime(payload.trip),
              currentScrubTime: getStartTime(payload.trip),
            }
          : {}),
      };
    case SET_ACTIVE_EVENT:
      return {
        ...state,
        activeEvent: payload.event,
      };
    case FETCHING_TRIP_PATH:
      return {
        ...state,
        tripPath: initialState.tripPath,
        fetchingTripPath: payload.pathFetchID,
        tripDistance: initialState.tripDistance,
      };
    case FETCHING_TRIP_EVENTS:
      return {
        ...state,
        tripEvents: initialState.tripEvents,
        fetchingTripEvents: true,
      };
    case TRIP_PATH_RECEIVED:
      if (payload.pathFetchID !== state.fetchingTripPath) {
        return state;
      }
      return {
        ...state,
        tripPath: payload.tripPath,
        tripDistance: payload.distance,
        fetchingTripPath: '',
      };
    case CLEAR_ACTIVE_TRIP:
      return {
        ...state,
        activeTrip: initialState.activeTrip,
        tripPath: initialState.tripPath,
        tripEvents: initialState.tripEvents,
        tripSnaps: initialState.tripSnaps,
        lastScrubberReleasedTime: initialState.lastScrubberReleasedTime,
        currentScrubTime: initialState.currentScrubTime,
      };

    case REFETCH_TRIPS: {
      return {
        ...state,
        shouldRefetchTrips: true,
      };
    }

    case TRIPS_REFETCHED: {
      return {
        ...state,
        shouldRefetchTrips: false,
      };
    }
    case FETCHING_TRIPS: {
      const newMatrix = { ...state.hoursMatrix };
      const { fetchingCells } = payload;
      fetchingCells.forEach(({ id, hour }) => {
        if (!newMatrix[id]) {
          newMatrix[id] = {};
        }
        newMatrix[id][hour] = false;
      });
      return {
        ...state,
        hoursMatrix: newMatrix,
      };
    }
    case TRIPS_RECEIVED: {
      const { fetchedCells, trips } = payload;
      const newTrips = uniqBy([...state.trips, ...trips], 'id');
      const newMatrix = { ...state.hoursMatrix };
      fetchedCells.forEach(({ id, hour }) => {
        newMatrix[id][hour] = true;
      });

      return { ...state, trips: newTrips, hoursMatrix: newMatrix };
    }
    case TRIP_EVENTS_RECEIVED:
      // if we've already dismissed the trip, ignore this api response
      if (!state.activeTrip) {
        return {
          ...state,
          fetchingTripEvents: false,
        };
      }
      return {
        ...state,
        tripEvents: payload,
        fetchingTripEvents: false,
      };
    case TRIP_EVENTS_APPEND: {
      return {
        ...state,
        tripEvents: [...state.tripEvents, ...payload],
        fetchingTripEvents: false,
      };
    }
    case TRIP_EVENTS_FETCH_FAILED:
      return {
        ...state,
        errors: payload,
        fetchingTripEvents: false,
      };
    case SCRUB: {
      const { time, released } = payload;
      // if scrubber was released, update currentScrubTime. Otherwise, don't change until released
      return {
        ...state,
        currentScrubTime: time,
        ...(released ? { lastScrubberReleasedTime: time } : {}),
        isScrubbing: !payload.released,
      };
    }
    case ASSIGNING_TRIP:
      return {
        ...state,
        assigningTrip: true,
      };
    case ASSIGN_TRIP_FAILED:
      return {
        ...state,
        assigningTrip: false,
        assignTripError: payload.error,
      };
    case CLEAR_ASSIGN_TRIP_ERROR:
      return {
        ...state,
        assignTripError: initialState.assignTripError,
      };

    case ASSIGN_TRIP_SUCCESS: {
      const { driver, tripID } = payload;
      return {
        ...state,
        // optimistic update activeTrip
        activeTrip:
          state.activeTrip && state.activeTrip.id === tripID
            ? {
                ...state.activeTrip,
                driver: driver.id,
                driver_id: driver.id,
                driver_object: driver,
              }
            : state.activeTrip,
        // optimistic update trip in trips array
        trips: state.trips.map(currTrip =>
          currTrip.id === tripID
            ? { ...currTrip, driver: driver.id, driver_object: driver }
            : currTrip,
        ),
        // optimistic update trip time events
        tripEvents: state.tripEvents.map(eventData => ({
          ...eventData,
          driver,
        })),
        assigningTrip: false,
      };
    }
    case CLEAR_TRIPS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
