import { Group } from 'components/groups/groups.redux';
import { lsGet, Keys, getGroupId, getCachedGroupId } from './localstorage';
import { memoize } from 'lodash-es';

const hasAccessBuilder = targetId => {
  const hasAccessFunc = (hasAccess, group) => {
    if ((group?.id === targetId && group?.access) || hasAccess) {
      return true;
    }

    if (group?.id === targetId && !group?.access) {
      return false;
    }

    const children = group?.groups || [];

    if (children.length === 0) {
      return false;
    }

    return children.reduce(hasAccessFunc, false);
  };

  return hasAccessFunc;
};

// memoize based on targetId so we don't have to make a cache key for groups
export const hasGroupAccess = memoize(
  (targetId, group) => {
    const hasAccess = hasAccessBuilder(targetId);
    return hasAccess(false, group);
  },
  (targetId, group) => `${targetId}${group?.id}`,
);

export const getCurrentGroupId = (): string =>
  getGroupId() || getCachedGroupId();

/**
 * Get group data by group ID
 */
export function getGroupById(id: string, root?: Group): Group | null {
  const rootGroup = root || lsGet(Keys.GROUPS);

  if (!rootGroup) {
    return;
  }

  if (rootGroup.id === id) {
    return rootGroup;
  }

  if (rootGroup.groups.length === 0) {
    return null;
  }

  /**
   * Since `groups` is a flat array of objects with no known depth,
   * a simple recursive BFS approach here is ideal.
   */
  const findGroupById = subGroup => {
    if (subGroup.id === id) {
      return subGroup;
    }

    if (subGroup.groups !== null) {
      return subGroup.groups.find(group => findGroupById(group));
    }

    return null;
  };

  return findGroupById(rootGroup);
}
