import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors, Text, spaceSizesPx } from '@nauto/core';
import { img } from 'constants/images';

const PreloadingScreen: React.FC = () => {
  const [t] = useTranslation();
  return (
    <Wrapper>
      <img src={img.animated_logo} />
      <LoadingText size="lg" weight="Bold" textAlign="center">
        {t('Loading your data...')}
      </LoadingText>
    </Wrapper>
  );
};

const LoadingText = styled(Text)`
  margin-top: ${spaceSizesPx.md};
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  background-color: ${colors.white};

  img {
    width: 60px;
    height: 55px;
  }
`;

export default PreloadingScreen;
