import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colors, ScreenSizes, ScreenBreakPoints, Text, Box } from '@nauto/core';
import SidebarNavigation from 'components/sidebar-navigation';
import HeaderControlBar from 'components/header';
import { Template } from 'routes/map';
import TopbarMobileNavigation from 'components/topbar-mobile-navigation';
import { mobileNavBarHeight } from 'constants/layout';
import { getDelegatedLoginUserEmail, getUser } from 'utils/localstorage';
import useFeatureFlags from 'hooks/use-feature-flags';

interface Params {
  groupId: string;
  fleetId: string;
  entityId: string;
}

interface Props extends Template {
  params?: Params;
  title?: string;
}

export const LayoutWrapper: React.SFC<Props> = props => {
  const {
    children,
    header,
    sidebar,
    showFleet,
    showSubfleet,
    showBackButton,
    shouldPreserveSearch,
    title,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [title]);

  const delegatedEmail = getDelegatedLoginUserEmail();
  const user = getUser();
  const featureFlags = useFeatureFlags();

  return (
    <Wrapper data-testid="site-wrapper">
      {delegatedEmail ? (
        <DelegatedLoginWrapper
          align="center"
          justify="space-between"
          marginBottom="lg"
        >
          <Text color="white">{`User ${delegatedEmail} logged in as ${user?.name ||
            user?.email}`}</Text>
        </DelegatedLoginWrapper>
      ) : null}
      {featureFlags.maintenanceBannerText && (
        <DelegatedLoginWrapper
          align="center"
          justify="space-between"
          marginBottom="lg"
        >
          <Text color="white">{featureFlags.maintenanceBannerText}</Text>
        </DelegatedLoginWrapper>
      )}
      {sidebar && (
        <MainNav>
          <SidebarNavigation />
        </MainNav>
      )}
      {sidebar && (
        <MobileNav>
          <TopbarMobileNavigation />
        </MobileNav>
      )}
      <ContentWrapper data-testid="page-wrapper">
        {header && (
          <HeaderControlBar
            routeName={title}
            canSelectGroup={showSubfleet}
            showFleetName={showFleet}
            showBackButton={showBackButton}
            shouldPreserveSearch={shouldPreserveSearch}
          />
        )}

        {children}
      </ContentWrapper>
    </Wrapper>
  );
};

const DelegatedLoginWrapper = styled(Box)`
  max-width: 100%;
  width: 100vw;
  height: 20px;
  position: fixed;
  z-index: 4500;
  background: ${colors.red500};
  @media print {
    display: none;
  }
  justify-content: center;
`;

const MainNav = styled.nav`
  min-width: 5rem;
  display: flex;
  flex-shrink: 1;

  @media print {
    display: none;
  }
  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    display: none;
  }
`;

const MobileNav = styled.nav`
  min-height: ${mobileNavBarHeight}px;
  display: flex;
  flex-shrink: 1;

  @media print {
    display: none;
  }
  @media (min-width: ${ScreenSizes.TABLET}) {
    display: none;
  }
`;

const ContentWrapper = styled.section`
  /* width: calc(100% - 5rem); */
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    width: 100vw;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  background: ${colors.grey100};
`;
