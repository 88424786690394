import React from 'react';
import styled from 'styled-components';
import { TransitionState, ToastProps } from 'react-toast-notifications';
import { colors, ScreenBreakPoints, textSizesPx } from '@nauto/core';
import {
  CheckmarkCircleFilledIcon,
  CancelFilledIcon,
  WarningIcon,
} from '@nauto/icons';

interface ColorFillProps {
  height: string;
  width: string;
}

const Snackbar: React.FC<ToastProps> = props => {
  const {
    appearance = 'success',
    children,
    transitionState,
    onDismiss,
  } = props;
  return (
    <SnackbarWrapper {...props} transitionState={transitionState}>
      <IconWrapper>
        {appearance === 'success' ? (
          <>
            <ColorFill height="22px" width="22px" />
            <Icon>
              <CheckmarkCircleFilledIcon color={colors.green500} size="lg" />
            </Icon>
          </>
        ) : (
          <>
            <ColorFill height="15px" width="12px" />
            <Icon>
              <WarningIcon color={colors.red500} size="lg" />
            </Icon>
          </>
        )}
      </IconWrapper>
      <ContentWrapper>{children}</ContentWrapper>
      <DismissWrapper onClick={() => onDismiss()}>
        <CancelFilledIcon color={colors.grey500} size="lg" />
      </DismissWrapper>
    </SnackbarWrapper>
  );
};

const snackStates = {
  entering: { transform: 'translate3d(0, 120%, 0) scale(0.9)' },
  entered: { transform: 'translate3d(0, 0, 0) scale(1)' },
  exiting: { transform: 'translate3d(0, 110%, 0) scale(0.9)' },
  exited: { transform: 'translate3d(0, 120%, 0) scale(0.9)' },
};

const SnackbarWrapper = styled.div<{ transitionState: TransitionState }>`
  display: flex;
  height: 56px;
  padding: 10px 10px;
  margin-top: 5px;
  min-width: 300px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  background-color: ${colors.black};
  color: ${colors.white};
  transition-property: 'transform';
  transition-duration: 220ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  transform-origin: bottom;
  ${props => ({ ...snackStates[props.transitionState] })};
  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    width: 400px;
  }
`;

const ContentWrapper = styled.div`
  font-size: ${textSizesPx.md};
  margin-left: 10px;
`;

const DismissWrapper = styled.div`
  display: flex;
  margin-left: auto;
  padding-left: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Icon = styled.div`
  display: flex;
  z-index: 1;
`;

const ColorFill = styled.div<ColorFillProps>`
  height: ${props => props.height};
  width: ${props => props.width};
  align-self: center;
  position: absolute;
  border-radius: 50%;
  background-color: white;
`;

export default Snackbar;
