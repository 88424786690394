import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { clearActiveEntity } from './active-entity-type.redux';

interface Params {
  entityId: string;
}

interface Props {
  dispatch: (action: any) => void;
}

const EntitySync: FC<Props> = ({ dispatch }) => {
  const { entityId } = useParams<Params>();

  useEffect(() => {
    if (!entityId) {
      dispatch(clearActiveEntity());
    }
  }, [entityId]);

  return null;
};

export default connect()(EntitySync);
