import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  TrackingInfo,
  mixpanelInitDataSelector,
  LDUserSelector,
  MixPanelInfo,
  TrackingPropertiesSelector,
} from './tracking-selector';
import { closeLD, initLD, updateLD } from 'components/feature-flags/helpers';
import { mpInit, registerUser } from 'components/mixpanel';
import config from 'utils/config';
import { createSelector, createStructuredSelector } from 'reselect';
import { LDUser } from 'components/feature-flags/flag-selectors';
import usePermissions from 'hooks/use-permissions';

interface Props extends TrackingInfo {
  dispatch: any;
  ld_user: LDUser;
  mixpanel_user: MixPanelInfo;
}

declare let window: any;

const Tracking: React.FC<Props> = (props: Props) => {
  const { dispatch, ld_user, mixpanel_user, fleet_id } = props;
  const { roles } = usePermissions();
  const instantiated = useRef(false);
  const [fleetId, setFleetId] = useState(fleet_id);
  const userRole = roles?.[0]?.role?.title || 'Undefined role';

  const initiateServices = () => {
    /**
     * Instantiate LaunchDarkly
     */
    initLD(dispatch, ld_user);

    /**
     * Instantiate Mixpanel: MP is also initiated in
     * index.html
     */
    mpInit(config.mixpanel_token, {
      debug: false,
    });
    registerUser({ ...mixpanel_user, $role: userRole });
  };

  useEffect(() => () => closeLD(), []);

  useEffect(() => {
    if (!instantiated.current) {
      if (ld_user && ld_user.custom.fleet_id && mixpanel_user) {
        initiateServices();
        instantiated.current = true;
      }
    }
  }, [ld_user, mixpanel_user]);

  useEffect(() => {
    if (instantiated.current) {
      const fleetChanged = fleetId && fleet_id && fleetId !== fleet_id;
      setFleetId(fleet_id);

      if (fleetChanged) {
        updateLD(ld_user);
      }
    }
  }, [fleet_id, ld_user, mixpanel_user]);

  return null;
};

const TrackingServicesSelector = createStructuredSelector({
  ld_user: LDUserSelector,
  mixpanel_user: mixpanelInitDataSelector,
});

const selector = createSelector(
  TrackingPropertiesSelector,
  TrackingServicesSelector,
  (properties, services) => ({
    ...properties,
    ...services,
  }),
);

export default connect(selector)(Tracking);
