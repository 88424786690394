import { ActionCreator as ReduxActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { FormikActions } from 'formik';
import { Driver, Vehicle } from 'models/db';
import { Group } from 'components/fleet-organization/fleet-group.props';
import { AlertType } from 'constants/alert-types';

export type ID = string;
type IDs = { [key in IDType]?: ID[] };
type Rules = { [key in RuleProperty]?: any };

export const Thresholds = {
  [AlertType.Braking]: {
    Lower: 0.55,
    Upper: 0.7,
    Increment: 0.05,
  },
  [AlertType.Tailgating]: {
    Lower: 8,
    Upper: 15,
    Increment: 1,
  },
  [AlertType.Distraction]: {
    Lower: 5,
    Upper: 7,
    Increment: 1,
  },
};

export interface AlertResponseItem {
  created: string;
  email_opt: boolean;
  id: string;
  inapp_opt: boolean;
  long_desc: string;
  need_review: boolean;
  properties: {
    default_rule: Rules;
    rules?: AlertResponseRule[];
  };
  scope: string;
  short_desc: AlertType;
  sms_opt: boolean;
  status: string;
  updated: string;
}

export enum RuleProperty {
  MinDuration = 'min_duration',
  MinGForce = 'min_gforce',
  MinSpeed = 'min_speed',
}

export enum IDType {
  Drivers = 'driver_ids',
  Subfleets = 'group_ids',
  Vehicles = 'vehicle_ids',
}

export interface AlertResponseRule extends IDs, Rules {
  modifiable?: boolean;
  enabled: boolean;
  filter: FilterType;
  fleet_id: string;
  id: string;
  name: string;
  selected: ID[];
}

export interface AlertResponse {
  alerts: {
    [AlertType.Braking]: AlertResponseItem;
    [AlertType.Distraction]: AlertResponseItem;
    [AlertType.Tailgating]: AlertResponseItem;
  };
  success: boolean;
}

export interface AlertNotification {
  modifiable?: boolean;
  rule: AlertResponseRule;
  type: AlertType;
  id: string;
  name: string;
  enabled: boolean;
}

export enum FilterType {
  Drivers = 'drivers',
  Vehicles = 'vehicles',
  Subfleets = 'subfleets',
}

export interface FormValues {
  alertType: AlertType;
  alertName: string;
  enabled: boolean;
  [AlertType.Braking]: {
    [RuleProperty.MinGForce]: number;
  };
  [AlertType.Distraction]: {
    [RuleProperty.MinDuration]: number;
  };
  [AlertType.Tailgating]: {
    [RuleProperty.MinDuration]: number;
  };
}

export interface ConnectedProps {
  drivers: Driver[];
  subfleets: Group[];
  vehicles: Vehicle[];
}

export enum ModalID {
  Form = 'form',
  Success = 'success',
}

export interface Modals {
  [ModalID.Success]: boolean;
  [ModalID.Form]: boolean;
}

export const defaultRules = {
  [AlertType.Braking]: {
    [RuleProperty.MinGForce]: 0.55,
  },
  [AlertType.Distraction]: {
    [RuleProperty.MinDuration]: 5,
    [RuleProperty.MinSpeed]: 20,
  },
  [AlertType.Tailgating]: {
    [RuleProperty.MinDuration]: 8,
    [RuleProperty.MinSpeed]: 20,
  },
};

export interface State {
  alertList: AlertNotification[];
  alertType: AlertType;
  editing: AlertNotification;
  filter: FilterType;
  modal: ModalID;
  search: string;
  selected: ID[];
}

export interface Action {
  type: Constant;
  payload?: Partial<State>;
}
export type ThunkResult<R> = ThunkAction<R, State, Action>;
export type ActionCreator = ReduxActionCreator<Action>;
export type APIResult = Promise<AlertNotification[]>;

export enum Constant {
  CLEAR_EDITING = '@severe-event-alerts/Clear Editing',
  DISABLE_ALERT = '@severe-event-alerts/Disable Alert',
  EDIT_ALERT = '@severe-event-alerts/Edit Alert',
  FILTER_BY = '@severe-event-alerts/Filter By',
  GET_ALERTS = '@severe-event-alerts/Get Alerts',
  HIDE_MODALS = '@severe-event-alerts/Hide Modals',
  POST_ALERT = '@severe-event-alerts/Post Alert',
  REMOVE_ALERT = '@severe-event-alerts/Remove Alert',
  SEARCH_BY = '@severe-event-alerts/Search By',
  SELECT_ENTITIES = '@severe-event-alerts/Select Entities',
  SET_ALERT_TYPE = '@severe-event-alerts/Set Alert Type',
  SHOW_MODAL = '@severe-event-alerts/Show Modal',
}

export interface Context extends State {
  email: string;
  drivers: Driver[];
  subfleets: Group[];
  vehicles: Vehicle[];
  editAlert(alert: AlertNotification): Action;
  filterBy(filter: FilterType): Action;
  hideModals(): void;
  removeAlert(alert: AlertNotification): APIResult;
  searchBy(search: string): Action;
  selectEntities(entities: ID[]): Action;
  setAlertType(alertType: AlertType): Action;
  shouldShowModal(id: ModalID): boolean;
  showModal(id: ModalID): Action;
  submit(values: FormValues, actions: FormikActions<FormValues>): void;
  toggleAlert(alert: AlertNotification, roleId: string): APIResult;
  validate(values: FormValues): any;
}
