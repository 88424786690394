import {
  DEFAULT_DATA,
  FEATURES,
  ADD_ON_FEATURES,
  ADD_ON_EVENTS,
} from 'components/feature/constants';

interface Args {
  features: Record<string, any>;
  addons: string[];
}
export const aggregateFeatures = ({
  features,
  addons,
}: Args): Record<string, any> => {
  if (!features) {
    return DEFAULT_DATA;
  }

  if (!addons || addons.length === 0) {
    return features;
  }

  const baseFeatures = { ...(features || DEFAULT_DATA) };

  const addonMap = addons.reduce(
    (reducedPackage, currentAddon) => {
      reducedPackage.features[currentAddon] = true;
      const includedEvents = ADD_ON_EVENTS[currentAddon] || [];
      const includedFeatures = ADD_ON_FEATURES[currentAddon] || [];

      includedFeatures.forEach(includedFeature => {
        reducedPackage.features[includedFeature] = true;
      });

      includedEvents.forEach(includedEvent => {
        reducedPackage.events.push(includedEvent);
      });

      return reducedPackage;
    },
    {
      features: {},
      events: [],
    },
  );

  // remove duplicates....
  const eventTypes = Array.from(
    new Set([...features[FEATURES.EVENT_TYPES], ...addonMap.events]),
  );
  return {
    ...baseFeatures,
    ...addonMap.features,
    [FEATURES.EVENT_TYPES]: eventTypes,
  };
};
