import * as API from '@nauto/api';
import { Page, Recipe } from '@nauto/api/lib/events';
import { EntityType } from 'components/entity-type/entity-type-utils';
import useFeatureFlags from 'hooks/use-feature-flags';
import useFleetData from 'hooks/use-fleet-data';
import useLoggedInUserType from 'hooks/use-logged-in-user-type';
import { useInfiniteQuery } from 'react-query';

interface EventsParams {
  driverId: string;
  entityType: EntityType;
  entityId: string;
  start: string;
  end: string;
  filters: Recipe[];
  severity: number;
  groupId: string;
}

const useEventsInfiniteQuery = (
  eventParams: EventsParams,
): typeof eventsListQuery => {
  const { id: fleetId } = useFleetData({ current: true });
  const featureFlags = useFeatureFlags();
  const {
    groupId,
    driverId,
    start,
    end,
    filters,
    entityType,
    severity,
    entityId,
  } = eventParams;
  const { eventsLimitParam, eventsSkiptripParam } = featureFlags;
  const { isLoggedInUserTypeDriver } = useLoggedInUserType();
  const isDriver =
    !!entityId && entityType === EntityType.DRIVER && entityId.startsWith('d-');
  const isVehicle =
    !!entityId &&
    entityType === EntityType.VEHICLE &&
    entityId.startsWith('v-');
  const shouldFetch = !!start && !!end && !!filters.length;

  const fetchEvents = async ({ pageParam = [] }) => {
    const nextPage = pageParam?.length === 0 ? null : pageParam;

    const requestParams = {
      ...eventParams,
      ...{
        isLoggedInUserTypeDriver,
        fleetId,
        groupId,
        ...(isDriver && {
          driver: entityId,
        }),
        ...(isVehicle && {
          vehicle: entityId,
        }),
        ...(nextPage ? { page: nextPage as Page[] } : {}),
        limit: eventsLimitParam || 10,
        eventIDsOnly: false,
        skipTrip: eventsSkiptripParam,
      },
    };

    const { data } = isLoggedInUserTypeDriver
      ? await API.driverLogin.getDriverEvents(
          {
            driverId,
            referenceExpand: true,
            ...requestParams,
          },
          { version: 'v1' },
        )
      : await API.events.getEvents(requestParams);

    return data;
  };

  const eventsListQuery = useInfiniteQuery(
    [
      'getEvents',
      {
        fleetId,
        groupId,
        entityType,
        start,
        end,
        severity,
        ...{ filters },
        entityId,
      },
    ],
    fetchEvents,
    {
      enabled: shouldFetch,
      staleTime: Infinity,
      // getNextPageParam and getPreviousPageMaram is truth-y if any value other than undefined is returned
      getNextPageParam: lastPage =>
        lastPage.after && lastPage.after[0] !== lastPage.before[0]
          ? lastPage.after
          : undefined,
      select: (data: any): any => data.pages.map(page => page.events).flat(),
      keepPreviousData: true,
    },
  );

  return eventsListQuery;
};

export default useEventsInfiniteQuery;
