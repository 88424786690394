import { AnyAction } from 'redux';
import { createSelector } from 'reselect';
import * as API from '@nauto/api';
import { UserAuth } from 'models/generics';
import {
  getUser,
  getToken,
  getFleets,
  getFleetId,
  getGroupId,
  getGlobalRoles,
  getLoggedInUserType,
} from 'utils/localstorage';
import {
  registerUser,
  registerFleetId,
  registerFleetName,
} from 'components/mixpanel';
import {
  LoggedInUserType,
  ROLES,
  V2_GLOBAL_INSTALLER_FLEET_ID,
} from 'constants/roles';
import { SpeedUnit } from 'components/settings/types';
import { GLOBAL_FLEET } from 'components/device-actions/utils';
import { FleetAccess, FleetProps } from 'models/db';
import { VeraVersionType } from 'utils/vera-score-utils';
import {
  atRiskVera2Threshold,
  atRiskVera3Threshold,
} from 'constants/event-thresholds';
import { TYPE } from 'constants/events';

/**
 * Selects the full fleet object
 */
export const currentFleetDataSelector = ({
  user: { fleets, currentFleet },
}): FleetAccess =>
  currentFleet && fleets && fleets.find(f => f.fleet.id === currentFleet);

/**
 * Selects the userType of the current logged in user
 */
export const currentUserTypeSelector = ({
  user: { loggedInUserType },
}): LoggedInUserType => loggedInUserType;

/**
 * Selects the full fleet object for the global fleet
 */
export const globalFleetDataSelector = ({ user: { fleets } }) =>
  fleets && fleets.find(f => f.fleet.id === GLOBAL_FLEET);

export const veraVersionTypeSelector = ({ user: { veraVersionType } }) => {
  return veraVersionType;
};

export const loggedInUserTypeSelector = ({
  user: { loggedInUserType },
}): LoggedInUserType => loggedInUserType;

// TODO: future ref : need to change the threshold value here and in the provider
export const atRiskVeraThresholdSelector = createSelector(
  veraVersionTypeSelector,
  veraVersionType =>
    !!veraVersionType &&
    (veraVersionType === VeraVersionType.VERA3
      ? atRiskVera3Threshold
      : atRiskVera2Threshold),
);
// TODO: future ref : need to change the collision value here and in the provider
export const collisionEventTypeSelector = createSelector(
  veraVersionTypeSelector,
  veraVersionType =>
    !!veraVersionType &&
    (veraVersionType === VeraVersionType.VERA3
      ? TYPE.CONFIRMED_COLLISION_VERA3
      : TYPE.CONFIRMED_COLLISION_VERA2),
);
/**
 * Selects the full fleet object
 */
export const currentFleetSelector = createSelector(
  currentFleetDataSelector,
  (fleetData: FleetAccess): FleetProps => fleetData?.fleet,
);

export const currentFleetRoleIdsSelector = createSelector(
  currentFleetDataSelector,
  (fleetData: FleetAccess): string[] => fleetData?.role_ids,
);

export const getUmVersionSelector = createSelector(
  currentFleetDataSelector,
  (fleetData: FleetAccess): string => fleetData?.um_version,
);

/**
 * Selects the full fleet object
 */
export const fleetPropertiesSelector = createSelector(
  currentFleetSelector,
  fleet => fleet?.properties || null,
);

/**
 * Select the unit to use for this fleet
 */
export const useMilesSelector = createSelector(
  fleetPropertiesSelector,
  properties => {
    const unit = properties.speeding_setting?.unit;
    return unit === SpeedUnit.MPH;
  },
);

export const useFeetSelector = createSelector(
  useMilesSelector,
  useFeet => useFeet,
);

/**
 * Selects the roles of the current logged in fleet
 */
export const rolesSelector = createSelector(
  currentFleetDataSelector,
  fleetData => (fleetData ? fleetData.roles : []),
);

/**
 * true if current user has admin access to fleet
 */
export const isAdminSelector = createSelector(rolesSelector, roles => {
  const adminRoles = [
    ROLES.FLEET_ADMIN,
    ROLES.HIDDEN_SUPPORT_FLEET_ADMIN,
    ROLES.GLOBAL_ADMIN,
  ];
  return adminRoles.some(adminRole => roles.includes(adminRole));
});

export const globalRolesSelector = ({ user }) => user.globalRoles;

export const isGlobalAdminSelector = createSelector(
  globalFleetDataSelector,
  globalRolesSelector,
  (globalFleet, globalRoles) => {
    return (
      globalRoles?.includes(ROLES.GLOBAL_ADMIN) ||
      (!!globalFleet && globalFleet.roles.includes(ROLES.GLOBAL_ADMIN))
    );
  },
);

/**
 * true if current user is has a nauto internal role
 */
export const isSupportUserSelector = createSelector(
  currentFleetDataSelector,
  isGlobalAdminSelector,
  (currentFleet, isGlobalAdmin) =>
    isGlobalAdmin ||
    (!!currentFleet &&
      currentFleet.roles.includes(ROLES.HIDDEN_SUPPORT_FLEET_ADMIN)),
);

/**
 * true if current user has fleet admin access
 */
export const isFleetAdminSelector = createSelector(rolesSelector, roles => {
  const fleetAdminRoles = [
    ROLES.FLEET_ADMIN,
    ROLES.GLOBAL_ADMIN,
    ROLES.HIDDEN_SUPPORT_FLEET_ADMIN,
    ROLES.SUPERFLEET_CHILD,
  ];
  return fleetAdminRoles.some(adminRole => roles.includes(adminRole));
});

export const isUserManagementAvailableSelector = createSelector(
  rolesSelector,
  roles => {
    const allowedRoles = [ROLES.GLOBAL_ADMIN, ROLES.HIDDEN_SUPPORT_FLEET_ADMIN];
    return allowedRoles.some(adminRole => roles.includes(adminRole));
  },
);

/**
 * true if current user is an admin but not from superfleet roles
 */
export const isNonSuperfleetAdminSelector: any = createSelector(
  rolesSelector,
  roles => {
    const fleetAdminRoles = [
      ROLES.FLEET_ADMIN,
      ROLES.GLOBAL_ADMIN,
      ROLES.FLEET_MANAGER,
      ROLES.HIDDEN_SUPPORT_FLEET_ADMIN,
    ];
    return fleetAdminRoles.some(adminRole => roles.includes(adminRole));
  },
);

export const isReadOnlyEventsRoleSelector: any = createSelector(
  rolesSelector,
  roles => roles?.length === 1 && roles[0] === ROLES.SUPERFLEET_EVENTS,
);

const isReadOnlyDriverLoginRoleSelector = createSelector(
  rolesSelector,
  roles => roles?.length === 1 && roles[0] === ROLES.DRIVER_PROFILE_VIEWER,
);

const isCurrentUserTypeDriverSelector = createSelector(
  currentUserTypeSelector,
  loggedInUserType =>
    !!loggedInUserType && loggedInUserType === LoggedInUserType.DRIVER,
);

/**
 * true if current user type is driver and has role 8 only
 */
export const isLoggedInUserTypeDriverSelector = createSelector(
  isCurrentUserTypeDriverSelector,
  isReadOnlyDriverLoginRoleSelector,
  (isDriverUserType: boolean, hasDriverProfileRole: boolean): boolean =>
    isDriverUserType && hasDriverProfileRole,
);

/**
 * true if current user has superfleet access roles
 */
export const isSuperfleetFullAccessEventsOnlySelector: any = createSelector(
  rolesSelector,
  roles => {
    const fleetAdminRoles = [
      ROLES.SUPERFLEET_FULL_ACCESS,
      ROLES.SUPERFLEET_EVENTS,
    ];
    return fleetAdminRoles.some(adminRole => roles.includes(adminRole));
  },
);

/**
 * We want to show all superfleets and orphan fleets the user has access to
 * We do not want to show the fleets inside superfleets
 */
export const topLevelFleetsSelector = createSelector(
  ({ user: { fleets } }) =>
    fleets.filter(
      fleetAccess =>
        fleetAccess.fleet.id !== V2_GLOBAL_INSTALLER_FLEET_ID &&
        !(
          fleetAccess.roles.length === 1 &&
          fleetAccess.roles[0] === ROLES.SUPERFLEET_CHILD
        ),
    ),
  (filteredFleets: FleetAccess[] = []): FleetProps[] => {
    return filteredFleets.map(data => ({
      ...data.fleet,
      roles: data.roles,
    }));
  },
);

export const isMultiFleetUserSelector = createSelector(
  topLevelFleetsSelector,
  fleets => fleets.length > 1,
);

/**
 * true if current user is in read only fleet
 */
export const isReadOnlySelector = createSelector(
  fleetPropertiesSelector,
  properties => {
    const { channel } = properties;
    return channel === 'gogps';
  },
);

export const isJapaneseFleetSelector = createSelector(
  currentFleetSelector,
  fleet => fleet?.business_country === 'JP',
);

export enum Constants {
  CLEAR_LOGIN_REDIRECT = 'auth/clear-login-redirect',
  FLEETS_HEALTH_SUCCESS = 'auth/fleets-health-success',
  FLEETS_UNTAGGED_FACES_SUCCESS = 'auth/fleets-untagged-faces-success',
  FORGOT_PASSWORD_ERROR = 'auth/forgot-password-error',
  FORGOT_PASSWORD_START = 'auth/forgot-password-start',
  FORGOT_PASSWORD_SUCCESS = 'auth/forgot-password-success',
  REFRESH_FLEETS_ERROR = 'auth/refresh-fleets-error',
  REFRESH_FLEETS_START = 'auth/refresh-fleets-start',
  REFRESH_UNTAGGED_FACES_START = 'auth/refresh-untagged-faces-start',
  REFRESH_FLEETS_SUCCESS = 'auth/refresh-fleets-success',
  RESET_ALL = 'RESET_ALL',
  SELECT_FLEET = 'auth/select-fleet',
  SELECT_GROUP = 'auth/select-group',
  SET_LOGIN_REDIRECT = 'auth/set-login-redirect',
  SET_PASSWORD_ERROR = 'auth/set-password-error',
  SET_PASSWORD_START = 'auth/set-password-start',
  SET_PASSWORD_SUCCESS = 'auth/set-password-success',
  UPDATE_NAME_ERROR = 'auth/update-name-error',
  UPDATE_NAME_START = 'auth/update-name-start',
  UPDATE_NAME_SUCCESS = 'auth/update-name-success',
  USER_AUTHENTICATE_FAILURE = 'auth/user-authenticate-failure',
  USER_AUTHENTICATE_START = 'auth/user-authenticate-start',
  USER_AUTHENTICATED = 'auth/user-authenticated',
  USER_UPDATE = 'auth/user-update',
  USER_UPDATE_ERROR = 'auth/user-update-error',
  USER_UPDATE_START = 'auth/user-update-start',
  USER_UPDATE_SUCCESS = 'auth/user-update-success',
  USER_VERA_SCORE_VERSION_UPDATE = 'auth/user-vera-score-version-update',
}

export const defaultState = (): UserAuth => {
  const storedCurrentFleet = getFleetId();
  const storedCurrentGroup = getGroupId();
  const storedFleets = getFleets();
  const storedToken = getToken();
  const storedUser = getUser();
  const storedGlobalRoles = getGlobalRoles();
  const storedLoggedInUserType = getLoggedInUserType();
  const veraVersionType = VeraVersionType.VERA3;

  if (storedUser) {
    registerUser(storedUser);
  }

  if (storedCurrentFleet) {
    registerFleetId(storedCurrentFleet);
  }

  if (storedFleets?.length && storedCurrentFleet) {
    const currentFleet = storedFleets.find(
      f => f.fleet.id === storedCurrentFleet,
    );
    const currentFleetName = currentFleet?.fleet?.name;
    currentFleetName && registerFleetName(currentFleetName);
  }

  API.setOptions({ token: storedToken, version: 'v2.2' });

  return {
    authenticated: !!storedUser,
    authenticateFailure: false,
    currentFleet: storedCurrentFleet || null,
    currentGroup: storedCurrentGroup || null,
    flash: '',
    fleets: storedFleets || [],
    id: storedUser?.id || '',
    isAuthenticating: false,
    isResettingPassword: false,
    isSettingPassword: false,
    redirect: '',
    refreshingFleets: true,
    refreshingFleetsError: '',
    resettingPasswordError: '',
    settingPasswordError: '',
    token: storedToken || null,
    updatingName: false,
    updatingNameError: '',
    user: storedUser || {},
    veraVersionType: veraVersionType,
    globalRoles: storedGlobalRoles,
    loggedInUserType: storedLoggedInUserType || LoggedInUserType.USER,
  };
};

/* tslint:disable:no-big-function */
export default (
  state: UserAuth = defaultState(),
  { payload, type }: AnyAction,
) => {
  switch (type) {
    case Constants.USER_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        authenticateFailure: false,
        fleets: payload.fleets,
        id: payload.user.id,
        isAuthenticating: false,
        token: payload.token,
        user: payload.user,
        username: payload.user.email,
        globalRoles: payload.globalRoles,
        loggedInUserType: payload.loggedInUserType,
      };

    case Constants.USER_AUTHENTICATE_FAILURE:
      return {
        ...state,
        authenticated: false,
        authenticateFailure: true,
        isAuthenticating: false,
      };

    case Constants.USER_AUTHENTICATE_START:
      return {
        ...state,
        authenticated: false,
        isAuthenticating: true,
        authenticateFailure: false,
      };

    case Constants.USER_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user,
        },
      };

    case Constants.UPDATE_NAME_START: {
      return {
        ...state,
        updatingNameError: '',
        updatingName: true,
      };
    }

    case Constants.UPDATE_NAME_ERROR: {
      return {
        ...state,
        updatingNameError: payload.error,
        updatingName: false,
      };
    }

    case Constants.UPDATE_NAME_SUCCESS: {
      return {
        ...state,
        user: payload.user,
        updatingNameError: '',
        updatingName: false,
      };
    }

    case Constants.REFRESH_FLEETS_START: {
      return {
        ...state,
        refreshingFleets: true,
        refreshingFleetsError: '',
      };
    }

    case Constants.REFRESH_UNTAGGED_FACES_START: {
      return {
        ...state,
        refreshingFaces: true,
        refreshingFleetsError: '',
      };
    }

    case Constants.REFRESH_FLEETS_ERROR: {
      return {
        ...state,
        refreshingFleets: false,
        refreshingFaces: false,
        refreshingFleetsError: payload.error,
      };
    }

    case Constants.REFRESH_FLEETS_SUCCESS: {
      return {
        ...state,
        refreshingFleets: false,
        refreshingFleetsError: '',
        fleets: payload.fleets,
      };
    }

    case Constants.FLEETS_HEALTH_SUCCESS: {
      return {
        ...state,
        refreshingFleets: false,
        refreshingFleetsError: '',
        fleets: state.fleets.map(f => {
          const deviceData = payload.find(item => item.id === f.fleet.id);
          return {
            ...f,
            fleet: {
              ...deviceData,
              ...f.fleet,
              untaggedFaces: { ...f.untaggedData },
            },
          };
        }),
      };
    }

    case Constants.FLEETS_UNTAGGED_FACES_SUCCESS: {
      return {
        ...state,
        refreshingFaces: false,
        refreshingFacesError: '',
        fleets: state.fleets.map(f => {
          const fleetID = f.fleet.id;
          const untaggedFacesData = payload[fleetID];
          return {
            ...f,
            untaggedData: {
              ...untaggedFacesData,
            },
          };
        }),
      };
    }

    case Constants.FORGOT_PASSWORD_START: {
      return {
        ...state,
        resettingPasswordError: '',
        isResettingPassword: true,
      };
    }

    case Constants.FORGOT_PASSWORD_ERROR: {
      return {
        ...state,
        resettingPasswordError: payload.error,
        isResettingPassword: false,
      };
    }

    case Constants.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        resettingPasswordError: '',
        isResettingPassword: false,
      };
    }

    case Constants.SET_PASSWORD_START: {
      return {
        ...state,
        settingPasswordError: '',
        isSettingPassword: true,
      };
    }

    case Constants.SET_PASSWORD_ERROR: {
      return {
        ...state,
        settingPasswordError: payload.error,
        isSettingPassword: false,
      };
    }

    case Constants.SET_PASSWORD_SUCCESS: {
      return {
        ...state,
        settingPasswordError: '',
        isSettingPassword: false,
      };
    }

    case Constants.SELECT_FLEET:
      return {
        ...state,
        currentFleet: payload.currentFleet,
      };

    case Constants.SELECT_GROUP:
      return {
        ...state,
        currentGroup: payload.currentGroup,
      };

    case Constants.SET_LOGIN_REDIRECT:
      return {
        ...state,
        redirect: payload.path,
        flash: payload.flash,
      };

    case Constants.CLEAR_LOGIN_REDIRECT:
      return {
        ...state,
        redirect: '',
        flash: '',
      };

    case Constants.USER_VERA_SCORE_VERSION_UPDATE:
      return {
        ...state,
        veraVersionType: payload.veraVersionType,
      };
    default:
      return state;
  }
};
