import React from 'react';
import { compose } from 'recompose';
import { NavItem, NavBar, NavLogo } from '@nauto/primary-navbar';
import styled from 'styled-components';
import { Route, Link } from 'react-router-dom';
import withCurrentFleet from 'decorators/with-current-fleet';
import { withTranslation, WithTranslation } from 'react-i18next';
import withFeatureFlags, {
  FeatureFlagsProps,
} from 'decorators/with-feature-flags';
import {
  UserPermissionData,
  Permissions,
  withUserPermission,
} from 'hooks/use-permissions';
import { ROUTES } from 'routes/constants';
import FleetLink, { fleetLink } from 'components/fleet-link';
import { zIndex } from '@nauto/core';
import { dataTestId } from './data-test-ids';
import {
  NavItemProps,
  navItemsSelector,
} from 'components/navigation/nav-items.selector';
import { currentFleetSelector } from 'components/auth/auth.reducer';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FleetProps } from 'models/db';
import withLoggedInUserType, {
  LoggedInUserTypeProps,
} from 'decorators/with-logged-in-user-type';

interface Props
  extends WithTranslation,
    FeatureFlagsProps,
    UserPermissionData,
    LoggedInUserTypeProps {
  currentFleet?: string;
  currentGroup?: string;
  navItems: NavItemProps[];
  fleet: FleetProps;
}

export class SidebarNavigation extends React.Component<Props> {
  render() {
    const {
      currentFleet,
      currentGroup,
      navItems,
      hasPermission,
      fleet,
      isLoggedInUserTypeDriver,
      featureFlags,
    } = this.props;

    const skipGroup = !currentGroup || fleet.is_super_fleet;
    const pathname = featureFlags.newHome ? ROUTES.HOME : ROUTES.INSIGHTS;
    const logo =
      !isLoggedInUserTypeDriver &&
      hasPermission(Permissions.WriteEvents, false) ? (
        <Link
          to={fleetLink({
            pathname,
            fleetId: currentFleet,
            groupId: currentGroup,
            ...(skipGroup && { skipGroup }),
          })}
          style={{
            position: 'fixed',
          }}
        >
          <NavLogo />
        </Link>
      ) : (
        <NavLogo />
      );

    return (
      <Wrapper className="primary-wrapper">
        <NavBar useDefaultLogo={false}>
          {logo}
          {!isLoggedInUserTypeDriver &&
            navItems.map(
              props =>
                props.show && (
                  <Route key={props.key} path={props.match || props.to}>
                    {({ match }) => (
                      <FleetLink to={props.to}>
                        <NavItem
                          data-testid={dataTestId[props.dataTestId]}
                          id={props.appcuesId}
                          label={props.label}
                          icon={props.icon}
                          isActive={match}
                        />
                      </FleetLink>
                    )}
                  </Route>
                ),
            )}
        </NavBar>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 5rem;
  height: 100vh;
  position: fixed;
  z-index: ${zIndex.navbar};
  overflow-x: hidden;

  a:nth-child(2) {
    margin-top: 6rem;
  }
`;

const Selector = createStructuredSelector({
  fleet: currentFleetSelector,
  navItems: navItemsSelector,
});

export default compose(
  withTranslation(),
  withFeatureFlags,
  withCurrentFleet,
  withUserPermission,
  withLoggedInUserType,
  connect(Selector),
)(SidebarNavigation);
