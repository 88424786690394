export enum EntityType {
  VEHICLE = 'vehicle',
  DRIVER = 'driver',
}

export enum SubFleetsEntityType {
  SUBFLEETS = 'groups',
}

export enum GridReportEntityType {
  Drivers = 'driver',
  Vehicles = 'vehicle',
  Subfleets = 'group',
}

export const entityTypes: EntityType[] = [
  EntityType.VEHICLE,
  EntityType.DRIVER,
];

export const reportEntityTypes: Array<EntityType | SubFleetsEntityType> = [
  SubFleetsEntityType.SUBFLEETS,
  EntityType.VEHICLE,
  EntityType.DRIVER,
];
