import moment, { Moment } from 'moment';
import { Vehicle } from 'models/db';
import * as API from '@nauto/api';
import { trimTimestamp } from 'utils/helpers';
import { parse, stringify } from 'query-string';
import { TFunction } from 'i18next';
import { getLastMessageTimestamp } from 'utils/vehicles';

// CONSTANTS
export const TABLE_ID_HEALTH = 'report_health';
export const OBSTRUCTION_GRAPH_DAYS = 7;
export const LAST_CONNECTED_DAYS_THRESHOLD = 7;

export const isLoose = (vehicle: Vehicle | API.Vehicle): boolean =>
  (!!vehicle.health && !!vehicle.health.device_loose) ||
  !!(vehicle as Vehicle)?.device?.health?.loose;

export const isSilent = (vehicle: Vehicle): boolean =>
  (!!vehicle.health && !!vehicle.health.device_silent) ||
  !!(vehicle as Vehicle)?.device?.health?.silent;

export const isObstructed = (vehicle: Vehicle | API.Vehicle): boolean =>
  !!(vehicle as API.Vehicle)?.health?.device_obstructed ||
  !!(vehicle as Vehicle)?.device?.health?.obstruction?.above_threshold;

export const isInService = (vehicle: Vehicle | API.Vehicle): boolean =>
  vehicle.service_status === ServiceStatus.IN_SERVICE;

export const isDeviceInService = (vehicle: Vehicle): boolean =>
  vehicle.service_status === ServiceStatus.IN_SERVICE ||
  vehicle.service_status === ServiceStatus.RMA_IN_PROGRESS;

export const isRMAInProgress = (vehicle: Vehicle): boolean =>
  vehicle.service_status === ServiceStatus.RMA_IN_PROGRESS;

export const formattedLastMessageTime = (
  vehicle: Vehicle,
  format: string,
  t: any,
): string => {
  const { LastMessageTimeStamp } = vehicle.shadow;
  return LastMessageTimeStamp
    ? moment(trimTimestamp(LastMessageTimeStamp)).format(format)
    : t('Never');
};

export const obstructedPercentageValue = (
  vehicle: API.Vehicle,
): number | '<1' => {
  if (vehicle?.obstruction) {
    return formattedWholeNumber(vehicle.obstruction.percentage_obstructed);
  } else if (vehicle?.device?.health?.obstruction) {
    return formattedWholeNumber(vehicle.device.health.obstruction.percentage);
  }
  return 0;
};

export const mountingExportValue = (vehicle: Vehicle, t) => {
  return !isLoose(vehicle) ? t('Secured') : t('Loose');
};

export const statusExportValue = (vehicle: Vehicle, t) => {
  if (vehicle.service_status === 'in_service') {
    return t('In service');
  } else {
    return t('Out of service');
  }
};

/**
 * Determine left bound of a trip segment
 * @param trip: Trip
 */
export const getTripSegmentLeftOffset = (start: Moment): number => {
  return start.diff(start.clone().startOf('day'), 'day', true) * 100;
};

/**
 * Determine top bound of a trip segment
 * @param trip: Trip
 */
export const getTripSegmentTopOffset = (start: Moment): number => {
  return moment()
    .startOf('day')
    .diff(start, 'day');
};

/**
 * Determine width of a trip segment
 * @param trip: Trip
 */
export const getTripSegmentTripLength = (
  start: Moment,
  end: Moment,
): number => {
  if (tripSegmentSpansTwoDays(start, end)) {
    return (
      start
        .clone()
        .endOf('day')
        .diff(start, 'day', true) * 100
    );
  } else {
    return end.diff(start, 'day', true) * 100;
  }
};

/**
 * Determine width of a next day segment of an overnight trip
 * @param end: timestamp
 */
export const getOvernightTripSegmentTripLength = (end: Moment): number => {
  return end.diff(end.clone().startOf('day'), 'day', true) * 100;
};

/**
 * Determine whether a trip segment spans 2 days
 * @param trip: Trip
 */
export const tripSegmentSpansTwoDays = (
  start: Moment,
  end: Moment,
): boolean => {
  return (
    start
      .clone()
      .endOf('day')
      .diff(end) < 0
  );
};

export enum ServiceStatus {
  OUT_OF_SERVICE = 'out_of_service',
  IN_SERVICE = 'in_service',
  RMA_REQUESTED = 'rma_requested',
  RMA_IN_PROGRESS = 'rma_in_progress',
  RMA_CANCELLED = 'rma_cancelled',
  RMA_SUBMITTED = 'rma_submitted',
}

export const toggleVehicleServiceStatus = (serviceStatus: ServiceStatus) => {
  if (serviceStatus === ServiceStatus.IN_SERVICE) {
    return ServiceStatus.OUT_OF_SERVICE;
  } else {
    return ServiceStatus.IN_SERVICE;
  }
};

export const formattedWholeNumber = value => {
  return value > 0 && value < 1 ? '<1' : value.toFixed(0);
};

export const FLEET_HEALTH_COLUMN_WIDTH = {
  ICON: 80,
  ICONV2: 50,
  NAME: 200,
  VIN: 300,
  DATA: 150,
  CUSTOM_DATA_OBSTRUCTION: 165,
  ACTION: 200,
  DATA_SM: 100,
  DATA_MD: 125,
};

export const goBackToDeviceHealthHome = location => {
  const urlParams = parse(location.search);
  return location.pathname + '?' + stringify(urlParams);
};

const ratioPercentage = (inService: number) => (count: number) =>
  (count / inService) * 100;

interface Ratios {
  healthyCount: number;
  healthScore: number;
  looseRatio: number;
  silentRatio: number;
  obstructedRatio: number;
}

export const getRatios = (issues: API.DeviceStatistics): Ratios => {
  if (!issues) return;

  const {
    inService,
    inServiceIssueCount,
    looseCount,
    silentCount,
    obstructedCount,
  } = issues;
  const healthyCount = inService - inServiceIssueCount;
  const ratioHelper = ratioPercentage(inService);
  return {
    healthyCount,
    healthScore: ratioHelper(healthyCount),
    looseRatio: ratioHelper(looseCount),
    silentRatio: ratioHelper(silentCount),
    obstructedRatio: ratioHelper(obstructedCount),
  };
};

export const formatLastConnectedExport = (
  vehicle: API.Vehicle,
  t: TFunction,
): string => {
  const tz = vehicle?.state?.last_known_location?.timezone;
  const ts = vehicle && getLastMessageTimestamp(vehicle);

  if (!tz || !ts) {
    return t('N/A');
  }

  return moment.tz(ts, tz).format('M/D/YYYY h:mm A z');
};
