/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import moment from 'moment-timezone';
import * as API from '@nauto/api';
import groupBy from 'lodash-es/groupBy';
import { colors } from '@nauto/core';
import { Flags } from 'components/feature-flags/flags';
import { Features } from 'models/db';
import {
  TYPE,
  FILTER,
  FILTER_DROPDOWN,
  FILTER_FOR_TYPE,
  COACHING_LABELS,
  VIOLATION_EVENT_FILTERS,
  SEVERITIES,
  ALLOWED_FILTER_MAP,
  FLAGGED_FILTER_MAP,
  DEFAULT_EVENT_FILTERS,
  FilterMapping,
  Filter,
} from 'constants/events';
import { convertHexToDecimal } from 'utils/helpers';
import { eventDisplayMap } from './event-types';
import * as recipes from './recipes';
import { UnifiedCoachingLabels } from 'constants/coaching';
import { findSelectedGroupPath } from 'components/fleet-selector/utils';
import { Group, findGroupById } from 'components/groups/groups.redux';
import { TFunction } from 'i18next';
import FilterCheckboxWrapper from './menu-bar/filter-checkbox-wrapper';

export const MPS_TO_KPH_MULTIPLIER = 3.6;
export const MPS_TO_MPH_MULTIPLIER = 2.23694;
export const mpsToKph = (mpsVal: number): number =>
  mpsVal * MPS_TO_KPH_MULTIPLIER;
export const mpsToMph = (mpsVal: number): number =>
  mpsVal * MPS_TO_MPH_MULTIPLIER;

export const eventToGeoJSONFeature = (event: API.events.Event) => {
  const color = getColor(event);
  const coordinates = getCoordinates(event);
  return {
    type: 'Feature',
    geometry: { type: 'Point', coordinates },
    properties: { color, id: getUniqueID(event) },
  } as any;
};

export const getCoordinates = (event: API.events.Event): [number, number] => {
  return (
    event.geo_info.s2_cell_id !== '1000000000000001' && [
      event.geo_info.gps.lng,
      event.geo_info.gps.lat,
    ]
  );
};

export const getAlertTimesMs = (event: API.events.Event): number[] => {
  const {
    params: { alert_times },
  } = event;

  if (!alert_times) {
    return [];
  }
  return alert_times;
};

export const getFilterGroup = (event: API.events.Event) =>
  FILTER_FOR_TYPE[event.type] || 'unknown';

export const getUniqueID = (event: API.events.Event) => event.id;

export const groupEventsByDay = (
  events: API.events.Event[],
): Record<string, API.events.Event[]> =>
  groupBy(events, event =>
    getTime(event)
      .startOf('day')
      .format(),
  );

export const groupEventsByDayWithTimezone = (
  events: API.events.Event[],
  timezone: string | undefined,
): Record<string, API.events.Event[]> =>
  groupBy(events, event =>
    getTimeWithTimezone(event, timezone || event.geo_info.timezone)
      .startOf('day')
      .format(),
  );

export const getTime = (event: API.events.Event) =>
  moment(event.timestamp).utc();

export const sortByLatestDate = (a: string, b: string): number => {
  const aMoment = moment(a);
  const bMoment = moment(b);
  return aMoment.isAfter(bMoment) ? -1 : aMoment.isBefore(bMoment) ? 1 : 0;
};
export const sortByEarliestDate = (a: string, b: string): number => {
  const aMoment = moment(a);
  const bMoment = moment(b);
  return bMoment.isAfter(aMoment) ? -1 : bMoment.isBefore(aMoment) ? 1 : 0;
};

export const getTimeWithTimezone = (
  event: API.events.Event,
  timezone: string,
) => {
  return moment(event.timestamp)
    .utc()
    .tz(timezone);
};
const SEGMENT_DURATION = 5000;
export const eventVideoStart = (event: API.events.Event) => {
  const { video_in, video_out } = event.params;
  const existingVideo = video_in ? video_in : video_out;
  const vStart = convertHexToDecimal(existingVideo[0]);
  return Math.floor(vStart / SEGMENT_DURATION) * SEGMENT_DURATION;
};

export const isDistraction = (event: API.events.Event) =>
  [TYPE.DISTRACTION, TYPE.CONFIRMED_DISTRACTION].includes(event.type);

export const isTailgating = (event: API.events.Event) =>
  [TYPE.TAILGATING, TYPE.CONFIRMED_TAILGATING].includes(event.type) ||
  event.labels?.['event-type']?.value === TYPE.TAILGATING;

export const getKind = (event: API.events.Event) =>
  (eventDisplayMap[event.type] || eventDisplayMap.default).kind;

export const getColor = (event: API.events.Event) => colors[getKind(event)];

/**
 * checks for media complete
 */
export const mediaUploadsComplete = (event: API.events.Event): boolean => {
  const { media_uploads } = event.params;
  if (!media_uploads) {
    return false;
  }
  return (
    media_uploads.uploaded + media_uploads.expired + media_uploads.failed ===
    media_uploads.expected
  );
};

/**
 * checks if any of the uploaded video segments were errors
 */
export const hasFailedVideos = (event: API.events.Event): boolean => {
  const { media_uploads } = event.params;
  return media_uploads && media_uploads.failed > 0;
};

// removes the area code from a USA address
const removeAreaCodeUSA = (location: string): string =>
  location.replace(/\s\d{5}(?:[-\s]\d{4})?, USA/g, '');
/**
 * TODO: Please refer to https://nautodev.atlassian.net/browse/NAUTO-42231
 * Once that ticket is resolved, we can uncomment the new geocode URL
 * implementation
 */
// location.replace(/\s\d{5}(?:[-\s]\d{4})?, United States/g, '');

// removes '日本、' from a japanese address
const removeJapanComma = (location: string): string =>
  location.replace(/^日本、/i, '');

export const formattedLocation = (location: string): string => {
  return removeJapanComma(removeAreaCodeUSA(location || '')).trim();
};

export const hasDeltaV = ({ params }: API.events.Event): boolean => {
  return typeof params['delta-v'] === 'number';
};

export const getDeltaV = (event: API.events.Event): number =>
  hasDeltaV(event) && event.params['delta-v'];

export const pathToGeoJSONSourceData = (states: API.trips.TripPoint[]) => {
  // ignore bad GPS points
  // don't plot if there are no non-zero points
  if (states.length === 0) {
    return null;
  }
  // convert to GeoJSONCoords
  const path = states.map(point => point.location);
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: path,
    },
  };
};

export const getSeverity = (event: API.events.Event): nauto.Severity =>
  (event.labels && event.labels.severity && event.labels.severity.value) ||
  event.params.severity;

export const hasLabel = (event: API.events.Event, label: string): boolean =>
  event &&
  event.labels &&
  event.labels[label] &&
  event.labels[label].value === 'true';

export const isCollision = (event: API.events.Event): boolean =>
  hasLabel(event, 'collision');

export const isNearCollision = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.NearCollision);

export const isSafetyIncident = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.SafetyIncident);

export const isDeviceStrike = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.DeviceStruck);

export const isDrowsiness = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.Drowsiness);

export const hasForwardCollisionWarning = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.ForwardCollisionWarning);

export const hasStopSignViolation = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.StopSign);

export const hasRollingStopSignViolation = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.RollingStopSign);

export const hasPedestrianCollisionWarning = (
  event: API.events.Event,
): boolean => hasLabel(event, UnifiedCoachingLabels.PedestrianCollisionWarning);

export const hasIntersectionViolationWarning = (
  event: API.events.Event,
): boolean =>
  hasLabel(event, UnifiedCoachingLabels.IntersectionViolationWarning);

export const isSpeeding = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.Speeding);

export const isBackingUp = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.BackingUp);

export const isFcwPlusDistraction = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.ForwardCollisionWarningDistraction);

export const isPcwPlusDistraction = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.PedestrianCollisionWarningDistraction);

export const isTailgatingPlusDistraction = (event: API.events.Event): boolean =>
  hasLabel(event, UnifiedCoachingLabels.TailgatingDistraction);

/**
 *
 * @param event
 * @returns true if one of: fcw +distraction, pcw +distraction, tailgating +distraction
 */
export const isComboDistractionEvent = (event: API.events.Event): boolean =>
  isFcwPlusDistraction(event) ||
  isPcwPlusDistraction(event) ||
  isTailgatingPlusDistraction(event);

export const isViolation = (type: nauto.EventType): boolean =>
  VIOLATION_EVENT_FILTERS.includes(type);

export const isRepeatedEvent = (ev: API.events.Event): boolean =>
  !!ev?.params?.media_locked_out;

export const getEventLabels = (
  event: API.events.Event,
): Record<string, unknown> => event && event.labels;

export const getEventLabel = (event: API.events.Event, label: string): string =>
  event && event.labels[label] && event.labels[label].value;

export const buildRecipes = ({
  filters,
  severity = SEVERITIES.LOW,
  coaching,
  flags,
}: {
  filters: string[];
  severity: number;
  coaching?: string;
  flags?: Flags;
}): API.events.Recipe[] => {
  const sev = Number(severity);

  const hasFilter = (filter: string) => filters.includes(filter);
  const {
    riskBasedViolations,
    riskBasedTailgating,
    riskBasedDistraction,
    riskBasedAbc,
    repeatedEventsVideoRequest,
  } = flags;

  return [
    ...(hasFilter(FILTER.ACCELERATION)
      ? recipes.acceleration({
          severity: sev,
          coaching,
          riskBased: riskBasedAbc,
        })
      : []),
    ...(hasFilter(FILTER.BRAKING)
      ? recipes.braking({ severity: sev, coaching, riskBased: riskBasedAbc })
      : []),
    ...(hasFilter(FILTER.CORNERING)
      ? recipes.cornering({ severity: sev, coaching, riskBased: riskBasedAbc })
      : []),
    ...(hasFilter(FILTER.DISTRACTION)
      ? recipes.distraction({
          severity,
          coaching,
          riskBased: riskBasedDistraction,
          repeatedEvents: repeatedEventsVideoRequest,
        })
      : []),
    ...(hasFilter(FILTER.TAILGATING)
      ? recipes.tailgating({
          severity,
          riskBased: riskBasedTailgating,
          coaching,
        })
      : []),
    ...(hasFilter(FILTER.MARKED) ? [recipes.marked(coaching)] : []),
    ...(hasFilter(FILTER.REQUESTED) ? [recipes.requests(coaching)] : []),
    ...(hasFilter(FILTER.COLLISION) ? [recipes.collisions(sev, coaching)] : []),
    ...(hasFilter(FILTER.CELL_PHONE)
      ? recipes.cellPhone({
          severity,
          coaching,
          riskBased: riskBasedViolations,
        })
      : []),
    ...(hasFilter(FILTER.SMOKING)
      ? recipes.smoking({ severity, coaching, riskBased: riskBasedViolations })
      : []),

    ...(hasFilter(FILTER.NEAR_COLLISION)
      ? [recipes.nearCollision(sev, coaching)]
      : []),
    ...(hasFilter(FILTER.SEATBELT)
      ? recipes.seatbelt({ severity, coaching, riskBased: riskBasedViolations })
      : []),
    ...(hasFilter(FILTER.FORWARD_COLLISION_WARNING)
      ? [recipes.forwardCollisionWarning(sev, coaching)]
      : []),
    ...(hasFilter(FILTER.DROWSINESS)
      ? [recipes.drowsiness(sev, coaching)]
      : []),
    ...(hasFilter(FILTER.SPEEDING) ? [recipes.speeding(sev, coaching)] : []),
    ...(hasFilter(FILTER.BACKING_UP) ? [recipes.backingUp(sev, coaching)] : []),
    ...(hasFilter(FILTER.PEDESTRIAN_COLLISION_WARNING)
      ? [recipes.pedestrianCollisionWarning(sev, coaching)]
      : []),
    ...(hasFilter(FILTER.ROAD_RAGE) ? [recipes.roadRage(sev, coaching)] : []),
    ...(hasFilter(FILTER.HIT_AND_RUN)
      ? [recipes.hitAndRun(sev, coaching)]
      : []),
    ...(hasFilter(FILTER.STOP_SIGN) ? [recipes.stopSign(sev, coaching)] : []),
    ...(hasFilter(FILTER.ROLLING_STOP)
      ? [recipes.rollingStopSign(sev, coaching)]
      : []),
    ...(hasFilter(FILTER.INTERSECTION_VIOLATION_WARNING)
      ? [recipes.intersectionViolationWarning(sev, coaching)]
      : []),
    ...(hasFilter(FILTER.REPEATED_EVENTS) ? [recipes.repeatedEvents(sev)] : []),
    ...(hasFilter(FILTER.SAFETY_INCIDENT) ? [recipes.safetyIncident(sev)] : []),
    ...(hasFilter(FILTER.TAILGATING_AND_DISTRACTION)
      ? [recipes.tailgatingPlusDistraction(sev)]
      : []),
    ...(hasFilter(FILTER.FCW_AND_DISTRACTION)
      ? [recipes.fcwPlusDistraction(sev)]
      : []),
    ...(hasFilter(FILTER.PCW_AND_DISTRACTION)
      ? [recipes.pcwPlusDistraction(sev)]
      : []),
    ...(hasFilter(FILTER.DEVICE_STRIKE) ? [recipes.deviceStrike(sev)] : []),
  ];
};

export const getAllowedFilters = (
  fleetFeatures: Features,
  featureFlags: Flags,
): Record<string, boolean> => {
  const { event_types: eventTypes = [] } = fleetFeatures;
  const allowed: FilterMapping<boolean> = {};

  for (const filter in DEFAULT_EVENT_FILTERS) {
    const allowedEventKey = ALLOWED_FILTER_MAP[filter];
    const featureFlagKey = FLAGGED_FILTER_MAP[filter];

    if (!allowedEventKey && !featureFlagKey) {
      allowed[filter] = true;
    }

    if (allowedEventKey) {
      allowed[filter] = eventTypes.includes(allowedEventKey);
    }

    if (featureFlagKey) {
      allowed[filter] =
        allowed[filter] || featureFlagKey.every(f => featureFlags[f]);
    }
  }
  const denyList = featureFlags.eventsDenyList;

  if (!denyList.length) {
    return allowed;
  }

  const filtered = Object.entries(allowed).reduce(
    (acc, [k, v]) => ({
      ...acc,
      ...(denyList.includes(k) ? { [k]: false } : { [k]: v }),
    }),
    {},
  );

  return filtered;
};

export const generateTripFilters = (
  allowedFilters: Record<string, boolean>,
  flags?: Flags,
): API.events.Recipe[] => {
  const {
    riskBasedViolations,
    riskBasedTailgating,
    riskBasedDistraction,
    riskBasedAbc,
  } = flags || {};
  return [
    ...(allowedFilters[FILTER.COLLISION] ? [recipes.collisions()] : []),
    ...(allowedFilters[FILTER.DISTRACTION]
      ? recipes.distraction({
          severity: SEVERITIES.LOW,
          riskBased: riskBasedDistraction,
        })
      : []),
    ...(allowedFilters[FILTER.CORNERING]
      ? recipes.cornering({ severity: SEVERITIES.LOW, riskBased: riskBasedAbc })
      : []),
    ...(allowedFilters[FILTER.ACCELERATION]
      ? recipes.acceleration({
          severity: SEVERITIES.LOW,
          riskBased: riskBasedAbc,
        })
      : []),
    ...(allowedFilters[FILTER.BRAKING]
      ? recipes.braking({ severity: SEVERITIES.LOW, riskBased: riskBasedAbc })
      : []),
    ...(allowedFilters[FILTER.TAILGATING]
      ? recipes.tailgating({
          severity: SEVERITIES.LOW,
          riskBased: riskBasedTailgating,
        })
      : []),
    ...(allowedFilters[FILTER.MARKED] ? [recipes.marked()] : []),
    ...(allowedFilters[FILTER.REQUESTED] ? [recipes.requests()] : []),
    ...(allowedFilters[FILTER.CELL_PHONE]
      ? recipes.cellPhone({
          severity: SEVERITIES.LOW,
          riskBased: riskBasedViolations,
        })
      : []),
    ...(allowedFilters[FILTER.SMOKING]
      ? recipes.smoking({
          severity: SEVERITIES.LOW,
          riskBased: riskBasedViolations,
        })
      : []),
    ...(allowedFilters[FILTER.SEATBELT]
      ? recipes.seatbelt({
          severity: SEVERITIES.LOW,
          riskBased: riskBasedViolations,
        })
      : []),
    ...(allowedFilters[FILTER.NEAR_COLLISION] ? [recipes.nearCollision()] : []),
    ...(allowedFilters[FILTER.DROWSINESS]
      ? [recipes.drowsiness(SEVERITIES.LOW)]
      : []),
    ...(allowedFilters[FILTER.SPEEDING]
      ? [recipes.speeding(SEVERITIES.LOW)]
      : []),
    ...(allowedFilters[FILTER.ROLLING_STOP] ? [recipes.rollingStopSign()] : []),
    ...(allowedFilters[FILTER.STOP_SIGN] ? [recipes.stopSign()] : []),
    ...(allowedFilters[FILTER.INTERSECTION_VIOLATION_WARNING]
      ? [recipes.intersectionViolationWarning()]
      : []),
    ...(allowedFilters[FILTER.SAFETY_INCIDENT]
      ? [recipes.safetyIncident()]
      : []),
  ];
};

export const getEventFilterLabels = ({
  t,
}: {
  t: (trans: string) => string;
}) => ({
  [FILTER.MARKED]: t('Marked'),
  [FILTER.REQUESTED]: t('Requested'),
  [FILTER.ACCELERATION]: t('Acceleration'),
  [FILTER.BRAKING]: t('Braking'),
  [FILTER.CORNERING]: t('Cornering'),
  [FILTER.DISTRACTION]: t('Distraction'),
  [FILTER.TAILGATING]: t('Tailgating'),
  [FILTER.COLLISION]: t('Collision'),
  [FILTER.NEAR_COLLISION]: t('Near collision'),
  [FILTER.SMOKING]: t('Smoking'),
  [FILTER.CELL_PHONE]: t('Cell phone'),
  [FILTER.SEATBELT]: t('No seat belt'),
  [FILTER.FORWARD_COLLISION_WARNING]: t('Forward collision warning'),
  [FILTER.PEDESTRIAN_COLLISION_WARNING]: t('Pedestrian collision warning'),
  [FILTER.DROWSINESS]: t('Drowsiness'),
  [FILTER.SPEEDING]: t('Speeding'),
  [FILTER.BACKING_UP]: t('Backing up'),
  [FILTER.ROAD_RAGE]: t('Road rage'),
  [FILTER.HIT_AND_RUN]: t('Hit and run'),
  [FILTER.STOP_SIGN]: t('Stop sign'),
  [FILTER.ROLLING_STOP]: t('Rolling stop sign'),
  [FILTER.INTERSECTION_VIOLATION_WARNING]: t('Intersection violation warning'),
  [FILTER.REPEATED_EVENTS]: t('Repeated events'),
  [FILTER.SAFETY_INCIDENT]: t('Safety incident'),
  [FILTER.TAILGATING_AND_DISTRACTION]: t('Tailgating + distraction'),
  [FILTER.FCW_AND_DISTRACTION]: t('Forward collision warning + distraction'),
  [FILTER.PCW_AND_DISTRACTION]: t('Pedestrian collision warning + distraction'),
  [FILTER.DEVICE_STRIKE]: t('Device strike'),
});

export const policyViolationsWhiteList = [
  FILTER.CELL_PHONE,
  FILTER.SMOKING,
  FILTER.SEATBELT,
  FILTER.BACKING_UP,
  FILTER.SPEEDING,
  UnifiedCoachingLabels.Cellphone,
  UnifiedCoachingLabels.Smoking,
  UnifiedCoachingLabels.Seatbelt,
  UnifiedCoachingLabels.BackingUp,
  UnifiedCoachingLabels.Speeding,
  UnifiedCoachingLabels.DeviceStruck,
  FILTER.DEVICE_STRIKE,
];

export const buildEventsFilterLabel = ({
  filters: rawFilters,
  categories,
  t,
  vera3 = false,
}: {
  filters: string[];
  categories: Filter[];
  t: (trans: string) => string;
  vera3?: boolean;
}): string => {
  const filters = Array.from(new Set(rawFilters));

  if (!filters || filters.length === 0) {
    return t('Focus areas');
  }

  const labels = getEventFilterLabels({ t, vera3 });

  const baseLabel = filters
    .slice(0, 2)
    .map(value => labels[value])
    .join(', ');

  if (filters.length < 3) {
    return baseLabel;
  }

  const allowedFiltersLength = categories.reduce((accum, category) => {
    return accum + category.options.length;
  }, 0);

  return filters.length === allowedFiltersLength
    ? t('All focus areas')
    : `${baseLabel} +${filters.length - 2}`;
};

export const buildSeverityFilterLabel = ({
  severityNumber,
  t,
}: {
  severityNumber: number;
  t: (trans: string) => string;
}): string => {
  switch (severityNumber) {
    case 1:
      return t('All severity');
    case 2:
      return `${t('Medium')} - ${t('High')}`;
    case 3:
      return t('High');
    default:
      return t('Severity');
  }
};

export const buildCoachingFilterLabel = ({
  filter,
  t,
}: {
  filter: any;
  t: (trans: string) => string;
}): string => {
  if (filter) {
    switch (filter) {
      case COACHING_LABELS.COACHED:
        return t('Coached');
      case COACHING_LABELS.COACHABLE:
        return t('Not yet coached');
      case COACHING_LABELS.UNCOACHABLE:
        return t('Marked as not coachable');
      default:
        return t('Coaching');
    }
  } else {
    return t('All events');
  }
};

export const coachingOptions = ({
  stagedFilters,
  t,
}: {
  stagedFilters: any;
  t: (trans: string) => string;
}): any[] => {
  return [
    {
      label: t('All events'),
      sublabel: t('Including non-coaching related events'),
      value: '',
      checked: !stagedFilters,
    },
    {
      label: t('Coached'),
      value: COACHING_LABELS.COACHED,
      checked: stagedFilters === COACHING_LABELS.COACHED,
    },
    {
      label: t('Not yet coached'),
      value: COACHING_LABELS.COACHABLE,
      checked: stagedFilters === COACHING_LABELS.COACHABLE,
    },
    {
      label: t('Marked as not coachable'),
      value: COACHING_LABELS.UNCOACHABLE,
      checked: stagedFilters === COACHING_LABELS.UNCOACHABLE,
    },
  ];
};

const getOptionsMapper = ({ labels, allowedFilters }) => ({
  value,
  options,
  checkboxWrapper,
}) => ({
  label: labels[value],
  value: value,
  hide: !allowedFilters[value],
  ...(options
    ? {
        options: options.map(getOptionsMapper({ labels, allowedFilters })),
        hide:
          !allowedFilters[value] ||
          !options.some(option => !allowedFilters[option]),
      }
    : {}),
  showAsterisk: policyViolationsWhiteList.includes(value),
  checkboxWrapper,
});

export const eventsFilterOptions = ({ allowedFilters, t, excludedFilters }) => {
  const labels = getEventFilterLabels({ t });
  const shouldShowParent = option => !option.hide;

  const removeExcludedFilters = (
    eventFilters: {
      value: typeof FILTER[keyof typeof FILTER];
      checkboxWrapper?: React.ReactNode;
    }[],
  ) => eventFilters.filter(f => !excludedFilters.includes(f.value));
  const optionMapper = getOptionsMapper({ labels, allowedFilters });

  const activityOptions = removeExcludedFilters([
    { value: FILTER.MARKED },
    { value: FILTER.REQUESTED },
  ]).map(optionMapper);

  const nonComplianceOptions = removeExcludedFilters([
    { value: FILTER.BACKING_UP },
  ]).map(optionMapper);

  const highRiskOptions = removeExcludedFilters([
    { value: FILTER.COLLISION },
    { value: FILTER.FORWARD_COLLISION_WARNING },
    { value: FILTER.NEAR_COLLISION },
    { value: FILTER.PEDESTRIAN_COLLISION_WARNING },
    { value: FILTER.INTERSECTION_VIOLATION_WARNING },
    { value: FILTER.SAFETY_INCIDENT },
  ]).map(optionMapper);

  const compoundRiskOptions = removeExcludedFilters([
    {
      value: FILTER.TAILGATING_AND_DISTRACTION,
      checkboxWrapper: ({ children }) => (
        <FilterCheckboxWrapper>{children}</FilterCheckboxWrapper>
      ),
    },
    {
      value: FILTER.FCW_AND_DISTRACTION,
      checkboxWrapper: ({ children }) => (
        <FilterCheckboxWrapper>{children}</FilterCheckboxWrapper>
      ),
    },
    {
      value: FILTER.PCW_AND_DISTRACTION,
      checkboxWrapper: ({ children }) => (
        <FilterCheckboxWrapper>{children}</FilterCheckboxWrapper>
      ),
    },
  ]).map(optionMapper);

  const inattentionOptions = removeExcludedFilters([
    { value: FILTER.CELL_PHONE },
    // @todo chrischuck add sub-options if/when available
    { value: FILTER.DISTRACTION },
    { value: FILTER.DROWSINESS },
    { value: FILTER.SMOKING },
  ]).map(optionMapper);

  const trafficViolationOptions = removeExcludedFilters([
    { value: FILTER.SEATBELT },
    // @todo chrischuck add sub-options if/when available
    { value: FILTER.SPEEDING },
    { value: FILTER.STOP_SIGN },
    { value: FILTER.ROLLING_STOP },
  ]).map(optionMapper);

  const aggressiveDrivingOptions = removeExcludedFilters([
    { value: FILTER.CORNERING },
    { value: FILTER.ACCELERATION },
    { value: FILTER.BRAKING },
    // @todo chrischuck add risky maneuver
    { value: FILTER.TAILGATING },
    { value: FILTER.ROAD_RAGE },
    { value: FILTER.HIT_AND_RUN },
    { value: FILTER.DEVICE_STRIKE },
  ]).map(optionMapper);

  return [
    {
      label: t('High risk'),
      title: FILTER_DROPDOWN.HIGH_RISK,
      hide: !highRiskOptions.some(shouldShowParent),
      options: highRiskOptions,
    },
    {
      label: t('Combo events'),
      title: FILTER_DROPDOWN.COMPOUND_RISK,
      hide: !compoundRiskOptions.some(shouldShowParent),
      options: compoundRiskOptions,
      checkboxWrapper: ({ children }) => (
        <FilterCheckboxWrapper>{children}</FilterCheckboxWrapper>
      ),
    },
    {
      label: t('Inattention'),
      title: FILTER_DROPDOWN.INATTENTION,
      hide: !inattentionOptions.some(shouldShowParent),
      options: inattentionOptions,
    },
    {
      label: t('Traffic  violation'),
      title: FILTER_DROPDOWN.TRAFFIC_VIOLATION,
      hide: !trafficViolationOptions.some(shouldShowParent),
      options: trafficViolationOptions,
    },
    {
      label: t('Aggressive driving'),
      title: FILTER_DROPDOWN.AGGRESSIVE_DRIVING,
      hide: !aggressiveDrivingOptions.some(shouldShowParent),
      options: aggressiveDrivingOptions,
    },
    {
      label: t('Non-compliance'),
      title: FILTER_DROPDOWN.NON_COMPLIANCE,
      hide: !nonComplianceOptions.some(shouldShowParent),
      options: nonComplianceOptions,
    },
    {
      label: t('Requests'),
      title: FILTER_DROPDOWN.REQUESTS,
      hide: !activityOptions.some(shouldShowParent),
      options: activityOptions,
    },
  ];
};

export const getBaseEventType = (
  flags: Flags,
  event: API.events.Event,
): string => {
  const riskEventTypes = [
    'riskBasedTailgating',
    'riskBasedDistraction',
    'riskBasedAbc',
  ];
  const eventTypeLabel = event?.labels?.['event-type']?.value;
  const baseEventType =
    riskEventTypes.some(type => flags[type]) && !!eventTypeLabel
      ? eventTypeLabel
      : event.type;

  return baseEventType;
};

export const getEventType = ({
  event,
  featureFlags,
  combo = false,
}: {
  event?: API.events.Event;
  featureFlags?: Flags;
  combo?: boolean;
}): string | string[] => {
  const baseEventType = getBaseEventType(featureFlags, event);
  const subtype = event.params.type;
  const hasSubtype = subtype && isViolation(subtype);
  const eventType = hasSubtype ? subtype : baseEventType;
  const tailgatingPlusDistraction = isTailgatingPlusDistraction(event);
  const pcwPlusDistraction = isPcwPlusDistraction(event);
  const fcwPlusDistraction = isFcwPlusDistraction(event);
  const pcwEvent = hasPedestrianCollisionWarning(event);
  const fcwEvent = hasForwardCollisionWarning(event);
  const distraction = isDistraction(event) ? 'd' : '';
  const rollingStopSign =
    featureFlags.postFactoStopSign && hasRollingStopSignViolation(event);
  const stopSignViolation =
    featureFlags.postFactoStopSign && hasStopSignViolation(event);
  const ivwEvent =
    featureFlags.intersectionViolationWarning &&
    hasIntersectionViolationWarning(event);
  const safetyIncidentEvent =
    featureFlags.safetyIncidentEvent && isSafetyIncident(event);

  const deviceStrikeEvent = featureFlags.deviceStrike && isDeviceStrike(event);
  const types = [];

  if (isCollision(event)) {
    types.push('severe-g-event');
  }

  if (deviceStrikeEvent) {
    types.push('device-strike');
  }

  if (featureFlags.nearCollisionEvents && isNearCollision(event)) {
    types.push('near-collision');
  }

  if (featureFlags.drowsiness && isDrowsiness(event)) {
    types.push('drowsiness');
  }

  if (featureFlags.postedSpeedLimit && isSpeeding(event)) {
    types.push('speeding');
  }

  if (pcwEvent) {
    types.push(`pcw${distraction}`);
  }

  if (fcwEvent) {
    types.push(`fcw${distraction}`);
  }

  if (ivwEvent) {
    types.push('ivw');
  }

  if (stopSignViolation) {
    types.push('stop-sign-violation');
  }

  if (rollingStopSign) {
    types.push('rolling-stop');
  }
  if (featureFlags.safetyIncidentEvent && safetyIncidentEvent) {
    types.push('safety-incident');
  }

  if (hasSubtype) {
    types.push(subtype);
  }

  // Only add this label, if it is not a crashnet event
  // since crashnet defaults to collision, which we parse
  // above using isCollision()
  if (eventType !== 'crashnet' && eventDisplayMap[eventType]) {
    types.push(eventDisplayMap[eventType].name);
  }

  if (types.length < 1) {
    types.push(eventDisplayMap.default.name);
  }

  if (combo) {
    return types;
  }

  const type = deviceStrikeEvent
    ? 'device-strike'
    : isCollision(event)
    ? 'severe-g-event'
    : featureFlags.nearCollisionEvents && isNearCollision(event)
    ? 'near-collision'
    : featureFlags.comboDistractionEvents && tailgatingPlusDistraction
    ? 'tailgating-plus-distraction'
    : featureFlags.comboDistractionEvents &&
      featureFlags.pedestrianCollisionWarning &&
      pcwPlusDistraction
    ? 'pcw-plus-distraction'
    : featureFlags.comboDistractionEvents &&
      featureFlags.forwardCollisionWarning &&
      fcwPlusDistraction
    ? 'fcw-plus-distraction'
    : featureFlags.drowsiness && isDrowsiness(event)
    ? 'drowsiness'
    : featureFlags.postedSpeedLimit && isSpeeding(event)
    ? 'speeding'
    : pcwEvent
    ? `pcw${distraction}`
    : fcwEvent
    ? `fcw${distraction}`
    : ivwEvent
    ? 'ivw'
    : stopSignViolation
    ? 'stop-sign-violation'
    : rollingStopSign
    ? 'rolling-stop'
    : safetyIncidentEvent
    ? 'safety-incident'
    : hasSubtype
    ? subtype
    : eventDisplayMap[eventType]
    ? eventDisplayMap[eventType].name
    : eventDisplayMap.default.name;

  return type;
};

export const createGroupNames = (rootGroup: Group, group: Group) => {
  const groupPath = findSelectedGroupPath(group.id, rootGroup);
  const titles = [];

  groupPath.forEach((g: string) => {
    const groupDetails = findGroupById(rootGroup, g);
    if (groupDetails) {
      titles.push(groupDetails.name);
    }
  });

  return titles;
};

export const eventTypeValues = (type: string) => {
  switch (type) {
    case 'crashnet':
    case 'possible-collision':
    case 'severe-g-event':
      return 'collision';
    default:
      return type;
  }
};

export const formatTime = (
  timestamp: string,
  t: TFunction,
  timezone?: string,
) => {
  const time = moment.tz(timestamp, timezone || moment.tz.guess());

  return `${time.format('MMMM Do, YYYY (dddd)')} ${t('at')} ${time.format(
    'HH:mm A z',
  )}`;
};
