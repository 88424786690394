import React from 'react';
import styled from 'styled-components';
import { colors } from '@nauto/uikit/dist/styles';
import { Icon, IconName } from '@nauto/uikit/dist/atoms/icons';
import { Size } from '@nauto/uikit/dist/types';
import { textSizesPx, spaceSizesPx } from '@nauto/core';

interface Props {
  className?: string;
  title: string;
  children: any;
  icon?: IconName;
  iconColor?: string;
  iconSize?: Size;
}

class IconPanel extends React.Component<Props, any> {
  static defaultProps = {
    iconColor: colors.alert,
    iconSize: 'lg' as Size,
  };

  render() {
    const {
      className,
      title,
      children,
      icon,
      iconColor,
      iconSize,
    } = this.props;

    return (
      <div className={className}>
        <div className="action-panel-wrap">
          {icon && (
            <div className="action-panel-icon-wrap">
              <Icon
                icon={icon}
                baseColor={iconColor}
                size={iconSize}
                className="action-panel-icon"
              />
            </div>
          )}
          <div className="action-panel-title">{title}</div>
          <div className="action-panel-container">{children}</div>
        </div>
      </div>
    );
  }
}

const StyledIconPanel = styled(IconPanel)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .action-panel-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .action-panel-icon-wrap {
      position: relative;
      display: flex;
      padding: ${spaceSizesPx.lg};
      border-radius: 100%;
      :after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100%;
        opacity: 0.12;
        background: ${colors.success};
      }
    }

    .action-panel-title {
      font-size: ${textSizesPx.lg};
      font-family: ${({ theme }) => theme.fonts.bold};
      margin: ${spaceSizesPx.md} 0;
    }

    .action-panel-container {
      width: 100%;
      text-align: center;
    }
  }
`;

export default StyledIconPanel;
