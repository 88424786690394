import React from 'react';
import { Box, spaceSizesPx, ScreenBreakPoints } from '@nauto/core';
import {
  maxCenteredContentWidth,
  minCenteredContentWidth,
} from 'constants/layout';
import styled from 'styled-components';

interface Props {
  style?: React.CSSProperties;
  className?: string;
  children?: any;
  direction?: 'row' | 'column';
}

const CenteredBox: React.FC<Props> = props => {
  const { style, className, children, direction = 'column' } = props;
  return (
    <Box style={style} className={className} direction="row" justify="center">
      <CenterBox
        style={{
          width: '100%',
          flexGrow: 1,
        }}
        paddingLeft="xl"
        paddingRight="xl"
        direction={direction}
      >
        {children}
      </CenterBox>
    </Box>
  );
};

const CenterBox = styled(Box)`
  padding-left: ${spaceSizesPx.xl};
  padding-right: ${spaceSizesPx.xl};
  max-width: ${maxCenteredContentWidth}px;
  min-width: ${minCenteredContentWidth}px;

  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    padding-left: ${spaceSizesPx.sm};
    padding-right: ${spaceSizesPx.sm};
    max-width: 100%;
    min-width: 0;
  }
`;

export default CenteredBox;
