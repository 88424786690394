import React from 'react';
import {
  HelpIcon,
  UserIcon,
  SettingsIcon,
  GroupIcon,
  CoachingIcon,
  ExitIcon,
  ReportIcon,
  InboxIcon,
  TagDriverIcon,
  CustomVideoRequestIcon,
  DeviceIcon,
} from '@nauto/icons';
import { ROUTES } from 'routes/constants';
import { isInIframe } from 'utils/helpers';
import { createSelector } from 'reselect';
import {
  currentFleetDataSelector,
  isFleetAdminSelector,
  isLoggedInUserTypeDriverSelector,
  isUserManagementAvailableSelector,
} from 'components/auth/auth.reducer';
import { mpTrack } from 'components/mixpanel';
import { MixpanelEventKeys } from 'components/mixpanel/tags';
import { featureFlagsSelector } from 'components/feature-flags/flag-selectors';
import { linkKeys } from 'utils/i18n-link-keys';
import { Permissions } from 'hooks/use-permissions';
import config from 'utils/config';
import { Flags } from 'components/feature-flags/flags';
import { PACKAGE } from 'components/feature/constants';
import { FleetAccess } from 'models/db';

export interface MenuItemProps {
  appcuesId: string;
  icon: JSX.Element;
  key: string;
  dataTestId: string;
  label: string;
  to: ROUTES | any;
  show: boolean;
  onItemClick?: () => void;
  match?: ROUTES[] | any;
  isFleetLink?: boolean;
  target?: string;
}

const reduceUserMenuItems = (
  isFleetAdmin: any,
  featureFlags: Flags,
  isUserManagementAvailable: boolean,
  isLoggedInUserTypeDriver: boolean,
  currentFleetData: FleetAccess,
  props: any,
): MenuItemProps[] => {
  const { t, forMobileNavMenu, hasPermission } = props;
  const legacySupportUrl = featureFlags.zendeskNewUrl
    ? config.japan_zendesk_url
    : config.zendesk_url;
  const helpCenterURI = featureFlags.sfHelpcenter ? '/samlp' : legacySupportUrl;
  const productTrainingURI = featureFlags.sfHelpCenterPages
    ? t(linkKeys.productTraining)
    : t(linkKeys.productTrainingZendesk);
  const showUsers = !isInIframe() && isFleetAdmin;
  const showSettings = isFleetAdmin;
  const showLogout = !isInIframe();
  const isCustomUserManagementEnabled: boolean = hasPermission(
    Permissions.CustomUserManagement,
    true,
  );
  const showFleetSettings = isCustomUserManagementEnabled
    ? hasPermission(Permissions.FleetSettings, 'read-only') ||
      hasPermission(Permissions.FleetSettings, 'full-access')
    : showSettings;

  const showDeveloperSettings = isCustomUserManagementEnabled
    ? featureFlags.developerDocs &&
      (hasPermission(Permissions.Developer, 'read-only') ||
        hasPermission(Permissions.Developer, 'full-access'))
    : featureFlags.developerDocs;

  const showUserManagement = isCustomUserManagementEnabled
    ? hasPermission(Permissions.UserManagement, 'read-only') ||
      hasPermission(Permissions.UserManagement, 'full-access')
    : showUsers;

  const showRoleManagement =
    isCustomUserManagementEnabled &&
    (hasPermission(Permissions.RoleManagement, 'read-only') ||
      hasPermission(Permissions.RoleManagement, 'full-access'));

  const isEssentialFleet = hasPermission(Permissions.NautoEssential);
  /* tslint:disable:no-duplicate-string */
  return [
    {
      appcuesId: 'mobile-nav-menu-button-notifications',
      icon: <InboxIcon />,
      key: 'notifications',
      dataTestId: 'notificationsNavItem',
      label: t('Notifications'),
      to: ROUTES.NOTIFICATIONS,
      show: !!forMobileNavMenu && !isLoggedInUserTypeDriver,
      onItemClick: () => {
        mpTrack(MixpanelEventKeys.NavNotification);
      },
      isFleetLink: true,
      match: [ROUTES.NOTIFICATIONS],
      target: '',
    },
    {
      appcuesId: 'mobile-nav-menu-button-video-requests',
      icon: <CustomVideoRequestIcon />,
      key: 'video-requests',
      dataTestId: 'videoRequestsNavItem',
      label: t('Video Requests'),
      to: ROUTES.VIDEO_REQUESTS,
      show: !!forMobileNavMenu && !isLoggedInUserTypeDriver,
      onItemClick: () => {
        mpTrack(MixpanelEventKeys.NavVideoRequests);
      },
      isFleetLink: true,
      match: [ROUTES.VIDEO_REQUESTS],
      target: '',
    },
    {
      appcuesId: 'user-menu-button-profile',
      icon: <UserIcon />,
      key: 'profile',
      dataTestId: 'userProfileNavItem',
      label: t('Account'),
      to: ROUTES.PROFILE,
      show: !isLoggedInUserTypeDriver,
      onItemClick: () => {
        mpTrack(MixpanelEventKeys.NavUserAccount);
      },
      isFleetLink: true,
      match: [ROUTES.PROFILE],
      target: '',
    },
    {
      // @TODO UM
      appcuesId: 'user-menu-button-user-management',
      icon: <TagDriverIcon />,
      key: 'user-management',
      dataTestId: 'userManagementNavItem',
      label: t('User management'),
      to: ROUTES.USERS,
      show: !isLoggedInUserTypeDriver && showUserManagement,
      isFleetLink: true,
      match: [ROUTES.USERS],
      target: '',
    },
    {
      appcuesId: 'user-menu-button-role-management',
      icon: <GroupIcon />,
      key: 'role-management',
      dataTestId: 'roleManagementNavItem',
      label: t('Role management'),
      to: ROUTES.ROLES,
      // @TODO UM
      show: !isLoggedInUserTypeDriver && showRoleManagement,
      isFleetLink: true,
      match: [ROUTES.ROLES],
      target: '',
    },
    {
      appcuesId: 'user-menu-button-fleet-settings',
      icon: <SettingsIcon />,
      key: 'fleet-settings',
      dataTestId: 'fleetSettingsNavItem',
      label: t('Fleet settings'),
      to: { pathname: ROUTES.SETTINGS, search: { direct: true } },
      // @TODO UM
      show: !isLoggedInUserTypeDriver && showFleetSettings,
      isFleetLink: true,
      match: [ROUTES.SETTINGS],
      target: '',
    },
    {
      appcuesId: 'user-menu-button-help-center',
      icon: <HelpIcon />,
      key: 'help-center',
      dataTestId: 'helpCenterNavItem',
      label: t('Help center'),
      to: helpCenterURI,
      show: !isLoggedInUserTypeDriver,
      onItemClick: () => {
        mpTrack(MixpanelEventKeys.NavHelpCenter);
      },
      isFleetLink: false,
      match: [helpCenterURI],
      target: '_blank',
    },
    {
      appcuesId: 'user-menu-button-product-training',
      icon: <CoachingIcon />,
      key: 'product-training',
      dataTestId: 'productTrainingNavItem',
      label: t('Product training'),
      to: productTrainingURI,
      show: !!productTrainingURI && !isLoggedInUserTypeDriver,
      onItemClick: () => {
        mpTrack(MixpanelEventKeys.NavProductTraining);
      },
      isFleetLink: false,
      match: [productTrainingURI],
      target: '_blank',
    },
    {
      appcuesId: 'user-menu-button-csv-install',
      icon: <DeviceIcon />,
      key: 'install-devices',
      dataTestId: 'installDevicesNavItem',
      label: t('Install devices'),
      to: ROUTES.CSV_INSTALLER,
      show:
        !isLoggedInUserTypeDriver &&
        hasPermission(Permissions.InstallerCsvUpload, true),
      // onItemClick: () => {
      //   mpTrack(MixpanelEventKeys.NavProductTraining);
      // },
      isFleetLink: true,
      match: [ROUTES.CSV_INSTALLER],
      target: '',
    },
    {
      appcuesId: 'user-menu-button-developer-settings',
      icon: <ReportIcon />,
      key: 'developer-settings',
      dataTestId: 'developerSettingsNavItem',
      label: t('Developer'),
      to: ROUTES.DEVELOPER_DOCS,
      show:
        !isLoggedInUserTypeDriver && showDeveloperSettings && !isEssentialFleet,
      isFleetLink: true,
      match: [ROUTES.DEVELOPER_DOCS],
      target: '',
    },
    {
      appcuesId: 'user-menu-button-logout',
      icon: <ExitIcon />,
      key: 'logout',
      dataTestId: 'signOutNavItem',
      label: t('Sign out'),
      to: ROUTES.LOGOUT,
      show: showLogout,
      isFleetLink: false,
      match: [ROUTES.LOGOUT],
      target: '_self',
    },
  ];
};

export const userMenuItemsSelector = createSelector(
  [
    isFleetAdminSelector,
    featureFlagsSelector,
    isUserManagementAvailableSelector,
    isLoggedInUserTypeDriverSelector,
    currentFleetDataSelector,
    (state, props) => {
      return props;
    },
  ],
  reduceUserMenuItems,
);
