import * as API from '@nauto/api';
import useFleetData from 'hooks/use-fleet-data';
import useGroupData from 'hooks/use-group-data';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../constants';

const useDeviceIssuesQuery = (enabled = true): typeof deviceIssuesQuery => {
  const { id: fleetId } = useFleetData({ current: true });
  const { id: groupId } = useGroupData();

  const fetchDeviceIssues = async () => {
    const response = await API.vehicles.getVehicleStats({
      fleetId,
      groupId,
    });

    return response;
  };

  const deviceIssuesQuery = useQuery(
    [QUERY_KEYS.VEHICLE_STATS, { fleetId, groupId }],
    fetchDeviceIssues,
    {
      staleTime: 1000 * 120,
      enabled,
    },
  );

  return deviceIssuesQuery;
};

export default useDeviceIssuesQuery;
