import VeraScoreTypeDataContext from 'hooks/use-vera-score-type-toggle/context';
import React from 'react';
import { VeraVersionType } from 'utils/vera-score-utils';

const withVeraScoreVersion = Wrapped => {
  class WithVeraScoreVersion extends React.Component<any, any> {
    render() {
      return (
        <VeraScoreTypeDataContext.Consumer>
          {({
            isScoreVersionVera3,
            atRiskVeraThreshold,
            collisionEventType,
          }) => (
            <Wrapped
              {...this.props}
              isScoreVersionVera3={isScoreVersionVera3}
              atRiskVeraThreshold={atRiskVeraThreshold}
              collisionEventType={collisionEventType}
            />
          )}
        </VeraScoreTypeDataContext.Consumer>
      );
    }
  }

  return WithVeraScoreVersion;
};

export interface VeraScoreVersionToggleProps {
  veraVersionType?: VeraVersionType;
  toggleVeraVersionType?: (versionType) => void;
  isScoreVersionVera3?: boolean;
  atRiskVeraThreshold?: number;
  collisionEventType?: string;
}

export default withVeraScoreVersion as any;
