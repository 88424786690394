import { createSelector } from 'reselect';
import moment from 'moment';
import { Face, Vehicle, Driver, Event, DeviceProps } from 'models/db';
import { EntityType } from 'components/entity-type/entity-type-utils';
import { fetchRequest } from 'utils/requests';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import * as EventUtils from 'utils/event-utils';
import { path } from 'utils/helpers';
import { mpTrack } from 'components/mixpanel';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export const FETCHING_LIVE_DEVICES = 'live-devices/fetching-live-devices';
export const LIVE_DEVICES_RECEIVED = 'live-devices/live-devices-received';
export const LIVE_DEVICES_FETCH_FAILED = 'live-devices/live-devices-failed';
export const CLEAR_LIVE_DEVICES = 'live-devices/clear-live-devices';

/**
 * ----------------------------------------------------------------------------
 * Helpers
 * ----------------------------------------------------------------------------
 */
export const DEVICE_STATE = {
  MOVING: 'Moving',
  STOPPED: 'Stopped',
  PARKED: 'Parked',
};

export const LIVE_STATE = {
  MOVING: 'moving',
  STOPPED: 'stopped',
  PARKED: 'parked',
  LOST: 'lost',
  NONE: 'none',
};

export const LIVE_STATE_PRIORITY = [
  LIVE_STATE.MOVING,
  LIVE_STATE.STOPPED,
  LIVE_STATE.PARKED,
  LIVE_STATE.LOST,
  LIVE_STATE.NONE,
];

const DEFAULT_LAST_SEEN = {
  state: LIVE_STATE.NONE,
  time: null,
  coordinates: null,
};
/**
 * ----------------------------------------------------------------------------
 * Format device data cleanly
 * ----------------------------------------------------------------------------
 */

// Format and sort the devices
const formattedDevices = (messages: LiveAPIResponse[]): LiveAPIResponse[] => {
  return messages.map(message => {
    const lastStateMessage = message.state;

    // Empty case: if no state message is found, set a live state of none
    if (!lastStateMessage) {
      return { ...message, lastSeen: DEFAULT_LAST_SEEN };
    }

    const coordinates = EventUtils.getCoordinates(lastStateMessage);
    const time = EventUtils.getTime(lastStateMessage);
    const recordedState = lastStateMessage.parameters.state;

    // a the recordedState is moving or stopped and is more than 2 hours ago it is considered lost
    // since this suggests a loss of LTE before the device could resolve to a parked state
    const isLost =
      typeof recordedState === 'undefined' ||
      ([DEVICE_STATE.MOVING, DEVICE_STATE.STOPPED].includes(recordedState) &&
        time &&
        time.isBefore(moment().subtract(2, 'hours')));

    // the lastSeen.state is the message's state, but overwritten if lost
    const state = isLost ? LIVE_STATE.LOST : recordedState.toLowerCase();

    return {
      ...message,
      // break out the key state properties into the status object
      lastSeen: { state, time, coordinates },
    };
  });
};

// format the device responses cleanly
export const formattedDevicesSelector = createSelector(
  // all live device statuses fetched
  ({ live_devices: { devices } }) => devices,
  devices => formattedDevices(devices),
);

// whether or not the user is on a view that uses live device state message data (the map view)
export const isLiveSelector = createSelector(
  ({ live_devices }) => live_devices.devices,
  ({ live_devices }) => live_devices.fetchingLiveDevices,
  (devices, fetching) => !!devices.length || !!fetching,
);

/**
 * ----------------------------------------------------------------------------
 * Exported combined selectors for specific component use cases
 * ----------------------------------------------------------------------------
 */
export interface MapLiveDevicesProps {
  liveDevicesGeoJSONSource: any;
}

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

/**
 * Gets the most recent status of every device in the fleet
 */
/* tslint:disable:no-duplicate-string */
export const fetchLive = (groupId: string, activeEntityType: EntityType) => (
  dispatch: (any) => void,
  getState,
) => {
  const fetchId = `${Date.now()}-${Math.floor(Math.random() * 9999)}`;
  dispatch({ type: FETCHING_LIVE_DEVICES, payload: { fetchId } });
  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/devices/last-seen`,
    {
      status: 'active',
      group: groupId,
      view: activeEntityType,
      '_ts._format': 'object',
    },
  );
  const options = {
    method: 'GET',
  };

  return fetchRequest(url, options)
    .then((response: any) => {
      mpTrack('Get Live Devices', { success: true });
      dispatch({
        type: LIVE_DEVICES_RECEIVED,
        payload: { devices: response.data, fetchId },
      });
    })
    .catch(error => {
      mpTrack('Get Live Devices', { success: false, error });
      dispatch({ type: LIVE_DEVICES_FETCH_FAILED, payload: error });
    });
};

/**
 * clears out the current stored live devices
 */
export const clearLiveDevices = () => dispatch =>
  dispatch({ type: CLEAR_LIVE_DEVICES });

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */
export interface LastSeenData {
  coordinates: [number, number];
  state: string;
  time: any;
}

export interface LiveAPIResponse {
  driver: Driver; // the last seen driver
  vehicle: Vehicle; // the current vehicle for this device (should rarely change)
  device: DeviceProps; // the device info
  state: Event; // the last valid vehicle state message sent from this device
  lastSeen?: LastSeenData; // reduced properties clearly indicating the status of this live state (reduced client side)
  untaggedFace?: Face; // if this is a synthetic driver, we include the face to tag them
}

export interface LiveDevicesReducer {
  fetchingLiveDevices: string;
  devices: LiveAPIResponse[];
  lastFetchTime: moment.Moment;
}

export const initialState: LiveDevicesReducer = {
  fetchingLiveDevices: '',
  devices: [],
  lastFetchTime: null,
};

export default (
  state = initialState,
  { type, payload },
): LiveDevicesReducer => {
  switch (type) {
    case FETCHING_LIVE_DEVICES:
      return {
        ...state,
        fetchingLiveDevices: payload.fetchId,
      };
    case LIVE_DEVICES_RECEIVED:
      if (payload.fetchId !== state.fetchingLiveDevices) {
        return state;
      }
      return {
        ...state,
        devices: payload.devices,
        fetchingLiveDevices: '',
        lastFetchTime: moment(),
      };
    case CLEAR_LIVE_DEVICES:
      return {
        ...initialState,
      };
    case LIVE_DEVICES_FETCH_FAILED:
      return {
        ...state,
        fetchingLiveDevices: '',
      };
    default:
      return state;
  }
};
