import React, { FC } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { colors } from '@nauto/core';
import FleetLink from 'components/fleet-link';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import withFeatureFlags, {
  FeatureFlagsProps,
} from 'decorators/with-feature-flags';
import { compose } from 'recompose';
import { Route } from 'react-router-dom';
import { dataTestId } from '../topbar-mobile-navigation/data-test-ids';
import { NavMenuItem } from '../navigation/nav-menu-item';
import {
  userMenuItemsSelector,
  MenuItemProps,
} from 'components/navigation/user-menu.selector';
import { withUserPermission } from 'hooks/use-permissions';

interface Props extends FeatureFlagsProps {
  onItemClick: any;
  style?: React.CSSProperties;
  forMobileNavMenu?: boolean;
  userMenuItems: MenuItemProps[];
}

const UserMenu: FC<Props> = props => {
  const { onItemClick, forMobileNavMenu = false, userMenuItems } = props;

  return (
    <UserList>
      {userMenuItems.map(
        userMenuProps =>
          userMenuProps.show && (
            <Route key={userMenuProps.key} path={userMenuProps.match}>
              {({ match }) => {
                const item = (
                  <NavMenuItem
                    id={userMenuProps.appcuesId}
                    label={userMenuProps.label}
                    icon={userMenuProps.icon}
                    isActive={match}
                    onClick={() => {
                      if (userMenuProps.onItemClick) {
                        userMenuProps.onItemClick();
                      }
                      onItemClick();
                    }}
                    forMobileNavMenu={forMobileNavMenu}
                    data-testid={dataTestId[userMenuProps.dataTestId]}
                  />
                );
                return (
                  <UserListItem>
                    {userMenuProps.isFleetLink ? (
                      <FleetLink to={userMenuProps.to}>{item}</FleetLink>
                    ) : (
                      <UserItemLink
                        href={userMenuProps.to}
                        target={userMenuProps.target}
                      >
                        {item}
                      </UserItemLink>
                    )}
                  </UserListItem>
                );
              }}
            </Route>
          ),
      )}
    </UserList>
  );
};

const UserList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 300px;
`;

const UserListItem = styled.li`
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${colors.black};
  cursor: pointer;

  > a {
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

const UserItemLink = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Selector = createStructuredSelector({
  userMenuItems: userMenuItemsSelector,
});

export default compose(
  withUserPermission,
  withTranslation(),
  connect(Selector),
  withFeatureFlags,
)(UserMenu);
