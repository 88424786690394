import { useContext } from 'react';
import DriverDataContext from './context';
import DriverDataProvider from './provider';
import { Driver } from 'models/db';

function useDriverData(options?: { id?: undefined }): Record<string, Driver>;
function useDriverData(options: { id: string }): Driver;
function useDriverData(options?: { id?: string }) {
  const drivers = useContext(DriverDataContext);
  return options ? drivers[options.id] : drivers;
}

export { DriverDataProvider };
export default useDriverData;
