import { RoleFeatureFlag } from '@nauto/api';
import { Flags } from 'components/feature-flags/flags';
import * as i18next from 'i18next';
import { get, set } from 'lodash';

export const CONFIGURATION_VALUES = {
  NO_ACCESS: 'no-access',
  READ_ONLY: 'read-only',
  FULL_ACCESS: 'full-access',
};

export const FLAG_IDS = {
  HOME_OVERVIEW: 'home',
  DRIVER_INSIGHTS: 'driver_insights',
  DRIVER_INSIGHTS_HOME: 'driver_insights.insights',
  DRIVER_INSIGHTS_FLEET: 'driver_insights.fleet',
  DRIVER_INSIGHTS_EVENTS: 'driver_insights.events',
  DRIVER_INSIGHTS_MAPS: 'driver_insights.maps',
  DRIVER_INSIGHTS_REPORTS: 'driver_insights.reports',
  VEHICLE_INSIGHTS: 'vehicle_insights',
  VEHICLE_INSIGHTS_HOME: 'vehicle_insights.insights',
  VEHICLE_INSIGHTS_FLEET: 'vehicle_insights.fleet',
  VEHICLE_INSIGHTS_EVENTS: 'vehicle_insights.events',
  VEHICLE_INSIGHTS_MAPS: 'vehicle_insights.maps',
  VEHICLE_INSIGHTS_REPORTS: 'vehicle_insights.maps_reports',
  REPORTS: 'reports',
  REPORTS_COLLISION: 'reports.collision',
  REPORTS_AT_RISK: 'reports.at_risk',
  REPORTS_DEVICE_HEALTH: 'reports.device_health',
  REPORTS_REAL_TIME_ALERT_EFFECTIVENESS:
    'reports.real_time_alert_effectiveness',
  REPORTS_POLICY_VIOLATIONS: 'reports.policy_violations',
  REPORTS_COACHING_EFFECTIVENESS: 'reports.coaching_effectiveness',
  REPORTS_TOP_PERFORMING: 'reports.top_performing',
  REPORTS_CUSTOM: 'reports.custom',
  TAG_DRIVER: 'tag_driver',
  VIDEO_REQUEST: 'video_request',
  CONFIG: 'config',
  COACHING: 'coaching',
  USER_MANAGEMENT: 'user_management',
  ROLE_MANAGEMENT: 'role_management',
  FLEET_SETTINGS: 'fleet_settings',
  DEVELOPER: 'developer',
  CONTRACTS: 'contracts',
};

export const getReadableName = (id, t: (s: string) => string): string => {
  switch (id) {
    case CONFIGURATION_VALUES.NO_ACCESS:
      return t('No access');
    case CONFIGURATION_VALUES.READ_ONLY:
      return t('Read only');
    case CONFIGURATION_VALUES.FULL_ACCESS:
      return t('Full access');
    default:
      return null;
  }
};

const controlledNotifications = {
  suspected_incident: 'suspectedCollisionNotification',
  obstructed_device: 'obstructionDetectionNotification',
};

type getNotificationResponse = {
  description: string;
  shortDescs: string[];
  title: string;
  value: string;
};

export const getNotifications = ({
  t,
  includePanicAlerts = false,
  includeCvrAlerts = false,
  featureFlags,
}: {
  t: i18next.TFunction;
  includePanicAlerts: boolean;
  includeCvrAlerts: boolean;
  featureFlags: Flags;
}): getNotificationResponse[] => {
  const baseAlerts = [
    {
      shortDescs: ['collision'],
      title: t('Confirmed incident'),
      description: t(
        'Get notified of events such as collisions and severe G-force events',
      ),
      value: 'confirmed_incident',
    },
    {
      shortDescs: ['possible-collision'],
      title: t('Suspected incident'),
      description: t(
        'Enable immediate notification when a suspected incident is detected. Video will follow if incident is confirmed',
      ),
      value: 'suspected_incident',
    },
    {
      shortDescs: ['device-off'],
      title: t('Signal loss'),
      description: t(
        'Get notified when your Nauto device has not received cell signal for more than 10 days',
      ),
      value: 'signal_loss',
    },
    {
      shortDescs: ['battery-drain'],
      title: t('Stationary vehicle'),
      description: t(
        'Receive alerts for stationary vehicles to keep your vehicles up and running',
      ),
      value: 'stationary_vehicle',
    },
    {
      shortDescs: ['obstructed-devices'],
      title: t('Obstructed devices'),
      description: t(
        'Get notified in real-time if drivers obstruct the inward camera sfor more than 10 minutes',
      ),
      value: 'obstructed_device',
    },
  ];

  const panicAlerts = [
    {
      shortDescs: ['mark-panic'],
      title: t('Panic Mode Alerts'),
      description: t('Get notified when a driver triggers the panic mode'),
      value: 'mark_panic',
    },
    {
      shortDescs: ['uploaded-mark-panic'],
      title: t('Panic Mode Video Completion'),
      description: t(
        'Get notified when a Panic Alert Video is successfully uploaded or fails',
      ),
      value: 'uploaded_mark_panic',
    },
  ];
  const cvrAlerts = [
    {
      shortDescs: ['verifying-custom-video-request', 'custom-video-request'],
      title: t('Custom Video Request Confirmation'),
      description: t(
        'Receive notifications when a device confirms the availability of requested video',
      ),
      value: 'cvr_confirmed',
    },
    {
      shortDescs: ['uploaded-custom-video-request'],
      title: t('Custom Video Request Completion'),
      description: t(
        'Receive notifications when a Video Request is successfully uploaded',
      ),
      value: 'upload_cvr',
    },
  ];

  if (includePanicAlerts) {
    baseAlerts.push(...panicAlerts);
  }

  if (includeCvrAlerts) {
    baseAlerts.push(...cvrAlerts);
  }

  const filteredBaseAlerts = baseAlerts.filter(
    alert =>
      controlledNotifications[alert.value] === undefined ||
      featureFlags[controlledNotifications[alert.value]] === true,
  );

  return filteredBaseAlerts;
};

const order = [
  FLAG_IDS.HOME_OVERVIEW,
  FLAG_IDS.DRIVER_INSIGHTS,
  FLAG_IDS.DRIVER_INSIGHTS_HOME,
  FLAG_IDS.DRIVER_INSIGHTS_MAPS,
  FLAG_IDS.DRIVER_INSIGHTS_EVENTS,
  FLAG_IDS.DRIVER_INSIGHTS_FLEET,
  FLAG_IDS.DRIVER_INSIGHTS_REPORTS,
  FLAG_IDS.VEHICLE_INSIGHTS,
  FLAG_IDS.VEHICLE_INSIGHTS_HOME,
  FLAG_IDS.VEHICLE_INSIGHTS_MAPS,
  FLAG_IDS.VEHICLE_INSIGHTS_EVENTS,
  FLAG_IDS.VEHICLE_INSIGHTS_FLEET,
  FLAG_IDS.VEHICLE_INSIGHTS_REPORTS,
  FLAG_IDS.REPORTS,
  FLAG_IDS.REPORTS_COLLISION,
  FLAG_IDS.REPORTS_AT_RISK,
  FLAG_IDS.REPORTS_DEVICE_HEALTH,
  FLAG_IDS.REPORTS_REAL_TIME_ALERT_EFFECTIVENESS,
  FLAG_IDS.REPORTS_POLICY_VIOLATIONS,
  FLAG_IDS.REPORTS_COACHING_EFFECTIVENESS,
  FLAG_IDS.REPORTS_TOP_PERFORMING,
  FLAG_IDS.REPORTS_CUSTOM,
  FLAG_IDS.COACHING,
  FLAG_IDS.TAG_DRIVER,
  FLAG_IDS.VIDEO_REQUEST,
  FLAG_IDS.USER_MANAGEMENT,
  FLAG_IDS.ROLE_MANAGEMENT,
  FLAG_IDS.FLEET_SETTINGS,
  FLAG_IDS.DEVELOPER,
  FLAG_IDS.CONFIG,
].reduce((accum, id, index) => {
  accum[id] = index;
  return accum;
}, {});

const roleMapper = (role, index) => {
  const parentPath = role.parentPath ? [...role.parentPath, 'children'] : [];
  const path = [...parentPath, index];

  return {
    id: role.flag_id,
    name: role.description,
    value: role.value,
    path,
    ...(role.children
      ? {
          children: Object.values(role.children)
            .map((r, index) => roleMapper({ ...r, parentPath: path }, index))
            .sort((a, b) => order[a.id] - order[b.id]),
        }
      : {}),
  };
};

// @TODO chrischuck unit tests 😭
export const buildRoleHierarchy = (
  roleConfiguration: RoleFeatureFlag[],
): RoleFeatureFlag[] => {
  const topLevelConfigs = roleConfiguration.reduce((topLevelConfig, flag) => {
    const path = flag.flag_id.replace('.', '.children.');
    const currentVal = get(topLevelConfig, path);
    set(topLevelConfig, path, { ...flag, ...currentVal });
    return topLevelConfig;
  }, {});

  return Object.values(topLevelConfigs)
    .map(roleMapper)
    .sort((a, b) => order[a.id] - order[b.id]);
};

export const configurationAccess = [
  CONFIGURATION_VALUES.NO_ACCESS,
  CONFIGURATION_VALUES.READ_ONLY,
  CONFIGURATION_VALUES.FULL_ACCESS,
];
