import moment from 'moment-timezone';

export const GLOBAL_FLEET = 'x-manufacturing';
export const REWORK_FLEET = 'x-rework';

export const PROMISE = {
  OK: Promise.resolve(true),
  FAIL: Promise.resolve(false),
  SEND: (resp: any) => Promise.resolve(resp),
};

export const getIMEI = (hardware = '') => hardware.replace('hw-', '');

export const getCurrentDate = () => moment().format('LL');

export const validateVin = vin => {
  const re = new RegExp('^[A-HJ-NPR-Z\\d]{8}[\\dX][A-HJ-NPR-Z\\d]{2}\\d{6}$');
  return vin.match(re);
};
