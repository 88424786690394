import React, { useContext } from 'react';
import { memoize, get, xor } from 'lodash-es';
import * as API from '@nauto/api';
import useFeatureFlags from 'hooks/use-feature-flags';
import useFleetData from 'hooks/use-fleet-data';
import { DEFAULT_DATA } from 'components/feature/constants';
import UserRoleContext from './context';
import UserPermissionProvider from './provider';
import featureFlagMapping, { Permissions } from './rules';
import { DEFAULT_FLEET_PROPERTIES, GLOBAL_ADMIN_ROLES } from './constants';
import { FLAG_IDS } from 'components/roles/constants';
import { ROLES } from 'constants/roles';
import {
  AuthFailed,
  RoleConfiguration,
  RoleFeatureFlag,
} from 'hooks/use-permissions/types';

export { Permissions } from './rules';
export * from './constants';

export interface UserPermissionData {
  roles: API.RoleConfiguration[];
  /**
   * @description
   * Check whether the given permission resolves to the provided value
   * If fleet is using new role management:
   * @example
   * hasPermission(Permissions.CustomCoaching, 'full-access')
   * hasPermission(Permissions.Events, 'read-only')
   * hasPermission(Permissions.Coaching, true)
   */
  hasPermission: (
    featureFlag: Permissions,
    value?: 'no-access' | 'read-only' | 'full-access' | true,
  ) => boolean;
  /**
   * @description
   * Check whether the fleet has new role management enabled
   */
  hasRoleManagement: boolean;
}

export const mergedPermissions = (
  roles: RoleConfiguration[] | AuthFailed[],
): RoleFeatureFlag[] | [] =>
  roles.filter(role => role.featureFlags).flatMap(role => role.featureFlags);

const useUserPermission = (): UserPermissionData => {
  const { roles, predefinedRoles } = useContext(UserRoleContext);
  const featureFlags = useFeatureFlags();
  const fleet = useFleetData({ current: true });
  const fleetProperties = get(fleet, 'properties', {});
  const fleetOffering = get(fleet, 'fleet_offering', {});
  // Filter roles who has featureFllags
  // flatMap all the roles

  const hasPermission = permissionResolver({
    predefinedRoles,
    permissions: mergedPermissions(roles),
    featureFlags,
    fleetOffering,
    featureFlagMapping,
    fleetProperties,
  });

  const hasRoleManagement = hasPermission(
    Permissions.CustomUserManagement,
    true,
  );

  return {
    roles,
    hasPermission,
    hasRoleManagement,
  };
};

export const withUserPermission = WrappedComponent => props => {
  const userPermissions = useUserPermission();
  return <WrappedComponent {...userPermissions} {...props} />;
};

// Check the fleet if it has capabilities -> first level
// Check old role -> second level
// Check new role -> third level
// Check feature flags -> fourth level
// Rules cannot override previous false values
// Previous true values can be overidden
export interface PermissionResolverParams {
  predefinedRoles: string[];
  permissions: API.RoleFeatureFlag[];
  featureFlags: any;
  fleetOffering: any;
  featureFlagMapping: any;
  fleetProperties: any;
}

export const permissionResolver = ({
  predefinedRoles,
  permissions,
  featureFlags,
  fleetOffering,
  featureFlagMapping,
  fleetProperties,
}: PermissionResolverParams) => (
  permission: Permissions,
  value = null,
): boolean => {
  const rules = featureFlagMapping[permission];
  if (!rules) {
    throw new Error('Undefined feature.');
  }

  const fleetHasAccess = resolveFleetAccess(
    fleetOffering,
    fleetProperties,
    rules,
    value,
  );

  if (!fleetHasAccess) {
    return false;
  }

  // check if roles are an exact match to the rules role array
  if (
    rules.exactRoleMatch &&
    predefinedRoles.length !== rules.roles.length &&
    xor(predefinedRoles, rules.roles).length !== 0
  ) {
    return false;
  }

  // check if roles contain all the roles as the rules role array
  if (
    rules.containAllRoles &&
    rules.roles &&
    !rules.roles.every(role => predefinedRoles.includes(role))
  ) {
    return false;
  }

  if (rules.roles && !predefinedRoles.some(r => rules.roles.includes(r))) {
    return false;
  }

  // only check roles if we have feature flag turned on
  // temporary check until all auth is converted...
  const roleValue = get(rules, `values.${value}.role`, value);
  let reducedFlags = reduceFeatureFlags(permissions);
  if (
    featureFlags.customUserManagement &&
    rules.roleId &&
    Object.values(FLAG_IDS).includes(rules.roleId) &&
    predefinedRoles.includes(ROLES.GLOBAL_ADMIN)
  ) {
    reducedFlags = GLOBAL_ADMIN_ROLES;
  }
  if (
    featureFlags.customUserManagement &&
    rules.roleId &&
    resolveRoleFeatures(rules.roleId, reducedFlags, roleValue)
  ) {
    return false;
  }

  const ldValue = get(rules, `values.${value}.ld`, value);
  if (rules.ldId && featureFlags[rules.ldId] !== ldValue) {
    return false;
  }

  return true;
};

// true/false values are flip-flopped here
// true indicates the user does NOT have access
const resolveRoleFeatures = (roleId, reducedFlags, value) => {
  // Forget role hierarchy...

  // const ids = roleId.split('.');
  // let runningId = '';
  // const roleHierarchy = ids.reduce((list, current) => {
  //   runningId = runningId ? `${runningId}-${current}` : current;
  //   list.push(runningId);
  //   return list;
  // }, []);

  //for (let i = 0; i < roleHierarchy.length; i++) {
  // if (reducedFlags[roleId] !== value) {
  //   return true;
  // }
  //}

  // return false;

  return reducedFlags[encodeFlag(roleId)] !== value;
};

// as long as one thing is true, the fleet has access
const resolveFleetAccess = (fleetOffering, fleetProperties, rules, value) => {
  if (!rules.fleet) {
    return true;
  }

  const fleetPackage = get(fleetOffering, 'package', null);
  const authorizedPackages = get(rules, 'fleet.packages', null);
  if (authorizedPackages && authorizedPackages.some(p => p === fleetPackage)) {
    return true;
  }

  const fleetAddons = get(fleetOffering, 'add_on', []);
  const authorizedAddons = get(rules, 'fleet.addOns', null);
  if (authorizedAddons && authorizedAddons.some(a => fleetAddons.includes(a))) {
    return true;
  }

  const features = get(fleetOffering, 'features', null) || DEFAULT_DATA;
  const authorizedFeatures = get(rules, 'fleet.features', null);

  if (
    authorizedFeatures &&
    authorizedFeatures.some(f => features[f] === true)
  ) {
    return true;
  }

  const propertyValue = get(rules, `values.${value}.fleet.property`, value);
  const properties = get(
    fleetProperties,
    'properties',
    DEFAULT_FLEET_PROPERTIES,
  );
  const authorizedProperties = get(rules, 'fleet.properties', null);
  if (
    authorizedProperties &&
    authorizedProperties.some(f => properties[f] === propertyValue)
  ) {
    return true;
  }

  return false;
};

// can take multiple roles per fkeet
// if one feature is true for one role, go with that
// this gets hairy if we don't have boolean values....
const reduceFeatureFlags = memoize(featureFlags => {
  const flags = {};
  featureFlags.forEach(featureFlag => {
    const { flagId, value } = featureFlag;
    const key = encodeFlag(flagId);

    if (!flags[key]) {
      flags[key] = value;
    }
  });
  return flags;
});

// We need to encode/decode flags because the backend uses dot notation
// to identify hierarchy in flags i.e. parent_flag.child_flag
// Doing so doesn't play nice w/ javascript especially with lodash get/set methods
const encodeFlag = flag => flag.replace('.', '-');

export { UserPermissionProvider };
export default useUserPermission;
