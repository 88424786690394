const banner1 = `


░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░▓█▓░░░░░░▒▓█████▓▒░░░░░░░░░░░░░░░░░░░░░░░░█░░░░░░░░░░░░░░░░░
░▓█░░██░░░▓▓░░░░░░░█▓░░░░░░░░░░░░░░░░░░░░░░░██░░░░░░░░░░░░░░░░
░██░░░░██░▒▒░░░░░░░██░░░░▓██▓░█▓░▓█░░░░░░█▓░████▓░░░░░▓██▓░░░░
░██░░░░░░██▒░░░░░░░██░░▓█░░░░███░██░░░░░░██░██░░░░░░▓█░░░░█▓░░
░██░░░░░░░▒██░░░░░░██░▓█░░░░░░██░██░░░░░░██░██░░░░░▓█░░░░░░█▓░
░██░░░░░░░▒▒░██░░░░██░▓█░░░░░░██░▓█░░░░░░█▓░▓█░░░░░▓█░░░░░░█▓░
░▓█░░░░░░░▓▓░░░██░░█▓░░▓█░░░░███░░▓█░░░░█▓░░░█░░░█░░▓█░░░░█▓░░
░░▒▓█████▓▒░░░░░░▓█▓░░░░░▓██▓░░█░░░▓████▓░░░░░▓█▓░░░░░▓██▓░░░░
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
       _____
      /|_||_\\.___     Beep Beep. Help us save lives!
     (   _    _ _\\    Join us at https://nauto.com/jobs
     ='-(_)--(_)-'
          
`;

const banner2 = `



   ███      █████████                        █
  ██░░██   ██░░░░░░░██                      ░██
 ░██   ░██░██      ░██    ████ ██ ██      ██░█████     ████
 ░██     ░███      ░██  ██░░░░███░██     ░██░██░░    ██░░░░██
 ░██      ░███     ░██ ██     ░██░██     ░██░██     ██     ░██
 ░██      ░██░██   ░██░██     ░██░██     ░██░██    ░██     ░██
 ░██      ░██░░░██ ░██░░██    ███░░██   ░██ ░░█   █░░██    ██
 ░░█████████   ░░░███  ░░░████░░█ ░░██████   ░░███  ░░░████░
  ░░░░░░░░░      ░░░     ░░░░ ░░   ░░░░░░     ░░░     ░░░░ 
       _____
      /|_||_\\.___     Beep Beep. Help us save lives!
     (   _    _ _\\    Join us at https://nauto.com/jobs
     ='-(_)--(_)-'
          
`;

const banner3 = `


::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::╔═╗::::::╔══════╗::::::::::::::::::::::║::::::::::::::::::
:::╔╝:╚═╗:::╔╝::::::╚╗:::::::::::::::::::::║::::::::::::::::::
:::║::::╚═╗:║::::::::║::══════╗::╔:::::::╗:║════:::╔═════╗:::: 
:::║::::::╚═╗::::::::║::::::::╚╗:║:::::::║:║::::::╔╝:::::╚╗:::
:::║::::::::║═╗::::::║:╔═══════║:║:::::::║:║::::::║:::::::║:::
:::║::::::::║:╚═╗::::║:║:::::::║:║:::::::║:║::::::║:::::::║:::
:::╚╗::::::╔╝:::╚═╗:╔╝:╚╗:::::╔╝:╚╗:::::╔╝:╚╗::╔╝:╚╗:::::╔╝:::
::::╚══════╝::::::╚═╝:::╚═════╝╚::╚═════╝:::╚══╝:::╚═════╝::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
                                                           
       _____
      /|_||_\\.___     Beep Beep. Help us save lives!
     (   _    _ _\\    Join us at https://nauto.com/jobs
     ='-(_)--(_)-'
          
`;

const banner4 = `



  ▓█▓      ▒▓█████▓▒                        ▓█
 ▓█  ██   ▓▓       █▓                       ██
 ██    ██ ▒▒       ██    ▓██▓ █▓ ▓█      █▓ ████▓     ▓██▓
 ██      ██▒       ██  ▓█    ███ ██      ██ ██      ▓█    █▓
 ██       ▒██      ██ ▓█      ██ ██      ██ ██     ▓█      █▓
 ██       ▒▒ ██    ██ ▓█      ██ ▓█      █▓ ▓█     ▓█      █▓
 ▓█       ▓▓   ██  █▓  ▓█    ███  ▓█    █▓   █   █  ▓█    █▓
  ▒▓█████▓▒      ▓█▓     ▓██▓ ██    ▓██▓      ▓█▓     ▓██▓

       _____
      /|_||_\\.___     Beep Beep. Help us save lives!
     (   _    _ _\\    Join us at https://nauto.com/jobs
     ='-(_)--(_)-'
          
`;

const banner5 = `

  .+#+     .+####+.                       #
  ##  ##  +#      #+                      #
  ##    ##..      ##    .+#+. #  #     #  ###      .+#+.
  ##      ##      ##  .#     +#  #     #  #      .#     #.
  ##      ˚˚##    ##  #       #  #     #  #      #       #
  +#      #+  ##  #+  ˚#     +#  +     +  +   +   #     #
    +####+      +#+˚    ˚+##˚ #   ˚+#+˚    ˚#˚     ˚+#+˚
    _____
   /|_||_\\.___     Beep Beep. Help us save lives!
  (   _    _ _\\    Join us at https://nauto.com/jobs
  ='-(_)--(_)-'

`;

export default () => banner2;
