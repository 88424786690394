import config from 'utils/config';
import { getFleetId } from 'utils/localstorage';
import { cacheBustUrl, path } from 'utils/helpers';
import { fetchRequest } from 'utils/requests';
import { User, UsersState } from 'components/users/users.types';
import {
  ROLES,
  canInviteUsers,
  canInviteHiddenUsers,
  managedRoles,
  isGlobal,
  isHiddenRole,
  isSuperfleetRole,
} from 'constants/roles';
import { createSelector } from 'reselect';
import { GLOBAL_FLEET } from 'components/device-actions/utils';
import uniq from 'lodash-es/uniq';
import { superfleetsSelector } from 'components/superfleets/superfleets.redux';
import * as API from '@nauto/api';

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

enum ActionTypes {
  FETCH_FLEET_USERS_START = 'users-actions/FETCH_FLEET_USERS_START',
  FETCH_FLEET_USERS_SUCCESS = 'users-actions/FETCH_FLEET_USERS_SUCCESS',
  FETCH_FLEET_USERS_ERROR = 'users-actions/FETCH_GLOBAL_USERS_ERROR',
  UPDATE_START = 'user-actions/UPDATE_START',
  UPDATE_SUCCESS = 'user-actions/UPDATE_SUCCESS',
  UPDATE_ERROR = 'user-actions/UPDATE_ERROR',
  FETCH_COMPOUND_USER_DETAILS_START = 'user-actions/FETCH_COMPOUND_USER_DETAILS_START',
  FETCH_COMPOUND_USER_DETAILS_SUCCESS = 'user-actions/FETCH_COMPOUND_USER_DETAILS_SUCCESS',
  FETCH_COMPOUND_USER_DETAILS_ERROR = 'user-actions/FETCH_COMPOUND_USER_DETAILS_ERROR',
  SET_ROLE_FILTER = 'user-actions/SET_ROLE_FILTER',
}

const throwUpdateError = (error, dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_ERROR,
    payload: { error },
  });
  throw error;
};

/**
 * Fetch a list of all users with access to the current fleet
 */
export const fetchFleetUsers = (
  customUsers = false,
  internalUsers = false,
) => async (dispatch: (any) => void): Promise<void> => {
  dispatch({ type: ActionTypes.FETCH_FLEET_USERS_START });
  if (customUsers) {
    const call = internalUsers
      ? () =>
          API.users.getAdminUsers({ url: config.admin_api_root, version: 'v3' })
      : () =>
          API.users.getUsers(
            { fleetId: getFleetId() },
            { url: config.admin_api_root, version: 'v3' },
          );
    return await call().then(({ data, success }) => {
      if (success) {
        const users = Object.values(data);
        dispatch({
          type: ActionTypes.FETCH_FLEET_USERS_SUCCESS,
          payload: { users, ignoreFilters: true },
        });
      } else {
        dispatch({
          type: ActionTypes.FETCH_FLEET_USERS_ERROR,
        });
      }
    });
  }
  return API.users
    .fetchFleetUsers({ fleetId: getFleetId() }, { url: config.admin_api_url })
    .then((response: any) => {
      dispatch({
        type: ActionTypes.FETCH_FLEET_USERS_SUCCESS,
        payload: { users: response.data },
      });
    })
    .catch((error: any) => {
      console.log(error);
      dispatch({
        type: ActionTypes.FETCH_FLEET_USERS_ERROR,
        payload: { error },
      });
    });
};

export const fetchCompoundUserDetails = (userID: string) => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: ActionTypes.FETCH_COMPOUND_USER_DETAILS_START });

  return API.users
    .fetchCompoundUserDetails({ userId: userID }, { url: config.admin_api_url })
    .then(response => {
      dispatch({
        type: ActionTypes.FETCH_COMPOUND_USER_DETAILS_SUCCESS,
        payload: { user: response.data },
      });
    })
    .catch(error => {
      dispatch({
        type: ActionTypes.FETCH_COMPOUND_USER_DETAILS_ERROR,
        payload: { error },
      });
    });
};

interface UserFormData {
  email: string;
  roles: string[];
  groups: string[];
}
const buildUser = ({ email, roles, groups }: UserFormData): Partial<User> => {
  const fleet: string = getFleetId();

  const roleMap = roles.reduce(
    (map, role) => ({
      ...map,
      [role]: [fleet],
    }),
    {},
  );

  return {
    email,
    role_to_fleets: roleMap,
    fleet_to_groups: { [fleet]: groups },
  };
};

export const addFleetUser = ({ email, roles, groups }: UserFormData) => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: ActionTypes.UPDATE_START });

  return API.users
    .addFleetUser(
      { email, roles, groups },
      { fleetId: getFleetId() },
      { url: config.admin_api_url },
    )
    .then(response => {
      dispatch({ type: ActionTypes.UPDATE_SUCCESS });
    })
    .catch(error => throwUpdateError(error, dispatch));
};

/**
 * update an existing user
 */
export const updateFleetUser = ({ id, roles, groups }: User) => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: ActionTypes.UPDATE_START });

  return API.users
    .updateFleetUser(
      { id, roles, groups },
      { fleetId: getFleetId() },
      { url: config.admin_api_url },
    )
    .then(response => {
      dispatch({ type: ActionTypes.UPDATE_SUCCESS });
    })
    .catch(error => throwUpdateError(error, dispatch));
};

/**
 * create a new user
 */
export const createCompoundUser = (user: Partial<User>) => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: ActionTypes.UPDATE_START });

  return API.users
    .createCompoundUser(user, { url: config.admin_api_url })
    .then(response => {
      dispatch({ type: ActionTypes.UPDATE_SUCCESS });
    })
    .catch(error => throwUpdateError(error, dispatch));
};

/**
 * update an existing user
 */
export const updateCompoundUser = (user: User) => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: ActionTypes.UPDATE_START });

  return API.users
    .updateCompoundUser(user, { url: config.admin_api_url })
    .then(response => {
      dispatch({ type: ActionTypes.UPDATE_SUCCESS });
    })
    .catch(error => throwUpdateError(error, dispatch));
};

export const setUserRoleFilter = (roles: string[]) => (
  dispatch: (any) => void,
) => {
  dispatch({
    type: ActionTypes.SET_ROLE_FILTER,
    payload: { roles: [...roles] },
  });
};

export const deleteCompoundUser = (id: string) => (
  dispatch: any,
  getState: any,
): Promise<void> => {
  const { featureFlags } = getState();
  if (featureFlags.customUserManagement) {
    return API.users
      .deleteAdminUser(id, { url: config.admin_api_root, version: 'v3' })
      .then(() => {
        dispatch({ type: ActionTypes.UPDATE_SUCCESS });
      });
  }
  return dispatch(updateCompoundUser({ id }));
};

/**
 * ----------------------------------------------------------------------------
 * Selectors
 * ----------------------------------------------------------------------------
 */

export const editableRolesSelector = createSelector(
  ({ user: { fleets } }) => fleets,
  superfleetsSelector,
  (fleets, superfleets) => {
    const getFleet = (fleetData: any) => {
      return { ...fleetData.fleet, serviceUrl: fleetData.service_url };
    };

    const isGlobalUser = fleets.some(f => f.roles.includes(ROLES.GLOBAL_ADMIN));

    const canInviteUsersFleets = fleets
      .filter(f => f.roles.some(canInviteUsers))
      .map(getFleet);

    const canInviteHiddenUsersFleets = fleets
      .filter(f => f.roles.some(canInviteHiddenUsers))
      .map(getFleet);

    return managedRoles.reduce((arr, role: ROLES) => {
      if (isSuperfleetRole(role)) {
        return isGlobalUser ? [...arr, { role, fleets: superfleets }] : arr;
      }
      if (isGlobal(role)) {
        return isGlobalUser ? [...arr, { role, fleets: [GLOBAL_FLEET] }] : arr;
      }
      if (isHiddenRole(role)) {
        if (canInviteHiddenUsersFleets.length) {
          return [...arr, { role, fleets: canInviteHiddenUsersFleets }];
        }
        return arr;
      }
      return [...arr, { role, fleets: canInviteUsersFleets }];
    }, []);
  },
);

export const activeFleetUserSelector = createSelector(
  ({ usersData }) => usersData.fleetUsers,
  ({ usersData }) => usersData.editFleetUser,
  (users: User[], userID: string): User =>
    userID && users.find(user => user.id === userID),
);

const allRolesByUsers = fleetUsers =>
  uniq(fleetUsers.map(u => u.role_ids).flat(2));

export const activeFleetUserRolesSelector = createSelector(
  ({ usersData }) => usersData.fleetUsers,
  allRolesByUsers,
);

export const filteredUsersSelector = createSelector(
  ({ usersData }) => usersData.fleetUsers,
  ({ usersData }) => usersData.filterByRoles,
  ({ featureFlags }) => featureFlags.customUserManagement,
  (fleetUsers, roleFilters, customUserManagement): User[] => {
    if (roleFilters?.length) {
      return fleetUsers.filter(fleetUser => {
        if (customUserManagement) {
          return fleetUser?.acls?.some(
            f => roleFilters.indexOf(f.role_id) >= 0,
          );
        } else if (fleetUser.role_ids) {
          return fleetUser.role_ids.some(f => roleFilters.indexOf(f) >= 0);
        }
        return true;
      });
    } else {
      return roleFilters?.length === 0 ? [] : fleetUsers;
    }
  },
);

export const addFleetUserSelector = ({ usersData }) => usersData.addFleetUser;

/**
 * ----------------------------------------------------------------------------
 * Reducer
 * ----------------------------------------------------------------------------
 */

export const defaultState: UsersState = {
  fleetUsers: [],
  filterByRoles: [],
  isRequestingFleetUsers: false,
  isRequestingCompoundUserDetails: false,
  compoundUserDetails: undefined,
  isUpdatingUser: false,
  fetchUsersError: '',
  fetchUserError: '',
  updateUserError: '',
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_FLEET_USERS_START:
      return {
        ...state,
        isRequestingFleetUsers: true,
      };
    case ActionTypes.FETCH_FLEET_USERS_SUCCESS:
      return {
        ...state,
        isRequestingFleetUsers: false,
        fleetUsers: payload.users,
        ...(payload.ignoreFilters
          ? {}
          : {
              filterByRoles: allRolesByUsers(payload.users),
            }),
      };
    case ActionTypes.FETCH_FLEET_USERS_ERROR:
      return {
        ...state,
        isRequestingFleetUsers: false,
        error: payload.error,
      };
    case ActionTypes.UPDATE_START:
      return {
        ...state,
        isUpdatingUser: true,
      };
    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
      };
    case ActionTypes.UPDATE_ERROR:
      return {
        ...state,
        isUpdatingUser: false,
        error: payload.error,
      };
    case ActionTypes.FETCH_COMPOUND_USER_DETAILS_START:
      return {
        ...state,
        isRequestingCompoundUserDetails: true,
      };
    case ActionTypes.FETCH_COMPOUND_USER_DETAILS_SUCCESS:
      return {
        ...state,
        compoundUserDetails: payload.user,
        isRequestingCompoundUserDetails: false,
      };
    case ActionTypes.FETCH_COMPOUND_USER_DETAILS_ERROR:
      return {
        ...state,
        isRequestingCompoundUserDetails: false,
        fetchUserError: payload.error,
      };
    case ActionTypes.SET_ROLE_FILTER:
      return {
        ...state,
        filterByRoles: payload.roles,
      };
    default:
      return state;
  }
};
