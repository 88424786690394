import moment, { LocaleSpecification } from 'moment';

moment.updateLocale('en', {
  ordinal: (number, period) => {
    switch (period) {
      case 'd':
      case 'D':
      case 'DDD':
      default:
        return number;
    }
  },
} as LocaleSpecification);
