import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { EventType } from 'components/events/event-types';

/* tslint:disable:no-duplicate-string */
export const strings = (t: TFunction, type: string): string => {
  switch (type) {
    case 'acceleration':
      return t('Acceleration');
    case 'braking':
      return t('Braking');
    case 'braking-hard':
      return t('Braking');
    case 'cornering':
      return t('Cornering');
    case 'crashnet':
    case 'possible-collision':
    case 'collision':
      return t('Collision');
    case 'collisions':
      return t('Collisions');
    case 'near-collision':
      return t('Near Collision');
    case 'no-seat-belt':
      return t('No Seat Belt');
    case 'distraction':
      return t('Distraction');
    case 'mark-panic':
      return t('Panic button');
    case 'mark':
    case 'marked':
      return t('Marked');
    case 'panic-button':
      return t('Panic button');
    case 'request':
    case 'requested':
      return t('Requested');
    case 'requests':
      return t('Requests');
    case 'risks':
      return t('Risks');
    case 'tailgating':
      return t('Tailgating');
    case 'speeding':
      return t('Speeding');
    case 'violations':
      return t('Violations');
    case 'smoking':
      return t('Smoking');
    case 'cell-phone':
      return t('Cell Phone');
    case 'coachable':
      return t('Coachable');
    case 'drowsiness':
      return t('Drowsiness');
    case 'backing-up':
      return t('Backing up');
    case 'posted-speed':
    case 'posted-speeding':
      return t('Posted speed');
    case 'collision-prevented':
      return t('Near Collision w/ FCW');
    case 'fcw':
      return t('Forward collision warning');
    case 'pcw':
      return t('Pedestrian collision warning');
    default:
      return '';
  }
};

export interface Props {
  t?: any;
  type?: nauto.EventType | EventType;
}

export const I18nEventString = (props: Props) => {
  let [t] = useTranslation();

  /**
   * In some circumstances we need to pass a `t` function in.
   * Such as in cases where React loses i18n context via portals.
   */
  if (props.t) {
    t = props.t;
  }

  return <span>{strings(t, props.type)}</span>;
};

export default I18nEventString;
