import { createStructuredSelector, createSelector } from 'reselect';
import moment from 'moment-timezone';
import { fetchRequest } from 'utils/requests';
import { path, cacheBustUrl } from 'utils/helpers';
import {
  getFleetId,
  getGroupId,
  getServiceUrl,
  getCachedFleetId,
  getCachedGroupId,
} from 'utils/localstorage';
import { debugSelector } from 'components/modes/modes.redux';
import { Face, Driver } from 'models/db';
import { findGroupById } from 'components/groups/groups.redux';
import { mpTrack } from 'components/mixpanel';
import { indexedFleetVehiclesSelector } from 'components/organization/vehicles/vehicles.redux';
import groupBy from 'lodash-es/groupBy';
import { WithTranslation } from 'react-i18next';
import { splitDateIntoIntervals } from 'components/coaching-new/utils';
import { untaggedFacesCutoff } from 'utils/date-ranges';
import { hasGroupAccess } from 'utils/groups';
import { isJapaneseFleetSelector } from 'components/auth/auth.reducer';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */

export const FETCHING_UNTAGGED_COUNT = 'faces/fetching-count';
export const CLEAR_UNTAGGED_COUNT = 'faces/clear-count';
export const UNTAGGED_COUNT_RECEIVED = 'faces/count-received';
export const UNTAGGED_COUNT_FETCH_FAILED = 'faces/count-fetched-failed';

export const FETCHING_UNTAGGED_FACES = 'faces/fetching-untagged';
export const UNTAGGED_FACES_RECEIVED = 'faces/untagged-faces-received';
export const UNTAGGED_FACES_FETCH_FAILED = 'faces/untagged-fetched-failed';
export const FETCHING_DRIVER_FACES = 'faces/fetching-driver-face';
export const DRIVER_FACES_RECEIVED = 'faces/driver-faces-received';
export const FETCHING_TOOLTIP_FACES = 'faces/fetching-tooltip-face';
export const DRIVER_TOOLTIP_FACES_RECEIVED =
  'faces/driver-tooltip-faces-received';
export const DRIVER_FACES_FETCH_FAILED = 'faces/driver-faces-failed';
export const TOOLTIP_FACES_FETCH_FAILED = 'faces/tooltip-faces-failed';
export const TAGGING_DRIVER = 'faces/tagging-driver';
export const DRIVER_TAGGED = 'faces/driver-tagged';
export const DRIVER_TAG_FAILED = 'faces/driver-tagged';
export const FETCHING_SUGGESTIONS = 'faces/fetching-suggestions';
export const SUGGESTIONS_RECEIVED = 'faces/suggestions-received';
export const CLEAR_SUGGESTIONS = 'faces/clear-suggestions';
export const SUGGESTIONS_FETCH_FAILED = 'faces/suggestions-fetch-failed';
export const UPDATING_FACE_STATUS = 'faces/updating-face-status';
export const FACE_STATUS_UPDATED = 'faces/face-status-updated';
export const FACE_STATUS_UPDATE_FAILED = 'faces/face-status-update-failed';
export const UNMERGING_FACE = 'faces/unmerging-face';
export const UNMERGE_FACE_SUCCESS = 'faces/unmerge-face-success';
export const UNMERGE_FACE_FAIL = 'faces/unmerge-face-fail';
export const CLEAR_FACES = 'faces/clear';
export const SKIP_FACE_TAGGING = 'faces/skip-tagging';
export const SKIP_FACE_SUCCESS = 'faces/skip-success';
export const SKIP_FACE_FAILED = 'faces/skip-failed';

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

/**
 * selector for the Faces page
 */
export interface TagFacesSelectorProps extends WithTranslation, FacesReducer {
  className?: string;
  dispatch?: any;
  drivers: Driver[];
  activeGroup: any;
}

const minDate = () => moment().subtract(180, 'days');
/**
 * join faces with vehicle and group data
 */
export const selectFacesWithVehiclesAndGroups = createSelector(
  ({ faces }) => faces.untaggedFaces,
  ({ groups }) => groups.rootGroup,
  indexedFleetVehiclesSelector,
  (untaggedFaces, rootGroup, indexedVehicles) =>
    untaggedFaces.map(face => {
      const vehicleId = face['vehicle-id'];
      const vehicle = indexedVehicles[vehicleId];
      const groupId = vehicle && vehicle.group_ids && vehicle.group_ids[0];
      const group = groupId && findGroupById(rootGroup, groupId);
      return {
        ...face,
        group,
        vehicle,
      };
    }),
);

export enum FaceCategory {
  skipped = 'skipped',
  untagged = 'untagged',
}

export const selectFacesGroupBySection = createSelector(
  selectFacesWithVehiclesAndGroups,
  faces =>
    groupBy(faces, (face: Face) =>
      face.properties.skipped ? FaceCategory.skipped : FaceCategory.untagged,
    ),
);

/**
 * selector for the Faces page for an individual driver
 */
export interface DriverTagsSelectorProps {
  driverFaces: Face[];
  fetchingDriverFaces: boolean;
  debug: boolean;
}

/**
 * selector for a single driver's tag page
 */
export const driverTagsSelector = createStructuredSelector({
  driverFaces: ({ faces }) => faces.driverFaces,
  untaggingFace: ({ faces }) => faces.untaggingFace,
  fetchingDriverFaces: ({ faces }) => faces.fetchingDriverFaces,
});

export interface DriverTileSelectorProps {
  tooltipFaces: Face[];
  fetchingTooltipFaces: boolean;
  tooltipRequested: string;
  debug: boolean;
  dispatch?: any;
  isJapaneseFleet?: boolean;
}

/**
 * selector for a single driver tile
 */
export const driverTileSelector = createStructuredSelector({
  tooltipFaces: ({ faces }) => faces.tooltipFaces,
  fetchingTooltipFaces: ({ faces }) => faces.fetchingTooltipFaces,
  tooltipRequested: ({ faces }) => faces.tooltipRequested,
  debug: debugSelector,
  isJapaneseFleet: isJapaneseFleetSelector,
});

export interface TagDriverCardSelectorProps {
  suggestions: {
    [faceID: string]: Driver[];
  };
  drivers: Driver[];
  debug: boolean;
}

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

/**
 * get untagged faces count
 */
export const getUntaggedFacesCount = () => (
  dispatch: (any) => void,
  getState,
) => {
  dispatch({ type: CLEAR_UNTAGGED_COUNT });
  const fleetId = getFleetId() || getCachedFleetId();
  const groupId = getGroupId() || getCachedGroupId();
  const {
    groups: { rootGroup },
  } = getState();

  const groupAccess = hasGroupAccess(groupId, rootGroup);

  if (!groupAccess) {
    return dispatch({
      type: UNTAGGED_COUNT_FETCH_FAILED,
      payload: new Error('No group access'),
    });
  }

  dispatch({ type: FETCHING_UNTAGGED_COUNT });

  const min = untaggedFacesCutoff().valueOf();
  const url = path(`${getServiceUrl()}/fleets/${fleetId}/paginated-faces`, {
    has_driver_association: false,
    faces_count_threshold: 50,
    status: 'active',
    limit: 0,
    group: groupId,
    min,
  });

  const options = {
    method: 'GET',
  };

  return fetchRequest(url, options)
    .then((response: any) => {
      const {
        data: { total_count, has_more },
      } = response;
      dispatch({
        type: UNTAGGED_COUNT_RECEIVED,
        payload: { count: total_count ?? 0, hasMore: has_more ?? false },
      });
    })
    .catch(error => {
      dispatch({
        type: UNTAGGED_COUNT_FETCH_FAILED,
        payload: error,
      });
      console.log('error', error);
    });
};

type Range = Record<'start' | 'end', string>;

interface UntaggedFacesRequestParams {
  group: string;
  has_driver_association: boolean;
  include_skipped: boolean;
  status: string;
  min?: number;
  max?: number;
}
/**
 * get all untagged faces for tagging
 */
export const getUntaggedFaces = (
  groupId: string,
  properties: { include_skipped: boolean },
) => (dispatch: (any) => void, getState) => {
  dispatch({ type: FETCHING_UNTAGGED_FACES });

  const { include_skipped } = properties;

  const generateUrl = (range: Range) => {
    const params: UntaggedFacesRequestParams = {
      group: groupId,
      has_driver_association: false,
      include_skipped,
      status: 'active',
    };

    if (range.start) {
      params.min = moment(range.start).valueOf();
    }
    if (range.end) {
      params.max = moment(range.end).valueOf();
    }
    return path(`${getServiceUrl()}/fleets/${getFleetId()}/faces`, params);
  };

  const options = {
    method: 'GET',
  };

  /**
   * NAUTO-47287:
   * Since trips data is not stored past 30 days, tagging a face
   * older than 30 days will not have have any change, so to
   * prevent API timeouts due to the number of untagged faces, we
   * limit request to 35 days from today.
   */
  const start = untaggedFacesCutoff().toISOString();

  const end = moment().toISOString();
  const splitDateRanges = splitDateIntoIntervals({ start, end });

  const paginatedRequest = (range: Range) =>
    fetchRequest(cacheBustUrl(generateUrl(range)), options);

  const promises = splitDateRanges.map(
    async range => await paginatedRequest(range),
  );

  return Promise.all(promises)
    .then(response => {
      const faces = response.reduce((acc, { data, timezone }) => {
        return acc.concat(data.map(face => ({ ...face, timezone })));
      }, []);

      dispatch({
        type: UNTAGGED_FACES_RECEIVED,
        payload: faces || [],
      });
    })
    .catch(err => {
      dispatch({
        type: UNTAGGED_FACES_FETCH_FAILED,
        payload: err,
      });
    });
};

/**
 * Set the face's status
 * Status is used to indicate whether it is a real driver face ("active")
 * or corrupted ("passenger", "object", or "not-recognized")
 */
export const updateFaceStatus = (
  driverID: string,
  faceID: string,
  status: string,
) => (dispatch: (any) => void) => {
  dispatch({ type: UPDATING_FACE_STATUS });

  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/faces/${faceID}`,
    {
      driver: driverID,
    },
  );
  const options = {
    method: 'PUT',
    body: { status },
  };

  const mpUpdate = 'Update Face Status';
  return fetchRequest(url, options)
    .then((response: any) => {
      mpTrack(mpUpdate, { success: true });
      dispatch({
        type: FACE_STATUS_UPDATED,
        payload: {
          face: faceID,
          status,
        },
      });
    })
    .catch(error => {
      mpTrack(mpUpdate, { success: false, error });
      dispatch({
        type: FACE_STATUS_UPDATE_FAILED,
        payload: error,
      });
    });
};

/**
 * Move a face to the skipped bucket
 * Skip is used to indicate the fleet manager doesn't know who this driver is
 */
export const skipFaceTagging = (driverID: string, faceID: string) => (
  dispatch: (any) => void,
) => {
  dispatch({ type: SKIP_FACE_TAGGING });
  dispatch({ type: UPDATING_FACE_STATUS });

  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/faces/${faceID}`,
    {
      driver: driverID,
    },
  );
  const options = {
    method: 'PUT',
    body: {
      properties: {
        skipped: true,
      },
    },
  };

  return fetchRequest(url, options)
    .then((response: any) => {
      mpTrack('Skip Face', { success: true });
      dispatch({
        type: SKIP_FACE_SUCCESS,
        payload: {
          face: faceID,
          status,
        },
      });
    })
    .catch(error => {
      mpTrack('Skip Face', { success: false, error });
      dispatch({
        type: SKIP_FACE_FAILED,
        payload: error,
      });
    });
};

/**
 * Set the face's status
 * Unmerge a face disassocates it from the driver it is assigned to
 * @param driverID the id of the driver that is being untagged
 * @param faceID the id of the face that is being untagged
 */
export const unmergeFace = (driverID: string, faceID: string) => (
  dispatch: (any) => void,
) => {
  dispatch({ type: UNMERGING_FACE, payload: { faceID } });

  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/faces/${faceID}/unmerge`,
    { driver: driverID },
  );
  const options = {
    method: 'POST',
  };

  const mpFaces = 'Unmerge Face';
  return fetchRequest(url, options)
    .then((response: any) => {
      mpTrack(mpFaces, { success: true });

      const syntheticDriverID = response.data.driver.id;

      return dispatch({
        type: UNMERGE_FACE_SUCCESS,
        payload: { faceID, syntheticDriverID },
      });
    })
    .catch(error => {
      mpTrack(mpFaces, { success: false, error });
      dispatch({
        type: UNMERGE_FACE_FAIL,
        payload: error,
      });
      throw error;
    });
};

/**
 * get all faces tagged to a specific driver
 * @param driverID the id of the driver to fetch faces for
 * @param forTooltip if these faces are to be shown on the tooltip, or, by default for the driver's tags page
 */
export const getDriverFaces = (driverID: string, forTooltip = false) => (
  dispatch: (any) => void,
) => {
  dispatch({
    type: forTooltip ? FETCHING_TOOLTIP_FACES : FETCHING_DRIVER_FACES,
    payload: driverID,
  });

  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/drivers/${driverID}/faces?min=${minDate().valueOf()}${
      forTooltip ? '&limit=6' : ''
    }`,
  );

  const options = {
    method: 'GET',
  };

  const mpDrivers = 'Get Drivers Faces';
  return fetchRequest(url, options)
    .then((response: any) => {
      mpTrack(mpDrivers, {
        success: true,
        size: forTooltip ? 'tooltip' : 'fullsize',
      });
      dispatch({
        type: forTooltip
          ? DRIVER_TOOLTIP_FACES_RECEIVED
          : DRIVER_FACES_RECEIVED,
        payload: {
          faces: response.data.faces,
          driver: driverID,
        },
      });
      return response.data.faces;
    })
    .catch(error => {
      mpTrack(mpDrivers, {
        success: false,
        error,
        size: forTooltip ? 'tooltip' : 'fullsize',
      });
      dispatch({
        type: DRIVER_FACES_FETCH_FAILED,
        payload: error,
      });
    });
};

/**
 * get the closest match drivers to a face
 * @param faceID the id of the face to get suggestions for
 * @param driverID the id of the driver to get suggestions for
 */
export const getSuggestions = (driverID: string, faceID: string) => (
  dispatch: (any) => void,
) => {
  dispatch({
    type: FETCHING_SUGGESTIONS,
    payload: faceID,
  });
  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/faces/${faceID}/suggestions`,
    { driver: driverID },
  );
  const options = {
    method: 'GET',
  };

  const mpSuggestions = 'Get Face Suggestions';
  return fetchRequest(url, options)
    .then((response: any) => {
      mpTrack(mpSuggestions, { success: true });
      dispatch({
        type: SUGGESTIONS_RECEIVED,
        payload: {
          face: faceID,
          suggestions: response.data,
        },
      });
    })
    .catch(error => {
      mpTrack(mpSuggestions, { success: false, error });
      dispatch({
        type: SUGGESTIONS_FETCH_FAILED,
        payload: error,
      });
    });
};

/**
 * clears out the unassigned faces
 */
export const clearFaces = () => dispatch => {
  mpTrack('Clear Faces');
  return dispatch({ type: CLEAR_FACES });
};

export const clearSuggestions = () => dispatch => {
  mpTrack('Clear Suggestions');
  return dispatch({ type: CLEAR_SUGGESTIONS });
};

interface AssignFaceToDriver {
  oldDriverID: string;
  newDriverID: string;
  faceID: string;
}

export const assignFaceToDriver = ({
  oldDriverID,
  newDriverID,
  faceID,
}: AssignFaceToDriver) => (dispatch: (any) => void) => {
  dispatch({ type: TAGGING_DRIVER });
  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/faces/${faceID}/assign`,
    { 'old-driver': oldDriverID },
  );
  const options = {
    method: 'POST',
    body: {
      driver: newDriverID,
    },
  };

  const mpAssign = 'Assign Driver to Face';
  return fetchRequest(url, options)
    .then((response: any) => {
      mpTrack(mpAssign, {
        faceDriverID: oldDriverID,
        faceID,
        selectedDriverID: newDriverID,
        success: true,
      });
      return dispatch({
        type: DRIVER_TAGGED,
        payload: {
          face: faceID,
          driver: newDriverID,
        },
      });
    })
    .catch(error => {
      mpTrack(mpAssign, { success: false, error });
      dispatch({
        type: DRIVER_TAG_FAILED,
        payload: error,
      });
      throw new Error(error);
    });
};

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */
export interface FacesReducer {
  // all faces assigned to no driver (or synthetic driver)
  untaggedFaces: Face[];
  // all faces for the current driver shown on the drivers/tags page
  driverFaces: Face[];
  // the faces for the driver that is being hovered on,meant to show more photos of that driver's face
  tooltipFaces: Face[];
  // is fetching the untagged faces
  fetchingUntaggedFaces: boolean;
  // is fetching driver faces
  fetchingDriverFaces: boolean;
  // is fetching driver faces for a tooltip
  fetchingTooltipFaces: boolean;
  // is fetching suggestions for driver
  fetchingSuggestions: boolean;
  // is submitting a request to change a face or driver assginment
  taggingDriver: boolean;
  // the face being untagged
  untaggingFace: string;
  // is changing the status of a face (status indicated whether a face is valid as 'new' or has a corrupted status like 'passenger' or 'object')
  updatingFaceStatus: boolean;
  // the driver ID of the last driver who's face has been requested for the driver/tags page
  driverRequested: string;
  // the driver ID of the last driver who's face has been requested for a tooltip
  tooltipRequested: string;
  // the suggested drivers for a face
  // key is the face id, value is an array of suggested drivers
  suggestions: {
    [faceID: string]: Driver[];
  };
  // the total number of faces left to tag
  count: number;
  // In case API returns early when “face_count_threshold” is satisfied,
  // “has_more” will be returned in the response with true value.
  hasMoreCount: boolean;
  // catching errors
  error: string;
}

export const initialState: FacesReducer = {
  driverRequested: null,
  driverFaces: [],
  tooltipRequested: null,
  tooltipFaces: [],
  untaggedFaces: [],
  fetchingUntaggedFaces: false,
  fetchingDriverFaces: false,
  fetchingTooltipFaces: false,
  fetchingSuggestions: false,
  taggingDriver: false,
  untaggingFace: '',
  updatingFaceStatus: false,
  suggestions: {},
  count: 0,
  hasMoreCount: false,
  error: '',
};

export default (state = initialState, { type, payload }): FacesReducer => {
  switch (type) {
    case UNTAGGED_COUNT_RECEIVED:
      return {
        ...state,
        count: payload.count,
        hasMoreCount: payload.hasMore,
      };
    case CLEAR_UNTAGGED_COUNT:
      return {
        ...state,
        count: 0,
        hasMoreCount: false,
      };
    case FETCHING_SUGGESTIONS:
      return {
        ...state,
        // a key with value null means the suggestions fetch is in progress
        suggestions: {
          ...state.suggestions,
          [payload]: null,
        },
        fetchingSuggestions: true,
      };
    case SUGGESTIONS_RECEIVED:
      return {
        ...state,
        // a key with value null means the suggestions fetch is in progress
        suggestions: {
          ...state.suggestions,
          [payload.face]: payload.suggestions,
        },
        fetchingSuggestions: false,
      };
    case SUGGESTIONS_FETCH_FAILED:
      return {
        ...state,
        // a key with value null means the suggestions fetch is in progress
        error: payload,
        fetchingSuggestions: false,
      };
    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: {},
        fetchingSuggestions: false,
      };
    case FETCHING_UNTAGGED_FACES:
      return {
        ...state,
        fetchingUntaggedFaces: true,
      };
    case UNTAGGED_FACES_RECEIVED:
      return {
        ...state,
        // reverse so that the data is ordered chronologically (oldest to newest)
        untaggedFaces: payload,
        fetchingUntaggedFaces: false,
      };
    case FETCHING_DRIVER_FACES:
      return {
        ...state,
        driverRequested: payload,
        driverFaces: initialState.driverFaces,
        fetchingDriverFaces: true,
      };
    case FETCHING_TOOLTIP_FACES:
      return {
        ...state,
        tooltipRequested: payload,
        tooltipFaces: initialState.tooltipFaces,
        fetchingTooltipFaces: true,
      };
    case DRIVER_FACES_RECEIVED:
      return {
        ...state,
        driverFaces:
          // if this API response is the for the most recent driver requested
          payload.driver === state.driverRequested
            ? // set state to those faces
              payload.faces || []
            : // otherwise ignore it's response
              state.driverFaces,
        fetchingDriverFaces: false,
      };
    case DRIVER_TOOLTIP_FACES_RECEIVED:
      return {
        ...state,
        tooltipFaces:
          // if this API response is the for the most recent driver requested
          payload.driver === state.tooltipRequested
            ? // set state to those faces
              payload.faces || []
            : // otherwise ignore it's response
              state.driverFaces,
        fetchingTooltipFaces: false,
      };
    case CLEAR_FACES:
      return {
        ...state,
        untaggedFaces: initialState.untaggedFaces,
      };
    case TAGGING_DRIVER:
      return {
        ...state,
        taggingDriver: true,
      };
    case DRIVER_TAGGED:
      return {
        ...state,
        taggingDriver: false,
        // now that this face has been confirmed tagged, remove it from the untagged faces array
        untaggedFaces: state.untaggedFaces.filter(
          face => face.id !== payload.face,
        ),
        driverFaces:
          // if we are currently viewing all faces of a driver, and this tag action removed a face from that driver
          state.driverFaces.length && payload.driver !== state.driverRequested
            ? // optimistic update by removing that face from the state
              state.driverFaces.filter(face => face.id !== payload.face)
            : state.driverFaces,
      };
    case SKIP_FACE_SUCCESS: {
      const skippedFace = state.untaggedFaces.find(
        face => face.id === payload.face,
      );
      return {
        ...state,
        updatingFaceStatus: false,
        // now that this face has been skipped, remove it from the untagged faces array
        untaggedFaces: [
          ...state.untaggedFaces.filter(face => face.id !== payload.face),
          {
            ...skippedFace,
            properties: {
              ...skippedFace.properties,
              skipped: true,
            },
          },
        ],
      };
    }
    case UPDATING_FACE_STATUS:
      return {
        ...state,
        updatingFaceStatus: true,
      };
    case FACE_STATUS_UPDATED:
      // if a face has been updated to no longer be active
      if (payload.status !== 'active') {
        return {
          ...state,
          updatingFaceStatus: false,
          // remove that face from the untagged faces or driver faces list (since it is now neither of those things)
          driverFaces: state.driverFaces.filter(
            face => face.id !== payload.face,
          ),
          untaggedFaces: state.untaggedFaces.filter(
            face => face.id !== payload.face,
          ),
        };
      }
    case UNMERGING_FACE:
      // if a face has been unmerged
      return {
        ...state,
        untaggingFace: payload.faceID,
      };

    case UNMERGE_FACE_SUCCESS:
      // if a face has been unmerged
      return {
        ...state,
        untaggingFace: '',
        // remove from driver faces, since it no longer belongs to a driver
        driverFaces: state.driverFaces.filter(
          face => face.id !== payload.faceID,
        ),
      };
    case UNMERGE_FACE_FAIL:
      return {
        ...state,
        untaggingFace: '',
      };
    default:
      return state;
  }
};
