import { Dispatch, Action } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { Vehicle } from 'models/db';
import config from 'utils/config';
import { fetchRequest } from 'utils/requests';
import {
  isReadOnlySelector,
  isSupportUserSelector,
  currentFleetSelector,
} from 'components/auth/auth.reducer';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import {
  groupVehicleIDsSelector,
  activeGroupSelector,
  Group,
} from 'components/groups/groups.redux';
import { fleetListSelector } from 'components/device-actions/device-actions.redux';
import {
  isSilent,
  isLoose,
  isObstructed,
  isInService,
  isRMAInProgress,
} from 'components/fleet-health/utils';
import { keyBy } from 'lodash-es';
import { Filter } from 'components/fleet-health/types';
import * as API from '@nauto/api';

export enum Constants {
  HYDRATE_VEHICLES = 'vehicles/hydrate-vehicles',
  FETCH_VEHICLES_START = 'vehicles/fetch-start',
  FETCH_VEHICLES_ERROR = 'vehicles/fetch-error',
  FETCH_VEHICLES_SUCCESS = 'vehicles/fetch-success',
  UPDATE_VEHICLE_START = 'vehicles/update-start',
  UPDATE_VEHICLE_ERROR = 'vehicles/update-error',
  UPDATE_VEHICLE_SUCCESS = 'vehicles/update-success',
  UPLOAD_RMA_NOTES_START = 'vehicles/upload-rma-notes-start',
  UPLOAD_RMA_NOTES_ERROR = 'vehicles/upload-rma-notes-error',
  UPLOAD_RMA_NOTES_SUCCESS = 'vehicles/upload-rma-notes-success',
  CLEAR_VEHICLES_DATA = 'vehicles/clear-all',
  SET_ACTIVE_VEHICLE = 'vehicles/set-active-vehicle',
  CLEAR_ACTIVE_VEHICLE = 'vehicles/clear-active-vehicle',
  SET_ACTIVE_VEHICLE_HEALTH_FILTER = 'vehicles/set-active-vehicle-health-filter',
}
export const FETCH_VEHICLES_SUCCESS = Constants.FETCH_VEHICLES_SUCCESS;
export const SET_ACTIVE_VEHICLE = Constants.SET_ACTIVE_VEHICLE;

export const vehicleAnDeviceListSelector = createSelector(
  ({ vehicles }) => vehicles.vehicleList,
  list => list,
);

export const hydrateVehicles = vehicles => dispatch =>
  dispatch({
    type: Constants.HYDRATE_VEHICLES,
    payload: {
      vehicles,
    },
  });

// filter out just vehicles
// array items without ids are vehicle-less devices
export const fleetVehiclesSelector = createSelector(
  vehicleAnDeviceListSelector,
  list => list.filter(v => !!v.id),
);

export const fleetDevicesAndVehiclesSelector = createSelector(
  vehicleAnDeviceListSelector,
  list => {
    let i = 0;

    return list.map(v => ({
      ...v,
      id: v.id || `NO_VEHICLE_${++i}`,
      name: v.name || `(${v.device_id})`,
      has_vehicle_assignment: !!v.id,
      shadow: v.shadow || {},
    }));
  },
);

// build a map object with vehicle ids as keys and vehicles as values
export const indexedFleetVehiclesSelector = createSelector(
  fleetDevicesAndVehiclesSelector,
  vehicles =>
    keyBy(vehicles, vehicle =>
      vehicle.id.startsWith('NO_VEHICLE_') ? vehicle.device_id : vehicle.id,
    ),
);

export const findVehicleInGroup = (
  group: Group,
  vehicleId: string,
  returnGroup = false,
) => {
  if (
    group.direct_vehicle_ids &&
    group.direct_vehicle_ids.includes(vehicleId)
  ) {
    return returnGroup ? group : group.name;
  }

  return group.groups.reduce(
    (nameFound, g) =>
      nameFound || findVehicleInGroup(g, vehicleId, returnGroup),
    false,
  );
};

// tslint:disable-next-line:prettier
export const groupVehiclesAndDevicesSelector = createSelector<
  any,
  any,
  any,
  any,
  any,
  any
>(
  fleetDevicesAndVehiclesSelector,
  indexedFleetVehiclesSelector,
  activeGroupSelector,
  groupVehicleIDsSelector,
  (vehiclesList, vehiclesMap, group, ids) => {
    if (!group || group.tag === 'entire-company') {
      return vehiclesList;
    }
    return ids.reduce((list, id) => {
      const v = vehiclesMap[id];
      return v ? [...list, v] : list;
    }, []);
  },
);

export const groupVehiclesSelector = createSelector(
  groupVehiclesAndDevicesSelector,
  vehiclesAndDevices =>
    vehiclesAndDevices.filter(v => v.has_vehicle_assignment),
);

export const inRMAInProgressSelector = createSelector(
  groupVehiclesAndDevicesSelector,
  vehicles => vehicles.filter(isRMAInProgress),
);

export const inServiceSelector = createSelector(
  groupVehiclesAndDevicesSelector,
  vehicles => vehicles.filter(isInService),
);

export const unhealthyCountSelector = createSelector(
  inServiceSelector,
  vehicles =>
    vehicles.filter(
      vehicle => isSilent(vehicle) || isLoose(vehicle) || isObstructed(vehicle),
    ).length,
);

export const inServiceCountSelector = createSelector(
  inServiceSelector,
  vehicles => vehicles.length,
);

export const inRMAInProgressCountSelector = createSelector(
  inRMAInProgressSelector,
  vehicles => vehicles.length,
);

export const silentCountSelector = createSelector(
  inServiceSelector,
  vehicles => vehicles.filter(isSilent).length,
);

export const silentNotLooseNotObstructedCountSelector = createSelector(
  inServiceSelector,
  vehicles => {
    return vehicles.filter(v => isSilent(v) && !isLoose(v) && !isObstructed(v))
      .length;
  },
);

export const looseCountSelector = createSelector(
  inServiceSelector,
  vehicles => vehicles.filter(isLoose).length,
);

export const obstructedCountSelector = createSelector(
  inServiceSelector,
  vehicles => vehicles.filter(isObstructed).length,
);

export const alphabeticalVehicleListSelector = createSelector(
  groupVehiclesSelector,
  vehicles =>
    vehicles.sort((v1, v2) => {
      const v1name = v1.name || '';
      const v2name = v2.name || '';
      return (
        v1name.localeCompare(v2name, undefined, { numeric: true }) ||
        v1.id.localeCompare(v2.id)
      );
    }),
);

export const fleetSelector = createSelector(
  fleetListSelector,
  fleetList => fleetList.find(({ id }) => id === getFleetId()) || {},
);

export const vehicleHealthFilterSelector = createSelector(
  ({ vehicles }) => vehicles.vehicleHealthFilter,
  healthFilter => healthFilter || Filter.FleetHealth,
);

export const filteredVehicleAndDeviceSelector = createSelector(
  groupVehiclesAndDevicesSelector,
  vehicleHealthFilterSelector,
  (list, filter) => {
    if (filter === Filter.FleetHealth || !filter) {
      return list;
    } else if (filter === Filter.RMAStatus) {
      return list.filter(isRMAInProgress);
    } else {
      return list.filter(vehicle => !!vehicle.health[filter]);
    }
  },
);

export const vehicleListSelector = createStructuredSelector({
  fleet: fleetSelector,
  isReadOnly: isReadOnlySelector,
  isSupportUser: isSupportUserSelector,
  fleetInfo: currentFleetSelector,
  isAssigning: ({ groups }) => groups.assigningVehicles,
  vehicles: groupVehiclesAndDevicesSelector,
  isFetching: ({ vehicles }) => vehicles.isFetching,
});

export const selectActiveVehicle = createSelector(
  indexedFleetVehiclesSelector,
  ({ vehicles }) => vehicles.active,
  (vehiclesMap, activeId) => vehiclesMap[activeId],
);

export const setActiveVehicle = (vehicleId: string) => dispatch => {
  dispatch({
    type: Constants.SET_ACTIVE_VEHICLE,
    meta: { sync: true },
    payload: { vehicleId },
  });
};

export const clearActiveVehicle = () => dispatch =>
  dispatch({
    type: Constants.CLEAR_ACTIVE_VEHICLE,
    meta: { sync: true },
    payload: { vehicleId: '' },
  });

export const fetchVehiclesList = () => (
  dispatch: Dispatch<Action>,
  getState,
): Promise<any> => {
  const fleetId = getState().user.currentFleet;

  dispatch({
    type: Constants.FETCH_VEHICLES_START,
  });

  return API.vehicles
    .fetchVehiclesList({ fleetId })
    .then(({ data }) => {
      return dispatch({
        type: Constants.FETCH_VEHICLES_SUCCESS,
        meta: { sync: true },
        payload: {
          vehicles: data,
        },
      });
    })
    .catch(error => {
      return dispatch({
        type: Constants.FETCH_VEHICLES_ERROR,
        payload: { error },
      });
    });
};
/* tslint:disable:no-duplicate-string */
export const clearVehiclesData = () => (dispatch: Dispatch<Action>) =>
  dispatch({ type: Constants.CLEAR_VEHICLES_DATA });

export const setVehicleHealthFilter = (filter: string) => (
  dispatch: Dispatch<Action>,
) => {
  return dispatch({
    type: Constants.SET_ACTIVE_VEHICLE_HEALTH_FILTER,
    payload: {
      vehicleHealthFilter: filter,
    },
  });
};

export const updateVehicle = (
  id: string,
  data: any,
  refetchVehicles?: () => void,
) => (dispatch: Dispatch<Action>, getState): Promise<any> => {
  const fleetId = getState().user.currentFleet;
  dispatch({ type: Constants.UPDATE_VEHICLE_START });

  return API.vehicles
    .updateVehicle(
      { fleetId, vehicleId: id, data },
      { url: config.admin_api_url },
    )
    .then(response => {
      refetchVehicles && refetchVehicles();
      return dispatch({
        type: Constants.UPDATE_VEHICLE_SUCCESS,
        success: true,
        payload: {
          vehicle: response.data,
        },
      });
    })
    .catch(error => {
      return dispatch({
        type: Constants.UPDATE_VEHICLE_ERROR,
        success: false,
        payload: {
          error: error.message,
          error_code:
            error.message === 'vehicle not found'
              ? 'ERROR_VEHICLE_NOT_FOUND'
              : '',
        },
      });
    });
};

export const uploadRMANotes = (id: string, data: any) => (
  dispatch: Dispatch<Action>,
  getState,
): Promise<any> => {
  const fleetId = getState().user.currentFleet;
  const root = getServiceUrl().replace('2.2', '3');
  const url = `${root}/fleets/${fleetId}/vehicles/${id}/rma`;
  const options = {
    method: 'POST',
    body: data,
  };
  dispatch({ type: Constants.UPLOAD_RMA_NOTES_START });

  return fetchRequest(url, options)
    .then(response => {
      if (response && response.success) {
        return dispatch({
          type: Constants.UPLOAD_RMA_NOTES_SUCCESS,
          success: true,
          payload: { s3_key: response.data.s3_key, id },
        });
      }
      if (response && !response.success) {
        return dispatch({
          type: Constants.UPLOAD_RMA_NOTES_ERROR,
          success: false,
          payload: {
            error: 'UPLOAD_RMA_NOTES_ERROR',
          },
        });
      }
    })
    .catch(error => {
      console.log('error', error);
      return dispatch({
        type: Constants.UPLOAD_RMA_NOTES_ERROR,
        success: false,
        payload: {
          error: error.message,
        },
      });
    });
};

export interface ReducerState {
  vehicleList: Vehicle[];
  filteredVehicleList: Vehicle[];
  isFetching: boolean;
  updating: boolean;
  error: string;
  active: string;
  vehicleHealthFilter?: string;
}

export const initialState: ReducerState = {
  vehicleList: [],
  filteredVehicleList: [],
  isFetching: false,
  updating: false,
  error: '',
  active: '',
  vehicleHealthFilter: Filter.FleetHealth,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case Constants.SET_ACTIVE_VEHICLE:
      return {
        ...state,
        active: payload.vehicleId,
      };

    case Constants.CLEAR_ACTIVE_VEHICLE:
      return {
        ...state,
        active: initialState.active,
      };

    case Constants.CLEAR_VEHICLES_DATA: {
      return initialState;
    }

    case Constants.FETCH_VEHICLES_START:
      return {
        ...state,
        isFetching: true,
        error: '',
      };

    case Constants.FETCH_VEHICLES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: payload.error,
      };

    case Constants.FETCH_VEHICLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        vehicleList: payload.vehicles,
        error: '',
      };

    case Constants.HYDRATE_VEHICLES:
      return {
        ...state,
        vehicleList: payload.vehicles,
      };

    case Constants.UPDATE_VEHICLE_START:
      return {
        ...state,
        updating: true,
        error: '',
      };

    case Constants.UPDATE_VEHICLE_ERROR:
      return {
        ...state,
        updating: false,
        error: payload.error,
      };

    case Constants.UPDATE_VEHICLE_SUCCESS: {
      const index = state.vehicleList.findIndex(vehicle => {
        return payload.vehicle.id === vehicle.id;
      });
      const updatedVehicleList = [...state.vehicleList];
      updatedVehicleList[index] = {
        ...updatedVehicleList[index],
        ...payload.vehicle,
      };
      return {
        ...state,
        vehicleList: updatedVehicleList,
        updating: false,
        error: '',
      };
    }

    case Constants.UPLOAD_RMA_NOTES_START:
      return {
        ...state,
        updating: true,
        error: '',
      };

    case Constants.UPLOAD_RMA_NOTES_ERROR:
      return {
        ...state,
        updating: false,
        error: payload.error,
      };

    case Constants.UPLOAD_RMA_NOTES_SUCCESS: {
      const index = state.vehicleList.findIndex(vehicle => {
        return payload.id === vehicle.id;
      });
      const updatedVehicleList = [...state.vehicleList];
      updatedVehicleList[index] = {
        ...updatedVehicleList[index],
        ...{ s3_key: payload.s3_key },
      };
      return {
        ...state,
        vehicleList: updatedVehicleList,
        updating: false,
        error: '',
      };
    }

    case Constants.SET_ACTIVE_VEHICLE_HEALTH_FILTER:
      return {
        ...state,
        vehicleHealthFilter: payload.vehicleHealthFilter,
      };

    default:
      return state;
  }
};
