import { createGlobalStyle } from 'styled-components';

export type ThemeType = {
  colors: {
    brand100: string;
    brand200: string;
    brand300: string;
    brand: string;
    navigationLogoBG: string;
    navigationBG: string;
  };
  fonts: {
    primary: string;
    medium: string;
    bold: string;
    black: string;
    book: string;
    family: string;
  };
  images: {
    logo_horizontal: string;
    nav_logo?: string;
  };
};

const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
  body {
    font-family: ${({ theme }) => theme && theme.fonts.primary};
  }
  .react-toast-notifications__container {
    z-index: 9999 !important;
  }
`;

export default GlobalStyles;
