export { default as mpTrackRoute } from './mixpanel-track-route';
import { MixPanelInfo } from 'components/tracking/tracking-selector';

declare let mixpanel;
declare let window;

export const mpInit = (...args) => {
  try {
    if (!window.Cypress) {
      mixpanel.init(...args);
    }
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const registerUser = (user: MixPanelInfo) => {
  try {
    mixpanel.identify(user.$email);
    mixpanel.register({
      Email: user.$email,
      Name: user.$user_name,
    });
    mixpanel.people.set(user);
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const registerFleetId = id => {
  try {
    mixpanel.register({
      'Fleet ID': id,
    });
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const registerFleetName = name => {
  try {
    mixpanel.register({
      'Fleet Name': name,
    });
    mixpanel.people.set({
      'Last Fleet Accessed': name,
    });
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const mpTrack = (...args) => {
  try {
    mixpanel.track(...args);
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const mpTimer = (...args) => {
  try {
    mixpanel.time_event(...args);
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const mpRegister = (...args) => {
  try {
    mixpanel.register(...args);
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const mpIdentify = (...args) => {
  try {
    mixpanel.identify(...args);
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const mpSet = (...args) => {
  try {
    mixpanel.people.set(...args);
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const mpReset = () => {
  try {
    mixpanel.reset();
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};

export const mpAlias = (...args) => {
  try {
    mixpanel.alias(...args);
    // tslint:disable-next-line
  } catch (e) {
    console.log(e);
  }
};
