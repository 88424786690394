import has from 'lodash-es/has';
import moment, { Moment } from 'moment';
import * as API from '@nauto/api';
import { Trip, Driver, Vehicle } from 'models/db';
import { getTime } from 'components/events/utils';
import * as TRIPS from 'constants/trips';
import { TripPoint } from 'redux/trips';

/**
 * get the driver ID of the trip
 */
export const getDriver = (trip: Trip): string =>
  trip.driver && trip.driver !== '' ? trip.driver : null;

/**
 * a type check to determine if an entity is a driver or a vehicle
 */
export const isDriver = (entity: Driver | Vehicle): boolean =>
  has(entity, 'first_name');

/**
 * get the ID of a trip
 */
export const getID = (trip: Trip): string => trip.id;

/**
 * start time of the trip
 */
export const getStartTime = (trip: Trip): moment.Moment =>
  moment(trip.start_ts / 1000000);

/**
 * end time of the trip
 */
export const getEndTime = (trip: Trip): moment.Moment =>
  moment(trip.latest_ts / 1000000);

/**
 * full start time including stopped state
 */
export const getFullStartTime = (trip: Trip | API.Trip): moment.Moment =>
  moment(trip.full_start_ts / 1000000);

/**
 * full end time including stopped state
 */
export const getFullEndTime = (trip: Trip | API.Trip): moment.Moment =>
  moment(trip.full_end_ts / 1000000);

export const getFormattedFullTime = (time: number): string =>
  moment(time / 1000000).toISOString();

/**
 * the point to display on the calendar/timeline when a trip is initially clicked, should be a zoomed out view
 */
export const getDefaultPlotRange = (
  trip: Trip,
  minTime: number,
  maxTime: number,
): TimelineRange => {
  // get the midpoint of the trip (the start time + half the trip duration / 1000000 for proper unit conversion)
  const midPoint = moment(
    (trip.start_ts + (trip.latest_ts - trip.start_ts) / 2) / 1000000,
  );
  // return a 12 hour range, centered on the trip. If the start or end extends beyond the minimum or maximum bounds, truncate the range.
  return {
    start: Math.max(
      midPoint
        .clone()
        .subtract(6, 'hours')
        .valueOf(),
      minTime,
    ),
    end: Math.min(
      midPoint
        .clone()
        .add(6, 'hours')
        .valueOf(),
      maxTime,
    ),
  };
};

/**
 * the point to display on the calendar/timeline when a trip is focused on - should be slightly larger than the size of the trip
 */
export const getFocusedPlotRange = (trip: Trip): TimelineRange => ({
  start: getStartTime(trip)
    .subtract(getDurationMinutes(trip) / 10, 'm')
    .valueOf(),
  end: getEndTime(trip)
    .add(getDurationMinutes(trip) / 10, 'm')
    .valueOf(),
});

/**
 * the length of a trip in minutes
 */
export const getDurationMinutes = (trip: Trip): number =>
  moment.duration(getEndTime(trip).diff(getStartTime(trip))).asMinutes();

/*
 * check if a time is during a trip
 * primarily used to determine if a scrub position is inside a trip
 */
export const includesTime = (trip: Trip, time: moment.Moment): boolean =>
  time.isBetween(
    getStartTime(trip).subtract(1, 's'),
    getEndTime(trip).add(1, 's'),
  );

/*
 * check if a trip includes an event
 * If the event was generated from that device during the trip, returns true
 */
export const includesEvent = (trip: Trip, event: API.events.Event): boolean =>
  trip.device === event.references.device_id &&
  includesTime(trip, getTime(event));

// convert a raw array of ordered state messages to a JSON source data object
export const pathToGeoJSONSourceData = (states: TripPoint[]) => {
  // ignore bad GPS points
  // don't plot if there are no non-zero points
  if (states.length === 0) {
    return null;
  }
  // convert to GeoJSONCoords
  const path = states.map(point => point.location);
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: path,
    },
  };
};

export interface TimelineRange {
  start: number;
  end: number;
}

/**
 * Get the total time in range
 */
const totalRange = (range: TimelineRange): number => range.end - range.start;

/**
 * transform a time range by decreasing it to zoom in on an area
 */
export const zoomIn = (range: TimelineRange): TimelineRange => {
  const total = totalRange(range);
  const extendBy = total / 3;
  // decrease start and end bounds by 1/3 per side, unless the range is already less than or equal to 1 minute,
  // in which case we don't want to get any smaller
  if (total >= TRIPS.MAX_ZOOM.IN) {
    return {
      start: range.start + extendBy,
      end: range.end - extendBy,
    };
  }
  return {
    start: range.start,
    end: range.end,
  };
};

/**
 * transform a time range by increasing it to zoom out on an area
 */
export const zoomOut = (
  range: TimelineRange,
  minTime: number,
  maxTime: number,
): TimelineRange => {
  const total = totalRange(range);
  const extendBy = total / 3;
  // extend start and end bounds by 1/3 per side, or up to the min or max time bounds
  return {
    start: Math.max(range.start - extendBy, minTime),
    end: Math.min(range.end + extendBy, maxTime),
  };
};

/**
 * move a time range forward to translate an area to the right
 */
export const jumpForward = (
  range: TimelineRange,
  maxTime: number,
): TimelineRange => {
  const total = totalRange(range);
  const jumpBy = total / 4;
  // move forward to either 1/4 the current range time, or to the maximum time
  return {
    start: range.end - total,
    end: Math.min(range.end + jumpBy, maxTime),
  };
};

/**
 * move a time range back to translate an area to the left
 */
export const jumpBack = (
  range: TimelineRange,
  minTime: number,
): TimelineRange => {
  const total = totalRange(range);
  const jumpBy = total / 4;
  // move forward to either 1/4 the current range time, or to the maximum time
  return {
    start: Math.max(range.start - jumpBy, minTime),
    end: range.start + total,
  };
};

export interface DurationParams {
  duration?: number;
  endTime?: Moment;
  long?: boolean;
  startTime?: Moment;
  unit?: 'second' | 'minute' | 'hour';
  t?: any;
  showSecondsUnit?: boolean;
}

export const getLocalizedDuration = (params: DurationParams): string => {
  const {
    t,
    startTime,
    endTime,
    duration,
    unit,
    long,
    showSecondsUnit = false,
  } = params;
  const momentDuration =
    startTime && endTime
      ? moment.duration(endTime.diff(startTime))
      : moment.duration(duration, unit);
  const wholeHours = Math.floor(momentDuration.asHours());
  const longHours = wholeHours
    ? t('{{count}} hours', { count: wholeHours }) + ' '
    : '';
  const longMinutes = t('{{count}} minutes', {
    count: momentDuration.minutes(),
  });
  const longSeconds =
    showSecondsUnit &&
    momentDuration.minutes() === 0 &&
    momentDuration.seconds() > 0
      ? t(' {{count}} seconds', {
          count: momentDuration.seconds(),
        })
      : '';
  const shortHours = wholeHours
    ? t('{{count}}h', { count: wholeHours }) + ' '
    : '';
  const shortMinutes = t('{{count}}m', {
    count: momentDuration.minutes(),
  });
  const shortSeconds =
    showSecondsUnit &&
    momentDuration.minutes() === 0 &&
    momentDuration.seconds() > 0
      ? t(' {{count}}s', {
          count: momentDuration.seconds(),
        })
      : '';

  return long
    ? longHours + longMinutes + longSeconds
    : shortHours + shortMinutes + shortSeconds;
};
