import config from 'utils/config';
import { fetchRequest } from 'utils/requests';
import { languageIsJapanese, path } from 'utils/helpers';
import { createStructuredSelector } from 'reselect';
import { formattedLocation } from 'components/events/utils';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export const FETCHING_ADDRESS = 'addresses/fetching-address';
export const ADDRESS_RECEIVED = 'addresses/address-fetched';

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */
const geocodeUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

const createUrl = (): string => {
  // TODO: there must be a cleaner way to find corresponding URLs
  if (languageIsJapanese()) {
    return 'https:/geodata.jp.nauto.systems/v1/rev_geocode';
  }
  if (config.admin_api_url.includes('staging')) {
    return 'https://geodata.staging-us.nauto.systems/v1/rev_geocode';
  }
  // points to us-prod in all other case...
  return 'https://geodata.nauto.systems/v1/rev_geocode';
};

export const cleanAddress = (address: any) => {
  const { formatted_address } = address;
  return formattedLocation(formatted_address);
};

const FAILED_ADDRESS = {
  error: 'Address unknown',
};

interface Location {
  latitude: number;
  longitude: number;
}

/**
 * TODO: Please refer to https://nautodev.atlassian.net/browse/NAUTO-42231
 * Once that ticket is resolved, we can uncomment the new geocode URL
 * implementation
 */

/*
export const cleanAddress = (address: any) => {
  return formattedLocation(address);
};
*/

// gets the vehicle state messages for the given time range in data
export const lookupAddress = (location: Location) => (
  dispatch: (any) => void,
) => {
  const locationHash = JSON.stringify(location);
  dispatch({ type: FETCHING_ADDRESS, payload: { locationHash } });
  const data = {
    latlng: `${location.latitude},${location.longitude}`,
    key: config.google_key,
  };

  /**
   * TODO: Please refer to https://nautodev.atlassian.net/browse/NAUTO-42231
   * Once that ticket is resolved, we can uncomment the new geocode URL
   * implementation
   */
  /*
  return fetchRequest(
    createUrl(),
    {
      method: 'POST',
      body: JSON.stringify({
        latitude: location.latitude,
        longitude: location.longitude,
      }),
    },
    true,
  )
    .then((response: any) => {
      if (response.success) {
        dispatch({
          type: ADDRESS_RECEIVED,
          // pass the response down as the value of an object with the original S2Cell requested as a key
          payload: { [locationHash]: response.data.address },
  */
  const JP_HACK_PLEASE_DELETE = languageIsJapanese()
    ? { language: 'ja', region: 'JP' }
    : {};
  const url = path(geocodeUrl, { ...data, ...JP_HACK_PLEASE_DELETE });

  return fetchRequest(url, { method: 'GET' }, false)
    .then((response: any) => {
      if (response.status === 'OK') {
        dispatch({
          type: ADDRESS_RECEIVED,
          // pass the response down as the value of an object with the original S2Cell requested as a key
          payload: { [locationHash]: response.results[0] },
        });
      } else {
        throw Error();
      }
    })
    .catch(error => {
      dispatch({
        type: ADDRESS_RECEIVED,
        // pass the response down as the value of an object with the original S2Cell requested as a key
        payload: { [locationHash]: FAILED_ADDRESS },
      });
      console.log('error', error);
    });
};

/**
 * ----------------------------------------------------------------------------
 * Selector
 * ----------------------------------------------------------------------------
 */

export const findAddress = (location: Location, addresses) =>
  addresses[JSON.stringify(location)];

export const isFetchingAddress = (latLongObj, addresses) => {
  const locationHash = JSON.stringify(latLongObj);
  return locationHash in addresses && !addresses[locationHash];
};

export const addressesSelector = createStructuredSelector({
  addresses: ({ addresses }) => addresses,
});

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */

// selector for the mapbox map component
export interface AddressesReducer {
  [S2Cell: string]: any;
}

export const initialState: AddressesReducer = {};

export default (state = initialState, { type, payload }): AddressesReducer => {
  switch (type) {
    case FETCHING_ADDRESS:
      return {
        ...state,
        // a location hash that has a key, but a value of null, is still being fetched
        [payload.locationHash]: null,
      };
    case ADDRESS_RECEIVED:
      return {
        ...state,
        // a location hash that has a key, but an object value has been fetched
        ...payload,
      };
    default:
      return state;
  }
};
