const namespace = 'variables';

const buildKey = (key: string): string => `${namespace}~${key}`;

export const linkKeys: Record<string, string> = {
  requestVideo: buildKey('url_request_video'),
  reportSuspectedCollision: buildKey('url_report_suspected_collision'),
  fleetHealth: buildKey('url_fleet_health'),
  coachingGuidelines: buildKey('url_coaching_guidelines'),
  productTraining: buildKey('url_product_training'),
  taggingHelp: buildKey('url_tag_faces'),
  // TODO: Remove these once zendesk is sunset
  requestVideoZendesk: buildKey('url_request_video_zendesk'),
  reportSuspectedCollisionZendesk: buildKey(
    'url_report_suspected_collision_zendesk',
  ),
  fleetHealthZendesk: buildKey('url_fleet_health_zendesk'),
  coachingGuidelinesZendesk: buildKey('url_coaching_guidelines_zendesk'),
  productTrainingZendesk: buildKey('url_product_training_zendesk'),
  taggingHelpZendesk: buildKey('url_tag_faces_zendesk'),
};
