import { isJapaneseFleetSelector } from 'components/auth/auth.reducer';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { shouldShowFleet } from 'utils/helpers';

const withCurrentFleet = Wrapped => {
  class WithCurrentFleet extends React.Component<any, any> {
    render() {
      const { currentFleet, isCurrentFleetJapanese } = this.props;
      return (
        <Wrapped
          {...this.props}
          currentFleet={currentFleet}
          isCurrentFleetJapanese={isCurrentFleetJapanese}
        />
      );
    }
  }

  const userSelector = ({ user }) => ({
    currentFleet: user.currentFleet,
    currentGroup: user.currentGroup,
    fleetsTotal: user.fleets && user.fleets.filter(shouldShowFleet).length,
  });
  const selector = createSelector(
    isJapaneseFleetSelector,
    userSelector,
    (isJapaneseFleet, user) => ({
      currentFleet: user.currentFleet,
      currentGroup: user.currentGroup,
      fleetsTotal: user.fleetsTotal,
      isCurrentFleetJapanese: isJapaneseFleet,
    }),
  );

  return connect(selector)(WithCurrentFleet);
};

export interface CurrentFleetProp {
  currentFleet?: string;
  isCurrentFleetJapanese?: boolean;
}

export default withCurrentFleet as any;
