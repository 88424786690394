import React from 'react';
import styled from 'styled-components';
import { colors } from '@nauto/uikit/dist/styles';
import { Icon, IconName } from '@nauto/uikit/dist/atoms/icons';
import { spaceSizesPx } from '@nauto/core';

export interface Props {
  icon: IconName;
  className?: string;
  color?: string;
  link?: string;
  onClick?: () => void;
}

export class IconLink extends React.Component<Props> {
  static defaultProps = {
    onClick: (): void => {
      return;
    },
  };

  render(): JSX.Element {
    const { className, icon, link, children, color, onClick } = this.props;

    return (
      <div className={className}>
        <a
          className="link"
          target="_blank"
          rel="noreferrer"
          href={link}
          onClick={onClick}
        >
          <Icon
            className="link-icon"
            baseColor={color || colors.grey900}
            icon={icon}
            size="md"
          />
          <span>{children}</span>
        </a>
      </div>
    );
  }
}

export default styled(IconLink)`
  .link {
    color: ${colors.black};
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-right: ${spaceSizesPx.md};
    .link-icon {
      margin-right: ${spaceSizesPx.sm};
    }
    &:hover {
      svg * {
        fill: ${colors.brand};
      }
      color: ${colors.brand};
    }
  }
`;
