interface AppcuesType {
  identify: (id: string, properties?: Record<string, any>) => void;
  track: (eventName: string, properties?: Record<string, any>) => void;
  page: (name?: string, properties?: Record<string, any>) => void;
}

const mockAppcues: AppcuesType = {
  identify: (id: string, properties?: Record<string, any>) => null,
  track: (eventName: string, properties?: Record<string, any>) => null,
  page: (name?: string, properties?: Record<string, any>) => null,
};

const getAppcues = (): AppcuesType => {
  if (process.env.NODE_ENV === 'production' && (window as any).Appcues) {
    return (window as any).Appcues;
  } else {
    return mockAppcues;
  }
};

export default getAppcues;
