import { Dispatch, Action } from 'redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { fetchRequest } from 'utils/requests';
import { Trip } from 'models/db';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { path } from 'utils/helpers';
import { OBSTRUCTION_GRAPH_DAYS } from './utils';
import { SUBTYPE } from 'constants/subtypes';
import * as API from '@nauto/api';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */

export enum ActionTypes {
  TRIPS_FETCH_START = 'trips/fetch-start',
  TRIPS_FETCH_SUCCESS = 'trips/fetch-success',
  TRIPS_FETCH_ERROR = 'trips/fetch-error',
  OBSTRUCTED_PERIODS_FETCH_START = 'obstructed-periods/fetch-start',
  OBSTRUCTED_PERIODS_FETCH_SUCCESS = 'obstructed-periods/fetch-success',
  OBSTRUCTED_PERIODS_FETCH_ERROR = 'obstructed-periods/fetch-error',
  CLEAR_DEVICE_DATA = 'obstructed-periods/clear',
}

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

export const tripsSelector = ({ health }) => health.trips;

export const tripsStructuredSelector = createStructuredSelector({
  trips: tripsSelector,
});

export const obstructedPeriodsSelector = ({ health }) =>
  health.obstructedPeriods;

export const obstructedPeriodsStructuredSelector = createStructuredSelector({
  obstructedPeriods: obstructedPeriodsSelector,
});

export const isFetchingTripsSelector = ({ health }) => health.fetchingTrips;

export const isFetchingObstructionSelector = ({ health }) =>
  health.fetchingObstructedPeriods;

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

export const getDeviceTrips = (vehicleId: string) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({ type: ActionTypes.TRIPS_FETCH_START });

  return API.devices
    .getDeviceTrips({ fleetId: getFleetId(), vehicleId })
    .then(response => {
      return dispatch({
        type: ActionTypes.TRIPS_FETCH_SUCCESS,
        payload: { trips: response.data },
      });
    })
    .catch(error => {
      console.log(error);
      return dispatch({
        type: ActionTypes.TRIPS_FETCH_ERROR,
        payload: { error: error.message },
      });
    });
};

export const getDeviceObstuctedPeriods = (vehicleId: string) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({
    type: ActionTypes.OBSTRUCTED_PERIODS_FETCH_START,
    payload: { vehicleId },
  });

  return API.devices
    .getDeviceObstuctedPeriods({ fleetId: getFleetId(), vehicleId })
    .then(response => {
      return dispatch({
        type: ActionTypes.OBSTRUCTED_PERIODS_FETCH_SUCCESS,
        payload: { obstructedPeriods: response.data, vehicleId },
      });
    })
    .catch(error => {
      console.log(error);
      return dispatch({
        type: ActionTypes.OBSTRUCTED_PERIODS_FETCH_ERROR,
        payload: { error: error.message },
      });
    });
};

export const clearHealthData = () => (dispatch: Dispatch<Action>) => {
  return dispatch({ type: ActionTypes.CLEAR_DEVICE_DATA });
};

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */

export interface HealthReducer {
  trips: Trip[];
  fetchingTrips: boolean;
  fetchingObstructedPeriods: string;
  obstructedPeriods: any[];
  error: string;
}

export const initialState: HealthReducer = {
  trips: [],
  fetchingTrips: false,
  fetchingObstructedPeriods: '',
  obstructedPeriods: [],
  error: '',
};

export default (state = initialState, { type, payload }): HealthReducer => {
  switch (type) {
    case ActionTypes.TRIPS_FETCH_START:
      return {
        ...state,
        fetchingTrips: true,
      };
    case ActionTypes.TRIPS_FETCH_SUCCESS:
      return {
        ...state,
        fetchingTrips: false,
        trips: payload.trips,
      };
    case ActionTypes.TRIPS_FETCH_ERROR:
      return {
        ...state,
        fetchingTrips: false,
        error: payload.error,
      };
    case ActionTypes.OBSTRUCTED_PERIODS_FETCH_START:
      return {
        ...state,
        fetchingObstructedPeriods: payload.vehicleId,
      };
    case ActionTypes.OBSTRUCTED_PERIODS_FETCH_SUCCESS:
      if (state.fetchingObstructedPeriods !== payload.vehicleId) {
        return state;
      }
      return {
        ...state,
        fetchingObstructedPeriods: '',
        obstructedPeriods: payload.obstructedPeriods,
      };
    case ActionTypes.OBSTRUCTED_PERIODS_FETCH_ERROR:
      return {
        ...state,
        fetchingObstructedPeriods: '',
        error: payload.error,
      };
    case ActionTypes.CLEAR_DEVICE_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
