import React, { FC, useEffect, useState } from 'react';
import * as API from '@nauto/api';
import camelize from 'camelize';
import { useSelector } from 'react-redux';
import { User } from 'models/db';
import { rolesSelector } from 'components/auth/auth.reducer';
import {
  getUmVersionSelector,
  currentFleetRoleIdsSelector,
  currentFleetSelector,
} from 'components/auth/auth.reducer';
import UserRoleContext from './context';
import { uniq } from 'lodash-es';
import config from 'utils/config';
import { USER_MANAGEMENT_VERSION } from 'hooks/use-permissions';
interface Props {
  user: User;
  roles: string[];
  globalRoles: string[];
}

const UserPermissionProvider: FC<Props> = ({ children }) => {
  const user = useSelector(({ user }) => user.user);
  const globalRoles = useSelector(({ user }) => user.globalRoles);
  const umVersion = useSelector(getUmVersionSelector);
  const roleIds = useSelector(currentFleetRoleIdsSelector) || [];
  const currentFleet = useSelector(currentFleetSelector);
  const fleetRoles = useSelector(rolesSelector);
  const [roles, setRoles] = useState([{ role: {}, featureFlags: [] }]);

  const fetchRoles = async () => {
    const roles = await Promise.all(
      roleIds.map(id =>
        API.roles
          .getRole(
            { fleetId: currentFleet.id, id },
            { url: config.admin_api_root, version: 'v3' },
          )
          .then(({ data }) => data),
      ),
    );
    setRoles(camelize(roles));
  };

  useEffect(() => {
    if (umVersion === USER_MANAGEMENT_VERSION.TWO && roleIds.length > 0) {
      fetchRoles();
    }
  }, [user, umVersion, roleIds]);

  return (
    <UserRoleContext.Provider
      value={{
        roles,
        predefinedRoles: uniq([...(fleetRoles || []), ...(globalRoles || [])]),
      }}
    >
      {children}
    </UserRoleContext.Provider>
  );
};

export default UserPermissionProvider;
