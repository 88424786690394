import moment from 'moment';
import { DispatchType, EventAPIResponse } from 'models/generics';
import { ASSIGN_TRIP_SUCCESS } from 'redux/trips';
import { TYPE, EVENT_FILTERS, LABELS } from 'constants/events';
import { getFleetId } from 'utils/localstorage';
import { createStructuredSelector, createSelector } from 'reselect';
import {
  EntityType,
  SubFleetsEntityType,
} from 'components/entity-type/entity-type-utils';
import { getTime } from 'utils/event-utils';
import groupBy from 'lodash-es/groupBy';
import { SEVERITY_DEFAULT, DEFAULT_DAYS } from './events-constants';
import { activeGroupSelector } from 'components/groups/groups.redux';
import { selectActiveEntityType } from 'components/entity-type/active-entity-type.redux';
import { fleetFeaturesSelector } from 'components/settings/settings.redux';
import { getEventType, eventTypeValues } from './utils';
import * as API from '@nauto/api';
/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
const EMPTY_EVENTS_LIST = 'events/empty-events-list';
const FETCHING_EVENTS = 'events/fetching-events';
const EVENTS_FETCHED = 'events/events-fetched';
const EVENTS_FETCH_ERROR = 'events/events-fetch-error';
const ALL_EVENTS_FETCHED = 'events/all-events-fetched';

const SET_INITIAL_FILTERS = 'events/set-initial-filters';
const SET_TYPE_FILTER = 'events/set-type-filter';

const SET_SEVERITY_FILTER = 'events/set-severity-filter';
const SET_RANGE_FILTER = 'events/set-range-filter';

/**
 * ----------------------------------------------------------------------------
 * SELECTORS
 * ----------------------------------------------------------------------------
 */
export const eventsListSelector = ({ eventData }) => eventData.events;
export const eventFiltersSelector = ({ eventData }) => eventData.eventFilters;
export const isfetchingEventsSelector = ({ eventData }) =>
  eventData.currentFetchHash;

export interface TypeGroupFilterSelector {
  typeGroups: { [group: string]: boolean };
}

export const typeGroupFilterSelector = createStructuredSelector({
  typeGroups: ({
    eventData: {
      eventFilters: { typeGroups },
    },
  }) => typeGroups,
  allowedTypes: ({
    fleetSettings: {
      fleet: {
        fleet_offering: {
          features: { event_types },
        },
      },
    },
  }) => [...event_types, TYPE.NEAR_COLLISION, TYPE.SEATBELT],
});

export const subtypeFilterSelector = createStructuredSelector({
  subtypes: ({ eventData: { eventFilters } }) =>
    eventFilters.distractionSubtypes,
});

export const rangeFilterSelector = createStructuredSelector({
  startTime: ({ eventData: { eventFilters } }) => eventFilters.startTime,
  endTime: ({ eventData: { eventFilters } }) => eventFilters.endTime,
});

export const groupEventsByDay = (
  events: EventAPIResponse[],
): { [dayKey: string]: EventAPIResponse[] } => {
  return groupBy(events, event => {
    return getTime(event)
      .startOf('day')
      .format();
  });
};

const eventsByDay = createSelector(
  eventsListSelector,
  (events: EventAPIResponse[]) => groupEventsByDay(events),
);

// ensure at least one groupType is set to true
const typesFilterIsEmptySelector = createSelector(
  ({ eventData }) => eventData.eventFilters.typeGroups,
  typeGroups => {
    return (
      !!typeGroups && !EVENT_FILTERS.some(typeGroup => !!typeGroups[typeGroup])
    );
  },
);

// ensure the active group contains at least one entity
export const groupHasEntitiesSelector = createSelector(
  activeGroupSelector,
  selectActiveEntityType,
  (activeGroup, activeEntityType) =>
    activeGroup && activeGroup[`${activeEntityType}_count`] > 0,
);

export interface EventsSelector {
  activeEntityType: EntityType | SubFleetsEntityType;
  typesFilterIsEmpty: boolean;
  eventFilters: Filters;
  groupHasEntities: boolean;
}

export const eventsPageCombinedSelector = createStructuredSelector({
  activeEntityType: selectActiveEntityType,
});

export const eventsFiltersCombinedSelector = createStructuredSelector({
  eventFilters: eventFiltersSelector,
  fleetFeatures: fleetFeaturesSelector,
  initialFiltersSet: ({ eventData }) => eventData.initialFiltersSet,
});

export const severitySelector = createStructuredSelector({
  severity: ({ eventData }) => eventData.eventFilters.severity,
});

/**
 * ----------------------------------------------------------------------------
 * ACTIONS
 * ----------------------------------------------------------------------------
 */

export const setInitialFilters = (filters: Filters) => (
  dispatch: (any) => void | Promise<any>,
) => {
  return Promise.resolve(
    dispatch({
      type: SET_INITIAL_FILTERS,
      payload: { filters },
    }),
  );
};

export const setTypeGroupFilter = (typeGroups: string[]) => (
  dispatch: (any) => void,
) => {
  dispatch({
    type: SET_TYPE_FILTER,
    payload: { typeGroups },
  });
};

export const setSeverityFilter = (severity: number) => (
  dispatch: (any) => void,
) => {
  dispatch({
    type: SET_SEVERITY_FILTER,
    payload: { severity },
  });
};

export const setRangeFilter = ({ startTime, endTime }) => (
  dispatch: (any) => void,
) => {
  dispatch({
    type: SET_RANGE_FILTER,
    payload: { startTime, endTime },
  });
};

/**
 * removes all events
 */
export const emptyEventsList = () => (dispatch: (any) => void) => {
  return dispatch({ type: EMPTY_EVENTS_LIST } as DispatchType);
};

export const updateEventLabel = ({
  event,
  name,
  value,
  callback,
  setIsLoading,
}) => async (_, getState) => {
  setIsLoading(true);
  const { featureFlags } = getState();
  const eventType = getEventType({ event, featureFlags });
  const eventTypeValue = eventTypeValues(eventType);
  const falsePositiveLabel = {
    name,
    value,
  };
  const falsePositiveEventTypes = {
    name: 'customer-review-fp-event-types',
    value: eventTypeValue,
  };

  return API.events
    .updateEventLabel({
      fleetId: getFleetId(),
      event,
      falsePositiveLabel,
      falsePositiveEventTypes,
    })
    .then(() => {
      const mergedEvent = {
        ...event,
        labels: { ...event.labels, [name]: { value } },
      };
      callback(mergedEvent);
      setIsLoading(false);
    })
    .catch(() => {
      setIsLoading(false);
    });
};

export const updateDriverEventLabel = ({
  driverId,
  event,
  name,
  value,
}: {
  driverId: string;
  event: API.events.Event;
  name: string;
  value: boolean;
}) => async _ => {
  const falsePositiveEventTypes = {
    name: LABELS.DRIVER_VIEWED_EVENT,
    value,
  };

  return API.driverLogin
    .updateEventLabel({
      driverId,
      eventId: event.id,
      falsePositiveEventTypes,
    })
    .then(() => {
      const mergedEvent = {
        ...event,
        labels: { ...event.labels, [name]: { value: `${value}` } },
      };
      return mergedEvent;
    });
};

/**
 * ----------------------------------------------------------------------------
 * REDUCERS
 * ----------------------------------------------------------------------------
 */

export const SEVERITY = {
  LOW: 'low',
  MED: 'med',
  HIGH: 'high',
};

export const SEVERITY_LEVELS = [SEVERITY.LOW, SEVERITY.MED, SEVERITY.HIGH];

export const severityNumberToString = (n: number) => SEVERITY_LEVELS[n - 1];

export interface Filters {
  typeGroups?: string[];
  severity?: number;
  startTime?: moment.Moment;
  endTime?: moment.Moment;
  hash?: string;
}

export interface EventReducer {
  // the fetched events that match the current filter
  events: EventAPIResponse[];
  // true if the end of all results has been fetched
  allEventsFetched: boolean;
  // '' if not currently fetching events,
  // a unique id for the expected current outgoing events fetching
  // when events are received, we check against this hash to make sure they are not from a stale API call
  currentFetchHash: string;
  // whether an error occurred fetching events
  error: string;
  // the current filter set
  eventFilters: Filters;
  // the page number fetched
  page: number;
  // the current day of time being fetching
  day: moment.Moment;
  // whether we have read any filters from the url
  initialFiltersSet: boolean;
}

// generates a string for the current millisecond
// used to build a unique id for each filter
const hash = (filters: Filters) => {
  delete filters.hash;
  return JSON.stringify(filters);
};

export const defaultState: EventReducer = {
  events: [],
  allEventsFetched: false,
  currentFetchHash: '',
  error: '',
  page: 0,
  day: moment().startOf('day'),
  eventFilters: {
    typeGroups: [],
    severity: SEVERITY_DEFAULT,
    startTime: moment().subtract(DEFAULT_DAYS, 'd'),
    endTime: moment(),
    hash: null,
  },
  initialFiltersSet: false,
};

export default (state = defaultState, action: DispatchType) => {
  const { payload, type } = action;
  switch (type) {
    case SET_INITIAL_FILTERS: {
      const newFilters: Filters = {
        ...state.eventFilters,
        ...payload.filters,
      };
      newFilters.hash = hash(newFilters);

      return {
        ...state,
        day: newFilters.endTime.startOf('day'),
        eventFilters: newFilters,
        initialFiltersSet: true,
      };
    }
    case SET_TYPE_FILTER: {
      const newTypeFilters: Filters = {
        ...state.eventFilters,
        typeGroups: payload.typeGroups,
      };
      newTypeFilters.hash = hash(newTypeFilters);
      return {
        ...state,
        eventFilters: newTypeFilters,
      };
    }
    case SET_SEVERITY_FILTER: {
      const newSeverityFilters: Filters = {
        ...state.eventFilters,
        severity: payload.severity,
      };
      newSeverityFilters.hash = hash(newSeverityFilters);
      return {
        ...state,
        eventFilters: newSeverityFilters,
      };
    }
    case SET_RANGE_FILTER: {
      const newRangeFilters: Filters = {
        ...state.eventFilters,
        ...payload,
      };
      newRangeFilters.hash = hash(newRangeFilters);
      return {
        ...state,
        day: payload.endTime.startOf('day'),
        eventFilters: newRangeFilters,
      };
    }
    case FETCHING_EVENTS:
      return {
        ...state,
        currentFetchHash: payload.fetchHash,
      };
    case EVENTS_FETCHED:
      // if these events are from an API response to an older fetch, ignore them
      if (payload.fetchHash !== state.currentFetchHash) {
        return state;
      }

      return {
        ...state,
        events: [...state.events, ...payload.events],
        page: payload.page,
        day: payload.day,
        currentFetchHash: '',
      };
    case EVENTS_FETCH_ERROR:
      return {
        ...state,
        // TODO: use error code to translated string mapping for improve error messages
        error: payload.error,
        currentFetchHash: '',
      };
    case EMPTY_EVENTS_LIST:
      return {
        ...defaultState,
        eventFilters: state.eventFilters,
        initialFiltersSet: state.initialFiltersSet,
      };
    case ALL_EVENTS_FETCHED:
      return {
        ...state,
        allEventsFetched: true,
        currentFetchHash: '',
      };
    // if the user has updated a trip with a new driver assignment,
    // optimistically update any events from that trip in the store
    case ASSIGN_TRIP_SUCCESS: {
      // the new trip and driver assignment
      const { tripID, driver } = action.payload;
      const updatedEvents = state.events.map(eventData =>
        // if the events was during the updated trip
        eventData.trip === tripID
          ? // update the driver
            { ...eventData, driver }
          : eventData,
      );
      return {
        ...state,
        events: updatedEvents,
      };
    }
  }
  return state;
};
