import React from 'react';
import { NotificationProps } from 'models/db';
import { UploadIcon } from '@nauto/icons';
import { Box } from '@nauto/core';
import Notification from './notification';
import LocalizedTime from 'atoms/localized-time';
import { useTranslation } from 'react-i18next';

interface Props {
  notification: NotificationProps;
  className?: string;
}

const BatchImportNotificationRow: React.SFC<Props> = ({
  notification,
  className,
}) => {
  const {
    id,
    status,
    created,
    properties: { import_type, parameters },
  } = notification;
  const [t] = useTranslation();

  const time = created;
  const timezone = parameters.timezone;

  const titles = {
    driver_import: t('Import CSV file Completed'),
  };

  return (
    <Notification
      icon={<UploadIcon size={'md'} />}
      dismissible={true}
      id={id}
      status={status}
      className={className}
    >
      <Box direction="column">
        <div data-testid="event-string">{titles[import_type]}</div>
        <LocalizedTime time={time} format="LLL" timezone={timezone} />
      </Box>
    </Notification>
  );
};

export default BatchImportNotificationRow;
