import { Flags } from 'components/feature-flags/flags';
import config from 'utils/config';

// use this selector to access feature flags in other components
export const featureFlagsSelector = ({
  featureFlags,
}: {
  featureFlags: Flags;
}) => featureFlags;

export interface LDUser {
  key: string;
  email: string;
  custom: {
    // fleet properties
    fleet_id: string;
    fleet_name: string;
    fleet_business_country: string;
    fleet_active_devices: string;
    // user properties
    user_id: string;
    user_email_domain: string;
  };
}

// TODO: once this is an env variable remove this function
// https://nautodev.atlassian.net/browse/NAUTO-20893
export const getLaunchDarklyClientSideID = () => {
  const US_PROD = '5babc4330fc3db2123c3180d';
  const JP_PROD = '5badbe773f1eb2211642aa9c';
  const US_STAGING_CANARY = '5badbea0ce9d7b211bb64044';
  const JP_STAGING_CANARY = '5badbea8e68499211ed74b76';
  const TEST = '5badbecc65949e2119ac7e10';

  const { hostname } = window.location;

  if (config.is_development) {
    return TEST;
  }

  const branchMatch = /fleet-([a-zA-Z\d-]*).nauto\.systems/gm;

  if (hostname.match(branchMatch)) {
    return US_STAGING_CANARY;
  }

  const hostClientIDMap = {
    'mydashboard.nauto.com': US_PROD,
    'mydashboard.jp.nauto.com': JP_PROD,
    'fleet-beta.nauto.com': US_PROD,
    'fleet-canary.nauto.com': US_STAGING_CANARY,
    'fleet-canary.nauto.systems': US_STAGING_CANARY,
    'fleet.staging-us.nauto.systems': US_STAGING_CANARY,
    'fleet-canary.jp.nauto.systems': JP_STAGING_CANARY,
    'fleet.staging-jp.nauto.systems': JP_STAGING_CANARY,
    'fleet.test-us.nauto.systems': TEST,
    'hackathon-fleet-app.staging-us.nauto.systems': US_STAGING_CANARY,
  };
  return hostClientIDMap[hostname] || US_PROD;
};
