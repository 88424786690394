export enum AlertType {
  Acceleration = 'acceleration-hard',
  BatteryDrain = 'battery-drain',
  Braking = 'braking-hard',
  Collision = 'collision',
  CustomVideoRequest = 'custom-video-request',
  DeviceOff = 'device-off',
  Distraction = 'distraction',
  MarkPanic = 'mark-panic',
  PossibleCollision = 'possible-collision',
  ReviewConfirmedDistraction = 'review-confirmed-distraction',
  ReviewConfirmedTailgating = 'review-confirmed-tailgating',
  Tailgating = 'tailgating',
  UntaggedDriver = 'untagged-driver',
  UploadedCustomVideoRequest = 'uploaded-custom-video-request',
  UploadedMarkPanic = 'uploaded-mark-panic',
  VerifyingCustomVideoRequest = 'verifying-custom-video-request',
  VideoSyncFailure = 'video-sync-failure',
  VideoSyncPending = 'video-sync-pending',
  VideoSyncSuccess = 'video-sync-success',
  ObstructedDevices = 'obstructed-devices',
}

export const alertTypeIDMap = {
  [AlertType.PossibleCollision]: '2',
  [AlertType.MarkPanic]: '3',
  [AlertType.BatteryDrain]: '4',
  [AlertType.DeviceOff]: '5',
  [AlertType.UntaggedDriver]: '6',
  [AlertType.VideoSyncPending]: '7',
  [AlertType.VideoSyncSuccess]: '8',
  [AlertType.VideoSyncFailure]: '9',
  [AlertType.VerifyingCustomVideoRequest]: '10',
  [AlertType.CustomVideoRequest]: '11',
  [AlertType.UploadedCustomVideoRequest]: '12',
  [AlertType.UploadedMarkPanic]: '13',
  [AlertType.Distraction]: '14',
  [AlertType.ReviewConfirmedDistraction]: '15',
  [AlertType.Tailgating]: '16',
  [AlertType.ReviewConfirmedTailgating]: '17',
  [AlertType.Acceleration]: '18',
  [AlertType.Braking]: '19',
  [AlertType.ObstructedDevices]: '20',
};
