import config from 'utils/config';

// admin_api_root: "https://admin-api.nauto.systems"
// admin_api_root: 'https://admin-api.staging-us.nauto.systems';
// https://fleet.staging-us.nauto.systems/ : api.staging-us.nauto.systems
// https://fleet-canary.nauto.systems/ : api.nauto.systems
// https://fleet-canary.jp.nauto.systems/ : api.jp.nauto.systems
// https://mydashboard.nauto.com/ : api.nauto.systems
// https://mydashboard.jp.nauto.com/ : api.jp.nauto.systems

export const buildAPIURLPrefix = (): string => {
  const host = window.location.host;
  const rootURL = config.admin_api_root;
  let serviceURL = 'https://api.nauto.systems';
  if (rootURL.includes('staging')) {
    serviceURL = rootURL.replace('admin-api', 'api'); //`https://api.staging-us.nauto.systems`
  } else if (host.includes('jp')) {
    serviceURL = rootURL.replace('admin-api', 'api.jp'); //`https://api.jp.nauto.systems`
  }
  return serviceURL;
};

export const buildLoginAPIURLPrefix = (): string => {
  const host = window.location.host;
  const rootURL = config.admin_api_root;
  let serviceURL = 'https://api.us.nauto.systems/v3';
  if (rootURL.includes('staging')) {
    serviceURL = config.admin_api_url; //`https://admin-api.staging-us.nauto.systems/v2.2`
  } else if (host.includes('jp')) {
    serviceURL = config.admin_api_url_v3.replace('admin-api', 'api.jp'); //`https://api.jp.nauto.systems/v3`
  }
  return serviceURL;
};
