import React from 'react';
import styled from 'styled-components';
import { colors, Box, Text } from '@nauto/core';
import { CaretIcon } from '@nauto/icons';
import useFeatureFlags from 'hooks/use-feature-flags';

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  .sub-fleet-selector > div {
    padding: 0 0.25rem;
  }
  .sub-fleet-selector button > span:last-of-type {
    margin-top: 2px;
  }
`;

export const Separator = ({ color = colors.grey500 }) => {
  const { newNavigationUi } = useFeatureFlags();

  return (
    <Box paddingLeft="sm" paddingRight="sm">
      {newNavigationUi ? (
        <CaretIcon
          color={colors.grey500}
          style={{ transform: 'rotate(-90deg)' }}
        />
      ) : (
        <Text color={color} size={newNavigationUi ? 'md' : 'xl'}>
          |
        </Text>
      )}
    </Box>
  );
};
