import { Action, Dispatch } from 'redux';
import * as API from '@nauto/api';
import { getFleetId } from 'utils/localstorage';

export interface LogEntry {
  timestamp: string;
  url: string;
}

export interface ReducerState {
  updating: boolean;
  error: string;
  logsList: LogEntry[];
  errorLogsList: LogEntry[];
  fetchingLogs: boolean;
  fetchingErrorLogs: boolean;
}

export const initialState: ReducerState = {
  updating: false,
  fetchingLogs: false,
  fetchingErrorLogs: false,
  logsList: [],
  errorLogsList: [],
  error: '',
};

export enum Constants {
  GET_LOGS_LIST_START = 'logs/get-logs-list-start',
  GET_LOGS_LIST_END = 'logs/get-logs-list-end',
  GET_LOGS_LIST_ERROR = 'logs/get-logs-list-error',
  GET_ERROR_LOGS_LIST_START = 'logs/get-error-logs-list-start',
  GET_ERROR_LOGS_LIST_END = 'logs/get-error-logs-list-end',
  GET_ERROR_LOGS_LIST_ERROR = 'logs/get-error-logs-list-error',
  SEND_LOGS_REQUEST_START = 'logs/start',
  SEND_LOGS_REQUEST_ERROR = 'logs/error',
  SEND_LOGS_REQUEST_END = 'logs/success',
}
/* tslint:disable:no-duplicate-string */
export const sendLogsRequest = (deviceId: string) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({ type: Constants.SEND_LOGS_REQUEST_START });
  const fleetId = getFleetId();

  return API.admin
    .requestLogs({ fleetId, deviceId })
    .then(response =>
      dispatch({
        type: Constants.SEND_LOGS_REQUEST_END,
        payload: { ...response },
      }),
    )
    .catch(error =>
      dispatch({
        type: Constants.SEND_LOGS_REQUEST_ERROR,
        payload: { error: error.message },
      }),
    );
};

export const getLogsForDevice = (deviceId: string) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({ type: Constants.GET_LOGS_LIST_START });

  return API.admin
    .getLogs({ deviceId, type: 'logcat' })
    .then(logs =>
      dispatch({
        type: Constants.GET_LOGS_LIST_END,
        payload: [...logs],
      }),
    )
    .catch(error =>
      dispatch({
        type: Constants.GET_LOGS_LIST_ERROR,
        payload: { error: error.message },
      }),
    );
};

export const getErrorLogsForDevice = (deviceId: string) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({ type: Constants.GET_ERROR_LOGS_LIST_START });

  return API.admin
    .getLogs({ deviceId, type: 'device-error-log' })
    .then(logs =>
      dispatch({
        type: Constants.GET_ERROR_LOGS_LIST_END,
        payload: [...logs],
      }),
    )
    .catch(error =>
      dispatch({
        type: Constants.GET_ERROR_LOGS_LIST_ERROR,
        payload: { error: error.message },
      }),
    );
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case Constants.GET_LOGS_LIST_START:
      return { ...state, fetchingLogs: true, error: '' };

    case Constants.GET_LOGS_LIST_END:
      return { ...state, fetchingLogs: false, error: '', logsList: payload };

    case Constants.GET_LOGS_LIST_ERROR:
      return { ...state, fetchingLogs: false, error: payload.error };

    case Constants.GET_ERROR_LOGS_LIST_START:
      return { ...state, fetchingErrorLogs: true, error: '' };

    case Constants.GET_ERROR_LOGS_LIST_END:
      return {
        ...state,
        fetchingErrorLogs: false,
        errorLogsList: payload,
        error: '',
      };

    case Constants.GET_ERROR_LOGS_LIST_ERROR:
      return { ...state, fetchingErrorLogs: false, error: payload.error };

    case Constants.SEND_LOGS_REQUEST_START:
      return {
        ...state,
        updating: true,
        error: '',
      };

    case Constants.SEND_LOGS_REQUEST_ERROR:
      return {
        ...state,
        updating: false,
        error: payload.error,
      };

    case Constants.SEND_LOGS_REQUEST_END:
      return {
        ...state,
        updating: false,
        error: '',
      };

    default:
      return state;
  }
};
