import React from 'react';
import { useTranslation } from 'react-i18next';
import NoDataImage from 'assets/img/no-data.svg';
import CenteredBox from 'components/centered-box';
import { Text, Box } from '@nauto/core';

interface Props {
  message: string;
}
export const Error: React.SFC<Props> = ({ message }) => {
  const [t] = useTranslation();

  return (
    <CenteredBox>
      <Box align="center" padding="md" margin="md" direction="column">
        <Box padding="xl">
          <Text size="xl">{t('An error occurred')}</Text>
        </Box>
        {!!message && (
          <Box paddingBottom="xl">
            <Text size="md">{message}</Text>
          </Box>
        )}

        <img src={NoDataImage} />
      </Box>
    </CenteredBox>
  );
};

export default Error;
