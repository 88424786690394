import React from 'react';
import connect from 'utils/connect';
import { ConnectProps } from 'models/generics';
import { NotificationProps } from 'models/db';
import DrawerSection from './drawer-section';
import NotificationVerifyingRequest from './notification-verifying-video-request';
import NotificationUploadingVideoRequest from './notification-uploading-video-request';
import NotificationFailedVideoRequest from './notification-failed-video-request';
import { withRouter } from 'react-router-dom';
import NotificationEvent from './notification-event';
import {
  selectActiveUploadedCVRNotifications,
  selectActiveFailedCVRNotifications,
  selectActivePendingCVRNotifications,
  selectActiveUploadingCVRNotifications,
} from 'components/notifications/notifications.redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { History } from 'history';
import { createStructuredSelector } from 'reselect';
import { ROUTES } from 'routes/constants';
import { fleetLink } from 'components/fleet-link';
import ErrorBoundary from 'components/error-boundary/error-boundary';

interface Props extends WithTranslation, ConnectProps {
  activeFailedCVRNotifications: NotificationProps[];
  activePendingCVRNotifications: NotificationProps[];
  activeUploadedCVRNotifications: NotificationProps[];
  activeUploadingCVRNotifications: NotificationProps[];
  className?: string;
  history: History;
  isDismissing: any;
  isFetching: boolean;
  handleToggle?: () => any;
}

const selector = createStructuredSelector({
  activeUploadedCVRNotifications: selectActiveUploadedCVRNotifications,
  activeFailedCVRNotifications: selectActiveFailedCVRNotifications,
  activePendingCVRNotifications: selectActivePendingCVRNotifications,
  activeUploadingCVRNotifications: selectActiveUploadingCVRNotifications,
});

@withRouter
class VideoRequestDrawers extends React.Component<Props> {
  render() {
    const {
      t,
      className,
      history,
      handleToggle,
      activeUploadedCVRNotifications,
      activeFailedCVRNotifications,
      activePendingCVRNotifications,
      activeUploadingCVRNotifications,
    } = this.props;

    const NoData = ({ children }) => <p className="no-data">{children}</p>;
    const buttonText = t('See all');
    const buttonAction = () => {
      history.push(fleetLink(ROUTES.VIDEO_REQUESTS));
      handleToggle();
    };
    const activeUploadedElements = activeUploadedCVRNotifications.map(n => (
      <NotificationEvent
        key={n.id}
        notification={n}
        onLinkClick={handleToggle}
      />
    ));
    const activePendingElements = activePendingCVRNotifications.map(n => (
      <NotificationVerifyingRequest key={n.id} notification={n} />
    ));
    const activeFailedElements = activeFailedCVRNotifications.map(n => (
      <NotificationFailedVideoRequest key={n.id} notification={n} />
    ));
    const activeUploadingElements = activeUploadingCVRNotifications.map(n => (
      <NotificationUploadingVideoRequest key={n.id} notification={n} />
    ));
    const pendingElements = [
      ...activePendingElements,
      ...activeFailedElements,
      ...activeUploadingElements,
    ];

    return (
      <div
        className={`video-request-dropdown ${className}`}
        style={{ minWidth: '300px' }}
      >
        <ErrorBoundary>
          <DrawerSection
            title={t('Uploaded Videos')}
            height={'md'}
            buttonText={buttonText}
            buttonAction={buttonAction}
          >
            {activeUploadedElements.length ? (
              activeUploadedElements
            ) : (
              <NoData>{t('No new video uploads')}</NoData>
            )}
          </DrawerSection>
          <DrawerSection
            title={t('Pending Uploads')}
            height={'md'}
            buttonText={buttonText}
            buttonAction={buttonAction}
          >
            {pendingElements.length ? (
              pendingElements
            ) : (
              <NoData>{t('No pending video request uploads')}</NoData>
            )}
          </DrawerSection>
        </ErrorBoundary>
      </div>
    );
  }
}

export default connect(selector)(withTranslation()(VideoRequestDrawers));
