export enum dataTestId {
  insightsNavItem = 'insights-nav-item-mobile',
  mapNavItem = 'map-nav-item-mobile',
  eventsNavItem = 'events-nav-item-mobile',
  reportsNavItem = 'reports-nav-item-mobile',
  organizationNavItem = 'organization-nav-item-mobile',
  coachingNavItem = 'coaching-nav-item-mobile',
  superfleetInsightsNavItem = 'superfleet-insights-nav-item-mobile',
  superfleetEventsNavItem = 'superfleet-events-nav-item-mobile',
  superfleetOrganizationNavItem = 'superfleet-organization-nav-item-mobile',
  superfleetReportsNavItem = 'superfleet-reports-nav-item-mobile',
  notificationsNavItem = 'notifications-nav-item-mobile',
  videoRequestsNavItem = 'video-requests-nav-item-mobile',
  userProfileNavItem = 'user-profile-nav-item-mobile',
  userManagementNavItem = 'user-management-nav-item-mobile',
  fleetSettingsNavItem = 'fleet-settings-nav-item-mobile',
  helpCenterNavItem = 'help-center-nav-item-mobile',
  productTrainingNavItem = 'product-training-nav-item-mobile',
  developerSettingsNavItem = 'developer-settings-nav-item-mobile',
  signOutNavItem = 'sign-out-nav-item-mobile',
  driverInsightsPageNavItem = 'driver-insights-nav-item-mobile',
  driverCoachingPageNavItem = 'driver-coaching-nav-item-mobile',
  driverProfilePageNavItem = 'driver-profile-nav-item-mobile',
}
