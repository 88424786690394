import React from 'react';
import styled from 'styled-components';
import { colors } from '@nauto/uikit/dist/styles';

type Heights = 'sm' | 'md' | 'lg' | 'full';
interface PropTypes {
  height: Heights;
  title: string;
  buttonText?: string;
  buttonAction?: () => void;
  className?: string;
  archive?: boolean;
}

class DrawerSection extends React.Component<PropTypes> {
  render() {
    const {
      buttonAction,
      buttonText,
      className,
      children,
      height,
      title,
      archive,
    } = this.props;

    return !archive ? (
      <div className={`${className} height-${height} drawer-section`}>
        <div className="drawer-header flex jc-space-between ai-center">
          {title}
          {buttonAction && buttonText && (
            <button className="drawer-button" onClick={buttonAction}>
              {buttonText}
            </button>
          )}
        </div>
        <div className="drawer-items">{children}</div>
      </div>
    ) : (
      <div className={`${className} archive drawer-section`}>
        <div className="drawer-items">{children}</div>
      </div>
    );
  }
}

const StyledDrawerSection = styled(DrawerSection)`
  width: 100%;
  background: ${colors.white};
  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
  }
  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
  }
  &:not(:first-child):not(.archive) {
    border-top: 1px solid ${colors.grey100};
  }
  .drawer-header {
    background: ${colors.grey10};
    border-bottom: 1px solid ${colors.grey100};
    color: #4a4a4a;
    padding: 1rem;
  }
  .drawer-items {
    overflow-y: auto;
  }
  .no-data {
    text-align: center;
    padding: 1.5rem;
    color: ${colors.grey700};
  }
  .drawer-button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.brand};
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.bold};
  }
  &.height-sm .drawer-items {
    max-height: 10rem;
  }
  &.height-md .drawer-items {
    max-height: 15rem;
  }
  &.height-lg .drawer-items {
    max-height: 25rem;
  }
  &.height-full .drawer-items {
    max-height: calc(100% - 3.25rem);
  }
`;

export default StyledDrawerSection;
