import React, { FC } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import LoggedInUserTypeContext from './context';
import {
  currentUserTypeSelector,
  isLoggedInUserTypeDriverSelector,
} from 'components/auth/auth.reducer';
import { LoggedInUserType } from 'constants/roles';

interface Props {
  loggedInUserType?: LoggedInUserType;
  isLoggedInUserTypeDriver?: boolean;
}

const LoggedInUserTypeProvider: FC<Props> = ({
  children,
  loggedInUserType,
  isLoggedInUserTypeDriver,
}) => {
  return (
    <LoggedInUserTypeContext.Provider
      value={{ loggedInUserType, isLoggedInUserTypeDriver }}
    >
      {children}
    </LoggedInUserTypeContext.Provider>
  );
};

const selector = createStructuredSelector({
  loggedInUserType: currentUserTypeSelector,
  isLoggedInUserTypeDriver: isLoggedInUserTypeDriverSelector,
});

export default connect(selector)(LoggedInUserTypeProvider);
