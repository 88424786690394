import moment from 'moment';
import 'moment-timezone';
import { Event } from 'models/db';
import { EventAPIResponse } from 'models/generics';
import { TYPE, FILTER_FOR_TYPE, DEFAULT_TYPE_FILTER } from 'constants/events';
import { convertHexToDecimal } from 'utils/helpers';
import { eventDisplayMap } from '@nauto/uikit/dist/styles/events';
import { colors } from '@nauto/uikit/dist/styles';
import { SUBTYPE } from 'constants/subtypes';
import { TFunction } from 'i18next';

// TODO: Allow the user to select the sistem of units that whats to use on Menu => Fleet settings.
// All the units on fleet app should be according to the user preferences.
// Create a file called unitSystems and define there the imperial system of units and the international system of units which are the ones we are currently using.
// Then the atom components that show a value with units should get the system of units selected by the user with a hook and update the current value and unit to its corresponding one.
export const MPS_TO_KPH_MULTIPLIER = 3.6;
export const MPS_TO_MPH_MULTIPLIER = 2.23694;
export const METER_TO_FEET = 3.28084;
export const mpsToKph = (mpsVal: number): number =>
  mpsVal * MPS_TO_KPH_MULTIPLIER;
export const mpsToMph = (mpsVal: number): number =>
  mpsVal * MPS_TO_MPH_MULTIPLIER;
export const meterToFeet = (meterVal: number): number =>
  meterVal * METER_TO_FEET;
export const feetToMeter = (feetVal: number): number => feetVal / METER_TO_FEET;

/**
 *
 * @param s - speed in meters
 * @param miles - optional, default=true
 * @returns returns kilometers or miles per hour, defaults to mph with just speed param
 */
export const getLocalizedSpeedUnit = (s: number, miles = true): number => {
  if (isNaN(s)) {
    console.error('Missing speed parameter');
    return;
  }
  return Math.round(miles ? mpsToMph(s) : mpsToKph(s));
};

/**
 *
 * @param t - TFunction: required
 * @param miles - boolean: optional (default=true)
 * @returns returns t('km/h') or t('mph'), default returns t('mph')
 */
export const getLocalizedSpeedLabel = (t: TFunction, miles = true): string => {
  if (!t || typeof t !== 'function') {
    console.error('Missing t() function');
    return;
  }
  return miles ? t('mph') : t('km/h');
};

export interface EventParameters {
  alert_times: number[];
  bounce?: any;
  event_start?: number;
  event_end?: number;
  has_audio?: any;
  is_sensor_time: boolean;
  snapshot_in?: any;
  snapshot_out?: any;
  state?: string;
  status?: string;
  utc_boot_time_ns?: number;
  utc_boot_time_offset_ns?: number;
  video_in?: any;
  video_out?: any;
  video_start?: any;
  video_stop?: any;
  media_uploads?: {
    expected: number;
    expired: number;
    failed: number;
    uploaded: number;
  };
  longitude?: number;
  latitude?: number;
  type?: SUBTYPE;
  severity?: string;
  severity_v1?: string;
  initial_velocity?: number;
  final_velocity?: number;
}

/**
 * sync the type groups object to a string
 * @param object typeGroups stored in redux
 * @return string string to use in url location params
 */
export const stringifyTypeGroups = (typeGroups: {
  [group: string]: boolean;
}): string =>
  Object.keys(typeGroups)
    .filter(g => !!typeGroups[g])
    .join('_');

/**
 * read the query param type groups into an object
 * @param string string to use in url location params
 * @return object typeGroups to store in redux
 */
export const parseTypeGroups = (
  typeGroupsParam: string,
  allowableEvents: string[],
): string[] => {
  const params = typeGroupsParam
    ? typeGroupsParam.split('_')
    : Object.keys(DEFAULT_TYPE_FILTER).filter(
        key => !!DEFAULT_TYPE_FILTER[key],
      );

  return params.filter(typeGroup => allowableEvents.includes(typeGroup));
};

export const eventToGeoJSONFeature = (event: Event) => {
  const color = getColor(event);
  const coordinates = getCoordinates(event);
  return {
    type: 'Feature',
    geometry: { type: 'Point', coordinates },
    properties: { color, id: getUniqueID(event) },
  } as any;
};

export const getCoordinates = (event: Event): [number, number] => {
  return (
    event.s2_cell_id !== '1000000000000001' && [
      event.location.longitude,
      event.location.latitude,
    ]
  );
};

export const getAlertTimesMs = (event: Event): number[] => {
  const {
    parameters: {
      alert_times,
      is_sensor_time,
      utc_boot_time_ns,
      utc_boot_time_offset_ns,
    },
  } = event;
  if (!alert_times) {
    return [];
  }
  if (is_sensor_time) {
    return alert_times.map(time =>
      Math.round((utc_boot_time_ns + utc_boot_time_offset_ns + time) / 1000000),
    );
  }
  return alert_times;
};

export const getFilterGroup = (event: Event) =>
  FILTER_FOR_TYPE[event.type] || 'unknown';

export const getUniqueID = (event: Event) => {
  return `${event.id}_${event.device}_${event.type}`;
};

export const getTime = (event: Event) => moment(event.message_ts);

/**
 * remove bounce events and events missing media. Only clean data set should be displayed to users unless in debug mode
 */
export const clean = (events: EventAPIResponse[]): EventAPIResponse[] =>
  events.filter(isClean);

/**
 * checks if an event is "clean" and should be displayed to a customer
 */
export const isClean = (event: EventAPIResponse) =>
  // ignore failed video requests
  !isBadVideoRequest(event.event) &&
  // ignore possible collision events
  event.event.type !== TYPE.POSSIBLE_COLLISION;

/**
 * Checks whether an event is a failed video request
 */
export const isBadVideoRequest = (event: Event) =>
  event.type === TYPE.REQUEST && event.parameters.status !== 'found';

/**
 * Checks whether an event was meant to have accompanying video files
 */
export const expectsVideo = (event: Event) => {
  const { parameters } = event;
  return !!(
    parameters.video_start ||
    parameters.video_stop ||
    parameters.video_in ||
    parameters.video_out
  );
};

const SEGMENT_DURATION = 5000;
export const eventVideoStart = (event: Event) => {
  const { video_in, video_out } = event.parameters;
  const existingVideo = video_in ? video_in : video_out;
  const vStart = convertHexToDecimal(existingVideo[0]);
  return Math.floor(vStart / SEGMENT_DURATION) * SEGMENT_DURATION;
};

/**
 * checks whether an event expects snapshots to uploaded as well
 */
export const expectsSnaps = (event: Event) => {
  const { snapshot_in, snapshot_out } = event.parameters;
  return !expectsVideo(event) && (!!snapshot_in || !!snapshot_out);
};

export const isDistraction = (event: Event) =>
  [TYPE.DISTRACTION, TYPE.CONFIRMED_DISTRACTION].includes(event.type);

export const isTailgating = (event: API.events.Event) =>
  [TYPE.TAILGATING, TYPE.CONFIRMED_TAILGATING].includes(event.type) ||
  event.labels?.['event-type']?.value === TYPE.TAILGATING;

// TODO: Remove client side Delta-v calculation
// will be provided by API https://nautodev.atlassian.net/browse/NAUTO-33757
export const hasDeltaV = ({ parameters }: Event): boolean => {
  return (
    typeof parameters.final_velocity === 'number' &&
    typeof parameters.initial_velocity === 'number'
  );
};

export const getDeltaV = (event: Event): number =>
  hasDeltaV(event) &&
  event.parameters.final_velocity - event.parameters.initial_velocity;

export const getKind = (event: Event) => {
  return eventDisplayMap[event.type].kind;
};

export const getColor = (event: Event) => colors[getKind(event)];

/**
 * checks for media complete
 */
export const mediaUploadsComplete = (event: Event): boolean => {
  const { media_uploads } = event.parameters;
  if (!media_uploads) {
    return false;
  }
  return (
    media_uploads.uploaded + media_uploads.expired + media_uploads.failed ===
    media_uploads.expected
  );
};

/**
 * checks if any of the uploaded video segments were errors
 */
export const hasFailedVideos = (event: Event): boolean => {
  const { media_uploads } = event.parameters;
  return media_uploads && media_uploads.failed > 0;
};

// removes the area code from a USA address
const removeAreaCodeUSA = (location: string): string =>
  location.replace(/\s\d{5}(?:[-\s]\d{4})?, USA/g, '');

// removes '日本、' from a japanese address
const removeJapanComma = (location: string): string =>
  location.replace(/[日本、]+/g, '');

export const formattedLocation = (location: string): string =>
  removeJapanComma(removeAreaCodeUSA(location || '')).trim();
