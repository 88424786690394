import React from 'react';
import { Link, matchPath } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { lsGet, Keys } from 'utils/localstorage';
import { path } from 'utils/helpers';
import { parse } from 'query-string';
import { colors } from '@nauto/core';

export interface FleetLinkTo {
  pathname?: ROUTES | string;
  fleetId?: string;
  groupId?: string;
  entityId?: string;
  deviceId?: string;
  driverId?: string;
  userId?: string;
  eventId?: string;
  roleId?: string;
  search?: Record<string, unknown>;
  removeEntity?: boolean;
  retainSearch?: boolean;
  skipGroup?: boolean;
}

const matchFleetPath = (pathname: string) => {
  // tslint:disable-next-line:forin
  for (const key in ROUTES) {
    const match = matchPath(pathname, { path: ROUTES[key], exact: true });
    if (match) {
      return match;
    }
  }
};

export const fleetLink = (to: FleetLinkTo | ROUTES): string => {
  const isString = typeof to === 'string';
  const toObject: FleetLinkTo = isString
    ? { pathname: to as ROUTES }
    : (to as FleetLinkTo);

  const nextPath: string = toObject.pathname || window.location.pathname;

  const match = matchFleetPath(nextPath);

  if (!match) {
    console.error(`Unable to parse current path: ${nextPath}`);
    return window.location.pathname;
  }
  const {
    fleetId,
    groupId,
    entityId,
    removeEntity,
    deviceId,
    driverId,
    userId,
    eventId,
    roleId,
    skipGroup = false,
  } = toObject;

  if (removeEntity) {
    match.path = match.path.replace('e/:entityId/', '');
  }

  if (skipGroup) {
    match.path = match.path.replace('g/:groupId/', '');
  }

  const nextPathParamsInjected = match.path
    .replace(
      ':fleetId',
      fleetId || lsGet(Keys.CURRENT_FLEET) || lsGet(Keys.CACHED_FLEET),
    )
    .replace(
      ':groupId',
      groupId || lsGet(Keys.CURRENT_GROUP) || lsGet(Keys.CACHED_GROUP),
    )
    // tslint:disable-next-line:prettier
    .replace(':entityId', entityId || lsGet(Keys.CURRENT_ENTITY))
    .replace(':driverId', driverId)
    .replace(':deviceId', deviceId)
    .replace(':userId', userId)
    .replace(':eventId', eventId)
    .replace(':roleId', roleId);

  const existingSearch =
    to.search || (toObject.retainSearch && parse(window.location.search));

  return path(nextPathParamsInjected, existingSearch);
};

interface Props {
  id?: string;
  children: any;
  to?: ROUTES | FleetLinkTo;
  className?: string;
  style?: React.CSSProperties;
  replace?: boolean;
  onClick?: () => void;
  state?: any;
  'data-testid'?: string;
}

export default class FleetLink extends React.Component<Props> {
  render() {
    const {
      id,
      children,
      to,
      className,
      onClick,
      style = { color: colors.black },
      replace = false,
      state = {},
    } = this.props;

    return (
      <Link
        id={id}
        style={style}
        to={fleetLink(to)}
        className={className}
        onClick={onClick}
        replace={replace}
        data-testid={this.props['data-testid']}
      >
        {children}
      </Link>
    );
  }
}
