import * as API from '@nauto/api';
import useFleetData from 'hooks/use-fleet-data';
import useGroupData from 'hooks/use-group-data';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../constants';

interface Params<T> {
  version?: API.Version;
  enabled?: boolean;
  withDevices?: boolean;
  select?: (data: API.Vehicle[]) => T;
}

export const useVehiclesListQuery = <
  T extends API.Vehicle | API.Vehicle[] | undefined = API.Vehicle[]
>(
  params: Params<T> = {},
) => {
  const {
    version = 'v2.2',
    enabled = true,
    withDevices = true,
    select,
  } = params;
  const { id: fleetId } = useFleetData({ current: true });
  const { id: groupId } = useGroupData();

  const fetchVehiclesList = async () => {
    const { data } = await API.vehicles.fetchVehiclesList(
      { fleetId, groupId, withDevices },
      { version },
    );

    return data;
  };

  return useQuery(
    [QUERY_KEYS.ALL_VEHICLES, { fleetId, groupId, version, withDevices }],
    fetchVehiclesList,
    {
      staleTime: Infinity,
      enabled,
      select,
    },
  );
};

export default useVehiclesListQuery;
