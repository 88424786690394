export enum MixpanelEventKeys {
  PageLogin = 'page_login_pageview',
  PageForgotPassword = 'page_forgot_password_pageview',
  PageSafetyAssessment = 'page_safety_assessment_pageview',
  PageDriverSafety = 'page_driver_safety_pageview',
  PageDriverSafetyTrend = 'page_driver_safety_trend_pageview',
  PageManagerPerformance = 'page_manager_performance_pageview',
  PageManagerPerformanceTrend = 'page_manager_performance_trend_pageview',
  PageEventInsights = 'page_event_insights_pageview',
  PageDeviceHealth = 'page_device_health_pageview',
  PageMap = 'page_map_pageview',
  PageEvents = 'page_events_pageview',
  PageEventDetails = 'page_event_details_pageview',
  PageEntityEvents = 'page_entity_events_pageview',
  PageReports = 'page_reports_pageview',
  PageFleet = 'page_fleet_pageview',
  PageCoaching = 'page_coaching_pageview',
  PageCoachingDriverV3 = 'page_coaching_driver_event_details',
  PageDriverCoachCommentary = 'page_driver_coaching_commentary',
  PageTagDrivers = 'page_tag_drivers_pageview',
  PageNotifications = 'page_notifications_pageview',
  PageSetPassword = 'page_set_password_pageview',
  PageResetPassword = 'page_reset_password_pageview',
  PagePartnerLogin = 'page_partner_login_pageview',
  FedexSSOLogin = 'page_fedex_sso_login_pageview',
  BPXSSOLogin = 'page_bpx_sso_login_pageview',
  PageAmazonLogin = 'page_amazon_partner_login_pageview',
  PageLogout = 'page_logout_pageview',
  PageNoFleets = 'page_no_fleets_pageview',
  PageSSO = 'page_sso_pageview',
  PageUpdateEmail = 'page_update_email_pageview',
  PageFleets = 'page_fleets_pageview',
  PageFNOLReport = 'page_fnol_report_pageview',
  PageVideoRequests = 'page_video_requests_pageview',
  PageAddFleetUser = 'page_add_fleet_user_pageview',
  PageFleetHealth = 'page_fleet_health_pageview',
  PageEditFleetUser = 'page_edit_fleet_user_pageview',
  PageAddMultiFleetUser = 'page_add_multi_fleet_user_pageview',
  PageEditMultiFleetUser = 'page_edit_multi_fleet_user_pageview',
  PageUsers = 'page_users_pageview',
  // @TODO chrischuck change this to whatever we decide....
  PageRoles = 'page_roles_pageview',
  PageEditRoles = 'page_edit_roles_pageview',
  PageCreateRoles = 'page_create_roles_pageview',
  PageUserActivation = 'page_user_activation_pageview',
  PageProfile = 'page_profile_pageview',
  PageEvent = 'page_event_pageview',
  PageSuperfleetAssessment = 'page_superfleet_assessment_pageview',
  PageSuperfleetFleets = 'page_superfleet_fleets_pageview',
  PageSuperfleetReports = 'page_superfleet_reports_pageview',
  PageSuperfleetEvent = 'page_superfleet_event_pageview',
  PageSuperfleetEvents = 'page_superfleet_events_pageview',
  PagePrintReport = 'page_print_report_pageview',
  PageTagTrips = 'page_tag_trips_pageview',
  PageDriverCoaching = 'page_driver_coaching_pageview',
  PageDriverCoachingHistory = 'page_driver_coaching_history_pageview',
  PageDriverInsights = 'page_driver_insights_pageview',
  PageDriverInsightsDriverLogin = 'page_driver_insights_driver_login',
  PageDriverProfile = 'page_driver_profile_pageview',
  PageDriverProfileEdit = 'page_driver_profile_edit_pageview',
  PageDeveloperDocs = 'page_developer_docs_pageview',
  PageTrips = 'page_trips_pageview',
  PageVehicles = 'page_vehicles_pageview',
  InternalPage = 'page_internal_pageview',

  PageDriverAccountActivation = 'page_driver_account_activation_pageview',
  PageDriverAccountForgotPassword = 'page_driver_account_forgot_password_pageview',
  PageDriverAccountResetPassword = 'page_driver_account_reset_password_pageview',
  PageDriverAccountCoaching = 'page_driver_account_coaching_pageview',
  PageDriverAccountInsights = 'page_driver_account_insights_pageview',
  PageDriverAccountProfile = 'page_driver_account_profile_pageview',
  PageDriverAccountEventDetailsProfile = 'page_driver_account_event_details_pageview',
  DriverAccountInputPassword = 'driver_account_input_password_click',
  DriverAccountInputPasswordConfirm = 'driver_account_input_password_confirm_click',
  DriverAccountActivateDriverSubmit = 'driver_account_activate_driver_submit_click',
  DriverAccountInputNewPassword = 'driver_account_input_new_password_click',
  DriverAccountInputNewPasswordConfirm = 'driver_account_input_new_password_confirm_click',
  DriverAccountResetPasswordSubmit = 'driver_account_reset_password_submit_click',
  DriverAccountForgotPasswordInputEmail = 'driver_account_forgot_password_input_email_click',
  DriverAccountForgotPasswordSubmit = 'driver_account_forgot_password_submit_click',
  ManagerAccountForgotPassword = 'manager_account_forgot_password_click',
  DriverAccountForgotPassword = 'driver_account_forgot_password_click',
  CancelForgotPassword = 'cancel_button_forgot_password_click',
  DriverAccountDriverInsights = 'driver_account_driver_insights_click',
  DriverAccountDriverCoaching = 'driver_account_driver_coaching_click',
  DriverAccountDriverProfile = 'driver_account_driver_profile_click',
  DriverAccountDriverProfileBanner = 'driver_account_driver_profile_banner_click',
  DriverAccountDriverWatchVideo = 'driver_account_driver_watch_video_click',
  DriverAccountDriverWatchedVideo = 'driver_account_driver_watched_video_click',

  UserInputEmail = 'user_input_email_click',
  UserInputPassword = 'user_input_password_click',
  UserSignIn = 'user_button_signin',
  UserForgotPassword = 'user_button_forgot_password_click', // Login (user/pass forgot)
  UserSignInSuccess = 'user_sign_in_success',
  UserSignInFailure = 'user_sign_in_failure',
  UserSignOutSuccess = 'user_sign_out_success',
  UserPartnerSignInSuccss = 'user_partner_login',
  UserPartnerSignInFailure = 'user_partner_login_failure',
  UserForgotPasswordSubmit = 'user_forgot_password_submit_click',
  UserForgotPasswordInputEmail = 'user_forgot_password_input_email_click',
  UserInputNewPassword = 'user_input_new_password_click',
  UserInputNewPasswordConfirm = 'user_input_new_password_confirm_click',
  UserResetPasswordSubmit = 'user_reset_password_submit_click',

  BackTo = 'nav_back_to_button_click',
  PartnerLoginRedirectLocation = 'partner_login_redirect_location',

  HomeOverview = 'page_home_pageview',
  HomeFleetSettings = 'page_fleetsettings_pageview',
  HomeOpenTasksCollisions = 'home_open_tasks_collisions_click',
  HomeOpenTasksCoaching = 'home_open_tasks_coaching_click',
  HomeOpenTasksDeviceIssues = 'home_open_tasks_device issues_click',
  HomeOpenTasksTag = 'home_open_tasks_tag_click',
  HomeSubfleetAtRisk = 'home_subfleet_at_risk_fleet_name',
  HomeSubfleetAtRiskArrow = 'home_subfleet_at_risk_fleet_arrow_click',
  NavSubfleetName = 'nav_subfleet_name_click',

  NavHelpCenter = 'nav_help_center_click',
  NavProductTraining = 'nav_product_training_click',

  NavNotification = 'nav_notifications_click',
  NavNotificationSeeAll = 'nav_notifications_see_all_click',
  NavNotificationTag = 'nav_notifications_tag_click',
  NavNotificationIssues = 'nav_notifications_issues_click',
  NavVideoRequests = 'nav_video_requests_click',
  NavSearch = 'nav_search_click',
  NavUser = 'nav_user_click',
  NavUserAccount = 'nav_user_account_click',

  OverviewVERA = 'home_fleet_vera_vera_click',
  OverviewAttentiveDriving = 'home_fleet_vera_attentive_click',
  OverviewSmoothDriving = 'home_fleet_vera_smooth_click',
  OverviewToggleExpand = 'home_fleet_vera_expand_click',
  OverviewToggleExpandCollapseVera3 = 'home_fleet_vera_expand_collapse_click',
  Overview6WeeksOr12Months = 'home_fleet_vera_6weeks_or_12months_click',
  OverviewLeaderboardName = 'home_leaderboard_name_click',
  OverviewOpenTask = 'Overview / Open Task',
  OverviewDriversAtRiskClickName = 'home_at_risk_name_click',
  OverviewDriversAtRiskClickSeeAll = 'home_at_risk_seeall_click',
  OverviewSubfleetsAtRiskClickSeeAll = 'home_subfleet_at_risk_seeall_click',

  SafetyAssessmentTab = 'assessment_tab_click',
  SafetyAssessmentSearchClick = 'assessment_search_click',
  SafetyAssessmentSuperFleetName = 'assessment_superfleet_name_click',
  SafetyAssessmentFleetName = 'assessment_fleet_name_click',
  SafetyAssessmentDriverClick = 'assessment_driver_click',

  EventInsightsTab = 'event_insights_tab_click',

  DeviceHealthFilter = 'device_health_filter_click',
  DeviceHealthVehicleStatus = 'device_health_toggle_click',
  DeviceHealthMoreDetails = 'device_health_more_details_click',
  DeviceHealthSearch = 'device_health_search_click',
  DeviceHealthDownloadCSV = 'device_health_download_csv_click',
  DeviceHealthAboutDeviceHealth = 'device_health_about_device_health_click',
  DeviceHealthCancelRMAClick = 'device_health_cancel_rma_click',
  DeviceHealthRequestRMAClick = 'device_health_request_rma_click',

  MapZoom = 'map_zoom_click',
  MapZoomToAll = 'map_zoom_to_all_click',
  MapSatelliteOrMap = 'map_satellite_or_map_click',
  MapBackToLiveMap = 'map_back_to_live_map_click',
  MapShowHistory = 'map_show_history_click',
  MapHowToRequestAVideo = 'map_how_to_request_a_video_click',
  MapSuspectedCollision = 'map_suspected_collision_click',
  MapRequestVideoToggle = 'map_show_history_request_video_click',
  MapCancelRequestVideo = 'map_entity_request_video_cancel_button',
  MapModalShowHistory = 'map_entity_show_history_click',
  MapScrubUse = 'map_scruber_drag',
  MapRequestVideo = 'map_request_video_submit_click',

  EventsCalendar = 'events_calendar_click',
  EventsFilter = 'events_tab_filter_click',
  EventsFilterChange = 'events_tab_filter_change',
  EventsListEventClick = 'events_list_single_event_click',
  EventsSeveritySlide = 'events_risks_severity_slide',
  EventsHowToRequestAVideo = 'Events / How to request a video',
  EventsDownloadVideo = 'events_download_video_click',
  EventsCollisionsOpenFullReport = 'events_collisions_select_report_click',
  EventsEmailRedirect = 'events_email_redirect',
  EventsEmailRedirectSuccess = 'events_email_redirect_success',
  EventsEmailRedirectError = 'events_email_redirect_error',
  EventsPrintCollisionReport = 'events_collisions_select_print_report_click',
  EventsPlayVideo = 'events_play_video',
  EventsPlaySnapshot = 'events_play_snapshot',

  ReportDrivers = 'reports_drivers_click',
  ReportVehicle = 'reports_vehicles_click',
  ReportSubFleets = 'reports_groups_click',
  ReportDriversName = 'reports_drivers_name_click',
  ReportVehicleName = 'reports_vehicles_name_click',
  ReportSubFleetsName = 'reports_groups_name_click',
  ReportDriversSearch = 'reports_drivers_search_click',
  ReportVehiclesSearch = 'reports_vehicles_search_click',
  ReportSubFleetsSearch = 'reports_groups_search_click',
  ReportCalendar = 'reports_calendar_click',
  ReportVisibleColumn = 'reports_visible_columns_click',
  ReportTripReport = 'reports_trip_report_click',
  ReportFilterColumn = 'reports_filter_columns_click',
  ReportFilterSelection = 'reports_filter_selection_click',
  ReportDownloadCSV = 'reports_download_csv_click',
  ReportPrint = 'reports_print_click',
  ReportApplyDates = 'reports_apply_dates_click',

  FleetDownloadCSVVehicles = 'Fleet / Download vehicles csv',
  FleetDownloadCSVVDrivers = 'Fleet / Download drivers csv',
  FleetToggleDriversColumns = 'Fleet / Toggle drivers columns',
  FleetToggleVehiclesColumns = 'Fleet / Toggle vehicles columns',
  FleetAddDriver = 'Fleet / Add Driver',
  FleetDriverProfile = 'Fleet / Driver profile',
  FleetToggleColumn = 'Fleet / Toggle column',
  FleetEditVehicle = 'fleet_edit_vehicle',

  TagSelectDriver = 'tag_select_driver_click',
  TagAddDriver = 'tag_add_new_driver_click',
  TagAddDriverCancel = 'tag_add_cancel_click',
  TagAddDriverFormData = 'tag_add_driver_submit_click',
  TagAddDriverDuplicateDriver = 'tag_add_driver_duplicate_driver_click',
  TagSkip = 'tag_skip_click',
  TagCantIdentifyDriver = 'tag_cannot_identify_click',
  TagCantIdentifyDriverOption = 'tag_cannot_identify_option_click',

  ProfileInsights = 'profile_insights_click',
  ProfileEvents = 'profile_events_click',
  ProfileProfile = 'profile_profile_click',
  ProfileEdit = 'profile_edit_click',
  ProfileEditDeleteDriver = 'profile_edit_delete_driver_click',
  ProfileEditCancel = 'profile_edit_cancel_click',
  ProfileEditSaveChanges = 'profile_edit_save_changes_click',
  ProfileSeeTags = 'profile_see_tags_click',
  ProfileSeeTagsRetag = 'profile_see_tags_retag_click',
  ProfileSeeTagsUntag = 'profile_see_tags_untag_click',
  ProfileChangePhoto = 'profile_change_photo_click',
  ProfileChangePhotoSaveChanges = 'profile_change_photo_save_changes_click',

  AccountNotificationPreferencesChange = 'account_notification_preferences_change',
  UserManagementResendInvite = 'user_management_resend_invite_click',

  AccountEdit = 'account_edit_click',
  AccountNameEditUpdate = 'account_name_edit_update_click',
  AccountPhoneNumberVerify = 'account_phone_number_verify_click',
  AccountPhoneNumberEditVerify = 'account_phone_number_edit_verify_click',
  AccountEmailUpdate = 'account_email_edit_change_address_click',
  AccountPasswordEditProceed = 'account_password_edit_proceed_click',

  AccountSevereEventsAlertAddAlert = 'account_severe_events_alert_add_alert_click',
  AccountAddAlertCreateAlert = 'account_add_alert_create_alert_click',
  AccountAddAlertCancel = 'account_add_alert_cancel_click',
  AccountAddAlertSearch = 'account_add_alert_search_click',
  AccountFleetSelectionUnderSearch = 'account_fleet_selection_under_search_click',

  UserManagementSearch = 'user_management_search_click',
  UserManagementInviteNewUser = 'user_management_invite_new_user_click',
  UserManagementInviteSubmit = 'user_management_invite_submit_click',
  UserManagementInviteMultifleetSubmit = 'user_management_invite_multifleet_submit_click',
  UserManagementInviteNewUserCancel = 'user_management_invite_new_user_cancel_click',
  UserManagementInviteNewMultifleetUserCancel = 'user_management_invite_new_multifleet_user_cancel_click',
  UserManagementEmailSortingArrow = 'user_management_email_sorting_arrow_click',
  UserManagementEditUser = 'user_management_edit_user_click',
  UserManagementDeleteUser = 'user_management_delete_user_click',
  UserManagementDeleteMultifleetUser = 'user_management_delete_multifleet_user_click',
  UserManagementEditUserUpdate = 'user_management_edit_user_update_click',
  UserManagementEditMultifleetUserUpdate = 'user_management_edit_multifleet_user_update_click',
  UserManagementEditUserCancel = 'user_management_edit_user_cancel_click',
  UserManagementEditMultifleetUserCancel = 'user_management_edit_multifleet_user_cancel_click',
  UserManagementAllRoles = 'user_management_all_roles_click',

  AdminSensorDataTab = 'admin_sensor_data_tab_click',
  AdminSensorDataRequestSubmit = 'admin_sensor_data_request_submit_click',
  AdminRequestSensorDataTab = 'admin_request_sensor_data_tab_click',
  AdminLogsTab = 'admin_logs_tab_click',
  AdminLogsRequestSubmit = 'admin_logs_request_submit_click',
  AdminMediaTab = 'admin_media_tab_click',
  AdminMediaGetFileListSubmit = 'admin_media_get_file_list_submit_click',

  DriverCoaching = 'driver_coaching_click',
  CoachingOverview = 'coaching_overview_click',
  CoachingResources = 'coaching_resources_click',
  CoachingDownloadCSV = 'coaching_download_csv_click',
  CoachingFindADriver = 'coaching_find_a_driver_click',
  CoachingAllFocusAreaSelectAll = 'coaching_all_focus_area_select_all_click',
  CoachingAllFocusAreaSelection = 'coaching_all_focus_area_selection_click',
  CoachingAllFocusAreaConfirm = 'coaching_all_focus_area_confirm_click',
  CoachingAllFocusAreaCancel = 'coaching_all_focus_area_cancel_click',
  CoachingFocusAreaClear = 'coaching_focus_area_cleart_click',
  CoachingName = 'coaching_name_click',
  CoachingVera = 'coaching_vera_click',
  CoachingWeeklyChangeVera = 'coaching_weekly_change_vera_click',
  CoachingSixWeekTrend = 'coaching_6w_trend_click',
  CoachingAttentive = 'coaching_attentive_click',
  CoachingSmooth = 'coaching_smooth_click',
  CoachingEventsCoached = 'coaching_events_coached_click',
  CoachingEventsUncoached = 'coaching_events_uncoached_click',
  CoachingCoachDriver = 'coaching_coach_driver_click',
  CoachingMarkAllEventsAsCoached = 'coaching_mark_all_events_as_coached_click',
  CoachingSeeDriverProfile = 'coaching_see_driver_profile_click',
  CoachingDriverCategoryTypeClick = 'coaching_driver_category_type_click',
  CoachingDriverAllFocusAreaSelectAll = 'coaching_driver_all_focus_area_select_all_click',
  CoachingDriverAllFocusAreaSelection = 'coaching_driver_all_focus_area_selection_click',
  CoachingDriverAllFocusAreaConfirm = 'coaching_driver_all_focus_area_confirm_click',
  CoachingDriverAllFocusAreaCancel = 'coaching_driver_all_focus_area_cancel_click',
  CoachingDriverFocusAreaClear = 'coaching_driver_focus_area_clear_click',
  CoachingDriverSelectAll = 'coaching_driver_select_all_click',
  CoachingDriverMarkedAsCoached = 'coaching_driver_marked_as_coached_click',
  CoachingDriverIndividualCheckBoxClickNextToEventType = 'coaching_driver_Individual_check_box_click_next_to_event_type_click',
  CoachingDriverEventMarkAsCoached = 'coaching_driver_event_mark_as_coached_click',
  CoachingDriverEventMarkAsNotCoachable = 'coaching_driver_event_mark_as_not_coachable_click',
  CoachingDriverEventClick = 'coaching_driver_event_click_click',
  CoachingDriverOpenEventNotCoachable = 'coaching_driver_open_event_not_coachable_click',
  CoachingDriverOpenEventMarkAsCoached = 'coaching_driver_open_event_mark_as_coached_click',
  CoachingDriverCoachingSessionNotes = 'coaching_driver_coaching_session_notes_click',
  CoachingDriverCoachingSessionNotesDoneCoaching = 'coaching_driver_coaching_session_notes_done_coaching_click',
  CoachingDriverCoachingSessionNotesUndo = 'coaching_driver_coaching_session_notes_undo_click',
  CoachingDriverCoachingSessionNotesExport = 'coaching_driver_coaching_session_notes_export_click',
  CoachingDriverPolicyViolationReview = 'coaching_driver_policy_violation_review_click',
  CoachingDriverPolicyViolationMarkAllAsCoached = 'coaching_driver_policy_violation_mark_all_as_coached_click',
  HistoricalCoachingDriverCoachingSessionNotesExport = 'coaching_driver_historical_coaching_session_notes_export_click',

  CoachingV3DriversToggleClick = 'coaching_v3_drivers_toggle_click',
  CoachingV3SubfleetsToggleClick = 'coaching_v3_subfleets_toggle_click',
  CoachingV3AllDriversClick = 'coaching_v3_all_drivers_toggle_click',
  CoachingV3RecentSessionsClick = 'coaching_v3_recent_sessions_toggle_click',
  CoachingV3HistoricalSessionsClick = 'coaching_v3_historical_sessions_toggle_click',

  FleetSettingsPreferenceSpeedingThreshold = 'Fleet_settings_preference_speeding_threshold_click',
  FleetSettingsSpeedingIVAToggle = 'Fleet_settings_Speeding_IVA_toggle',

  FleetVeraScoreVersionToggle = 'fleet_vera_score_version_toggle',
  FleetFeatureUpdateBannerClickHere = 'fleet_feature_update_click_here',
  FleetVeraInfoIconClick = 'fleet_vera_info_icon_click',
  FleetVeraInfoIconLearnMore = 'fleet_vera_info_icon_learn_more',
  FleetVeraScoreDurationDropdownClick = 'fleet_vera_score_duration_click',

  ReportingNewCollisionClick = 'reporting_new_collision_click',
  ReportingNewAtRiskClick = 'reporting_new_at_risk_click',
  ReportingNewDeviceHealthClick = 'reporting_new_device_health_click',
  ReportingNewRTAClick = 'reporting_new_rta_click',
  ReportingNewPolicyViolationsClick = 'reporting_new_policy_violations_click',
  ReportingNewTopPerformingClick = 'reporting_new_top_performing_click',
  ReportingNewCustomClick = 'reporting_new_custom_click',
  ReportingNewDateRangeClick = 'reporting_new_date_range_click',
  ReportingNewCustomDateRangeClick = 'reporting_new_custom_date_range_click',
  ReportingNewCustomDateRangeApplyClick = 'reporting_new_custom_date_range_apply_click',
  ReportingNewDownloadCSVClick = 'reporting_new_download_csv_click',
  ReportingNewDownloadVideoClick = 'reporting_new_download_video_click',
  ReportingNewDownloadVideoApplyClick = 'reporting_new_download_video_apply_click',
  ReportingNewDownloadFNOLClick = 'reporting_new_download_fnol_click',
  ReportingNewViewEventClick = 'reporting_new_view_event_click',
  ReportingNewEntitySwitchClick = 'reporting_new_entity_switch_click',
  ReportingNewViewDetailsClick = 'reporting_new_view_details_click',
  ReportingNewRTAFilterClick = 'reporting_new_rta_filter_click',
  ReportingNewEntityStatusFilterClick = 'reporting_new_entity_status_filter_click',
  ReportingVEDRClick = 'reporting_vedr_click',

  UserManagementAddUserClick = 'user_management_add_user_click',
  UserManagementSaveNewUserClick = 'user_management_save_new_user_click',
  UserManagementCancelAddUserClick = 'user_management_cancel_add_user_click',
  UserManagementExportUsersClick = 'user_management_export_users_click',
  UserManagementEditUserClick = 'user_management_edit_user_click',
  UserManagementSaveUserClick = 'user_management_save_user_click',
  UserManagementCancelEditUserClick = 'user_management_cancel_edit_user_click',
  UserManagementAppendRoleClick = 'user_management_append_role_click',
  UserManagementRemoveRoleClick = 'user_management_remove_role_click',
  UserManagementSubfleetSelectClick = 'user_management_subfleet_select_click',
  UserManagementFleetSelectClick = 'user_management_fleet_select_click',
  UserManagementDownloadUsersClick = 'user_management_download_users_click',
  UserManagementRoleSelectClick = 'user_management_role_select_click',
  RoleManagementCreateRoleClick = 'role_management_create_role_click',
  RoleManagementEditRoleClick = 'role_management_edit_role_click',
  RoleManagementSaveNewRoleClick = 'role_management_save_new_role_click',
  RoleManagementCancelNewRoleClick = 'role_management_cancel_new_role_click',
  RoleManagementSaveRoleClick = 'role_management_save_role_click',
  RoleManagementCancelEditRoleClick = 'role_management_cancel_edit_role_click',
  RoleManagementParentAccessToggleClick = 'role_management_parent_access_toggle_click',
  RoleManagementAccessGroupClick = 'role_management_access_group_click',
  RoleManagementAllowOverrideClick = 'role_management_allow_override_click',
  RoleManagementNotificationEmailPreferenceClick = 'role_management_notification_email_preference_click',
  RoleManagementNotificationSmsPreferenceClick = 'role_management_notification_sms_preference_click',
  RoleManagementAddSevereAlertClick = 'role_management_add_severe_alert_click',
  RoleManagementRemoveSevereAlertClick = 'role_management_remove_severe_alert_click',

  CvrRequestVideoTab = 'cvr_request_video_tab_click',
  CvrRequestedVideosTab = 'cvr_requested_videos_tab_click',
  CvrTripSearch = 'cvr_search_button_click',
  CvrCancelRequest = 'cvr_cancel_request_click',
  CvrDownloadVideo = 'cvr_download_video_click',
  CvrSubmitVideoRequest = 'cvr_submit_request_click',

  RepeatedEventRequestVideo = 'repeated_event_request_video',
  RepeatedEventDetailView = 'repeated_event_detail_view',

  ManagerPerformanceDateRangeClick = 'manager_peformance_date_range_click',
  ManagerPerformanceCustomDateRangeClick = 'manager_peformance_custom_date_range_click',
  ManagerPerformanceCustomDateRangeApplyClick = 'manager_peformance_custom_date_range_apply_click',
  ManagerPerformanceDownloadCSVClick = 'manager_peformance_download_csv_click',
  ManagerPerformanceCalendar = 'manager_peformance_calendar_click',
  ManagerPerformanceVisibleColumn = 'manager_peformance_visible_columns_click',
  ManagerPerformanceFilterColumn = 'manager_peformance_filter_columns_click',
  ManagerPerformanceTimeIntervalTypeClick = 'manager_peformance_time_interval_click',
  ManagerPerformanceTrendTimeIntervalTypeClick = 'manager_peformance_trend_time_interval_click',
  ManagerPerformanceTrendShowMoreGraphsClick = 'manager_performance_trend_show_more_graphs_click',

  NewInsightsLanding = 'page_new_insights_landing',
  InsightsNewCurrentInsightsClick = 'insights_new_current_insights_click',
  InsightsNewSafetyAssessmentClick = 'insights_new_safety_assessment_click',
  InsightsNewDriverSafetyClick = 'insights_new_driver_safety_click',
  InsightsNewManagerPerformanceClick = 'insights_new_manager_performance_click',
  InsightsNewDeviceInsightsClick = 'insights_new_device_insights_click',

  DriverSafetyTimeIntervalTypeClick = 'driver_safety_time_interval_click',
  DriverSafetyCustomDateRangeApplyClick = 'driver_safety_custom_date_range_apply_click',
  DriverSafetyTrendTimeIntervalTypeClick = 'driver_safety_trend_time_interval_click',
  DriverSafetyTrendShowMoreGraphsClick = 'driver_safety_trend_show_more_graphs_click',

  VehiclesModulePageView = 'vehicles_module_page_view',
  VehiclesModuleOverviewClick = 'vehicles_module_page_overview_click',
  VehiclesModuleDeviceMonitoringClick = 'vehicles_module_page_device-monitoring_click',
  VehiclesModuleViewClick = 'vehicles_module_page_offline_devices_click',
  VehiclesModuleStateDropdownClick = 'vehicles_module_state_dropdown_click',
  VehiclesModuleStatusDropdownClick = 'vehicles_module_status_dropdown_click',
}
