import { colors } from '@nauto/core';
import { ThemeType } from './globalstyles';

const themes: Record<'default' | 'brightdrop', ThemeType> = {
  default: {
    colors: {
      brand100: colors.boldGreen100,
      brand200: colors.boldGreen200,
      brand300: colors.boldGreen300,
      brand: colors.boldGreen500,
      navigationLogoBG: colors.boldGreen500,
      navigationBG: colors.black,
    },
    fonts: {
      primary: 'Circular-Book',
      medium: 'Circular-Medium',
      bold: 'Circular-Bold',
      black: 'Circular-Black',
      book: 'Circular-Book',
      family: 'Circular',
    },
    images: {
      logo_horizontal:
        '//nauto-public.s3.amazonaws.com/apps/assets/img/logo-horizontal-green.svg',
    },
  },
  brightdrop: {
    colors: {
      brand: '#0072ce',
      brand100: 'rgba(0, 114, 206, .1)',
      brand200: 'rgba(0, 114, 206, .2)',
      brand300: 'rgba(0, 114, 206, .3)',
      navigationBG: '#0f1929',
      navigationLogoBG: '#fff',
    },
    fonts: {
      black: 'Overpass-Black',
      bold: 'Overpass-Bold',
      book: 'Overpass-Book',
      family: 'Overpass',
      medium: 'Overpass-Medium',
      primary: 'Overpass-Book',
    },
    images: {
      logo_horizontal:
        'https://brightdrop-cobrand.s3.amazonaws.com/brightDrop-nauto-Lock-up.svg',
      nav_logo:
        'https://brightdrop-cobrand.s3.amazonaws.com/brightdrop-cobrand.png',
    },
  },
};

export default themes;
