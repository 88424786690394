import { Dispatch, Action } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { fetchRequest } from 'utils/requests';
import config from 'utils/config';
import { ProvisionHistoryItem, DownstreamMessageHistoryItem } from 'models/db';
import { getServiceUrl } from 'utils/localstorage';
import * as API from '@nauto/api';

const provisionListSelector = createSelector(
  ({ deviceHistory }) => deviceHistory.provisionHistoryList,
  list => list || [],
);

const downstreamListSelector = createSelector(
  ({ deviceHistory }) => deviceHistory.downstreamHistoryList,
  list => list || [],
);

const provisionFiltersSelector = createSelector(
  provisionListSelector,
  ({ deviceHistory }) => deviceHistory.provisionHistoryFilters,
  (list, filters) =>
    list.length
      ? list.reduce(
          (obj, { action_type }) => ({
            ...obj,
            [action_type]: filters[action_type],
          }),
          {},
        )
      : filters,
);

const downstreamFiltersSelector = createSelector(
  downstreamListSelector,
  ({ deviceHistory }) => deviceHistory.downstreamHistoryFilters,
  (list, filters) =>
    list.length
      ? list.reduce(
          (obj, { action_type }) => ({
            ...obj,
            [action_type]: filters[action_type],
          }),
          {},
        )
      : filters,
);

const filteredProvisionListSelector = createSelector(
  provisionListSelector,
  provisionFiltersSelector,
  (list, filters) => list.filter(({ action_type }) => filters[action_type]),
);

const filteredDownstremListSelector = createSelector(
  downstreamListSelector,
  downstreamFiltersSelector,
  (list, filters) => list.filter(({ action_type }) => filters[action_type]),
);

export const provisioningHistorySelector = createStructuredSelector({
  list: filteredProvisionListSelector,
  filters: provisionFiltersSelector,
  isFetching: ({ deviceHistory }) => deviceHistory.fetching,
});

export const downstreamHistorySelector = createStructuredSelector({
  list: filteredDownstremListSelector,
  filters: downstreamFiltersSelector,
  isFetching: ({ deviceHistory }) => deviceHistory.fetching,
});

export type ProvisionHistoryFilterType =
  | 'provision'
  | 'deprovision'
  | 'recycle'
  | 'rework';

export type DownstreamMessageType =
  | 'sync'
  | 'reboot'
  | 'restart'
  | 'restart_system_app'
  | 'request_original_video'
  | 'upload_sensor_data'
  | 'upload_log_file'
  | 'restart_super_brick'
  | 'cycle_power_super_brick'
  | 'set_super_brick_config'
  | 'configuration_update'
  | 'clear_upload_queue'
  | 'request_configuration'
  | 'factory_reset'
  | 'send_internal_snapshots'
  | 'run_teamviewer_registration'
  | 'reformat_sdcard'
  | 'tailgating-calibration'
  | 'schedule_wakeup'
  | 'send-calibration-snapshots'
  | 'run-csd-tests'
  | 'start_research_mjpeg'
  | 'set-system-properties'
  | 'get-system-properties'
  | 'reboot-ssc';

export type DownstreamStatusType = 'pending' | 'sent';

export type IProvisionHistoryFilters = {
  [filterType in ProvisionHistoryFilterType]: boolean;
};

export type IDownstreamHistoryFilters = {
  [filterType in DownstreamMessageType]: boolean;
};

interface ReducerState {
  provisionHistoryList: ProvisionHistoryItem[];
  downstreamHistoryList: DownstreamMessageHistoryItem[];
  fetching: boolean;
  error: string;
  provisionHistoryFilters: IProvisionHistoryFilters;
  downstreamHistoryFilters: IDownstreamHistoryFilters;
}

enum ActionTypes {
  FETCH_ERROR = 'device-history/fetch-error',
  FETCH_START = 'device-history/fetch-start',
  FETCH_PROVISIONING_HISTORY_SUCCESS = 'device-history/fetch-provisioning-history-success',
  FETCH_DOWNSTREAM_MESSAGE_HISTORY_SUCCESS = 'device-history/fetch-downstream-message-history-success',
  TOGGLE_PROVISION_HISTORY_FILTER = 'device-history/toggle-provisioning-history-filter',
  TOGGLE_DOWNSTREAM_MESSAGE_HISTORY_FILTER = 'device-history/toggle-downstream-message-history-filter',
}
/* tslint:disable:no-duplicate-string */
export const getProvisioningHistory = (imei: string) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({ type: ActionTypes.FETCH_START });
  return API.admin
    .getProvisioningHistory(imei, { url: config.admin_api_url })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.FETCH_PROVISIONING_HISTORY_SUCCESS,
        payload: {
          history: data,
        },
      });
    })
    .catch(error => {
      dispatch({ type: ActionTypes.FETCH_ERROR, payload: { error } });
    });
};

export const getDownstreamMessageHistory = (id: string) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({ type: ActionTypes.FETCH_START });
  return API.admin
    .getDownstreamMessageHistory(id)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.FETCH_DOWNSTREAM_MESSAGE_HISTORY_SUCCESS,
        payload: {
          history: data,
        },
      });
    })
    .catch(error => {
      dispatch({ type: ActionTypes.FETCH_ERROR, payload: { error } });
    });
};

export const toggleProvisionHistoryFilter = (
  filterType: ProvisionHistoryFilterType,
) => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionTypes.TOGGLE_PROVISION_HISTORY_FILTER,
    payload: { filterType },
  });
};

export const toggleDownstreamHistoryFilter = (
  filterType: DownstreamMessageType,
) => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionTypes.TOGGLE_DOWNSTREAM_MESSAGE_HISTORY_FILTER,
    payload: { filterType },
  });
};

export const initialState: ReducerState = {
  provisionHistoryList: [],
  downstreamHistoryList: [],
  fetching: false,
  error: '',
  provisionHistoryFilters: {
    provision: true,
    deprovision: true,
    recycle: true,
    rework: true,
  },
  downstreamHistoryFilters: {
    sync: true,
    reboot: true,
    restart: true,
    restart_system_app: true,
    request_original_video: true,
    upload_sensor_data: true,
    upload_log_file: true,
    restart_super_brick: true,
    cycle_power_super_brick: true,
    set_super_brick_config: true,
    configuration_update: true,
    clear_upload_queue: true,
    request_configuration: true,
    factory_reset: true,
    send_internal_snapshots: true,
    run_teamviewer_registration: true,
    reformat_sdcard: true,
    schedule_wakeup: true,
    start_research_mjpeg: true,
    'tailgating-calibration': true,
    'send-calibration-snapshots': true,
    'run-csd-tests': true,
    'set-system-properties': true,
    'get-system-properties': true,
    'reboot-ssc': true,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_START:
      return {
        ...state,
        fetching: true,
        error: '',
      };

    case ActionTypes.FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error:
          payload.error instanceof Error
            ? payload.error.message
            : payload.error,
      };

    case ActionTypes.FETCH_PROVISIONING_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        provisionHistoryList: [...payload.history],
      };

    case ActionTypes.FETCH_DOWNSTREAM_MESSAGE_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        downstreamHistoryList: [...payload.history],
      };

    case ActionTypes.TOGGLE_PROVISION_HISTORY_FILTER:
      return {
        ...state,
        provisionHistoryFilters: {
          ...state.provisionHistoryFilters,
          [payload.filterType]: !state.provisionHistoryFilters[
            payload.filterType
          ],
        },
      };

    case ActionTypes.TOGGLE_DOWNSTREAM_MESSAGE_HISTORY_FILTER:
      return {
        ...state,
        downstreamHistoryFilters: {
          ...state.downstreamHistoryFilters,
          [payload.filterType]: !state.downstreamHistoryFilters[
            payload.filterType
          ],
        },
      };

    default:
      return state;
  }
};
