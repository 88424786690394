import React from 'react';
import styled from 'styled-components';
import { colors, textSizesPx } from '@nauto/core';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const NewTag = styled.div`
  border-radius: 4px;
  color: ${colors.white};
  background-color: ${colors.blue400};
  font-size: ${textSizesPx.xxs};
  margin-left: 8px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  max-height: 16px;
`;

interface Props {
  children: React.ReactNode;
}

const FilterCheckboxWrapper: React.FC<Props> = ({ children }) => {
  const [t] = useTranslation();
  return (
    <Container>
      {children} <NewTag>{t('NEW')}</NewTag>
    </Container>
  );
};

export default FilterCheckboxWrapper;
