export enum FEATURES {
  HOME = 'home',
  DRIVER_INSIGHTS = 'driver_insights',
  VEHICLE_INSIGHTS = 'vehicle_insights',
  TRIPS = 'trips',
  EVENTS = 'events',
  REPORTS = 'reports',
  VEHICLE_MANAGEMENT = 'vehicle_management',
  DRIVER_MANAGEMENT = 'driver_management',
  DRIVER_COACHING = 'driver_coaching',
  SEVERE_EVENTS_ALERTS = 'severe_event_alerts',
  EVENT_TYPES = 'event_types',
  DRIVER_APP = 'driver_app',
  AUDIO_RECORDING = 'audio_recording',
  BLURRING = 'blurring',
  PANIC_NOTIFICATIONS = 'panic_notifications',
  CVR_NOTIFICATIONS = 'cvr_notifications',
  DEVICE_HEALTH = 'device_health',
  CREATED_ON = 'created_on',
  DISABLE_INTERIOR_VIDEO = 'disable_interior_video',
  ON_DEMAND_VIDEO_REQUEST_SUB = 'on_demand_video_sub',
  ON_DEMAND_VIDEO_ALACARTE = 'on_demand_video_alacarte',
  PCA_REAR_END_COLLISION = 'PCA_rear_end_collision',
  SELF_GUIDED_COACHING = 'self_guided_coaching',
  DRIVER_INITIATED_VIDEO = 'driver_initiated_video',
  INCIDENT_REPORTING = 'incident_reporting',
  MARK_BUTTON = 'mark_button',
  CONTRACTS = 'contracts',
}

export const DEFAULT_DATA = {
  [FEATURES.HOME]: true,
  [FEATURES.DRIVER_INSIGHTS]: true,
  [FEATURES.VEHICLE_INSIGHTS]: true,
  [FEATURES.TRIPS]: true,
  [FEATURES.EVENTS]: true,
  [FEATURES.REPORTS]: true,
  [FEATURES.VEHICLE_MANAGEMENT]: true,
  [FEATURES.DRIVER_MANAGEMENT]: true,
  [FEATURES.DRIVER_COACHING]: true,
  [FEATURES.SEVERE_EVENTS_ALERTS]: true,
  [FEATURES.EVENT_TYPES]: [],
  [FEATURES.DRIVER_APP]: true,
  [FEATURES.AUDIO_RECORDING]: true,
  [FEATURES.BLURRING]: true,
  [FEATURES.PANIC_NOTIFICATIONS]: true,
  [FEATURES.CVR_NOTIFICATIONS]: true,
  [FEATURES.DEVICE_HEALTH]: true,
  [FEATURES.CREATED_ON]: true,
  [FEATURES.DISABLE_INTERIOR_VIDEO]: false,
  [FEATURES.CONTRACTS]: false,
};

// What events do Add-ons bring
export const ADD_ON_EVENTS = {
  [FEATURES.PCA_REAR_END_COLLISION]: ['FCW'],
  [FEATURES.DRIVER_INITIATED_VIDEO]: ['mark'],
  [FEATURES.INCIDENT_REPORTING]: ['collision'],
  [FEATURES.ON_DEMAND_VIDEO_ALACARTE]: ['request'],
  [FEATURES.ON_DEMAND_VIDEO_REQUEST_SUB]: ['request'],
  [FEATURES.MARK_BUTTON]: ['mark'],
};

// What features do addon's bring
export const ADD_ON_FEATURES = {
  [FEATURES.SELF_GUIDED_COACHING]: [FEATURES.DRIVER_APP],
  [FEATURES.DRIVER_INITIATED_VIDEO]: [FEATURES.PANIC_NOTIFICATIONS],
  [FEATURES.MARK_BUTTON]: [FEATURES.PANIC_NOTIFICATIONS],
  [FEATURES.ON_DEMAND_VIDEO_ALACARTE]: [
    FEATURES.TRIPS,
    FEATURES.CVR_NOTIFICATIONS,
  ],
  [FEATURES.ON_DEMAND_VIDEO_REQUEST_SUB]: [
    FEATURES.TRIPS,
    FEATURES.CVR_NOTIFICATIONS,
  ],
};

export enum PACKAGE {
  PREVENT_ENTERPRISE = 'prevent_enterprise',
  PREVENT_PRO = 'prevent_pro',
  COACH_LEGACY = 'coach_legacy',
  PREVENT_LEGACY = 'prevent_legacy',
  PLATFORM_3_BUNDLE = 'platform_3_bundle',
  MANAGER_LED_COACHING = 'manager_led_coaching',
  DRIVER_BEHAVIOR_ALERTS = 'driver_behavior_alerts',
  ESSENTIAL = 'nauto_essentials',
  NAUTO_PREMIUM = 'nauto_premium',
  VEDR_PD = 'vedr_pd',
  VEDR_LH = 'vedr_lh',
}
