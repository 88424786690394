import { createSelector } from 'reselect';
import {
  SET_ACTIVE_DRIVER,
  clearActiveDriver,
  activeDriverSelector,
  alphabeticalDriverListSelector,
} from 'components/organization/drivers/drivers.redux';
import {
  SET_ACTIVE_VEHICLE,
  clearActiveVehicle,
  selectActiveVehicle,
  alphabeticalVehicleListSelector,
} from 'components/organization/vehicles/vehicles.redux';
import { EntityType, SubFleetsEntityType } from './entity-type-utils';
import { packageFeatures } from 'components/feature/utils';

// Constants
export const SET_ENTITY_TYPE = 'entityType/setEntityType';

// Selector
export const selectActiveEntityType = state => state.activeEntityType;

export const selectActiveEntityId = createSelector(
  ({ activeEntityType }) => activeEntityType,
  ({ drivers }) => drivers.active,
  ({ vehicles }) => vehicles.active,
  (activeEntityType: EntityType, driverId, vehicleId) =>
    activeEntityType === EntityType.DRIVER ? driverId : vehicleId,
);

export const selectActiveEntity = createSelector<any, any, any, any, any>(
  ({ activeEntityType }) => activeEntityType,
  activeDriverSelector,
  selectActiveVehicle,
  (activeEntityType: EntityType, driver, vehicle) => {
    return activeEntityType === EntityType.DRIVER ? driver : vehicle;
  },
);

// returns either the driver or vehicle list depending on what the current activeEntityTypeIs
export const entityListSelector = createSelector(
  ({ activeEntityType }) => activeEntityType,
  alphabeticalDriverListSelector,
  alphabeticalVehicleListSelector,
  (activeEntityType: EntityType, driverList, vehicleList) =>
    activeEntityType === EntityType.DRIVER ? driverList : vehicleList,
);

// Actions
export const clearActiveEntity = () => dispatch => {
  // reset any activeEntity if the type is changed
  dispatch(clearActiveDriver());
  dispatch(clearActiveVehicle());
};

export const setEntityType = (type: EntityType | SubFleetsEntityType) => (
  dispatch,
  getState,
) => {
  // reset any activeEntity if the type is changed
  const { activeEntityType, fleetSettings } = getState();

  const offering = fleetSettings.fleet && fleetSettings.fleet.fleet_offering;

  let allowedType = type;
  if (offering && !packageFeatures(offering.package).isTypeAllowed(type)) {
    allowedType = EntityType.VEHICLE;
  }
  if (allowedType !== activeEntityType) {
    dispatch(clearActiveEntity());
    dispatch({ type: SET_ENTITY_TYPE, payload: allowedType });
  }
  return null;
};

// Reducers
export const initialState: EntityType = EntityType.VEHICLE;

export default (
  state = initialState,
  { type, payload },
): EntityType | SubFleetsEntityType => {
  switch (type) {
    case SET_ENTITY_TYPE:
      return payload;
    // if an active driver or vehicle or group is set,
    // ensure that the active entity type matches
    case SET_ACTIVE_VEHICLE:
      return EntityType.VEHICLE;
    case SET_ACTIVE_DRIVER:
      return EntityType.DRIVER;
    default:
      return state;
  }
};
