import { applyMiddleware, createStore, Store, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import {
  syncCurrentFleet,
  syncCurrentGroup,
  syncDrivers,
  syncFleets,
  syncGroups,
  syncJwt,
  syncUser,
  syncVehicles,
  syncZendeskCookie,
  syncCurrentEntity,
  syncUserCookie,
  syncGlobalRoles,
} from 'components/auth/auth.middleware';

declare let module: any;

export function storeConfig(): Store<any> {
  let store: Store<any>;

  const middleware = [
    syncCurrentFleet,
    syncCurrentGroup,
    syncCurrentEntity,
    syncDrivers,
    syncFleets,
    syncGroups,
    syncJwt,
    syncUser,
    syncVehicles,
    syncZendeskCookie,
    syncUserCookie,
    syncGlobalRoles,
    thunk,
  ];

  if (process.env.NODE_ENV === 'production') {
    store = createStore(reducers, applyMiddleware(...middleware));
  } else {
    const composeEnhancers =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    store = createStore(
      reducers,
      composeEnhancers(applyMiddleware(...middleware)),
    );
  }

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
