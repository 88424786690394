import config from 'utils/config';
import { cacheBustUrl, path } from 'utils/helpers';
import { fetchRequest } from 'utils/requests';
import { SuperfleetReportData } from './reports/types';
import { Moment } from 'moment';
import { createSelector } from 'reselect';
import { DurationType, ReportRowData } from 'components/reports/types';
import { cleanPeriodData } from 'components/reports/reports.redux';
import {
  currentWeekData,
  getVeraScore,
  isValidScore,
  isAtRisk,
  isTopPerformer,
} from 'utils/report-utils';
import { FleetProps, FleetAccess } from 'models/db';
import {
  atRiskVeraThresholdSelector,
  currentFleetSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
} from 'components/auth/auth.reducer';
import { getServiceUrl } from 'utils/localstorage';
import { isSuperfleet } from 'components/fleets/utils';
import { ScoreVersion, VeraVersionType } from 'utils/vera-score-utils';
import { EventType } from 'components/events/event-types';
import * as API from '@nauto/api';
import { min } from 'lodash';

enum ActionTypes {
  FETCH_SUPERFLEETS_START = 'superfleet/FETCH_SUPERFLEETS_START',
  FETCH_SUPERFLEETS_SUCCESS = 'superfleet/FETCH_SUPERFLEETS_SUCCESS',
  FETCH_SUPERFLEETS_ERROR = 'superfleet/FETCH_SUPERFLEETS_ERROR',
  FETCH_REPORT_START = 'superfleet/FETCH_SUPERFLEETS_REPORT_START',
  FETCH_REPORT_SUCCESS = 'superfleet/FETCH_SUPERFLEETS_REPORT_SUCCESS',
  FETCH_REPORT_ERROR = 'superfleet/FETCH_SUPERFLEETS_REPORT_ERROR',
  CLEAR_REPORT = 'superfleet/CLEAR_REPORT',
  FETCH_DETAILS_START = 'superfleet/FETCH_SUPERFLEETS_DETAILS_START',
  FETCH_DETAILS_SUCCESS = 'superfleet/FETCH_SUPERFLEETS_DETAILS_SUCCESS',
  FETCH_DETAILS_ERROR = 'superfleet/FETCH_SUPERFLEETS_DETAILS_ERROR',
}

export const clearReport = () => (dispatch: (any) => void) =>
  dispatch({ type: ActionTypes.CLEAR_REPORT });

export const fetchSuperfleets = () => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: ActionTypes.FETCH_SUPERFLEETS_START });

  return API.superfleet
    .fetchSuperfleets({ url: config.admin_api_url })
    .then(response => {
      dispatch({
        type: ActionTypes.FETCH_SUPERFLEETS_SUCCESS,
        payload: { superfleets: response.data },
      });
    })
    .catch(error => {
      dispatch({
        type: ActionTypes.FETCH_SUPERFLEETS_ERROR,
        payload: { error },
      });
    });
};

interface ReportParams {
  superfleet: string;
  range: { min: Moment; max: Moment };
  type?: DurationType;
  returnData?: boolean;
  isScoreVersionVera3?: boolean;
  isAggregateScore?: boolean;
}
export const fetchSuperfleetReport = ({
  superfleet,
  range,
  type,
  returnData,
  isScoreVersionVera3,
  isAggregateScore = false,
}: ReportParams) => (dispatch: (any) => void) => {
  dispatch({ type: ActionTypes.FETCH_REPORT_START });

  return API.superfleet
    .fetchSuperfleetReport({
      superfleetId: superfleet,
      range: { min: range.min.valueOf(), max: range.max.valueOf() },
      type,
      isScoreVersionVera3,
      isAggregateScore,
    })
    .then(response => {
      if (returnData) {
        return response.data[0];
      }
      dispatch({
        type: ActionTypes.FETCH_REPORT_SUCCESS,
        payload: { report: response.data[0] },
      });
    })
    .catch(error => {
      dispatch({
        type: ActionTypes.FETCH_REPORT_ERROR,
        payload: { error },
      });
    });
};

export const fetchSuperfleetDetails = (superfleet: string) => (
  dispatch: (any) => void,
): Promise<void> => {
  dispatch({ type: ActionTypes.FETCH_DETAILS_START });

  return API.superfleet
    .fetchSuperfleetDetails({ superfleet })
    .then(response => {
      dispatch({
        type: ActionTypes.FETCH_DETAILS_SUCCESS,
        payload: { details: response.data[0] },
      });
    })
    .catch(error => {
      dispatch({
        type: ActionTypes.FETCH_DETAILS_ERROR,
        payload: { error },
      });
    });
};

export const fetchingSuperfleetReportSelector = ({
  superfleets,
}: SuperfleetsRedux): boolean =>
  !!superfleets.fetchingReport &&
  !!superfleets.superfleetDetails.children.length;

export const superfleetsReportSelector = ({
  superfleets,
}: SuperfleetsRedux): SuperfleetReportData => superfleets.report;

export const childFleetsSelector = ({
  superfleets,
}: SuperfleetsRedux): FleetProps[] => superfleets.superfleetDetails.children;

export const indexedChildFleetsSelector = createSelector(
  childFleetsSelector,
  (fleets: FleetProps[]): { [id: string]: FleetProps } =>
    fleets.reduce((obj, fleet) => ({ ...obj, [fleet.id]: fleet }), {}),
);

export const userSuperfleetsSelector = ({ user: { fleets } }): FleetAccess[] =>
  fleets.filter(({ fleet }) => isSuperfleet(fleet));

export const currentFleetIsSuperfleetSelector = createSelector(
  currentFleetSelector,
  isSuperfleet,
);

export const currentSuperfleetSelector = createSelector(
  currentFleetSelector,
  userSuperfleetsSelector,
  (currentFleet: FleetProps, userSuperfleets: FleetAccess[]): FleetProps => {
    if (isSuperfleet(currentFleet)) {
      return currentFleet;
    }
    const superfleetsContainingCurrentFleet =
      (currentFleet && currentFleet.super_fleets) || [];

    return userSuperfleets
      .map(({ fleet }) => fleet)
      .find(({ id }) => superfleetsContainingCurrentFleet.includes(id));
  },
);

export const fleetsReportSelector = createSelector(
  superfleetsReportSelector,
  indexedChildFleetsSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    report: SuperfleetReportData,
    indexedFleets: { [id: string]: FleetProps },
    collisionEventType: EventType,
    veraVersionType: VeraVersionType,
  ): ReportRowData[] => {
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    const speedingType = isScoreVersionVera3 ? 'posted-speeding' : 'speeding';
    const fleetIds = Object.keys(report.child_fleet_score);
    return fleetIds.map(id => {
      const fleetData = report.child_fleet_score[id];
      return {
        ...fleetData,
        id,
        entity: indexedFleets[id],
        weekly_score: fleetData.weekly_score.map(d => ({
          ...d,
          ...cleanPeriodData(d, collisionEventType, speedingType),
        })),
      };
    });
  },
);

export const scoredFleetsSelector = createSelector(
  fleetsReportSelector,
  (fleets: ReportRowData[]): ReportRowData[] =>
    fleets.filter(fleet => isValidScore(getVeraScore(currentWeekData(fleet)))),
);

export const atRiskFleetsSelector = createSelector(
  scoredFleetsSelector,
  atRiskVeraThresholdSelector,
  (fleets: ReportRowData[], atRiskVeraThreshold: number): ReportRowData[] =>
    fleets.filter(fleet =>
      isAtRisk(currentWeekData(fleet), atRiskVeraThreshold),
    ),
);

export const topPerformerFleetsSelector = createSelector(
  scoredFleetsSelector,
  (fleets: ReportRowData[]): ReportRowData[] =>
    fleets.filter(row => isTopPerformer(currentWeekData(row))),
);

export const superfleetsSelector = ({
  superfleets,
}: SuperfleetsRedux): SuperfleetsReportAPI[] => superfleets.allSuperfleets;

export const fetchingSuperfleetsSelector = ({
  superfleets,
}: SuperfleetsRedux): boolean => superfleets.fetchingAllSuperfleets;

export const superfleetsErrorSelector = ({
  superfleets,
}: SuperfleetsRedux): string => superfleets.error;

interface SuperfleetsReducer {
  allSuperfleets: SuperfleetReportData;
  fetchingAllSuperfleets: boolean;
  // eslint-disable-next-line
  report: {};
  fetchingReport: boolean;
  fetchingDetails: boolean;
  superfleetDetails: {
    name: string;
    id: string;
    children: FleetProps[];
  };
  error: string;
}

interface SuperfleetsRedux {
  superfleets: SuperfleetsReducer;
}

export const defaultState: SuperfleetsReducer = {
  allSuperfleets: [],
  fetchingAllSuperfleets: false,
  fetchingReport: false,
  report: { child_fleet_score: {} },
  fetchingDetails: false,
  superfleetDetails: {
    id: undefined,
    name: undefined,
    children: [],
  },
  error: '',
};

export default (
  state: SuperfleetsReducer = defaultState,
  { type, payload },
): SuperfleetsReducer => {
  switch (type) {
    case ActionTypes.FETCH_SUPERFLEETS_START:
      return {
        ...state,
        fetchingAllSuperfleets: true,
      };
    case ActionTypes.FETCH_SUPERFLEETS_SUCCESS:
      return {
        ...state,
        allSuperfleets: payload.superfleets,
        fetchingAllSuperfleets: false,
      };
    case ActionTypes.FETCH_SUPERFLEETS_ERROR:
      return {
        ...state,
        fetchingAllSuperfleets: false,
        error: payload.error,
      };
    case ActionTypes.FETCH_REPORT_START:
      return {
        ...state,
        fetchingReport: true,
      };
    case ActionTypes.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        report: payload.report,
        fetchingReport: false,
      };
    case ActionTypes.FETCH_REPORT_ERROR:
      return {
        ...state,
        fetchingReport: false,
        error: payload.error,
      };
    case ActionTypes.CLEAR_REPORT:
      return {
        ...state,
        report: defaultState.report,
      };

    case ActionTypes.FETCH_DETAILS_START:
      return {
        ...state,
        fetchingDetails: true,
      };
    case ActionTypes.FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        superfleetDetails: payload.details,
        fetchingDetails: false,
      };
    case ActionTypes.FETCH_DETAILS_ERROR:
      return {
        ...state,
        fetchingDetails: false,
        error: payload.error,
      };
    default:
      return state;
  }
};
