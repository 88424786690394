import { useQuery, useQueryClient } from 'react-query';
import * as API from '@nauto/api';
import { TEN_MINUTES } from 'hooks/queries/constants';
import useFleetData from 'hooks/use-fleet-data';
import useGroupData from 'hooks/use-group-data';
import { FACE_QUERIES } from 'constants/query-keys';
import { untaggedFacesCutoff } from 'utils/date-ranges';
import { HookParams, PaginatedFacesQuery } from './types';

const transformData = (data: API.PaginatedFacesResp['data']) => ({
  untaggedFacesCount: data.total_count,
  hasMoreUntaggedFaces: data.has_more,
});

type UsePaginatedFaces = (params?: HookParams) => PaginatedFacesQuery;

const usePaginatedFaces: UsePaginatedFaces = (params = {}) => {
  const {
    min = untaggedFacesCutoff().valueOf(),
    limit = 0,
    hasDriverAssociation = false,
    facesCountThreshold = 50,
    enabled = true,
  } = params;
  const queryClient = useQueryClient();
  const { id: fleetId } = useFleetData({ current: true }) || {};
  const { id: groupId } = useGroupData() || {};

  const keys = {
    fleetId,
    groupId,
    min,
    limit,
    hasDriverAssociation,
    facesCountThreshold,
  };

  const fetchPaginatedFaces = async () => {
    const response = await API.faces.getPaginatedFaces(keys);
    return response.data;
  };

  const queryProps = useQuery(
    [FACE_QUERIES.PAGINATED_FACES, keys],
    fetchPaginatedFaces,
    {
      enabled: enabled && !!fleetId && !!groupId,
      staleTime: TEN_MINUTES,
      select: transformData,
    },
  );

  return {
    ...queryProps,
    invalidateQuery: () =>
      queryClient.invalidateQueries(FACE_QUERIES.PAGINATED_FACES),
  };
};

export { usePaginatedFaces };
