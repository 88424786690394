import moment from 'moment';
import * as API from '@nauto/api';
import { formattedWholeNumber } from 'components/fleet-health/utils';
import { trimTimestamp } from './helpers';

const lastMessageDates = {
  lastTwoDays: () => moment().subtract(2, 'day'),
  lastFourDays: () => moment().subtract(4, 'day'),
  lastThirtyDays: () => moment().subtract(30, 'day'),
};

export const getLastMessageTimestamp = (vehicle: API.Vehicle): number => {
  if (!vehicle.last_message_timestamp) return 0;
  return trimTimestamp(vehicle.last_message_timestamp);
};

export const hasValidTimestamp = (v: API.Vehicle): boolean =>
  !!v?.last_message_timestamp;

// online
// no connection status issue OR temporarily offline for less than 2 days (48hrs)
export const isOnline = (v: API.Vehicle): boolean => {
  return moment(getLastMessageTimestamp(v)).isSameOrAfter(
    lastMessageDates.lastTwoDays(),
  );
};

// temporarily offline
// last message received between 2-4 days
export const isTemporarilyOffline = (v: API.Vehicle): boolean => {
  return moment(getLastMessageTimestamp(v)).isBetween(
    lastMessageDates.lastFourDays(),
    lastMessageDates.lastTwoDays(),
    undefined,
    '[]',
  );
};

// offline
// last message received 4-30 days
export const isOffline = (v: API.Vehicle): boolean => {
  return moment(getLastMessageTimestamp(v)).isBetween(
    lastMessageDates.lastThirtyDays(),
    lastMessageDates.lastFourDays(),
    undefined,
    '[]',
  );
};

// prolonged offline
// last message received >30days
export const isProlongedOffline = (v: API.Vehicle): boolean => {
  return moment(getLastMessageTimestamp(v)).isBefore(
    lastMessageDates.lastThirtyDays(),
  );
};

// Bad Install
// devices that have never sent a message
export const isBadInstall = (v: API.Vehicle): boolean => {
  if (!v.last_message_timestamp) return true;
  return false;
};

export const hasVehicle = (v: API.Vehicle): boolean => {
  /**
   * device-monitoring sets a backup value of vehicle.device.id
   * to vehicle.id if vehicle.id === ""
   *
   * vehicle.id === "" occurs when there is no device/vehicle
   * association
   */
  if (!v.id.startsWith('v-')) return false;
  return true;
};

export const getDaysSinceLastMessageTimestamp = (v: API.Vehicle): number => {
  if (!v.last_message_timestamp) {
    return 0;
  }
  return moment().diff(moment(getLastMessageTimestamp(v)), 'day');
};

export const buildInputLabel = (
  filters: string[] = [],
  translations: Record<string, string> = {},
): string => {
  if (!filters.length) return '';

  const trimmed = filters.slice(0, 2);
  const labels = trimmed.map(value => translations[value] || value).join(', ');

  if (filters.length <= 2) return labels;

  return `${labels} +${filters.length - 2}`;
};

export const formatRoundNumber = (v: number | string): string => {
  const numVal = typeof v === 'string' ? parseInt(v) : v;
  return numVal > 0 && numVal < 1 ? '<1%' : `${Math.round(numVal)}%`;
};

export const formatIssueCount = (count: number, total: number) => {
  if (isNaN(count) || isNaN(total)) {
    return '-';
  }

  const formatted = formattedWholeNumber((count / total) * 100);
  if (!formatted) return '-';

  return `${formattedWholeNumber((count / total) * 100)}%`;
};
