import React from 'react';
import { connect } from 'react-redux';
import { Flags } from 'components/feature-flags/flags';

const withFeatureFlags = Wrapped => {
  class WithFeatureFlags extends React.Component<any, any> {
    render() {
      const { featureFlags } = this.props;
      return <Wrapped {...this.props} featureFlags={featureFlags} />;
    }
  }

  const selector = ({ featureFlags }) => ({ featureFlags });

  return connect(selector)(WithFeatureFlags);
};

export interface FeatureFlagsProps {
  featureFlags?: Flags;
}

export default withFeatureFlags;
