import React, { FC } from 'react';
import { connect } from 'react-redux';
import UserDataContext from './context';
import { User } from 'models/db';

interface Props {
  user: User;
}

const UserDataProvider: FC<Props> = ({ children, user }) => {
  return (
    <UserDataContext.Provider value={user}>{children}</UserDataContext.Provider>
  );
};

const selector = ({ user: { user } }) => ({ user });

export default connect(selector)(UserDataProvider);
