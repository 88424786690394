export enum SpeedUnit {
  MPH = 'mph',
  KPH = 'km/h',
  KPHS = 'kph',
}

export interface FleetSettingProperties {
  speeding_setting: {
    value: number;
    unit: SpeedUnit;
  };
  iva?: {
    speeding: boolean;
  };
  enable_driver_ranking?: boolean;
}

export interface Settings {
  properties: FleetSettingProperties;
  business_country: string;
}

export interface APIResponse {
  data: Settings;
  success: boolean;
}

export const noSettings = 0;
export const maxSpeed = 200;

export const defaultSettings: Settings = {
  properties: {
    speeding_setting: {
      value: noSettings,
      unit: SpeedUnit.MPH,
    },
    iva: {
      speeding: false,
    },
  },
  business_country: 'US',
};
