import * as API from '@nauto/api';
import { validScoreSelector } from 'components/reports/reports.redux';
import {
  DurationType,
  ReportRowAPIData,
  ReportRowData,
} from 'components/reports/types';
import useFleetData from 'hooks/use-fleet-data';
import useGroupData from 'hooks/use-group-data';
import useVeraScoreToggle from 'hooks/use-vera-score-type-toggle';
import { Dispatch, SetStateAction, useState } from 'react';
import { Range } from 'utils/date-ranges';
import {
  currentWeekData,
  isAtRisk,
  isSignificantlyDeclined,
  isSignificantlyImproved,
  isTopPerformer,
  isValidScore,
  getVeraScore,
} from 'utils/report-utils';
import { ScoreVersion } from 'utils/vera-score-utils';
import { GridReportEntityType } from 'components/entity-type/entity-type-utils';
import { useReportQuery } from 'hooks/use-reports';

export interface Params {
  /**
   * allows overvide of active group
   */
  groupId?: string;
  entity: API.reports.EntityType | GridReportEntityType;
  range: Range;
  duration: DurationType;
  scoreOnly?: boolean;
  /**
   * fetch report by entity (driver | vehicle)
   */
  id?: string;
}

export const atRiskDriversReport = (
  data: ReportRowAPIData[] | ReportRowData[],
  atRiskVeraThreshold: number,
) =>
  validScoreSelector(data as ReportRowData[]).filter((row: ReportRowData) =>
    isAtRisk(currentWeekData(row), atRiskVeraThreshold),
  );

export const topPerformingDriversReport = (
  data: ReportRowAPIData[] | ReportRowData[],
) => data.filter((row: ReportRowData) => isTopPerformer(currentWeekData(row)));

export const significantlyImprovedDriversReport = (
  data: ReportRowAPIData[] | ReportRowData[],
) => data.filter((row: ReportRowData) => isSignificantlyImproved(row));

export const significantlyDeclinedDriversReport = (
  data: ReportRowAPIData[] | ReportRowData[],
) => data.filter((row: ReportRowData) => isSignificantlyDeclined(row));

// Bucket in one pass
export const bucketDriversReportData = (
  data: ReportRowAPIData[] | ReportRowData[],
  atRiskVeraThreshold: number,
): {
  atRiskDrivers: ReportRowAPIData[] | ReportRowData[];
  topPerformingDrivers: ReportRowAPIData[] | ReportRowData[];
  significantlyImprovedDrivers: ReportRowAPIData[] | ReportRowData[];
  significantlyDeclinedDrivers: ReportRowAPIData[] | ReportRowData[];
} => {
  const atRiskDrivers = [];
  const topPerformingDrivers = [];
  const significantlyImprovedDrivers = [];
  const significantlyDeclinedDrivers = [];

  data.forEach(row => {
    if (
      isValidScore(getVeraScore(currentWeekData(row))) &&
      isAtRisk(currentWeekData(row), atRiskVeraThreshold)
    ) {
      atRiskDrivers.push(row);
    }

    if (isTopPerformer(currentWeekData(row))) {
      topPerformingDrivers.push(row);
    }

    if (isSignificantlyImproved(row)) {
      significantlyImprovedDrivers.push(row);
    }

    if (isSignificantlyDeclined(row)) {
      significantlyDeclinedDrivers.push(row);
    }
  });

  return {
    atRiskDrivers,
    topPerformingDrivers,
    significantlyImprovedDrivers,
    significantlyDeclinedDrivers,
  };
};

interface ReportDataQueryPayload {
  data: ReportRowAPIData[];
  isLoading: boolean;
  isError: boolean;
}

type ReportDataQuery = (enabled?: boolean) => ReportDataQueryPayload;

interface UseReportDataPayload {
  refetchReportData: Dispatch<SetStateAction<Params>>;
  reportDataQuery: ReportDataQuery;
}

/**
 *
 * DO NOT USE: LEGACY hook, please use use-reports hook instead or add/extend
 * the appropriate queries for your use case in: 'hooks/use-reports'
 */
const useReportDataQuery = (initialParams: Params): UseReportDataPayload => {
  const [params, setParams] = useState<Params>(initialParams);
  const { isScoreVersionVera3 } = useVeraScoreToggle();
  const version = isScoreVersionVera3 ? ScoreVersion.VERA3 : null;
  const { id: fleetId } = useFleetData({ current: true });
  const { id: backupGroupId } = useGroupData();
  const { entity, duration, range, scoreOnly = false, id, groupId } = params;

  const queryParams = {
    fleetId,
    groupId: groupId || backupGroupId,
    entity,
    version,
    term: duration,
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    scoreOnly,
    id,
  };

  const useReportData = (enabled = true): ReportDataQueryPayload => {
    const { data = [], isLoading, status } = useReportQuery(queryParams, {
      enabled,
    });

    return {
      data,
      isLoading,
      isError: status === 'error',
    };
  };

  return {
    refetchReportData: setParams,
    reportDataQuery: useReportData,
  };
};

export default useReportDataQuery;
