import { Dispatch, Action } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fetchRequest } from 'utils/requests';
import config from 'utils/config';
import { path } from 'utils/helpers';
import * as API from '@nauto/api';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export enum ActionTypes {
  SET_SEARCH_CONTEXT = 'global-search/set-search-context',
  SET_SEARCH_TERM = 'global-search/set-search-term',
  START_SEARCH = 'global-search/start-search',
  SEARCH_COMPLETE = 'global-search/search-complete',
  SEARCH_ERROR = 'global-search/search-error',
}

/**
 * ----------------------------------------------------------------------------
 * Selectors
 * ----------------------------------------------------------------------------
 */
export const searchResultsSelector = ({ globalSearch }) =>
  globalSearch.searchResults;

export const globalSearchSelector = createStructuredSelector({
  activeContext: ({ globalSearch }) => globalSearch.activeContext,
  searchTerm: ({ globalSearch }) => globalSearch.searchTerm,
  isFetching: ({ globalSearch }) => globalSearch.isFetching,
  searchError: ({ globalSearch }) => globalSearch.searchError,
  searchResults: searchResultsSelector,
});

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

export const setSearchContext = (activeContext: string) => dispatch =>
  Promise.resolve().then(() => {
    return dispatch({
      type: ActionTypes.SET_SEARCH_CONTEXT,
      payload: { activeContext },
    });
  });

export const setSearchTerm = (searchTerm: string) => dispatch =>
  Promise.resolve().then(() => {
    return dispatch({
      type: ActionTypes.SET_SEARCH_TERM,
      payload: { searchTerm },
    });
  });

export const getSearchResults = (search?: string, context?: string) => (
  dispatch: Dispatch<Action>,
  getState,
) => {
  const {
    globalSearch: { searchTerm, activeContext },
  } = getState();
  /* prettier-ignore */

  const params = {
    s: search || searchTerm,
    scope: context || activeContext,
  };

  dispatch({
    type: ActionTypes.START_SEARCH,
  });
  return API.admin
    .getSearchResults(params, { url: config.admin_api_url })
    .then(res => {
      const {
        globalSearch: { searchTerm: currentSearchTerm },
      } = getState();

      if (searchTerm !== currentSearchTerm) {
        return;
      }

      if (res.success) {
        return dispatch({
          type: ActionTypes.SEARCH_COMPLETE,
          payload: {
            data: res.data,
            searchTerm,
          },
        });
      } else {
        return dispatch({
          type: ActionTypes.SEARCH_ERROR,
          payload: {
            error: res,
          },
        });
      }
    })
    .catch(error => {
      return dispatch({
        type: ActionTypes.SEARCH_COMPLETE,
        payload: {
          error,
        },
      });
    });
};

/**
 * ----------------------------------------------------------------------------
 * Reducer
 * ----------------------------------------------------------------------------
 */

export interface GlobalSearch {
  activeContext: string;
  searchTerm: string;
  isFetching?: boolean;
  searchError?: boolean;
  searchResults?: any[];
}

export const defaultState: GlobalSearch = {
  searchTerm: '',
  activeContext: 'fleet',
  isFetching: false,
  searchResults: null,
  searchError: false,
};

export default (state: GlobalSearch = defaultState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_SEARCH_CONTEXT: {
      return {
        ...state,
        activeContext: payload.activeContext,
        isFetching: false,
        searchResults: null,
      };
    }
    case ActionTypes.SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: payload.searchTerm,
        isFetching: false,
        searchResults: null,
      };
    }
    case ActionTypes.START_SEARCH: {
      return {
        ...state,
        isFetching: true,
        searchResults: null,
        searchError: false,
      };
    }
    case ActionTypes.SEARCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        searchResults: null,
        searchError: payload.error,
      };
    }
    case ActionTypes.SEARCH_COMPLETE: {
      return {
        ...state,
        isFetching: false,
        searchError: false,
        searchResults: payload.data,
      };
    }
    default:
      return state;
  }
};
