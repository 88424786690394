import { NotificationProps } from 'models/db';
import { getFleetId, getCachedFleetId } from 'utils/localstorage';
import { createSelector } from 'reselect';
import { TYPE } from 'constants/events';
import { untaggedFacesCountSelector } from 'components/groups/groups.redux';
import { unhealthyCountSelector } from 'components/organization/vehicles/vehicles.redux';
import { fleetLink } from 'components/fleet-link';
import { ROUTES } from 'routes/constants';
import { featureFlagsSelector } from 'components/feature-flags/flag-selectors';
import * as API from '@nauto/api';

const EVENT_TYPE = TYPE;

export const NOTIFICATION_TYPE = {
  VERIFYING_CUSTOM_VIDEO_REQUEST: 'verifying-custom-video-request',
  UPLOADING_CUSTOM_VIDEO_REQUEST: 'custom-video-request',
  UPLOADED_CUSTOM_VIDEO_REQUEST: 'uploaded-custom-video-request',
  MARK: 'mark',
  MARK_PANIC: 'uploaded-mark-panic',
  TAG: 'tag',
  COLLISION: 'collision',
  BATCH_JOB: 'batch-job',
};

const EVENT_TYPES = [
  NOTIFICATION_TYPE.MARK,
  NOTIFICATION_TYPE.MARK_PANIC,
  NOTIFICATION_TYPE.TAG,
  NOTIFICATION_TYPE.COLLISION,
];

// The event type cooresponding to the notification type
const TYPE_EVENT_MAP = {
  [NOTIFICATION_TYPE.UPLOADED_CUSTOM_VIDEO_REQUEST]: EVENT_TYPE.REQUEST,
  [NOTIFICATION_TYPE.MARK]: EVENT_TYPE.MARK,
  [NOTIFICATION_TYPE.MARK_PANIC]: EVENT_TYPE.MARK_PANIC,
  [NOTIFICATION_TYPE.COLLISION]: EVENT_TYPE.CONFIRMED_COLLISION_VERA2,
};

const getEventTimestamp = (n: NotificationProps) => {
  return n.properties['message-id'];
};

const sortChronological = (n1: NotificationProps, n2: NotificationProps) =>
  getEventTimestamp(n2).localeCompare(getEventTimestamp(n1));

export const cleanNotifications = (
  notifications: NotificationProps[],
  eventDetails = false,
): NotificationProps[] => {
  return notifications
    .filter(n => !!getEventTimestamp(n))
    .map(n => {
      // the event type that triggered the notification (will be undefined if not an event-based notification)
      const type = TYPE_EVENT_MAP[n.type];
      const hasEventLink =
        n.properties['message-id'] && !n.type.includes('verifying');
      const fleetId = n.properties['fleet-id'];
      const eventId = n.properties['event-id'];
      const pathname = eventDetails ? ROUTES.EVENT_DETAILS : ROUTES.EVENT;

      // build the deeplink to the event
      const eventLink =
        hasEventLink &&
        !!eventId &&
        fleetLink({
          pathname,
          fleetId,
          eventId,
        });
      return { ...n, eventType: type, eventLink };
    })
    .sort(sortChronological);
};

const selectActiveNotifications = state => {
  return cleanNotifications(
    state.notificationsData.notifications,
    state.featureFlags.eventDetailsUi,
  );
};

const selectInactiveNotifications = state => {
  return cleanNotifications(
    state.notificationsData.inactiveNotifications,
    state.featureFlags.eventDetailsUi,
  );
};

export const selectActiveEventNotifications = createSelector(
  selectActiveNotifications,
  notifications => notifications.filter(e => EVENT_TYPES.includes(e.type)),
);

export const selectActiveCollisionNotifications = createSelector(
  selectActiveNotifications,
  notifications =>
    notifications.filter(e => e.type === NOTIFICATION_TYPE.COLLISION),
);

export const selectBatchImportNotifications = createSelector(
  ({ notificationsData }) => notificationsData.notifications,
  notifications =>
    notifications.filter(e => e.type === NOTIFICATION_TYPE.BATCH_JOB),
);

export const selectArchiveEventNotifications = createSelector(
  selectInactiveNotifications,
  notifications => notifications.filter(e => EVENT_TYPES.includes(e.type)),
);

const isUploadedCVR = n =>
  n.type === NOTIFICATION_TYPE.UPLOADED_CUSTOM_VIDEO_REQUEST &&
  n.properties['video-sync'] === 'success';

const isFailedCVR = n =>
  (n.type === NOTIFICATION_TYPE.VERIFYING_CUSTOM_VIDEO_REQUEST &&
    'verified' in n.properties &&
    !n.properties.verified) ||
  (n.type === NOTIFICATION_TYPE.UPLOADED_CUSTOM_VIDEO_REQUEST &&
    n.properties['video-sync'] === 'failure');

const isUploadingCVR = n =>
  n.type === NOTIFICATION_TYPE.UPLOADING_CUSTOM_VIDEO_REQUEST &&
  n.status !== 'inactive';

const isPendingCVR = n =>
  n.type === NOTIFICATION_TYPE.VERIFYING_CUSTOM_VIDEO_REQUEST &&
  !('verified' in n.properties);

export const selectActiveUploadedCVRNotifications = createSelector(
  selectActiveNotifications,
  notifications => notifications.filter(isUploadedCVR),
);

export const selectInactiveUploadedCVRNotifications = createSelector(
  selectInactiveNotifications,
  notifications => notifications.filter(isUploadedCVR),
);

export const selectActiveFailedCVRNotifications = createSelector(
  selectActiveNotifications,
  notifications => notifications.filter(isFailedCVR),
);

export const selectInactiveFailedCVRNotifications = createSelector(
  selectInactiveNotifications,
  notifications => notifications.filter(isFailedCVR),
);

export const selectActivePendingCVRNotifications = createSelector(
  selectActiveNotifications,
  notifications => notifications.filter(isPendingCVR),
);

export const selectActiveUploadingCVRNotifications = createSelector(
  selectActiveNotifications,
  notifications => notifications.filter(isUploadingCVR),
);

export const notificationCountSelector = createSelector(
  selectActiveEventNotifications,
  untaggedFacesCountSelector,
  unhealthyCountSelector,
  featureFlagsSelector,
  (notifications, untaggedFacesCount, unhealthyCount, featureFlags) => {
    const tagFacesIncrement = untaggedFacesCount > 0 ? 1 : 0;
    const unhealthyIncrement = unhealthyCount > 0 ? 1 : 0;
    return notifications.length + tagFacesIncrement + unhealthyIncrement;
  },
);

export const requestsCountSelector = createSelector(
  selectActiveUploadedCVRNotifications,
  selectActiveFailedCVRNotifications,
  selectActivePendingCVRNotifications,
  selectActiveUploadingCVRNotifications,
  (uploaded, failed, pending, uploading) =>
    uploaded.length + failed.length + pending.length + uploading.length || 0,
);

// Notification Actions
export const SET_POLLING_FLEET = 'notifications/set-polling-fleet';
export const FETCHING_NOTIFICATIONS = 'notifications/fetching';
export const NOTIFICATIONS_FETCHED = 'notifications/fetched';
export const NOTIFICATIONS_FETCH_FAILED = 'notifications/fetech-failed';

export const FETCHING_INACTIVE_NOTIFICATIONS =
  'notifications/fetching-inactive';
export const INACTIVE_NOTIFICATIONS_FETCHED = 'notifications/fetched-inactive';
export const INACTIVE_NOTIFICATIONS_FETCH_FAILED =
  'notifications/fetech-inactive-failed';

export const DISMISSING_NOTIFICATION = 'notifications/dismissing-notification';
export const NOTIFICATION_DISMISSED = 'notifications/notification-dismissed';
export const NOTIFICATION_DISMISS_FAILED =
  'notifications/notification-dismiss-failed';

export const UNARCHIVING_NOTIFICATION =
  'notifications/unarchiving-notification';
export const NOTIFICATION_UNARCHIVED = 'notifications/notification-unarchived';
export const UNARCHIVING_NOTIFICATION_FAILED =
  'notifications/notification-unarchiving-failed';

// called when another api, for example custom video request generates a notification
export const ADD_NOTIFICATION = 'notifications/add-notification';
export const TOGGLE_NOTIFICATION_INFO_MODAL =
  'notifications/toggle-notification-info-modal';

export const addNotification = (notification: NotificationProps) => (
  dispatch: (any) => void,
) => dispatch({ type: ADD_NOTIFICATION, payload: { notification } });

/**
 * Get notifications returns fleet notifications
 * @param status
 */
export const pollForNotifications = (status = 'active') => (
  dispatch: (any) => void,
  getState,
) => {
  const pollingFleet = getFleetId();

  // inform redux that this is the fleet we are now polling for
  dispatch({ type: SET_POLLING_FLEET, payload: { pollingFleet } });

  let pollingInterval = null;

  const refreshNotifications = () => {
    // the fleet we have been poling for is no longer the current fleet
    if (pollingFleet !== getState().notificationsData.pollingFleet) {
      clearInterval(pollingInterval);
      return;
    }

    if (!getState().notificationsData.isFetching && document.hasFocus()) {
      dispatch(getActiveNotifications());
    }
  };
  const refreshRateSeconds = 60;

  dispatch(getActiveNotifications());
  pollingInterval = setInterval(
    refreshNotifications,
    refreshRateSeconds * 1000,
  );
};

/**
 * Get notifications returns fleet notifications
 * @param status
 */
export const getActiveNotifications = () => (dispatch: (any) => void) => {
  dispatch({ type: FETCHING_NOTIFICATIONS });
  const fleetId = getFleetId() || getCachedFleetId();

  return API.notifications
    .getNotifications({ fleetId, type: 'active' })
    .then((response: any) => {
      dispatch({
        type: NOTIFICATIONS_FETCHED,
        payload: {
          notifications: response.data,
          fleetId,
        },
      });
    })
    .catch(err => {
      dispatch({
        type: NOTIFICATIONS_FETCH_FAILED,
        payload: { err },
      });
      console.log(err);
    });
};

export const getInactiveNotifications = () => (dispatch: (any) => void) => {
  dispatch({ type: FETCHING_INACTIVE_NOTIFICATIONS });
  const fleetId = getFleetId();

  return API.notifications
    .getNotifications({ fleetId, type: 'inactive' })
    .then((response: any) => {
      dispatch({
        type: INACTIVE_NOTIFICATIONS_FETCHED,
        payload: {
          notifications: response.data,
          fleetId,
        },
      });
    })
    .catch(err => {
      dispatch({
        type: INACTIVE_NOTIFICATIONS_FETCH_FAILED,
        payload: { err },
      });
      console.log(err);
    });
};

export const getAllNotifications = () => (dispatch: (any) => void) => {
  dispatch(getActiveNotifications());
  dispatch(getInactiveNotifications());
};

export const dismissNotification = (notificationID: string) => (
  dispatch: (any) => void,
) => {
  dispatch({
    type: DISMISSING_NOTIFICATION,
    payload: { id: notificationID },
  });

  return API.notifications
    .updateNotification({
      fleetId: getFleetId(),
      notificationId: notificationID,
      status: 'inactive',
    })
    .then((response: any) => {
      dispatch({
        type: NOTIFICATION_DISMISSED,
        payload: { id: notificationID },
      });
    })
    .catch(err => {
      dispatch({
        type: NOTIFICATION_DISMISS_FAILED,
        payload: { err },
      });
      console.log(err);
    });
};

export const unarchiveNotification = (notificationID: string) => (
  dispatch: (any) => void,
) => {
  dispatch({
    type: UNARCHIVING_NOTIFICATION,
    payload: { id: notificationID },
  });

  return API.notifications
    .updateNotification({
      fleetId: getFleetId(),
      notificationId: notificationID,
      status: 'active',
    })
    .then((response: any) => {
      dispatch({
        type: NOTIFICATION_UNARCHIVED,
        payload: { id: notificationID },
      });
    })
    .catch(err => {
      dispatch({
        type: UNARCHIVING_NOTIFICATION_FAILED,
        payload: { err },
      });
      console.log(err);
    });
};

export const toggleInfoModal = (isOpened: boolean) => (
  dispatch: (any) => void,
) => dispatch({ type: TOGGLE_NOTIFICATION_INFO_MODAL, payload: { isOpened } });

interface NotificationsState {
  notifications: Notification[];
  inactiveNotifications: Notification[];
  pollingFleet: string;
  isFetching: boolean;
  isFetchingInactive: boolean;
  isDismissing: any;
  isUnarchiving: any;
  isInfoModalOpened: boolean;
}

export const initialState: NotificationsState = {
  notifications: [],
  inactiveNotifications: [],
  pollingFleet: '',
  isFetching: false,
  isFetchingInactive: false,
  isDismissing: {},
  isUnarchiving: {},
  isInfoModalOpened: false,
};

export default (
  state: NotificationsState = initialState,
  { type, payload },
): NotificationsState => {
  switch (type) {
    case SET_POLLING_FLEET:
      return {
        ...state,
        pollingFleet: payload.pollingFleet,
        isFetching: false,
      };
    case FETCHING_NOTIFICATIONS:
      return {
        ...state,
        isFetching: true,
      };
    case NOTIFICATIONS_FETCHED:
      // response is from a request for a previous fleet
      if (payload.fleetId !== state.pollingFleet) {
        return state;
      }
      return {
        ...state,
        notifications: payload.notifications,
        isFetching: false,
      };
    case NOTIFICATIONS_FETCH_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    case FETCHING_INACTIVE_NOTIFICATIONS:
      return {
        ...state,
        isFetchingInactive: true,
      };
    case INACTIVE_NOTIFICATIONS_FETCHED:
      return {
        ...state,
        inactiveNotifications: payload.notifications,
        isFetchingInactive: false,
      };
    case INACTIVE_NOTIFICATIONS_FETCH_FAILED:
      return {
        ...state,
        isFetchingInactive: false,
      };
    case DISMISSING_NOTIFICATION:
      return {
        ...state,
        isDismissing: {
          ...state.isDismissing,
          [payload.id]: true,
        },
      };
    case NOTIFICATION_DISMISSED:
      return {
        ...state,
        isDismissing: {
          ...state.isDismissing,
          [payload.id]: false,
        },
      };
    case NOTIFICATION_DISMISS_FAILED:
      return {
        ...state,
        isDismissing: false,
      };
    case UNARCHIVING_NOTIFICATION:
      return {
        ...state,
        isUnarchiving: {
          ...state.isUnarchiving,
          [payload.id]: true,
        },
      };
    case NOTIFICATION_UNARCHIVED:
      return {
        ...state,
        isUnarchiving: {
          ...state.isUnarchiving,
          [payload.id]: false,
        },
      };
    case UNARCHIVING_NOTIFICATION_FAILED:
      return {
        ...state,
        isUnarchiving: false,
      };

    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, payload.notification],
      };
    case TOGGLE_NOTIFICATION_INFO_MODAL:
      return {
        ...state,
        isInfoModalOpened: payload.isOpened,
      };
  }
  return state;
};
