import { createStructuredSelector, createSelector } from 'reselect';
import { getFullName } from 'utils/driver-utils';
import {
  InsightsDateFilter,
  InsightsIntervalFilter,
  VeraScoreProps,
  latestScoreAndChange,
  latestScoreSelector,
  latestDefinedInterval,
  dateFilterToParams,
  filtered,
  interpolateUndefinedValues,
  intervalFiltersSelector,
  scoresOverTime,
  sortedByScore,
  vera,
  attention,
  smoothDriving,
  combineVeraScores,
  requestScores,
} from 'redux/insights';
import { activeDriverSelector } from 'components/organization/drivers/drivers.redux';
import { activeGroupScoresSelector } from 'redux/fleet-insights';
import { mpTrack } from 'components/mixpanel';
import { EntityType } from 'components/entity-type/entity-type-utils';
import { isJapaneseFleetSelector } from 'components/auth/auth.reducer';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export enum ActionTypes {
  CLEAR_DATA = 'entity-insights/clear-entity-data',
  FETCHING_ENTITY_SCORES = 'entity-insights/fetching-entity-scores',
  FETCHING_ENTITIES_SCORES = 'entity-insights/fetching-entities-scores',
  ENTITY_SCORES_FETCHED = 'entity-insights/entity-scores-fetched',
  ENTITIES_SCORES_FETCHED = 'entity-insights/entities-scores-fetched',
  OTHER_ENTITIES_SCORES_FETCHED = 'entity-insights/other-entities-scores-fetched',
  ERROR = 'entity-insights/error',
}

// The minimum number of hours driven for a driver to be scored
export const MILLISECONDS_IN_AN_HOUR = 3600000;

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

const filteredEntitiesScore = (
  score: EntityScore,
  filter: InsightsIntervalFilter,
): VeraScoreProps => {
  return score.score.find(s => {
    return s.start_time === filter.start_time && s.end_time === filter.end_time;
  });
};

const undefinedCount = selector =>
  createSelector(
    selector,
    ({ insights }) => insights.scoreDefined,
    (selection: any[], isDefined) =>
      selection.filter(s => !isDefined(s.score)).length,
  );

const latestHoursDrivenSelector = createSelector(
  ({ entityInsights }) => entityInsights.entityScores,
  ({ insights }) => insights.scoreDefined,
  (scores, isDefined) => {
    const latestDurationMs = latestScoreSelector(
      scores,
      s => s.duration,
      isDefined,
    ) as number;
    return latestDurationMs / MILLISECONDS_IN_AN_HOUR;
  },
);

const totalHoursDrivenSelector = createSelector(
  ({ entityInsights }) => entityInsights.entityScores,
  scores => {
    const totalMilliseconds = scores.reduce(
      (sum, period) =>
        // duration of -1 means no driving recorded in data warehouse in that period
        sum + (period.duration === -1 ? 0 : period.duration),
      0,
    );
    return totalMilliseconds / MILLISECONDS_IN_AN_HOUR;
  },
);

const scoreChangeSelector = selector =>
  createSelector(
    ({ entityInsights }) => entityInsights.entityScores,
    ({ insights }) => insights.scoreDefined,
    (scores, isDefined) => latestScoreAndChange(scores, selector, isDefined),
  );

const scoreChangesSelector = createStructuredSelector({
  vera: scoreChangeSelector(vera),
  attention: scoreChangeSelector(attention),
  smoothDriving: scoreChangeSelector(smoothDriving),
});

export const driverSummarySelector = createStructuredSelector({
  driverInsights: ({ entityInsights }) => entityInsights,
  scoreChanges: scoreChangesSelector,
  latestHoursDriven: latestHoursDrivenSelector,
  totalHoursDriven: totalHoursDrivenSelector,
  isFetchingDriverScores: ({ entityInsights }) =>
    entityInsights.isFetchingEntityScores,
});

const entityScoreComparisonSelector = selector =>
  createSelector(
    ({ entityInsights }) => entityInsights.entityScores,
    activeGroupScoresSelector,
    ({ insights }) => insights.scoreDefined,
    (_myScores, _fleetScores, scoreDefined) => {
      const [myScores, fleetScores] = [_myScores, _fleetScores]
        .map(scores => scoresOverTime(scores, selector))
        .map(scores => interpolateUndefinedValues(scores, scoreDefined));

      return { myScores, fleetScores };
    },
  );

const entityScoreComparisonsSelector = createStructuredSelector({
  vera: entityScoreComparisonSelector(vera),
  attention: entityScoreComparisonSelector(attention),
  smoothDriving: entityScoreComparisonSelector(smoothDriving),
});

export const filteredDriverScoreComparisonsSelector = filtered(
  createStructuredSelector({
    scoreComparisons: entityScoreComparisonsSelector,
  }),
);

const entitiesScores = ({ entityInsights }) => entityInsights.entitiesScores;

const filteredEntityScores = selector =>
  createSelector(
    entitiesScores,
    intervalFiltersSelector,
    isJapaneseFleetSelector,
    (scores, intervalFilter, isJapaneseFleet) =>
      scores.map(score => {
        const { driver, vehicle } = score;
        const scoreInInterval = filteredEntitiesScore(score, intervalFilter);
        const driverScore = scoreInInterval ? selector(scoreInInterval) : -1;
        const fullName = driver
          ? getFullName({ driver, isJapaneseFleet })
          : vehicle.name;

        return {
          driverId: driver?.id,
          vehicleId: vehicle?.id,
          name: fullName,
          avatarUrl: driver && driver['driver-snapshot'],
          score: driverScore,
        };
      }),
  );

export const filteredEntityScoresSelector = createStructuredSelector({
  vera: sortedByScore(filteredEntityScores(vera)),
  attention: sortedByScore(filteredEntityScores(attention)),
  smoothDriving: sortedByScore(filteredEntityScores(smoothDriving)),
});

const unscoredEntityCountsSelector = createStructuredSelector({
  vera: undefinedCount(filteredEntityScores(vera)),
  attention: undefinedCount(filteredEntityScores(attention)),
  smoothDriving: undefinedCount(filteredEntityScores(smoothDriving)),
});

export const driverScoreDistributionSelector = filtered(
  createStructuredSelector({
    entityScores: filteredEntityScoresSelector,
    unscoredEntityCounts: unscoredEntityCountsSelector,
    activeEntity: ({ activeEntityType }) => activeEntityType,
  }),
);

export const latestDefinedDriverScoreIntervalSelector = createSelector(
  ({ entityInsights }) => entityInsights.entityScores,
  ({ insights }) => insights.scoreDefined,
  latestDefinedInterval,
);

export const filteredDriverInsightsSelector = filtered(
  createStructuredSelector({
    activeDriver: activeDriverSelector,
    latestDefinedInterval: latestDefinedDriverScoreIntervalSelector,
  }),
);

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */
const handleError = (dispatch: (any) => void, error: any): void => {
  console.error(new Error(error));
  dispatch({ type: ActionTypes.ERROR, payload: { error } });
};

/**
 * Nuke all state! 💥
 */
export const clearEntityData = () => dispatch => {
  dispatch({
    type: ActionTypes.CLEAR_DATA,
  });
};

/**
 * Grab the driver's vera scores - Vera, attention, smooth driver
 */
/* tslint:disable:no-duplicate-string */
export const fetchDriverVeraScores = (
  driverId: string,
  dateFilter: InsightsDateFilter,
  vera2Cutover: string,
) => dispatch => {
  dispatch({ type: ActionTypes.FETCHING_ENTITY_SCORES });
  const { interval, startMs, endMs } = dateFilterToParams(dateFilter);
  const params = {
    no_holes: true,
    type: interval,
    min: startMs,
    max: endMs,
  };

  const requests = requestScores(`/drivers/${driverId}`, params, vera2Cutover);

  return requests
    .then(([response1, response2]) => {
      mpTrack('Fetch Entity VERA Score', { success: true });

      const vera1Scores = response1.data.length ? response1.data : [];
      const { weekly_score, monthly_score } = response2.data[0];
      const scores = [...vera1Scores, ...weekly_score, ...monthly_score];

      dispatch({
        type: ActionTypes.ENTITY_SCORES_FETCHED,
        payload: { scores },
      });
    })
    .catch(error => {
      mpTrack('Fetch Entity VERA Score', { success: false, error });
    });
};

/*
 * Get all drivers' vera scores for the current fleet
 */
export const fetchEntityVeraScores = (
  groupId: string,
  dateFilter: InsightsDateFilter,
  vera2Cutover: string,
  entity: EntityType = EntityType.DRIVER,
) => (dispatch: (any) => void, getState): Promise<void> => {
  dispatch({ type: ActionTypes.FETCHING_ENTITIES_SCORES });

  const { interval, startMs, endMs } = dateFilterToParams(dateFilter);

  const params = {
    group: groupId,
    type: interval,
    min: startMs,
    max: endMs,
    with_details: true,
  };

  const requests = requestScores(`/${entity}s`, params, vera2Cutover);
  const entityId = entity === EntityType.DRIVER ? 'driver_id' : 'vehicle_id';

  return requests
    .then(([response1, response2]) => {
      const scores = combineVeraScores(
        response1.data,
        response2.data,
        entityId,
      );

      dispatch({
        type: ActionTypes.ENTITIES_SCORES_FETCHED,
        payload: { data: scores },
      });
    })
    .catch(error => {
      handleError(dispatch, error);
    });
};

/**
 * ----------------------------------------------------------------------------
 * Reducer
 * ----------------------------------------------------------------------------
 */

interface EntityScore {
  score: VeraScoreProps[];
  driver_id: string;
}

interface EntityInsightsState {
  isFetchingEntityScores: boolean;
  isFetchingEntitiesScores: boolean;
  error: string;
  entityScores: VeraScoreProps[];
  entitiesScores: EntityScore[];
}

export const defaultState: EntityInsightsState = {
  isFetchingEntitiesScores: false,
  isFetchingEntityScores: false,
  error: '',
  entityScores: [],
  entitiesScores: [],
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCHING_ENTITY_SCORES:
      return { ...state, isFetchingEntityScores: true };
    case ActionTypes.FETCHING_ENTITIES_SCORES:
      return { ...state, isFetchingEntitiesScores: true };
    case ActionTypes.ENTITY_SCORES_FETCHED:
      return {
        ...state,
        entityScores: payload.scores || defaultState.entityScores,
        isFetchingEntityScores: false,
      };

    case ActionTypes.ENTITIES_SCORES_FETCHED:
      return {
        ...state,
        entitiesScores: payload.data || defaultState.entitiesScores,
        isFetchingEntitiesScores: false,
      };

    case ActionTypes.CLEAR_DATA:
      return defaultState;

    default:
      return state;
  }
};
