import * as API from '@nauto/api';
import { TimeIntervals } from '@nauto/date-picker';
import { GridReportEntityType } from 'components/entity-type/entity-type-utils';
import { DurationType, ReportRowAPIData } from 'components/reports/types';
import useFleetData from 'hooks/use-fleet-data';
import useVeraScoreToggle from 'hooks/use-vera-score-type-toggle';
import { useQuery } from 'react-query';
import { getRangePresetType, isYearlyRange, Range } from 'utils/date-ranges';
import { ScoreVersion } from 'utils/vera-score-utils';
import { Group } from 'components/groups/types';

interface QueryProps {
  range: Range;
  dateFilter: TimeIntervals;
  isScoreVersionVera3: boolean;
}

interface QueryPropsPayload {
  reportType?: API.reports.REPORT_TYPES;
  min?: number;
  max?: number;
  range?: {
    min?: number;
    max?: number;
  };
  version?: ScoreVersion;
  scoreOnly?: boolean;
  minScore?: number;
  maxScore?: number;
  term?: DurationType;
  aggOnly?: boolean;
}

interface Params {
  reportType: API.reports.REPORT_TYPES;
  range: Range;
  entity: GridReportEntityType;
  dateFilter: TimeIntervals;
  groupId: string;
  subfleetsSelected?: boolean;
  indexedGroups?: any[] | Group;
  cleanSubfleetsReportsData?: (
    reportData: API.reports.ReportItem[] | ReportRowAPIData[],
    indexedGroups?: any[] | Group,
    isScoreVersionVera3?: boolean,
    collisionEventType?: string,
  ) => any;
  cleanReportsData?: (
    reportData: API.reports.ReportItem[] | ReportRowAPIData[],
    indexedGroups?: any[] | Group,
    isScoreVersionVera3?: boolean,
    collisionEventType?: string,
  ) => any;
  collisionEventType?: string;
  select?: (
    data: API.reports.ReportItem[] | ReportRowAPIData[],
  ) => API.reports.ReportItem[] | ReportRowAPIData[];
}

interface ReportPageQueryPayload {
  data: (ReportRowAPIData | API.reports.ReportItem)[];
  isLoading: boolean;
}

type ReportPageQuery = (params?: {
  enabled?: boolean;
  select?: (
    data: (ReportRowAPIData | API.reports.ReportItem)[],
  ) => (ReportRowAPIData | API.reports.ReportItem)[];
}) => ReportPageQueryPayload;

interface UseReportPagePayload {
  reportPageQuery: ReportPageQuery;
}
const REPORT_TYPES = API.reports.REPORT_TYPES;

const filterRangeMapping = {
  [TimeIntervals.YEARLY]: DurationType.Monthly,
  [TimeIntervals.QUARTERLY]: DurationType.Monthly,
  [TimeIntervals.MONTHLY]: DurationType.Monthly,
  [TimeIntervals.WEEKLY]: DurationType.Weekly,
};

export const queries = ({
  range,
  dateFilter,
  isScoreVersionVera3,
}: QueryProps): Record<API.reports.REPORT_TYPES, QueryPropsPayload> => ({
  [REPORT_TYPES.COLLISION]: {
    reportType: REPORT_TYPES.COLLISION,
    min: range.min.valueOf(),
    max: range.max.valueOf(),
  },
  [REPORT_TYPES.RTA]: {
    reportType: REPORT_TYPES.RTA,
    min: range.min.valueOf(),
    max: range.max.valueOf(),
  },
  [REPORT_TYPES.CUSTOM_REPORT]: {
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    term: getRangePresetType(range.min, range.max),
    aggOnly: true,
    ...(isScoreVersionVera3 ? { version: ScoreVersion.VERA3 } : {}),
  },
  [REPORT_TYPES.DRIVER_SAFETY]: {
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    term:
      dateFilter === TimeIntervals.WEEKLY
        ? DurationType.Weekly
        : dateFilter === TimeIntervals.MONTHLY
        ? DurationType.Monthly
        : getRangePresetType(range.min, range.max),
    ...(isScoreVersionVera3 ? { version: ScoreVersion.VERA3 } : {}),
  },
  [REPORT_TYPES.TOP_PERFORMING]: {
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    aggOnly: true,
    term:
      dateFilter === TimeIntervals.WEEKLY
        ? API.drivers.DurationType.Weekly
        : API.drivers.DurationType.Monthly,
    ...(isScoreVersionVera3 ? { version: ScoreVersion.VERA3 } : {}),
  },
  [REPORT_TYPES.AT_RISK]: {
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    aggOnly: true,
    term:
      dateFilter === TimeIntervals.WEEKLY
        ? API.drivers.DurationType.Weekly
        : API.drivers.DurationType.Monthly,
    ...(isScoreVersionVera3 ? { version: ScoreVersion.VERA3 } : {}),
  },
  [REPORT_TYPES.POLICY_VIOLATIONS]: {
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    aggOnly: true,
    term:
      dateFilter === TimeIntervals.WEEKLY
        ? API.drivers.DurationType.Weekly
        : API.drivers.DurationType.Monthly,
    ...(isScoreVersionVera3 ? { version: ScoreVersion.VERA3 } : {}),
  },
});

const useReportPageQuery = (initialParams: Params): UseReportPagePayload => {
  const {
    reportType,
    range,
    dateFilter,
    entity,
    groupId,
    subfleetsSelected,
    indexedGroups,
    cleanSubfleetsReportsData,
    cleanReportsData,
    collisionEventType,
  } = initialParams;
  const { isScoreVersionVera3 } = useVeraScoreToggle();
  const { id: fleetId } = useFleetData({ current: true });
  const queryParams = {
    ...queries({ range, dateFilter, isScoreVersionVera3 })[reportType],
    entity,
    fleetId,
    groupId,
  };

  const getReportData = async (): Promise<
    ReportRowAPIData[] | API.reports.ReportItem[]
  > => {
    let APIRequest;
    if ([REPORT_TYPES.RTA, REPORT_TYPES.COLLISION].includes(reportType)) {
      APIRequest = API.reports.getNewReportData(queryParams);
    } else {
      APIRequest = API.reports.getReport(queryParams);
    }
    const { data } = await APIRequest;

    let updatedData;
    if (
      reportType === REPORT_TYPES.CUSTOM_REPORT ||
      reportType === REPORT_TYPES.DRIVER_SAFETY
    ) {
      if (subfleetsSelected) {
        updatedData = cleanSubfleetsReportsData(
          data as API.reports.ReportItem[],
          indexedGroups,
          isScoreVersionVera3,
          collisionEventType,
        );
      } else {
        updatedData = await cleanReportsData(
          data as API.reports.ReportItem[],
          null,
          isScoreVersionVera3,
          collisionEventType,
        ).then(data => JSON.parse(data));
      }
    } else {
      updatedData = data;
    }

    return updatedData as ReportRowAPIData[];
  };

  /**
   * For all reports not including a few, the calendar date is always
   * in sync with the dateFilter (weekly, monthly, yearly). So when the
   * dateFilter changes, the calendar date should reflect the correct range,
   * and if it does not, we need to handle the case of preventing a request
   * from being made using wrong dateFilter and range. Example, if dateFilter
   * is Monthly and range is 7 days, we disable request.
   */

  const verifyFilters = ![
    REPORT_TYPES.RTA,
    REPORT_TYPES.COLLISION,
    REPORT_TYPES.CUSTOM_REPORT,
    REPORT_TYPES.DRIVER_SAFETY,
  ].includes(reportType);

  const filterMatchesRange =
    verifyFilters &&
    filterRangeMapping[dateFilter] === getRangePresetType(range.min, range.max);

  const enableQuery = verifyFilters
    ? filterMatchesRange && !!queryParams
    : !!queryParams;

  const useReportPageData: ReportPageQuery = ({
    enabled = true,
    select,
  } = {}) => {
    const { data = [], isLoading } = useQuery(
      [
        'getReportPageData',
        queryParams,
        ...(!verifyFilters ? [dateFilter] : []),
        Object.keys(indexedGroups),
      ],
      getReportData,
      {
        staleTime: Infinity,
        enabled: enableQuery && enabled,
        select,
      },
    );

    return {
      data,
      isLoading,
    };
  };

  return { reportPageQuery: useReportPageData };
};

export default useReportPageQuery;
