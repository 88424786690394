import { LoggedInUserType } from 'constants/roles';
import { useContext } from 'react';
import LoggedInUserTypeContext from './context';
import LoggedInUserTypeProvider from './provider';

interface Props {
  loggedInUserType?: LoggedInUserType;
  isLoggedInUserTypeDriver?: boolean;
}

const useLoggedInUserType = (): Props => useContext(LoggedInUserTypeContext);

export { LoggedInUserTypeProvider };
export default useLoggedInUserType;
