import useFleetData from 'hooks/use-fleet-data';
import useGroupData from 'hooks/use-group-data';
import * as API from '@nauto/api';
import { useQuery, useInfiniteQuery, InfiniteData } from 'react-query';
import { useCallback } from 'react';

interface Params {
  fleetId: string;
  groupId: string;
}

const LIMIT = 20;

const useRequestedVideosQuery = (
  offset = 0,
): typeof requestedVideoDataQuery => {
  const { id: fleetId } = useFleetData({ current: true });
  const { id: groupId } = useGroupData();

  const getRequestedVideoData = async () => {
    const { data } = await API.cvr.fetchCVRRequestedVideos({
      fleetId: fleetId,
      groupId: groupId,
      limit: LIMIT,
      offset: offset,
    });
    return data;
  };

  const requestedVideoDataQuery = useQuery(
    ['useRequestedVideos', { fleetId, groupId }, offset],
    getRequestedVideoData,
    {
      staleTime: 60000,
      refetchOnMount: true,
      cacheTime: 0,
    },
  );

  return requestedVideoDataQuery;
};

interface CVRPage {
  data?: API.cvr.CVRResponse[];
  offset?: number;
}

type PagedCVR = InfiniteData<CVRPage> & {
  cvr?: API.cvr.CVRResponse[];
};

type requestedVideosResponse = {
  cvr: API.cvr.CVRResponse[];
  data?: PagedCVR;
  fetchNextPage: () => void;
  isLoading: boolean;
  hasNextPage?: boolean;
};

export const useRequestedVideosInfiniteQuery = (): requestedVideosResponse => {
  const { id: fleetId } = useFleetData({ current: true });
  const { id: groupId } = useGroupData();

  const getRequestedVideoData = async ({ pageParam = 0 }) => {
    const { data } = await API.cvr.fetchCVRRequestedVideos({
      fleetId: fleetId,
      groupId: groupId,
      limit: LIMIT,
      offset: pageParam,
    });
    return { data, offset: pageParam };
  };

  const query = useInfiniteQuery(
    ['useRequestedVideosInfinite', { fleetId, groupId }],
    getRequestedVideoData,
    {
      getNextPageParam: lastPage => {
        if (lastPage.data.length < LIMIT) {
          return undefined;
        }
        return lastPage.offset + LIMIT;
      },
      select: useCallback(({ pages, pageParams }) => {
        const flatData = pages.flatMap(p => p.data);
        return {
          cvr: flatData,
          pages,
          pageParams,
        };
      }, []),
      staleTime: 30000,
      refetchOnMount: true,
      cacheTime: 0,
    },
  );

  return {
    ...query,
    cvr: (query.data as PagedCVR)?.cvr,
  };
};

export default useRequestedVideosQuery;
