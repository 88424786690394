import { createStructuredSelector } from 'reselect';
import { parse } from 'query-string';
/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export const SET_DEBUG = 'debug/set-debug';

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */
export const debugSelector = state => state.modes.debug;

export const debugStructuredSelector = createStructuredSelector({
  debug: debugSelector,
});

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

/**
 * Post to create a new user in the system based
 */
export const setDebug = (isDebug: boolean) => dispatch =>
  dispatch({ type: SET_DEBUG, payload: isDebug });

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */
export interface DebugReducer {
  debug: boolean;
}
export const initialState = {
  debug: window && parse(window.location.href).debug,
};

export default (state = initialState, { type, payload }) => {
  if (type === SET_DEBUG) {
    return {
      ...state,
      debug: payload,
    };
  } else {
    return state;
  }
};
