export const TWENTY_FOUR_HOURS = 8640000;
export const TWELVE_HOURS = TWENTY_FOUR_HOURS / 2;
export const FOUR_HOURS = TWENTY_FOUR_HOURS / 6;
export const THIRTY_MINUTES = 1800000;
export const TEN_MINUTES = 600000;
export const PAGINATION_LIMIT = 20;

export enum DEVICE_STATE {
  MOVING = 'moving',
  STOPPED = 'stopped',
  PARKED = 'parked',
  LOST = 'lost',
  NONE = 'undefined',
  UNKNOWN = 'unknown',
}

export const JP_ORDER_LIST = [
  DEVICE_STATE.MOVING,
  DEVICE_STATE.STOPPED,
  DEVICE_STATE.PARKED,
  DEVICE_STATE.LOST,
  DEVICE_STATE.NONE,
  DEVICE_STATE.UNKNOWN,
];

export const QUERY_KEYS = {
  ALL_VEHICLES: 'all-vehicles',
  VEHICLE_STATS: 'all-vehicle-statistics'
};
