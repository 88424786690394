import { mpTrack } from 'components/mixpanel';
import { debugSelector } from 'components/modes/modes.redux';
import { createStructuredSelector } from 'reselect';
import { fetchRequest } from 'utils/requests';
import { getFleetId, getServiceUrl } from 'utils/localstorage';

export interface Judgement {
  id: number | string;
  type: string;
  questionnaire_id: number | string;
  createdAt: string;
  byAdmin: boolean;
  duration: number;
  confidence: number;
  summary: string;
  preferred: boolean;
  sufficient: boolean;
  info: any;
  user: any;
  event: any;
}

export interface JudgementReducer {
  fetchingJudgement?: boolean;
  judgementNotFound?: boolean;
  judgement?: Judgement;
  eventKey?: string;
}

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export const FETCHING_JUDGEMENT = 'judgement/fetching-judgement';
export const JUDGEMENT_RECEIVED = 'judgement/judgement-received';
export const JUDGEMENT_FETCH_FAILED = 'judgement/judgement-fetch-failed';
export const JUDGEMENT_NOT_FOUND = 'judgement/judgement-not-found';
export const CLEAR_JUDGEMENT = 'judgement/judgement-fetch-failed';

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

/**
 * selector for judgement
 */

export const judgementSelector = createStructuredSelector({
  judgement: ({ judgement }) => judgement.judgement,
  eventKey: ({ judgement }) => judgement.eventKey,
  fetchingJudgement: ({ judgement }) => judgement.fetchingJudgement,
  judgementNotFound: ({ judgement }) => judgement.judgementNotFound,
  debug: debugSelector,
});

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */
/* tslint:disable:no-duplicate-string */
export const getJudgement = (eventKey: string, fleetId: string) => (
  dispatch: (any) => void,
) => {
  dispatch({ type: FETCHING_JUDGEMENT });

  const bestFleetId = fleetId || getFleetId();
  const url = `${getServiceUrl()}/fleets/${bestFleetId}/events/review-details/${eventKey}`;

  const options = {
    method: 'GET',
  };

  return fetchRequest(url, options)
    .then(res => {
      if (res) {
        mpTrack('Get judgement', { success: true });
        dispatch({
          type: JUDGEMENT_RECEIVED,
          payload: { judgement: res.data, eventKey },
        });
      } else {
        mpTrack('Get judgement', {
          success: false,
          error: 'Judgement not found',
        });
        dispatch({
          type: JUDGEMENT_NOT_FOUND,
        });
      }
    })
    .catch(error => {
      mpTrack('Get judgement', { success: false, error });
      dispatch({ type: JUDGEMENT_FETCH_FAILED, payload: error });
    });
};

// clears out the current judgements
export const clearJudgement = () => dispatch =>
  dispatch({ type: CLEAR_JUDGEMENT });

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */
export const initialState: JudgementReducer = {
  fetchingJudgement: false,
  judgementNotFound: false,
  judgement: null,
  eventKey: null,
};

export default (state = initialState, { type, payload }): JudgementReducer => {
  switch (type) {
    case FETCHING_JUDGEMENT:
      return {
        ...initialState,
        fetchingJudgement: true,
      };
    case JUDGEMENT_RECEIVED:
      return {
        ...state,
        fetchingJudgement: false,
        eventKey: payload.eventKey,
        judgement: payload.judgement,
      };
    case JUDGEMENT_NOT_FOUND:
    case JUDGEMENT_FETCH_FAILED:
      return {
        ...state,
        // a key with value null means the suggestions fetch is in progress
        fetchingJudgement: false,
        eventKey: null,
        judgementNotFound: true,
      };
    case CLEAR_JUDGEMENT:
      return initialState;
    default:
      return state;
  }
};
