import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch, Action } from 'redux';
import styled from 'styled-components';
import { History, Location } from 'history';
import { withRouter, Link } from 'react-router-dom';
import { colors } from '@nauto/uikit/dist/styles';
import { SearchInput } from '@nauto/uikit/dist/atoms/forms';
import PillsMenu, {
  PillsMenuItem,
} from '@nauto/uikit/dist/atoms/pills-menu/pills-menu';
import { withTranslation, WithTranslation } from 'react-i18next';
import { stringify } from 'query-string';
import EmptySearch from './empty-search';
import {
  setSearchContext,
  setSearchTerm,
  getSearchResults,
  globalSearchSelector,
} from './global-search.redux';
import CenteredSpinner from 'atoms/centered-spinner';
import { InitialsCoin } from '@nauto/uikit/dist/atoms/coins';
import { Icon, IconName } from '@nauto/uikit/dist/atoms/icons';
import ListItem from '@nauto/uikit/dist/atoms/list-item/list-item';
import debounce from 'lodash-es/debounce';
import { parse } from 'query-string';
import Tooltip from '@nauto/tooltip';
import { Box, Text } from '@nauto/core';

interface Props extends WithTranslation {
  className?: string;
  handleToggle?: () => void;
  dispatch?: Dispatch<Action>;
  activeContext: string;
  searchTerm: string;
  isFetching?: boolean;
  searchError?: boolean;
  searchResults?: any[];
  history?: History;
  location?: Location;
}

export class GlobalSearch extends React.Component<Props, any> {
  state = {
    search: this.props.searchTerm || '',
  };

  startNewSearch = () => {
    const { searchTerm, dispatch } = this.props;
    if (searchTerm.length < 3) {
      return null;
    }
    dispatch(getSearchResults());
  };

  handleSearchInput = e => {
    this.setState({
      search: e.target.value,
    });
    this.props
      .dispatch(setSearchTerm(e.target.value))
      .then(debounce(this.startNewSearch, 500));
  };

  handleContextMenuSelect = item => {
    this.props.dispatch(setSearchContext(item.id)).then(this.startNewSearch);
  };

  getMenuItems = () => {
    const { t } = this.props;
    return [
      {
        id: 'fleet',
        component: t('Fleets'),
      },
      {
        id: 'device',
        component: t('Devices'),
      },
      {
        id: 'vehicle',
        component: t('Vehicles'),
      },
      {
        id: 'user',
        component: t('Users'),
      },
    ] as PillsMenuItem[];
  };

  showResults = () => {
    switch (this.props.activeContext) {
      case 'fleet':
        return this.getFleetsListing();
      case 'device':
        return this.getGeneralListing('device', 'fleet');
      case 'user':
        return this.getGeneralListing('user', 'email');
      case 'vehicle':
        return this.getGeneralListing('vehicle');
    }
  };

  getFleetsListing = () => {
    const { searchResults } = this.props;
    return searchResults.map(({ item }) => (
      <Link
        to={{
          pathname: `/f/${item.id}`,
          state: { return_to_page: 'organization' },
          search: stringify({ entity: 'vehicle' }),
        }}
        key={item.id}
      >
        <ListItem
          key={item.id}
          icon={<InitialsCoin size="md" label={item.name || item.id} />}
          label={item.name}
          note={item.id}
          hovered={<Icon icon="arrowForward" size="md" />}
        />
      </Link>
    ));
  };

  getLinkTo = item => {
    const { activeContext } = this.props;
    switch (activeContext) {
      case 'device':
        return {
          pathname: `/f/${item.fleet}`,
          state: { return_to_page: 'organization' },
          search: stringify({
            entity: 'vehicle',
            tablekey: 'vehicle_name',
            tablevalue: item.vehicle_name,
          }),
        };
      case 'vehicle':
        return {
          pathname: `/f/${item.fleet}`,
          state: { return_to_page: 'organization' },
          search: stringify({
            entity: 'vehicle',
            tablekey: 'id',
            tablevalue: item.id,
          }),
        };
      case 'user':
        return `/user/${item.id}`;
    }
  };

  tooltipContent = item => {
    return (
      <div>
        {Object.keys(item).map(key => {
          return (
            <Box marginLeft="md" marginRight="md" key={key}>
              <Box>
                <Text size="sm" color={colors.grey10}>
                  {key}
                </Text>
              </Box>
              <Box marginLeft="sm" marginRight="sm">
                :
              </Box>
              <Box>
                <Text size="sm" color={colors.white}>
                  {item[key] ? item[key] : `" "`}
                </Text>
              </Box>
            </Box>
          );
        })}
      </div>
    );
  };

  getGeneralListing = (iconName?: IconName, noteId?: string) => {
    const { searchResults, location } = this.props;
    const styleObj = {
      background: colors.grey50,
      padding: '0.6rem',
      borderRadius: '50%',
      display: 'flex',
    };
    const searchParams = parse(location.search);
    const debugMode = searchParams && searchParams.debug;

    return searchResults.map(({ item }) => {
      const linkComponent = (
        <Link key={item.id} to={this.getLinkTo(item)}>
          <ListItem
            key={item.id}
            icon={
              <div className="icon-container" style={styleObj}>
                <Icon icon={iconName} size="lg" />
              </div>
            }
            label={item.name || item.id}
            note={noteId && item[noteId]}
            hovered={<Icon icon="arrowForward" size="md" />}
          />
        </Link>
      );

      if (debugMode && iconName === 'device') {
        return (
          <TooltipContainer>
            <Tooltip key={item.id} tooltip={this.tooltipContent(item)}>
              {linkComponent}
            </Tooltip>
          </TooltipContainer>
        );
      } else {
        return linkComponent;
      }
    });
  };

  render() {
    const {
      t,
      className,
      searchTerm,
      activeContext,
      searchResults,
      isFetching,
      searchError,
    } = this.props;
    const isSearchTermValid = searchTerm && searchTerm.length > 2;
    const activeContextString = {
      ['fleet']: t('fleet'),
      ['device']: t('device'),
      ['vehicle']: t('vehicle'),
      ['user']: t('user'),
    };
    return (
      <div className={className}>
        <div className="search-box" data-testid="global-search">
          <SearchInput
            className="search"
            value={this.state.search}
            autoFocus={true}
            onChange={this.handleSearchInput}
            placeholder={t('Search by {{context}}', {
              context: activeContextString[activeContext],
            })}
          />
        </div>
        <div className="search-context">
          <PillsMenu
            direction={'row'}
            onClick={this.handleContextMenuSelect}
            onFocus={this.handleContextMenuSelect}
            items={this.getMenuItems()}
            active={activeContext}
          />
        </div>
        <div className="search-results">
          {isFetching && <CenteredSpinner />}
          {!isSearchTermValid && (
            <div className="empty-search-container">
              <EmptySearch
                heading={t('Search Nauto Dashboard')}
                subHeading={t(
                  'Find your fleets, Nauto devices, dashboard users and vehicles.',
                )}
              />
            </div>
          )}
          {isSearchTermValid &&
            !isFetching &&
            !searchError &&
            (!searchResults ||
              (searchResults && searchResults.length === 0)) && (
              <div className="no-search-container">
                <EmptySearch
                  // tslint:disable-next-line
                  heading={`${t("We can't find ")} '${searchTerm}'`}
                />
              </div>
            )}
          {searchError && !isFetching && (
            <div className="error-search-container">
              <EmptySearch
                heading={t('Error occured')}
                subHeading={t('Team is on it')}
              />
            </div>
          )}
          {!isFetching && searchResults && searchResults.length > 0 && (
            <div className="result-items-container">{this.showResults()}</div>
          )}
        </div>
      </div>
    );
  }
}

const TooltipContainer = styled.div`
  .activator {
    width: 100%;
  }
`;

const StyledGlobalSearch = styled(GlobalSearch)`
  width: 26.25rem;
  .search-box {
    padding: 0.625rem;
    input {
      box-shadow: none;
    }
  }
  .search-results {
    height: 27.725rem;
    display: flex;
  }
  .empty-search-container,
  .error-search-container,
  .no-search-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .result-items-container {
    width: 100%;
    height: 100%;
    overflow: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: flex;
    flex-flow: column;
  }
`;

export default compose(
  connect(globalSearchSelector),
  withTranslation(),
  withRouter,
)(StyledGlobalSearch);
