import { combineReducers } from 'redux';
import ldRedux from 'ld-redux';
import activeEntityType from './components/entity-type/active-entity-type.redux';
import addresses from './components/address/addresses.redux';
import coaching from './components/coaching/coaching.redux';
import coachingNew from './components/coaching-new/coaching.redux';
import deviceActions from './components/device-actions/device-actions.redux';
import deviceHistory from './components/device-history/device-history.redux';
import installPhotos from 'redux/install-photos';
import entityInsights from './redux/entity-insights';
import driverInvites from './components/organization/drivers/invites/driver-invites.redux';
import drivers from './components/organization/drivers/drivers.redux';
import driver from 'components/driver/redux';
import entityPicker from './components/entity-picker/entity-picker.redux';
import eventData from './components/events/events.redux';
import faces from './redux/faces';
import fleetInsights from './redux/fleet-insights';
import globalSearch from './components/global-search/global-search.redux';
import groups from './components/groups/groups.redux';
import health from './components/fleet-health/health.redux';
import insights from './redux/insights';
import live_devices from './redux/live-devices';
import logs from './components/downstream-messages/logs.redux';
import mediaPlayer from './components/media-player/media-player.redux';
import modes from './components/modes/modes.redux';
import notificationsData from './components/notifications/notifications.redux';
import profile from './components/profile/profile.redux';
import reboot from './components/reboot/reboot.redux';
import reports from './components/reports/reports.redux';
import sensorData from './components/downstream-messages/sensor-data.redux';
import singleEvent from './redux/single-event';
import tripSnapshots from 'components/tag-trips/trip-snapshots.redux';
import trips_data from './redux/trips';
import user, {
  Constants as AuthConstants,
} from './components/auth/auth.reducer';
import superfleets from 'components/superfleets/superfleets.redux';

import usersData from './components/users/users.redux';
import vehicles from './components/organization/vehicles/vehicles.redux';
import videoRequestSender from './redux/video-request-sender';
import questionnaires from './redux/questionnaires';
import judgement from './redux/collision-details';
import fleetSettings from './components/settings/settings.redux';
import * as Sentry from '@sentry/browser';

const featureFlags = ldRedux.reducer();

const appReducer = combineReducers({
  activeEntityType,
  addresses,
  coaching,
  coachingNew,
  deviceActions,
  deviceHistory,
  driverInvites,
  drivers,
  driver,
  entityInsights,
  entityPicker,
  eventData,
  faces,
  featureFlags,
  fleetInsights,
  fleetSettings,
  globalSearch,
  groups,
  health,
  insights,
  installPhotos,
  judgement,
  live_devices,
  logs,
  mediaPlayer,
  modes,
  notificationsData,
  profile,
  questionnaires,
  reboot,
  reports,
  sensorData,
  singleEvent,
  superfleets,
  trips_data,
  tripSnapshots,
  user,
  usersData,
  vehicles,
  videoRequestSender,
});

const rootReducer = (state: any = {}, action) => {
  if (action.type === 'RESET_ALL') {
    state = {
      // preserve redirect state
      user: {
        redirect: state.user.redirect,
        flash: state.user.flash,
      },
    };

    try {
      Sentry.configureScope(scope => scope.setUser(null));
    } catch (error) {
      // noop
    }
  }

  if (action.type === AuthConstants.SELECT_FLEET) {
    state = {
      user: state.user,
      featureFlags: state.featureFlags,
    };
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
