import { createSelector } from 'reselect';
import moment from 'moment';
import orderBy from 'lodash-es/orderBy';
import uniq from 'lodash-es/uniq';

import { Risk } from '@nauto/uikit/dist/types';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { fetchRequest } from 'utils/requests';
import { path } from 'utils/helpers';
import { isValidScore } from 'utils/report-utils';
import { selectActiveEntityType } from 'components/entity-type/active-entity-type.redux';

export type InsightsInterval = 'd' | 'w' | 'm';
export interface InsightsDateFilter {
  interval: InsightsInterval;
  duration: number; // duration of date filter, in milliseconds
}
export interface InsightsIntervalFilter {
  start_time: string; // The UTC day for which the interval starts
  end_time: string; // The UTC day for which the interval ends
}
export type InsightsScore = 'vera' | 'attention' | 'smoothDriving';
export interface InsightsScoreFilter {
  score: InsightsScore;
}
export interface VeraScoreProps {
  date: string;
  // The first UTC aggregation day of data used to get this data
  start_time: string;
  // The last (inclusive) UTC aggregation day of data used to get this data
  end_time: string;
  duration: number;
  vera_score: number;
  attention_score: number;
  smooth_driving_score: number;
}

export type DataPredicate = (d: any) => boolean;

/**
 * ----------------------------------------------------------------------------
 * Helpers
 * ----------------------------------------------------------------------------
 */

// TODO: Find a better home for this util function
export const scoreRiskLevel = (score: number): Risk => {
  switch (true) {
    case score >= 75:
      return 'top-performers';
    case score >= 50:
      return 'normal-range';
    case score >= 25:
      return 'below-normal';
    default:
      return 'at-risk';
  }
};

export const interpolateUndefinedValues: (
  data: any[],
  isDefined: DataPredicate,
  value?: (d: any) => number,
) => any[] = (_data, isDefined, value = d => d.score) => {
  const data = _data.map(d => ({
    ...d,
    __value: value(d),
    defined: isDefined(value(d)),
  }));
  let interpolationQueue = [];
  let lastDefined;

  const interpolate: (start: number, end: number) => void = (start, end) => {
    const step = (end - start) / (interpolationQueue.length + 1);

    interpolationQueue.forEach((index, i) => {
      data[index].defined = true;
      data[index].interpolated = true;
      data[index].__value = start + step * (i + 1);
    });
    interpolationQueue = [];
  };

  data.forEach((d, i) => {
    const canInterpolate = typeof lastDefined !== 'undefined';
    if (isDefined(d.__value)) {
      if (canInterpolate) {
        interpolate(lastDefined, d.__value);
      }
      lastDefined = d.__value;
    } else {
      if (canInterpolate) {
        interpolationQueue.push(i);
      }
    }
  });

  return data;
};

export const dateFilterToParams = (filter: InsightsDateFilter) => {
  const { duration, interval } = filter;
  const startOf = interval === 'w' ? 'isoWeek' : interval;

  const startMs = +moment()
    .utc()
    .startOf(startOf)
    .subtract(1, interval)
    .subtract(duration, 'ms');
  const endMs = +moment()
    .utc()
    .startOf(startOf)
    // subtract 1 ms so that the range does not include the next period inclusively
    .subtract(1, 'ms');

  return { interval, endMs, startMs };
};

export const scoreProp = (score: InsightsScore): string => {
  return {
    vera: 'score',
    attention: 'attention_score',
    smoothDriving: 'smooth_driving_score',
  }[score];
};

/**
 * ----------------------------------------------------------------------------
 * Vera 2.0 utility functions
 * ----------------------------------------------------------------------------
 */

export const cutoverMin = ({ min, max, vera2CutoverMs }) =>
  Math.min(Math.max(vera2CutoverMs, min), max - 1);

export const cutoverMax = ({ min, max, vera2CutoverMs }) =>
  Math.max(Math.min(vera2CutoverMs, max), min + 1);

export const requestScores = (
  route: string,
  params: any,
  vera2Cutover: string,
) => {
  const vera2CutoverMs = moment(vera2Cutover).valueOf();

  const currentPrefix = 'report/score';
  const legacyPrefix = 'report/vera';
  // tslint:disable-next-line:prettier
  const currentRoute = `${getServiceUrl()}/fleets/${getFleetId()}/${currentPrefix}${route}`;
  const legacyRoute = currentRoute.replace(currentPrefix, legacyPrefix);

  const { min, max } = params;

  const currentPath = path(currentRoute, {
    ...params,
    min: cutoverMin({ min, max, vera2CutoverMs }),
  });

  const legacyPath = path(legacyRoute, {
    ...params,
    max: cutoverMax({ min, max, vera2CutoverMs }),
  });
  let legacyRequest = Promise.resolve({ data: [] });
  if (min < vera2CutoverMs) {
    legacyRequest = fetchRequest(legacyPath, { method: 'GET' });
  }

  return Promise.all([
    legacyRequest,
    fetchRequest(currentPath, { method: 'GET' }),
  ]);
};

export const combineVeraScores = (vera1Scores, vera2Scores, idKey: string) => {
  const getKey = data => data[idKey];
  const defaultScoreData = { weekly_score: [], monthly_score: [], score: [] };

  const allKeys = [...vera1Scores.map(getKey), ...vera2Scores.map(getKey)];

  const uniqueKeys = uniq(allKeys);

  return uniqueKeys.map(key => {
    const vera1Data =
      vera1Scores.find(data => getKey(data) === key) || defaultScoreData;
    const vera2Data =
      vera2Scores.find(data => getKey(data) === key) || defaultScoreData;

    const score = [
      ...vera2Data.weekly_score,
      ...vera2Data.monthly_score,
      ...vera1Data.score,
    ];
    return { ...vera2Data, ...vera1Data, score };
  });
};

/**
 * ----------------------------------------------------------------------------
 * Selectors
 * ----------------------------------------------------------------------------
 */

export const dateAsc = (array, date = d => d.start_time) =>
  orderBy(array, date);
export const dateDesc = (array, date = d => d.start_time) =>
  orderBy(array, date, 'desc');

export const scoresOverTime = (scores, selector) =>
  dateAsc(scores).map(s => ({
    ...s,
    score: selector(s),
  }));

export const sortedByScore = selector =>
  createSelector(selector, selection =>
    orderBy(selection as any[], s => s.score),
  );

const amountChange = (current: number, previous: number) =>
  !isValidScore(previous) ? 0 : current - previous;

export const latestScoreSelector = (
  scores,
  selector,
  isDefined: DataPredicate,
) => {
  const [latest] = dateDesc(scores)
    .map(selector)
    .filter(isDefined);

  return latest || 0;
};

export const latestScoreAndChange = (
  scores,
  selector,
  isDefined: DataPredicate,
) => {
  const [latest, prev] = dateDesc(scores)
    .map(selector)
    .filter(isDefined) as number[];

  return {
    score: latest || 0,
    change: amountChange(latest, prev),
  };
};

export const latestDefinedInterval = (
  scores: any[],
  isDefined: DataPredicate,
): InsightsIntervalFilter => {
  const lastDefinedInterval =
    dateDesc(scores).find(score => isDefined(vera(score))) || {};
  const { start_time, end_time } = lastDefinedInterval;
  return { start_time, end_time };
};

export const vera = d => d[scoreProp('vera')];
export const attention = d => d[scoreProp('attention')];
export const smoothDriving = d => d[scoreProp('smoothDriving')];
export const dateFilterSelector = ({ insights }) => insights.dateFilter;

const scoreFilterSelector = ({ insights }) => insights.scoreFilter;
const intervalFilterSelector = ({ insights }) => insights.intervalFilter;

export const intervalFiltersSelector = ({
  insights: { intervalFilter, transientIntervalFilter },
}) => transientIntervalFilter || intervalFilter;

export const dateFiltered = (selector: any = () => ({})) =>
  createSelector(selector, dateFilterSelector, (selection, dateFilter) => ({
    ...selection,
    dateFilter,
  }));

export const scoreFiltered = (selector: any = () => ({})) =>
  createSelector(selector, scoreFilterSelector, (selection, scoreFilter) => ({
    ...selection,
    scoreFilter,
  }));

export const intervalFiltered = (selector: any = () => ({})) =>
  createSelector(
    selector,
    intervalFilterSelector,
    selectActiveEntityType,
    (selection, intervalFilter, activeEntity) => ({
      ...selection,
      intervalFilter,
      activeEntity,
    }),
  );

export const filtered = (selector: any = () => ({})) =>
  intervalFiltered(dateFiltered(scoreFiltered(selector)));

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

export enum ActionTypes {
  DATE_FILTER_UPDATED = 'insights/date-filter-updated',
  INTERVAL_FILTER_UPDATED = 'insights/interval-filter-updated',
  TRANSIENT_INTERVAL_FILTER_CLEARED = 'insights/transient-interval-filter-cleared',
  TRANSIENT_INTERVAL_FILTER_UPDATED = 'insights/transient-interval-filter-updated',
  SCORE_FILTER_UPDATED = 'insights/score-filter-updated',
}

export const updateInsightsDateFilter = (dateFilter: InsightsDateFilter) => (
  dispatch: (any) => void,
): void => {
  dispatch({
    type: ActionTypes.DATE_FILTER_UPDATED,
    payload: { ...dateFilter },
  });
};

export const updateInsightsIntervalFilter = (
  intervalFilter: InsightsIntervalFilter,
) => (dispatch: (any) => void): void => {
  dispatch({
    type: ActionTypes.INTERVAL_FILTER_UPDATED,
    payload: { ...intervalFilter },
  });
};

export const updateTransientInsightsIntervalFilter = (
  intervalFilter: InsightsIntervalFilter,
) => (dispatch: (any) => void): void => {
  dispatch({
    type: ActionTypes.TRANSIENT_INTERVAL_FILTER_UPDATED,
    payload: { ...intervalFilter },
  });
};

export const clearTransientInsightsIntervalFilter = () => (
  dispatch: (any) => void,
): void => {
  dispatch({
    type: ActionTypes.TRANSIENT_INTERVAL_FILTER_CLEARED,
  });
};

export const updateInsightsScoreFilter = (scoreFilter: InsightsScoreFilter) => (
  dispatch: (any) => void,
): void => {
  dispatch({
    type: ActionTypes.SCORE_FILTER_UPDATED,
    payload: { ...scoreFilter },
  });
};

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */

interface InsightsState {
  dateFilter: InsightsDateFilter;
  scoreDefined: DataPredicate;
  scoreFilter: InsightsScoreFilter;
  intervalFilter: InsightsIntervalFilter;
  transientIntervalFilter: InsightsIntervalFilter;
}

export const defaultState: InsightsState = {
  dateFilter: {
    duration: +moment.duration(6, 'w'),
    interval: 'w',
  },
  scoreDefined: isValidScore,
  intervalFilter: {
    start_time: moment().format(),
    end_time: moment().format(),
  },
  transientIntervalFilter: undefined,
  scoreFilter: {
    score: 'vera',
  },
};

export default (state = defaultState, { type, payload }): InsightsState => {
  switch (type) {
    case ActionTypes.DATE_FILTER_UPDATED:
      return {
        ...state,
        dateFilter: { ...payload },
      };
    case ActionTypes.INTERVAL_FILTER_UPDATED:
      return {
        ...state,
        intervalFilter: { ...payload },
      };
    case ActionTypes.TRANSIENT_INTERVAL_FILTER_UPDATED:
      return {
        ...state,
        transientIntervalFilter: { ...payload },
      };
    case ActionTypes.TRANSIENT_INTERVAL_FILTER_CLEARED:
      return {
        ...state,
        transientIntervalFilter: defaultState.transientIntervalFilter,
      };
    case ActionTypes.SCORE_FILTER_UPDATED:
      return {
        ...state,
        scoreFilter: { ...payload },
      };
    default:
      return state;
  }
};
