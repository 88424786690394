import { mpTrack } from 'components/mixpanel';
import { debugSelector } from 'components/modes/modes.redux';
import { createStructuredSelector } from 'reselect';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { fetchRequest } from 'utils/requests';
import { Questionnaire, QuestionnaireSet } from '@nauto/questionnaires';

export type ID = number | string;

export interface QuestionnaireReducer {
  fetchingQuestionnaire?: boolean;
  questionnaireNotFound?: boolean;
  questionnaire?: Questionnaire;
  questionnaireCache?: QuestionnaireSet[];
}

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export const FETCHING_QUESTIONNAIRE = 'questionnaire/fetching-questionnaire';
export const QUESTIONNAIRE_RECEIVED = 'questionnaire/questionnaire-received';
export const QUESTIONNAIRE_FETCH_FAILED =
  'questionnaire/questionnaire-fetch-failed';
export const QUESTIONNAIRE_NOT_FOUND = 'questionnaire/questionnaire-not-found';
export const CLEAR_QUESTIONNAIRE = 'questionnaire/questionnaire-fetch-failed';

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

/**
 * selector for questionnaire
 */

export const questionnaireSelector = createStructuredSelector({
  questionnaire: ({ questionnaires }) => questionnaires.questionnaire,
  fetchingQuestionnaire: ({ questionnaires }) =>
    questionnaires.fetchingQuestionnaire,
  questionnaireNotFound: ({ questionnaires }) =>
    questionnaires.questionnaireNotFound,
  debug: debugSelector,
});

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */
/* tslint:disable:no-duplicate-string */
export const getQuestionnaire = (questionnaireID: ID, fleetId?: string) => (
  dispatch: (any) => void,
  getState,
) => {
  // Ask for a questionnaire of given subtype ID (= questionnaire ID).
  // It may so happen that this is already locally stored, so then just
  // simulate as if it was fetched.
  const cache = getState().questionnaires.questionnaireCache;

  const cached = cache.find(qc => qc.id === questionnaireID);

  if (cached) {
    dispatch({
      type: QUESTIONNAIRE_RECEIVED,
      payload: { id: questionnaireID, questionnaire: cached.questionnaire },
    });
    return Promise.resolve(true);
  }

  // If its first time we're seeing that questionnaire ID,
  // lets make a real request for it.
  dispatch({ type: FETCHING_QUESTIONNAIRE });

  const bestFleetId = fleetId || getFleetId();

  const url = `${getServiceUrl()}/fleets/${bestFleetId}/events/questionnaire/${questionnaireID}`;

  const options = {
    method: 'GET',
  };

  return fetchRequest(url, options)
    .then(res => {
      if (res) {
        // All questions should have a key defined - older questionnaires may not have it,
        // so we default to question ID in such a case.
        res.data.questions.forEach(question => {
          if (!question.key) {
            question.key = question.id;
          }
        });

        mpTrack('Get questionnaire', { success: true });
        // Pushing the questionnaire to cache: ID and its contents.
        // Keeping ID separately here since the questionnaire format could
        // possibly change, if the API gets some turbulence.
        dispatch({
          type: QUESTIONNAIRE_RECEIVED,
          payload: {
            id: questionnaireID,
            questionnaire: res.data,
          },
        });
      } else {
        mpTrack('Get questionnaire', {
          success: false,
          error: 'Questionnaire not found',
        });
        dispatch({
          type: QUESTIONNAIRE_NOT_FOUND,
        });
      }
    })
    .catch(error => {
      mpTrack('Get questionnaire', { success: false, error });
      dispatch({ type: QUESTIONNAIRE_FETCH_FAILED, payload: error });
    });
};

// clears out the current event
export const clearEvent = () => dispatch =>
  dispatch({ type: CLEAR_QUESTIONNAIRE });

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */
export const initialState: QuestionnaireReducer = {
  fetchingQuestionnaire: false,
  questionnaireNotFound: false,
  questionnaire: null,
  questionnaireCache: [],
};

export default (
  state = initialState,
  { type, payload },
): QuestionnaireReducer => {
  switch (type) {
    case FETCHING_QUESTIONNAIRE:
      return {
        ...initialState,
        fetchingQuestionnaire: true,
      };
    case QUESTIONNAIRE_RECEIVED:
      // received new Questionnaire - pushing it to cache
      // and pushing it out to props at the same time.
      if (!state.questionnaireCache.some(qc => qc.id === payload.id)) {
        state.questionnaireCache.push(payload);
      }

      return {
        ...state,
        fetchingQuestionnaire: false,
        questionnaire: payload.questionnaire,
      };

    case QUESTIONNAIRE_NOT_FOUND:
    case QUESTIONNAIRE_FETCH_FAILED:
      return {
        ...state,
        fetchingQuestionnaire: false,
        questionnaireNotFound: true,
      };
    case CLEAR_QUESTIONNAIRE:
      return initialState;
    default:
      return state;
  }
};
