import moment, { Moment } from 'moment';
import { addNotification } from 'components/notifications/notifications.redux';
import { fetchRequest } from 'utils/requests';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { pathToGeoJSONSourceData } from 'utils/trip-utils';
import { createSelector } from 'reselect';
import { currentScrubTimeSelector } from 'redux/trips';
import {
  fleetPropertiesSelector,
  isSupportUserSelector,
  isNonSuperfleetAdminSelector,
} from 'components/auth/auth.reducer';
import {
  tripPathSelector,
  selectScrubIndex,
} from 'components/map/map-selectors';
import { mpTrack } from 'components/mixpanel';
import { selectActiveEntityType } from 'components/entity-type/active-entity-type.redux';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */

export const TOGGLE_REQUEST_MENU = 'video-request/toggle-request-menu';
export const SET_REQUEST_DURATION = 'video-request/set-request-duration';

export const POSTING_VIDEO_REQUEST = 'video-request/send-video-request';
export const VIDEO_REQUEST_POSTED = 'video-request/video-request-posted';
export const VIDEO_REQUEST_POST_FAILED =
  'video-request-sender/video-request-post-failed';

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

// selector for posting a video request
// this redux file is just responsible for posting the downstream video request
// the resulting custom-video-request event generated are managed through event and notification lifecycle,

export const maxDurationSelector: any = createSelector(
  fleetPropertiesSelector,
  isSupportUserSelector,
  (properties: any, isSupportUser: boolean) => {
    return isSupportUser
      ? 10
      : (properties && properties.custom_video_length) || 1;
  },
);

export const durationSelector = ({ videoRequestSender }) =>
  videoRequestSender.duration;
export const showRequestMenuSelector = ({ videoRequestSender }) =>
  videoRequestSender.showRequestMenu;

// select the range covered by a custom video request
// This range may be further trimmed to only overlap with trip time
// tslint:disable-next-line:prettier
export const videoRequestRangeSelector = createSelector<
  any,
  any,
  any,
  any,
  any,
  any
>(
  currentScrubTimeSelector,
  durationSelector,
  showRequestMenuSelector,
  (currentScrubTime, duration, showRequestMenu) => {
    // console.log({ showRequestMenu, duration, currentScrubTime });
    if (!showRequestMenu || !currentScrubTime) {
      return null;
    }
    // The range is the duration centered on the current scrub time
    return {
      min: currentScrubTime.clone().subtract(duration / 2, 'm'),
      max: currentScrubTime.clone().add(duration / 2, 'm'),
    };
  },
);

export const videoRequestSelector = createSelector(
  ({ videoRequestSender }) => videoRequestSender,
  maxDurationSelector,
  videoRequestRangeSelector,
  durationSelector,
  selectActiveEntityType,
  isNonSuperfleetAdminSelector,
  selectActiveEntityType,
  (
    videoRequestState: VideoRequestSenderReducer,
    maxDuration: number,
    range: { min: Moment; max: Moment },
    duration,
    entity,
    isNonSuperfleetAdmin,
    activeEntityType,
  ) => ({
    ...videoRequestState,
    maxDuration,
    range,
    duration,
    entity,
    isNonSuperfleetAdmin,
    activeEntityType,
  }),
);

// Given an open video request menu,
// highlight the state messages that will be covered in the video request
export const videoRequestGeoJSONSourceSelector = createSelector(
  tripPathSelector,
  videoRequestRangeSelector,
  selectScrubIndex,
  (tripPath, range: { min: Moment; max: Moment }, centerIndex) => {
    if (!tripPath || !range || centerIndex === -1) {
      return null;
    }

    const timeAt = (i: number) => tripPath[i].momentTime;

    // walk back from center index to find the earliest point in range
    let startIndex = centerIndex;
    while (startIndex >= 0 && timeAt(startIndex).isAfter(range.min)) {
      startIndex--;
    }
    // walk forward from center index to find the last point in range
    let endIndex = centerIndex;
    while (endIndex < tripPath.length && timeAt(endIndex).isBefore(range.max)) {
      endIndex++;
    }
    // slice out just the points in range
    const tripPointsInRequestRange = tripPath.slice(startIndex + 1, endIndex);

    // empty path GeoJSON will be invalid, so return a valid empty geoJSON instead
    if (!tripPointsInRequestRange.length) {
      return null;
    }
    return {
      type: 'geojson',
      data: pathToGeoJSONSourceData(tripPointsInRequestRange),
    };
  },
);

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

export const requestVideo = ({ min, max, isSuspectedCollision }) => (
  dispatch: (any) => void,
  getState,
) => {
  // get the pieces of redux state needed
  const {
    trips_data: { activeTrip },
  } = getState();
  dispatch(postVideoRequest(activeTrip.device, min, max, isSuspectedCollision));
};

/* tslint:disable:bool-param-default no-duplicate-string */
// Make the API call for a custom video request
export const postVideoRequest = (
  device: string,
  startTime: moment.Moment,
  endTime: moment.Moment,
  isSuspectedCollision?: boolean,
) => (dispatch: (any) => void) => {
  dispatch({ type: POSTING_VIDEO_REQUEST });

  const url = `${getServiceUrl()}/fleets/${getFleetId()}/video/custom-video-request`;
  const options = {
    method: 'POST',
    body: {
      device,
      start: startTime.valueOf(),
      end: endTime.valueOf(),
      suspected_collision: !!isSuspectedCollision,
    },
  };

  // make the request to the outbound message service
  // this triggers a downstream message to the nauto device
  return fetchRequest(url, options)
    .then((response: any) => {
      mpTrack('Video Request', { success: true });
      dispatch(addNotification(response.data));
      return dispatch({
        type: VIDEO_REQUEST_POSTED,
        payload: { ...response.data },
      });
    })
    .catch(error => {
      mpTrack('Video Request', { success: false, error });
      return dispatch({ type: VIDEO_REQUEST_POST_FAILED, payload: { error } });
    });
};

export const toggleRequestMenu = (show: boolean) => (dispatch: (any) => void) =>
  dispatch({ type: TOGGLE_REQUEST_MENU, payload: { show } });

export const setRequestDuration = (duration: number) => (
  dispatch: (any) => void,
) => dispatch({ type: SET_REQUEST_DURATION, payload: { duration } });
/* tslint:enable:bool-param-default no-duplicate-string */
/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */

export interface VideoRequestSenderReducer {
  postingVideoRequest: boolean;
  videoRequestError: string;
  showRequestMenu: boolean;
  // in minutes
  duration: number;
}

export const initialState: VideoRequestSenderReducer = {
  showRequestMenu: false,
  duration: 1,
  postingVideoRequest: false,
  videoRequestError: '',
};

export default (
  state = initialState,
  { type, payload },
): VideoRequestSenderReducer => {
  switch (type) {
    case POSTING_VIDEO_REQUEST:
      return {
        ...state,
        postingVideoRequest: true,
      };
    case VIDEO_REQUEST_POST_FAILED:
      // todo actually make sure something happens on a failed call
      return {
        ...state,
        postingVideoRequest: false,
        videoRequestError: payload.error,
      };
    case VIDEO_REQUEST_POSTED:
      return {
        ...state,
        postingVideoRequest: false,
        showRequestMenu: false,
      };
    case TOGGLE_REQUEST_MENU:
      return {
        ...state,
        showRequestMenu: payload.show,
      };
    case SET_REQUEST_DURATION:
      return {
        ...state,
        duration: payload.duration,
      };
    default:
      return state;
  }
};
