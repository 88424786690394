import React from 'react';
import { colors, Text, spaceSizesPx } from '@nauto/core';
import { createStructuredSelector } from 'reselect';
import {
  currentSuperfleetSelector,
  currentFleetIsSuperfleetSelector,
} from 'components/superfleets/superfleets.redux';
import { getFleetName } from 'components/fleets/utils';
import { FleetProps } from 'models/db';
import { Separator } from './styles';
import { connect } from 'react-redux';
import FleetLink, { FleetLinkTo } from 'components/fleet-link';
import { ROUTES } from 'routes/constants';
import styled from 'styled-components';
import {
  currentFleetSelector,
  isJapaneseFleetSelector,
} from 'components/auth/auth.reducer';
import { activeGroupSelector, Group } from 'components/groups/groups.redux';
import useFeatureFlags from 'hooks/use-feature-flags';
import { GLOBAL_MEDIA_QUERIES } from '@nauto/core';
import { useMedia } from 'react-media';
import { isReadOnlyEventsRoleSelector } from 'components/auth/auth.reducer';

interface Props {
  group: Group;
  isMultiFleetUser?: boolean;
  currentFleetIsSuperfleet: boolean;
  currentSuperfleet: FleetProps;
  currentFleet: FleetProps;
  isJapaneseFleet: boolean;
  isReadOnlyEvents: boolean;
  isSelectedNodeRoot: boolean;
}

interface LinkProps {
  to?: ROUTES | FleetLinkTo;
  shouldLink: boolean;
  children: any;
}

const LinkWrapper: React.SFC<LinkProps> = ({ to, shouldLink, children }) => {
  return shouldLink ? <FleetLink to={to}>{children}</FleetLink> : children;
};

export const FleetTitle: React.SFC<Props> = props => {
  const {
    currentFleetIsSuperfleet,
    currentSuperfleet,
    currentFleet,
    isJapaneseFleet,
    group,
    isReadOnlyEvents,
    isSelectedNodeRoot,
  } = props;
  const { newNavigationUi, newHome } = useFeatureFlags();
  const screenSizeMatches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const isMobileView = newNavigationUi && !!screenSizeMatches.MOBILE;

  const showSuperfleetName = !!currentSuperfleet;

  // If the customer has is japanese and not a superfleet user, do not show the fleet name
  const isJapaneseFleetUser = !currentSuperfleet && isJapaneseFleet;

  const showFleetName =
    currentFleet && !currentFleetIsSuperfleet && !isJapaneseFleetUser;

  const showSeparator = showFleetName && currentSuperfleet;

  const showFullElipsis = isMobileView && group;

  if (showFullElipsis) {
    return <OverflowText>...</OverflowText>;
  }

  const showCurrentFleet = newNavigationUi ? !isSelectedNodeRoot : true;

  return (
    <React.Fragment>
      {showSuperfleetName &&
        (isMobileView && showSeparator ? (
          <OverflowText>...</OverflowText>
        ) : (
          <>
            <LinkWrapper
              to={{
                pathname: ROUTES.SUPERFLEET_ASSESSMENT,
                fleetId: currentSuperfleet.id,
              }}
              shouldLink={!isReadOnlyEvents}
            >
              <OverflowText>{getFleetName(currentSuperfleet)}</OverflowText>
            </LinkWrapper>
            {newNavigationUi && group && (
              <FleetCount>{group.groups.length}</FleetCount>
            )}
          </>
        ))}

      {showSeparator && showCurrentFleet && <Separator />}
      {showFleetName && showCurrentFleet && (
        <>
          <LinkWrapper
            to={{
              pathname: newHome ? ROUTES.HOME : ROUTES.INSIGHTS,
              fleetId: currentFleet.id,
            }}
            shouldLink={!isReadOnlyEvents}
          >
            <OverflowText>{getFleetName(currentFleet)}</OverflowText>
          </LinkWrapper>
          {!showSuperfleetName && newNavigationUi && group && (
            <FleetCount>{group.groups.length}</FleetCount>
          )}
        </>
      )}
    </React.Fragment>
  );
};

const FleetCount = styled.span`
  background-color: ${colors.grey500};
  color: ${colors.white};
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${spaceSizesPx.sm};
  border-radius: 12px;
  min-width: 22px;
  height: 22px;
  padding: 2px;
`;

const OverflowText = styled(Text)`
  white-space: nowrap;
  color: ${colors.grey500};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: unset;
`;

export const selector = createStructuredSelector({
  isJapaneseFleet: isJapaneseFleetSelector,
  currentFleet: currentFleetSelector,
  currentSuperfleet: currentSuperfleetSelector,
  currentFleetIsSuperfleet: currentFleetIsSuperfleetSelector,
  group: activeGroupSelector,
  isReadOnlyEvents: isReadOnlyEventsRoleSelector,
});

export default connect(selector)(FleetTitle);
