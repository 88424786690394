import { createSelector } from 'reselect';
import { LDUser } from 'components/feature-flags/flag-selectors';
import { currentFleetSelector } from 'components/auth/auth.reducer';
import { activeGroupSelector } from 'components/groups/groups.redux';

// base selectors
const userSelector = ({ user }) => user.user;

const getDomain = (email: string): string =>
  email ? email.match(/@(.+)/)[0] : '';

export interface TrackingInfo {
  fleet_id: string;
  fleet_name: string;
  fleet_business_country: string;
  fleet_active_devices: string;
  user_id: string;
  user_email: string;
  user_email_domain: string;
  user_name: string;
  user_created: string;
  group_id: string;
  group_name: string;
}

export const TrackingPropertiesSelector = createSelector(
  activeGroupSelector,
  currentFleetSelector,
  userSelector,
  (group = {}, fleet = {}, user = {}): TrackingInfo => ({
    // fleet properties
    fleet_id: fleet.id,
    fleet_name: fleet.name,
    fleet_business_country: fleet.business_country,
    fleet_active_devices: fleet.active_devices,
    // user properties
    user_id: user.id,
    user_email: user.email,
    user_name: user.name,
    user_email_domain: getDomain(user.email),
    user_created: user.created,
    group_id: group && group.id,
    group_name: group && group.name,
  }),
);

export const LDUserSelector = createSelector(
  TrackingPropertiesSelector,
  (properties: TrackingInfo): LDUser => ({
    key: properties.user_id,
    email: properties.user_email,
    custom: {
      // fleet properties
      fleet_id: properties.fleet_id,
      fleet_name: properties.fleet_name,
      fleet_business_country: properties.fleet_business_country,
      fleet_active_devices: properties.fleet_active_devices,
      // user properties
      user_id: properties.user_id,
      user_email_domain: properties.user_email_domain,
    },
  }),
);

export interface MixPanelInfo {
  $fleet_id: string;
  $fleet_name: string;
  $fleet_business_country: string;
  $fleet_active_devices: string;
  $user_id: string;
  $user_name: string;
  $email: string;
  $user_email_domain: string;
  $user_created: string;
  $group_id: string;
  $group_name: string;
  $role: string;
}

export const mixpanelInitDataSelector = createSelector(
  TrackingPropertiesSelector,
  (properties: TrackingInfo): MixPanelInfo => ({
    $email: properties.user_email,
    // fleet properties
    $fleet_id: properties.fleet_id,
    $fleet_name: properties.fleet_name,
    $fleet_business_country: properties.fleet_business_country,
    $fleet_active_devices: properties.fleet_active_devices,
    // user properties
    $user_id: properties.user_id,
    $user_email_domain: properties.user_email_domain,
    $user_name: properties.user_name,
    $user_created: properties.user_created,
    $group_id: properties.group_id,
    $group_name: properties.group_name,
    $role: 'Undefined role',
  }),
);
