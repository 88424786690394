import React, { FC } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import FleetFeaturesContext from './context';
import { Features } from 'models/db';
import { DEFAULT_DATA } from 'components/feature/constants';

interface Props {
  features: Features;
}

const FleetFeaturesProvider: FC<Props> = ({ children, features }) => (
  <FleetFeaturesContext.Provider value={features}>
    {children}
  </FleetFeaturesContext.Provider>
);

const selector = createStructuredSelector({
  features: ({ fleetSettings: { fleet } }) =>
    (fleet && fleet.fleet_offering.features) || DEFAULT_DATA,
});

export default connect<Props, Record<string, unknown>, any>(selector)(
  FleetFeaturesProvider,
);
