import { lsSet, Keys, getUser } from 'utils/localstorage';
import { Middleware } from 'redux';
import cookie from 'cookie';
import { FleetAccess } from 'models/db';
import * as API from '@nauto/api';
import * as Sentry from '@sentry/browser';

/**
 * Sync current fleet id to localstorage
 */
export const syncCurrentFleet: Middleware = store => next => action => {
  const { payload, meta } = action;

  if (meta && meta.sync && payload && payload.currentFleet) {
    const fleets = store.getState().user.fleets || [];
    const currentFleet: FleetAccess =
      fleets.find(f => f.fleet.id === payload.currentFleet) || {};

    const { service_url = 'api.nauto.systems' } = currentFleet;

    lsSet(Keys.CURRENT_FLEET, payload.currentFleet);
    lsSet(Keys.CACHED_FLEET, payload.currentFleet);
    lsSet(Keys.CURRENT_FLEET_SERVICE_URL, service_url);
    API.setOptions({ url: service_url });
  }
  return next(action);
};

/**
 * Sync current group id to localstorage
 */
export const syncCurrentGroup: Middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.sync && payload && payload.activeGroupId) {
    lsSet(Keys.CURRENT_GROUP, payload.activeGroupId);
    lsSet(Keys.CACHED_GROUP, payload.activeGroupId);
  }
  return next(action);
};

/**
 * Sync current entity id to localstorage
 */
export const syncCurrentEntity: Middleware = store => next => action => {
  const { payload, meta } = action;
  const entity = payload && (payload.driverId || payload.vehicleId);

  if (meta && meta.sync && typeof entity === 'string') {
    lsSet(Keys.CURRENT_ENTITY, entity);
  }
  return next(action);
};

/**
 * Sync fleet data to localstorage
 */
export const syncFleets: Middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.sync && payload && payload.fleets) {
    lsSet(Keys.FLEETS, payload.fleets);
  }
  return next(action);
};

/**
 * Sync groups data to localstorage
 */
export const syncGroups: Middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.sync && payload && payload.rootGroup) {
    lsSet(Keys.GROUPS, payload.rootGroup);
  }
  return next(action);
};

/**
 * Sync drivers data to localstorage
 */
export const syncVehicles: Middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.sync && payload && payload.vehicles) {
    lsSet(Keys.VEHICLES, payload.vehicles);
  }
  return next(action);
};

/**
 * Sync drivers data to localstorage
 */
export const syncDrivers: Middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.sync && payload && payload.drivers) {
    lsSet(Keys.DRIVERS, payload.drivers);
  }
  return next(action);
};

/**
 * Sync user data to cookies
 */
export const syncUser: Middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.sync && payload && payload.user) {
    const currentUserData = getUser();
    const updatedUser = {
      ...currentUserData,
      ...payload.user,
    };

    try {
      Sentry.setUser({
        email: updatedUser.email,
      });
    } catch (error) {
      // null
    }

    lsSet(Keys.USER, updatedUser);
  }
  return next(action);
};

/**
 * Sync JWT token
 */
export const syncJwt: Middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.sync && payload && payload.token) {
    lsSet(Keys.TOKEN, payload.token);
  }
  return next(action);
};

/**
 * Sync user info for Zendesk cookie
 */
export const syncZendeskCookie: Middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.sync && payload && payload.user) {
    const { name, email } = payload.user;
    const zendesk = cookie.serialize('zendesk', `${name}:${email}`, {
      maxAge: 60 * 60 * 24 * 365,
    });
    document.cookie = zendesk;
  }
  return next(action);
};

/**
 * Sync user info to a cookie for SAML
 */
export const syncUserCookie: Middleware = store => next => action => {
  const { payload, meta } = action;
  const maxAge = 60 * 60 * 24 * 365;

  if (meta && meta.sync && payload && payload.user) {
    const { name, email } = payload.user;

    document.cookie = cookie.serialize('userName', name, {
      maxAge,
    });

    // tslint:disable-next-line: no-element-overwrite
    document.cookie = cookie.serialize('userEmail', email, {
      maxAge,
    });
  }
  return next(action);
};

/**
 * Sync global roles
 */
export const syncGlobalRoles: Middleware = store => next => action => {
  const { payload, meta } = action;
  if (meta && meta.sync && payload && payload.globalRoles) {
    lsSet(Keys.GLOBAL_ROLES, payload.globalRoles);
  }
  return next(action);
};
