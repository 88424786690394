import { QueryStatus } from 'react-query';
import * as API from '@nauto/api';
import useFleetData from 'hooks/use-fleet-data';
import useGroupData from 'hooks/use-group-data';
import { InputParams, QueryOpts, QueryParams } from './types';
import { useReportQuery } from './queries';
import { ScoreVersion } from 'utils/vera-score-utils';

export interface QueryResult {
  data: API.reports.ReportItem[];
  isLoading: boolean;
  status: QueryStatus;
}

type Params = InputParams & QueryOpts;

export type UseReports = (opts: Params) => QueryResult;

const useReports: UseReports = opts => {
  const {
    enabled = true,
    scoreOnly = false,
    id = '',
    entity,
    term,
    range,
    aggOnly = false,
  } = opts;
  const { id: fleetId } = useFleetData({ current: true });
  const { id: groupId } = useGroupData();

  const keys: QueryParams = {
    fleetId,
    groupId,
    entity,
    version: ScoreVersion.VERA3,
    term,
    range: { min: range.min.valueOf(), max: range.max.valueOf() },
    scoreOnly,
    id,
    aggOnly,
  };

  const query = useReportQuery(keys, {
    enabled,
  });

  return query;
};

export default useReports;
