/** how far back the user can lookup events from now */
export const MAX_DAYS = 30;
/** the default range on page load */
export const DEFAULT_DAYS = 30;
/** the default coaching range on page load */
export const DEFAULT_COACHING_DAYS = 7;
/** how many events are recieved on paginated fetched */
export const LIMIT = 30;

/** the severity levels */
export const SEVERITY_MIN = 1;
export const SEVERITY_MAX = 3;
export const SEVERITY_DEFAULT = 2;
