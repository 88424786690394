import React from 'react';
import GlobalContext from './global-context';
import { createStructuredSelector } from 'reselect';
import {
  isGlobalAdminSelector,
  isSupportUserSelector,
} from 'components/auth/auth.reducer';
import { connect } from 'react-redux';

interface Props {
  isGlobalUser: boolean;
  isSupportUser: boolean;
}

export const GlobalUserContextWrapper: React.SFC<Props> = props => {
  const { isGlobalUser, isSupportUser } = props;

  return (
    <GlobalContext.Provider value={{ isGlobalUser, isSupportUser }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

const selector = createStructuredSelector({
  isGlobalUser: isGlobalAdminSelector,
  isSupportUser: isSupportUserSelector,
});

export default connect(selector)(GlobalUserContextWrapper);
