export const DRIVER_QUERIES = {
  DRIVER: 'driver',
  DRIVERS: 'drivers',
  INFINITE: 'infinite-drivers',
  BY_IDS: 'drivers-by-id',
  EVENT_MEDIA: 'drivers-event-media',
};

export const VEHICLE_QUERIES = {
  VEHICLE: 'vehicle',
  VEHICLES: 'vehicles',
  INFINITE: 'infinite-vehicles',
};

export const ACTIVITY_QUERIES = {
  DRIVERS: 'driver-activities',
  VEHICLES: 'vehicle-activities',
  INFINITE_DRIVERS: 'infinite-driver-activities',
  INFINITE_VEHICLES: 'infinite-vehicle-activities',
};

export const FACE_QUERIES = {
  PAGINATED_FACES: 'paginated-faces',
};

export const COACHING_QUERIES = {
  DRIVER: 'driver-coaching',
  GROUP: 'group-coaching',
  DEFAULT_COMMENTARIES: 'default-commentaries',
  DRIVER_COUNTS: 'coaching-driver-counts',
  DRIVER_EVENTS: 'coaching-driver-events',
  COMMENTARIES: 'coaching-commentaries',
  HISTORICAL_SESSIONS: 'historical-sessions',
  POLICY_VIOLATIONS: 'policy-violations',
  EVENT_IDS: 'event-ids',
};

export const REPORT_QUERIES = {
  DRIVER: 'driver-reports',
  VEHICLE: 'vehicle-reports',
  GROUP: 'group-reports',
  TRIP_LOCATION_DATA: 'trip-location-data',
};

export const TRIP_QUERIES = {
  TRIP: 'trip',
  OVERVIEW: 'trip-overview',
};

export const CONTRACTS_QUERIES = {
  CONTRACTS: 'contracts',
  CONTRACT: 'contract',
};

export const VEDR_QUERIES = {
  DEVICE_ASSIGNMENT: 'device-assignment',
};

export const EVENT_QUERIES = {
  DEVICE_STRIKE: 'device-strike-events-count',
};
