import moment, { Moment } from 'moment';
import { getFleetId } from 'utils/localstorage';
import { createSelector } from 'reselect';
import { EntityType } from 'components/entity-type/entity-type-utils';
import {
  ReportRowData,
  ReportRowAPIData,
  DurationType,
  PeriodData,
} from 'components/reports/types';
import { cleanPeriodData } from 'components/reports/reports.redux';
import { VeraVersionType } from 'utils/vera-score-utils';
import {
  collisionEventTypeSelector,
  veraVersionTypeSelector,
} from 'components/auth/auth.reducer';
import { EventType } from 'components/events/event-types';
import * as API from '@nauto/api';
export enum ACTIONS {
  FETCHING_REPORT = 'driver-report/fetching-report',
  CLEAR_REPORT = 'driver-report/clear-report',
  REPORT_FETCHED = 'driver-report/report-fetched',
  REPORT_FETCH_FAILED = 'driver-report/report-fetched-failed',
}

export interface TableSelectorProps {
  activeEntityType: EntityType;
  tableData: ReportRowData[];
  fetchingReport: boolean;
  fetchFailed: boolean;
  range: Range;
  maxUTCDayBucketTime: Moment;
}

/**
 * ----------------------------------------------------------------------------
 * Selectors
 * ----------------------------------------------------------------------------
 */

interface ReduxState {
  driver: ReportReducer;
}

export const driverReportSelector = ({
  driver,
}: ReduxState): { [key: string]: ReportRowAPIData } => driver.reportData;

export const driverWeeklyReportSelector = createSelector(
  driverReportSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    report: { [key: string]: ReportRowAPIData },
    collisionEventType: EventType,
    veraVersionType: VeraVersionType,
  ): PeriodData[] => {
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    const speedingType = isScoreVersionVera3 ? 'posted-speeding' : 'speeding';
    return (report[DurationType.Weekly].weekly_score || []).map(data => ({
      ...data,
      ...cleanPeriodData(data, collisionEventType, speedingType),
    }));
  },
);

export const driverDailyReportSelector = createSelector(
  driverReportSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    report: { [key: string]: ReportRowAPIData },
    collisionEventType: EventType,
    veraVersionType: VeraVersionType,
  ): PeriodData[] => {
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    const speedingType = isScoreVersionVera3 ? 'posted-speeding' : 'speeding';
    return (report[DurationType.Daily].daily_score || []).map(data => ({
      ...data,
      ...cleanPeriodData(data, collisionEventType, speedingType),
    }));
  },
);

export const driverMonthlyReportSelector = createSelector(
  driverReportSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    report: { [key: string]: ReportRowAPIData },
    collisionEventType: EventType,
    veraVersionType: VeraVersionType,
  ): PeriodData[] => {
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    const speedingType = isScoreVersionVera3 ? 'posted-speeding' : 'speeding';
    return (report[DurationType.Monthly].monthly_score || []).map(data => ({
      ...data,
      ...cleanPeriodData(data, collisionEventType, speedingType),
    }));
  },
);

export const fetchingDriverReportSelector = ({ driver }: ReduxState): boolean =>
  driver.fetchingReport;
export const driverReportFetchFailed = ({ driver }: ReduxState): boolean =>
  driver.fetchFailed;

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

// clear report data
export const clearDriverReport = () => (dispatch: (any) => void) => {
  dispatch({ type: ACTIONS.CLEAR_REPORT });
};

/* Score/Event count lookup by one driver */
export const getDriverReport = (
  { min, max }: Range,
  driverId: string,
  type = DurationType.Weekly,
  isScoreVersionVera3 = false,
  isLoggedInUserTypeDriver = false,
  groupId?: string,
) => (dispatch: (any) => void, getState): Promise<any> => {
  const {
    groups: { activeGroupId },
  } = getState();
  dispatch({ type: ACTIONS.FETCHING_REPORT, payload: { key: type } });

  const request = isLoggedInUserTypeDriver
    ? (API.driverLogin.getDriverScoreReport({
        min: min.valueOf(),
        max: max.valueOf(),
        driverId: driverId,
        isScoreVersionVera3,
        type,
      }) as Promise<API.drivers.DriversResponse>)
    : (API.drivers.getDriverReport({
        min: min.valueOf(),
        max: max.valueOf(),
        driverId: driverId,
        groupId: groupId || activeGroupId,
        fleetId: getFleetId(),
        isScoreVersionVera3,
        type,
      }) as Promise<API.drivers.DriversResponse>);

  return request
    .then(resp => {
      return dispatch({
        type: ACTIONS.REPORT_FETCHED,
        payload: {
          data: resp.data && resp.data[0],
          key: type,
        },
      });
    })
    .catch(error => {
      dispatch({ type: ACTIONS.REPORT_FETCH_FAILED, payload: error });
      console.log('error', error);
    });
};

export const getAllFleetAverageScore = ({
  driverId,
  range: { min, max },
  type = DurationType.Weekly,
  isScoreVersionVera3,
}: {
  driverId: string;
  range: Range;
  type?: DurationType;
  isScoreVersionVera3?: boolean;
}): Promise<number> => {
  return API.driverLogin
    .getAllFleetAverageScore({
      driverId,
      range: { min: min.valueOf(), max: max.valueOf() },
      type,
      isScoreVersionVera3,
    })
    .then(resp => resp?.data?.[0]?.agg_score?.risk_scores?.risk_score);
};

/**
 * ----------------------------------------------------------------------------
 * Reducer
 * ----------------------------------------------------------------------------
 */

export interface Range {
  min: moment.Moment;
  max: moment.Moment;
}

export interface ReportReducer {
  reportData: { [key: string]: ReportRowAPIData };
  fetchingReport: boolean;
  fetchFailed: boolean;
}

export const initialState: ReportReducer = {
  reportData: {
    [DurationType.Daily]: {},
    [DurationType.Weekly]: {},
    [DurationType.Monthly]: {},
  },
  fetchingReport: false,
  fetchFailed: false,
};

export default (state = initialState, { type, payload }): ReportReducer => {
  switch (type) {
    case ACTIONS.FETCHING_REPORT:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          [payload.key]: {},
        },
        fetchFailed: initialState.fetchFailed,
        fetchingReport: true,
      };
    case ACTIONS.REPORT_FETCH_FAILED:
      return {
        ...state,
        fetchingReport: false,
        fetchFailed: true,
      };
    case ACTIONS.REPORT_FETCHED: {
      return {
        ...state,
        fetchingReport: false,
        reportData: {
          ...state.reportData,
          [payload.key]: payload.data ? payload.data : {},
        },
      };
    }
    case ACTIONS.CLEAR_REPORT:
      return initialState;
    default:
      return state;
  }
};
