export const ERROR_CODES = {
  ['ERR_ALREADY_EXISTS_PHONE_NUMBER']: {
    code: 'ERR_ALREADY_EXISTS_PHONE_NUMBER',
    message: (t: any, param?: any) =>
      t(
        'This number is already used in our system. Please use your own cellphone number. We use it if you subscribe to SMS alerts.',
      ),
  },
  ['ERR_MALFORMED_REQ_PARAM_PHONE_NUMBER']: {
    code: 'ERR_MALFORMED_REQ_PARAM_PHONE_NUMBER',
    message: (t: any, param?: any) =>
      t('Phone number {{phoneNumber}} is used by another user', param),
  },
  ['ERROR_DRIVER_NAME_EMAIL_EXISTS']: {
    code: 'ERROR_DRIVER_NAME_EMAIL_EXISTS',
    message: (t: any, param?: any) =>
      t('Driver with this name or email already exists'),
  },
  ['ERROR_VEHICLE_NOT_FOUND']: {
    code: 'ERROR_VEHICLE_NOT_FOUND',
    message: (t: any, param?: any) => t('Vehicle not found'),
  },
  ['UPLOAD_RMA_NOTES_ERROR']: {
    code: 'UPLOAD_RMA_NOTES_ERROR',
    message: (t: any, param?: any) =>
      t('An error has occurred uploading the RMA Notes'),
  },
  ['ERROR_FACE_NOT_FOUND']: {
    code: 'ERROR_FACE_NOT_FOUND',
  },
  ['ERROR_TOKEN_NOT_ACTIVE']: {
    code: 'ERROR_TOKEN_NOT_ACTIVE',
  },
  ['ERROR_NO_INVITE']: {
    code: 'ERROR_NO_INVITE',
  },
  ['ERROR_INVITATION_ACCEPTED']: {
    code: 'ERROR_INVITATION_ACCEPTED',
  },
  ['DEFAULT_ERROR']: {
    code: 'DEFAULT_ERROR',
    message: (t: any, param?: any) => t('An error occurred'),
  },
  ['FETCHING_ERROR']: {
    code: 'FETCHING_ERROR',
    message: (t: any, param?: any) => t('An error occurred while fetching'),
  },
};
