import { Permissions } from 'hooks/use-permissions';

export const showCoachingRoute = ({
  newRoleManagementPermission,
  newCoachingFeatureFlag,
  hasPermission,
}: {
  newRoleManagementPermission: boolean;
  newCoachingFeatureFlag: boolean;
  hasPermission: (
    featureFlag: Permissions,
    value?: 'no-access' | 'read-only' | 'full-access' | true,
  ) => boolean;
}): boolean => {
  const hasCoachingAccess =
    (hasPermission(Permissions.Coaching, true) ||
      hasPermission(Permissions.CoachingPackage, true)) &&
    newCoachingFeatureFlag;

  const hasCoachingRole =
    newRoleManagementPermission &&
    hasCoachingAccess &&
    (hasPermission(Permissions.CustomCoaching, 'full-access') ||
      hasPermission(Permissions.CustomCoaching, 'read-only'));

  return newRoleManagementPermission ? hasCoachingRole : hasCoachingAccess;
};
