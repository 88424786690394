import React from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import { connect, DispatchProp } from 'react-redux';
import {
  logout,
  updateUser,
  setLoginRedirect,
} from 'components/auth/auth.actions';
import { lsSet, Keys, getUser, getLoggedInUserType } from 'utils/localstorage';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as API from '@nauto/api';
import * as Sentry from '@sentry/browser';

interface Props<P> extends DispatchProp<P>, WithTranslation {
  history?: History;
  location?: Location;
}

@withRouter
class Interceptor<P> extends React.Component<Props<P>, any> {
  componentDidMount() {
    const { t, dispatch, history, location } = this.props;
    const fetchCopy: any = window.fetch;
    const ourFetch = (window.fetch = (...args) => {
      const pageName =
        typeof args[0] === 'string' ? args[0] : 'external request';

      const user = getUser();
      const userType = getLoggedInUserType();
      Sentry.setUser({
        email: user?.email,
        userType,
      });

      const ALLOWED_APIS = [
        '/reports/',
        '/drivers',
        '/vehicles',
        'coaching/count',
        'coaching/sessions',
      ];
      let transaction, span;
      if (ALLOWED_APIS.includes(pageName)) {
        transaction = Sentry.startTransaction({ name: pageName });
        Sentry.getCurrentHub().configureScope(scope => {
          scope.setSpan(transaction);
        });
        span = transaction.startChild({
          op: 'http',
          description: pageName,
          data: args[1] && {
            method: args[1].method,
          },
        });
      }
      return fetchCopy(...args)
        .then(res => {
          if (res.status === 401) {
            const token = res.headers.get('x-nauto-auth-token');
            if (token) {
              lsSet(Keys.TOKEN, token);
              dispatch(updateUser({ token }));
              const [url, options] = args;
              options.headers.Authorization = `Bearer ${token}`;
              return ourFetch(url, options);
            } else {
              dispatch(
                setLoginRedirect(
                  `${location.pathname}${location.search}`,
                  t(
                    'Access prohibited, please contact support if you continue to see this error.',
                  ),
                ),
              );
              dispatch(logout());
              history.replace('/login');
            }
          }
          span?.setStatus(res.status);
          span?.finish();
          transaction?.finish();
          return res;
        })
        .catch(error => {
          span?.setStatus(error.statusCode);
          console.log(error);
          span?.finish();
          transaction?.finish();
        });
    });

    API.setOptions({ fetch: ourFetch });
  }

  render() {
    return null;
  }
}

export default connect()(withTranslation()(Interceptor));
