import React from 'react';
import { colors } from '@nauto/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import FleetLink from 'components/fleet-link';
import { ROUTES } from 'routes/constants';
import { OrganizationIcon } from '@nauto/icons';
import { isMultiFleetUserSelector } from 'components/auth/auth.reducer';
import styled from 'styled-components';

interface Props {
  isMultiFleetUser: boolean;
}

const MultiFleetIconWrapper = styled.div`
  border-radius: 1000px;
  background: ${({ theme }) => theme.colors.brand};
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media print {
    display: none;
  }
`;

export const MultiFleetListLink: React.SFC<Props> = props => {
  const { isMultiFleetUser } = props;

  return (
    isMultiFleetUser && (
      <FleetLink to={ROUTES.FLEETS}>
        <MultiFleetIconWrapper>
          <OrganizationIcon color={colors.white} size="sm" />
        </MultiFleetIconWrapper>
      </FleetLink>
    )
  );
};

export const selector = createStructuredSelector({
  isMultiFleetUser: isMultiFleetUserSelector,
});

export default connect(selector)(MultiFleetListLink);
