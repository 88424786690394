import React from 'react';
import * as API from '@nauto/api';
import I18nEventString from './i18n-event-string';
import { eventDisplayMap } from 'components/events/event-types';
import {
  hasForwardCollisionWarning,
  hasPedestrianCollisionWarning,
} from 'components/events/utils';

export interface Props {
  type: nauto.EventType;
  event?: API.events.Event;
}

export class I18nEventTypeToString extends React.Component<Props> {
  render() {
    const { type, event } = this.props;

    const isPcwEvent = event && hasPedestrianCollisionWarning(event);
    const isFcwEvent = event && hasForwardCollisionWarning(event);

    return (
      <I18nEventString
        type={
          isPcwEvent
            ? 'pcw'
            : isFcwEvent
            ? 'fcw'
            : eventDisplayMap[type]
            ? eventDisplayMap[type].name
            : eventDisplayMap.default.name
        }
      />
    );
  }
}

export default I18nEventTypeToString;
