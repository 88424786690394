import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { Commentary } from 'components/coaching-v3/types';
import { UnifiedCoachingLabels } from '@nauto/api';
import { EViolations } from 'components/driver/coaching-commentary/policy-violations-banner';

interface CoachingCommentaryItems {
  position: number;
  label: string;
  q: { label: string; value: boolean; qid: string }[];
}

interface CoachingCommentaryHeaders {
  index: number;
  label: string;
  items: CoachingCommentaryItems[];
}

export interface CoachingSession {
  events: { eventId: string; eventType: UnifiedCoachingLabels }[];
  session: {
    coaching_commentary: { headers: CoachingCommentaryHeaders }[];
    custom_notes: string;
    template_id: string;
  };
}

const CoachSessionsContext = createContext<{
  highRiskSessions: CoachingSession[];
  nonHighRiskSessions: CoachingSession[];
  coachingSessionsCount: number;
  showEndSessionModal: boolean;
  setHighRiskSessions: React.Dispatch<React.SetStateAction<CoachingSession[]>>;
  setNonHighRiskSession: React.Dispatch<
    React.SetStateAction<CoachingSession[]>
  >;
  coachedViolations: EViolations[];
  setCoachedViolations: React.Dispatch<React.SetStateAction<EViolations[]>>;
  setShowEndSessionModal: React.Dispatch<React.SetStateAction<boolean>>;
}>(null);

const CoachSessionContext = createContext<CoachingNoteState>(null);
const CoachSessionDispatchContext = createContext(null);

export const useCoachingSessions = () => useContext(CoachSessionsContext);
export const useCommentarySessions = () => useContext(CoachSessionContext);
export const useCommentarySessionsDispatch = () =>
  useContext(CoachSessionDispatchContext);

export const CoachSessionProvider = ({ children }) => {
  const [highRiskSessions, setHighRiskSessions] = useState<CoachingSession[]>(
    [],
  );
  const [nonHighRiskSessions, setNonHighRiskSession] = useState<
    CoachingSession[]
  >([]);
  const [coachingSessionsCount, setCoachingCount] = useState(0);
  const [coachedViolations, setCoachedViolations] = useState<EViolations[]>([]);
  const [showEndSessionModal, setShowEndSessionModal] = useState(false);

  useEffect(() => {
    const count = nonHighRiskSessions.length + highRiskSessions.length;
    setCoachingCount(count);
  }, [nonHighRiskSessions, highRiskSessions]);

  const coachingSessionsValue = useMemo(
    () => ({
      highRiskSessions,
      nonHighRiskSessions,
      coachingSessionsCount,
      showEndSessionModal,
      setHighRiskSessions,
      setNonHighRiskSession,
      coachedViolations,
      setCoachedViolations,
      setShowEndSessionModal,
    }),
    [
      highRiskSessions,
      nonHighRiskSessions,
      coachingSessionsCount,
      showEndSessionModal,
      setHighRiskSessions,
      setNonHighRiskSession,
      coachedViolations,
      setCoachedViolations,
      setShowEndSessionModal,
    ],
  );

  const [session, dispatch] = useReducer(
    coachingnotesReducer,
    coachingNoteInitialState,
  );

  return (
    <CoachSessionsContext.Provider value={coachingSessionsValue}>
      <CoachSessionContext.Provider value={session}>
        <CoachSessionDispatchContext.Provider value={dispatch}>
          {children}
        </CoachSessionDispatchContext.Provider>
      </CoachSessionContext.Provider>
    </CoachSessionsContext.Provider>
  );
};

export interface CoachingNoteState {
  coaching_commentary: Commentary[];
  custom_notes: string;
  template_id: string;
}

export const coachingNoteInitialState: CoachingNoteState = {
  coaching_commentary: [],
  custom_notes: '',
  template_id: '',
};

export enum CoachingNotesActionTypes {
  SET_DEFAULT_COMMENTARIES = 'SET_DEFAULT_COMMENTARIES',
  SET_CUSTOM_NOTES = 'SET_CUSTOM_NOTES',
  SET_COACHING_COMMENTARY = 'SET_COACHING_COMMENTARY',
}

export type ActionSetCustomNotes = {
  type: CoachingNotesActionTypes.SET_CUSTOM_NOTES;
  payload: CoachingNoteState;
};

export type ActionDefaultCommentaries = {
  type: CoachingNotesActionTypes.SET_DEFAULT_COMMENTARIES;
  payload: CoachingNoteState;
};

export type ActionSetCoachingCommentary = {
  type: CoachingNotesActionTypes.SET_COACHING_COMMENTARY;
  payload: {
    index: number;
    headerLabel: string;
    itemLabel: string;
    q: { label: string; value: boolean; qid: string }[];
  };
};

export type Action =
  | ActionSetCustomNotes
  | ActionDefaultCommentaries
  | ActionSetCoachingCommentary;

export const coachingnotesReducer = (
  state: CoachingNoteState,
  action: Action,
): CoachingNoteState => {
  switch (action.type) {
    case CoachingNotesActionTypes.SET_DEFAULT_COMMENTARIES:
      return action.payload;
    case CoachingNotesActionTypes.SET_COACHING_COMMENTARY:
      // eslint-disable-next-line no-case-declarations
      const newCoachingCommentary = state.coaching_commentary.map(
        (item, index) => {
          if (index === action.payload.index) {
            return {
              headers: item.headers.map(header => {
                if (header.label === action.payload.headerLabel) {
                  return {
                    ...header,
                    items: header.items.map(headerItem => {
                      if (headerItem.label === action.payload.itemLabel) {
                        return {
                          ...headerItem,
                          q: action.payload.q,
                        };
                      }
                      return headerItem;
                    }),
                  };
                }
                return header;
              }),
            };
          }
          return item;
        },
      );
      return { ...state, coaching_commentary: newCoachingCommentary };
    default:
      return state;
  }
};
