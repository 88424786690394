import moment, { Moment } from 'moment';
import { getFleetId } from 'utils/localstorage';
import { languageIsJapanese } from 'utils/helpers';
import { TYPE } from 'constants/events';
import { createStructuredSelector, createSelector } from 'reselect';
import { selectActiveEntityType } from 'components/entity-type/active-entity-type.redux';
import {
  EntityType,
  SubFleetsEntityType,
} from 'components/entity-type/entity-type-utils';
import {
  currentFleetSelector,
  atRiskVeraThresholdSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  isJapaneseFleetSelector,
} from 'components/auth/auth.reducer';
import { fleetFeaturesSelector } from 'components/settings/settings.redux';
import {
  ReportRowData,
  ReportRowAPIData,
  PeriodData,
  DurationType,
} from './types';
import {
  isAtRisk,
  currentWeekData,
  isTopPerformer,
  isSignificantlyImproved,
  isSignificantlyDeclined,
  isNewEntity,
  getVeraScore,
  isValidScore,
  compareLatestVera,
  previousWeekData,
} from 'utils/report-utils';
import {
  activeGroupIdSelector,
  activeGroupSelector,
  Group,
  activeGroupHasNamedChildrenSelector,
  indexedGroupsSelector,
} from 'components/groups/groups.redux';
import { Range } from 'utils/date-ranges';
import * as API from '@nauto/api';
import { ScoreVersion, VeraVersionType } from 'utils/vera-score-utils';
import {
  getCleanReportsData,
  cleanPeriodData,
  calculateMaxUTCDayBucketTime,
} from './utils';

export * from './utils';

export const FETCHING_REPORT = 'reports/fetching-report';
export const REPORT_FETCHED = 'reports/report-fetched';
export const REPORT_FETCH_FAILED = 'reports/report-fetched-failed';
export const FETCHING_UNASSIGNED_HOURS = 'reports/fetching-unassigned-hours';
export const UNASSIGNED_HOURS_FETCHED = 'reports/unassigned-hours-fetched';
export const UNASSIGNED_HOURS_FETCH_FAILED =
  'reports/unassigned-hours-fetched-failed';
export const CLEAR_REPORT = 'reports/clear-report';

export const FETCHING_GROUP_REPORT = 'reports/fetching-group-report';
export const GROUP_REPORT_FETCHED = 'reports/group-report-fetched';
export const GROUP_REPORT_FETCH_FAILED = 'reports/group-report-fetched-failed';

export const FETCHING_INDUSTRY_AVERAGE_SCORE =
  'reports/fetching-industry-average-score';
export const INDUSTRY_AVERAGE_SCORE_FETCHED =
  'reports/industry-average-score-fetched';
export const FETCHING_INDUSTRY_AVERAGE_SCORE_FAILED =
  'reports/fetching-industry-average-score-failed';

export const msPerHour = 3600000;
const msToHours = (milliseconds: number) => milliseconds / msPerHour;

/**
 * ----------------------------------------------------------------------------
 * Selectors
 * ----------------------------------------------------------------------------
 */

const groupDailyDataSelector = ({ reports }): ReportRowAPIData[] =>
  reports.groupReportData[DurationType.Daily];
const groupWeeklyDataSelector = ({ reports }): ReportRowAPIData[] =>
  reports.groupReportData[DurationType.Weekly];
const groupMonthlyDataSelector = ({ reports }): ReportRowAPIData[] =>
  reports.groupReportData[DurationType.Monthly];

export const fetchingReportSelector = createSelector(
  ({ reports }) => reports.fetchingReport,
  fetchingReport => fetchingReport,
);

export const fetchingFailedSelector = createSelector(
  ({ reports }) => reports.fetchFailed,
  fetchFailed => fetchFailed,
);

export const reportRowsSelector = createSelector(
  ({ reports }) => reports.reportData,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    reportData: ReportRowAPIData[],
    collisionEventType = languageIsJapanese()
      ? TYPE.CONFIRMED_COLLISION_VERA2
      : TYPE.CONFIRMED_COLLISION_VERA3,
    veraVersionType,
  ): ReportRowData[] => {
    if (!reportData) {
      return [];
    }
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;

    return getCleanReportsData(
      reportData,
      null,
      isScoreVersionVera3,
      collisionEventType,
    );
  },
);

export const newReportRowsSelector = createSelector(
  ({ reports }) => reports.reportData,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    reportData: ReportRowAPIData[],
    collisionEventType,
    veraVersionType,
  ): ReportRowData[] => {
    if (!reportData) {
      return [];
    }
    return reportData;
  },
);

export const reportSubFleetsRowsSelector = createSelector(
  groupDailyDataSelector,
  indexedGroupsSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    groupReportData: ReportRowAPIData[],
    indexedGroups,
    collisionEventType = languageIsJapanese()
      ? TYPE.CONFIRMED_COLLISION_VERA2
      : TYPE.CONFIRMED_COLLISION_VERA3,
    veraVersionType,
  ): ReportRowData[] => {
    if (!groupReportData) {
      return [];
    }
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    return getCleanReportsData(
      groupReportData,
      indexedGroups,
      isScoreVersionVera3,
      collisionEventType,
    );
  },
);

export const activeGroupDailyDataSelector = createSelector(
  groupDailyDataSelector,
  activeGroupIdSelector,
  (
    groupDataArray: ReportRowAPIData[],
    activeGroupId: string,
  ): ReportRowAPIData =>
    groupDataArray &&
    groupDataArray.length &&
    groupDataArray.find(
      (groupData: ReportRowAPIData) => groupData.group_id === activeGroupId,
    ),
);

export const activeGroupWeeklyDataSelector = createSelector(
  groupWeeklyDataSelector,
  activeGroupIdSelector,
  (
    groupDataArray: ReportRowAPIData[],
    activeGroupId: string,
  ): ReportRowAPIData =>
    groupDataArray &&
    groupDataArray.length &&
    groupDataArray.find(
      (groupData: ReportRowAPIData) => groupData.group_id === activeGroupId,
    ),
);

export const activeGroupMonthlyDataSelector = createSelector(
  groupMonthlyDataSelector,
  activeGroupIdSelector,
  (
    groupDataArray: ReportRowAPIData[],
    activeGroupId: string,
  ): ReportRowAPIData =>
    groupDataArray &&
    groupDataArray.length &&
    groupDataArray.find(
      (groupData: ReportRowAPIData) => groupData.group_id === activeGroupId,
    ),
);

export const childGroupDataSelector = createSelector(
  groupWeeklyDataSelector,
  activeGroupSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    groupDataArray: ReportRowAPIData[],
    activeGroup: Group,
    collisionEventType = languageIsJapanese()
      ? TYPE.CONFIRMED_COLLISION_VERA2
      : TYPE.CONFIRMED_COLLISION_VERA3,
    veraVersionType: VeraVersionType,
  ): ReportRowAPIData[] => {
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    const speedingType = isScoreVersionVera3 ? 'posted-speeding' : 'speeding';

    return groupDataArray && groupDataArray.length
      ? (activeGroup.groups || []).map(group => {
          const reportData = groupDataArray.find(d => d.group_id === group.id);
          return {
            ...reportData,
            id: group.id,
            group,
            weekly_score:
              reportData &&
              reportData.weekly_score &&
              reportData.weekly_score.map(data => ({
                ...data,
                ...cleanPeriodData(data, collisionEventType, speedingType),
              })),
          };
        })
      : [];
  },
);

export const groupDailyReportSelector = createSelector(
  activeGroupDailyDataSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    activeGroupData: ReportRowAPIData,
    collisionEventType = languageIsJapanese()
      ? TYPE.CONFIRMED_COLLISION_VERA2
      : TYPE.CONFIRMED_COLLISION_VERA3,
    veraVersionType: VeraVersionType,
  ): PeriodData[] => {
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    const speedingType = isScoreVersionVera3 ? 'posted-speeding' : 'speeding';
    if (!activeGroupData) {
      return [];
    }
    return activeGroupData.daily_score.map(data => ({
      ...data,
      ...cleanPeriodData(data, collisionEventType, speedingType),
    }));
  },
);

export const groupWeeklyReportSelector = createSelector(
  activeGroupWeeklyDataSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    activeGroupData: ReportRowAPIData,
    collisionEventType = languageIsJapanese()
      ? TYPE.CONFIRMED_COLLISION_VERA2
      : TYPE.CONFIRMED_COLLISION_VERA3,
    veraVersionType: VeraVersionType,
  ): PeriodData[] => {
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    const speedingType = isScoreVersionVera3 ? 'posted-speeding' : 'speeding';
    if (!activeGroupData) {
      return [];
    }

    return (
      activeGroupData.weekly_score &&
      activeGroupData.weekly_score.map(data => ({
        ...data,
        ...cleanPeriodData(data, collisionEventType, speedingType),
      }))
    );
  },
);

export const groupMonthlyReportSelector = createSelector(
  activeGroupMonthlyDataSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    activeGroupData: ReportRowAPIData,
    collisionEventType = languageIsJapanese()
      ? TYPE.CONFIRMED_COLLISION_VERA2
      : TYPE.CONFIRMED_COLLISION_VERA3,
    veraVersionType: VeraVersionType,
  ): PeriodData[] => {
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    const speedingType = isScoreVersionVera3 ? 'posted-speeding' : 'speeding';
    if (!activeGroupData) {
      return [];
    }
    return activeGroupData.monthly_score.map(data => ({
      ...data,
      ...cleanPeriodData(data, collisionEventType, speedingType),
    }));
  },
);

export const groupWeeklyAverageScoreSelector = createSelector(
  groupWeeklyReportSelector,
  (activeGroupData: PeriodData[]): number => {
    return getVeraScore(
      activeGroupData && activeGroupData[activeGroupData.length - 1],
    );
  },
);

export const validScoreSelector = (
  reportRows: ReportRowData[],
): ReportRowData[] =>
  reportRows
    .filter(row => isValidScore(getVeraScore(currentWeekData(row))))
    .sort(compareLatestVera);

export const validWeeklySubfleetScoreSelector = createSelector(
  childGroupDataSelector,
  (weeklyGroupData: any) => validScoreSelector(weeklyGroupData),
);

export const validWeeklyScoreSelector = createSelector(
  reportRowsSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    validScoreSelector(reportRows),
);

export const rawValidWeeklyScoreSelector = createSelector(
  ({ reports }) => reports.reportData,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    validScoreSelector(reportRows),
);

export const validWeeklyReportScoreSelector = createSelector(
  newReportRowsSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    validScoreSelector(reportRows),
);

export const atRiskSelector = createSelector(
  rawValidWeeklyScoreSelector,
  atRiskVeraThresholdSelector,
  (reportRows: ReportRowData[], atRiskVeraThreshold: number): ReportRowData[] =>
    reportRows.filter(row =>
      isAtRisk(currentWeekData(row), atRiskVeraThreshold),
    ),
);

export const atRiskDriversReportSelector = createSelector(
  rawValidWeeklyScoreSelector,
  atRiskVeraThresholdSelector,
  collisionEventTypeSelector,
  veraVersionTypeSelector,
  (
    reportRows: ReportRowData[],
    atRiskVeraThreshold: number,
    collisionEventType = languageIsJapanese()
      ? TYPE.CONFIRMED_COLLISION_VERA2
      : TYPE.CONFIRMED_COLLISION_VERA3,
    veraVersionType,
  ): ReportRowData[] => {
    if (!reportRows) {
      return [];
    }
    const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;
    const drivers = reportRows.filter(row =>
      isAtRisk(currentWeekData(row), atRiskVeraThreshold),
    );
    if (!drivers.length) {
      return [];
    }
    return getCleanReportsData(
      drivers,
      null,
      isScoreVersionVera3,
      collisionEventType,
    );
  },
);

export const previousWeekAtRiskSelector = createSelector(
  validWeeklyScoreSelector,
  atRiskVeraThresholdSelector,
  (reportRows: ReportRowData[], atRiskVeraThreshold: number): ReportRowData[] =>
    reportRows.filter(row =>
      isAtRisk(previousWeekData(row), atRiskVeraThreshold),
    ),
);

export const topPerformersSelector = createSelector(
  rawValidWeeklyScoreSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    reportRows.filter(row => isTopPerformer(currentWeekData(row))),
);

export const significantlyImprovedSelector = createSelector(
  rawValidWeeklyScoreSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    reportRows.filter(row => isSignificantlyImproved(row)),
);

export const significantlyDeclinedSelector = createSelector(
  rawValidWeeklyScoreSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    reportRows.filter(row => isSignificantlyDeclined(row)),
);

export const atRiskSubfleetSelector = createSelector(
  validWeeklySubfleetScoreSelector,
  atRiskVeraThresholdSelector,
  (reportRows: ReportRowData[], atRiskVeraThreshold: number): ReportRowData[] =>
    reportRows.filter(row =>
      isAtRisk(currentWeekData(row), atRiskVeraThreshold),
    ),
);

export const previousWeekAtRiskSubfleetSelector = createSelector(
  validWeeklySubfleetScoreSelector,
  atRiskVeraThresholdSelector,
  (reportRows: ReportRowData[], atRiskVeraThreshold: number): ReportRowData[] =>
    reportRows.filter(row =>
      isAtRisk(previousWeekData(row), atRiskVeraThreshold),
    ),
);

export const topPerformingSubfleetSelector = createSelector(
  validWeeklySubfleetScoreSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    reportRows.filter(row => isTopPerformer(currentWeekData(row))),
);

export const significantlyImprovedSubfleetSelector = createSelector(
  validWeeklySubfleetScoreSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    reportRows.filter(row => isSignificantlyImproved(row)),
);

export const significantlyDeclinedSubfleetSelector = createSelector(
  validWeeklySubfleetScoreSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    reportRows.filter(row => isSignificantlyDeclined(row)),
);

export const newEntitySelector = createSelector(
  reportRowsSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    reportRows.filter(row => isNewEntity(row)),
);

export const newEntityReportSelector = createSelector(
  newReportRowsSelector,
  (reportRows: ReportRowData[]): ReportRowData[] =>
    reportRows.filter(row => isNewEntity(row)),
);

export const selectMaxUTCDayBucketTime = createSelector(
  currentFleetSelector,
  fleet => calculateMaxUTCDayBucketTime(fleet.timezone_name),
);

export const rangeSelector = createStructuredSelector({
  range: ({ reports }) => reports.range,
  maxUTCDayBucketTime: selectMaxUTCDayBucketTime,
});

export interface TableSelectorProps {
  activeEntityType: EntityType | SubFleetsEntityType;
  tableData: ReportRowData[];
  subFleetsTableData: ReportRowData[];
  fetchingReport: boolean;
  fetchFailed: boolean;
  range: Range;
  maxUTCDayBucketTime: Moment;
  totalUnassignedHours: number;
  activeGroupHasSubfleets: boolean;
  activeGroup: Group;
  isCurrentFleetJapanese?: boolean;
}
export const tableSelector = createStructuredSelector({
  activeEntityType: selectActiveEntityType,
  subFleetsTableData: reportSubFleetsRowsSelector,
  fleet: currentFleetSelector,
  maxUTCDayBucketTime: selectMaxUTCDayBucketTime,
  fleetFeatures: fleetFeaturesSelector,
  totalUnassignedHours: ({ reports }) => {
    return reports.totalUnassignedHours
      ? Math.floor(reports.totalUnassignedHours)
      : 0;
  },
  activeGroup: activeGroupSelector,
  activeGroupHasSubfleets: activeGroupHasNamedChildrenSelector,
  isCurrentFleetJapanese: isJapaneseFleetSelector,
});

export const industryAvgScoreSelector = ({ reports }) =>
  reports.industryAverageScore;

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

// clear report data
export const clearReport = () => (dispatch: (any) => void) => {
  dispatch({ type: CLEAR_REPORT });
};

// options passed to getTripReportHttpUrl function
export interface TripReportOptions {
  vehicle?: string;
  driver?: string;
  group?: string;
  entityType?: EntityType | SubFleetsEntityType;
  min: Moment;
  max: Moment;
}

export interface ReportOptions {
  range: Range;
  entity?: EntityType | SubFleetsEntityType;
  type?: DurationType;
  isScoreVersionVera3?: boolean;
  isAggregateScore?: boolean;
  groupId?: string;
}

interface ReportRequestParams {
  min: number;
  max: number;
  group?: string;
  type?: DurationType;
  agg_only?: boolean;
  view?: EntityType | SubFleetsEntityType;
  version?: ScoreVersion;
}

export const getReport = ({
  range: { min, max },
  groupId,
  entity,
  type,
  isScoreVersionVera3,
}: ReportOptions) => (dispatch: (any) => void, getState): Promise<any> => {
  const {
    groups: { activeGroupId },
  } = getState();
  const groupIdToFetch = groupId || activeGroupId;
  dispatch({ type: FETCHING_REPORT });

  const version = isScoreVersionVera3 ? ScoreVersion.VERA3 : null;
  return API.reports
    .getReport({
      fleetId: getFleetId(),
      groupId: groupIdToFetch,
      version,
      term: type,
      range: { min: min.valueOf(), max: max.valueOf() },
      entity,
    })
    .then(resp => {
      return dispatch({
        type: REPORT_FETCHED,
        payload: {
          reportData: resp.data,
        },
      });
    })
    .catch(error => {
      dispatch({ type: REPORT_FETCH_FAILED, payload: error });
      console.log('error', error);
    });
};

/* Score/Event count lookup by Group */
export const getGroupReport = ({
  range: { min, max },
  groupId = '',
  type = DurationType.Weekly,
  isScoreVersionVera3,
  isAggregateScore = false,
}: ReportOptions) => (dispatch: (any) => void, getState): Promise<any> => {
  const {
    groups: { activeGroupId },
  } = getState();
  const groupIdToFetch = groupId || activeGroupId;
  dispatch({ type: FETCHING_GROUP_REPORT, payload: { key: type } });
  const version = isScoreVersionVera3 ? ScoreVersion.VERA3 : null;
  return API.reports
    .getGroupReport({
      fleetId: getFleetId(),
      groupId: groupIdToFetch,
      range: { min: min.valueOf(), max: max.valueOf() },
      term: type,
      version,
      isAggregateScore,
    })
    .then(resp => {
      return dispatch({
        type: GROUP_REPORT_FETCHED,
        payload: {
          data: resp.data,
          key: type,
        },
      });
    })
    .catch(error => {
      dispatch({ type: GROUP_REPORT_FETCH_FAILED, payload: error });
      console.log('error', error);
    });
};

/* Score/Event count lookup by Fleet
To pull all fleet average:
*/
export const getIndustryAverageScore = ({
  range: { min, max },
  type,
  isScoreVersionVera3 = false,
  isAggregateScore = false,
}: {
  range: Range;
  type?: DurationType;
  isScoreVersionVera3?: boolean;
  isAggregateScore?: boolean;
}) => (dispatch: (any) => void) => {
  dispatch({ type: FETCHING_INDUSTRY_AVERAGE_SCORE });

  return API.reports
    .getIndustryAverageScore({
      fleetId: getFleetId(),
      range: { min: min.valueOf(), max: max.valueOf() },
      type,
      isAggregateScore,
      isScoreVersionVera3,
    })
    .then(resp => {
      if (resp.data && resp.data.length) {
        const industryAggScore = resp.data[0].agg_score.risk_scores.risk_score;
        return dispatch({
          type: INDUSTRY_AVERAGE_SCORE_FETCHED,
          payload: {
            data: industryAggScore,
          },
        });
      }
    })
    .catch(error => {
      dispatch({
        type: FETCHING_INDUSTRY_AVERAGE_SCORE_FAILED,
        payload: error,
      });
      console.log('error', error);
    });
};

export const getTotalUnassignedHours = ({ min, max }: Range) => (
  dispatch: (any) => void,
) => {
  dispatch({ type: FETCHING_UNASSIGNED_HOURS });

  return API.reports
    .getTotalUnassignedHours({
      fleetId: getFleetId(),
      range: { min: min.valueOf(), max: max.valueOf() },
    })
    .then(resp => {
      const totalUnassignedHours = msToHours(resp.unassigned_duration_ms);
      return dispatch({
        type: UNASSIGNED_HOURS_FETCHED,
        payload: {
          totalUnassignedHours,
        },
      });
    })
    .catch(error => {
      dispatch({ type: REPORT_FETCH_FAILED, payload: error });
      console.log('error', error);
    });
};

/**
 * ----------------------------------------------------------------------------
 * Reducer
 * ----------------------------------------------------------------------------
 */

export interface ReportReducer {
  // the data grouping into rows, by driver or by vehicle
  reportData: ReportRowAPIData[];
  groupReportData: { [key: string]: ReportRowAPIData[] };
  industryAverageScore: number;
  fetchingReport: boolean;
  fetchingGroupReport: boolean;
  fetchFailed: boolean;
  fleetFetchFailed: boolean;
  totalUnassignedHours: number;
  fetchingUnassignedHours: boolean;
  unassignedHoursFetchFailed: boolean;
}

export const initialState: ReportReducer = {
  reportData: [],
  groupReportData: {
    [DurationType.Daily]: [],
    [DurationType.Weekly]: [],
    [DurationType.Monthly]: [],
  },
  fetchingGroupReport: false,
  industryAverageScore: -1,
  fetchingReport: false,
  fetchFailed: false,
  fleetFetchFailed: false,
  totalUnassignedHours: null,
  fetchingUnassignedHours: false,
  unassignedHoursFetchFailed: false,
};

export default (state = initialState, { type, payload }): ReportReducer => {
  switch (type) {
    case FETCHING_REPORT:
      return {
        ...state,
        reportData: initialState.reportData,
        fetchFailed: initialState.fetchFailed,
        fetchingReport: true,
      };
    case REPORT_FETCH_FAILED:
      return {
        ...state,
        fetchingReport: false,
        fetchFailed: true,
      };
    case REPORT_FETCHED: {
      const { reportData } = payload;
      return {
        ...state,
        fetchingReport: false,
        reportData,
      };
    }
    case FETCHING_GROUP_REPORT:
      return {
        ...state,
        groupReportData: {
          ...state.groupReportData,
          [payload.key]: [],
        },
        fetchFailed: initialState.fetchFailed,
        fetchingGroupReport: true,
      };
    case GROUP_REPORT_FETCH_FAILED:
      return {
        ...state,
        fetchingGroupReport: false,
        fleetFetchFailed: true,
      };
    case GROUP_REPORT_FETCHED:
      return {
        ...state,
        fetchingGroupReport: false,
        groupReportData: {
          ...state.groupReportData,
          [payload.key]:
            payload.data && payload.data.length ? payload.data : [],
        },
      };
    case INDUSTRY_AVERAGE_SCORE_FETCHED:
      return {
        ...state,
        industryAverageScore: payload.data,
      };

    case CLEAR_REPORT:
      return {
        ...initialState,
        industryAverageScore: state.industryAverageScore,
      };
    case FETCHING_UNASSIGNED_HOURS:
      return {
        ...state,
        fetchingUnassignedHours: true,
      };
    case UNASSIGNED_HOURS_FETCH_FAILED:
      return {
        ...state,
        unassignedHoursFetchFailed: true,
      };
    case UNASSIGNED_HOURS_FETCHED: {
      const { totalUnassignedHours } = payload;
      return {
        ...state,
        fetchingUnassignedHours: false,
        totalUnassignedHours,
      };
    }
    default:
      return state;
  }
};
