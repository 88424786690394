import React from 'react';
import { createSelector } from 'reselect';
import { ROUTES } from 'routes/constants';
import { currentFleetIsSuperfleetSelector } from 'components/superfleets/superfleets.redux';
import {
  MapIcon,
  EventIcon,
  ReportIcon,
  OrganizationIcon,
  HomeIcon,
  CoachingIcon,
  UserIcon,
  ChartupIcon,
  ExitIcon,
  VideoIcon,
  CarIcon,
} from '@nauto/icons';
import { Permissions } from 'hooks/use-permissions';
import {
  currentFleetDataSelector,
  isLoggedInUserTypeDriverSelector,
} from 'components/auth/auth.reducer';
import { FleetAccess } from 'models/db';
import { activeGroupSelector, Group } from 'components/groups/groups.redux';
import { getMapsEventsConfig, setMapsEventsConfig } from 'utils/localstorage';
import { featureFlagsSelector } from 'components/feature-flags/flag-selectors';
import { FleetLinkTo } from 'components/fleet-link';
import { isInIframe } from 'utils/helpers';
import { showCoachingRoute } from 'utils/navigation-utils';
import { Flags } from 'components/feature-flags/flags';

export interface NavItemProps {
  appcuesId: string;
  icon: JSX.Element;
  key: string;
  dataTestId: string;
  label: (t: any) => string;
  to: ROUTES | FleetLinkTo;
  show: boolean;
  match?: ROUTES[];
  isFleetLink?: boolean;
  target?: string;
  state?: any;
}

const showRouteConfig = ({ groupData, fleetData }) => {
  if (groupData && fleetData) {
    const fleetLimitAccessGroupLayer =
      fleetData.properties?.fleet_limit_access_group_layer || 0;
    const groupLevel = groupData?.level;
    setMapsEventsConfig(!(groupLevel <= fleetLimitAccessGroupLayer));
    return !(groupLevel <= fleetLimitAccessGroupLayer);
  } else {
    return getMapsEventsConfig();
  }
};

const reduceNavItems = (
  activeGroup: Group,
  currentFleetData: FleetAccess,
  currentFleetIsSuperfleet: boolean,
  featureFlags: Flags,
  isLoggedInUserTypeDriver: boolean,
  props: any,
): NavItemProps[] => {
  const { t, hasPermission } = props;

  const canViewWithNewPermissions = permissions => {
    return permissions.some(
      permission =>
        hasPermission(permission, 'full-access') ||
        hasPermission(permission, 'read-only'),
    );
  };

  const isCustomUserManagementEnabled = hasPermission(
    Permissions.CustomUserManagement,
    true,
  );

  const showInsightsRoute = isCustomUserManagementEnabled
    ? canViewWithNewPermissions([
        Permissions.VehicleInsightsHome,
        Permissions.DriverInsightsHome,
      ])
    : hasPermission(Permissions.Insights, true) ||
      hasPermission(Permissions.NewInsightsLanding, true);

  const insightsRoute = featureFlags.newInsights2
    ? ROUTES.NEW_INSIGHTS_LANDING
    : ROUTES.INSIGHTS;

  const showMapRoute = featureFlags.eventsMapsRouteConfig
    ? showRouteConfig({
        groupData: activeGroup,
        fleetData: currentFleetData?.fleet,
      }) && hasPermission(Permissions.Trips, true)
    : isCustomUserManagementEnabled
    ? canViewWithNewPermissions([
        Permissions.VehicleMaps,
        Permissions.DriverMaps,
      ]) && hasPermission(Permissions.Trips, true)
    : hasPermission(Permissions.Trips, true);

  const showEventsRoute = featureFlags.eventsMapsRouteConfig
    ? showRouteConfig({
        groupData: activeGroup,
        fleetData: currentFleetData?.fleet,
      }) && hasPermission(Permissions.Events, true)
    : isCustomUserManagementEnabled
    ? hasPermission(Permissions.VehicleEvents, 'full-access') ||
      hasPermission(Permissions.DriverEvents, 'full-access') ||
      hasPermission(Permissions.VehicleEvents, 'read-only') ||
      hasPermission(Permissions.DriverEvents, 'read-only')
    : hasPermission(Permissions.Events, true);

  const showReportsRoute = isCustomUserManagementEnabled
    ? canViewWithNewPermissions([
        Permissions.ReportsCoachingEffectiveness,
        Permissions.ReportsCollision,
        Permissions.ReportsCustomReports,
        Permissions.ReportsTopPerforming,
        Permissions.ReportsAtRisk,
        Permissions.ReportsDeviceHealth,
        Permissions.ReportsRealTimeEffectiveness,
        Permissions.ReportsPolicyViolations,
      ])
    : hasPermission(Permissions.Reports, true);

  const showOrganizationRoute = isCustomUserManagementEnabled
    ? canViewWithNewPermissions([
        Permissions.DriverManagement,
        Permissions.VehicleManagement,
      ]) && hasPermission(Permissions.Organization, true)
    : hasPermission(Permissions.Organization, true);

  const isNautoEssential = hasPermission(Permissions.NautoEssential);

  const showVehiclesModule = featureFlags.vehiclesModule
    ? isCustomUserManagementEnabled
      ? hasPermission(Permissions.VehicleInsightsHome, 'read-only') ||
        hasPermission(Permissions.VehicleInsightsHome, 'full-access')
      : true
    : false;

  const coachingRoute = featureFlags.coachingVersion3
    ? ROUTES.COACHING_DRIVERS
    : ROUTES.COACHING;

  const fleetNavItemsData = [
    {
      appcuesId: 'primary-navigation-button-home',
      icon: <HomeIcon />,
      key: 'home',
      dataTestId: 'homeNavItem',
      label: t('Home'),
      to: ROUTES.HOME,
      show: !isLoggedInUserTypeDriver && featureFlags.newHome,
      match: [ROUTES.HOME],
    },
    {
      appcuesId: featureFlags.newHome
        ? 'primary-navigation-button-insights'
        : 'primary-navigation-button-home',
      icon: featureFlags.newHome ? <ChartupIcon /> : <HomeIcon />,
      key: 'insights',
      dataTestId: 'insightsNavItem',
      label: featureFlags.newHome ? t('Insights') : t('Home'),
      to: insightsRoute,
      show: !isLoggedInUserTypeDriver && showInsightsRoute,
      match: [
        ROUTES.INSIGHTS,
        ROUTES.NEW_INSIGHTS_LANDING,
        ROUTES.SAFETY_ASSESSMENT,
        ROUTES.EVENT_INSIGHTS,
        ROUTES.FLEET_HEALTH,
        ROUTES.MANAGER_PERFORMANCE,
        ROUTES.MANAGER_PERFORMANCE_TREND,
        ROUTES.DRIVER_SAFETY,
        ROUTES.DRIVER_SAFETY_TREND,
      ],
    },
    {
      appcuesId: 'primary-navigation-cvr',
      icon: <VideoIcon />,
      key: 'cvr',
      dataTestId: 'cvrNavItem',
      label: t('CVR'),
      to: ROUTES.CVR,
      show:
        hasPermission(Permissions.CVR, true) &&
        !hasPermission(Permissions.VideoRequest, 'no-access'),
      match: [ROUTES.CVR],
    },
    {
      appcuesId: 'primary-navigation-button-map',
      icon: <MapIcon />,
      key: 'map',
      dataTestId: 'mapNavItem',
      label: t('Map'),
      to: ROUTES.MAP,
      show: !isLoggedInUserTypeDriver && showMapRoute,
      match: [ROUTES.MAP, ROUTES.ENTITY_TRIPS],
    },
    {
      appcuesId: 'primary-navigation-button-events',
      icon: <EventIcon />,
      key: 'events',
      dataTestId: 'eventsNavItem',
      label: t('Events'),
      to: ROUTES.EVENTS,
      show: !isLoggedInUserTypeDriver && showEventsRoute && !isNautoEssential,
      match: [ROUTES.EVENTS, ROUTES.ENTITY_EVENTS],
    },
    {
      appcuesId: 'primary-navigation-button-reports',
      icon: <ReportIcon />,
      key: 'reports',
      dataTestId: 'reportsNavItem',
      label: t('Reports'),
      to: ROUTES.REPORTS,
      show: !isLoggedInUserTypeDriver && showReportsRoute,
    },
    {
      appcuesId: 'primary-navigation-button-fleet',
      icon: <OrganizationIcon />,
      key: 'org',
      dataTestId: 'organizationNavItem',
      label: t('Fleet'),
      to: ROUTES.ORGANIZATION,
      show: !isLoggedInUserTypeDriver && showOrganizationRoute,
    },
    {
      appcuesId: 'primary-navigation-button-coaching',
      icon: <CoachingIcon />,
      key: 'coaching',
      dataTestId: 'coachingNavItem',
      label: t('Coaching'),
      to: coachingRoute,
      match: [ROUTES.COACHING, ROUTES.DRIVER_COACHING],
      show: showCoachingRoute({
        newRoleManagementPermission: isCustomUserManagementEnabled,
        newCoachingFeatureFlag: featureFlags.newCoaching,
        hasPermission,
      }),
    },
    {
      appcuesId: 'primary-navigation-button-vehicles',
      icon: <CarIcon />,
      key: 'vehicles',
      label: 'Vehicles',
      dataTestId: 'vehiclesNavItem',
      to: ROUTES.VEHICLES_OVERVIEW,
      show: !isLoggedInUserTypeDriver && showVehiclesModule,
    },
    {
      appcuesId: 'primary-navigation-button-internal',
      icon: <UserIcon />,
      key: 'internal',
      label: t('Internal'),
      dataTestId: 'internalPageNavItem',
      to: ROUTES.INTERNAL,
      show:
        !isLoggedInUserTypeDriver &&
        hasPermission(Permissions.InternalUsers, true),
    },
  ];

  /* tslint:enable:no-duplicate-string */
  const superfleetNavItemsData = [
    {
      appcuesId: 'primary-navigation-button-home-superfleet',
      icon: <HomeIcon />,
      key: 'insights',
      dataTestId: 'superfleetInsightsNavItem',
      label: t('Home'),
      to: ROUTES.SUPERFLEET_ASSESSMENT,
      show: hasPermission(Permissions.SuperfleetInsights),
    },
    {
      appcuesId: 'primary-navigation-button-events-superfleet',
      icon: <EventIcon />,
      key: 'events',
      dataTestId: 'superfleetEventsNavItem',
      label: t('Events'),
      to: ROUTES.SUPERFLEET_EVENTS,
      show: hasPermission(Permissions.SuperfleetEvents, true),
    },
    {
      appcuesId: 'primary-navigation-button-fleet-superfleet',
      icon: <OrganizationIcon />,
      key: 'org',
      dataTestId: 'superfleetOrganizationNavItem',
      label: t('Fleets'),
      to: ROUTES.SUPERFLEET_FLEETS,
      show: hasPermission(Permissions.SuperfleetOrganization),
    },
    {
      appcuesId: 'primary-navigation-button-reports-superfleet',
      icon: <ReportIcon />,
      key: 'reports',
      label: t('Reports'),
      dataTestId: 'superfleetReportsNavItem',
      to: ROUTES.SUPERFLEET_REPORTS,
      show: hasPermission(Permissions.SuperfleetReports),
    },
    {
      appcuesId: 'primary-navigation-button-internal-page',
      icon: <UserIcon />,
      key: 'internal-users',
      label: t('Internal'),
      dataTestId: 'internalPageNavItem',
      to: ROUTES.INTERNAL,
      show: hasPermission(Permissions.InternalUsers, true),
    },
  ];
  return currentFleetIsSuperfleet ? superfleetNavItemsData : fleetNavItemsData;
};

const reduceDriverNavItems = (
  featureFlags: any,
  isLoggedInUserTypeDriver: boolean,
  props: any,
): NavItemProps[] => {
  const { t, user, hasPermission } = props;
  const newRoleManagement = hasPermission(
    Permissions.CustomUserManagement,
    true,
  );

  return [
    {
      appcuesId: 'driver-navigation-button-insights',
      icon: <ChartupIcon />,
      key: 'insights',
      label: t('Insights'),
      dataTestId: 'driverInsightsPageNavItem',
      to: { pathname: ROUTES.DRIVER_LOGIN_INSIGHTS, driverId: user?.id },
      match: [ROUTES.DRIVER_LOGIN_INSIGHTS],
      show: isLoggedInUserTypeDriver,
      isFleetLink: true,
    },
    {
      appcuesId: 'driver-navigation-button-coaching',
      icon: <CoachingIcon />,
      key: 'coaching',
      label: t('Coaching'),
      dataTestId: 'driverCoachingPageNavItem',
      to: { pathname: ROUTES.DRIVER_LOGIN_COACHING, entityId: user?.id },
      match: [ROUTES.DRIVER_LOGIN_COACHING],
      show: showCoachingRoute({
        newRoleManagementPermission: newRoleManagement,
        newCoachingFeatureFlag: featureFlags.newCoaching,
        hasPermission,
      }),
      isFleetLink: true,
    },
    {
      appcuesId: 'driver-navigation-button-profile',
      icon: <UserIcon />,
      key: 'profile',
      label: t('Profile'),
      dataTestId: 'driverProfilePageNavItem',
      to: {
        pathname: ROUTES.DRIVER_LOGIN_PROFILE,
        driverId: user?.id,
      },
      match: [ROUTES.DRIVER_LOGIN_PROFILE],
      show: isLoggedInUserTypeDriver,
      isFleetLink: true,
    },
    {
      appcuesId: 'driver-navigation-button-logout',
      icon: <ExitIcon />,
      key: 'logout',
      dataTestId: 'signOutNavItem',
      label: t('Sign out'),
      to: ROUTES.LOGOUT,
      show: !isInIframe(),
      isFleetLink: false,
      match: [ROUTES.LOGOUT],
      target: '_self',
    },
  ];
};

export const navItemsSelector = createSelector(
  [
    activeGroupSelector,
    currentFleetDataSelector,
    currentFleetIsSuperfleetSelector,
    featureFlagsSelector,
    isLoggedInUserTypeDriverSelector,
    (state, props) => {
      return props;
    },
  ],
  reduceNavItems,
);

export const driverNavItemsSelector = createSelector(
  [
    featureFlagsSelector,
    isLoggedInUserTypeDriverSelector,
    (state, props) => {
      return props;
    },
  ],
  reduceDriverNavItems,
);
