import loadable from '@loadable/component';
import { ROUTES } from './constants';
import { MixpanelEventKeys } from 'components/mixpanel/tags';
import { Flags } from 'components/feature-flags/flags';
import { TFunction } from 'i18next';
import React from 'react';

export interface Template {
  header?: boolean;
  sidebar?: boolean;
  showFleet?: boolean;
  showSubfleet?: boolean;
  showLegacyLeftNav?: boolean;
  showBackButton?: boolean;
  shouldPreserveSearch?: boolean;
}

const template: { [key: string]: Template } = {
  subfleet: {
    header: true,
    sidebar: true,
    showSubfleet: true,
    showFleet: true,
  },
  fleet: {
    header: true,
    sidebar: true,
    showSubfleet: false,
    showFleet: true,
  },
  nonFleet: {
    header: true,
    sidebar: true,
    showSubfleet: false,
    showFleet: false,
  },
  orphanWithFleetWithBackButton: {
    header: true,
    sidebar: true,
    showSubfleet: false,
    showFleet: true,
    showBackButton: true,
  },
  orphanWithBackButton: {
    header: true,
    sidebar: true,
    showSubfleet: false,
    showFleet: false,
    showBackButton: true,
  },
};

interface RouteRules {
  template?: Template;
  public?: boolean;
  component?: (featureFlags: Flags) => React.ReactNode | React.ReactNode;
  trackingId?: string;
  i18nTitle?: (t: TFunction, featureFlags?: Flags) => string;
  userFeatureFlag?: string;
}

// Reused components only need to be loaded once
const EventsLoadable = loadable(() => import('components/events'));
const SuperfleetEventsLoadable = loadable(() =>
  import('components/superfleets/events'),
);
const MapLoadable = loadable(() => import('components/map'));
const FedexSSO = loadable(() =>
  import('components/auth/routes/fedex-sso.route'),
);
const BpxSSO = loadable(() => import('components/auth/routes/bpx-sso.route'));
const CoachingLoadable = loadable(() => import('components/coaching'));
const CoachingEventDetails = loadable(() =>
  import(
    'components/coaching-v3/coaching-event-details/coaching-event-details.route'
  ),
);
const CoachingV3Loadable = loadable(() => import('components/coaching-v3'));
const NewCoachingLoadable = loadable(() =>
  import('components/coaching-new/coaching'),
);
const Reports = loadable(() => import('components/reports'));
const NewReports = loadable(() => import('components/reports-new'));
const CoachingHistoryLoadable = loadable(() =>
  import('components/driver/coaching-history.route'),
);
const DriverInsightsLoadable = loadable(() =>
  import('components/driver/insights.route'),
);
const DriverCoachingLoadable = loadable(() =>
  import('components/driver/coaching/coaching.route'),
);
const DriverProfileLoadable = loadable(() =>
  import('components/driver/profile.route'),
);
const ForgotPasswordLoadable = loadable(() =>
  import('components/auth/routes/forgot-password.route'),
);
const SetPasswordLoadable = loadable(() =>
  import('components/auth/routes/set-password.route'),
);

const EditUserLoadable = loadable(() => import('components/user/single-fleet'));
const NewEditUserLoadable = loadable(() =>
  import('components/users/new/add-user'),
);

const NewHome = loadable(() => import('components/home/new-home'));
const HomeInsights = loadable(() => import('components/home/overview'));
const NewInsights = loadable(() => import('components/insights-2.0'));

const getInsightsTitle = (t: TFunction, featureFlags: Flags): string => {
  return featureFlags.newInsights2
    ? ''
    : featureFlags.newHome
    ? t('Insights')
    : t('Home');
};

export const routeMap: { [key: string]: RouteRules } = {
  [ROUTES.SET_PASSWORD]: {
    trackingId: MixpanelEventKeys.PageSetPassword,
    public: true,
    component: SetPasswordLoadable,
  },
  [ROUTES.RESET_PASSWORD]: {
    trackingId: MixpanelEventKeys.PageResetPassword,
    public: true,
    component: SetPasswordLoadable,
  },
  [ROUTES.DRIVER_RESET_PASSWORD]: {
    trackingId: MixpanelEventKeys.PageDriverAccountResetPassword,
    public: true,
    component: loadable(() =>
      import('components/auth/routes/driver-reset-password.route'),
    ),
  },
  [ROUTES.CUSTOMER_SUPPORT_TOOLKIT]: {
    template: template.nonFleet,
    component: loadable(() => import('components/customer-support-toolkit')),
  },
  [ROUTES.FORGOT_PASSWORD]: {
    trackingId: MixpanelEventKeys.PageForgotPassword,
    public: true,
    component: ForgotPasswordLoadable,
  },
  [ROUTES.DRIVER_FORGOT_PASSWORD]: {
    trackingId: MixpanelEventKeys.PageDriverAccountForgotPassword,
    public: true,
    component: ForgotPasswordLoadable,
  },
  [ROUTES.LOGIN]: {
    trackingId: MixpanelEventKeys.PageLogin,
    public: true,
    component: loadable(() => import('components/auth/routes/login.route')),
  },
  [ROUTES.FEDEX_SSO]: {
    trackingId: MixpanelEventKeys.FedexSSOLogin,
    public: true,
    component: FedexSSO,
  },
  [ROUTES.BPX_SSO]: {
    trackingId: MixpanelEventKeys.BPXSSOLogin,
    public: true,
    component: BpxSSO,
  },
  [ROUTES.LOGOUT]: {
    trackingId: MixpanelEventKeys.PageLogout,
    public: true,
    component: loadable(() => import('components/auth/routes/logout.route')),
  },
  [ROUTES.NO_FLEETS]: {
    trackingId: MixpanelEventKeys.PageNoFleets,
    public: true,
    component: loadable(() => import('components/auth/routes/no-fleets.route')),
  },
  [ROUTES.SSO]: {
    trackingId: MixpanelEventKeys.PageSSO,
    public: true,
    component: loadable(() => import('components/auth/routes/sso.route')),
  },
  [ROUTES.UPDATE_EMAIL]: {
    trackingId: MixpanelEventKeys.PageUpdateEmail,
    public: true,
    component: loadable(() => import('components/profile/update-email.route')),
  },
  [ROUTES.FLEETS]: {
    trackingId: MixpanelEventKeys.PageFleets,
    template: template.nonFleet,
    component: loadable(() => import('components/fleets')),
    i18nTitle: (t: TFunction): string => t('Fleets'),
  },
  [ROUTES.FLEET_HEALTH]: {
    trackingId: MixpanelEventKeys.PageFleetHealth,
    template: template.subfleet,
    component: loadable(() => import('components/fleet-health')),
    i18nTitle: (t: TFunction, featureFlags: Flags): string =>
      featureFlags.newHome ? t('Insights') : t('Home'),
  },
  [ROUTES.DEVICE_HEALTH]: {
    trackingId: MixpanelEventKeys.PageDeviceHealth,
    template: template.orphanWithBackButton,
    component: loadable(() => import('components/fleet-health/device-health')),
  },
  [ROUTES.FNOL_REPORT]: {
    trackingId: MixpanelEventKeys.PageFNOLReport,
    component: loadable(() => import('components/fnol/fnol.route')),
  },
  [ROUTES.INTERNAL]: {
    trackingId: MixpanelEventKeys.InternalPage,
    template: template.nonFleet,
    component: loadable(() => import('components/internal')),
  },
  [ROUTES.NOTIFICATIONS]: {
    trackingId: MixpanelEventKeys.PageNotifications,
    template: template.fleet,
    component: loadable(() =>
      import('components/notifications/notifications-archive.route'),
    ),
    i18nTitle: (t: TFunction): string => t('Notifications'),
  },
  [ROUTES.VIDEO_REQUESTS]: {
    trackingId: MixpanelEventKeys.PageVideoRequests,
    template: template.fleet,
    component: loadable(() =>
      import('components/notifications/video-requests-archive.route'),
    ),
    i18nTitle: (t: TFunction): string => t('Video Requests'),
  },
  [ROUTES.ADD_FLEET_USER]: {
    trackingId: MixpanelEventKeys.PageAddFleetUser,
    template: template.fleet,
    component: (featureFlags: Flags): React.ReactNode => {
      return featureFlags.customUserManagement
        ? NewEditUserLoadable
        : EditUserLoadable;
    },
    i18nTitle: (t: TFunction): string => t('Add new user'),
  },
  [ROUTES.CVR]: {
    trackingId: 'video_requests_new_pageview',
    template: template.subfleet,
    component: loadable(() => import('components/video-request-route/index')),
  },
  [ROUTES.FLEET_USER]: {
    trackingId: MixpanelEventKeys.PageEditFleetUser,
    template: template.fleet,
    component: (featureFlags: Flags): React.ReactNode =>
      featureFlags.customUserManagement
        ? NewEditUserLoadable
        : loadable(() => import('components/user/single-fleet')),
    i18nTitle: (t: TFunction): string => t('Edit user'),
  },
  [ROUTES.ADD_MULTI_FLEET_USER]: {
    trackingId: MixpanelEventKeys.PageAddMultiFleetUser,
    template: template.nonFleet,
    component: loadable(() => import('components/user/multi-fleet')),
    i18nTitle: (t: TFunction): string => t('Add new user'),
  },
  [ROUTES.ADD_INTERNAL_FLEET_USER]: {
    trackingId: MixpanelEventKeys.PageAddMultiFleetUser,
    template: template.nonFleet,
    component: loadable(() => import('components/users/internal')),
  },
  [ROUTES.MULTI_FLEET_USER]: {
    trackingId: MixpanelEventKeys.PageEditMultiFleetUser,
    template: template.nonFleet,
    component: loadable(() => import('components/user/multi-fleet')),
    i18nTitle: (t: TFunction): string => t('Edit user'),
  },
  [ROUTES.MULTI_FLEET_ADMIN_USER]: {
    trackingId: MixpanelEventKeys.PageEditMultiFleetUser,
    template: template.nonFleet,
    component: loadable(() => import('components/users/internal')),
  },
  [ROUTES.USERS]: {
    trackingId: MixpanelEventKeys.PageUsers,
    template: template.fleet,
    component: loadable(() => import('components/users/users-table')),
    i18nTitle: (t: TFunction): string => t('Users'),
  },
  [ROUTES.INTERNAL_USERS]: {
    trackingId: MixpanelEventKeys.PageUsers,
    template: template.fleet,
    component: loadable(() => import('components/users/users-table')),
    i18nTitle: (t: TFunction): string => t('Users'),
  },
  [ROUTES.ROLES]: {
    trackingId: MixpanelEventKeys.PageRoles,
    template: template.fleet,
    component: loadable(() => import('components/roles')),
    i18nTitle: (): string => '',
  },
  [ROUTES.EDIT_ROLE]: {
    trackingId: MixpanelEventKeys.PageEditRoles,
    template: template.fleet,
    component: loadable(() => import('components/roles/edit-role')),
    i18nTitle: (): string => '',
  },
  [ROUTES.CREATE_ROLE]: {
    trackingId: MixpanelEventKeys.PageCreateRoles,
    template: template.fleet,
    component: loadable(() => import('components/roles/edit-role')),
    i18nTitle: (): string => '',
  },
  [ROUTES.USER_ACTIVATION]: {
    trackingId: MixpanelEventKeys.PageUserActivation,
    public: true,
    component: loadable(() => import('components/users/activation')),
  },
  [ROUTES.DRIVER_ACTIVATION]: {
    trackingId: MixpanelEventKeys.PageDriverAccountActivation,
    public: true,
    component: loadable(() => import('components/users/driver-activation')),
  },
  [ROUTES.PROFILE]: {
    trackingId: MixpanelEventKeys.PageProfile,
    template: template.fleet,
    component: loadable(() => import('components/profile')),
    i18nTitle: (t: TFunction): string => t('Account'),
  },
  [ROUTES.EDIT_CONTRACT]: {
    template: template.subfleet,
    trackingId: MixpanelEventKeys.HomeFleetSettings,
    component: loadable(() =>
      import('components/settings/contracts/contract.route'),
    ),
  },
  [ROUTES.ADD_CONTRACT]: {
    template: template.subfleet,
    trackingId: MixpanelEventKeys.HomeFleetSettings,
    component: loadable(() =>
      import('components/settings/contracts/contract.route'),
    ),
  },
  [ROUTES.SETTINGS]: {
    template: template.orphanWithFleetWithBackButton,
    trackingId: MixpanelEventKeys.HomeFleetSettings,
    component: loadable(() => import('components/settings/settings.route')),
  },
  [ROUTES.COACHING_EVENT_DETAILS]: {
    template: template.orphanWithFleetWithBackButton,
    trackingId: MixpanelEventKeys.PageCoachingDriverV3,
    component: CoachingEventDetails,
  },
  [ROUTES.COACHING_DRIVERS]: {
    trackingId: MixpanelEventKeys.PageCoaching,
    template: template.subfleet,
    component: CoachingV3Loadable,
    i18nTitle: (t: TFunction): string => t('Coaching'),
  },
  [ROUTES.COACHING_SUBFLEETS]: {
    trackingId: MixpanelEventKeys.PageCoaching,
    template: template.subfleet,
    component: CoachingV3Loadable,
    i18nTitle: (t: TFunction): string => t('Coaching'),
  },
  [ROUTES.COACHING_RECENT_SESSIONS]: {
    trackingId: MixpanelEventKeys.PageCoaching,
    template: template.subfleet,
    component: CoachingV3Loadable,
    i18nTitle: (t: TFunction): string => t('Coaching'),
  },
  [ROUTES.COACHING_HISTORICAL_SESSIONS]: {
    trackingId: MixpanelEventKeys.PageCoaching,
    template: template.subfleet,
    component: CoachingV3Loadable,
    i18nTitle: (t: TFunction): string => t('Coaching'),
  },
  [ROUTES.DRIVER_COACHING_HISTORY]: {
    trackingId: MixpanelEventKeys.PageDriverCoachingHistory,
    template: template.orphanWithBackButton,
    component: CoachingHistoryLoadable,
  },
  [ROUTES.COACHING]: {
    trackingId: MixpanelEventKeys.PageCoaching,
    template: template.subfleet,
    component: (featureFlags: Flags): React.ReactNode =>
      featureFlags.newCoaching ? NewCoachingLoadable : CoachingLoadable,
    i18nTitle: (t: TFunction): string => t('Coaching'),
  },
  [ROUTES.EVENTS]: {
    trackingId: MixpanelEventKeys.PageEvents,
    template: template.subfleet,
    component: EventsLoadable,
    i18nTitle: (t: TFunction): string => t('Events'),
  },
  [ROUTES.EVENT_DETAILS]: {
    trackingId: MixpanelEventKeys.PageEventDetails,
    template: template.orphanWithBackButton,
    component: EventsLoadable,
  },
  [ROUTES.EVENT_DETAILS_PAGE]: {
    trackingId: MixpanelEventKeys.PageEventDetails,
    template: template.nonFleet,
    component: EventsLoadable,
    i18nTitle: (t: TFunction): string => t('Events'),
  },
  [ROUTES.LEGACY_EMAIL_EVENT]: {
    template: template.subfleet,
    component: loadable(() => import('components/events/legacy-event.route')),
    i18nTitle: (t: TFunction): string => t('Events'),
  },

  [ROUTES.HOME]: {
    trackingId: MixpanelEventKeys.HomeOverview,
    template: template.subfleet,
    component: (featureFlags: Flags): React.ReactNode =>
      featureFlags.newHome ? NewHome : HomeInsights,
    i18nTitle: (t: TFunction): string => t('Home'),
  },
  [ROUTES.INSIGHTS]: {
    trackingId: MixpanelEventKeys.HomeOverview,
    template: template.subfleet,
    component: HomeInsights,
    i18nTitle: (t: TFunction, featureFlags: Flags): string =>
      getInsightsTitle(t, featureFlags),
  },
  [ROUTES.NEW_INSIGHTS_LANDING]: {
    trackingId: MixpanelEventKeys.NewInsightsLanding,
    template: template.subfleet,
    component: NewInsights,
  },
  [ROUTES.SAFETY_ASSESSMENT]: {
    trackingId: MixpanelEventKeys.PageSafetyAssessment,
    template: template.subfleet,
    component: loadable(() => import('components/home/safety-assessment')),
    i18nTitle: (t: TFunction, featureFlags: Flags): string =>
      getInsightsTitle(t, featureFlags),
  },
  [ROUTES.DRIVER_SAFETY]: {
    trackingId: MixpanelEventKeys.PageDriverSafety,
    template: template.subfleet,
    component: loadable(() => import('components/insights-2.0/driver-safety')),
    i18nTitle: (t: TFunction, featureFlags: Flags): string =>
      getInsightsTitle(t, featureFlags),
  },
  [ROUTES.DRIVER_SAFETY_TREND]: {
    trackingId: MixpanelEventKeys.PageDriverSafetyTrend,
    template: template.subfleet,
    component: loadable(() =>
      import('components/insights-2.0/driver-safety-trend'),
    ),
  },
  [ROUTES.MANAGER_PERFORMANCE]: {
    trackingId: MixpanelEventKeys.PageManagerPerformance,
    template: template.subfleet,
    component: loadable(() =>
      import('components/insights-2.0/manager-performance'),
    ),
    i18nTitle: (t: TFunction, featureFlags: Flags): string =>
      getInsightsTitle(t, featureFlags),
  },
  [ROUTES.MANAGER_PERFORMANCE_TREND]: {
    trackingId: MixpanelEventKeys.PageManagerPerformanceTrend,
    template: template.subfleet,
    component: loadable(() =>
      import('components/insights-2.0/manager-performance-trend'),
    ),
  },
  [ROUTES.EVENT_INSIGHTS]: {
    trackingId: MixpanelEventKeys.PageEventInsights,
    template: template.subfleet,
    component: loadable(() => import('components/home/event-insights.route')),
    i18nTitle: (t: TFunction): string => t('Home'),
  },
  [ROUTES.SUPERFLEET_ASSESSMENT]: {
    trackingId: MixpanelEventKeys.PageSuperfleetAssessment,
    template: template.fleet,
    component: loadable(() =>
      import('components/superfleets/fleets-safety-assessment'),
    ),
    i18nTitle: (t: TFunction): string => t('Home'),
  },
  [ROUTES.SUPERFLEET_FLEETS]: {
    trackingId: MixpanelEventKeys.PageSuperfleetFleets,
    template: template.fleet,
    component: loadable(() => import('components/superfleets/fleets-list')),
    i18nTitle: (t: TFunction): string => t('Fleets'),
  },
  [ROUTES.SUPERFLEET_REPORTS]: {
    trackingId: MixpanelEventKeys.PageSuperfleetReports,
    template: template.fleet,
    component: loadable(() => import('components/superfleets/reports')),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.SUPERFLEET_EVENT]: {
    trackingId: MixpanelEventKeys.PageSuperfleetEvent,
    template: template.fleet,
    component: SuperfleetEventsLoadable,
    i18nTitle: (t: TFunction): string => t('Events'),
  },
  [ROUTES.SUPERFLEET_EVENTS]: {
    trackingId: MixpanelEventKeys.PageSuperfleetEvents,
    template: template.fleet,
    component: SuperfleetEventsLoadable,
    i18nTitle: (t: TFunction): string => t('Events'),
  },
  [ROUTES.MAP]: {
    trackingId: MixpanelEventKeys.PageMap,
    template: template.subfleet,
    component: MapLoadable,
    i18nTitle: (t: TFunction): string => t('Map'),
  },
  [ROUTES.ORGANIZATION]: {
    trackingId: MixpanelEventKeys.PageFleet,
    template: template.subfleet,
    component: loadable(() => import('components/organization')),
    i18nTitle: (t: TFunction): string => t('Fleet'),
  },
  [ROUTES.PRINT_REPORT]: {
    trackingId: MixpanelEventKeys.PagePrintReport,
    template: template.subfleet,
    component: loadable(() => import('components/reports/print.route')),
  },
  [ROUTES.COLLISION_REPORT]: {
    template: template.subfleet,
    component: loadable(() => import('components/reports-new/collision')),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.REAL_TIME_ALERTS_REPORT]: {
    template: template.subfleet,
    component: loadable(() =>
      import('components/reports-new/real-time-alert-effectiveness'),
    ),

    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.REPORTS_DEVICE_HEALTH]: {
    template: template.subfleet,
    component: loadable(() => import('components/reports-new/device-health')),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.TOP_PERFORMING]: {
    template: template.subfleet,
    component: loadable(() => import('components/reports-new/top-performing')),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.AT_RISK]: {
    template: template.subfleet,
    component: loadable(() => import('components/reports-new/at-risk')),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.CUSTOM_REPORT]: {
    template: template.subfleet,
    component: loadable(() => import('components/reports-new/custom-report')),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.VEDR_DEVICE_ASSIGNMENT_REPORT]: {
    template: template.subfleet,
    component: loadable(() => import('components/reports-new/vedr')),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.VEDR_KIS_REPORT]: {
    template: template.subfleet,
    component: loadable(() => import('components/reports-new/vedr')),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.VEDR_REPORT]: {
    template: template.subfleet,
    component: loadable(() => import('components/reports-new/vedr')),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.POLICY_VIOLATIONS_REPORT]: {
    template: template.subfleet,
    component: loadable(() =>
      import('components/reports-new/policy-violations'),
    ),
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.REPORTS]: {
    trackingId: MixpanelEventKeys.PageReports,
    template: template.subfleet,
    component: (featureFlags: Flags): React.ReactNode =>
      featureFlags.newReporting ? NewReports : Reports,
    i18nTitle: (t: TFunction): string => t('Reports'),
  },
  [ROUTES.TAG]: {
    trackingId: MixpanelEventKeys.PageTagDrivers,
    template: template.subfleet,
    component: loadable(() => import('components/tagging')),
    i18nTitle: (t: TFunction): string => t('Tag drivers'),
  },
  [ROUTES.TAG_TRIPS]: {
    trackingId: MixpanelEventKeys.PageTagTrips,
    template: template.fleet,
    component: loadable(() => import('components/tag-trips')),
    i18nTitle: (t: TFunction): string => t('Tag trips'),
  },
  [ROUTES.DRIVER_COACHING]: {
    trackingId: MixpanelEventKeys.PageDriverCoaching,
    template: {
      ...template.orphanWithBackButton,
      shouldPreserveSearch: true,
    },
    component: DriverCoachingLoadable,
  },
  [ROUTES.DRIVER_INSIGHTS]: {
    trackingId: MixpanelEventKeys.PageDriverInsights,
    template: template.orphanWithBackButton,
    component: DriverInsightsLoadable,
  },
  [ROUTES.DRIVER_PROFILE]: {
    trackingId: MixpanelEventKeys.PageDriverProfile,
    template: template.orphanWithBackButton,
    component: DriverProfileLoadable,
  },
  [ROUTES.DRIVER_PROFILE_EDIT]: {
    trackingId: MixpanelEventKeys.PageDriverProfileEdit,
    template: template.orphanWithBackButton,
    component: loadable(() => import('components/driver/profile-edit.route')),
  },
  [ROUTES.DRIVER_LOGIN_PROFILE]: {
    trackingId: MixpanelEventKeys.PageDriverAccountProfile,
    template: template.nonFleet,
    component: DriverProfileLoadable,
  },
  [ROUTES.DRIVER_LOGIN_COACHING]: {
    trackingId: MixpanelEventKeys.PageDriverAccountCoaching,
    template: {
      ...template.nonFleet,
      shouldPreserveSearch: true,
    },
    component: DriverCoachingLoadable,
  },
  [ROUTES.DRIVER_LOGIN_INSIGHTS]: {
    trackingId: MixpanelEventKeys.PageDriverAccountInsights,
    template: template.nonFleet,
    component: DriverInsightsLoadable,
  },
  [ROUTES.DRIVER_LOGIN_EVENT_DETAILS]: {
    trackingId: MixpanelEventKeys.PageDriverAccountEventDetailsProfile,
    template: template.orphanWithBackButton,
    component: EventsLoadable,
  },
  [ROUTES.DEVELOPER_DOCS]: {
    trackingId: MixpanelEventKeys.PageDeveloperDocs,
    template: template.fleet,
    component: loadable(() => import('components/developer')),
  },
  [ROUTES.USER_MANAGEMENT_MIGRATION_TOOL]: {
    template: template.fleet,
    component: loadable(() => import('components/user-migration-tool')),
    i18nTitle: (t: TFunction): string => t('User Management 2 Migration Tool'),
  },
  [ROUTES.CSV_INSTALLER]: {
    template: template.fleet,
    component: loadable(() => import('components/csv-installer')),
    i18nTitle: (t: TFunction): string => t('Install devices'),
  },
  [ROUTES.ENTITY_EVENTS]: {
    trackingId: MixpanelEventKeys.PageEntityEvents,
    template: template.subfleet,
    component: EventsLoadable,
    i18nTitle: (t: TFunction): string => t('Events'),
  },
  [ROUTES.ENTITY_EVENT]: {
    template: template.subfleet,
    component: EventsLoadable,
    i18nTitle: (t: TFunction): string => t('Events'),
  },
  [ROUTES.ENTITY_TRIPS]: {
    trackingId: MixpanelEventKeys.PageTrips,
    template: template.subfleet,
    component: MapLoadable,
    i18nTitle: (t: TFunction): string => t('Map'),
  },
  [ROUTES.VEHICLES_OVERVIEW]: {
    trackingId: MixpanelEventKeys.PageVehicles,
    template: template.subfleet,
    component: loadable(() => import('components/vehicles')),
    i18nTitle: (t: TFunction): string => t('Vehicles'),
  },
  [ROUTES.VEHICLES_DEVICE_MONITORING]: {
    trackingId: MixpanelEventKeys.PageVehicles,
    template: template.subfleet,
    component: loadable(() => import('components/vehicles')),
    i18nTitle: (t: TFunction): string => t('Vehicles'),
  },
  [ROUTES.VEHICLES]: {
    trackingId: MixpanelEventKeys.PageVehicles,
    template: template.subfleet,
    component: loadable(() => import('components/vehicles')),
    i18nTitle: (t: TFunction): string => t('Vehicles'),
  },
  [ROUTES.FLEET]: {
    template: template.nonFleet,
    component: (): React.ReactNode => false,
  },
  [ROUTES.F]: {
    template: template.nonFleet,
    component: (): React.ReactNode => false,
  },
};

export const routeKeys = Object.keys(routeMap);
