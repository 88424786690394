import React from 'react';
import styled from 'styled-components';
import NotificationsList from './notifications-list';

interface Props {
  className?: string;
  handleToggle?: () => void;
}

const NotificationsDropdown: React.FC<Props> = ({
  handleToggle,
  className,
}) => {
  return (
    <div className={className}>
      <NotificationsList handleToggle={handleToggle} />
    </div>
  );
};

const StyledComponent = styled(NotificationsDropdown)`
  width: 100%;
  min-width: 300px;
`;

export default StyledComponent;
