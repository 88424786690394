import * as API from '@nauto/api';
import { Session } from 'components/driver/coaching/session-note';
import { DriverCoachingAggregation, Score } from 'models/db';

export enum TableViews {
  Drivers = 'drivers',
  Subfleets = 'subfleets',
  RecentSessions = 'recent-sessions',
}

export interface RecentSessionData extends CoachingReport, Session {
  session: Session[];
}

export interface CoachingData extends CoachingReport {
  coachingData: DriverCoachingAggregation;
  first_name?: string;
  last_name?: string;
  driver_id?: string;
}

export interface CoachingReport {
  agg_score: Record<string, Score>;
  weekly_score: Score[];
  id: string;
  entity: API.Driver;
  ranking: { rank: number; count: number; vera: string };
}
