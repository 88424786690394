import React from 'react';
import { useTranslation } from 'react-i18next';
import { UnifiedCoachingLabels } from 'constants/coaching';

/* tslint:disable:no-duplicate-string */
export const strings = (t, type) => {
  switch (type) {
    case UnifiedCoachingLabels.RiskyManeuver:
      return t('Risky maneuver');
    case UnifiedCoachingLabels.TrafficViolation:
      return t('Traffic violation');
    case UnifiedCoachingLabels.Distraction:
      return t('Distraction');
    case UnifiedCoachingLabels.Drowsiness:
      return t('Drowsiness');
    case UnifiedCoachingLabels.Collision:
      return t('Collision');
    case UnifiedCoachingLabels.Tailgating:
      return t('Tailgating');
    case UnifiedCoachingLabels.Seatbelt:
      return t('No seat belt');
    case UnifiedCoachingLabels.Cellphone:
      return t('Cell phone');
    case UnifiedCoachingLabels.DeviceTampering:
      return t('Device tampering');
    case UnifiedCoachingLabels.RoadAwareness:
      return t('Road awareness');
    case UnifiedCoachingLabels.NearCollision:
      return t('Near collision');
    case UnifiedCoachingLabels.Smoking:
      return t('Smoking');
    case UnifiedCoachingLabels.AggressiveDriving:
      return t('Aggressive driving');
    case UnifiedCoachingLabels.BackingUp:
      return t('Backing up');
    case UnifiedCoachingLabels.ForwardCollisionWarning:
      return t('Forward collision warning');
    case UnifiedCoachingLabels.Cornering:
      return t('Cornering');
    case UnifiedCoachingLabels.HardAcceleration:
      return t('Hard acceleration');
    case UnifiedCoachingLabels.HardBraking:
      return t('Hard braking');
    case UnifiedCoachingLabels.Speeding:
      return t('Speeding');
    case UnifiedCoachingLabels.HitAndRun:
      return t('Hit and run');
    case UnifiedCoachingLabels.RoadRage:
      return t('Road rage');
    case UnifiedCoachingLabels.StopSign:
      return t('Stop sign');
    case UnifiedCoachingLabels.RollingStopSign:
      return t('Rolling stop sign');
    case UnifiedCoachingLabels.PedestrianCollisionWarning:
      return t('Pedestrian collision warning');
    case UnifiedCoachingLabels.IntersectionViolationWarning:
      return t('Intersection violation warning');
    default:
      return '';
  }
};

export interface Props {
  t?: any;
  type?: UnifiedCoachingLabels;
}

export const I18nCoachingString = (props: Props) => {
  let [t] = useTranslation();

  /**
   * In some circumstances we need to pass a `t` function in.
   * Such as in cases where React loses i18n context via portals.
   */
  if (props.t) {
    t = props.t;
  }

  return <span>{strings(t, props.type)}</span>;
};

export default I18nCoachingString;
