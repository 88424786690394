import moment from 'moment';
import mapboxgl, { Map } from 'mapbox-gl';
import { TripPoint } from 'redux/trips';
import sortedIndexBy from 'lodash-es/sortedIndexBy';

const radians = (deg: number): number => deg * (Math.PI / 180);
const degrees = (rad: number): number => rad * (180 / Math.PI);

export const getBearing = (start: TripPoint, end: TripPoint): number => {
  // eslint-disable-next-line prefer-const
  let [longStart, latStart] = start.location;
  // eslint-disable-next-line prefer-const
  let [longEnd, latEnd] = end.location;

  latStart = radians(latStart);
  latEnd = radians(latEnd);
  const dLong = radians(longEnd) - radians(longStart);
  const cosLatEnd = Math.cos(latEnd);

  return (
    (degrees(
      Math.atan2(
        Math.sin(dLong) * cosLatEnd,
        Math.cos(latStart) * Math.sin(latEnd) -
          Math.sin(latStart) * cosLatEnd * Math.cos(dLong),
      ),
    ) +
      360.0) %
    360.0
  );
};

interface SortableTimeObject {
  time: number;
}

// TODO: Find better location for this utility (it's only used in the trips reducer)
export const findNearestPoint = (
  scrubTime: moment.Moment,
  path: TripPoint[],
): { point: TripPoint; index: number } => {
  // null case
  if (!scrubTime || !path || !path.length) {
    return { point: null, index: -1 };
  }

  const scrubPoint: SortableTimeObject = { time: scrubTime.valueOf() };

  const index = sortedIndexBy(path as SortableTimeObject[], scrubPoint, 'time');
  const safeIndex = Math.min(index, path.length - 1);
  const point = path[safeIndex];
  return { point, index: safeIndex };
};

/**
 * Converts an array of coordinates to southwest and northeast bounds that encompass those points
 */
export const coordinatesToBounds = (
  coordinates: [[number, number], [number, number]],
): [[number, number], [number, number]] => {
  if (!coordinates.length) {
    return null;
  }
  const bounds = coordinates.reduce(
    (b, coord) => b.extend(new mapboxgl.LngLat(coord[0], coord[1])),
    new mapboxgl.LngLatBounds(),
  );
  return [bounds.getSouthWest().toArray(), bounds.getNorthEast().toArray()] as [
    [number, number],
    [number, number],
  ];
};

export const centeredBounds = (
  [longitude, latitude]: [number, number],
  paddingMiles: number,
): [[number, number], [number, number]] => {
  const DEGREES_IN_A_MILE = 1 / 69;
  // pad the zoom to 1/4 of a mile
  const pad = DEGREES_IN_A_MILE * paddingMiles;
  // pad the bounds by 1 mile.
  const southWest: [number, number] = [longitude - pad, latitude - pad];
  const northEast: [number, number] = [longitude + pad, latitude + pad];
  return [southWest, northEast];
};

// utility function that translates all layers of a mapbox instance to their respective language
export const translateLayers = (map: Map): void => {
  map
    .getStyle()
    .layers.filter(l => l.type === 'symbol')
    // Get all text labels
    .filter(l => map.getLayoutProperty(l.id, 'text-field') === '{name_en}')
    // instead of English, translate to language of the country the label is located in
    .forEach(l => map.setLayoutProperty(l.id, 'text-field', '{name}'));
};

export const MPS_TO_MPH_MULTIPLIER = 2.23694;
export const mpsToMph = (mpsVal: number): number =>
  mpsVal * MPS_TO_MPH_MULTIPLIER;
