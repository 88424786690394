import React from 'react';
import styled from 'styled-components';
import connect from 'utils/connect';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import DrawerSection from './drawer-section';
import { useTranslation } from 'react-i18next';
import EventNotificationRow from './notification-event';
import TaggingNotificationRow from './notification-tagging';
import HealthNotificationRow from './notification-health';
import { NotificationProps } from '../../models/db';
import ErrorBoundary from '../error-boundary/error-boundary';
import { compose } from 'recompose';
import { ROUTES } from 'routes/constants';
import { fleetLink } from 'components/fleet-link';
import { createStructuredSelector } from 'reselect';
import { FEATURES } from 'components/feature/constants';
import { fleetFeaturesSelector } from 'components/settings/settings.redux';
import { mpTrack } from 'components/mixpanel';
import { MixpanelEventKeys } from 'components/mixpanel/tags';
import { Features } from 'models/db';
import {
  selectActiveEventNotifications,
  selectBatchImportNotifications,
} from './notifications.redux';
import useFeatureFlags from 'hooks/use-feature-flags';
import BatchImportNotificationRow from './notification-batch-import';
import useUserPermissions, { Permissions } from 'hooks/use-permissions';
import { useDeviceIssuesQuery } from 'hooks/queries';
import { usePaginatedFaces } from 'hooks/use-faces-data';

interface ConnectedProps {
  notifications: NotificationProps[];
  batchImportNotifications: NotificationProps[];
  fleetFeatures?: Features;
}

interface Props extends ConnectedProps {
  archive?: boolean;
  className?: string;
  history?: History;
  handleToggle?: () => void;
}

const NotificationsList: React.FC<Props> = props => {
  const {
    className,
    notifications,
    batchImportNotifications,
    handleToggle,
    history,
    archive,
    fleetFeatures,
  } = props;
  const [t] = useTranslation();
  const featureFlags = useFeatureFlags();
  const { hasPermission } = useUserPermissions();
  const isNautoEssential = hasPermission(Permissions.NautoEssential);
  const { data } = useDeviceIssuesQuery();
  const { inServiceIssueCount } = data || {};
  const { data: paginatedFaces } = usePaginatedFaces();
  const { untaggedFacesCount } = paginatedFaces || {};
  const hasUntaggedFaces = !isNautoEssential && untaggedFacesCount > 0;
  const hasUnhealthyDevices = inServiceIssueCount > 0;
  const NoData = ({ children }) => <p className="no-data">{children}</p>;
  const handleTogglePopup = archive ? () => null : handleToggle;

  const taggingNotificationRow = (
    <TaggingNotificationRow
      handleToggle={() => {
        mpTrack(MixpanelEventKeys.NavNotificationTag);
        handleTogglePopup();
      }}
    />
  );

  const importNotificationRows = batchImportNotifications.map(n => (
    <BatchImportNotificationRow key={n.id} notification={n} />
  ));

  const eventNotificationRows = notifications?.map(n => (
    <EventNotificationRow
      notification={n}
      onLinkClick={handleTogglePopup}
      key={n.id}
    />
  ));

  const healthNotificationRow = hasUnhealthyDevices && (
    <HealthNotificationRow
      handleToggle={() => {
        mpTrack(MixpanelEventKeys.NavNotificationIssues);
        handleTogglePopup();
      }}
      unhealthyCount={inServiceIssueCount}
    />
  );

  const buttonText = archive ? null : t('See all');

  const buttonAction = archive
    ? null
    : () => {
        mpTrack(MixpanelEventKeys.NavNotificationSeeAll);
        history.push(fleetLink(ROUTES.NOTIFICATIONS));
        handleToggle();
      };

  const hasImportNotifications =
    featureFlags.importDriversCsv && importNotificationRows.length;
  const hasFleetHealthNotifications = !!inServiceIssueCount;
  const hasEventNotifications =
    !isNautoEssential && eventNotificationRows.length;
  const hasTaggingNotifications = fleetFeatures[FEATURES.DRIVER_MANAGEMENT];
  const hasNoNotifications = !(
    hasUntaggedFaces ||
    hasImportNotifications ||
    hasEventNotifications ||
    hasFleetHealthNotifications
  );

  return (
    <div className={`notifications-dropdown ${className}`}>
      <ErrorBoundary>
        <DrawerSection
          title={archive ? t('All Notifications') : t('Notifications')}
          height={archive ? 'full' : 'lg'}
          buttonText={buttonText}
          buttonAction={buttonAction}
          archive={archive}
        >
          {hasTaggingNotifications ? taggingNotificationRow : null}
          {hasImportNotifications ? importNotificationRows : null}
          {hasFleetHealthNotifications ? healthNotificationRow : null}
          {hasEventNotifications ? eventNotificationRows : null}
          {hasNoNotifications && <NoData>{t('No notifications')}</NoData>}
        </DrawerSection>
      </ErrorBoundary>
    </div>
  );
};

const StyledComponent = styled(NotificationsList)`
  width: 100%;
`;

const selector = createStructuredSelector({
  fleetFeatures: fleetFeaturesSelector,
  notifications: selectActiveEventNotifications,
  batchImportNotifications: selectBatchImportNotifications,
});

export default compose(withRouter, connect(selector))(StyledComponent);
