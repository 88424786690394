import { LoggedInUserType } from 'constants/roles';
import { createContext } from 'react';

interface Props {
  loggedInUserType?: LoggedInUserType;
  isLoggedInUserTypeDriver?: boolean;
}

const LoggedInUserTypeContext = createContext<Props>({
  loggedInUserType: LoggedInUserType.USER,
  isLoggedInUserTypeDriver: false,
});

export default LoggedInUserTypeContext;
