import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import { connect } from 'react-redux';
import { Dispatch, Action } from 'redux';
import { logout, setLoginRedirect } from 'components/auth/auth.actions';
import { getUser, getToken, getFleets } from 'utils/localstorage';
import AuthInterceptor from 'components/auth/interceptor';

interface Props {
  dispatch?: Dispatch<Action>;
  routeRequiresAuthentication: boolean;
  redirect: string;
  location?: Location;
  history?: History;
}

class AuthPersistence extends React.Component<Props> {
  authCheck = null;

  isAuthenticated = (): boolean => {
    const user = getUser();
    const token = getToken();
    const fleets = getFleets();
    return !!user && !!token && !!fleets;
  };

  checkStatus = () => {
    const {
      dispatch,
      location,
      history,
      routeRequiresAuthentication,
    } = this.props;

    if (!!routeRequiresAuthentication && !this.isAuthenticated()) {
      dispatch(setLoginRedirect(`${location.pathname}${location.search}`));
      dispatch(logout());
      history.push('/');
    }
  };

  componentDidMount() {
    this.checkStatus();
    window.addEventListener('storage', this.checkStatus);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.checkStatus);
  }

  render() {
    const { children, routeRequiresAuthentication } = this.props;
    return routeRequiresAuthentication
      ? this.isAuthenticated() && (
          <>
            <AuthInterceptor />
            {children}
          </>
        )
      : children;
  }
}

export default compose(connect(), withRouter)(AuthPersistence);
