import { WindowProps } from 'models/generics';
import { lsGet, Keys } from 'utils/localstorage';

interface ServerConfig {
  mapbox_key?: string;
  zendesk_key?: string;
  japan_zendesk_key?: string;
  is_development?: boolean;
  using_test_apis?: boolean;
  mapbox_geocode_base?: string;
  google_key?: string;
  admin_api_root?: string;
  admin_api_url?: string;
  admin_api_url_v3?: string;
  mixpanel_token?: string;
  cognito_client_id: string;
  cognito_auth_root: string;
  cognito_scope: string;
  driverid_debug_tool_url: string;
  zendesk_url: string;
  zendesk_username: string;
  zendesk_password: string;
  japan_zendesk_url: string;
  japan_zendesk_username: string;
  japan_zendesk_password: string;
  max_snapshot_x: number;
  max_snapshot_y: number;
  max_media_screen_height: number;
}

const config: ServerConfig = {
  // if loading from production, variables are accessed through window.server added via /server/variales.js built in index.js and template.html
  // if running localhost variables are found at process.env.DEV_ENV_VARIABLES, added via webpack definePlugin
  ...(lsGet(Keys.CUSTOM_CONFIG) ||
    (window as WindowProps).server ||
    process.env.DEV_ENV_VARIABLES),
};

export default config;
