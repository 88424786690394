import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { selectActiveEntityType } from 'components/entity-type/active-entity-type.redux';
import {
  EntityType,
  SubFleetsEntityType,
} from 'components/entity-type/entity-type-utils';
import { createSelector, createStructuredSelector } from 'reselect';
import { activeGroupSelector, Group } from 'components/groups/groups.redux';
import {
  activeDriverSelector,
  Drivers,
} from 'components/organization/drivers/drivers.redux';
import { selectActiveVehicle } from 'components/organization/vehicles/vehicles.redux';
import { Vehicle } from 'models/db';

export interface Filters {
  filters?: {
    activeGroup: Group;
    activeEntityType: EntityType | SubFleetsEntityType;
    activeEntityId: string;
    activeDriver: Drivers;
    activeVehicle: Vehicle;
    hash: string;
  };
}

class WithFilters extends React.Component<Filters> {
  render() {
    return React.Children.map(
      this.props.children,
      (child: ReactElement<Filters>) =>
        React.cloneElement(child, { ...this.props }),
    );
  }
}

// select all the state properties that can be filtered
const filtersSelector = createSelector(
  selectActiveEntityType,
  activeGroupSelector,
  activeDriverSelector,
  selectActiveVehicle,
  (activeEntityType, activeGroup, activeDriver, activeVehicle) => {
    return {
      activeEntityType,
      activeGroup,
      activeEntityId:
        activeEntityType === EntityType.DRIVER
          ? activeDriver && activeDriver.id
          : activeVehicle && activeVehicle.id,
      activeDriver,
      activeVehicle,
    };
  },
);

// create a hash of the filters so that one can easily compare filterDidChange by (oldProps.filter.hash !== newProps.filter.hash)
const selectFiltersWithHash = createSelector(filtersSelector, filters => ({
  ...filters,
  hash: JSON.stringify({
    activeEntityType: filters.activeEntityType,
    activeGroup: filters.activeGroup,
    activeEntityId: filters.activeEntityId,
  }),
}));

export const filtersStructuredSelector = createStructuredSelector({
  filters: selectFiltersWithHash,
});

export default connect<Filters, any, any>(filtersStructuredSelector)(
  WithFilters,
);
