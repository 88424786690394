import React from 'react';
import {
  DistractionEventIcon,
  AccelerationEventIcon,
  BrakingEventIcon,
  CorneringEventIcon,
  TailgatingEventIcon,
  CollisionEventIcon,
  NearCollisionIcon,
  MarkEventIcon,
  AlertIcon,
  CloudDownloadIcon,
  CellphoneIcon,
  SmokingIcon,
  SeatbeltIcon,
  DrowsyIcon,
  SpeedIcon,
  BackingupIcon,
  RiskyManeuverIcon,
} from '@nauto/icons';

// the raw event types that are retrieved via APIs
export enum EventType {
  ACCELERATION = 'acceleration-hard',
  ACCELERATION_RISK = 'acceleration',
  BRAKING = 'braking-hard',
  BRAKING_SEVERE = 'braking-severe',
  BRAKING_HARD_SEVERE = 'braking-hard-severe',
  BRAKING_RISK = 'braking',
  // tslint:disable-next-line
  CELL_PHONE = 'cell-phone',
  CORNERING_LEFT = 'corner-left-hard',
  CORNERING_RIGHT = 'corner-right-hard',
  CORNERING_RISK = 'cornering',
  CRASHNET = 'crashnet',
  DISTRACTION = 'distraction',
  DISTRACTION_RESEARCH = 'distraction-research',
  CONFIRMED_DISTRACTION = 'review-confirmed-distraction',
  TAILGATING = 'tailgating',
  CONFIRMED_TAILGATING = 'review-confirmed-tailgating',
  MARK = 'mark',
  MARK_PANIC = 'mark-panic',
  NEAR_COLLISION = 'near-collision',
  REQUEST = 'custom-video-request',
  REQUESTED = 'requested',
  POSSIBLE_COLLISION = 'severe-g-event',
  SMOKING = 'smoking',
  UNSURE_COLLISION = 'review-unsure-severe-g-event',
  CONFIRMED_COLLISION = 'review-confirmed-severe-g-event',
  SNAPSHOT_IN = 'snapshot-in',
  SNAPSHOT_OUT = 'snapshot-out',
  SEATBELT = 'no-seat-belt',
  DROWSINESS = 'drowsiness',
  SPEEDING = 'speeding',
  BACKING_UP = 'maneuver-back-up',
  POSTED_SPEEDING = 'posted-speeding',
  SAFETY_INCIDENT = 'safety-incident',
}

export enum AllowedEvent {
  Collision = 'collision',
  Acceleration = 'acceleration',
  Braking = 'braking',
  Cornering = 'cornering',
  Distraction = 'distraction',
  Tailgating = 'tailgating',
  Request = 'request',
  Smoking = 'smoking',
  // tslint:disable-next-line
  CellPhone = 'cell-phone',
  ForwardCollisionWarning = 'fcw',
  Mark = 'mark',
  PostedSpeeding = 'posted-speeding',
  NoSeatBelt = 'no-seat-belt',
  NearCollision = 'near-collision',
  Drowsiness = 'drowsiness',
  RepeatedEvents = 'repeated-events',
}

// the presentation of each event type in the UIs
// KEY - the raw event message type
export interface TypeMap {
  kind: Kind; // kind - the variety of event - determines color and some display logic
  name: nauto.EventType; // name - the displayed text for that event
  icon?: string; // iconPath - the path of the event icon to use
  Icon?: React.ReactNode; // If passing icon component directly. i.e. using @nauto/icons
}

export enum Kind {
  risk = 'risk',
  action = 'action',
  collision = 'collision',
  violation = 'violation',
}

export const eventDisplayMap: { [kind: string]: TypeMap } = {
  default: {
    kind: Kind.risk,
    name: 'distraction',
    icon: 'distraction',
    Icon: DistractionEventIcon,
  },
  [EventType.ACCELERATION]: {
    kind: Kind.risk,
    name: 'acceleration',
    icon: 'acceleration',
    Icon: AccelerationEventIcon,
  },
  [EventType.ACCELERATION_RISK]: {
    kind: Kind.risk,
    name: 'acceleration',
    icon: 'acceleration',
    Icon: AccelerationEventIcon,
  },
  [EventType.BRAKING]: {
    kind: Kind.risk,
    name: 'braking-hard',
    icon: 'braking',
    Icon: BrakingEventIcon,
  },
  [EventType.BRAKING_SEVERE]: {
    kind: Kind.risk,
    name: 'severe-braking',
    icon: 'braking',
    Icon: BrakingEventIcon,
  },
  [EventType.BRAKING_HARD_SEVERE]: {
    kind: Kind.risk,
    name: 'severe-braking',
    icon: 'braking',
    Icon: BrakingEventIcon,
  },
  [EventType.BRAKING_RISK]: {
    kind: Kind.risk,
    name: 'braking-hard',
    icon: 'braking',
    Icon: BrakingEventIcon,
  },
  [EventType.CORNERING_LEFT]: {
    kind: Kind.risk,
    name: 'cornering',
    icon: 'cornering',
    Icon: CorneringEventIcon,
  },
  [EventType.CORNERING_RIGHT]: {
    kind: Kind.risk,
    name: 'cornering',
    icon: 'cornering',
    Icon: CorneringEventIcon,
  },
  [EventType.CORNERING_RISK]: {
    kind: Kind.risk,
    name: 'cornering',
    icon: 'cornering',
    Icon: CorneringEventIcon,
  },
  [EventType.DISTRACTION]: {
    kind: Kind.risk,
    name: 'distraction',
    icon: 'distraction',
    Icon: DistractionEventIcon,
  },
  [EventType.DISTRACTION_RESEARCH]: {
    kind: Kind.risk,
    name: 'distraction',
    icon: 'distraction',
    Icon: DistractionEventIcon,
  },
  [EventType.CONFIRMED_DISTRACTION]: {
    kind: Kind.risk,
    name: 'distraction',
    icon: 'distraction',
    Icon: DistractionEventIcon,
  },
  [EventType.TAILGATING]: {
    kind: Kind.risk,
    name: 'tailgating',
    icon: 'tailgating',
    Icon: TailgatingEventIcon,
  },
  [EventType.CONFIRMED_TAILGATING]: {
    kind: Kind.risk,
    name: 'tailgating',
    icon: 'tailgating',
    Icon: TailgatingEventIcon,
  },
  [EventType.POSSIBLE_COLLISION]: {
    kind: Kind.collision,
    name: 'possible-collision',
    icon: 'collision',
    Icon: CollisionEventIcon,
  },
  [EventType.CRASHNET]: {
    kind: Kind.collision,
    name: 'crashnet',
    icon: 'collision',
    Icon: CollisionEventIcon,
  },
  [EventType.UNSURE_COLLISION]: {
    kind: Kind.collision,
    name: 'possible-collision',
    icon: 'collision',
    Icon: CollisionEventIcon,
  },
  [EventType.CONFIRMED_COLLISION]: {
    kind: Kind.collision,
    name: 'collision',
    icon: 'collision',
    Icon: CollisionEventIcon,
  },
  [EventType.NEAR_COLLISION]: {
    kind: Kind.collision,
    name: 'near-collision',
    icon: 'nearCollision',
    Icon: NearCollisionIcon,
  },
  [EventType.MARK]: {
    kind: Kind.action,
    name: 'marked',
    icon: 'star',
    Icon: MarkEventIcon,
  },
  [EventType.MARK_PANIC]: {
    kind: Kind.action,
    name: 'mark-panic',
    icon: 'panic',
    Icon: AlertIcon,
  },
  [EventType.REQUEST]: {
    kind: Kind.action,
    name: 'requested',
    icon: 'videoRequest',
    Icon: CloudDownloadIcon,
  },
  [EventType.REQUESTED]: {
    kind: Kind.action,
    name: 'requested',
    icon: 'videoRequest',
    Icon: CloudDownloadIcon,
  },
  [EventType.CELL_PHONE]: {
    kind: Kind.violation,
    name: 'cell-phone',
    icon: 'cellPhone',
    Icon: CellphoneIcon,
  },
  [EventType.SMOKING]: {
    kind: Kind.violation,
    name: 'smoking',
    icon: 'cigarette',
    Icon: SmokingIcon,
  },
  [EventType.SEATBELT]: {
    kind: Kind.violation,
    name: 'no-seat-belt',
    icon: 'noSeatBelt',
    Icon: SeatbeltIcon,
  },
  [EventType.DROWSINESS]: {
    kind: Kind.violation,
    name: 'drowsiness',
    icon: 'drowsy',
    Icon: DrowsyIcon,
  },
  [EventType.SPEEDING]: {
    kind: Kind.risk,
    name: 'speeding',
    icon: 'speed',
    Icon: SpeedIcon,
  },
  [EventType.BACKING_UP]: {
    kind: Kind.violation,
    name: 'backing-up',
    Icon: BackingupIcon,
  },
  [EventType.SAFETY_INCIDENT]: {
    kind: Kind.risk,
    name: 'safety-incident',
    icon: 'riskyManeuver',
    Icon: RiskyManeuverIcon,
  },
};

export default eventDisplayMap;
