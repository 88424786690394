import * as API from '@nauto/api';
import config from 'utils/config';
import { getUser, getFleetId } from 'utils/localstorage';
import { fetchRequest } from 'utils/requests';
import { AlertType } from 'constants/alert-types';
import { AlertResponse, AlertNotification } from './types';
import { mapAlertResponseToNotifications } from './utils';

const getAlerts = (): Promise<AlertNotification[]> => {
  const userId = getUser().id;
  const url = `${config.admin_api_url}/alerts/users/${userId}`;

  return fetchRequest(url, { method: 'GET' }).then((alerts: AlertResponse) =>
    mapAlertResponseToNotifications(alerts, [
      AlertType.Braking,
      AlertType.Distraction,
      AlertType.Tailgating,
    ]),
  );
};

const postAlert = ({ body }): Promise<AlertNotification[]> => {
  const userId = getUser().id;
  const url = `${config.admin_api_url}/alerts/users/${userId}`;

  return fetchRequest(url, { method: 'POST', body }).then(alerts =>
    mapAlertResponseToNotifications(alerts, [
      AlertType.Braking,
      AlertType.Distraction,
      AlertType.Tailgating,
    ]),
  );
};

const postV3Alert = (body, roleId: string): Promise<AlertNotification[]> => {
  const fleetId = getFleetId();
  const userId = getUser().id;

  return API.profile
    .updatePreferences(
      { userId, fleetId, roleId, body },
      { url: config.admin_api_url_v3 },
    )
    .then(response => {
      const alerts = response.alerts?.[roleId];
      if (alerts) {
        return mapAlertResponseToNotifications({ alerts, success: true }, [
          AlertType.Braking,
          AlertType.Distraction,
          AlertType.Tailgating,
        ]);
      }
    });
};

export default {
  postAlert,
  postV3Alert,
  getAlerts,
};
