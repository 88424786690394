import { Action, Dispatch } from 'redux';
import * as API from '@nauto/api';

export enum Constants {
  SEND_REBOOT_MESSAGE_START = 'reboot/reboot-msg-start',
  SEND_REBOOT_MESSAGE_ERROR = 'reboot/reboot-msg-error',
  SEND_REBOOT_MESSAGE_SUCCESS = 'reboot/reboot-msg-success',
}

/* tslint:disable:no-duplicate-string */
export const sendRebootMessage = (
  deviceId: string,
  messageType: string,
  messageBody?: any,
) => (dispatch: Dispatch<Action>, getState) => {
  const fleetId = getState().user.currentFleet;
  dispatch({ type: Constants.SEND_REBOOT_MESSAGE_START });

  return API.admin
    .sendAdminCommand({ deviceId, messageType, messageBody, fleetId })
    .then(response => {
      return dispatch({
        type: Constants.SEND_REBOOT_MESSAGE_SUCCESS,
        payload: { ...response },
      });
    })
    .catch(error => {
      return dispatch({
        type: Constants.SEND_REBOOT_MESSAGE_ERROR,
        payload: {
          error: error.message,
        },
      });
    });
};
/* tslint:enable:no-duplicate-string */
export interface ReducerState {
  updating: boolean;
  error: string;
}

export const initialState: ReducerState = {
  updating: false,
  error: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case Constants.SEND_REBOOT_MESSAGE_START:
      return {
        ...state,
        updating: true,
        error: '',
      };

    case Constants.SEND_REBOOT_MESSAGE_ERROR:
      return {
        ...state,
        updating: false,
        error: payload.error,
      };

    case Constants.SEND_REBOOT_MESSAGE_SUCCESS:
      return {
        ...state,
        updating: false,
        error: '',
      };

    default:
      return state;
  }
};
