import React from 'react';
import styled from 'styled-components';
import Modal from '@nauto/modal';
import { ModalProps } from 'models/db';
import { Primary, Alert } from '@nauto/uikit/dist/atoms/buttons';
import Title from 'atoms/title';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface PropTypes extends ModalProps, WithTranslation {
  isError?: boolean;
}

const ModalBody = styled.div`
  padding: 3rem;
  text-align: center;
  font-size: 1rem;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  margin: 0;
  flex: 1;
  button {
    width: auto;
    height: 2.5rem;
    padding: 0 4.5rem;
    font-size: 1rem;
  }
`;

class InfoModal extends React.Component<PropTypes, any> {
  render() {
    const { className, t, content, isError, onClose, title } = this.props;

    return (
      <Modal open={true} onClose={onClose} bodyWidth={550}>
        <>
          {title && <Title>{title}</Title>}
          <ModalBody className={className}>{content}</ModalBody>
          <ModalFooter>
            {isError ? (
              <Alert className="confirm-btn" onClick={onClose}>
                {t('OK')}
              </Alert>
            ) : (
              <Primary className="confirm-btn" onClick={onClose}>
                {t('OK')}
              </Primary>
            )}
          </ModalFooter>
        </>
      </Modal>
    );
  }
}

export default withTranslation()(InfoModal);
