import { fetchRequest } from 'utils/requests';
import { cacheBustUrl, path } from 'utils/helpers';
import { getFleetId, getServiceUrl } from 'utils/localstorage';

// prettier-ignore
export const driverInvitesSelector = ({ driverInvites }): DriverInvites => driverInvites;

export enum ActionTypes {
  OPEN_INVITE_MODAL = 'driver-invites/open-modal',
  CLOSE_INVITE_MODAL = 'driver-invites/close-modal',
  CLEAR_INVITE_DRIVER_DATA = 'driver-invites/clear-data',
  INVITE_DRIVER_BATCH_START = 'driver-invites/start-invite-drivers',
  INVITE_DRIVER_BATCH_END = 'driver-invites/end-invite-drivers',
  INVITE_DRIVER_SUCCESS = 'driver-invites/invite-success',
  INVITE_DRIVER_FAIL = 'driver-invites/invite-fail',
}

export const clearInviteData = () => dispatch => {
  dispatch({
    type: ActionTypes.CLEAR_INVITE_DRIVER_DATA,
  });
};

export const openInviteModal = () => dispatch => {
  dispatch({
    type: ActionTypes.OPEN_INVITE_MODAL,
  });
};

export const closeInviteModal = () => dispatch => {
  dispatch({
    type: ActionTypes.CLOSE_INVITE_MODAL,
  });
};

export const inviteDrivers = (driverIDs: string[]) => dispatch => {
  dispatch({
    type: ActionTypes.INVITE_DRIVER_BATCH_START,
    payload: { driverIDs },
  });

  const BATCH_SIZE = 5;

  const sendInvites = (invitesSent = 0) => {
    const minIndex = invitesSent;
    const maxIndex = Math.min(minIndex + BATCH_SIZE, driverIDs.length);
    const requestIDs = driverIDs.slice(minIndex, maxIndex);
    const requests = requestIDs.map(id => dispatch(sendDriverInvite(id)));

    Promise.all(requests).then(responses => {
      const allDriversInvited = maxIndex >= driverIDs.length;

      if (allDriversInvited) {
        return dispatch({
          type: ActionTypes.INVITE_DRIVER_BATCH_END,
          payload: { driverIDs },
        });
      }

      sendInvites(maxIndex);
    });
  };
  sendInvites();
};

export const sendDriverInvite = (driverID: string) => (dispatch, getState) => {
  const url = cacheBustUrl(
    path(`${getServiceUrl()}/fleets/${getFleetId()}/driver-invite`),
  );

  return fetchRequest(url, {
    method: 'POST',
    body: { driver_id: driverID, channel: 'email' },
  })
    .then(resp => {
      return dispatch({
        type: ActionTypes.INVITE_DRIVER_SUCCESS,
        payload: { driverID },
      });
    })
    .catch(error => {
      dispatch({ type: ActionTypes.INVITE_DRIVER_FAIL, payload: { driverID } });
    });
};

export interface DriverInvites {
  selectedDrivers: string[];
  sendingBatchInvites: boolean;
  showInviteModal: boolean;
  batchInvitesDone: boolean;
  successCount: number;
  errorCount: number;
}

export const defaultState = {
  selectedDrivers: [],
  sendingBatchInvites: false,
  showInviteModal: false,
  batchInvitesDone: false,
  successCount: 0,
  errorCount: 0,
};

export default (state: DriverInvites = defaultState, { type, payload }) => {
  switch (type) {
    case ActionTypes.OPEN_INVITE_MODAL:
      return {
        ...state,
        showInviteModal: true,
      };
    case ActionTypes.CLOSE_INVITE_MODAL:
    case ActionTypes.CLEAR_INVITE_DRIVER_DATA:
      return defaultState;
    case ActionTypes.INVITE_DRIVER_BATCH_START:
      return {
        ...state,
        sendingBatchInvites: true,
        selectedDrivers: payload.driverIDs,
      };
    case ActionTypes.INVITE_DRIVER_BATCH_END:
      return {
        ...state,
        sendingBatchInvites: false,
        batchInvitesDone: true,
      };
    case ActionTypes.INVITE_DRIVER_SUCCESS:
      return {
        ...state,
        successCount: state.successCount + 1,
      };
    case ActionTypes.INVITE_DRIVER_FAIL:
      return {
        ...state,
        errorCount: state.errorCount + 1,
      };
    default:
      return state;
  }
};
