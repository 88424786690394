import React from 'react';
import { usePaginatedFaces } from './queries';
import { PaginatedFacesProps } from './types';

const withPaginatedFacesQuery = <T extends unknown>(): ((
  WrappedComponent: React.ComponentType<T>,
) => React.ComponentType<T & PaginatedFacesProps>) => WrappedComponent => {
  const WithPaginatedFacesQuery = props => {
    const queryData = usePaginatedFaces();
    const passDownProps = {
      ...props,
      paginatedFaces: {
        ...queryData,
      },
    };

    return React.createElement(WrappedComponent, passDownProps);
  };

  WithPaginatedFacesQuery.displayName = `withPaginatedFacesQuery(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'Unknown'})})`;

  WithPaginatedFacesQuery.WrappedComponent = WrappedComponent;

  return WithPaginatedFacesQuery;
};

export { withPaginatedFacesQuery };
