import React, { useState } from 'react';
import { compose } from 'recompose';
import { MobileNavBar, MobileNavMenu } from '@nauto/primary-navbar';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import withCurrentFleet from 'decorators/with-current-fleet';
import { withTranslation, WithTranslation } from 'react-i18next';
import withFeatureFlags, {
  FeatureFlagsProps,
} from 'decorators/with-feature-flags';
import { ROUTES } from 'routes/constants';
import { fleetLink } from 'components/fleet-link';
import { zIndex, spaceSizesPx } from '@nauto/core';
import ComponentActions from 'atoms/component-actions';
import MobileNavMenuComponent from 'components/topbar-mobile-navigation/mobile-nav-menu-component';
import { img } from 'constants/images';
import { mobileNavBarHeight } from 'constants/layout';
import useLoggedInUserType from 'hooks/use-logged-in-user-type';
import useUserPermission, {
  UserPermissionData,
  Permissions,
} from 'hooks/use-permissions';
import useUserData from 'hooks/use-user-data';

interface Props extends WithTranslation, FeatureFlagsProps, UserPermissionData {
  currentFleet?: string;
  currentGroup?: string;
}

export const TopbarMobileNavigation: React.FC<Props> = props => {
  const { currentFleet, currentGroup, featureFlags } = props;
  const [opened, setOpened] = useState(false);
  const { hasPermission } = useUserPermission();
  const { isLoggedInUserTypeDriver } = useLoggedInUserType();
  const user = useUserData();

  const onMobileMenuActive = () => {
    setOpened(!opened);
  };
  const onHandleClose = () => {
    setOpened(false);
  };

  const mobileLogo =
    !isLoggedInUserTypeDriver &&
    hasPermission(Permissions.WriteEvents, false) ? (
      <Link
        to={fleetLink({
          pathname: featureFlags.newHome ? ROUTES.HOME : ROUTES.INSIGHTS,
          fleetId: currentFleet,
          groupId: currentGroup,
        })}
      >
        <MobileNavLogo
          data-testid="primary-mobile-nav-logo"
          src={img.mobile_nav_logo}
        />
      </Link>
    ) : (
      <MobileNavLogo
        data-testid="primary-mobile-nav-logo"
        src={img.mobile_nav_logo}
      />
    );

  return (
    <Wrapper className="primary-wrapper">
      <MobileNavBar>
        {mobileLogo}
        <>
          <MobileNavMenuWrapper onClick={onMobileMenuActive}>
            <MobileNavMenu showCloseIcon={opened} />
          </MobileNavMenuWrapper>
          <MobileNavMenuComponentWrapper>
            {opened && (
              <ComponentActions onClickOutside={() => onHandleClose()}>
                <MobileNavMenuComponent
                  onItemClick={onHandleClose}
                  user={user}
                />
              </ComponentActions>
            )}
          </MobileNavMenuComponentWrapper>
        </>
      </MobileNavBar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: ${mobileNavBarHeight}px;
  position: fixed;
  z-index: ${zIndex.navbar};
`;

const MobileNavMenuWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const MobileNavMenuComponentWrapper = styled.div`
  width: 100%;
`;

const MobileNavLogo = styled.img`
  width: 85px;
  min-height: ${spaceSizesPx.xl};
  height: ${spaceSizesPx.xl};
  margin-bottom: 0;
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export default compose(
  withTranslation(),
  withFeatureFlags,
  withCurrentFleet,
)(TopbarMobileNavigation);
