import { connect } from 'react-redux';

export default (
  mapStateToProps,
  mapDispatchToProps?,
  mergeProps?,
  options?,
) => {
  return target =>
    connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps,
      options,
    )(target) as any;
};
