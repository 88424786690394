import {
  atRiskVera2Threshold,
  atRiskVera3Threshold,
} from 'constants/event-thresholds';
import { TYPE } from 'constants/events';
import { createContext } from 'react';
import { VeraVersionType } from 'utils/vera-score-utils';
import { languageIsJapanese } from 'utils/helpers';

interface Props {
  veraVersionType?: VeraVersionType;
  toggleVeraVersionType?: (versionType) => void;
  isScoreVersionVera3?: boolean;
  atRiskVeraThreshold?: number;
  collisionEventType?: string;
}

const VeraScoreTypeDataContext = createContext<Props>({
  veraVersionType: VeraVersionType.VERA3,
  toggleVeraVersionType: versionType => versionType,
  isScoreVersionVera3: true,
  atRiskVeraThreshold: atRiskVera2Threshold,
  collisionEventType: TYPE.CONFIRMED_COLLISION_VERA3,
});

export default VeraScoreTypeDataContext;
