import React from 'react';
import { mpTrack, mpTimer } from './';

const mpTrackRoute = (routeName: string) => Wrapped => {
  class MixpanelTrackRoute extends React.Component {
    componentDidMount() {
      mpTrack(`Route / ${routeName}`);
      mpTimer(`Route Leave / ${routeName}`);
    }

    componentWillUnmount() {
      mpTrack(`Route Leave / ${routeName}`);
    }

    render() {
      return <Wrapped {...this.props} />;
    }
  }

  return MixpanelTrackRoute;
};

export default mpTrackRoute as any;
