import { mapValues, get } from 'lodash-es';
import { mean, standardDeviation } from 'utils/math';
import {
  PeriodAPIData,
  PeriodData,
  ReportRowAPIData,
  ReportRowData,
} from './types';
import { ROUTES } from 'routes/constants';
import { FleetLinkTo } from 'components/fleet-link';
import { FILTER, TYPE } from 'constants/events';
import * as API from '@nauto/api';
import moment, { Moment } from 'moment';
let momentObj;

if (
  typeof WorkerGlobalScope !== 'undefined' &&
  self instanceof WorkerGlobalScope
) {
  importScripts('https://momentjs.com/downloads/moment.min.js');
  momentObj = moment;
} else {
  momentObj = require('moment-timezone');
}

export const MINIMUM_SCORED_HOURS_VERA2 = 2;
export const MINIMUM_SCORED_HOURS_VERA3 = 0;

/* in vera2, the entity must have a driving duration of atleast 2hrs or above
  in order to be scored. in vera3, the threshold limit was removed and if the
  entity has some significant driving duration will be scored.
*/
export const isSignificant = (
  data?: ReportRowData,
  isScoreVersionVera3?: boolean,
): boolean => {
  return isScoreVersionVera3
    ? Boolean(data.movingTime && data.movingTime > 0)
    : Boolean(data.movingTime && data.movingTime >= MINIMUM_SCORED_HOURS_VERA2);
};

export const msToHours = (ms: number): number => ms / 3600000;

export const RISK_EVENT_COLUMNS = [
  FILTER.ACCELERATION,
  FILTER.BRAKING,
  FILTER.CORNERING,
  FILTER.DISTRACTION,
  FILTER.TAILGATING,
  FILTER.SPEEDING,
  FILTER.DROWSINESS,
];

export const scoreToStatData = (
  type: string,
  rowData: ReportRowData,
  meanRates: { [type: string]: { meanRate: number; std: number } },
) => {
  const columnData = rowData.events[type];
  const { meanRate, std } = meanRates[type];
  const stdDev = std ? (columnData.rate - meanRate) / std : 0;
  const severityScaled = Math.max(stdDev, 0) / 3;
  const severityLevel = Math.min(severityScaled, 1);
  return {
    ...columnData,
    stdDev,
    mean: meanRate,
    severityLevel,
  };
};

export const durationToStatData = (
  type: string,
  rowData: ReportRowData,
  meanRates: { [type: string]: { mean: number; std: number } },
) => {
  const columnData = get(rowData, `agg_score.statistics.${type}`);
  const { mean, std } = meanRates[type];
  const stdDev = std ? (columnData - mean) / std : 0;
  const severityScaled = Math.max(stdDev, 0) / 3;
  const severityLevel = Math.min(severityScaled, 1);
  return {
    duration: columnData,
    stdDev,
    mean,
    severityLevel,
  };
};

export const getCleanReportsData = (
  reportData: API.reports.ReportItem[] | ReportRowAPIData[],
  indexedGroups?: any,
  isScoreVersionVera3 = false,
  collisionEventType = TYPE.CONFIRMED_COLLISION_VERA3,
): any => {
  const speedingType = isScoreVersionVera3 ? 'posted-speeding' : 'speeding';
  const cleanReportsData = reportData.reduce(
    (data, entityData: ReportRowAPIData) => {
      const entityDetails = indexedGroups && indexedGroups[entityData.group_id];

      const id =
        entityData.driver_id ||
        entityData.vehicle_id ||
        (entityDetails && entityDetails.id);

      if (!id) {
        return data;
      }

      const cleanedPeriodData = cleanPeriodData(
        entityData.agg_score,
        collisionEventType,
        speedingType,
      );

      cleanedPeriodData.agg_score = entityData.agg_score;
      cleanedPeriodData.daily_score = entityData.daily_score;
      cleanedPeriodData.driver_id = entityData.driver_id;
      cleanedPeriodData.vehicle_id = entityData.vehicle_id;
      cleanedPeriodData.monthly_score = entityData.monthly_score;
      cleanedPeriodData.score_version = entityData.score_version;
      cleanedPeriodData.weekly_score = entityData.weekly_score;

      const entityGroupDetails = entityDetails && entityDetails.group_ids;
      const entityGroupId =
        entityGroupDetails &&
        entityGroupDetails.length &&
        entityGroupDetails[0];

      cleanedPeriodData.entity = entityDetails;
      cleanedPeriodData.group_id = entityGroupId
        ? entityGroupId
        : entityDetails && entityDetails.id;
      cleanedPeriodData.id = id;
      cleanedPeriodData.scores = cleanedPeriodData.agg_score.risk_scores;

      data.push(cleanedPeriodData);
      return data;
    },
    [],
  );

  const driversWithSignificantDriving = cleanReportsData.filter(isSignificant);

  const meanRates = RISK_EVENT_COLUMNS.reduce((means, column) => {
    const rates = driversWithSignificantDriving.map(
      driverReport => driverReport.events[column].rate,
    );
    const meanVal = mean(rates);
    const stdVal = standardDeviation(rates, meanVal);
    return {
      ...means,
      [column]: {
        meanRate: meanVal,
        std: stdVal,
      },
    };
  }, {});

  const speedingDurationType = isScoreVersionVera3
    ? 'max_speeding_duration'
    : 'speeding_duration';
  const statistics = [speedingDurationType, 'moving_duration'];
  const totalDurationsObj = {
    moving_duration: [],
    speeding_duration: [],
    max_speeding_duration: [],
  };

  driversWithSignificantDriving.forEach(driverReport => {
    const statisticsData = driverReport.agg_score.statistics;

    for (const s of statistics) {
      if (totalDurationsObj[s]) {
        totalDurationsObj[s].push(statisticsData[s]);
      }
    }
  });

  const meanDurations = statistics.reduce((t, type) => {
    const total = totalDurationsObj[type];
    const m = mean(total);
    const s = standardDeviation(total, m);
    return {
      ...t,
      [type]: {
        mean: m,
        std: s,
      },
    };
  }, {});

  const combinedData = cleanReportsData.map(
    (rowData: ReportRowData): ReportRowData => {
      return {
        ...rowData,
        events: {
          ...rowData.events,
          acceleration: scoreToStatData('acceleration', rowData, meanRates),
          braking: scoreToStatData('braking', rowData, meanRates),
          cornering: scoreToStatData('cornering', rowData, meanRates),
          distraction: scoreToStatData('distraction', rowData, meanRates),
          tailgating: scoreToStatData('tailgating', rowData, meanRates),
          drowsiness: scoreToStatData('drowsiness', rowData, meanRates),
          speeding: scoreToStatData('speeding', rowData, meanRates),
        },
        rates: {
          /* maxSpeedDuration stats */
          speedingDuration: durationToStatData(
            speedingDurationType,
            rowData,
            meanDurations,
          ),
          movingDuration: durationToStatData(
            'moving_duration',
            rowData,
            meanDurations,
          ),
        },
      };
    },
  );
  return combinedData;
};

export const cleanPeriodData = (
  periodRespData: PeriodAPIData,
  collisionEventType: string,
  speedingType: string,
): PeriodData => {
  const { statistics, start_time, end_time, risk_scores } = periodRespData;
  const tripDuration = statistics && statistics.trip_duration;
  const movingDuration = statistics && statistics.moving_duration;
  const unassignedTripDuration =
    statistics && statistics.unassigned_trip_duration;
  const movingTimeHours = msToHours(movingDuration);
  const tripTimeHours = msToHours(tripDuration);
  const timeRangeMilliseconds =
    momentObj(end_time).valueOf() - momentObj(start_time).valueOf();
  const rangeHours = msToHours(timeRangeMilliseconds);
  const parkedTimeMilliseconds = timeRangeMilliseconds - tripDuration;
  const parkedTime = msToHours(parkedTimeMilliseconds);
  const unassignedTime = msToHours(unassignedTripDuration);
  const tripRate: number = (tripDuration / timeRangeMilliseconds) * 100;
  const movingRate: number = (tripDuration / tripRate) * 100;
  const idleRate = 100 - Math.floor(movingRate);
  const parkedRate = (parkedTimeMilliseconds / timeRangeMilliseconds) * 100;

  // convert raw event types to client consumable event types
  const eventCountMap = {
    acceleration: totalEvents(periodRespData, 'acceleration-hard'),
    braking: totalEvents(periodRespData, 'braking-hard'),
    cornering: mergeTotalEvents(
      totalEvents(periodRespData, 'corner-left-hard'),
      totalEvents(periodRespData, 'corner-right-hard'),
    ),
    distraction: totalEvents(periodRespData, 'distraction'),
    tailgating: totalEvents(periodRespData, 'tailgating'),
    smoking: totalEvents(periodRespData, 'smoking'),
    cell_phone: totalEvents(periodRespData, 'cell-phone'),
    speeding: totalEvents(periodRespData, speedingType),
    collision: totalEvents(periodRespData, collisionEventType),
    collisions: totalEvents(periodRespData, collisionEventType),
    drowsiness: totalEvents(periodRespData, 'drowsiness'),
  };

  const eventCountAndRateMap: any = mapValues(
    eventCountMap,
    (eventObj: any, id) => ({
      count: eventObj.count,
      rate: movingTimeHours
        ? id === 'drowsiness'
          ? (100 / movingTimeHours) * eventObj.count
          : eventObj.count / movingTimeHours
        : 0,
      severityLevel: eventObj.severity,
    }),
  );

  return {
    events: eventCountAndRateMap,
    tripTime: tripTimeHours,
    movingTime: movingTimeHours,
    idleTime: tripTimeHours - movingTimeHours,
    parkedTime,
    range: rangeHours,
    tripRate,
    parkedRate,
    movingRate,
    idleRate,
    start: momentObj(start_time),
    end: momentObj(end_time),
    scores: risk_scores,
    unassignedTime,
  };
};

const totalEvents = (data: PeriodAPIData, type: string) => {
  return {
    count: get(data, `event_counts.${type}.total`, 0),
    severity: get(data, `event_counts.${type}.severity_breakdown`, {
      high: 0,
      med: 0,
      low: 0,
    }),
  };
};

const mergeTotalEvents = (eventData1, eventData2) => {
  return {
    count: eventData1.count + eventData2.count,
    severity: {
      high:
        ((eventData1.severity && eventData1.severity.high) || 0) +
        ((eventData2.severity && eventData2.severity.high) || 0),
      med:
        ((eventData1.severity && eventData1.severity.med) || 0) +
        ((eventData2.severity && eventData2.severity.med) || 0),
      low:
        ((eventData1.severity && eventData1.severity.low) || 0) +
        ((eventData2.severity && eventData2.severity.low) || 0),
    },
  };
};

export const getFleetLinkPath = ({
  entityId,
  eventType,
}: {
  entityId: string;
  eventType;
}): ROUTES | FleetLinkTo => {
  const isSubfleetView = entityId?.startsWith('g-');
  return isSubfleetView
    ? {
        pathname: ROUTES.EVENTS,
        groupId: entityId,
        removeEntity: true,
        retainSearch: true,
        search: {
          severity: 1,
          filter: eventType,
        },
      }
    : {
        pathname: ROUTES.ENTITY_EVENTS,
        entityId,
        search: {
          severity: 1,
          filter: eventType,
        },
      };
};

export const calculateMaxUTCDayBucketTime = (tz: string): Moment => {
  const fleetTimezone = tz || 'America/New_York';
  // the current day in the fleet's timezone
  const currentFleetDayString = moment()
    .tz(fleetTimezone)
    .format('LL');

  // the UTC Day bucket in our system that corresponds to today's data
  const currentFleetDayAsUTCDayBucket = moment(
    currentFleetDayString,
    'LL',
    'UTC',
  );

  // remove one second, to ensure the cutoff is before the current day
  return currentFleetDayAsUTCDayBucket.subtract(1, 's').tz('GMT');
};

export const getEarliestSelectDate = (time: Moment, days: number): Moment => {
  if (!time || !days) {
    return null;
  }

  return moment(time)
    .startOf('day')
    .subtract(days, 'days');
};
