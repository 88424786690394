import { sumBy } from 'lodash-es';

const characters =
  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890;,./<>?-+!@#$%^&*()_- &';

const c = document.createElement('canvas');

const widthPerPixelMap = (face = 'Book') =>
  [...characters].reduce((obj, character) => {
    const ctx = c.getContext('2d');
    ctx.font = `24px Circular-${face}`;
    return { ...obj, [character]: ctx.measureText(character).width / 24 };
  }, {});

export const estimateWidth = ({
  text,
  height,
  face,
}: {
  text: string;
  height: number;
  face?: string;
}) => sumBy([...text], char => widthPerPixelMap(face)[char] || 8) * height;
