/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Moment } from 'moment';
import * as API from '@nauto/api';
import { DispatchType } from 'models/generics';
import {
  coachingDateRange,
  buildCoachingRecipes,
  splitDateIntoIntervals,
  calculateEventsCoached,
  generateRankData,
} from './utils';
import { EntityType } from 'components/entity-type/entity-type-utils';
import {
  CoachingLabels,
  DriverCoachingAggregation,
  GroupCoachingAggregation,
} from 'models/db';
import {
  findGroupById,
  activeGroupSelector,
  activeGroupHasNamedChildrenSelector,
} from 'components/groups/groups.redux';
import { getFleetId } from 'utils/localstorage';
import { UnifiedCoachingLabels } from 'constants/coaching';
import { createSelector, createStructuredSelector } from 'reselect';
import {
  reportRowsSelector,
  childGroupDataSelector,
  fetchingReportSelector,
} from 'components/reports/reports.redux';
import { RootState } from 'reducers';
import {
  atRiskVeraThresholdSelector,
  isJapaneseFleetSelector,
} from 'components/auth/auth.reducer';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */

export enum ActionTypes {
  FETCHING_START = 'coaching/fetching-data-start',
  FETCHING_COMPLETE = 'coaching/fetching-data-complete',
  FETCHING_ERROR = 'coaching/fetching-data-error',
  FETCHING_COACHABLE_EVENTS = 'coaching/fetching-coachable-events',
  COACHABLE_EVENTS_FETCHED = 'coaching/coachable-events-fetched',
  COACHABLE_EVENTS_FETCH_FAILED = 'coaching/coachable-events-fetch-failed',
  CLEAR_COACHABLE_EVENTS = 'coaching/clear-coachable-events',
  FETCHING_COACHING_AGGREGATION = 'coaching/fetching-coaching-aggregation',
  COACHING_AGGREGATION_FETCHED = 'coaching/coaching-aggregation-fetched',
  COACHING_GROUP_AGGREGATION_FETCHED = 'coaching/coaching-group-aggregation-fetched',
  COACHING_AGGREGATION_FAILED = 'coaching/coaching-aggregation-failed',
  CLEAR_COACHING_AGGREGATION = 'coaching/clear-coaching-aggregation',
  SET_PAGE = 'coaching/set-coaching-page',
}
/**
 * ----------------------------------------------------------------------------
 * SELECTORS
 * ----------------------------------------------------------------------------
 */

export const uncoachedEventIDsSelector = ({ coachingNew }) => {
  if (!coachingNew.eventIDs.length) return Array.prototype;

  const hasStringProperties: boolean =
    typeof coachingNew.eventIDs[0] === 'string';

  const enventsIds = hasStringProperties
    ? coachingNew.eventIDs
    : coachingNew.eventIDs.map(e => e.id);
  return enventsIds;
};

export const uncoachedEventsSelector = ({ coachingNew }) =>
  coachingNew && coachingNew.events;

export const pageSelector = ({ coachingNew }) =>
  coachingNew && coachingNew.page;

export const isFetchingUncoachedEventsSelector = ({ coachingNew }) =>
  coachingNew.isFetching;

export const aggregatedCoachingDataSelector = ({
  coachingNew,
}): DriverCoachingAggregation[] => coachingNew.aggregation || Array.prototype;

export const groupsCoachedEventsSelector = ({
  coachingNew,
}): GroupCoachingAggregation[] =>
  coachingNew.groupAggregation || Array.prototype;

export const currentGroupCoachedCountSelector = createSelector(
  groupsCoachedEventsSelector,
  activeGroupSelector,
  (allGroupCounts, activeGroup) =>
    calculateEventsCoached(
      (allGroupCounts || []).find(g => g.group_id === activeGroup.id),
    ),
);

export const aggregatedCoachingReport = createSelector(
  aggregatedCoachingDataSelector,
  aggData => {
    if (aggData.length) {
      /**
       * Instead of filtering and checking in the component level,
       * we now filter the list of coaching data in the selector
       * to only include drivers who have atleast one coaching label
       * with count > 0
       *
       * we also prune any coaching labels with count 0
       */
      const filteredCoachingData = aggData.reduce((filtered, data) => {
        if (!data.uncoached_live) {
          return filtered;
        }
        const filteredEventKeys =
          (data.uncoached_live &&
            Object.keys(data.uncoached_live).filter(
              (eventKey: UnifiedCoachingLabels) =>
                data.uncoached_live[eventKey] > 0,
            )) ||
          [];

        if (!filteredEventKeys.length) {
          return filtered;
        }

        const coachingLabels = Object.values(UnifiedCoachingLabels);

        const withCorrectLabels = filteredEventKeys.some(
          (eventKeys: UnifiedCoachingLabels) =>
            coachingLabels.includes(eventKeys),
        );

        if (!withCorrectLabels) {
          return filtered;
        }

        const prunedUncoachedData = {} as CoachingLabels;

        for (const element of filteredEventKeys) {
          prunedUncoachedData[element] = data.uncoached_live[element];
        }

        data.uncoached_live = prunedUncoachedData;

        filtered.push(data);
        return filtered;
      }, []);

      return filteredCoachingData;
    }
    return Array.prototype;
  },
);

export const aggregatedCoachingReportCount = createSelector(
  aggregatedCoachingReport,
  report => report.length,
);

export const aggregatedDataWithCoachingLabels = createSelector(
  aggregatedCoachingReport,
  reportRowsSelector,
  (aggData, driversData) => {
    if (aggData.length && driversData.length) {
      return aggData.map(cData => {
        const driver = driversData.find(
          driverData => cData.driver_id === driverData.id,
        );
        const ranking = generateRankData(cData, driver);
        return {
          coachingData: cData,
          ranking,
          // match indexed drivers
          ...driver,
        };
      });
    }
  },
);

export const driversToCoachSelector = createStructuredSelector({
  isFetching: isFetchingUncoachedEventsSelector,
  uncoachedEventIDs: uncoachedEventIDsSelector,
  subfleetsData: childGroupDataSelector,
  fetchingGroupReport: fetchingReportSelector,
  allGroupsCoachingDetails: groupsCoachedEventsSelector,
  activeGroupHasSubfleets: activeGroupHasNamedChildrenSelector,
  atRiskVeraThreshold: atRiskVeraThresholdSelector,
  aggregatedCoachingData: aggregatedCoachingDataSelector,
  isJapaneseFleet: isJapaneseFleetSelector,
});

/**
 * ----------------------------------------------------------------------------
 * ACTIONS
 * ----------------------------------------------------------------------------
 */

export interface CoachableEventsOptions {
  driverId: string;
  appliedFilters?: UnifiedCoachingLabels[];
  eventIDs?: boolean;
  expandReference?: boolean;
  isLoggedInUserTypeDriver?: boolean;
}

export const clearUncoachedEvents = () => dispatch =>
  dispatch({ type: ActionTypes.CLEAR_COACHABLE_EVENTS });

export const getCoachableEvents = ({
  driverId,
  appliedFilters = [],
  eventIDs = false,
  expandReference = false,
  isLoggedInUserTypeDriver = false,
}: CoachableEventsOptions) => (dispatch: (any) => void, getState) => {
  dispatch({ type: ActionTypes.FETCHING_COACHABLE_EVENTS });
  const {
    groups: { rootGroup, activeGroupId },
    coachingNew: { limit },
  } = getState();

  const fleetId = getFleetId();
  const groupInfo = findGroupById(rootGroup, activeGroupId);
  const filters = [{ labels: buildCoachingRecipes(appliedFilters) }];

  const range = coachingDateRange();

  const splitRanges = splitDateIntoIntervals(range, 5);

  const getEventsRequest = (
    range: { start: Moment; end: Moment },
    page?: API.events.Page,
  ): Promise<API.events.EventsResponse> =>
    isLoggedInUserTypeDriver
      ? API.driverLogin.getDriverEvents(
          {
            driverId,
            start: range.start,
            end: range.end,
            filters,
            limit,
            eventIDsOnly: false,
            referenceExpand: expandReference,
            ...(page && { page }),
          },
          { version: 'v1' },
        )
      : API.events.getEvents(
          {
            fleetId,
            groupId: groupInfo.id,
            entityType: EntityType.DRIVER,
            driver: driverId,
            start: range.start,
            end: range.end,
            filters,
            limit,
            eventIDsOnly: eventIDs,
            referenceExpand: expandReference,
            ...(page && { page }),
          },
          { version: 'v3' },
        );

  const eventPromises = splitRanges.map(async range => {
    const getEvents = async (splitRange, page?: API.events.Page) => {
      const { after, events } = (await getEventsRequest(splitRange, page)).data;

      if (after) {
        const afterEvents = await getEvents(range, after);
        return [...events, ...afterEvents];
      }

      return events;
    };

    try {
      const rangeEvents = await getEvents(range);

      return rangeEvents;
    } catch (err) {
      dispatch({ type: ActionTypes.COACHABLE_EVENTS_FETCH_FAILED });
    }
  });

  return Promise.all(eventPromises).then(response => {
    const events = response.reduce((acc, val) => acc.concat(val), []);
    const key = eventIDs ? 'eventIDs' : 'events';

    dispatch({
      type: ActionTypes.COACHABLE_EVENTS_FETCHED,
      payload: {
        [key]: events,
      },
    });
  });
};

export const getCoachingAggregation = ({
  driverId,
  countByGroup = false,
  isLoggedInUserTypeDriver = false,
  coachingOffline = false,
}: {
  driverId?: string;
  countByGroup?: boolean;
  isLoggedInUserTypeDriver?: boolean;
  coachingOffline?: boolean;
}) => (dispatch: (any) => void, getState) => {
  if (!countByGroup) {
    dispatch({ type: ActionTypes.CLEAR_COACHING_AGGREGATION });
  }
  dispatch({ type: ActionTypes.FETCHING_COACHING_AGGREGATION });
  const {
    groups: { rootGroup, activeGroupId },
  }: RootState = getState();
  const fleetId = getFleetId();
  const groupInfo = findGroupById(rootGroup, activeGroupId);
  const groupId = countByGroup
    ? rootGroup && rootGroup.id
    : groupInfo && groupInfo.id;
  const options = {
    fleetId,
    groupId,
    minCountFilter: true,
    withAtRiskDriverFilter: true,
    countType: countByGroup,
    coachingOffline,
  };

  const driverOptions = {
    driverId,
    minCountFilter: true,
    withAtRiskDriverFilter: true,
    coachingOffline,
  };

  const request = isLoggedInUserTypeDriver
    ? API.driverLogin.getDriverCoachingAggregation(driverOptions, {
        version: 'v1',
      })
    : API.coaching.getCoachingAggregation(options, {
        version: 'v3',
      });
  return request
    .then(({ data }) => {
      if (countByGroup) {
        return dispatch({
          type: ActionTypes.COACHING_GROUP_AGGREGATION_FETCHED,
          payload: { groupAggregation: data },
        });
      }
      return dispatch({
        type: ActionTypes.COACHING_AGGREGATION_FETCHED,
        payload: { aggregation: data },
      });
    })
    .catch(error => {
      dispatch({ type: ActionTypes.COACHING_AGGREGATION_FAILED });
      console.log('error', error);
    });
};

/**
 * ----------------------------------------------------------------------------
 * REDUCERS
 * ----------------------------------------------------------------------------
 */

interface CoachingEvents {
  [key: string]: number;
}
interface CoachingDataItem {
  id: string;
  coached: CoachingEvents;
  uncoached: CoachingEvents;
  uncoachable: CoachingEvents;
}

interface CoachingAPIResponse {
  events?: CoachingDataItem[];
  eventIDs?: string[];
  aggregation?: DriverCoachingAggregation[];
  groupAggregation?: GroupCoachingAggregation[];
  page?: API.events.Page;
  limit?: number;
  total?: number;
  error?: string;
  isFetching?: boolean;
}

export const initialState: CoachingAPIResponse = {
  events: [],
  eventIDs: [],
  aggregation: [],
  groupAggregation: [],
  page: null,
  limit: 999,
  total: 72,
  isFetching: false,
  error: '',
};

export default (state = initialState, action: DispatchType) => {
  const { payload, type } = action;
  switch (type) {
    case ActionTypes.FETCHING_START:
    case ActionTypes.FETCHING_COACHABLE_EVENTS:
    case ActionTypes.FETCHING_COACHING_AGGREGATION:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.CLEAR_COACHING_AGGREGATION:
      return {
        ...state,
        aggregation: [],
      };
    case ActionTypes.FETCHING_COMPLETE:
    case ActionTypes.FETCHING_ERROR:
    case ActionTypes.COACHABLE_EVENTS_FETCHED:
    case ActionTypes.COACHABLE_EVENTS_FETCH_FAILED:
    case ActionTypes.COACHING_AGGREGATION_FETCHED:
    case ActionTypes.COACHING_GROUP_AGGREGATION_FETCHED:
    case ActionTypes.COACHING_AGGREGATION_FAILED:
      return {
        ...state,
        ...payload,
        isFetching: false,
      };
    case ActionTypes.SET_PAGE:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.CLEAR_COACHABLE_EVENTS:
      return initialState;
    default:
      return state;
  }
};
