import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  dismissNotification,
  unarchiveNotification,
  getAllNotifications,
} from './notifications.redux';
import { Icon } from '@nauto/uikit/dist/atoms/icons';
import { colors } from '@nauto/uikit/dist/styles';

interface Props extends WithTranslation {
  className?: string;
  dismissible: boolean;
  dispatch?: any;
  history?: History;
  icon: JSX.Element | React.ComponentClass;
  id: string;
  link?: string;
  status?: string;
  onLinkClick?: () => void;
}

@withRouter
export class Notification extends React.Component<Props> {
  handleClick = (): void => {
    const { link, onLinkClick } = this.props;
    if (link) {
      onLinkClick && onLinkClick();
    }
  };

  handleDismiss = (e: MouseEvent<HTMLElement>): void => {
    const { id, dispatch } = this.props;
    e.preventDefault();
    e.stopPropagation();
    dispatch(dismissNotification(id)).then(() => {
      dispatch(getAllNotifications());
    });
  };

  handleUnarchive = (e: MouseEvent<HTMLElement>): void => {
    const { id, dispatch } = this.props;
    e.preventDefault();
    e.stopPropagation();
    dispatch(unarchiveNotification(id)).then(() => {
      dispatch(getAllNotifications());
    });
  };

  render() {
    const { children, className, dismissible, icon, status, t } = this.props;

    return (
      <div className={className} onClick={this.handleClick}>
        <div className="icon-wrapper">{icon}</div>
        <div className="content-wrapper">{children}</div>
        {dismissible && (
          <div className="notification-icon-container">
            {status === 'active' && (
              <div className="dismiss" onClick={this.handleDismiss}>
                <Icon
                  icon="closeCircle"
                  baseColor={colors.grey800}
                  hoverColor={colors.red500}
                  size="md"
                />
              </div>
            )}
            {status === 'inactive' && (
              <div className="unarchive" onClick={this.handleUnarchive}>
                <div className="action-help">{t('Mark as unread')}</div>
                <Icon
                  className="unarchive-icon"
                  icon="reboot"
                  baseColor={colors.grey800}
                  hoverColor={colors.green500}
                  size="sm"
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const StyledEventNotificationRow = styled(Notification)`
  width: 100%;
  height: 5.5rem;
  padding: 0 1rem;
  cursor: ${props => (props.dismissible ? 'pointer' : 'default')};
  display: flex;
  flex-flow: row;
  align-items: center;
  overflow: hidden;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grey100};
  }
  .icon-wrapper {
    margin-right: 1rem;
  }
  .dismiss {
    display: flex;
    margin-left: auto;
    flex-grow: 1;
    cursor: pointer;
  }
  .unarchive {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .unarchive-icon {
    transform: scale(-1, 1) rotate(-45deg);
  }
  .action-help {
    display: none;
    padding-right: 0.75rem;
    right: 1.75rem;
    font-size: 13px;
    color: ${colors.grey800};
    white-space: nowrap;
  }
  .notification-icon-container {
    display: flex;
    position: relative;
    align-items: center;
    margin-left: auto;
  }

  &:hover {
    .action-help {
      display: flex;
    }
  }
`;

export default connect()(withTranslation()(StyledEventNotificationRow));
