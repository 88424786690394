import React from 'react';
import { NotificationProps } from 'models/db';
import EventCoin from 'atoms/event-coin';
import { convertHexToDecimal } from 'utils/helpers';
import EventTypeToString from 'atoms/i18n-event-type-to-string';
import Notification from './notification';
import LocalizedTime from 'atoms/localized-time';
import { Link } from 'react-router-dom';

interface Props {
  notification: NotificationProps;
  onLinkClick: any;
  className?: string;
}

export default class EventNotificationRow extends React.Component<Props> {
  render() {
    const { notification, onLinkClick, className } = this.props;
    const { id, eventLink, status, eventType, properties } = notification;

    const vehicleName =
      // Use the vehicle name
      (properties && properties['vehicle-name']) ||
      // or fallback to the deviceID
      (properties && properties['device-id']);

    const time = convertHexToDecimal(properties['message-id']);
    const timezone = notification.properties.parameters.timezone;

    const Component = eventLink ? Link : React.Fragment;

    return (
      <Component to={eventLink} target="_blank" rel="noopener noreferrer">
        <Notification
          icon={<EventCoin eventType={eventType} size={'md'} />}
          dismissible={true}
          id={id}
          link={eventLink}
          onLinkClick={onLinkClick}
          status={status}
          className={className}
        >
          <div className="flex column">
            <div data-testid="event-string">
              <EventTypeToString type={eventType} /> | {vehicleName}
            </div>
            <LocalizedTime time={time} format="LLL" timezone={timezone} />
          </div>
        </Notification>
      </Component>
    );
  }
}
