import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import {
  withTranslation,
  WithTranslation,
  useTranslation,
} from 'react-i18next';
import {
  colors,
  zIndex,
  Text,
  spaceSizesPx,
  Heading,
  ScreenSizes,
} from '@nauto/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import UserMenu from 'components/header/user-menu';
import { Route } from 'react-router-dom';
import withFeatureFlags, {
  FeatureFlagsProps,
} from 'decorators/with-feature-flags';
import FleetLink from 'components/fleet-link';
import { dataTestId } from './data-test-ids';
import { NavMenuItem } from '../navigation/nav-menu-item';
import {
  driverNavItemsSelector,
  NavItemProps,
  navItemsSelector,
} from 'components/navigation/nav-items.selector';
import { mobileNavBarHeight } from 'constants/layout';
import { withUserPermission } from 'hooks/use-permissions';
import useLoggedInUserType from 'hooks/use-logged-in-user-type';
import { User } from 'models/db';

interface Props extends WithTranslation, FeatureFlagsProps {
  currentFleet?: string;
  currentGroup?: string;
  onItemClick: any;
  navItems: NavItemProps;
  driverNavItems: NavItemProps;
  user?: User;
}

// tslint:disable-next-line:no-big-function
const MobileNavMenuComponent: FC<Props> = props => {
  const { onItemClick, navItems, driverNavItems, user } = props;
  const { t } = useTranslation();
  const { isLoggedInUserTypeDriver } = useLoggedInUserType();

  useEffect(() => {
    document.body.classList.add('for-mobile-screen-scrolling-nav-menu');
    return () =>
      document.body.classList.remove('for-mobile-screen-scrolling-nav-menu');
  }, []);

  return (
    <ContentWrapper data-testid="mobile-menu-page-wrapper">
      <NavMenuContentWrapper data-testid="nav-menu-page-wrapper">
        <Heading
          size="xxl"
          weight="Black"
          color={colors.white}
          style={{ padding: `${spaceSizesPx.sm} ${spaceSizesPx.md}` }}
        >
          {t('Menu')}
        </Heading>
        {isLoggedInUserTypeDriver ? (
          <div>
            <MobileMenuList>
              {driverNavItems.map(
                navProps =>
                  navProps.show && (
                    <Route
                      key={navProps.key}
                      path={navProps.match || navProps.to}
                    >
                      {({ match }) => {
                        const item = (
                          <NavMenuItem
                            id={navProps.appcuesId}
                            label={navProps.label}
                            icon={navProps.icon}
                            isActive={match}
                            onClick={onItemClick}
                            forDriverMobileNavMenu={true}
                            data-testid={dataTestId[navProps.dataTestId]}
                          />
                        );
                        return (
                          <MobileMenuListItem onClick={onItemClick}>
                            {navProps.isFleetLink ? (
                              <FleetLink to={navProps.to}>{item}</FleetLink>
                            ) : (
                              <LogoutItemLink
                                href={navProps.to}
                                target={navProps.target}
                              >
                                {item}
                              </LogoutItemLink>
                            )}
                          </MobileMenuListItem>
                        );
                      }}
                    </Route>
                  ),
              )}
            </MobileMenuList>
          </div>
        ) : (
          <div>
            <MobileMenuList>
              {navItems.map(
                navProps =>
                  navProps.show && (
                    <Route
                      key={navProps.key}
                      path={navProps.match || navProps.to}
                    >
                      {({ match }) => (
                        <MobileMenuListItem onClick={onItemClick}>
                          <FleetLink to={navProps.to}>
                            <NavMenuItem
                              id={navProps.appcuesId}
                              label={navProps.label}
                              icon={navProps.icon}
                              isActive={match}
                              onClick={onItemClick}
                              forMobileNavMenu={true}
                              data-testid={dataTestId[navProps.dataTestId]}
                            />
                          </FleetLink>
                        </MobileMenuListItem>
                      )}
                    </Route>
                  ),
              )}
            </MobileMenuList>
          </div>
        )}
      </NavMenuContentWrapper>

      {!isLoggedInUserTypeDriver && (
        <UserMenuContentWrapper data-testid="user-menu-page-wrapper">
          <Text size="lg" color={colors.white} className="text-content">
            {user.name || user.email}
          </Text>
          <div>
            <UserMenu onItemClick={onItemClick} forMobileNavMenu={true} />
          </div>
        </UserMenuContentWrapper>
      )}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.section`
  display: flex;
  flex-grow: 1;
  flex-flow: column;
  position: fixed;
  width: 100%;
  height: calc(100% - ${mobileNavBarHeight}px);
  z-index: ${zIndex.navbar};
  background-color: ${colors.black};
  margin-top: 6px;
  border-top: 1px solid ${colors.grey700};
  overflow: scroll;

  @media (min-width: ${ScreenSizes.TABLET}) {
    display: none;
  }
`;

const NavMenuContentWrapper = styled.div`
  padding-bottom: 12px;
`;

const UserMenuContentWrapper = styled.div`
  border-top: 1px solid ${colors.grey700};
  padding-bottom: ${spaceSizesPx.md};
  .text-content {
    padding-top: ${spaceSizesPx.md};
    padding-left: ${spaceSizesPx.md};
    padding-bottom: 12px;
  }
`;

const MobileMenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 300px;
`;

const MobileMenuListItem = styled.li`
  display: flex;
  flex-flow: row;
  align-items: center;
  color: ${colors.black};
  cursor: pointer;

  > a {
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

const LogoutItemLink = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Selector = createStructuredSelector({
  navItems: navItemsSelector,
  driverNavItems: driverNavItemsSelector,
});

export default compose(
  withTranslation(),
  withUserPermission,
  connect(Selector),
  withFeatureFlags,
)(MobileNavMenuComponent);
