import isArray from 'lodash-es/isArray';
import isNil from 'lodash-es/isNil';
import keys from 'lodash-es/keys';
import merge from 'lodash-es/merge';
import omit from 'lodash-es/omit';
import { getLocale, getServiceUrl } from 'utils/localstorage';
import FileSaver from 'file-saver';
import moment from './moment';
import { ROLES } from 'constants/roles';
import { ERROR_CODES } from 'constants/error-codes';
declare let navigator;

const isEmpty = xs => xs.length === 0;
const take = n => xs => xs.slice(0, n);
const drop = n => xs => xs.slice(n);
const chunk = n => xs =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];
const stagingDeviceConfigURL = `https://device-config.staging-us.nauto.systems/edit-configs/global`;
const deviceConfigURL = `https://device-config.nauto.systems/edit-configs/global`;

/**
 * IE11 detection
 */
export const isIE11 = () =>
  !!window.MSInputMethodContext && !!document.documentMode;

export const cacheBustUrl = url => {
  /**
   * IE11 agressively caches AJAX calls, to avoid this we need to bust the cache
   * with a random query param. Ideally we need to set the `Control-Cache` header
   * to `no-cache` on the service side, but this will satisfy any bugs related
   * to not re-fetching the drivers list.
   */
  if (isIE11) {
    const cacheBust = `${Date.now()}${Math.round(Math.random() * 9999)}`;
    url = url.concat(url.indexOf('?') > -1 ? '&' : '?', cacheBust);
  }
  return url;
};

/**
 * Converts any given string into an arbitrary HSL value
 * E.g: "oh hai thar" => "hsl(123, 50%, 70%)"
 */
export const hashStringToColor = (string): string => {
  let h = 0;

  for (const char of string) {
    h += char.charCodeAt(0);
  }

  return `hsl(${h % 360}, 80%, 45%)`;
};

/**
 * convertHexToDecimal converts timeseries timestamp from hex to decimal
 * @param hexTs hex timestamp
 */
export const convertHexToDecimal = (hexTs: string): number => {
  if (!hexTs) {
    return;
  }
  return Math.floor(parseInt(hexTs, 16) * Math.pow(10, -6));
};

/**
 * check if element is in iframe
 */
export const isInIframe = (): boolean => {
  try {
    if (process.env.TEST_ENV === 'integration') {
      return false;
    }
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const path = (urlPath, params = {}) => {
  return mergeUrl(urlPath, params);
};

const mergeUrl = (url, params) => {
  if (!url) {
    console.log('URL is not defined for action: ', params);
  }

  if (!params) {
    return url;
  }

  params = merge({}, params);

  if (url.indexOf(':') !== -1) {
    const matches = url.match(/:([_a-z][0-9_a-z]*)/gi);
    if (matches) {
      matches.forEach(match => {
        const key = match.replace(':', '');
        url = url.replace(match, params[key]);
        params = omit(params, key);
      });
    }
  }

  if (keys(params).length > 0) {
    url = `${url}?${paramsToString(params)}`;
  }

  return url;
};

export const paramsToString = obj => {
  const parts = [];
  for (const i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      const current: string | string[] = obj[i];
      if (current && isArray(current)) {
        for (const j in current) {
          if (!isNil(current[j])) {
            parts.push(
              `${encodeURIComponent(i)}=${encodeURIComponent(current[j])}`,
            );
          }
        }
      } else if (!isNil(current)) {
        parts.push(
          `${encodeURIComponent(i)}=${encodeURIComponent(current as string)}`,
        );
      }
    }
  }
  return parts.join('&');
};

export const trimTimestamp = timestamp => {
  return parseInt(timestamp.toString().substring(0, 13), 10);
};

/**
 * Maintain a whitelist of regions that prefer 24 hour format
 */
export const prefers24HourFormat = () => {
  // Note: This is (obviously) not a complete list of 12 hour clock geos.
  // It is sufficient for our current needs
  const twelveHourLocales = ['en-US'];
  return twelveHourLocales.indexOf(getLocale(true)) === -1;
};

/**
 * Maintain a whitelist of regions that are using DD/MM format
 */
export const prefersMMDDFormat = () => {
  // Note: This is (obviously) not a complete list of MM/DD geos.
  // It is sufficient for our current needs
  const MMDDLocales = ['en-US', 'en-CA', 'fr-CA', 'ja-JP'];
  return MMDDLocales.includes(getLocale(true));
};

export const localizedShortDate = () => {
  return prefersMMDDFormat() ? 'MM/DD' : 'DD/MM';
};

export const getBrowserLanguage = () => {
  return (
    navigator.language ||
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    'en-US'
  );
};

export const languageIsEnglish = () =>
  getBrowserLanguage().substring(0, 2) === 'en';

export const languageIsJapanese = () => getLocale(true) === 'ja-JP';

/**
 * Map a number-based role to a string
 */
export const mapRoleToName = (role: string | ROLES): string => {
  switch (role) {
    case ROLES.FLEET_ADMIN:
      return 'Admin';
    case ROLES.FLEET_MANAGER:
      return 'Manager';
  }
};

/**
 * The UI should only show fleets wherein the user has the proper permissions
 */
export const shouldShowFleet = ({ roles }): boolean =>
  roles &&
  (roles.includes(ROLES.FLEET_ADMIN) ||
    roles.includes(ROLES.FLEET_MANAGER) ||
    roles.includes(ROLES.HIDDEN_SUPPORT_FLEET_ADMIN) ||
    roles.includes(ROLES.GLOBAL_ADMIN));

export const handlePhoneErrorMessage = (errorText: string, trans: any) => {
  if (errorText && errorText.includes('used by another user')) {
    const phoneNumber = errorText.substring(
      errorText.indexOf('('),
      errorText.indexOf(')') + 1,
    );
    return trans('Phone number {{phoneNumber}} is used by another user', {
      phoneNumber,
    });
  }
  return errorText;
};

export const downloadBlobFile = (
  fileText,
  mimeType = 'text/plain',
  fileName = 'file_download.txt',
) => {
  const blob = new Blob([fileText], { type: mimeType });
  FileSaver.saveAs(blob, fileName);
};

export const saveFileOnBrowser = (url: string) => {
  window.open(url);
};

/**
 * Get abbreviated timezone name (timezone code) from a full blown timezone name.
 */
export const getTimezoneCode = (zone: string) => {
  return moment()
    .tz(zone)
    .format('z');
};

/**
 * Check if user uses 24 or 12hr clock
 */
export const is12hrLocale = (): boolean => {
  return moment()
    .startOf('day')
    .format('LLL')
    .includes('AM');
};

/**
 * Get root url with current env.
 */
export const getRootUrl = (urlPath: string): string =>
  getServiceUrl()
    .replace('api', urlPath)
    .replace(/\/v.+/, '');

/**
 * Get url for Kibana.
 */
export const getKibanaUrl = (): string => {
  // new kibana only supports http
  const kibanaUrl = getRootUrl('kibana').replace('https', 'http');
  return `${kibanaUrl}/_plugin/kibana/app/kibana#/discover`;
};

/**
 * Get url for Device-config tool.
 */
export const getDeviceConfigUrl = (): string => {
  /* 
    Comments from Michael Mao as part of https://nautodev.atlassian.net/browse/NAUTO-42812 :
    The device config service is a global service, there is only one 
    https://device-config.nauto.systems/ endpoint. There is no device config service for each 
    locale region.
  */
  if (getServiceUrl().includes('staging')) {
    return stagingDeviceConfigURL;
  } else {
    return deviceConfigURL;
  }
};

/**
 * Sorting strings in a natural alphanumerical way
 * @param {string, string}
 * @returns {number}
 */
export const alphaNumericalSorting = (a: string, b: string): number => {
  if (a === b) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

export const getErrorMessage = (
  errorCode: string,
  trans: any,
  param?: any,
): string => {
  return ERROR_CODES[errorCode] && ERROR_CODES[errorCode].message
    ? ERROR_CODES[errorCode].message(trans, param)
    : ERROR_CODES.DEFAULT_ERROR.message(trans);
};
