import React from 'react';
import moment, { Moment } from 'moment';
import 'moment-timezone';

interface Props {
  time: string | Moment | number;
  format: string;
  timezone: string;
  showTimezone?: boolean;
}

export class LocalizedTime extends React.Component<Props, any> {
  static defaultProps: Partial<Props> = {
    showTimezone: true,
  };

  render() {
    const { time, format, timezone, showTimezone } = this.props;

    const formatted = time
      ? moment
          .tz(time, timezone)
          .format(`${format} ${timezone && showTimezone ? 'z' : ''}`)
      : '';

    if (!timezone) {
      return (
        <React.Fragment>
          {time ? moment(time).format(format) : ''}
        </React.Fragment>
      );
    }

    return <React.Fragment>{formatted}</React.Fragment>;
  }
}

export default LocalizedTime;
