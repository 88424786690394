import React, { useEffect } from 'react';
import { mpTrack, mpTimer } from 'components/mixpanel';

interface Props {
  trackingId: string;
}

enum WindowState {
  focus = 'focus',
  blur = 'blur',
}

export const TrackPage: React.SFC<Props> = (props: Props) => {
  /* the currently in progress page being tracked */
  const currentTrackingIdRef = React.useRef<string>('');
  /* the page we were on before current */
  const previousTrackingIdRef = React.useRef<string>('');

  /* whether or not the window is blurred */
  const [windowState, setWindowState] = React.useState<WindowState>(null);

  const getIds = (): { prev: string; current: string } => ({
    prev: (previousTrackingIdRef && previousTrackingIdRef.current) || '',
    current: (currentTrackingIdRef && currentTrackingIdRef.current) || '',
  });

  useEffect(() => {
    const { current, prev } = getIds();
    if (current) {
      mpTrack(current, { prev, next: props.trackingId });
    }
    if (document.hasFocus()) {
      startPageView();
    }
  }, [props.trackingId]);

  useEffect(() => {
    const { prev, current } = getIds();

    if (windowState === WindowState.blur) {
      mpTrack(current, { prev, blur: true });
      currentTrackingIdRef.current = '';
    }

    if (windowState === WindowState.focus) {
      startPageView();
    }
  }, [windowState]);

  const startPageView = () => {
    const { current } = getIds();
    mpTimer(props.trackingId);
    previousTrackingIdRef.current = current;
    currentTrackingIdRef.current = props.trackingId;
  };

  const onBlur = () => setWindowState(WindowState.blur);
  const onFocus = () => setWindowState(WindowState.focus);

  React.useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    window.onbeforeunload = () => {
      const { prev, current } = getIds();
      if (current) {
        mpTrack(current, { prev, close: true });
      }
    };
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return <>{false}</>;
};
