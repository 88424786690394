import * as API from '@nauto/api';
import { useQuery } from 'react-query';
import { THIRTY_MINUTES } from '../constants';

const DEFAULT_VALUES = {
  sa_declined: 0,
  sa_improved: 0,
  sa_new: 0,
  sa_risky: 0,
  sa_top: 0,
}

const useDriverCountQuery = ({
  fleetId,
  groupId,
}: {
  fleetId: string;
  groupId: string;
}): any => {
  const getDriverCountData = async () => {
    const { data } = await API.reports.getDriverCount({
      fleetId,
      groupId,
    });
    return data;
  };

  const { data, isLoading, isError } = useQuery(
    ['getDriverCountData', fleetId, groupId],
    getDriverCountData,
    {
      cacheTime: THIRTY_MINUTES,
      staleTime: Infinity,
      select: (data) => {
        return ({
          ...DEFAULT_VALUES,
          ...data,
        })
      }
    },
  );

  return {
    data,
    isLoading,
    isError,
  };
};

export default useDriverCountQuery;
