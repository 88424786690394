import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useMilesSelector } from 'components/auth/auth.reducer';

export interface Props extends WithTranslation {
  meters: number;
  useMiles: boolean;
}

const METERS_TO_MILES_MULTIPLIER = 0.000621371;
const METERS_TO_KILOMETERS_MULTIPLIER = 0.001;

export const metersToKilometers = (meters: number): number =>
  meters * METERS_TO_KILOMETERS_MULTIPLIER;

export const metersToMiles = (meters: number): number =>
  meters * METERS_TO_MILES_MULTIPLIER;

export class LocalizedDistance extends React.Component<Props, any> {
  getLocalizedDistance = (): string => {
    const { t, meters, useMiles } = this.props;

    return useMiles
      ? t('{{distance}} mi', {
          distance: Math.round(metersToMiles(meters)),
        })
      : t('{{distance}} km', {
          distance: Math.round(metersToKilometers(meters)),
        });
  };

  render() {
    return <span>{this.getLocalizedDistance()}</span>;
  }
}

export const translated = withTranslation()(LocalizedDistance);

const selector = createStructuredSelector({
  useMiles: useMilesSelector,
});

export default connect(selector)(translated);
