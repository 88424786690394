/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { useContext } from 'react';
import FleetDataContext from './context';
import FleetDataProvider from './provider';
import { FleetProps } from 'models/db';
import { PACKAGE } from 'components/feature/constants';
import { packageFeatures } from 'components/feature/utils';

export interface Props {
  currentFleet?: FleetProps;
  fleets?: Record<string, FleetProps>;
}

export const useCurrentFleetData = (): FleetProps => {
  const { currentFleet } = useContext(FleetDataContext);
  return currentFleet;
};

interface All {
  id?: undefined;
  current?: undefined;
}

interface ById {
  id?: string;
  current?: undefined;
}

interface Current {
  id?: undefined;
  current?: boolean;
}

function useFleetData(options?: All): Record<string, FleetProps>;
function useFleetData(options?: ById | Current): FleetProps;
function useFleetData(options?: {
  id?: string;
  current?: boolean;
}): Record<string, FleetProps> | FleetProps {
  const { fleets, currentFleet } = useContext(FleetDataContext);

  if (options.current) {
    const { name } = currentFleet || {};
    const fleetFeatures = packageFeatures(
      currentFleet?.fleet_offering.package || PACKAGE.PREVENT_PRO,
      currentFleet?.fleet_offering.features || [],
    );

    if (currentFleet && Object.keys(currentFleet)) {
      currentFleet['isFedex'] = !!name?.match(/fedex/gi);
    }
    Object.assign(currentFleet || {}, fleetFeatures);
    return currentFleet;
  }

  if (options.id) {
    return fleets[options.id];
  }

  return fleets;
}

export { FleetDataProvider };
export default useFleetData;
