import { Dispatch, Action } from 'redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { fetchRequest } from 'utils/requests';
import config from 'utils/config';
import { FleetProps, Features } from 'models/db';
import { getFleetId } from 'utils/localstorage';
import { FleetSettingProperties } from './types';
import { packageFeatures } from 'components/feature/utils';
import { DEFAULT_DATA, PACKAGE, FEATURES } from 'components/feature/constants';
import { aggregateFeatures } from './utils';
import { cacheBustUrl } from 'utils/helpers';
import * as API from '@nauto/api';
/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export enum ActionTypes {
  GET_FLEET_SETTINGS_START = 'settings/start-get-fleet-settings',
  GET_FLEET_SETTINGS_SUCCESS = 'settings/get-fleet-settings-success',
  GET_FLEET_SETTINGS_ERROR = 'settings/get-fleet-settings-error',
  UPDATE_FLEET_SETTINGS_START = 'setting/start-update-fleet-settings',
  UPDATE_FLEET_SETTINGS_SUCCESS = 'setting/update-fleet-settings-success',
  UPDATE_FLEET_SETTINGS_ERROR = 'setting/update-fleet-settings-error',
}

/**
 * ----------------------------------------------------------------------------
 * Selectors
 * ----------------------------------------------------------------------------
 */

export const fleetSelector = ({ fleetSettings }) =>
  fleetSettings && fleetSettings.fleet;

export const fleetSettingsSelector = createStructuredSelector({
  fleetSettings: fleetSelector,
  isProcessing: ({ fleetSettings }) => fleetSettings.isProcessing,
  errorOccurred: ({ fleetSettings }) => fleetSettings.errorOccurred,
  error: ({ fleetSettings }) => fleetSettings.error,
});

export const fleetFeaturesSelector = createSelector(
  fleetSelector,
  (fleet): Features =>
    (fleet && fleet.fleet_offering && fleet.fleet_offering.features) ||
    DEFAULT_DATA,
);

export const fleetInfoSelector = createStructuredSelector({
  fleetSettings: fleetSelector,
});

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

export const getFleetSettings = () => dispatch => {
  dispatch({ type: ActionTypes.GET_FLEET_SETTINGS_START });

  return API.admin
    .getFleetSettings({ fleetId: getFleetId() }, { url: config.admin_api_url })
    .then(response => {
      if (response.success) {
        dispatch({
          type: ActionTypes.GET_FLEET_SETTINGS_SUCCESS,
          payload: {
            ...response.data,
            fleet_offering: {
              ...response.data.fleet_offering,
              features: aggregateFeatures({
                features: response.data.fleet_offering.features,
                addons: response.data.fleet_offering.add_on,
              }),
            },
          },
        });
      } else {
        dispatch({
          type: ActionTypes.GET_FLEET_SETTINGS_ERROR,
          payload: response,
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ActionTypes.GET_FLEET_SETTINGS_ERROR,
        payload: error,
      });
    });
};

export const updateFleetSettings = (body: Partial<FleetSettingProperties>) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({ type: ActionTypes.UPDATE_FLEET_SETTINGS_START });

  API.admin
    .updateFleetSettings(
      { fleetId: getFleetId(), body },
      { url: config.admin_api_url },
    )
    .then(response => {
      if (response.success) {
        dispatch({
          type: ActionTypes.UPDATE_FLEET_SETTINGS_SUCCESS,
          payload: {
            speeding_setting: {
              value: body.speeding_setting && body.speeding_setting.value,
              unit: body.speeding_setting && body.speeding_setting.unit,
            },
            iva: {
              speeding: body.iva && body.iva.speeding,
            },
            enable_driver_ranking: body.enable_driver_ranking,
          },
        });
      } else {
        dispatch({
          type: ActionTypes.UPDATE_FLEET_SETTINGS_ERROR,
          payload: response,
        });
      }
    })
    .catch(error => {
      dispatch({
        type: ActionTypes.UPDATE_FLEET_SETTINGS_ERROR,
        payload: error,
      });
    });
};

/**
 * ----------------------------------------------------------------------------
 * Reducer
 * ----------------------------------------------------------------------------
 */

export interface FleetSettings {
  fleet: FleetProps;
  isProcessing: boolean;
  errorOccurred: boolean;
  error: any;
}

export const initialState: FleetSettings = {
  error: null,
  errorOccurred: false,
  isProcessing: false,
  fleet: null,
};

export default (state: FleetSettings = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_FLEET_SETTINGS_START: {
      return {
        state: null,
        isProcessing: true,
      };
    }
    case ActionTypes.UPDATE_FLEET_SETTINGS_START: {
      return {
        ...state,
        isProcessing: true,
      };
    }
    case ActionTypes.GET_FLEET_SETTINGS_ERROR: {
      return {
        ...state,
        errorOccurred: !!payload.error,
        error: payload.error,
        isProcessing: false,
      };
    }
    case ActionTypes.GET_FLEET_SETTINGS_SUCCESS: {
      return {
        ...state,
        fleet: payload,
        isProcessing: false,
        error: null,
        errorOccurred: false,
      };
    }
    case ActionTypes.UPDATE_FLEET_SETTINGS_ERROR: {
      return {
        ...state,
        isProcessing: false,
        errorOccurred: !!payload.error,
        error: payload.error,
      };
    }
    case ActionTypes.UPDATE_FLEET_SETTINGS_SUCCESS: {
      return {
        ...state,
        fleet: {
          ...state.fleet,
          properties: {
            ...state.fleet.properties,
            speeding_setting: {
              value:
                payload.speeding_setting.value ||
                state.fleet.properties.speeding_setting.value,
              unit:
                payload.speeding_setting.unit ||
                state.fleet.properties.speeding_setting.value,
            },
            iva: {
              speeding: payload.iva.speeding,
            },
            enable_driver_ranking: payload.enable_driver_ranking,
          },
        },
        isProcessing: false,
        error: null,
        errorOccurred: false,
      };
    }
    default:
      return state;
  }
};
