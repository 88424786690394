import { mpTrack } from 'components/mixpanel';
import { Action, Dispatch } from 'redux';
import config from 'utils/config';
import { fetchRequest } from 'utils/requests';
import { getFleetId } from 'utils/localstorage';

export interface ImagesData {
  id: string;
  device_id: string;
  installer_id: string;
  bucket: string;
  Keys: {
    back: string;
    front: string;
    internal: string;
    left: string;
    odometer: string;
    right: string;
  };
  Images: {
    back: string;
    front: string;
    internal: string;
    left: string;
    odometer: string;
    right: string;
  };
  Created: string;
}

export interface ReducerState {
  error: string;
  ImagesList: ImagesData;
  fetchingImages: boolean;
  slideIndex: number;
}

export const initialState: ReducerState = {
  fetchingImages: false,
  ImagesList: {
    id: '',
    device_id: '',
    installer_id: '',
    bucket: '',
    Keys: {
      back: '',
      front: '',
      internal: '',
      left: '',
      odometer: '',
      right: '',
    },
    Images: {
      back: '',
      front: '',
      internal: '',
      left: '',
      odometer: '',
      right: '',
    },
    Created: '',
  },
  error: '',
  slideIndex: 0,
};

export enum ActionTypes {
  GET_INSTALL_IMAGES_START = 'install_images/get-install-images-start',
  GET_INSTALL_IMAGES_END = 'install_images/get-install-images-end',
  GET_INSTALL_IMAGES_ERROR = 'install_images/get-install-images-error',
  NEXT_IMAGE = 'install_images/next-image',
  PREVIOUS_IMAGE = 'install_images/previous-image',
  SET_ZERO = 'install_images/set-index-zero',
  SET_FIVE = 'install_images/set-index-five',
  RESET = 'install_images/reset',
}

export const nextImage = slideIndex => (
  dispatch: Dispatch<Action>,
  getState,
) => {
  slideIndex < 5
    ? dispatch({ type: ActionTypes.NEXT_IMAGE, payload: slideIndex + 1 })
    : dispatch({ type: ActionTypes.SET_ZERO });
};

export const previousImage = slideIndex => (
  dispatch: Dispatch<Action>,
  getState,
) => {
  slideIndex > 0
    ? dispatch({ type: ActionTypes.PREVIOUS_IMAGE, payload: slideIndex - 1 })
    : dispatch({ type: ActionTypes.SET_FIVE });
};

export const resetModal = () => (dispatch: Dispatch<Action>, getState) => {
  dispatch({ type: ActionTypes.RESET });
};
/* tslint:disable:no-duplicate-string */
export const getInstallPicturesForDevice = (deviceId: string) => (
  dispatch: Dispatch<Action>,
) => {
  const url = `${
    config.admin_api_url
  }/installer/fleets/${getFleetId()}/devices/${deviceId}/install_images`;

  dispatch({ type: ActionTypes.GET_INSTALL_IMAGES_START });

  return fetchRequest(url, { method: 'GET' })
    .then(response => {
      mpTrack('Get install images request', { success: true });
      return dispatch({
        type: ActionTypes.GET_INSTALL_IMAGES_END,
        payload: response.data,
      });
    })
    .catch(error => {
      console.log(error);
      mpTrack('Get install images request', { success: false });
      dispatch({
        type: ActionTypes.GET_INSTALL_IMAGES_ERROR,
        payload: { error: error.message },
      });
    });
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.GET_INSTALL_IMAGES_START:
      return { ...state, fetchingImages: true, error: '' };

    case ActionTypes.GET_INSTALL_IMAGES_END:
      return {
        ...state,
        fetchingImages: false,
        error: '',
        imagesList: payload,
      };

    case ActionTypes.GET_INSTALL_IMAGES_ERROR:
      return { ...state, fetchingImages: false, error: payload.error };

    case ActionTypes.NEXT_IMAGE:
      return { ...state, slideIndex: payload };

    case ActionTypes.SET_ZERO:
      return { ...state, slideIndex: 0 };

    case ActionTypes.RESET:
      return { ...state, slideIndex: 0, imagesList: { Images: {} } };

    case ActionTypes.PREVIOUS_IMAGE:
      return { ...state, slideIndex: payload };

    case ActionTypes.SET_FIVE:
      return { ...state, slideIndex: 5 };
    default:
      return state;
  }
};
