import { SetStateAction } from 'react';
import useVeraScoreToggle from 'hooks/use-vera-score-type-toggle';
import { Dispatch, useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query'
import { Range } from 'utils/date-ranges';
import { getFleetId } from 'utils/localstorage';
import * as API from '@nauto/api';
import { DurationType } from 'components/reports/types';

interface Params {
  range: Range;
  type?: DurationType;
  isAggregateScore?: boolean;
}

type SetParamState = SetStateAction<Params>;

interface UseIndustryAverageQueryMethods {
  refetchIndustryAverage: Dispatch<SetParamState>;
  useIndustryAverage(): UseQueryResult;

}

const useIndustryAverageQuery = (initialParams: Params): UseIndustryAverageQueryMethods => {
  const { isScoreVersionVera3 } = useVeraScoreToggle();
  const [fetchParams, setFetchParams] = useState<Params>(initialParams);

  const fetchIndustryAverage = async () => {
    const { range: { min, max }, type, isAggregateScore } = fetchParams;
    const { data } = await API.reports.getIndustryAverageScore({
      fleetId: getFleetId(),
      range: { min: min.valueOf(), max: max.valueOf() },
      type,
      isAggregateScore,
      isScoreVersionVera3,
    });

    return data[0]?.agg_score?.risk_scores?.risk_score;
  }

  const useIndustryAverage = () => useQuery(['getIndustryAverage', fetchParams], fetchIndustryAverage, {
    cacheTime: Infinity,
    staleTime: Infinity
  });

  return {
    refetchIndustryAverage: setFetchParams,
    useIndustryAverage
  };
}

export default useIndustryAverageQuery;
