import { Driver, Vehicle, FleetProps } from 'models/db';
import { Moment } from 'moment';
import { Group } from 'components/groups/types';
import { UnifiedCoachingLabels } from 'constants/coaching';

export enum DurationType {
  Daily = 'd',
  Weekly = 'w',
  Monthly = 'm',
}

interface EventCountAPIData {
  duration?: number;
  total: number;
  severity_breakdown: {
    high: number;
    med: number;
    low: number;
  };
}

export interface PeriodAPIData {
  start_time: string;
  end_time: string;
  event_counts: {
    'acceleration-hard': EventCountAPIData;
    'backing-up': EventCountAPIData;
    'braking-hard': EventCountAPIData;
    'cell-phone': EventCountAPIData;
    collision?: EventCountAPIData;
    'collision-prevented': EventCountAPIData;
    'collision-reduced'?: EventCountAPIData;
    'corner-left-hard': EventCountAPIData;
    'corner-right-hard': EventCountAPIData;
    distraction: EventCountAPIData;
    drowsiness: EventCountAPIData;
    'holding-object': EventCountAPIData;
    'looking-down': EventCountAPIData;
    'near-collision': EventCountAPIData;
    'no-seat-belt': EventCountAPIData;
    obstructed?: EventCountAPIData;
    'posted-speeding'?: EventCountAPIData;
    'review-confirmed-severe-g-event'?: EventCountAPIData; // todo: remove after vera2 cutoff in JP
    smoking: EventCountAPIData;
    speeding?: EventCountAPIData; // todo: remove after vera2 cutoff in JP
    tailgating: EventCountAPIData;
    fcw?: EventCountAPIData;
    pcw?: EventCountAPIData;
  };
  statistics: {
    moving_duration: number;
    no_seat_belt_duration?: number; // todo: remove after vera2 cutoff in JP
    posted_speeding_duration?: number; // todo: remove after vera2 cutoff in JP
    speeding_duration?: number; // todo: remove after vera2 cutoff in JP
    trip_count: number;
    trip_distance: number;
    trip_duration: number;
    unassigned_trip_duration: number;
    stopped_duration?: number;
    max_speeding_duration?: number;
  };
  risk_scores: {
    attention_score: number;
    smooth_driving_score: number;
    risk_score: number;
    aggressive_driving_score: number;
    aggressive_driving_score_delta: number;
    inattention_score: number;
    inattention_score_delta: number;
    high_risk_score: number;
    high_risk_score_delta: number;
    traffic_violation_score: number;
    traffic_violation_score_delta: number;
  };
}

export interface PeriodData extends PeriodAPIData {
  events: {
    acceleration: EventRateData;
    braking: EventRateData;
    cornering: EventRateData;
    distraction: EventRateData;
    tailgating: EventRateData;
    smoking: EventRateData;
    cell_phone: EventRateData;
    speeding: EventRateData;
    collision: EventRateData;
    collisions: EventRateData;
    drowsiness: EventRateData;
  };
  rates: {
    speedingDuration: EventRateData;
    movingDuration: EventRateData;
  };
  scores: {
    attention_score: number;
    smooth_driving_score: number;
    risk_score: number;
    aggressive_driving_score: number;
    aggressive_driving_score_delta: number;
    inattention_score: number;
    inattention_score_delta: number;
    high_risk_score: number;
    high_risk_score_delta: number;
    traffic_violation_score: number;
    traffic_violation_score_delta: number;
  };
  tripTime: number;
  movingTime: number;
  idleTime: number;
  parkedTime;
  range: number;
  tripRate: number;
  parkedRate: number;
  movingRate: number;
  idleRate: number;
  start: Moment;
  end: Moment;
}

export interface ReportBaseData {
  driver_id?: string;
  vehicle_id?: string;
  group_id?: string;
  start_time: string;
  end_time: string;
}

export interface ReportRowAPIData extends ReportBaseData {
  agg_score: PeriodAPIData;
  daily_score: PeriodAPIData[];
  weekly_score: PeriodAPIData[];
  monthly_score: PeriodAPIData[];
}

interface EventRateData {
  rate?: number;
  count?: number;
  stdDev: number;
  mean: number;
  severityLevel: number;
}

export interface RowCoachingData {
  uncoached_live?: any;
  driver_id: string;
  coached_count: number;
  uncoached_count: number;
  uncoachable_count: number;
  uncoached_live_count: number;
  last_coached_time: string;
}

export interface ReportRowData extends ReportBaseData, PeriodData {
  group: Group;
  // the data for this
  // tslint:disable-next-line:max-union-size
  entity: Driver | Vehicle | FleetProps;
  id: string;
  agg_score: PeriodData;
  daily_score: PeriodData[];
  weekly_score: PeriodData[];
  monthly_score: PeriodData[];
  coachingData?: RowCoachingData;
  coachedOn?: number;
  eventsTypes?: UnifiedCoachingLabels[];
  responsesCount?: { goodJob: number; coach: number };
}

export interface TableFilter {
  title: string;
  filterData: (data: ReportRowData) => boolean;
}
