import { uniqWith, isEqual } from 'lodash-es';
import { Dispatch, Action } from 'redux';
import { FleetProps, DeviceProps } from 'models/db';
import { createSelector, createStructuredSelector } from 'reselect';
import { PROMISE, getIMEI } from './utils';
import { getFleetId, getFleets } from 'utils/localstorage';
import * as API from '@nauto/api';
import config from 'utils/config';

export const fleetListSelector = createSelector(
  ({ deviceActions }) => deviceActions.fleetList || [],
  fleetList => fleetList.map(({ fleet }) => fleet),
);

const fleetInputResultsSelector = createSelector(fleetListSelector, fleetList =>
  uniqWith(
    [
      ...fleetList.map(fleet => fleet.id),
      ...fleetList.map(fleet => fleet.name),
    ],
    isEqual,
  ),
);

const errorSelector = createSelector(
  ({ deviceActions }) => deviceActions.error || {},
  ({ deprovision, provision, vehicle }) =>
    deprovision ? deprovision : provision ? provision : vehicle ? vehicle : '',
);

export const BootloaderSelector = createStructuredSelector({
  deviceList: ({ deviceActions }) => deviceActions.deviceList || [],
  deviceInfo: ({ deviceActions }) => deviceActions.deviceInfo || {},
  isFetching: ({ deviceActions }) => deviceActions.fetching,
});

export const RecycleSelector = createStructuredSelector({
  panelStatus: ({ deviceActions }) =>
    deviceActions.actionPanelStatus && deviceActions.actionPanelStatus.recycle,
  deviceInfo: ({ deviceActions }) => deviceActions.deviceInfo || {},
  isFetching: ({ deviceActions }) => deviceActions.fetching,
  error: ({ deviceActions: { error } }) => error.recycle,
});

export const ProvisionSelector = createStructuredSelector({
  fleetInputResults: fleetInputResultsSelector,
  fleetList: fleetListSelector,
  error: errorSelector,
  panelStatus: ({ deviceActions }) =>
    deviceActions.actionPanelStatus &&
    deviceActions.actionPanelStatus.provision,
  deviceInfo: ({ deviceActions }) => deviceActions.deviceInfo || {},
  isFetching: ({ deviceActions }) => deviceActions.fetching,
});

export enum ActionPanelStatus {
  Form = 'form',
  Success = 'success',
  Error = 'error',
}

export enum DeviceActionName {
  Recycle = 'recycle',
  Provision = 'provision',
}

interface ActionPanel {
  recycle: ActionPanelStatus;
  provision: ActionPanelStatus;
}

interface ErrorTypes {
  deviceList: string;
  recycle: string;
  provision: string;
  deprovision: string;
  vehicle: string;
}

interface FleetInfo {
  fleet: FleetProps;
}

interface ReducerState {
  fleetList: FleetInfo[];
  deviceList: DeviceProps[];
  deviceInfo: DeviceProps;
  fetching: boolean;
  error: ErrorTypes;
  actionPanelStatus: ActionPanel;
}

enum ActionTypes {
  CLEAR_ERROR_RECORDS = 'device-actions/clear-error-records',
  FETCH_ERROR = 'device-actions/fetch-error',
  FETCH_FLEET_LIST_START = 'device-actions/fetch-fleet-list-start',
  FETCH_FLEET_LIST_SUCCESS = 'device-actions/fetch-fleet-list-success',
  FETCH_FLEET_LIST_ERROR = 'device-actions/fetch-fleet-list-error',
  FETCH_DEVICE_LIST_START = 'device-actions/fetch-device-list-start',
  FETCH_DEVICE_LIST_SUCCESS = 'device-actions/fetch-device-list-success',
  FETCH_DEVICE_LIST_ERROR = 'device-actions/fetch-device-list-error',
  GET_CURRENT_DEVICE_INFO = 'device-actions/get-current-device-info',
  RECYCLING_START = 'device-actions/recycling-start',
  RECYCLING_SUCCESS = 'device-actions/recycling-success',
  RECYCLING_ERROR = 'device-actions/recycling-error',
  PROVISIONING_START = 'device-actions/provisioning-start',
  PROVISIONING_SUCCESS = 'device-actions/provisioning-success',
  PROVISIONING_ERROR = 'device-actions/provisioning-error',
  DEPROVISIONING_START = 'device-actions/deprovisioning-start',
  DEPROVISIONING_SUCCESS = 'device-actions/deprovisioning-success',
  DEPROVISIONING_ERROR = 'device-actions/deprovisioning-error',
  DEPROVISION_TO_XREWORK_START = 'device-actions/deprovision-to-xrework-start',
  DEPROVISION_TO_XREWORK_SUCCESS = 'device-actions/deprovision-to-xrework-success',
  DEPROVISION_TO_XREWORK_ERROR = 'device-actions/deprovision-to-xrework-error',
  VEHICLE_BINDING_START = 'device-actions/vehicle-binding-start',
  VEHICLE_BINDING_SUCCESS = 'device-actions/vehicle-binding-success',
  VEHICLE_BINDING_ERROR = 'device-actions/vehicle-binding-error',
  SET_ACTION_PANEL_STATUS = 'device-actions/set-action-panel-status',
  PERFORM_ADMIN_ACTION_START = 'device-actions/perform-admin-action-start',
  PERFORM_ADMIN_ACTION_SUCCESS = 'device-actions/perform-admin-action-success',
  PERFORM_ADMIN_ACTION_ERROR = 'device-actions/perform-admin-action-error',
}

const errorHandler = (dispatch, error, errorType) => {
  dispatch({
    type: ActionTypes.FETCH_ERROR,
    payload: {
      error,
      errorType,
    },
  });
};
/* tslint:disable:no-duplicate-string */
export const getDevices = () => (dispatch: Dispatch<Action>) => {
  dispatch({ type: ActionTypes.FETCH_DEVICE_LIST_START });
  dispatch({
    type: ActionTypes.CLEAR_ERROR_RECORDS,
    payload: {
      errorType: 'deviceList',
    },
  });

  return API.admin
    .getDevices({ fleetId: getFleetId() })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.FETCH_DEVICE_LIST_SUCCESS,
        payload: {
          devices: data,
        },
      });
      return PROMISE.OK;
    })
    .catch(error => {
      dispatch({ type: ActionTypes.FETCH_DEVICE_LIST_ERROR });
      errorHandler(dispatch, error, 'deviceList');
      return PROMISE.FAIL;
    });
};

export const getDeviceInfo = (deviceId: string) => (
  dispatch: Dispatch<Action>,
  getState,
) => {
  const deviceList = getState().deviceActions.deviceList;
  const device =
    deviceList.length &&
    deviceId &&
    deviceList.find(({ id }) => id === deviceId);
  dispatch({
    type: ActionTypes.GET_CURRENT_DEVICE_INFO,
    payload: {
      deviceInfo: device ? { ...device, imei: getIMEI(device.hardware) } : null,
    },
  });
};

export const recycleDevice = (imei: string) => (dispatch: Dispatch<Action>) => {
  dispatch({ type: ActionTypes.RECYCLING_START });
  dispatch({
    type: ActionTypes.CLEAR_ERROR_RECORDS,
    payload: {
      errorType: 'recycle',
    },
  });

  return API.admin
    .recycleDevice(
      { fleetId: getFleetId(), imei },
      { url: config.admin_api_url },
    )
    .then(() => {
      dispatch({ type: ActionTypes.RECYCLING_SUCCESS });
      return PROMISE.OK;
    })
    .catch(error => {
      dispatch({ type: ActionTypes.RECYCLING_ERROR });
      errorHandler(dispatch, error, 'recycle');
      return PROMISE.FAIL;
    });
};

export const sendAdminCommand = (
  deviceId: string,
  messageType: string,
  messageBody?: any,
) => (dispatch: Dispatch<Action>, getState) => {
  const fleetId = getState().user.currentFleet;

  dispatch({ type: ActionTypes.PERFORM_ADMIN_ACTION_START });

  return API.admin
    .sendAdminCommand({ fleetId, deviceId, messageType, messageBody })
    .then(response => {
      return dispatch({
        type: ActionTypes.PERFORM_ADMIN_ACTION_SUCCESS,
        payload: { ...response },
      });
    })
    .catch(error => {
      return dispatch({
        type: ActionTypes.PERFORM_ADMIN_ACTION_ERROR,
        payload: {
          error: error.message,
        },
      });
    });
};

export const deprovisionToXRework = (imei: string) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({ type: ActionTypes.DEPROVISION_TO_XREWORK_START });

  return new Promise((resolve, reject) =>
    API.admin
      .deprovisionDeviceToXRework(
        { fleetId: getFleetId(), imei },
        { url: config.admin_api_url },
      )
      .then(() => {
        dispatch({ type: ActionTypes.DEPROVISION_TO_XREWORK_SUCCESS });
        return resolve(true);
      })
      .catch(error => {
        dispatch({ type: ActionTypes.DEPROVISION_TO_XREWORK_ERROR });
        return reject(error);
      }),
  );
};

export const provisionDevice = (
  fleetId: string,
  imei: string,
  isError = false,
) => (dispatch: Dispatch<Action>) => {
  dispatch({ type: ActionTypes.PROVISIONING_START });
  if (!isError) {
    dispatch({
      type: ActionTypes.CLEAR_ERROR_RECORDS,
      payload: {
        errorType: 'provision',
      },
    });
  }

  return API.admin
    .provisionDevice({ fleetId, imei }, { url: config.admin_api_url })
    .then(({ data: { device } }) => {
      dispatch({ type: ActionTypes.PROVISIONING_SUCCESS });
      return PROMISE.SEND(device);
    })
    .catch(error => {
      dispatch({ type: ActionTypes.PROVISIONING_ERROR });
      errorHandler(dispatch, error, 'provision');
      return PROMISE.FAIL;
    });
};

export const deprovisionDevice = (fleetId: string, imei: string) => (
  dispatch: Dispatch<Action>,
) => {
  dispatch({ type: ActionTypes.DEPROVISIONING_START });
  dispatch({
    type: ActionTypes.CLEAR_ERROR_RECORDS,
    payload: {
      errorType: 'deprovision',
    },
  });

  return API.admin
    .deprovisionDevice({ fleetId, imei }, { url: config.admin_api_url })
    .then(() => {
      dispatch({ type: ActionTypes.DEPROVISIONING_SUCCESS });
      return PROMISE.OK;
    })
    .catch(error => {
      dispatch({ type: ActionTypes.DEPROVISIONING_ERROR });
      errorHandler(dispatch, error, 'deprovision');
      return PROMISE.FAIL;
    });
};

export const bindDeviceToVehicle = (
  fleet: string,
  device: string,
  vehicle: any,
) => (dispatch: Dispatch<Action>) => {
  dispatch({ type: ActionTypes.VEHICLE_BINDING_START });
  dispatch({
    type: ActionTypes.CLEAR_ERROR_RECORDS,
    payload: {
      errorType: 'vehicle',
    },
  });

  return API.admin
    .bindDeviceToVehicle(
      { fleet, device, vehicle },
      { url: config.admin_api_url },
    )
    .then(() => {
      dispatch({ type: ActionTypes.VEHICLE_BINDING_SUCCESS });
      return PROMISE.OK;
    })
    .catch(error => {
      dispatch({ type: ActionTypes.VEHICLE_BINDING_ERROR });
      errorHandler(dispatch, error, 'vehicle');
      return PROMISE.FAIL;
    });
};

export const setActionPanelStatus = (
  actionName: DeviceActionName,
  status: ActionPanelStatus,
) => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionTypes.SET_ACTION_PANEL_STATUS,
    payload: { actionName, status },
  });
};

export const initialState: ReducerState = {
  fleetList: getFleets(),
  deviceList: [],
  deviceInfo: null as DeviceProps,
  fetching: false,
  error: {
    deviceList: '',
    recycle: '',
    provision: '',
    deprovision: '',
    vehicle: '',
  },
  actionPanelStatus: {
    recycle: ActionPanelStatus.Form,
    provision: ActionPanelStatus.Form,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_DEVICE_LIST_START:
    case ActionTypes.RECYCLING_START:
    case ActionTypes.PROVISIONING_START:
    case ActionTypes.DEPROVISIONING_START:
    case ActionTypes.DEPROVISION_TO_XREWORK_START:
    case ActionTypes.VEHICLE_BINDING_START:
      return {
        ...state,
        fetching: true,
      };

    case ActionTypes.CLEAR_ERROR_RECORDS:
      return {
        ...state,
        error: {
          ...state.error,
          [payload.errorType]: '',
        },
      };

    case ActionTypes.FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        error: {
          ...state.error,
          [payload.errorType]:
            payload.error instanceof Error
              ? payload.error.message
              : payload.error,
        },
      };

    case ActionTypes.FETCH_DEVICE_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        deviceList: [...payload.devices],
      };

    case ActionTypes.GET_CURRENT_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: { ...payload.deviceInfo },
      };

    case ActionTypes.RECYCLING_SUCCESS:
    case ActionTypes.PROVISIONING_SUCCESS:
    case ActionTypes.DEPROVISIONING_SUCCESS:
    case ActionTypes.DEPROVISION_TO_XREWORK_SUCCESS:
    case ActionTypes.VEHICLE_BINDING_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case ActionTypes.SET_ACTION_PANEL_STATUS:
      return {
        ...state,
        actionPanelStatus: {
          ...state.actionPanelStatus,
          [payload.actionName]: payload.status,
        },
      };

    default:
      return state;
  }
};
