import React, { FC } from 'react';
import { createStructuredSelector, createSelector } from 'reselect';
import { connect } from 'react-redux';
import FleetDataContext from './context';
import { FleetProps, FleetAccess } from 'models/db';
import { currentFleetSelector } from 'components/auth/auth.reducer';
import keyBy from 'lodash-es/keyBy';
import { Props } from '.';

const FleetDataProvider: FC<Props> = ({ children, currentFleet, fleets }) => {
  return (
    <FleetDataContext.Provider value={{ currentFleet, fleets }}>
      {children}
    </FleetDataContext.Provider>
  );
};

export const fleetSelector = createSelector(
  currentFleetSelector,
  ({ fleetSettings: { fleet } }: any) => fleet,
  (
    currentFleet: FleetProps,
    currentFleetWithSettings: FleetProps,
  ): FleetProps => currentFleetWithSettings || currentFleet,
);

export const fleetsSelector = createSelector(
  ({ user: { fleets } }) => fleets,
  (fleets: FleetAccess[]): FleetProps[] =>
    fleets ? fleets.map(f => f.fleet) : [],
);

const indexedFleetsSelector = createSelector(
  fleetsSelector,
  (fleets): Record<string, FleetProps> => keyBy(fleets, 'id'),
);

const selector = createStructuredSelector({
  currentFleet: fleetSelector,
  fleets: indexedFleetsSelector,
});

export default connect<Props, Record<string, unknown>, any>(selector)(
  FleetDataProvider,
);
