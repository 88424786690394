import { spaceSizes } from '@nauto/core';

export const maxCenteredContentWidth = 1440;
export const minCenteredContentWidth = 600;
export const headerControlBarHeight = 80;
export const controlBarHeight = 50;
export const sideBarEntityTypePickerHeight = 50;
export const sideBarEntitySearchBarHeight = 50;
export const DEPRECATED__sideBarEntityContentWidth = 256;
export const sideBarEntityContentWidth = 350;
export const mobileNavBarHeight = 44;
export const tabletScoreGraphPanelHeight = 332;
export const mobileScoreGraphPanelHeight = 586;
export const mobileScoreGraphHeight =
  (mobileScoreGraphPanelHeight - spaceSizes.lg) / 2;
export const mobileFleetDropdownButtonHeight = 65;
export const mobileFleetDropdownNavTitleHeight = 40;
export const mobileStackedBarChartHeight = 350;
export const tableScrollOffset = 80;
export const updatedFleetUiTableOffset = 136;
