import React, { FC } from 'react';
import { connect } from 'react-redux';
import VeraScoreTypeDataContext from './context';
import { VeraVersionType } from 'utils/vera-score-utils';
import { createStructuredSelector } from 'reselect';
import {
  atRiskVeraThresholdSelector,
  collisionEventTypeSelector,
  Constants,
  veraVersionTypeSelector,
} from 'components/auth/auth.reducer';
import { setVeraScoreType } from 'utils/localstorage';

interface Props {
  dispatch?: any;
  veraVersionType?: VeraVersionType;
  toggleVeraVersionType?: (versionType) => void;
  isScoreVersionVera3?: boolean;
  atRiskVeraThreshold?: number;
  collisionEventType?: string;
}

const VeraScoreTypeDataProvider: FC<Props> = ({
  children,
  dispatch,
  veraVersionType,
  atRiskVeraThreshold,
  collisionEventType,
}) => {
  const toggleVeraVersionType = (scoreType: VeraVersionType) => {
    if (scoreType) {
      setVeraScoreType(scoreType);
      dispatch({
        type: Constants.USER_VERA_SCORE_VERSION_UPDATE,
        payload: { veraVersionType: scoreType },
      });
    }
  };
  const isScoreVersionVera3 = veraVersionType === VeraVersionType.VERA3;

  return (
    <VeraScoreTypeDataContext.Provider
      value={{
        veraVersionType,
        toggleVeraVersionType,
        isScoreVersionVera3,
        atRiskVeraThreshold,
        collisionEventType,
      }}
    >
      {children}
    </VeraScoreTypeDataContext.Provider>
  );
};

const selector = createStructuredSelector({
  veraVersionType: veraVersionTypeSelector,
  atRiskVeraThreshold: atRiskVeraThresholdSelector,
  collisionEventType: collisionEventTypeSelector,
});

export default connect(selector)(VeraScoreTypeDataProvider);
