import React from 'react';

interface Props {
  children?: any;
  onClickOutside?: any;
  onKeyPress?: any;
  onEscKeyPress?: any;
  className?: any;
}

/**
 * race condition issue in React 17 with effects
 * it is described well here for read -> https://github.com/facebook/react/issues/20074
 *
 * In short, when we update a piece of state such as
 * opening the modal, it triggers an effect, which
 * then adds a click handler to the window
 *
 * so our first click event to open the modal actually triggers
 * our clickOutside handler instead of waiting for another
 * click
 *
 * adding a capture property to our eventlistener delays registering
 * our initial click
 */
const ComponentActions: React.SFC<Props> = props => {
  React.useEffect(() => {
    window.addEventListener('click', handleClickOutside, { capture: true });
    window.addEventListener('keydown', handleKeyPress, { capture: true });
    return () => {
      window.removeEventListener('click', handleClickOutside, {
        capture: true,
      });
      window.removeEventListener('keydown', handleKeyPress, { capture: true });
    };
  });

  const contentEl = React.useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent): void => {
    if (contentEl.current) {
      const el = event.target as HTMLElement;
      const hasClickedOutside = contentEl && !contentEl.current.contains(el);
      if (hasClickedOutside) {
        const { onClickOutside } = props;
        onClickOutside && onClickOutside();
      }
    }
  };

  const handleKeyPress = (event: KeyboardEvent): void => {
    const { onKeyPress, onEscKeyPress } = props;
    onKeyPress && onKeyPress(event);
    if (event.key === 'Escape') {
      onEscKeyPress && onEscKeyPress(event);
    }
  };

  return (
    <div className={props.className} ref={contentEl as any}>
      {props.children}
    </div>
  );
};

export default ComponentActions;
