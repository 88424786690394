import React from 'react';
import { Text, Box, spaceSizesPx } from '@nauto/core';
import { BackSmallIcon } from '@nauto/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mpTrack } from 'components/mixpanel';
import { MixpanelEventKeys } from 'components/mixpanel/tags';
import { parse } from 'query-string';
import useLoggedInUserType from 'hooks/use-logged-in-user-type';

interface PrevLocation {
  pathname: string;
  routeName: string;
  search: string;
}

interface Props {
  showBackButton: boolean;
  prevLocation?: PrevLocation;
  shouldPreserveSearch: boolean;
}

const BackButton: React.FC<Props> = props => {
  const { showBackButton, prevLocation } = props;
  const [t] = useTranslation();
  const { search } = useLocation();
  const { direct } = parse(search);
  const { isLoggedInUserTypeDriver } = useLoggedInUserType();
  const history = useHistory();
  const hasBackButtonDependecies = !direct && showBackButton && prevLocation;

  const linkRouteName = isLoggedInUserTypeDriver
    ? 'Driver Coaching'
    : prevLocation?.routeName;

  return hasBackButtonDependecies ? (
    <div
      style={{ cursor: 'pointer', marginRight: spaceSizesPx.md }}
      data-testid="back-button"
      onClick={() => {
        mpTrack(MixpanelEventKeys.BackTo, {
          location: linkRouteName,
        });
        history.goBack();
      }}
    >
      <Box align="center">
        <BackSmallIcon />
        <Text weight="Bold" size="sm">
          {t('Back')}
        </Text>
      </Box>
    </div>
  ) : null;
};

export default BackButton;
