import { createSelector, createStructuredSelector } from 'reselect';
import moment from 'moment';
import * as API from '@nauto/api';
import { pathToGeoJSONSourceData } from 'utils/trip-utils';
import {
  activeTripSelector,
  isScrubbingSelector,
  tripEventsSelector,
  TripPoint,
  currentScrubTimeSelector,
} from 'redux/trips';

import {
  coordinatesToBounds,
  getBearing,
  findNearestPoint,
} from 'utils/map-utils';
import { selectActiveEntityType } from 'components/entity-type/active-entity-type.redux';
import { EntityType } from 'components/entity-type/entity-type-utils';
import { eventToGeoJSONFeature, getCoordinates } from 'components/events/utils';
import { GeoJSONSourceRaw } from 'mapbox-gl';
import { isGlobalAdminSelector } from 'components/auth/auth.reducer';

const JUNK_LONGITUDE = -1000000000000000;
const isJunkPoint = (point: TripPoint) => {
  return !point.location || point.location[0] === JUNK_LONGITUDE;
};

// include only moving and stopped messages with locations
// convert id_ns value to a moment timestamp
export const cleanPoints = (points: TripPoint[] = []): TripPoint[] =>
  points
    .filter(
      point =>
        !isJunkPoint(point) && (point.state === 'm' || point.state === 's'),
    )
    .map(point => ({
      ...point,
      momentTime: moment(point.time),
      speed: point.speed || 0,
    }));

// replace the inaccurate heading_deg parameter sent from the device with a simple geometry calculated one
export const improvedHeadingPoints = (points: TripPoint[]): TripPoint[] =>
  points.map((point, i) => ({
    ...point,
    heading_deg: getBearing(points[Math.max(i - 1, 0)], point),
  }));

// cleaned up API response for trip path
export const tripPathSelector = createSelector(
  ({ trips_data }) => trips_data.tripPath,
  tripPath => {
    return improvedHeadingPoints(cleanPoints(tripPath));
  },
);

/**
 * Convert the raw trip messages to a GeoJSON path
 */
export const pathGeoJSONSourceSelector = createSelector(
  // select the raw trip path
  tripPathSelector,
  // convert to GeoJSON
  tripPath => {
    const data = pathToGeoJSONSourceData(tripPath);
    return data && { type: 'geojson', data };
  },
);

// get the bounding rectangle around the live devices on a map
export const selectTripBounds = createSelector(
  pathGeoJSONSourceSelector,
  (source: any) =>
    source && coordinatesToBounds(source.data.geometry.coordinates),
);

/**
 * calculate the distance of active trip
 */
export const selectTripDistance = ({ trips_data }) => trips_data.tripDistance;

export const selectAPoint = createSelector(
  tripPathSelector,
  tripPath => !!tripPath && !!tripPath.length && tripPath[0],
);

export const selectBPoint = createSelector(
  tripPathSelector,
  tripPath => !!tripPath && !!tripPath.length && tripPath[tripPath.length - 1],
);

const selectCapPoints = createSelector<any, any, any, any, any>(
  selectAPoint,
  selectBPoint,
  (pointA, pointB) => {
    if (!pointA || !pointB) {
      return null;
    }
    return [
      { letter: 'A', message: pointA },
      { letter: 'B', message: pointB },
    ];
  },
);

const capsGeoJSONSourceSelector = createSelector(selectCapPoints, capPoints => {
  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: (capPoints || []).map(({ letter, message }) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: message.location,
        },
        properties: { letter },
      })),
    },
  };
});
/**
 * calculate the closest point on map when scrubber is moved
 */
export const scrubPointAndIndexSelector = createSelector(
  tripPathSelector,
  currentScrubTimeSelector,
  (tripPath, currentScrubTime) => findNearestPoint(currentScrubTime, tripPath),
);

/**
 * get the closest point on map when scrubber is moved
 */
export const selectScrubPoint = createSelector(
  scrubPointAndIndexSelector,
  ({ point }) => point,
);

/**
 * get the index of closest point on map when scrubber is moved
 */
export const selectScrubIndex = createSelector(
  scrubPointAndIndexSelector,
  ({ index }) => index,
);

const eventsGeoJSONSourceSelector = createSelector(
  tripEventsSelector,
  events =>
    ({
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: events
          .filter(event => getCoordinates(event))
          .map(event => eventToGeoJSONFeature(event)),
      },
    } as GeoJSONSourceRaw),
);

export interface CapPoint {
  letter: 'A' | 'B';
  message: TripPoint;
}

// selector for the map sidebar component
export interface SidebarTripsProps {
  capPoints: CapPoint[];
  tripEvents: API.events.Event[];
  tripDistance: number;
  isOpen: boolean;
  activeEntityType: EntityType;
  fetchingTripData: boolean;
  dispatch?: any;
  isGlobalAdmin: boolean;
}

export const sidebarTripsSelector = createStructuredSelector({
  capPoints: selectCapPoints,
  tripDistance: selectTripDistance,
  activeEntityType: selectActiveEntityType,
  isGlobalAdmin: isGlobalAdminSelector,
});

// selector for the mapbox map component
export interface MapboxTripsProps {
  events: API.events.Event[];
  eventsGeoJSONSource: any;
  pathGeoJSONSource: any;
  capsGeoJSONSource: any;
  tripBounds: [[number, number], [number, number]];
  currentScrubPoint: TripPoint;
  debug: boolean;
  dispatch?: any;
}

export const mapboxTripsSelector = createStructuredSelector({
  isScrubbing: isScrubbingSelector,
  events: tripEventsSelector,
  eventsGeoJSONSource: eventsGeoJSONSourceSelector,
  pathGeoJSONSource: pathGeoJSONSourceSelector,
  capsGeoJSONSource: capsGeoJSONSourceSelector,
  tripBounds: selectTripBounds,
  currentScrubPoint: selectScrubPoint,
});

export const eventTripPathSelector = createStructuredSelector({
  capsGeoJSONSource: capsGeoJSONSourceSelector,
  pathGeoJSONSource: pathGeoJSONSourceSelector,
  shouldFetchPath: createSelector(
    activeTripSelector,
    activeTrip => !activeTrip,
  ),
});
