import * as API from '@nauto/api';
import i18next from 'i18next';
import { Driver } from 'models/db';

export const getFullName = ({
  driver,
  isJapaneseFleet = false,
}: {
  driver: API.Driver | Driver;
  isJapaneseFleet?: boolean;
}): string => {
  const firstName = driver?.first_name || '';
  const lastOrFamilyName = driver?.last_name || '';

  if (driver?.type === 'synthetic') {
    return i18next.t('Unknown driver');
  }

  return isJapaneseFleet
    ? `${lastOrFamilyName} ${firstName}`
    : driver?.first_name
    ? `${firstName} ${lastOrFamilyName}`
    : driver?.name;
};

export const getInformalName = (
  driver: API.Driver | Driver,
  isJapaneseFleet = false,
): string => {
  if (!driver) {
    return '';
  }
  return isJapaneseFleet ? driver.last_name : driver.first_name;
};

export const addFullNames = (
  drivers: API.Driver[],
  isJapaneseFleet = false,
): API.Driver[] =>
  drivers.map((driver: API.Driver) => ({
    ...driver,
    name: getFullName({ driver, isJapaneseFleet }),
  }));
