import React, { useState, useEffect, useRef, useContext } from 'react';
import styled, { ThemeContext, css } from 'styled-components';
import { connect } from 'react-redux';
import {
  colors,
  Button,
  spaceSizes,
  Text,
  Box,
  textSizes,
  textSizesPx,
  spaceSizesPx,
  ScreenBreakPoints,
  GLOBAL_MEDIA_QUERIES,
} from '@nauto/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { useTranslation } from 'react-i18next';
import Dropdown from '@nauto/dropdown';
import TreeView from '@nauto/tree-view';
import CaretIcon from '@nauto/icons/icons/caret';
import {
  activeGroupSelector,
  setActiveGroup,
  Group,
} from 'components/groups/groups.redux';
import { clearActiveDriver } from 'components/organization/drivers/drivers.redux';
import { clearActiveVehicle } from 'components/organization/vehicles/vehicles.redux';
import { fleetLink } from 'components/fleet-link';
import { clearReport } from '../reports/reports.redux';
import FleetTitle from './fleet-title';
import MultiFleetListLink from './multi-fleet-list-link';
import { Separator } from './styles';
import { mpTrack } from 'components/mixpanel';
import { MixpanelEventKeys } from 'components/mixpanel/tags';
import { FleetProps } from 'models/db';
import { estimateWidth } from 'utils/font';
import { getFleetName } from 'components/fleets/utils';
import {
  currentSuperfleetSelector,
  currentFleetIsSuperfleetSelector,
} from 'components/superfleets/superfleets.redux';
import {
  currentFleetSelector,
  isJapaneseFleetSelector,
} from 'components/auth/auth.reducer';
import useFeatureFlags from 'hooks/use-feature-flags';
import { useMedia } from 'react-media';
import {
  mobileNavBarHeight,
  mobileFleetDropdownButtonHeight,
  mobileFleetDropdownNavTitleHeight,
} from 'constants/layout';
import {
  buildGroupTree,
  buildGroupTreeWithAOS,
  findSelectedGroupPath,
  treeViewTheme,
} from './utils';
import useLoggedInUserType from 'hooks/use-logged-in-user-type';
import useUserPermissions, { Permissions } from 'hooks/use-permissions';
// The space used by all the added spacing, icons, pipes in the title.
const NON_TEXT_WIDTH = 180;

interface Props extends RouteComponentProps {
  group: Group;
  rootGroup: any;
  canSelectGroup: boolean;
  showFleetName: boolean;
  dispatch?: any;
  currentSuperfleet: FleetProps;
  currentFleet: FleetProps;
  isJapaneseFleet: boolean;
  currentFleetIsSuperfleet: boolean;
  inUseSpace: number;
}

const FleetSelector: React.FC<Props> = props => {
  const {
    dispatch,
    history,
    group,
    rootGroup,
    canSelectGroup,
    showFleetName,
    currentSuperfleet,
    currentFleet,
    isJapaneseFleet,
    currentFleetIsSuperfleet,
  } = props;
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const selectedGroupRef = useRef<Group>();
  const featureFlags = useFeatureFlags();
  const { hasPermission } = useUserPermissions();
  const showSuperfleetName = !!currentSuperfleet;
  const screenSizeMatches = useMedia({
    queries: GLOBAL_MEDIA_QUERIES,
  });
  const isMobileView =
    featureFlags.newNavigationUi && !!screenSizeMatches.MOBILE;
  const { isLoggedInUserTypeDriver } = useLoggedInUserType();
  const hasAdminOnlySubfleetAccess =
    hasPermission(Permissions.AdminOnlySubfleet, 'full-access') ||
    hasPermission(Permissions.AdminOnlySubfleet, 'read-only');
  const setSelectedGroupRef = g => {
    selectedGroupRef.current = g;
  };

  const totalExpectedWidth = (size: number) => {
    const shouldShowFleetName =
      showFleetName &&
      currentFleet &&
      !currentFleetIsSuperfleet &&
      !isJapaneseFleet;

    const superfleet = showSuperfleetName
      ? getFleetName(currentSuperfleet)
      : '';
    const fleet = shouldShowFleetName ? getFleetName(currentFleet) : '';
    const groupName = canSelectGroup && group ? group.name : '';
    const titles = `${superfleet}${fleet}${groupName}`;
    return (
      estimateWidth({
        text: titles,
        height: size,
      }) + NON_TEXT_WIDTH
    );
  };

  useEffect(() => {
    setSelectedGroup(group);
  }, [group]);

  const handleSelectFleet = (handleClose: any) => () => {
    const groupRef = selectedGroupRef.current;
    if (!groupRef) {
      handleClose();
      return;
    }
    setSelectedGroup(groupRef);
    mpTrack(MixpanelEventKeys.NavSubfleetName);
    const groupId = groupRef.id;
    if (groupId === group.id) {
      handleClose();
      return;
    }
    dispatch(clearActiveDriver());
    dispatch(clearActiveVehicle());
    dispatch(clearReport());
    dispatch(setActiveGroup(groupId));
    history.push(
      fleetLink({
        groupId,
        removeEntity: true,
        retainSearch: true,
      }),
    );
    handleClose();
  };

  if (featureFlags.newNavigationUi && rootGroup?.tag === 'entire-company') {
    rootGroup.name = currentFleet.alias || currentFleet.name;
  }

  const groupLabel = group && (group.name || group.id);
  const treeList = rootGroup && [
    buildGroupTreeWithAOS(
      rootGroup,
      featureFlags.adminOnlySubfleets,
      hasAdminOnlySubfleetAccess,
    ),
  ];

  const selectedGroupNode = selectedGroup || (group && { id: group.id });
  const selectedIdPath =
    rootGroup &&
    selectedGroupNode &&
    findSelectedGroupPath(selectedGroupNode.id, rootGroup);

  const resizeCutoffs = {
    xl: totalExpectedWidth(textSizes.xl) + props.inUseSpace,
    lg: totalExpectedWidth(textSizes.lg) + props.inUseSpace,
    md: totalExpectedWidth(textSizes.md) + props.inUseSpace,
  };

  const Wrapper = ({ children }) =>
    featureFlags.resizeHeader ? (
      <ResizeWrapper
        resizeCutoffs={resizeCutoffs}
        inUseSpace={props.inUseSpace}
      >
        {children}
      </ResizeWrapper>
    ) : (
      <BoxWrapper>{children}</BoxWrapper>
    );

  const selectorContentProps = {
    treeList,
    selectedGroupNode,
    setSelectedGroupRef,
    selectedIdPath,
    rootGroup,
    handleSelectFleet,
  };

  // If the customer has is japanese and not a superfleet user, do not show the fleet name
  const showSeparator = showFleetName && currentSuperfleet;

  return (
    <Wrapper>
      {!isLoggedInUserTypeDriver && <MultiFleetListLink />}
      {showFleetName && (
        <FleetTitle
          isSelectedNodeRoot={selectedGroupNode?.tag === 'entire-company'}
        />
      )}
      {canSelectGroup && (
        <React.Fragment>
          <Separator />
          <DropdownWrapper
            className="sub-fleet-selector"
            id="home-header-dropdown-subfleet-tree-selector"
            data-testid="home-header-dropdown-subfleet-tree-selector"
          >
            {isMobileView ? (
              <>
                <MobileButton onClick={() => setIsMobileNavOpen(true)}>
                  {<ToggleButton groupLabel={groupLabel} />}
                  <CaretIcon />
                </MobileButton>
                {isMobileNavOpen && (
                  <MobileNav>
                    <Box
                      padding="sm"
                      style={{
                        backgroundColor: colors.grey400,
                        height: `${mobileFleetDropdownNavTitleHeight}px`,
                      }}
                    >
                      {showSuperfleetName && (
                        <OverflowText color={colors.white}>
                          {getFleetName(currentSuperfleet)}
                        </OverflowText>
                      )}

                      {showSeparator && <Separator color={colors.white} />}

                      {showFleetName && (
                        <OverflowText color={colors.white}>
                          {getFleetName(currentFleet)}
                        </OverflowText>
                      )}
                      {groupLabel && (
                        <>
                          <Separator color={colors.white} />
                          <OverflowText color={colors.white}>
                            {groupLabel}
                          </OverflowText>
                        </>
                      )}
                    </Box>
                    <SelectorContent
                      onClose={() => setIsMobileNavOpen(false)}
                      {...selectorContentProps}
                    />
                  </MobileNav>
                )}
              </>
            ) : (
              <Dropdown
                toggle={<ToggleButton groupLabel={groupLabel} />}
                caret={true}
                {...(featureFlags.newNavigationUi && {
                  iconColor: colors.black,
                })}
                maintainPageView={true}
              >
                {({ handleClose }: any) => (
                  <SelectorContent
                    onClose={handleClose}
                    {...selectorContentProps}
                  />
                )}
              </Dropdown>
            )}
          </DropdownWrapper>
        </React.Fragment>
      )}
    </Wrapper>
  );
};

const ToggleButton = ({ groupLabel }) => {
  const theme = useContext(ThemeContext);
  return (
    <Box paddingRight="sm" style={{ lineHeight: '25px' }}>
      <OverflowText
        style={{
          fontFamily: theme.fonts.bold,
          textDecoration: 'underline',
        }}
      >
        {groupLabel}
      </OverflowText>
    </Box>
  );
};

const MobileNav = ({ children }) => {
  useEffect(() => {
    document.body.classList.add(
      'for-mobile-screen-scrolling-subfleet-selector',
    );
    return () =>
      document.body.classList.remove(
        'for-mobile-screen-scrolling-subfleet-selector',
      );
  }, []);

  return <MobileNavContainer>{children}</MobileNavContainer>;
};

const SelectorContent = ({
  onClose,
  treeList,
  selectedGroupNode,
  setSelectedGroupRef,
  selectedIdPath,
  rootGroup,
  handleSelectFleet,
}) => {
  const { newNavigationUi, fleetSelectorSearch } = useFeatureFlags();
  const [t] = useTranslation();
  const searchParams = {
    withSearch: true,
    searchPlaceholder: t('Search'),
  };
  return (
    <React.Fragment>
      <GroupsContainer>
        <TreeView
          list={treeList}
          selectedNode={selectedGroupNode}
          onListItemSelect={setSelectedGroupRef}
          expandedKeys={selectedGroupNode ? selectedIdPath : [rootGroup.id]}
          {...(newNavigationUi && { theme: treeViewTheme })}
          {...(fleetSelectorSearch && searchParams)}
        />
      </GroupsContainer>
      <ActionBar data-testid="button-bar" justify="flex-end">
        <Button
          onClick={onClose}
          buttonType="text"
          data-testid="cancel-select-fleet-button"
          style={{ marginRight: spaceSizesPx.sm }}
        >
          {t('Cancel')}
        </Button>
        <Button
          onClick={handleSelectFleet(onClose)}
          color={newNavigationUi ? 'default' : 'positive'}
          data-testid="select-fleet-button"
        >
          {t('Select fleet')}
        </Button>
      </ActionBar>
    </React.Fragment>
  );
};

const OverflowText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: unset;
`;

const MobileNavContainer = styled.div`
  position: fixed;
  min-height: 100px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 6px 24px rgba(46, 46, 58, 0.15),
    0px 3px 5px rgba(0, 0, 0, 0.08);
  z-index: 2000;
  left: 0;
  top: ${mobileNavBarHeight}px;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MobileButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const GroupsContainer = styled.div`
  padding: ${spaceSizes.xs}px ${spaceSizes.sm}px;
  width: 25rem;
  max-width: 100%;
  max-height: 80vh;
  font-size: ${textSizesPx.sm};
  overflow-y: auto;
  display: block;
  flex: 1;
  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    width: 100vw;
    max-height: calc(
      100% - ${mobileNavBarHeight}px - ${mobileFleetDropdownNavTitleHeight}px -
        ${mobileFleetDropdownButtonHeight}px
    );
  }
`;

const ActionBar = styled<any>(Box)`
  border-top: 1px solid ${colors.grey200};
  background: ${colors.white};
  padding: ${spaceSizesPx.md};
  font-size: ${textSizesPx.sm};
  position: sticky;
  bottom: 0;
  z-index: 4;
  align-items: center;
  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: ${mobileFleetDropdownButtonHeight}px;
  }
`;

interface StyleProps {
  resizeCutoffs: {
    xl: number;
    lg: number;
    md: number;
  };
  inUseSpace: number;
}

const BaseStyles = css`
  font-size: ${textSizes.md}px;
  flex-direction: row;
  align-items: center;
`;

const BoxWrapper = styled<any>(Box)`
  ${BaseStyles}
`;

const ResizeWrapper = styled<any>(Box)<StyleProps>`
  ${BaseStyles}
  @media (min-width: ${({ resizeCutoffs }) =>
    resizeCutoffs && resizeCutoffs.lg}px) {
    font-size: ${textSizes.lg}px;
  }
  @media (min-width: ${({ resizeCutoffs }) =>
    resizeCutoffs && resizeCutoffs.xl}px) {
    font-size: ${textSizes.xl}px;
  }
  flex: 1;
`;

const DropdownWrapper = styled.div`
  > div {
    padding-left: 0;
    button:first-of-type {
      font-size: inherit;
    }
  }
`;

export const fleetInfoSelector = createStructuredSelector({
  group: activeGroupSelector,
  currentFleet: currentFleetSelector,
  currentSuperfleet: currentSuperfleetSelector,
  currentFleetIsSuperfleet: currentFleetIsSuperfleetSelector,
  isJapaneseFleet: isJapaneseFleetSelector,
  rootGroup: ({ groups }) => groups.rootGroup,
});

export default compose(connect(fleetInfoSelector), withRouter)(FleetSelector);
