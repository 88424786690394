import styled from 'styled-components';
import { colors } from '@nauto/uikit/dist/styles';

const Title = styled.h5`
  color: ${colors.grey700};
  text-transform: uppercase;
  margin: 0 0 0.25rem;
`;

Title.displayName = 'Title';

export default Title;
