export enum ROUTES {
  SET_PASSWORD = '/activate/:token',
  FORGOT_PASSWORD = '/forgot-password',
  DRIVER_FORGOT_PASSWORD = '/driver-forgot-password',
  RESET_PASSWORD = '/forgot-password/:token',
  DRIVER_RESET_PASSWORD = '/driver-forgot-password/:token',
  LOGIN = '/login',
  BPX_SSO = '/bpx-sso',
  FEDEX_SSO = '/fedex-sso',
  AMAZON_LOGIN = '/amazon-login',
  LOGOUT = '/logout',
  NO_FLEETS = '/no-fleets',
  CUSTOMER_SUPPORT_TOOLKIT = '/f/:fleetId/customer-support-toolkit',
  ROLE_ACTIVATION = '/role_activation',
  ROLE_UPDATE = '/role_update',
  SSO = '/sso',
  UPDATE_EMAIL = '/update_email',
  FLEETS = '/fleets',
  FLEET_HEALTH = '/f/:fleetId/g/:groupId/health',
  DEVICE_HEALTH = '/f/:fleetId/g/:groupId/:deviceId/health',
  NOTIFICATIONS = '/f/:fleetId/notifications',
  VIDEO_REQUESTS = '/f/:fleetId/video-requests',
  CVR = '/f/:fleetId/g/:groupId/video',
  USERS = '/f/:fleetId/g/:groupId/users',
  INTERNAL = '/internal',
  INTERNAL_USERS = '/f/:fleetId/internal-users',
  ROLES = '/f/:fleetId/g/:groupId/roles',
  EDIT_ROLE = '/f/:fleetId/g/:groupId/role/:roleId',
  CREATE_ROLE = '/f/:fleetId/g/:groupId/create-role',
  ADD_FLEET_USER = '/f/:fleetId/g/:groupId/users/invite',
  FLEET_USER = '/f/:fleetId/g/:groupId/users/:userId',
  ADD_MULTI_FLEET_USER = '/user/invite',
  ADD_INTERNAL_FLEET_USER = '/f/:fleetId/g/:groupId/internal-user/invite',
  MULTI_FLEET_USER = '/user/:userId',
  MULTI_FLEET_ADMIN_USER = '/f/:fleetId/g/:groupId/internal-user/:userId',
  USER_ACTIVATION = '/user_activation',
  DRIVER_ACTIVATION = '/driver-activation/:token',
  PROFILE = '/f/:fleetId/profile',
  SETTINGS = '/f/:fleetId/settings',
  LEGACY_COACHING = '/f/:fleetId/g/:groupId/legacy-coaching',
  COACHING = '/f/:fleetId/g/:groupId/coaching',
  COACHING_SUBFLEETS = '/f/:fleetId/g/:groupId/coaching/subfleets',
  COACHING_DRIVERS = '/f/:fleetId/g/:groupId/coaching/drivers',
  COACHING_RECENT_SESSIONS = '/f/:fleetId/g/:groupId/coaching/recent-sessions',
  COACHING_HISTORICAL_SESSIONS = '/f/:fleetId/g/:groupId/coaching/historical-sessions',
  COACHING_EVENT_DETAILS = '/f/:fleetId/g/:groupId/coaching/e/:entityId/event-details',
  COACHING_EVENT = '/f/:fleetId/g/:groupId/coaching/event/:eventId',
  FNOL_REPORT = '/f/:fleetId/g/:groupId/events/:eventId/fnol',
  EVENT = '/f/:fleetId/g/:groupId/events/:eventId',
  EVENTS = '/f/:fleetId/g/:groupId/events',
  EVENT_DETAILS = '/f/:fleetId/g/:groupId/event-details/:eventId',
  SUPERFLEET_ASSESSMENT = '/s/:fleetId/safety-assessment',
  SUPERFLEET_FLEETS = '/s/:fleetId/fleets',
  SUPERFLEET_REPORTS = '/s/:fleetId/reports',
  SUPERFLEET_EVENTS = '/s/:fleetId/events',
  SUPERFLEET_EVENT = '/s/:fleetId/events/:eventId',
  HOME = '/f/:fleetId/g/:groupId/home',
  INSIGHTS = '/f/:fleetId/g/:groupId/insights',
  NEW_INSIGHTS_LANDING = '/f/:fleetId/g/:groupId/new-insights-landing',
  SAFETY_ASSESSMENT = '/f/:fleetId/g/:groupId/safety-assessment',
  DRIVER_SAFETY = '/f/:fleetId/g/:groupId/driver-safety',
  DRIVER_SAFETY_TREND = '/f/:fleetId/g/:groupId/driver-safety-trend',
  MANAGER_PERFORMANCE = '/f/:fleetId/g/:groupId/manager-performance',
  MANAGER_PERFORMANCE_TREND = '/f/:fleetId/g/:groupId/manager-performance-trend',
  EVENT_INSIGHTS = '/f/:fleetId/g/:groupId/event-insights',
  MAP = '/f/:fleetId/g/:groupId/map',
  ORGANIZATION = '/f/:fleetId/g/:groupId/organization',
  PRINT_REPORT = '/f/:fleetId/g/:groupId/reports/print',
  REPORTS = '/f/:fleetId/g/:groupId/reports',
  COLLISION_REPORT = '/f/:fleetId/g/:groupId/reports/collision',
  REPORTS_DEVICE_HEALTH = '/f/:fleetId/g/:groupId/reports/device-health',
  REAL_TIME_ALERTS_REPORT = '/f/:fleetId/g/:groupId/reports/real-time-alerts',
  TOP_PERFORMING = '/f/:fleetId/g/:groupId/reports/top-performing',
  AT_RISK = '/f/:fleetId/g/:groupId/reports/at-risk',
  CUSTOM_REPORT = '/f/:fleetId/g/:groupId/reports/custom-report',
  VEDR_REPORT = '/f/:fleetId/g/:groupId/reports/vedr',
  VEDR_DEVICE_ASSIGNMENT_REPORT = '/f/:fleetId/g/:groupId/reports/vedr/device-assignment',
  VEDR_KIS_REPORT = '/f/:fleetId/g/:groupId/reports/vedr/kis',
  POLICY_VIOLATIONS_REPORT = '/f/:fleetId/g/:groupId/reports/policy-violations',
  TAG = '/f/:fleetId/g/:groupId/tag',
  TAG_TRIPS = '/f/:fleetId/g/:groupId/tag-trips',
  DRIVER_COACHING = '/f/:fleetId/g/:groupId/e/:entityId/coaching',
  DRIVER_COACHING_HISTORY = '/f/:fleetId/g/:groupId/e/:entityId/historical-sessions',
  DRIVER_INSIGHTS = '/f/:fleetId/g/:groupId/d/:driverId/insights',
  DRIVER_PROFILE = '/f/:fleetId/g/:groupId/d/:driverId/profile',
  DRIVER_PROFILE_EDIT = '/f/:fleetId/g/:groupId/d/:driverId/profile-edit',
  DRIVER_LOGIN_COACHING = '/f/:fleetId/e/:entityId/driver-coaching',
  DRIVER_LOGIN_INSIGHTS = '/f/:fleetId/d/:driverId/driver-insights',
  DRIVER_LOGIN_PROFILE = '/f/:fleetId/d/:driverId/driver-profile',
  DRIVER_LOGIN_EVENT_DETAILS = '/f/:fleetId/d/:driverId/driver-event-details/:eventId',
  ENTITY_EVENT = '/f/:fleetId/g/:groupId/e/:entityId/events/:eventId',
  ENTITY_EVENTS = '/f/:fleetId/g/:groupId/e/:entityId/events',
  ENTITY_TRIPS = '/f/:fleetId/g/:groupId/e/:entityId/map',
  ENTITY = '/f/:fleetId/g/:groupId/e/:entityId',
  GROUP = '/f/:fleetId/g/:groupId',
  FLEET = '/f/:fleetId',
  FLEET_AND_GROUP = '/f/:fleetId/g/:groupId',
  F = '/f/',
  S = '/s/',
  DEVELOPER_DOCS = '/f/:fleetId/developer',
  LEGACY_EMAIL_EVENT = '/f/:fleetId/events',
  EVENT_DETAILS_PAGE = '/f/:fleetId/g/:groupId/event-details-page/:eventId',
  CSV_INSTALLER = '/f/:fleetId/csv-installer',
  USER_MANAGEMENT_MIGRATION_TOOL = '/f/:fleetId/um2-migration-tool',
  VEHICLES_OVERVIEW = '/f/:fleetId/g/:groupId/vehicles/overview',
  VEHICLES_DEVICE_MONITORING = '/f/:fleetId/g/:groupId/vehicles/device-monitoring',
  VEHICLES = '/f/:fleetId/g/:groupId/vehicles',
  VERSION = '/version',
  ADD_CONTRACT = '/f/:fleetId/settings/contract',
  EDIT_CONTRACT = '/f/:fleetId/settings/contract/:contractId',
}
