// Constants
export const SET_ENTITY_SEARCH = 'entitiy-picker/set-entity-search';

// Selectors
export const entitySearchSelector = ({ entityPicker }) => entityPicker.search;

// action
export const setEntitySearch = (search: string) => dispatch =>
  dispatch({ type: SET_ENTITY_SEARCH, payload: { search } });

export interface EntityPickerProps {
  search: string;
}

// Reducer
export const initialState: EntityPickerProps = {
  search: '',
};

export default (state = initialState, { type, payload }): EntityPickerProps => {
  if (type === SET_ENTITY_SEARCH) {
    return { ...state, search: payload.search };
  }
  return state;
};
