import React, { FC } from 'react';
import { connect } from 'react-redux';
import GroupDataContext from './context';
import { Group, findGroupById } from 'components/groups/groups.redux';

interface Props {
  group: Group;
}

const GroupDataProvider: FC<Props> = ({ children, group }) => {
  return (
    <GroupDataContext.Provider value={group}>
      {children}
    </GroupDataContext.Provider>
  );
};

const selector = ({ groups: { activeGroupId, rootGroup } }) => ({
  group: findGroupById(rootGroup, activeGroupId),
});

export default connect(selector)(GroupDataProvider);
