import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';
import { NotificationProps } from 'models/db';
import { colors } from '@nauto/uikit/dist/styles';
import { Icon } from '@nauto/uikit/dist/atoms/icons';
import Notification from './notification';
import { CircleIcon } from '@nauto/uikit/dist/atoms/graphs';
import LocalizedTime from 'atoms/localized-time';

interface Props extends WithTranslation {
  className?: string;
  notification: NotificationProps;
}

class VerifyingVideoRequestNotification extends React.Component<Props> {
  render() {
    const {
      className,
      t,
      notification: { id, properties },
    } = this.props;

    const videoTime = moment(properties.parameters['ts-min']);

    const timezone = properties.parameters.timezone;

    // the vehicle name - if no vehicle is found, revert to the device ID of that nauto device
    const vehicleName = properties['vehicle-name'] || properties['device-id'];

    /**
     * TODO: Break this out into a UI-kit atom
     */
    const verifyingSpinner = (
      <div className="verifying-icon">
        <div className="spinner layer">
          <CircleIcon
            className="circle-icon"
            color={colors.blue500}
            score={33}
            innerRadius={17}
            outerRadius={21}
          />
        </div>
        <div className="layer flex jc-center ai-center">
          <Icon icon="videoRequest" baseColor={colors.blue500} size="lg" />
        </div>
      </div>
    );

    return (
      <Notification
        id={id}
        dismissible={false}
        icon={verifyingSpinner}
        className={className}
      >
        <div>
          <div>{vehicleName}</div>
          <LocalizedTime time={videoTime} format="LLL" timezone={timezone} />
          <div>{t('Verifying video availability.')}</div>
        </div>
      </Notification>
    );
  }
}

// prettier-ignore
const StyledVideoRequestNotification = styled(VerifyingVideoRequestNotification)`
  .circle {
    width: 2.625rem;
    height: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: ${colors.blue10};
  }
  .verifying-icon {
    position: relative;
  }
  .verifying-icon,
  .verifying-icon .layer {
    height: 42px;
    width: 42px;
  }
  .verifying-icon .layer {
    position: absolute;
    top: 0;
    left: 0;
  }
  .verifying-icon .spinner {
    transform-origin: 50% 50%;
    animation: spin 2000ms linear infinite;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default withTranslation()(StyledVideoRequestNotification);
