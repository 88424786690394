import { Dispatch } from 'redux';
import * as API from '@nauto/api';
import config from 'utils/config';
import {
  clearUser,
  clearRedirect,
  clearPrevLocation,
  getFleets,
  getGroups,
  getServiceUrl,
  clearMapsEventsConfig,
  setVeraScoreType,
  clearLoggedInUserType,
  setLoggedInUserType,
  clearDelegatedLoginUserEmail,
  clearManagerPerformanceVisibleGraphs,
  clearDriverSafetyVisibleGraphs,
} from 'utils/localstorage';
import { fetchRequest } from 'utils/requests';
import { getGroupById } from 'utils/groups';
import { Constants } from 'components/auth/auth.reducer';
import { mpTrack, registerUser, mpSet, mpReset } from 'components/mixpanel';
import { MixpanelEventKeys } from 'components/mixpanel/tags';
import { Group } from 'components/groups/groups.redux';
import { VeraVersionType } from 'utils/vera-score-utils';
import { isEmpty, isNull } from 'lodash';
import { LoggedInUserType } from 'constants/roles';
import { buildAPIURLPrefix, buildLoginAPIURLPrefix } from 'utils/api';
import * as Sentry from '@sentry/browser';

interface Fleet {
  id: string;
  roles: string[];
  properties: {
    custom_video_length: number;
  };
}

interface Credentials {
  email: string;
  password: string;
}

const getLoggedUserObjectType = (user, driver, global_roles) => {
  if (!isEmpty(user)) {
    return { userInfo: user, userType: LoggedInUserType.USER };
  } else if (!isEmpty(driver) && isEmpty(user) && isNull(global_roles)) {
    return { userInfo: driver, userType: LoggedInUserType.DRIVER };
  }
};

/**
 * Attempt to log a user into our system via email and password credentials
 */
export const login = ({ email, password }: Credentials) => (
  dispatch: Dispatch<any>,
) => {
  const url = `${buildLoginAPIURLPrefix()}/login`;
  const payload = {
    method: 'POST',
    body: {
      email,
      password,
    },
  };

  dispatch({ type: Constants.USER_AUTHENTICATE_START });

  return fetchRequest(url, payload, false)
    .then(({ data: { fleets, token, user, driver, global_roles } }) => {
      mpTrack(MixpanelEventKeys.UserSignInSuccess);
      API.setOptions({ token });
      const { userInfo, userType } = getLoggedUserObjectType(
        user,
        driver,
        global_roles,
      );
      setLoggedInUserType(userType);

      Sentry.setUser({
        email: userInfo.email,
        userType: userType,
        globalRoles: global_roles,
      });

      return dispatch({
        type: Constants.USER_AUTHENTICATED,
        meta: { sync: true },
        payload: {
          fleets,
          token,
          user: userInfo,
          globalRoles: global_roles,
          loggedInUserType: userType,
        },
      });
    })
    .catch(error => {
      mpTrack(MixpanelEventKeys.UserSignInFailure);
      return dispatch({
        type: Constants.USER_AUTHENTICATE_FAILURE,
        payload: error,
      });
    });
};

/**
 * Attempt to log a user into our system via Parner SSO with email and password credentials
 */
export const partnerLogin = (externalAuthCode: string, redirectUri = '') => (
  dispatch: Dispatch<any>,
) => {
  const url = `${config.admin_api_url_v3}/sso`;

  const payload = {
    method: 'POST',
    body: {
      external_auth_code: externalAuthCode,
      channel: 'amazon',
      redirect_uri: redirectUri,
    },
  };

  dispatch({ type: Constants.USER_AUTHENTICATE_START });
  /* tslint:disable:no-identical-functions */
  return fetchRequest(url, payload, false)
    .then(({ data: { fleets, token, user } }) => {
      mpTrack(MixpanelEventKeys.UserPartnerSignInSuccss);
      registerUser(user);

      API.setOptions({ token });

      return dispatch({
        type: Constants.USER_AUTHENTICATED,
        meta: { sync: true },
        payload: {
          fleets,
          token,
          user,
        },
      });
    })
    .catch(error => {
      mpTrack(MixpanelEventKeys.UserPartnerSignInFailure);
      return dispatch({
        type: Constants.USER_AUTHENTICATE_FAILURE,
        payload: error,
      });
    });
};
/* tslint:enable:no-identical-functions */
/**
 * Updates the user in redux and in the cookie, synchronously
 */
export const updateUser = (user: any) => {
  if (user.name) {
    mpSet({ $name: user.name });
  }
  if (user.email) {
    mpSet({ $name: user.email });
  }
  return {
    type: Constants.USER_UPDATE,
    meta: { sync: true },
    payload: { user },
  };
};

/**
 * Log a user out of the system and invalidate their local session
 */
export const logout = () => {
  API.setOptions({ token: null });
  mpTrack(MixpanelEventKeys.UserSignOutSuccess);
  mpReset();
  clearUser();
  clearRedirect();
  clearPrevLocation();
  clearMapsEventsConfig();
  clearLoggedInUserType();
  clearDelegatedLoginUserEmail();
  clearManagerPerformanceVisibleGraphs();
  clearDriverSafetyVisibleGraphs();

  return { type: Constants.RESET_ALL };
};

/**
 * Initiate a forgot password email to the user
 */
export const sendForgotPasswordEmail = ({
  email,
  isUserDriver = false,
}: {
  email: string;
  isUserDriver?: boolean;
}) => (dispatch: (any) => void): Promise<void> => {
  const url = `${config.admin_api_url}/reset-password`;
  const payload = {
    method: 'POST',
    body: { email },
  };

  dispatch({
    type: Constants.FORGOT_PASSWORD_START,
  });

  const request = isUserDriver
    ? API.driverLogin.resetDriverPasswordRequest(
        {
          email,
        },
        { url: buildAPIURLPrefix(), version: 'v1' },
      )
    : fetchRequest(url, payload, false);

  return request
    .then(() => {
      dispatch({
        type: Constants.FORGOT_PASSWORD_SUCCESS,
      });
    })
    .catch(error => {
      dispatch({
        type: Constants.FORGOT_PASSWORD_ERROR,
        payload: {
          error,
        },
      });
      throw new Error(error);
    });
};

export const setPassword = ({
  password,
  token,
  isUserDriver = false,
}: {
  password: string;
  token: string;
  isUserDriver?: boolean;
}) => (dispatch: (any) => void): Promise<void> => {
  const url = `${config.admin_api_url}/reset-password/${token}`;
  const payload = {
    method: 'POST',
    body: { password },
  };

  const request = isUserDriver
    ? API.driverLogin.resetDriverPassword(
        {
          password,
          token,
        },
        { url: buildAPIURLPrefix(), version: 'v1' },
      )
    : fetchRequest(url, payload, false);

  dispatch({ type: Constants.SET_PASSWORD_START });

  return request
    .then(() => {
      dispatch({
        type: Constants.SET_PASSWORD_SUCCESS,
      });
    })
    .catch(error => {
      dispatch({
        type: Constants.SET_PASSWORD_ERROR,
        payload: {
          error,
        },
      });
      throw new Error(error);
    });
};

export const validateResetPasswordToken = ({
  token,
}: {
  token: string;
}): Promise<any> => {
  return API.driverLogin
    .validateResetPasswordToken(
      { token },
      { url: buildAPIURLPrefix(), version: 'v1' },
    )
    .then(response => {
      return response;
    });
};

/**
 * Select a fleet by id
 */
export const selectFleet = (id: string) => {
  const fleets = getFleets();
  const lastFleet = fleets.find(f => f.fleet.id === id).fleet.name;

  mpSet({ 'Last Fleet Accessed': lastFleet });
  mpTrack('Fleet Selected', { id });

  return {
    type: Constants.SELECT_FLEET,
    meta: { sync: true },
    payload: {
      currentFleet: id,
    },
  };
};

/**
 * Select a group by id
 */
export const selectGroup = (id: string, root?: Group) => {
  const groups = root ? root : getGroups();
  const lastGroup = getGroupById(id, groups);

  mpSet({ 'Last Goup Accessed': lastGroup });
  mpTrack('Group Selected', { id });

  return {
    type: Constants.SELECT_GROUP,
    meta: { sync: true },
    payload: {
      currentGroup: id,
    },
  };
};

/**
 * Update a user's full name
 */
export const updateName = (userId: string, name: string): any => (
  dispatch: any,
): Promise<void> => {
  const url = `${config.admin_api_url}/users/${userId}`;

  const mpUpdate = 'Update Name';
  dispatch({ type: Constants.UPDATE_NAME_START });

  return fetchRequest(url, {
    method: 'POST',
    body: { name },
  })
    .then(({ data }) => {
      mpTrack(mpUpdate, { success: true });
      mpSet({ $name: name });
      dispatch({
        type: Constants.UPDATE_NAME_SUCCESS,
        meta: {
          sync: true,
        },
        payload: {
          user: data,
        },
      });
    })
    .catch(error => {
      mpTrack(mpUpdate, { success: false, error });
      dispatch({
        type: Constants.UPDATE_NAME_ERROR,
        payload: {
          error,
        },
      });
      throw error;
    });
};

/**
 * Refreshes the fleet data from the API
 */
export const refreshFleets = (userId: string): any => (
  dispatch: any,
): Promise<void> => {
  const url = `${config.admin_api_url}/users/${userId}`;
  const fleetUrl = `${getServiceUrl()}/fleets/`;

  const getFleet = (fleet: Fleet): Promise<any> =>
    fetchRequest(`${fleetUrl}${fleet.id}`, {
      method: 'GET',
    })
      .then(({ data }) => data)
      .then(curFleet => ({
        fleet: curFleet,
        roles: fleet.roles,
      }));

  const getAllFleets = (fleets: Fleet[]): Promise<any[]> =>
    Promise.all(fleets.map(getFleet));

  dispatch({ type: Constants.REFRESH_FLEETS_START });

  const mpRefresh = 'Refresh Fleets';
  return fetchRequest(url, {
    method: 'GET',
  })
    .then(({ data: { roles } }) =>
      roles.map(role => ({ id: role.fleet, roles: [role.role] })),
    )
    .then(getAllFleets)
    .then(fleets => {
      mpTrack(mpRefresh, { success: true });
      dispatch({
        type: Constants.REFRESH_FLEETS_SUCCESS,
        meta: { sync: true },
        payload: {
          fleets,
        },
      });
    })
    .catch(error => {
      mpTrack(mpRefresh, { success: false, error });
      dispatch({
        type: Constants.REFRESH_FLEETS_ERROR,
        payload: {
          error,
        },
      });
      throw error;
    });
};

/**
 * Set a url to redirect to after login, and optionally a message to display on the login screen
 */
export const setLoginRedirect = (path: string, flash = '') => ({
  type: Constants.SET_LOGIN_REDIRECT,
  payload: {
    path,
    flash,
  },
});

/**
 * Clear the redirect property
 */
export const clearLoginRedirect = () => ({
  type: Constants.CLEAR_LOGIN_REDIRECT,
});

export const updateVeraScoreType = (
  veraScoreType: VeraVersionType,
): {
  type: Constants;
  payload: { veraVersionType: VeraVersionType };
} => {
  setVeraScoreType(veraScoreType);
  return {
    type: Constants.USER_VERA_SCORE_VERSION_UPDATE,
    payload: { veraVersionType: veraScoreType },
  };
};
