import queryString from 'query-string';
const path = '//nauto-public.s3.amazonaws.com/apps/assets/img';

export const getSubdomain = () => {
  const host = window.location.host;
  const search = queryString.parse(window.location.search);
  return search?.subdomain ||  host.split('.')[0];
}

const assets = {
  default: {
    logo_horizontal_green: `${path}/logo-horizontal-green.svg`,
  },
  brightdrop: {
    logo_horizontal_green: `https://brightdrop-cobrand.s3.amazonaws.com/brightDrop-nauto-Lock-up.svg`
  },
};

export const getAssets = () => {
  const subdomain = getSubdomain();
  return assets[subdomain] || assets.default;
};