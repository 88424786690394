import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PageTitle from '@nauto/uikit/dist/atoms/text/page-title';
import NoDataImage from 'assets/img/no-data.svg';
import * as Sentry from '@sentry/browser';

interface Props {
  t?: any;
}

interface ErrorState {
  hasError: boolean;
}

declare let window;

class ErrorBoundary extends React.Component<Props, ErrorState> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error, {
      extra: info,
      tags: {
        jsHeapSizeLimit:
          window?.performance?.memory?.jsHeapSizeLimit || 'Not supported',
        totalJSHeapSize:
          window?.performance?.memory?.totalJSHeapSize || 'Not supported',
        usedJSHeapSize:
          window?.performance?.memory?.usedJSHeapSize || 'Not supported',
      },
    });
    console.log(error, info);

    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      const { t } = this.props;

      return (
        <ErrorContainer>
          <div data-testid="error-message">
            <PageTitle
              title1={t('An error occurred')}
              /* tslint:disable-next-line:quotemark */
              title2={t("Don't worry. Our team has been notified")}
            />
          </div>
          <div className="no-data-image">
            <img src={NoDataImage} />
          </div>
        </ErrorContainer>
      );
    } else {
      return this.props.children;
    }
  }
}

const ErrorContainer = styled.div`
  padding: 1em;
  max-width: 100%;
  text-align: center;
  flex-grow: 1;

  .no-data-image img {
    margin: 2rem auto;
    width: 20vw;
    max-width: 90%;
    max-height: 60%;
  }
`;

export default withTranslation()(ErrorBoundary);
