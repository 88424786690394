import React, { FC, createContext, useState, useContext } from 'react';
import * as API from '@nauto/api';

interface DeviceContext {
  activeEvent: API.events.Event;
  setActiveEvent: (event: API.events.Event) => void;
  showCalendar: boolean;
  setShowCalendar: (show: boolean) => void;
}

const context = createContext<DeviceContext>(null);
const { Provider } = context;
export const useMapData = (): DeviceContext => useContext(context);

const DeviceProvider: FC = ({ children }) => {
  const [activeEvent, setActiveEvent] = useState<API.events.Event>();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  return (
    <Provider
      value={{
        activeEvent,
        setActiveEvent,
        showCalendar,
        setShowCalendar,
      }}
    >
      {children}
    </Provider>
  );
};

export { DeviceProvider };
export default context;
