import * as React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthPersistence } from 'decorators';
import FleetData from './fleet-data';
import { LayoutWrapper } from 'components/layout';
import { ROUTES } from 'routes/constants';
import { routeKeys, routeMap } from 'routes/map';
import { useTranslation } from 'react-i18next';
import Tracking from 'components/tracking/tracking';
import { TrackPage } from './track-page';
import EntitySync from 'components/entity-type/entity-sync';
import useFeatureFlags from 'hooks/use-feature-flags';
import VersionRoute from '../version.route';
import ErrorBoundary from 'components/error-boundary/error-boundary';

export const Routes: React.SFC<any> = props => {
  const {
    user: { authenticated },
  } = props;
  const [t] = useTranslation();
  const featureFlags = useFeatureFlags();

  return (
    <Switch>
      <Route path={ROUTES.VERSION} component={VersionRoute} />
      <Route path={routeKeys}>
        {({ match, location }) => {
          const path = match.path;
          const rules = routeMap[path];
          const { component, trackingId, template } = rules;
          const Component =
            typeof component === 'function'
              ? component(featureFlags)
              : component;
          const title = rules.i18nTitle && rules.i18nTitle(t, featureFlags);

          const layoutAndContent = (
            <LayoutWrapper {...{ ...template, title }}>
              <Route path={path} component={Component} />
            </LayoutWrapper>
          );

          return (
            <>
              <EntitySync />
              <TrackPage trackingId={trackingId} />
              <Switch>
                {/* all routes that start with /f/ require fleet data */}
                <Route path={[ROUTES.F, ROUTES.S]} exact={false}>
                  <AuthPersistence routeRequiresAuthentication={!rules.public}>
                    <Tracking />
                    <ErrorBoundary>
                      <FleetData match={match} location={location}>
                        {layoutAndContent}
                      </FleetData>
                    </ErrorBoundary>
                  </AuthPersistence>
                </Route>
                <Route>{layoutAndContent}</Route>
              </Switch>
            </>
          );
        }}
      </Route>
      <Route>
        {({ location }) => {
          if (location.pathname) {
            console.error('Unexpected route reached:', location);
          }
          return <Redirect to={authenticated ? ROUTES.F : ROUTES.LOGIN} />;
        }}
      </Route>
    </Switch>
  );
};

const selector = createStructuredSelector({
  user: ({ user }) => user,
});

export default connect(selector)(Routes);
