import { createStructuredSelector } from 'reselect';
import { fetchRequest, apiExtras } from 'utils/requests';
import { getFleetId, getServiceUrl } from 'utils/localstorage';
import { SingleEventReducer } from 'models/generics';
import { path, convertHexToDecimal } from 'utils/helpers';
import { debugSelector } from 'components/modes/modes.redux';
import { mpTrack } from 'components/mixpanel';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export const FETCHING_EVENT = 'event/fetching-event';
export const EVENT_RECEIVED = 'event/event-received';
export const EVENT_FETCH_FAILED = 'event/event-fetch-failed';
export const EVENT_NOT_FOUND = 'event/event-not-found';
export const CLEAR_EVENT = 'event/event-fetch-failed';

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

/**
 * selector for single event page page
 */

export const singleEventSelector = createStructuredSelector({
  event: ({ singleEvent }) => singleEvent.event,
  fetchingEvent: ({ singleEvent }) => singleEvent.fetchingEvent,
  eventNotFound: ({ singleEvent }) => singleEvent.eventNotFound,
  debug: debugSelector,
});

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */
export const getEvent = (
  hexTimestamp: string,
  device: string,
  type: string,
  distractionType?: string = null,
) => (dispatch: (any) => void) => {
  dispatch({ type: FETCHING_EVENT });
  // we want to be as lenient as possible when querying for single events,
  // since the user was directed to this link we should show whatever we have, so we do no debounce or media-completeness check
  const params = {
    device,
    _ts: type,
    '_ts._min': `ms:${convertHexToDecimal(hexTimestamp)}`,
    '_ts._max': `ms:${convertHexToDecimal(hexTimestamp) + 2}`,
    '_ts._limit': 2 /* limit two to account for review-confirmed- events appearing twice in elastic-search index */,
    include_debounced: true,
    media_check: false,
    '_ts._format': 'object',
    distraction_type: distractionType,
    include_confirmed_type: true,
    ...apiExtras(),
  };

  const url = path(
    `${getServiceUrl()}/fleets/${getFleetId()}/events/device-events`,
    params,
  );
  const options = {
    method: 'GET',
  };

  return fetchRequest(url, options)
    .then((response: any) => {
      const event = response.data.length > 0 && response.data[0];

      if (event) {
        mpTrack('Get Event', { success: true });
        dispatch({
          type: EVENT_RECEIVED,
          payload: { event },
        });
      } else {
        mpTrack('Get Event', {
          success: false,
          error: 'Event not found',
        });
        dispatch({
          type: EVENT_NOT_FOUND,
        });
      }
    })
    .catch(error => {
      mpTrack('Get Event', { success: false, error });
      dispatch({ type: EVENT_FETCH_FAILED, payload: error });
    });
};

// clears out the current event
export const clearEvent = () => dispatch => dispatch({ type: CLEAR_EVENT });

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */
export const initialState: SingleEventReducer = {
  fetchingEvent: false,
  eventNotFound: false,
  event: null,
};

export default (
  state = initialState,
  { type, payload },
): SingleEventReducer => {
  switch (type) {
    case FETCHING_EVENT:
      return {
        ...initialState,
        fetchingEvent: true,
      };
    case EVENT_RECEIVED:
      return {
        ...state,
        fetchingEvent: false,
        event: payload.event,
      };
    case EVENT_NOT_FOUND:
    case EVENT_FETCH_FAILED:
      return {
        ...state,
        // a key with value null means the suggestions fetch is in progress
        fetchingEvent: false,
        eventNotFound: true,
      };
    case CLEAR_EVENT:
      return initialState;
    default:
      return state;
  }
};
