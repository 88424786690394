import React, { FC } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import VehicleDataContext from './context';
import { Vehicle } from 'models/db';
import { indexedFleetVehiclesSelector } from 'components/organization/vehicles/vehicles.redux';

interface Props {
  vehicles: Record<string, Vehicle>;
}

const VehicleDataProvider: FC<Props> = ({ children, vehicles }) => (
  <VehicleDataContext.Provider value={vehicles}>
    {children}
  </VehicleDataContext.Provider>
);

const selector = createStructuredSelector({
  vehicles: indexedFleetVehiclesSelector,
});

export default connect(selector)(VehicleDataProvider);
