export enum dataTestId {
  insightsNavItem = 'insights-nav-item',
  mapNavItem = 'map-nav-item',
  eventsNavItem = 'events-nav-item',
  reportsNavItem = 'reports-nav-item',
  organizationNavItem = 'organization-nav-item',
  coachingNavItem = 'coaching-nav-item',
  superfleetInsightsNavItem = 'superfleet-insights-nav-item',
  superfleetEventsNavItem = 'superfleet-events-nav-item',
  superfleetOrganizationNavItem = 'superfleet-organization-nav-item',
  superfleetReportsNavItem = 'superfleet-reports-nav-item',
  userProfileNavItem = 'user-profile-nav-item',
  userManagementNavItem = 'user-management-nav-item',
  fleetSettingsNavItem = 'fleet-settings-nav-item',
  helpCenterNavItem = 'help-center-nav-item',
  productTrainingNavItem = 'product-training-nav-item',
  developerSettingsNavItem = 'developer-settings-nav-item',
  signOutNavItem = 'sign-out-nav-item',
  internalPage = 'internal-page-nav-item',
  vehiclesNavItem = 'vehicles-page-nav-item',
}
