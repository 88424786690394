import { createStructuredSelector, createSelector } from 'reselect';
import {
  InsightsDateFilter,
  VeraScoreProps,
  latestScoreAndChange,
  latestDefinedInterval,
  dateFilterToParams,
  scoresOverTime,
  filtered,
  interpolateUndefinedValues,
  vera,
  attention,
  smoothDriving,
  combineVeraScores,
  requestScores,
  dateFilterSelector,
} from 'redux/insights';
import { EntityType } from 'components/entity-type/entity-type-utils';
import {
  activeGroupIdSelector,
  findGroupById,
} from 'components/groups/groups.redux';
import { mpTrack } from 'components/mixpanel';

/**
 * ----------------------------------------------------------------------------
 * Selectors
 * ----------------------------------------------------------------------------
 */

const allScoresSelector = ({ fleetInsights }) => fleetInsights.scores;

export const activeGroupScoresSelector = createSelector<any, any, any, any>(
  allScoresSelector,
  activeGroupIdSelector,
  (allScores, groupID) => {
    const activeGroupData = allScores.find(group => group.group_id === groupID);
    return activeGroupData ? activeGroupData.score : [];
  },
);

const subgroupScoresSelector = createSelector<any, any, any, any, any, any>(
  allScoresSelector,
  ({ insights }) => insights.scoreDefined,
  ({ groups }) => groups.rootGroup,
  activeGroupIdSelector,
  (allScores, isDefined, rootGroup, activeGroupID) => {
    const subgroupData = allScores.map(groupData => {
      const group = findGroupById(rootGroup, groupData.group_id);
      return {
        group,
        vera: latestScoreAndChange(groupData.score, vera, isDefined),
        attention: latestScoreAndChange(groupData.score, attention, isDefined),
        // tslint:disable-next-line:prettier
        smoothDriving: latestScoreAndChange(
          groupData.score,
          smoothDriving,
          isDefined,
        ),
      };
    });

    const namedSubgroupData = subgroupData.filter(
      d =>
        d.group && d.group.tag !== 'unassigned' && d.group.id !== activeGroupID,
    );

    return namedSubgroupData.sort((d1, d2) =>
      d1.group.name.localeCompare(d2.group.name),
    );
  },
);

const scoreChangeSelector = selector =>
  createSelector(
    activeGroupScoresSelector,
    ({ insights }) => insights.scoreDefined,
    (scores, isDefined) => latestScoreAndChange(scores, selector, isDefined),
  );

export const scoreChangesSelector = createStructuredSelector({
  vera: scoreChangeSelector(vera),
  attention: scoreChangeSelector(attention),
  smoothDriving: scoreChangeSelector(smoothDriving),
});

const scoreComparisonSelector = selector =>
  createSelector(
    activeGroupScoresSelector,
    ({ fleetInsights }) => fleetInsights.othersScores,
    ({ insights }) => insights.scoreDefined,
    (_scores, _otherScores, scoreDefined) => {
      const [scores, otherScores] = [_scores, _otherScores]
        .map(s => scoresOverTime(s, selector))
        .map(s => interpolateUndefinedValues(s, scoreDefined));

      return { scores, otherScores };
    },
  );

export const scoreComparisonsSelector = createStructuredSelector({
  vera: scoreComparisonSelector(vera),
  attention: scoreComparisonSelector(attention),
  smoothDriving: scoreComparisonSelector(smoothDriving),
});

export const latestDefinedFleetScoreIntervalSelector = createSelector(
  activeGroupScoresSelector,
  ({ insights }) => insights.scoreDefined,
  latestDefinedInterval,
);

export const filteredFleetInsightsSelector = filtered(
  createStructuredSelector({
    groups: ({ groups }) => ({ groups }),
    latestDefinedInterval: latestDefinedFleetScoreIntervalSelector,
  }),
);

export const filteredScoreComparisonsSelector = filtered(
  createStructuredSelector({
    scoreComparisons: scoreComparisonsSelector,
  }),
);

export const fleetSummarySelector = createStructuredSelector({
  scoreChanges: scoreChangesSelector,
  subgroupScores: subgroupScoresSelector,
  latestDefinedInterval: latestDefinedFleetScoreIntervalSelector,
  dateFilter: dateFilterSelector,
});

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */

export enum ActionTypes {
  FETCHING_SCORES = 'fleet-insights/fetching-scores',
  FETCHING_OTHERS_SCORES = 'fleet-insights/fetching-others-scores',
  FLEET_SCORES_FETCHED = 'fleet-insights/fleet-scores-fetched',
  OTHER_FLEETS_SCORES_FETCHED = 'fleet-insights/other-fleets-scores-fetched',
  DRIVER_SCORES_FETCHED = 'fleet-insights/driver-scores-fetched',
  ERROR = 'fleet-insights/error',
}

const handleError = (dispatch: (any) => void, error: any): void => {
  dispatch({ type: ActionTypes.ERROR, payload: { error } });
};

/* tslint:disable:no-duplicate-string */
export const fetchFleetScores = (
  groupId: string,
  dateFilter: InsightsDateFilter,
  vera2Cutover: string,
) => (dispatch: (any) => void, getState) => {
  dispatch({ type: ActionTypes.FETCHING_SCORES });

  const { interval, startMs, endMs } = dateFilterToParams(dateFilter);

  const params = {
    view: EntityType.VEHICLE,
    type: interval,
    min: startMs,
    max: endMs,
    no_holes: true,
  };

  const requests = requestScores(`/groups/${groupId}`, params, vera2Cutover);

  return requests
    .then(([response1, response2]) => {
      const scores = combineVeraScores(
        response1.data,
        response2.data,
        'group_id',
      );

      mpTrack('Fetch Fleet Scores', { success: true });
      dispatch({
        type: ActionTypes.FLEET_SCORES_FETCHED,
        payload: { scores },
      });
    })
    .catch((error: any) => {
      mpTrack('Fetch Fleet Scores', { success: false, error });
      handleError(dispatch, error);
    });
};

export const fetchOtherFleetsScores = (
  dateFilter: InsightsDateFilter,
  vera2Cutover: string,
) => (dispatch: (any) => void) => {
  dispatch({ type: ActionTypes.FETCHING_OTHERS_SCORES });

  const { interval, startMs, endMs } = dateFilterToParams(dateFilter);

  const params = {
    no_holes: true,
    type: interval,
    scope: 'global',
    self: false,
    min: startMs,
    max: endMs,
  };

  const requests = requestScores('', params, vera2Cutover);
  return requests
    .then(([response1, response2]) => {
      const vera1Scores =
        response1.data && response1.data.score ? response1.data.score : [];
      const { weekly_score, monthly_score } = response2.data[0];
      const scores = [...vera1Scores, ...weekly_score, ...monthly_score];

      mpTrack('Fetch Other Fleets Scores', { success: true });
      dispatch({
        type: ActionTypes.OTHER_FLEETS_SCORES_FETCHED,
        payload: { otherScores: scores },
      });
    })
    .catch((error: any) => {
      console.log(error);
      mpTrack('Fetch Other Fleets Scores', { success: false, error });
      handleError(dispatch, error);
    });
};

/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */

interface FleetInsightsState {
  isFetchingScores: boolean;
  isFetchingOthersScores: boolean;
  error: string;
  scores: VeraScoreProps[];
  othersScores: VeraScoreProps[];
}

export const defaultState: FleetInsightsState = {
  isFetchingScores: false,
  isFetchingOthersScores: false,
  error: '',
  scores: [],
  othersScores: [],
};

export default (
  state = defaultState,
  { type, payload },
): FleetInsightsState => {
  switch (type) {
    case ActionTypes.FETCHING_SCORES:
      return {
        ...state,
        // clear scores
        scores: defaultState.scores,
        isFetchingScores: true,
      };
    case ActionTypes.FETCHING_OTHERS_SCORES:
      return {
        ...state,
        // clear scores
        othersScores: defaultState.othersScores,
        isFetchingOthersScores: true,
      };
    case ActionTypes.FLEET_SCORES_FETCHED:
      return {
        ...state,
        scores: payload.scores || defaultState.scores,
        isFetchingScores: false,
      };
    case ActionTypes.OTHER_FLEETS_SCORES_FETCHED:
      return {
        ...state,
        othersScores: payload.otherScores || defaultState.othersScores,
        isFetchingOthersScores: false,
      };
    default:
      return state;
  }
};
