import { createContext } from 'react';

export interface GlobalUserContextProps {
  isGlobalUser: boolean;
  isSupportUser: boolean;
}

const initialValue: GlobalUserContextProps = {
  isGlobalUser: false,
  isSupportUser: false,
};

export default createContext(initialValue);
