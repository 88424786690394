import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@nauto/uikit/dist/atoms/spinner';
import { colors } from '@nauto/uikit/dist/styles';

interface Props {
  text?: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

class CenteredSpinner extends React.Component<Props> {
  render() {
    const { className, text, size } = this.props;

    return (
      <div
        data-testid="centered-spinner"
        className={`centered-spinner ${className}`}
      >
        <Spinner speed="normal" size={size || 'md'} />
        {text && <span className="loading-text">{text}</span>}
      </div>
    );
  }
}

const paddingMap = { sm: '1rem', md: '2rem', lg: '2.5rem' };

const StyledMyAtom = styled(CenteredSpinner)`
  width: 100%;
  height: 100%;
  padding-top: ${props => paddingMap[props.size] || '2rem'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  .loading-text {
    padding-top: 1rem;
    color: ${colors.grey500};
  }
`;

export default StyledMyAtom;
