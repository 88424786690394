import React, { FC } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import DriverDataContext from './context';
import { Driver } from 'models/db';
import { indexedFleetDriversSelector } from 'components/organization/drivers/drivers.redux';

interface Props {
  drivers: Record<string, Driver>;
}

const DriverDataProvider: FC<Props> = ({ children, drivers }) => (
  <DriverDataContext.Provider value={drivers}>
    {children}
  </DriverDataContext.Provider>
);

const selector = createStructuredSelector({
  drivers: indexedFleetDriversSelector,
});

export default connect(selector)(DriverDataProvider);
