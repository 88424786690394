import React from 'react';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import { NotificationProps } from 'models/db';
import { colors } from '@nauto/uikit/dist/styles';
import Notification from './notification';
import { CircleIcon } from '@nauto/uikit/dist/atoms/graphs';
import { Icon } from '@nauto/uikit/dist/atoms/icons';
import { convertHexToDecimal } from 'utils/helpers';
import LocalizedTime from 'atoms/localized-time';
import ErrorBoundary from 'components/error-boundary/error-boundary';

interface Props extends WithTranslation {
  notification: NotificationProps;
  className?: string;
}

class UploadingVideoRequestNotification extends React.Component<Props> {
  render() {
    const {
      className,
      t,
      notification: { id, properties },
    } = this.props;

    const hasInternalVideo =
      properties.parameters && properties.parameters.video_in;
    const startTime = hasInternalVideo
      ? properties.parameters.video_in[0]
      : properties.parameters.video_out[0];
    const videoStartMs = convertHexToDecimal(startTime);
    const videoTime = moment(videoStartMs);
    const timezone = properties.parameters.timezone;
    // the vehicle name - if no vehicle is found, revert to the device ID of that nauto device
    const vehicleName = properties['vehicle-name'] || properties['device-id'];
    const {
      parameters: {
        media_uploads: { expected, expired, failed, uploaded },
      },
    } = properties;
    const percent = ((uploaded + expired + failed) / expected) * 100;
    const formattedPercent = percent.toFixed();

    return (
      <Wrapper>
        <Notification
          id={id}
          dismissible={false}
          icon={
            <CircleIcon
              className="circle-icon"
              color={colors.blue500}
              score={percent}
              innerRadius={17}
              outerRadius={21}
            />
          }
          className={className}
        >
          <div>
            <div>{vehicleName}</div>
            <LocalizedTime time={videoTime} format="LLL" timezone={timezone} />
            <div>
              {t('{{percent}}% uploaded.', {
                percent: formattedPercent,
              })}
            </div>
          </div>
        </Notification>
        <IconOverlay>
          <Icon icon="videoRequest" baseColor={colors.blue500} size="lg" />
        </IconOverlay>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
`;

const IconOverlay = styled.div`
  position: absolute;
  top: 32px;
  left: 25px;
`;

export default withTranslation()(UploadingVideoRequestNotification);
