import ldRedux from 'ld-redux';
import { defaultKebabCaseFlags } from './flags';
import {
  getLaunchDarklyClientSideID,
  LDUser,
} from 'components/feature-flags/flag-selectors';

declare let window: any;

export const initLD = (dispatch, ldUser): void => {
  ldRedux.init({
    clientSideId: getLaunchDarklyClientSideID(),
    user: ldUser,
    flags: defaultKebabCaseFlags,
    dispatch,
    options: { bootstrap: 'localStorage' },
  });
};

export const updateLD = (ldUser: LDUser): void => {
  window.ldClient.identify(ldUser, null);
};

export const closeLD = (): void => {
  window.ldClient?.close?.();
};
