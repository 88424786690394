import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';
import { NotificationProps } from 'models/db';
import { colors } from '@nauto/uikit/dist/styles';
import { Icon } from '@nauto/uikit/dist/atoms/icons';
import Notification from './notification';
import LocalizedTime from 'atoms/localized-time';

interface Props extends WithTranslation {
  className?: string;
  notification: NotificationProps;
}

class FailedVideoRequestNotification extends React.Component<Props> {
  render() {
    const {
      className,
      t,
      notification: { id, properties, status },
    } = this.props;

    const videoTime = moment(properties.parameters['ts-min']);
    const timezone = properties.parameters.timezone;
    // the vehicle name - if no vehicle is found, revert to the device ID of that nauto device
    const vehicleName = properties['vehicle-name'] || properties['device-id'];

    const failedIcon = (
      <div className="circle">
        <Icon icon="noVideo" baseColor={colors.alert} />
      </div>
    );

    return (
      <Notification
        id={id}
        dismissible={true}
        icon={failedIcon}
        className={className}
        status={status}
      >
        <div>
          <div>{vehicleName}</div>
          <LocalizedTime time={videoTime} format="LLL" timezone={timezone} />
          <div>{t('Request failed. Video unavailable.')}</div>
        </div>
      </Notification>
    );
  }
}

const StyledVideoRequestNotification = styled(FailedVideoRequestNotification)`
  .circle {
    width: 2.625rem;
    height: 2.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: ${colors.red100};
  }
`;

export default withTranslation()(StyledVideoRequestNotification);
