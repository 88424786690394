import React from 'react';
import styled from 'styled-components';
import { colors } from '@nauto/uikit/dist/styles';
import { Icon } from '@nauto/uikit/dist/atoms/icons';

interface Props {
  className?: string;
  heading: string;
  subHeading?: string;
}

export class EmptySearch extends React.Component<Props, any> {
  render() {
    const { className, heading, subHeading } = this.props;
    return (
      <div className={className}>
        <div className="icon-container">
          <Icon className="search-icon" icon="search" />
        </div>
        <div className="heading">{heading}</div>
        {subHeading && <div className="sub-heading">{subHeading}</div>}
      </div>
    );
  }
}
export default styled(EmptySearch)`
  display: flex;
  flex-flow: column;
  align-items: center;
  line-height: 1.5rem;
  width: 100%;
  .icon-container {
    width: 3.25rem;
    height: 3.25rem;
    border-radius: 50%;
    background-color: ${colors.grey50};
    .icon {
      top: 1rem;
      position: relative;
      left: 1rem;
    }
  }
  .heading,
  .sub-heading {
    text-align: center;
  }
  .heading {
    margin-top: 1rem;
    font-size: 1rem;
    color: ${colors.grey900};
  }
  .sub-heading {
    width: 70%;
    color: ${colors.grey700};
  }
`;
