import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import {
  textSizesPx,
  lineHeightSizesPx,
  colors,
  ScreenBreakPoints,
  spaceSizesPx,
} from '@nauto/core';

const NavMenuItemWrapper = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;

  &:hover {
    background: ${colors.grey100};
  }

  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    min-height: 48px;

    &:hover {
      background: transparent;
    }
    &.nav-menu-item.isActive {
      background: ${colors.brand};
    }
  }
`;
const NavMenuItemIcon = styled.span`
  display: flex;
  margin-right: ${spaceSizesPx.md};
  padding: ${spaceSizesPx.sm};

  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    margin-right: 0;
    padding: 12px ${spaceSizesPx.md};

    &.nav-menu-item-icon-wrapper.isActive {
      path {
        fill: ${colors.white} !important;
      }
    }
  }
`;
const LabelText = styled.span`
  font-size: ${textSizesPx.sm};
  line-height: ${lineHeightSizesPx.sm};

  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    font-size: ${textSizesPx.md};
    line-height: ${lineHeightSizesPx.md};
  }
`;

export interface Props {
  id?: string;
  label: string;
  icon: JSX.Element;
  isActive?: boolean;
  onClick?: () => void;
  forMobileNavMenu?: boolean;
  forDriverMobileNavMenu?: boolean;
  /** OPTIONAL: Pass in a HTML data-testid attribute */
  'data-testid'?: string;
}

export const NavMenuItem: React.FC<Props> = props => {
  const {
    isActive,
    icon,
    label,
    onClick,
    id,
    forMobileNavMenu = false,
    forDriverMobileNavMenu = false,
  } = props;
  const textColor =
    (forMobileNavMenu && isActive) || forDriverMobileNavMenu
      ? colors.white
      : !forMobileNavMenu
      ? colors.black
      : colors.grey500;
  const iconColor =
    (forMobileNavMenu && isActive) || forDriverMobileNavMenu
      ? colors.white
      : colors.grey500;

  return (
    <NavMenuItemWrapper
      id={id}
      className={cx('nav-menu-item', { isActive })}
      onClick={onClick}
      data-testid={props['data-testid']}
    >
      <NavMenuItemIcon
        className={cx('nav-menu-item-icon-wrapper', { isActive })}
      >
        {icon &&
          React.cloneElement(icon, {
            color: iconColor,
          })}
      </NavMenuItemIcon>
      <LabelText
        className={cx('nav-menu-item-text-wrapper', { isActive })}
        style={{ color: textColor }}
      >
        {label}
      </LabelText>
    </NavMenuItemWrapper>
  );
};

export default NavMenuItem;
