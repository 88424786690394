import { LoggedInUserType } from 'constants/roles';
import LoggedInUserTypeContext from 'hooks/use-logged-in-user-type/context';
import React from 'react';

const withLoggedInUserType = Wrapped => {
  class WithLoggedInUserType extends React.Component<any, any> {
    render() {
      return (
        <LoggedInUserTypeContext.Consumer>
          {({ loggedInUserType, isLoggedInUserTypeDriver }) => (
            <Wrapped
              {...this.props}
              loggedInUserType={loggedInUserType}
              isLoggedInUserTypeDriver={isLoggedInUserTypeDriver}
            />
          )}
        </LoggedInUserTypeContext.Consumer>
      );
    }
  }

  return WithLoggedInUserType;
};

export interface LoggedInUserTypeProps {
  loggedInUserType?: LoggedInUserType;
  isLoggedInUserTypeDriver?: boolean;
}

export default withLoggedInUserType as any;
