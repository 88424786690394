import { createSelector, createStructuredSelector } from 'reselect';
import { fetchRequest } from 'utils/requests';
import { getFleetId } from 'utils/localstorage';
import { cacheBustUrl, path } from 'utils/helpers';
import * as API from '@nauto/api';
import {
  isAdminSelector,
  isReadOnlySelector,
} from 'components/auth/auth.reducer';
import { RootState } from 'reducers';
import { Groups, Group } from './types';

export * from './types';

/**
 * ----------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------
 */
export const FETCHING_GROUPS = 'groups/fetching-groups';
export const HYDRATE_GROUPS = 'groups/set-groups';
export const GROUPS_RECEIVED = 'groups/groups-received';
export const GROUPS_FETCH_FAILED = 'groups/event-fetch-failed';
export const CLEAR_GROUPS = 'groups/groups-fetch-failed';
export const SELECT_GROUP = 'groups/select-active-group';
export const ASSIGNING_DRIVERS_TO_GROUP = 'groups/assigning-drivers';
export const ASSIGN_DRIVERS_SUCCESS = 'groups/assigin-drivers-success';
export const ASSIGN_DRIVERS_FAIL = 'groups/assigin-drivers-fail';
export const ASSIGNING_VEHICLES_TO_GROUP = 'groups/assigning-vehicles';
export const ASSIGN_VEHICLES_SUCCESS = 'groups/assinging-vehicles-success';
export const ASSIGN_VEHICLES_FAIL = 'groups/assigning-vehicles-fail';
export const OPEN_ADD_GROUP_MODAL = 'groups/open-add-group-modal';
export const ADDING_GROUP = 'groups/adding-group';
export const ADD_GROUP_SUCCESS = 'groups/add-group-succes';
export const OPEN_RENAME_GROUP_MODAL = 'groups/open-rename-group-modal';
export const RENAMING_GROUP = 'groups/renaming-group';
export const RENAME_GROUP_SUCCESS = 'groups/rename-group-success';
export const OPEN_DELETE_GROUP_MODAL = 'groups/open-delete-group-modal';
export const DELETING_GROUP = 'groups/deleting-group';
export const DELETE_GROUP_SUCCESS = 'groups/delete-group-success';
export const CLOSE_GROUP_MODAL = 'groups/close-group-modal';
export const GROUP_ACTION_FAIL = 'groups/group-action-fail';

/**
 * ----------------------------------------------------------------------------
 * Selector(s)
 * ----------------------------------------------------------------------------
 */

/**
 * selector for all groups data
 */
export const activeGroupIdSelector = ({ groups }): string =>
  groups.activeGroupId;

export const urlGroupSelector = createSelector(
  ({ groups }) => groups.rootGroup,
  (_, { groupId, match }) => groupId || match.params.groupId,
  (rootGroup, groupId) => {
    if (!(rootGroup && groupId)) {
      return null;
    }
    return findGroupById(rootGroup, groupId);
  },
);

export const activeGroupSelector = createSelector(
  ({ groups }) => groups.rootGroup,
  activeGroupIdSelector,
  (rootGroup, activeGroupId): Group => {
    if (!(rootGroup && activeGroupId)) {
      return null;
    }

    return findGroupById(rootGroup, activeGroupId);
  },
);

// build a map object with group ids as keys and groups/subfleets as values
export const indexedGroupsSelector = createSelector(
  activeGroupSelector,
  group => {
    return group && group.groups.length
      ? group.groups.reduce((obj, g) => ({ ...obj, [g.id]: g }), {})
      : [];
  },
);

/* returns whether the current group has named children groups (i.e. groups other than Unassigned) */
export const hasNamedChildren = (activeGroup: Group): boolean => {
  const childGroups = (activeGroup && activeGroup.groups) || [];
  const namedChildGroups = childGroups.filter(
    group => group.tag !== 'unassigned',
  );
  return !!namedChildGroups.length;
};

export const activeGroupHasNamedChildrenSelector = createSelector(
  activeGroupSelector,
  hasNamedChildren,
);

// return all entityIDs that of the group and all of its descendants
export const getAllEntityIDs = (group: Group, entityType: string): string[] => {
  // Group contains either direct_driver_ids or direct_vehicle_ids as an array
  const directEntities = group[`direct_${entityType}_ids`];
  const childrenEntities = group.groups
    ? group.groups.map(g => getAllEntityIDs(g, entityType))
    : [];
  return directEntities?.concat(...childrenEntities);
};

export const groupDriverIDsSelector = createSelector(
  activeGroupSelector,
  group => (group ? getAllEntityIDs(group, 'driver') : []),
);

export const groupVehicleIDsSelector = createSelector(
  activeGroupSelector,
  group => (group ? getAllEntityIDs(group, 'vehicle') : []),
);

export const groupsSelector = createStructuredSelector({
  rootGroup: ({ groups }) => groups.rootGroup,
  fetchingGroups: ({ groups }) => groups.fechingGroups,
  groupsFetchError: ({ groups }) => groups.groupsFetchError,
  addModalParentGroupId: ({ groups }) => groups.addModalParentGroupId,
  renameModalGroupId: ({ groups }) => groups.renameModalGroupId,
  deleteModalGroupId: ({ groups }) => groups.deleteModalGroupId,
  user: ({ user }) => user,
  isAdmin: isAdminSelector,
  isReadOnly: isReadOnlySelector,
  group: activeGroupSelector,
});

/**
 * find the group in the tree that matches an id
 */
export const prune = (group: Group) => {
  // recursively prune children
  const prunedChildren = (group.groups || []).map(prune).filter(g => !!g);

  const canAccessSelf = group.access;
  const canAccessDescendant = !!prunedChildren.length;
  const shouldPrune = !canAccessSelf && !canAccessDescendant;

  if (shouldPrune) {
    // falsy will be filtered out in prunedChildren above
    return false;
  }
  return { ...group, groups: prunedChildren, readOnly: !group.access };
};

/**
 * find the group in the tree that matches an id
 */
export const findGroupById = (group: Group, id: string): Group | false => {
  // success case
  if (!group || !id) {
    return null;
  }
  if (group && group.id === id) {
    return group;
    // stop case
  } else if (!group?.groups || !group?.groups.length) {
    return null;
    // recursive case
  } else {
    return group.groups.reduce((matchFound, g) => {
      return matchFound || findGroupById(g, id);
    }, false);
  }
};

/**
 * find the default group to select - this is the first group in the tree with access = true
 */
export const findFirstAccessibleGroup = (group: Group): string | false => {
  // success case
  if (!!group && !!group.access) {
    return group.id;
    // stop case
  } else if (!group.groups || !group.groups.length) {
    return null;
    // recursive case
  } else {
    return group.groups.reduce((matchFound, g) => {
      return matchFound || findFirstAccessibleGroup(g);
    }, false);
  }
};

export const findAllAccessibleGroups = (group: Group): string[] => {
  if (!group) {
    return [];
  }

  const groups = new Set<string>();
  let stack = [group];
  while (stack.length) {
    const groupsToProcess = [...stack];
    const n = groupsToProcess.length;
    stack = [];
    for (let i = 0; i < n; i++) {
      const g = groupsToProcess[i];
      if (g.driver_count === 0) {
        continue;
      }

      if (g.access && !groups.has(g.parent_id)) {
        groups.add(g.id);
      }

      if (g.groups) {
        g.groups.forEach(g => stack.push(g));
      }
    }
  }

  return [...groups];
};

export const untaggedFacesCountSelector = createSelector(
  ({ faces }) => faces.count,
  count => count,
);

export const hasMoreUntaggedFacesSelector = createSelector(
  ({ faces }: RootState) => faces.hasMoreCount,
  hasMoreUntaggedFaces => hasMoreUntaggedFaces,
);

/**
 * sum the untagged counts
 * only include accessible groups
 */
const getTotalUntaggedFaces = (group: Group) => {
  if (group.access) {
    return group.untagged_face_count;
    // stop case
  } else if (!group.groups || !group.groups.length) {
    return 0;
    // recursive case
  } else {
    return group.groups.reduce((count, g) => {
      return count + getTotalUntaggedFaces(g);
    }, 0);
  }
};

export const getAllDescendantIds = (group: Group) => {
  const children = group.groups || [];
  const childIds = children.map(g => g.id);
  return childIds.concat(...children.map(getAllDescendantIds));
};

/**
 * ----------------------------------------------------------------------------
 * Actions
 * ----------------------------------------------------------------------------
 */
export const fetchGroups = (fleetId = '', serviceUrl = '') => (
  dispatch: (any) => Promise<void>,
) => {
  dispatch({ type: FETCHING_GROUPS });

  const fetchForSpecificFleet = !!fleetId;
  const fleetToFetch = fetchForSpecificFleet ? fleetId : getFleetId();

  return API.groups
    .fetchGroups(
      { fleetId: fleetToFetch },
      { url: serviceUrl && `${serviceUrl}/v2.2` },
    )
    .then((response: any) => {
      if (fetchForSpecificFleet) {
        return response.data;
      }
      const responseGroup = response.data;
      const prunedRootGroup = prune(responseGroup);
      const rootGroup = { ...prunedRootGroup, isRoot: true };

      dispatch({
        type: GROUPS_RECEIVED,
        meta: { sync: true },
        payload: {
          rootGroup,
        },
      });
      return rootGroup;
    })
    .catch(error => {
      dispatch({ type: GROUPS_FETCH_FAILED, payload: { error } });
      console.log('error', error);
    });
};

// clears out the unassigned faces
export const clearGroups = () => dispatch => dispatch({ type: CLEAR_GROUPS });

/**
 * Clear out active drivers and vehicles, and set active group
 * @param activeGroupId
 */
export const setActiveGroup = (activeGroupId: string) => dispatch =>
  dispatch({
    type: SELECT_GROUP,
    meta: { sync: true },
    payload: { activeGroupId },
  });

/**
 * Hydrates groups data from localStorage
 */
export const hydrateGroups = groups => dispatch =>
  dispatch({ type: HYDRATE_GROUPS, payload: { groups } });

export const openAddGroupModal = (id: string) => dispatch =>
  dispatch({ type: OPEN_ADD_GROUP_MODAL, payload: { id } });

export const openDeleteGroupModal = (id: string) => dispatch =>
  dispatch({ type: OPEN_DELETE_GROUP_MODAL, payload: { id } });

export const openRenameGroupModal = (id: string) => dispatch =>
  dispatch({ type: OPEN_RENAME_GROUP_MODAL, payload: { id } });

export const closeGroupModal = () => dispatch =>
  dispatch({ type: CLOSE_GROUP_MODAL });

const handleEditError = (error, dispatch) => {
  console.error(error);
  dispatch({ type: CLOSE_GROUP_MODAL });
  dispatch({
    type: GROUP_ACTION_FAIL,
    payload: { error },
  });
  throw new Error(error);
};
/* tslint:disable:no-duplicate-string */
export const addGroup = (name: string, parentId: string) => dispatch => {
  dispatch({ type: ADDING_GROUP });

  return API.groups
    .addGroup({
      fleetId: getFleetId(),
      name,
      parentId,
    })
    .then((response: any) => {
      return dispatch({
        type: ADD_GROUP_SUCCESS,
        payload: { updatedRoot: response.data },
      });
    })
    .catch(error => {
      return handleEditError(error, dispatch);
    });
};

export const renameGroup = (id: string, name: string) => dispatch => {
  dispatch({ type: RENAMING_GROUP });

  return API.groups
    .renameGroup({
      fleetId: getFleetId(),
      name,
      groupId: id,
    })
    .then((response: any) => {
      return dispatch({
        type: RENAME_GROUP_SUCCESS,
        payload: { updatedRoot: response.data },
      });
    })
    .catch(error => {
      return handleEditError(error, dispatch);
    });
};

export const deleteGroup = (id: string) => dispatch => {
  dispatch({ type: DELETING_GROUP });

  return API.groups
    .deleteGroup({ fleetId: getFleetId(), id })
    .then((response: any) => {
      return dispatch({
        type: DELETE_GROUP_SUCCESS,
        payload: { updatedRoot: response.data },
      });
    })
    .catch(error => {
      return handleEditError(error, dispatch);
    });
};

export const assignDriversToGroup = (
  targetGroupId: string,
  driverIds: string[],
) => (dispatch, getState) => {
  dispatch({ type: ASSIGNING_DRIVERS_TO_GROUP });

  const {
    groups: { activeGroupId },
  } = getState();

  return API.groups
    .assignDriversToGroup({
      fleetId: getFleetId(),
      groupId: activeGroupId,
      driverIds,
      targetGroupId,
    })
    .then(resp => {
      dispatch({ type: ASSIGN_DRIVERS_SUCCESS });
      return dispatch(fetchGroups());
    })
    .catch(error => {
      return handleEditError(error, dispatch);
    });
};

export const assignVehiclesToGroup = (
  targetGroupId: string,
  vehicleIds: string[],
) => (dispatch, getState) => {
  const {
    groups: { activeGroupId },
  } = getState();
  dispatch({ type: ASSIGNING_VEHICLES_TO_GROUP });

  return API.groups
    .assignVehiclesToGroup({
      fleetId: getFleetId(),
      groupId: activeGroupId,
      vehicleIds,
      targetGroupId,
    })
    .then(resp => {
      dispatch({ type: ASSIGN_VEHICLES_SUCCESS });
      return dispatch(fetchGroups());
    })
    .catch(error => {
      return dispatch({ type: ASSIGN_VEHICLES_FAIL, payload: { error } });
    });
};
/* tslint:enable:no-duplicate-string */
/**
 * ----------------------------------------------------------------------------
 * Reducers
 * ----------------------------------------------------------------------------
 */

export const initialState: Groups = {
  activeGroupId: '',
  addingGroup: false,
  addModalParentGroupId: '',
  assignDriversError: '',
  assigningDrivers: false,
  assigningVehicles: false,
  assignVehiclesError: '',
  groupCountKey: null,
  deleteModalGroupId: '',
  deletingGroup: false,
  fetchingGroups: false,
  groupActionError: '',
  groupsFetchError: '',
  renameModalGroupId: '',
  renamingGroup: false,
  rootGroup: null, // TODO do not coerce types
};

export default (state = initialState, { type, payload }): Groups => {
  switch (type) {
    case SELECT_GROUP:
      return {
        ...state,
        activeGroupId: findGroupById(state.rootGroup, payload.activeGroupId)
          ? payload.activeGroupId
          : state.activeGroupId,
      };
    case FETCHING_GROUPS:
      return {
        ...state,
        fetchingGroups: true,
      };
    case GROUPS_RECEIVED:
      return {
        ...state,
        fetchingGroups: false,
        rootGroup: payload.rootGroup,
      };
    case HYDRATE_GROUPS:
      return {
        ...state,
        rootGroup: payload.groups,
      };
    case GROUPS_FETCH_FAILED:
      return {
        ...state,
        fetchingGroups: false,
        groupsFetchError: payload.error,
      };
    case CLEAR_GROUPS:
      return initialState;

    // Driver assign
    case ASSIGNING_DRIVERS_TO_GROUP:
      return {
        ...state,
        assigningDrivers: true,
      };
    case ASSIGN_DRIVERS_SUCCESS:
      return {
        ...state,
        assigningDrivers: false,
      };
    case ASSIGN_DRIVERS_FAIL:
      return {
        ...state,
        assigningDrivers: false,
        assignDriversError: payload.error,
      };

    // Vehicle assign
    case ASSIGNING_VEHICLES_TO_GROUP:
      return {
        ...state,
        assigningVehicles: true,
      };
    case ASSIGN_VEHICLES_SUCCESS:
      return {
        ...state,
        assigningVehicles: false,
      };
    case ASSIGN_VEHICLES_FAIL:
      return {
        ...state,
        assigningVehicles: false,
        assignVehiclesError: payload.error,
      };

    // Modal toggles
    case OPEN_ADD_GROUP_MODAL:
      return { ...state, addModalParentGroupId: payload.id };
    case OPEN_RENAME_GROUP_MODAL:
      return { ...state, renameModalGroupId: payload.id };
    case OPEN_DELETE_GROUP_MODAL:
      return { ...state, deleteModalGroupId: payload.id };

    case CLOSE_GROUP_MODAL:
      return {
        ...state,
        addModalParentGroupId: '',
        renameModalGroupId: '',
        deleteModalGroupId: '',
      };

    // edit groups lifecycle
    case ADDING_GROUP:
      return { ...state, addingGroup: true };
    case RENAMING_GROUP:
      return { ...state, renamingGroup: true };
    case DELETING_GROUP:
      return { ...state, deletingGroup: true };

    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        addingGroup: false,
        addModalParentGroupId: '',
        rootGroup: payload.updatedRoot,
      };
    case RENAME_GROUP_SUCCESS:
      return {
        ...state,
        renamingGroup: false,
        renameModalGroupId: '',
        rootGroup: payload.updatedRoot,
      };
    case DELETE_GROUP_SUCCESS: {
      const activeGroupDeleted = !findGroupById(
        state.rootGroup,
        payload.updatedRoot,
      );
      return {
        ...state,
        deletingGroup: false,
        rootGroup: payload.updatedRoot,
        deleteModalGroupId: '',
        activeGroupId: activeGroupDeleted
          ? payload.updatedRoot.id
          : findFirstAccessibleGroup(payload.updatedRoot),
      };
    }
    case GROUP_ACTION_FAIL:
      return {
        ...state,
        addingGroup: false,
        renamingGroup: false,
        deletingGroup: false,
        assigningDrivers: false,
        assigningVehicles: false,
        groupActionError: payload.error,
      };

    default:
      return state;
  }
};
