import React, { FC, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  Heading,
  Box,
  Text,
  spaceSizesPx,
  zIndex,
  textSizes,
  colors,
  textSizesPx,
  ScreenBreakPoints,
} from '@nauto/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CustomVideoRequestIcon, InboxIcon, SearchIcon } from '@nauto/icons';
import Dropdown from '@nauto/dropdown';
import FleetSelector from 'components/fleet-selector';
import GlobalSearch from 'components/global-search/global-search';
import {
  isLoggedInUserTypeDriverSelector,
  isReadOnlyEventsRoleSelector,
} from 'components/auth/auth.reducer';
import useUserData from 'hooks/use-user-data';
import VideoRequestDrawers from 'components/notifications/video-request-drawers';
import NotificationsDropdown from 'components/notifications/notifications-dropdown';
import GlobalContext from 'components/context/global-context';
import UserMenu from './user-menu';
import BackButton from 'atoms/back-button';
import { useHistory } from 'react-router-dom';
import { setPrevLocation as cachePrevLocation } from 'utils/localstorage';
import { mpTrack } from 'components/mixpanel';
import { MixpanelEventKeys } from 'components/mixpanel/tags';
import { estimateWidth } from 'utils/font';
import { headerControlBarHeight } from 'constants/layout';
import NotificationsInfoModal from 'components/notifications/notifications-info-modal';
import useUserPermission, { Permissions } from 'hooks/use-permissions';
import { currentFleetIsSuperfleetSelector } from 'components/superfleets/superfleets.redux';

const LEFT_NAV_AND_PADDING_WIDTH = 112;
const POSSIBLE_WINDOW_SCROLLBAR = 20;

interface Props {
  routeName?: string;
  canSelectGroup?: boolean;
  showFleetName?: boolean;
  showBackButton?: boolean;
  shouldPreserveSearch?: boolean;
  readOnly: boolean;
  isLoggedInUserTypeDriver?: boolean;
  isCurrentFleetSuperFleet: boolean;
}
interface StyledProps {
  isLoggedInUserTypeDriver: boolean;
}

const HeaderControlBar: FC<Props> = props => {
  const {
    routeName,
    canSelectGroup = true,
    showFleetName = true,
    showBackButton = false,
    shouldPreserveSearch = false,
    readOnly,
    isLoggedInUserTypeDriver,
    isCurrentFleetSuperFleet,
  } = props;
  const pageTitleRef = useRef<HTMLDivElement>(null);

  const {
    location: { pathname, search },
  } = useHistory();

  const [rightWidth, setRightWidth] = useState<number>(undefined);

  const [prevLocation, setPrevLocation] = useState({
    routeName: '',
    pathname: '',
    search: '',
  });

  const user = useUserData();
  const { hasPermission } = useUserPermission();
  const shouldShowCVR =
    hasPermission(Permissions.CustomUserManagement, true) &&
    !isCurrentFleetSuperFleet
      ? hasPermission(Permissions.DriverInsightsHome, 'full-access')
      : true;

  useEffect(() => {
    if (!showBackButton) {
      const location = {
        routeName,
        pathname,
        search,
      };
      setPrevLocation(location);
      cachePrevLocation(location);
    }
  }, [routeName, pathname, search, showBackButton]);

  const measureRightNavEl = el => {
    if (!!el && !rightWidth) {
      setRightWidth(el.offsetWidth + POSSIBLE_WINDOW_SCROLLBAR);
    }
  };

  const leftWidth =
    estimateWidth({
      text: routeName || '',
      height: textSizes.xxl,
      face: 'Bold',
    }) + LEFT_NAV_AND_PADDING_WIDTH;

  const knownInUseSpace = leftWidth && rightWidth && leftWidth + rightWidth;

  return (
    <HeaderBox
      justify="space-between"
      align="center"
      isLoggedInUserTypeDriver={isLoggedInUserTypeDriver}
    >
      <BoxLeft justify="flex-start" align="center">
        {!isLoggedInUserTypeDriver && routeName && (
          <div ref={el => (pageTitleRef.current = el)}>
            <Heading
              size="xxl"
              weight="Black"
              style={{
                whiteSpace: 'nowrap',
                marginRight: spaceSizesPx.md,
              }}
            >
              {routeName}
            </Heading>
          </div>
        )}
        <BackButton
          showBackButton={showBackButton}
          prevLocation={prevLocation}
          shouldPreserveSearch={shouldPreserveSearch}
        />
        {!isLoggedInUserTypeDriver && (
          <FleetSelectorWrapper>
            {knownInUseSpace && (
              <FleetSelector
                inUseSpace={knownInUseSpace}
                canSelectGroup={canSelectGroup}
                showFleetName={showFleetName}
              />
            )}
          </FleetSelectorWrapper>
        )}
      </BoxLeft>

      <BoxRight justify="flex-end" align="center" ref={measureRightNavEl}>
        <Box
          paddingLeft="sm"
          paddingRight="sm"
          onClick={() => {
            mpTrack(MixpanelEventKeys.NavUser);
          }}
        >
          <Dropdown
            toggle={
              <ToggleBox id="home-header-dropdown-user-menu">
                <Text size="md">{user && (user.name || user.email)}</Text>
              </ToggleBox>
            }
            caret={true}
          >
            {({ handleClose }) => <UserMenu onItemClick={handleClose} />}
          </Dropdown>
        </Box>
        {!isLoggedInUserTypeDriver && (
          <>
            <GlobalContext.Consumer>
              {({ isGlobalUser }) =>
                isGlobalUser && (
                  <Box
                    paddingLeft="sm"
                    paddingRight="sm"
                    onClick={() => {
                      mpTrack(MixpanelEventKeys.NavSearch);
                    }}
                  >
                    <Dropdown
                      toggle={
                        <ToggleBox id="home-header-dropdown-global-search">
                          <SearchIcon />
                        </ToggleBox>
                      }
                      align="right"
                    >
                      <GlobalSearch />
                    </Dropdown>
                  </Box>
                )
              }
            </GlobalContext.Consumer>
            {!readOnly && (
              <>
                {shouldShowCVR && (
                  <Box
                    paddingLeft="sm"
                    paddingRight="sm"
                    onClick={() => {
                      mpTrack(MixpanelEventKeys.NavVideoRequests);
                    }}
                  >
                    <Dropdown
                      toggle={
                        <ToggleBox id="home-header-dropdown-custom-video-request">
                          <CustomVideoRequestIcon />
                        </ToggleBox>
                      }
                      align="right"
                    >
                      {({ handleClose }) => (
                        <VideoRequestDrawers handleToggle={handleClose} />
                      )}
                    </Dropdown>
                  </Box>
                )}
                <Box
                  paddingLeft="sm"
                  paddingRight="sm"
                  onClick={() => {
                    mpTrack(MixpanelEventKeys.NavNotification);
                  }}
                >
                  <Dropdown
                    toggle={
                      <ToggleBox id="home-header-dropdown-inbox">
                        <InboxIcon />
                      </ToggleBox>
                    }
                    align="right"
                  >
                    {({ handleClose }) => (
                      <NotificationsDropdown handleToggle={handleClose} />
                    )}
                  </Dropdown>
                </Box>
                <NotificationsInfoModal />
              </>
            )}
          </>
        )}
      </BoxRight>
    </HeaderBox>
  );
};

const FleetSelectorWrapper = styled.div`
  flex-grow: 1;
`;

const BoxLeft = styled(Box)`
  font-size: ${textSizesPx.xl};
  flex-grow: 1;
`;

const ToggleBox = styled(Box)`
  padding: 0 ${spaceSizesPx.sm};
`;

const BoxRight = styled(Box)`
  position: sticky;
  right: 0;
  padding-top: ${spaceSizesPx.xs};
  background: ${colors.grey100};
  height: calc(100% - 2px);

  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    display: none;
  }
`;

const HeaderBox = styled(Box)<StyledProps>`
  max-width: 100vw;
  height: ${headerControlBarHeight}px;
  min-height: ${headerControlBarHeight}px;
  padding-left: ${spaceSizesPx.xl};
  padding-right: ${spaceSizesPx.lg};
  z-index: 1;
  position: relative;
  .route-name {
    font-size: ${textSizesPx.lg};
    font-family: ${({ theme }) => theme.fonts.black};
  }
  /* TODO: replace with ${
    zIndex.navigation
  } once ui-kit modal and table are removed */
  /* This value 7 is lower than ui-kit modal index of 12 and higher then ui-kit sticky-table header index of 4 */
  z-index: 7;

  @media (max-width: ${ScreenBreakPoints.MOBILE}) {
    height: ${({ isLoggedInUserTypeDriver }) =>
      isLoggedInUserTypeDriver ? `unset` : `48px`};
    min-height: ${({ isLoggedInUserTypeDriver }) =>
      isLoggedInUserTypeDriver ? `unset` : `48px`};
    padding-left: ${spaceSizesPx.md};
    padding-right: ${spaceSizesPx.sm};
    margin-top: 60px;
  }
`;

const selector = createStructuredSelector({
  readOnly: isReadOnlyEventsRoleSelector,
  isLoggedInUserTypeDriver: isLoggedInUserTypeDriverSelector,
  isCurrentFleetSuperFleet: currentFleetIsSuperfleetSelector,
});

export default connect(selector)(HeaderControlBar);
